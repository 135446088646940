<template>
    <li class="item">
        <!-- <span class="label">{{ label }}</span>
        <span class="info">
            <el-dropdown v-if="type == 'email'" trigger="click" placement="bottom-start">
                <span class="el-dropdown-link">
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <div v-if="type == 'email' && hasTickets">
                        <el-dropdown-item @click.native="searchTicket">{{ $t("agent-console.search-ticket") }}</el-dropdown-item>
                        <el-dropdown-item @click.native="sendEmail">{{ $t("agent-console.send-email") }}</el-dropdown-item>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
            <p :title="value" class="mb-0" >
                <el-tooltip effect="dark" :content="$t('agent-console.call-to', { name : value })" placement="bottom-start">
                    <el-button type='text' v-if="type === 'call'" @click.native="call"><i class="fas fa-phone"></i></el-button>
                </el-tooltip>
                {{ value }}
            </p>
        </span> -->

        <el-tooltip effect="dark" :content="clientInfoItem.label" placement="bottom-start">
            <span class="label">{{ clientInfoItem.label }}</span>
        </el-tooltip>

        <div v-if="!editing">
            <el-tooltip effect="dark" :content="clientInfoItem.value" placement="bottom-start">
                <span class="value" v-if="!isLink">{{ clientInfoItem.value }}</span>
                <a :href="clientInfoItem.value" v-else target="_blank" rel="noopener noreferrer">{{clientInfoItem.value}}</a>
            </el-tooltip>
            <el-button type="text" icon="el-icon-edit" @click="editing = true" v-if="clientInfoItem.editable && enabledEdit"></el-button>
        </div>
        <div v-else class="form">
            <el-input v-model="newValue" size="small" :disabled="saving"></el-input>
            <el-button icon="el-icon-close" size="small" @click="editing = false" :disabled="saving"></el-button>
            <el-button icon="el-icon-check" size="small" type="primary" @click="edit" v-loading="saving"></el-button>
        </div>
    </li>
</template>

<script>
import { mapGetters } from 'vuex';
// import getUrls from 'get-urls'
export default {
    name: "TheClientInfoItem",
    props: {
        activityKey: {
            required: true,
            type: String
        },  
        clientInfoItem: {
            required: true,
        },
        enabledEdit: {
            required: true,
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            saving: false,
            editing: false,
            newProperty: this.clientInfoItem.label,
            newValue: this.clientInfoItem.value
        }
    },
    methods: {
        ...mapGetters(['getAgent']),
        sendEmail() {
            this.$emit('sendEmail', this.clientInfoItem.value);
        },
        searchTicket() {
            this.$emit('searchTicket', this.clientInfoItem.value);
        },
        call() {
            this.$emit('call', this.clientInfoItem.value);
        },
        async edit() {
            this.saving = true;
            const response = await this.$store.dispatch("doUpdatePropertyValue", {
                roomId: this.activityKey,
                value: this.newValue,
                id: this.clientInfoItem.id,
            });
            const actionKey = response.key;
            this.$store.dispatch("agentConsoleChat/listenToChatEngineAction", { key: actionKey, callback: this.callback });
        },
        callback(payload) {
            if (payload.status) {
                this.saving = payload.status === "success" ? false : true;
                if (payload.status === "success") {
                    this.editing = false;
                }
            }
        }
    },
    computed: {
        hasTickets() {
            return this.getAgent().mail;
        },
        isLink() {
            const regex = /https?:\/\/[^\s/$.?#].[^\s]*/
            return regex.test(this.clientInfoItem.value)
        }
    }
}
</script>

<style scoped>
.item {
    display: inline-flex;
    width: 100%;
    min-height: 32px;
    gap: calc(var(--column) / 2);
    flex-wrap: wrap;
}

.label {
    min-width: 100px;
    width: 15%;
    display: inline-block
}

.value {
    color: var(--grey-neutral-900);
    display: inline-block
}

button {
    margin: 0 !important;
}

span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
</style>