<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="tpmEntity" :title="title" search="name" :submit="beforeSubmit"></the-form>
</template>

<script>
import TheForm from '@/components/crud/TheForm'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data() {

    let tpmEntity = this.entity;

    if (this.entity) {
      if (this.entity.bot) {
        tpmEntity.bot = this.entity.bot["id"];
      }
    }

    return {
      store: "departments",
      tpmEntity,
      form: {
        nodes: [
          { component : 'input', index : 'name', label : this.$t('configuration.users.name'), required : true },
          { component : "select", index : "chatProvider", label : this.$t("contact-me.chat-center.provider-type"), store : 'chatProviders', required : true, 'label-value' : ['name',"type"], 'index-value' : "id", params: { ["organization.id"] : this.$store.getters["contactMe/getOrganizationId"] }},
          { component : "select", index : 'enableSysMsgClient', label: this.$t("contact-me.chat-center.send-system-messages-to-client"), value: 0, values : [
            { label : this.$t("common.yes"), value : 1 },
            { label : this.$t("common.no"), value : 0 },
          ]},
          { component : "select", index : 'showAgentName', label: this.$t("contact-me.chat-center.show-agent-name-in-system-messages"), value: 0, values : [
            { label : this.$t("common.yes"), value : 1 },
            { label : this.$t("common.no"), value : 0 },
          ]},
          { component : 'input', index: 'whatsappNumber', label: 'Whatsapp'},
          { component : "select", index : 'strategy', label: this.$t("contact-me.chat-center.strategy"), value: null, clearable: true, values : [
            { label : this.$t("strategy.secuencial"), value : 'secuencial' },
          ]},
          { component : 'select', index: 'bot', label: 'Bot', store : 'bots', 'label-value': 'name', clearable: true, value: null, params: { ["organization.id"] : this.$store.getters["contactMe/getOrganizationId"]} },
          { component: "checkbox", index: "botAutoStart", label : "Bot auto start", notes: "Esta opción habilita el inicio del bot antes de que el usuario envié un mensaje", hidden: "chatProvider:[data-type=Gupshup|Custom|Hiperme]"},
          { component: "checkbox", index: "showImageFromClient", label : this.$t("contact-me.chat-center.show-multimedia"), notes: "Esta opción habilita la subida de imagenes y videos del cliente"},
          { component: 'select', index: "lang", label: this.$t("contact-me.chat-center.language"), values : [
            { label: this.$t("languages.es"), value: "es" },
            { label: this.$t("languages.en"), value: "en" }
          ]}
        ]
      }
    }
  },
  components: {
    TheForm
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("contact-me.chat-center.edit-department") : this.$t("contact-me.chat-center.new-department")
    },
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  },
  methods: {
    beforeSubmit(data) {
      const organizationId = this.$store.getters["contactMe/getOrganizationId"];
      data.bot = data.bot ? `/api/bots/${data.bot}` : null;
      data.chatProvider = `/api/chat_providers/${data.chatProvider}`;
      data.organization = `/api/organizations/${organizationId}`;
      data.botAutoStart = data.botAutoStart ? 1 : 0
      data.showImageFromClient = data.showImageFromClient ? 1 : 0;
      data.strategy = data.strategy || null;
      return data;
    }
  }
}
</script>