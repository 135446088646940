<template>
  <div class="callhistory-item">
    <div class="info">
      <p>{{ call.displayname }} <br> {{ call.date | redeableDate(true) }}</p>
      <el-tooltip class="item" effect="dark" placement="right" :content="$i18n.messages[$i18n.locale]['common'][call.type]">
        <span class="type"><i class="fas" :class="className"></i></span>
      </el-tooltip>
    </div>
    <phone-button v-if="janus" :janus="janus" :extension="call.number" :displayname="call.displayname" class="phone-button"></phone-button>
  </div>
</template>

<script>
import PhoneButton from '@/components/webrtc/PhoneButton'
export default {
  name : "TheCallHistoryItem",
  props : {
    call : {
      required : true,
    },
    janus : {
      required : false, 
    }
  },
  computed : {
    className() {
      return {
        'fa-arrow-up': this.call.type === 'outgoing',
        'fa-arrow-down': this.call.type === 'ingoing',
        'fa-arrow-down lost': this.call.type === 'lost',
      }
    }
  },
  components: { PhoneButton },
}
</script>

<style scoped>
.callhistory-item {
  align-items: baseline;
  color: var(--blue-grey);
  display: inline-flex;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
}

.phone-button {
  color: var(--blue-grey);
}

.lost {
  color: var(--red);
}

.fas {
  margin-left: 10px;
}

.fa-arrow-up,
.fa-arrow-down {
  font-size: 12px;
  transform: rotate(45deg);
}

button {
  margin-top: 0!important
}

.info {
  display: inline-flex;
}

.type {
  color: #334139;
}
</style>