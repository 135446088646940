<template>
  <header>
    <div>
      <button v-if="showUsers" @click="$emit('newMessage')"><i class="fas fa-arrow-left"></i></button>
      <h2 class="mb-0" v-if="isMax">{{ title }}</h2>
    </div>
    <div class="buttons">
      <button @click="toggleSearchBar" v-if="isMax"><i class="fas" :class="{ 'fa-search' : !showSearchBar, 'fa-times' : showSearchBar }"></i></button>
      <el-dropdown trigger="click" placement="bottom-start" v-if="!showUsers && isMax">
        <button class="el-dropdown-link">
          <i class="fas fa-filter"></i>
        </button>
        <el-dropdown-menu slot="dropdown">
            <p>{{ $t("chats.filters.order-by") }}</p>
            <div class="group">
                <el-dropdown-item><el-radio v-model="order" @click.native="updateSortBy('date')" label="1">{{ $t("chats.filters.activity") }}</el-radio></el-dropdown-item>
                <el-dropdown-item><el-radio v-model="order" @click.native="updateSortBy('alphabet')" label="2">{{ $t("chats.filters.alphabetically") }}</el-radio></el-dropdown-item>
            </div>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown trigger="click" placement="bottom-start" v-if="!showUsers">
        <button class="el-dropdown-link mr-0">
            <i class="fas fa-ellipsis-v"></i>
        </button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="$emit('newMessage')">{{ $t("chats.new-message") }}</el-dropdown-item>
            <el-dropdown-item @click.native="toggleShowCreateGropModal">{{ $t("chats.new-group") }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </header>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props : {
    showUsers : {
      required : true,
      default : false
    },
    isMax : {
      required : true,
      default : true
    }
  },
  data() {
    return {
      showSearchBar : false,
      order : "1"
    }
  },
  computed : {
    title : function(){
      return (!this.showUsers) ? "Chats" : "Nuevo mensaje"
    }
  },
  methods : {
    ...mapActions('corpochat', ['updateSortBy']),
    ...mapActions(['toggleShowCreateGropModal']),
    toggleSearchBar() {
      this.showSearchBar = !this.showSearchBar
      this.$emit('toggleSearchBar')
    }
  }
}
</script>

<style scoped>
  header {
    align-items: center;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: var(--column);
  }

  button {
    border: none;
    outline : none;
    margin-top: 0!important;
    background-color: transparent;
    color: white
  }

  header div {
    display:inline-flex;
  }

  header > div button {
    margin-right: var(--column);
  }

  h2 {
    font-size: 1.5rem;
    color: white;
  }

  .light h2 {
    color: var(--other-blue);
  }

  .light button {
    color: var(--other-blue);
  }

  .clasic h2 {
    color: var(--hiperpbx-grey)
  }

  .clasic button {
    color: var(--hiperpbx-green);
  }

  .el-dropdown-menu > p:first-child {
      margin-left: 10px;
      font-weight: bold;
      color: var(--blue-grey)
  }

</style>