<template>
    <div class="user-buttons">
        <el-dropdown trigger="click">
            <span class="el-dropdown-link">
                <user-item :user="user" :showFullname="false" class="user-item"/>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="updateStatus('connected')"><span class="status connected"></span>{{ $t('user-status.connected') }}</el-dropdown-item>
                <el-dropdown-item @click.native="updateStatus('away')"><span class="status away"></span>{{ $t('user-status.away') }}</el-dropdown-item>
                <el-dropdown-item @click.native="updateStatus('busy')"><span class="status busy"></span>{{ $t('user-status.busy') }}</el-dropdown-item>
                <el-dropdown-item @click.native="openHelp">{{ $t('user-buttons.help') }}</el-dropdown-item>
                <el-dropdown-item @click.native="openUserModal">{{ $t('user-buttons.account') }}</el-dropdown-item>
                <el-dropdown-item @click.native="openUserConfiguration">{{ $t('user-buttons.configuration') }}</el-dropdown-item>
                <el-dropdown-item @click.native="$router.push({name: 'Logout'})">{{ $t('user-buttons.logout') }}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-tooltip class="item" effect="dark" content="Sala de conferencias" :placement="position">
            <videocall-button v-if="user.hasVideoCall" type="meet" class="button" :room-id="'@' + new Date().getTime()"></videocall-button>
        </el-tooltip>
        <div v-if="hasExtension && !hasBasic">
            <el-button  type="text" :icon="precense === 'web' ? 'fas fa-phone' : 'fas fa-mobile-alt'" class="button notification" @click="$store.commit('SET_SHOW_MODAL', true); $store.commit('SET_SHOW_LEFT_MENU', true)" v-if="!getCorpoIsInCall"></el-button>
            <el-dropdown v-if="getCorpoIsInCall" class="the-keypad-button" size="small" >
              <span class="el-dropdown-link">
                <el-button type="success" icon="fas fa-phone" circle @click="$store.commit('SET_SHOW_MODAL', true); $store.commit('SET_SHOW_LEFT_MENU', true)"></el-button>
              </span>
              <el-dropdown-menu slot="dropdown" style="width: 200px">
                <hold-button :janus="janus" type="text"></hold-button>
                <mute-button :janus="janus" type="text"></mute-button>
                <hangup-button :janus="janus" type="text"></hangup-button>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
        <the-account-modal :user="user" ref="userModal" ></the-account-modal>
        <user-configuration ref="userConfiguration"></user-configuration>
        <the-help-modal ref="help"></the-help-modal>
         <el-popover popper-class="pop-over-notifications"	 placement="top-start" trigger="click">
             <div :class="['user-button__notifications-container', {'long-container': formatedNotification.length <= 5}]" ref="scrollContainer" @scroll="handleScroll">
                 <p v-if="notifications.length === 0">{{ $t('common.you-have-no-new-notifications') }}</p>
                 
                <the-notification v-for="(notification, index) in formatedNotification" :key="index" :notification="notification"/>
                 
             </div>
            <el-badge :value="totalNotifications" :max="99" class="item" slot="reference" :hidden="totalNotifications == 0">
                <button class="button notification"  ><i class="fas fa-bell "></i></button>
            </el-badge>
        </el-popover>
    </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import UserItem from '@/components/common/UserItem';
import UserConfiguration from '@/components/common/UserConfiguration';
import VideocallButton from '@/components/webrtc/VideocallButton'
import TheHelpModal from '@/components/help/TheHelpModal'
import TheAccountModal from '@/components/account/TheAccountModal'
import TheNotification from './TheNotification'
import HoldButton from '@/components/webrtc/HoldButton'
import MuteButton from '@/components/webrtc/MuteButton'
import HangupButton from '@/components/webrtc/HangupButton'
    export default {
        name : "UserButtons",
        components : {
            UserItem,
            UserConfiguration,
            VideocallButton,
            TheHelpModal,
            TheAccountModal,
            TheNotification,
            HoldButton,
            MuteButton,
            HangupButton
        },
        props : {
            min : {
                required : true,
                default : false,
                type : Boolean
            }
        },
        data() {
            return {
                labelPosition : this.min ? 'right' : 'bottom',
                offset: 10
            }
        },
        methods : {
            ...mapActions(['updateStatus','firebaseLogout','listenToNewNotifications']),
            ...mapGetters(['getFirebase', 'getJanus']),
            openUserModal() {
                this.$refs.userModal.toggleVisible()
            },
            openUserConfiguration() {
                this.$refs.userConfiguration.toggleVisible()
            },
            openHelp() {
                this.$refs.help.toggleVisible()
            },
            handleScroll(){
                const scrollContainer = this.$refs.scrollContainer;
                if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight) {
                    this.loadMore();
                }
        
            },
            loadMore(){
                if(this.offset < this.totalNotifications){
                    this.offset += this.totalNotifications > 10 ? 10 : this.totalNotifications
                    this.listenToNewNotifications({uid: this.user.uid, offset: this.offset})
                }
            }
        },
        computed : {
            ...mapGetters({totalNotifications:'getTotalNotifications', notifications : 'getNotifications', janus : 'getJanus', user : 'getUser', precense: 'getPresence'}),
            ...mapGetters(['getAgent','getCorpoIsInCall']),
            position() {
                return (this.min) ? 'right' : 'bottom'
            },
            ticketNotifications() {
                return this.notifications.filter(notification => notification.type === 'tickets')
            },
            hasMailCenterEnabled() {
                if (Object.keys(this.getAgent).length !== 0 && this.getAgent.mail) {
                    return true
                }
                return false
            },
            hasExtension() {
                return this.user.localExtension && (this.user.roles.includes('ROLE_USER') || this.user.roles.includes('ROLE_ADMIN') || this.user.roles.includes('ROLE_BASIC')) ? true : false
            },
            hasBasic() {
                return this.user.roles.includes('ROLE_BASIC');
            },
            formatedNotification(){

                const notificationDateFormated = this.notifications.map((element)=>{
                    if(element.createdAt.nanoseconds){
                        const nanoseconds = element.createdAt.nanoseconds;
                        const seconds = element.createdAt.seconds;
                        const milliseconds = nanoseconds / 1e6;

                        const date = new Date((seconds * 1000) + milliseconds);
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        const hours = String(date.getHours()).padStart(2, '0');
                        const minutes = String(date.getMinutes()).padStart(2, '0');
                        
                        element.createdAt = `${month}-${day} ${hours}:${minutes}`
                    }
                
                    return element;
                })

                return notificationDateFormated.sort((a,b) => {
                    if(a.createdAt < b.createdAt){
                        return 1
                    }
                    return -1
                })
                
            }
        },
        created(){
            this.listenToNewNotifications({uid: this.user.uid, offset: this.offset})
        }
    }
</script>
<style>
.pop-over-notifications{
    padding: 5px !important;
}
</style>
<style scoped>
    .user-buttons {
        align-items: center;
        display: inline-flex;
        justify-content: space-between;
        padding: var(--column);
        margin-top: auto;
    }

     .min .user-buttons {
        display: flex;
        flex-direction: column-reverse;
        height: 200px;
    }

    .status {
        display: inline-block;
        margin-right: var(--column);
        height: 10px;
        width: 10px;
        border-radius: 50%;
    }

    .user-item {
        cursor: pointer;
    }

    .user-item:deep(.user-status)  {
        border-color: var(--dark-blue);
    }

    .button {
        background-color: transparent;
    }

    .light .button,
    .light .button:deep(button)  {
        color: var(--light-menu-links-color);
    }

    .light .user-item:deep(.user-status)  {
        border-color: var(--light-menu-color);
    }

    .clasic .button,
    .clasic .button:deep(button)  {
        color: white;
    }

    .clasic .user-item:deep(.user-status)  {
        border-color: var(--hiperpbx-grey);
    }
    .user-button__notifications-container{
        display: flex;
        flex-direction: column;
        height: 500px; 
        overflow-y: auto;
    }
    .user-button__notifications-container .notification:last-child{
        border: 0;
    }
    .user-button__notifications-container.long-container{
        justify-content: flex-end;
    }
    .button.notification{
        border: 0;
        outline: none;
    }

</style>
