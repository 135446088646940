<template>
  <div class="jitsi" > 
    <div class="meet" v-if="exists"  ref="meet" ></div>
    <div class="not-found" v-else>
      <h2>{{ $t("videocall.room-not-found") }}</h2>
    </div>
  </div>
</template>

<script>
import JitsiMeetExternalAPI from "@/plugins/jitsi_external_api.js"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "Videocall",
  props: {
    roomId: {
      required: true,
    },
  },
  data() {
    return {
      exists: true,
      domain: process.env.VUE_APP_JITSI_HOST,
    };
  },
  computed : {
    ...mapGetters({
      user : 'getUser',
    }),
  },
  methods: {
    ...mapActions(["getVideocall","setInVideocall", "fetchUserData", "saveFirebaseConfiguration", "initializeFirebaseApp"]),
  },
  async created() {
    try {
      const firebaseConfig = await this.fetchUserData();
      this.saveFirebaseConfiguration(firebaseConfig)
      await this.initializeFirebaseApp()

      const snap = await this.getVideocall(this.roomId);
      this.exists = snap.exists;

      if (this.exists) {
        this.setInVideocall(this.roomId)
        
        const options = {
          roomName: this.roomId.replace('@',''),
          width: "100%",
          parentNode: this.$refs.meet,
          userInfo: {
            displayName: this.user.fullname,
            email : this.user.email
          },
          configOverwrite: {
            prejoinPageEnabled: true
          }
        }

        new JitsiMeetExternalAPI(this.domain, options)    
      }
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped>
#meet {
  background-color: black;
  width: 100%;
}

.jitsi {
  height: 100vh;
  width: 100%;
  display: flex;
}

.jitsi .meet {
  flex: 1
}

.jitsi .not-found {
  color: var(--other-blue);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  background-color: var(--light-menu-chat);
  flex-direction: column;
  text-align: center;
}

h1 {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  text-indent: -50000px;
  width: 100%;
  margin-bottom: 0;
  text-align:center; width: 100%; 
  font-size: 8em;
}
</style>