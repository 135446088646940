<template>
	<el-dialog
		:close-on-click-modal="closeOnClickModal"
		:show-close="closeOnClickModal"
		:close-on-press-escape="closeOnClickModal"
		:visible.sync="showGroupEdit"
		@open="searchMembersOfGroup"
		@closed="initState"
		width="60%"
	>
		<el-form :model="form" v-loading="updating" v-if="!success && !error">
			<h2>{{ $t("groups.add-members.title", { name : name }) }}</h2>
			<el-form-item prop="users">
				<the-search-input v-model="search"></the-search-input>
				<div class="list">
					<el-checkbox-group v-model="form.users" v-loading="loading">
						<el-checkbox v-for="user in users" :label="user.uid" :key="user.uid"> {{ user.fullname }}</el-checkbox>
					</el-checkbox-group>
				</div>
			</el-form-item>
			<el-button type="primary" @click="addMembers">{{ $t("common.update") }}</el-button>
		</el-form>
		<div v-if="success" >
			<success  class="icon"></success>
			<h2>{{ $t("groups.add-members.group-edited-successfully") }}</h2>
			<p>{{ name }}</p>
		</div>
		<div v-if="error" >
			<error class="icon"></error>
			<h2>{{ $t("common.error-message") }}</h2>
			<p>{{ $t("groups.add-members.errors.updating") }}</p>
		</div>
	</el-dialog>
</template>

<script>
import Error from '@/components/icons/Error';
import Success from '@/components/icons/Success';
import TheSearchInput from '@/components/html/TheSearchInput'
export default {
	components : {
		TheSearchInput,
		Success,
		Error
	},
	data() {
		return {
			search : '',
			updating : false,
			members : [],
			loading : false,
			closeOnClickModal : true,
			form : {
				users : []
			},
			success : false,
			error : false
		}
	},
	computed: {
		showGroupEdit: {
			get() {
				return this.$store.getters.groupEditIsVisible;
			},
			set() {
				return this.$store.commit("toggleGroupEdit");
			}
		},
		name() {
			return this.$store.getters.getGroupName;
		},
		roomId() {
			return this.$store.getters.getRoomId;
		},
		users() {
			return this.$store.getters['_users/getUsers'].filter(user => !this.members.includes(user.uid)).filter(user => !this.search || user.fullname.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
		}
	},
	methods : {
		initState() {
			this.search = ''
			this.updating = false
			this.members = []
			this.loading = false
			this.closeOnClickModal = true
			this.form.users = []
			this.success = false
			this.error = false
		},
		async searchMembersOfGroup() {
			if (this.loading) {
				return
			}

			this.loading = true
			try {
				const ref = await this.$store.dispatch("getMembersOfGroup", { roomId : this.roomId })
				ref.orderBy("fullname")
				const data = await ref.get()
				if (!this.empty) {
					this.members = data.docs.map(doc => (doc.id))
				}
			} catch (error) {
				console.error(error)
			} finally {
				this.loading = false
			}
		},
		async addMembers() {
			this.updating = true
			this.closeOnClickModal = false
			try {
				await this.$store.dispatch('editGroup', { participants : this.form.users, roomId : this.roomId })
				this.success = true
			} catch (error) {
				this.error = true
				console.log(error)
			} finally {
				this.updating = false
				this.closeOnClickModal = true
			}
		}
		
	},
}
</script>

<style scoped>
.list {
	height: inherit;
	overflow-y: auto;
	max-height: 200px;
	margin-top: calc(var(--column) * 2);
}

.el-checkbox-group {
	display: flex;
	flex-direction: column;
}

.el-checkbox-group .el-checkbox {
	margin-bottom: var(--column);
}

.icon {
	margin: 0 auto;
	margin-bottom: var(--column);
}

h2, p {
	text-align: center;
}
</style>