const state = {
  extensionsDashboardList: [],
  refExtensionDashboard: null,
  organizationListen: 0
}
const getters = {
  getExtensionsDashboardList: (state) => state.extensionsDashboardList,
  getRefExtensionDashboard: (state) => state.refExtensionDashboard,
  getOrganizationListen: (state) => state.organizationListen
}
const mutations = {
  UPDATE_EXTENSIONS_DASHBOARD_LIST: (state, extensions) => state.extensionsDashboardList = extensions, 
  UPDATE_REF_EXTENSIONS_DASHBOARD: (state, refOrganization) => state.refExtensionDashboard = refOrganization,
  UPDATE_ORGANIZATION_LISTEN: (state, organizationId) => state.organizationListen = organizationId
}
const actions = {
  startListenExtensionsDashboard ({ commit, rootGetters }, organizationId)  {
    const firebase = rootGetters.getFirebase;
    if(state.refExtensionDashboard !== null){
      state.refExtensionDashboard.off();
    }
    commit('UPDATE_REF_EXTENSIONS_DASHBOARD', firebase.database().ref(`organization/${organizationId}/extensions_dashboard`));
    state.refExtensionDashboard.on('value', (snap) => {
      const extensions = snap.val();
      if(extensions){
        const keys = Object.keys(extensions);
        const extensionsArray = keys.map((key) => {
          return {
            extension: key,
            name: extensions[key].name,
            status:'status' in extensions[key] ? extensions[key].status : 'unavailable'
          }
        })
        commit('UPDATE_EXTENSIONS_DASHBOARD_LIST', extensionsArray);
      }else {
        commit('UPDATE_EXTENSIONS_DASHBOARD_LIST', []);
      }
    })
  },
  stopLitenExtensionsDashboard({ commit }){
    if(state.refExtensionDashboard !== null){
      state.refExtensionDashboard.off();
      commit('UPDATE_EXTENSIONS_DASHBOARD_LIST', []);
    }
  }
}


export default{
  actions,
  state,
  getters,
  mutations,
  namespaced : true,
}