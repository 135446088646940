const state = {
  contacts: [],
  alreadyListening : false,
  favorites : [],
  hiddenProps : [
    "hasChat",
    "canEdit",
    "@id",
    "@type",
    "name",
    "lastname",
    "phone",
    "props",
    "type",
    "visiblename",
    "extension",
    "id",
    "videocallRoomId",
    "visibleExtension",
    "status",
    "fullname"
  ]
}

const getters = {
  getHiddenProps : (state) => state.hiddenProps,
  getContacts: (state) => state.contacts,
  getFavorites : (state) => state.favorites,
  getContactById: (state) => (id) => state.contacts.find(contact => contact.id === id),
  getFavoriteById: (state) => (route) => state.contacts.find(contact => contact.route === route),
  getAllContacts : (state, getters, rootState, rootGetters) => {
    const user = rootGetters["getUser"];

    const users = rootGetters['users/getData']
    .filter((u) => u.uid !== user.uid)
    .map(user => {
      const u = rootGetters["_users/getUserByUid"](user.uid);

      const chat = u ? rootGetters["corpochat/getChatByUser"](u) : null
      return {
        ...user,
        ['@id'] : '/api/users/' + user.id,
        hasChat : u ? true : false,
        videocallRoomId : chat ? chat.roomId : new Date().getTime(),
        visiblename : user.name + " " + user.lastname,
        favorite_id : null,
        isFavorite : false,
        type : "HiperMe",
        visibleExtension : user.extensions.length > 0 ? user.extensions[0].extension : "",
        props : [{ type: "email", value: user.email }],
        status: u ? u.status : "disconnected",
      }
    });

    const organizationContacts = rootGetters['organization_contacts/getData'].map(contact =>  ({
      ...contact,
      visiblename : `${contact.name} ${contact.lastname}`,
      visibleExtension : contact.extension,
      canEdit : user.roles.includes("ROLE_ADMIN") || user.roles.includes("ROLE_MANAGER"),
      //canEdit : false,
      type : 'Organization',
      status : 'none',
      props : Object.keys(contact).map((index) => {
        if (!state.hiddenProps.includes(index)) {
          const value = index !== 'organization' ? contact[index] : contact.organization.name
          return { type : index, value : value }
        }
      })
    }));

    const contacts = state.contacts.map((_contact) => {
      _contact['@id'] = "/api/contacts/" + _contact.id
      _contact.picture = ''
      _contact.props = [],
      _contact.type = 'Personal'
      Object.keys(_contact).forEach((index) => {
        if (!state.hiddenProps.includes(index)) {
          const value = index !== 'organization' ? _contact[index] : _contact.organization.name
          _contact.props.push({ type : index, value : value })
        }
      })
      return _contact
    })

    const data = [...users, ...organizationContacts, ...contacts];

    return data.map((contact) => {
      contact.favorite_id = null
      contact.isFavorite = false
      contact.phones = [
        { label : 'extension', value : contact.visibleExtension },
        { label : 'phone', value : contact.phone },
        { label : 'phone_one', value : contact.phone_one },
        { label : 'phone_two', value : contact.phone_two },
      ].filter(phone => phone.value)
      const favorite = getters.getFavorites.find(favorite => favorite.route === contact['@id'])
      if (favorite) {
        contact.favorite_id = favorite.id
        contact.isFavorite = favorite.isFavorite
      }
      return contact
    })
  },
  getContactByVisibleExtension: (state, getters) => (extension)  => getters.getAllContacts.find(contact => {
    return contact.visibleExtension == extension
  }),
  getContactByCallerId: (state, getters) => (callerid) => {
    return getters.getAllContacts.find((contact) => {
      return contact.phones.find((phone) => phone.value == callerid);
    });
  }
}

const mutations = {
  ADD_CONTACT: (state, contact) => state.contacts.push(contact),
  UPDATE_CONTACT: (state, contact) => state.contacts = state.contacts.map(c => c.id === contact.id ? contact : c),
  REMOVE_CONTACT: (state, contact) => state.contacts = state.contacts.filter(c => c.id !== contact.id),
  ADD_FAVORITE: (state, favorite) => state.favorites.push(favorite),
  UPDATE_FAVORITE: (state, favorite) => state.favorites = state.favorites.map(f => f.id === favorite.id ? favorite : f),
  REMOVE_FAVORITE: (state, favorite) => state.favorites = state.favorites.filter(f => f.id !== favorite.id),
  SET_ALREADY_LISTENING : (state, value) => state.alreadyListening = value
}

const actions = {
  listenContacts({ rootGetters, commit }) {

    if (state.alreadyListening) {
      return
    }

    commit('SET_ALREADY_LISTENING', true)

    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase

    firebase.firestore().collection('contacts').doc(user.uid).collection('favorites').onSnapshot((snap) => {
      snap.docChanges().forEach((change) => {
        const favorite = { ...change.doc.data(), id : change.doc.id};

        if (change.type === "added") {
          commit('ADD_FAVORITE', favorite)
        }

        if (change.type === "modified") {
          commit('UPDATE_FAVORITE', favorite)
        }

        if (change.type === "removed") {
          commit('REMOVE_FAVORITE', favorite)
        }
      })
    })

    firebase.firestore().collection('contacts').doc(user.uid).collection('contacts').onSnapshot((snap) => {
      snap.docChanges().forEach((change) => {
        const contact = { ...change.doc.data(), 
          id: change.doc.id, 
          canEdit: true, 
          fullname: `${change.doc.data().name} ${change.doc.data().lastname}`, 
          visiblename: `${change.doc.data().name} ${change.doc.data().lastname}`, 
          status: 'none', 
          videocallRoomId: null, 
          type: "Personal", 
          props: [],
          visibleExtension : change.doc.data().extension,
          favorite : change.doc.data().favorite,
          email: change.doc.data().email ?? "",
          company: change.doc.data().company ?? ""
        }

        if (change.type === "added") {
          commit('ADD_CONTACT', contact)
        }

        if (change.type === "modified") {
          commit('UPDATE_CONTACT', contact)
        }

        if (change.type === "removed") {
          commit('REMOVE_CONTACT', contact)
        }
      })
    })
  },
  addContact({ rootGetters }, contact) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    contact.favorite = false
    return firebase.firestore().collection('contacts').doc(user.uid).collection('contacts').doc().set(contact)
  },
  updateContact({ rootGetters }, { id, contact }) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase

    if (state.contacts.find(contact => contact.id === id)) {
      return firebase.firestore().collection('contacts').doc(user.uid).collection('contacts').doc(id).update(contact)
    }

    return firebase.firestore().collection('contacts').doc(user.uid).collection('contacts').doc(id).set({
      name: '',
      lastname: '',
      phone: contact.phone,
      extension: contact.extension,
      email: contact.email,
      favorite : contact.favorite
    })
  },
  deleteContact({ rootGetters }, id) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    return firebase.firestore().collection('contacts').doc(user.uid).collection('contacts').doc(id).delete()
  },
  findById({ rootGetters }, id) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    return firebase.firestore().collection('contacts').doc(user.uid).collection('contacts').doc(id).get()
  },
  async toggleFavorite({ rootGetters }, row) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    const ref = firebase.firestore().collection('contacts').doc(user.uid).collection('favorites');
    const snap = await firebase.firestore().collection('contacts').doc(user.uid).collection('favorites').where("route", "==", row['@id']).get()
    
    if (snap.empty) {
      ref.doc().set({
        route : row['@id'],
        isFavorite : true
      })
    } else {
      const doc = snap.docs[0]
      ref.doc(doc.id).update({
        isFavorite : !row.isFavorite
      })
    }

  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}