<template>
  <div>
    <TheKeypadContainer :go-back="goBack">
      <template v-slot:container>
        <div class="information" v-if="loading">
          <p>{{ $t("keypad.searching-contacts") }}</p>
          <i class="fas fa-circle-notch fa-spin"></i>
        </div>
        <ul class="list">
          <li @click="dialTo(extension.extension)" v-for="extension in extensions" :key="extension.id" >
            <p>{{ extension.name }} <br>({{ extension.extension }}) </p>
          </li>
        </ul>
      </template>
    </TheKeypadContainer>
  </div>
</template>

<script>
import TheKeypadContainer from './TheKeypadContainer';
import ExtensionApi from '@/api/extensions';
export default {
  name : "ThePhonebook",
  props : {
    goBack  : {
      required : false,
    }
  },
  data() {
    return {
      loading : false,
      extensions : []
    }
  },
  methods : {
    fetch() {
      this.loading = true;
      ExtensionApi.fetchExtensionData().then((response) => {
        this.loading = false;
        this.extensions = response.data["hydra:member"];
      });
    },
    dialTo(value) {
      this.$emit('dialTo', value);
    }
  },
  created() {
    this.fetch();
  },
  components : {
    TheKeypadContainer
  }
}
</script>

<style scoped>

li {
  color: var(--blue-grey);
  cursor: pointer;
  font-size: 14px;
  list-style: none;
}

</style>