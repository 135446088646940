<template>
  <div class="main-content" v-loading="loading">
    <button class="back" @click="$router.push({ name: 'contact-me-pbx-queues' })"><i class="fas fa-angle-left"></i> {{ $t('common.go-back') }}</button>
    <h2>{{ !id ? $t("contact-me.pbx.queues.create") : $t("contact-me.pbx.queues.edit") }}</h2>
    <el-form label-position="top" size="large" :model="form" :rules="rules" v-if="status === 'form'">
      <el-tabs v-model="tab">
        <el-tab-pane :label="$t('contact-me.pbx.queues.basic-information')" name="basic" >
          <h3>{{ $t('contact-me.pbx.queues.basic-information') }}</h3>
          <el-form-item :label="$t('contact-me.pbx.queues.name')" required :error="errors.name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.queues.number')" required :error="errors.number">
            <el-input v-model="form.number" type="number" :readonly="!id ? false : true" :class="{ 'disabled' : id !== 0 }"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.queues.callerid')" :error="errors.cid">
            <el-input v-model="form.cid"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.queues.maxwait')" :error="errors.maxwait">
            <el-input v-model="form.maxwait" type="number"></el-input>
          </el-form-item>
          <div class="queues-row">
            <el-form-item :label="$t('contact-me.pbx.queues.ringstrategy')" :error="errors.strategy">
              <el-select v-model="form.strategy" :disabled="!allValuesParsed">
                  <el-option v-for="strategy in strategies" :key="strategy.value" :label="$t('contact-me.pbx.strategies.' + strategy.value)" :value="strategy.value" ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.queues.agent-announcement')" :error="errors.agent_announcement">
              <el-select v-model="form.agent_announcement" filterable :loading="loadingSystemRecording"  :disabled="!allValuesParsed">
                  <el-option v-for="recording in systemRecordings" :key="recording.id" :label="recording.name" :value="recording.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.queues.join-announcement')" :error="errors.join_announcement">
              <el-select v-model="form.join_announcement" filterable :loading="loadingSystemRecording"  :disabled="!allValuesParsed">
                  <el-option v-for="recording in systemRecordings" :key="recording.id" :label="recording.name" :value="recording.id" ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.queues.music-hold')" :error="errors.music">
              <el-select v-model="form.music" filterable  :disabled="!allValuesParsed">
                  <el-option v-for="musicCategory in musicCategories" :key="musicCategory.name" :label="musicCategory.name" :value="musicCategory.name" ></el-option>
                </el-select>
            </el-form-item>
          </div>
          <el-form-item :label="$t('contact-me.pbx.queues.is-recording-calls')">
            <el-switch v-model="form.recording" :active-text="$t('common.yes')" :inactive-text="$t('common.no')"></el-switch>
          </el-form-item>
          <el-button @click.native="setTab('members')" type="primary" :disabled="!basicInformationIsValid">{{ $t("common.next") }}</el-button>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.queues.members')" name="members" :disabled="!basicInformationIsValid">
          <h3>{{ $t('contact-me.pbx.queues.members') }}</h3>
          <p>{{ $t('contact-me.pbx.queues.add-extensions-to-the-queue') }}</p>
            <el-transfer
              filterable
              v-model="form.members"
              :data="extensions"
              :titles="[ $t('contact-me.pbx.queues.add-extensions-list-free') ,  $t('contact-me.pbx.queues.add-extensions-list-in-use') ]">
            </el-transfer>
            <el-button @click.native="setTab('basic')">{{ $t("common.back") }}</el-button>
            <el-button @click.native="setTab('advanceConfiguration')" type="primary">{{ $t("common.next") }}</el-button>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.queues.advance-configuration')" name="advanceConfiguration" :disabled="!basicInformationIsValid">
          <h3>{{ $t('contact-me.pbx.queues.advance-configuration') }}</h3>
          <div class="queues-row">
            <el-form-item :label="$t('contact-me.pbx.queues.timeout')">
              <el-select v-model="form.timeout">
                <el-option :value="0" :label="$t('common.unlimited' )" />
                <el-option v-for="number, index in combo_numbers(1, 60)" :key="index" :label="getSecondsLabel(number)" :value="number"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.queues.retry')">
              <el-select v-model="form.retry">
                <el-option value="none" :label="$t('common.no-retry' )" />
                <el-option v-for="number, index in combo_numbers(0, 20)" :key="index" :label="getSecondsLabel(number)" :value="number" ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.queues.wrap-up-time')">
              <el-select v-model="form.wrapuptime">
                <el-option v-for="number, index in combo_numbers(0, 60)" :key="index" :label="getSecondsLabel(number)" :value="number"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.queues.queue-weight')">
              <el-select v-model="form.weight">
                <el-option v-for="number, index in combo_numbers(0, 10)" :key="index" :label="number" :value="number"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item :label="$t('contact-me.pbx.queues.skip-busy-agents')">
            <el-switch v-model="form.cwignore" active-value="1" inactive-value="0" :active-text="$t('common.yes')" :inactive-text="$t('common.no')"></el-switch>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.queues.event-when-called')">
            <el-switch v-model="form.eventwhencalled" :active-text="$t('common.yes')" :inactive-text="$t('common.no')" active-value="yes" inactive-value="no"></el-switch>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.queues.autofill')">
            <el-switch v-model="form.autofill" active-value="yes" inactive-value="no" :active-text="$t('common.yes')" :inactive-text="$t('common.no')"></el-switch>
          </el-form-item>
          <el-button @click.native="setTab('members')">{{ $t("common.back") }}</el-button>
          <el-button @click.native="setTab('announce-call-position')" type="primary">{{ $t("common.next") }}</el-button>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.queues.caller-position-announcements')" name="announce-call-position" :disabled="!basicInformationIsValid">
          <h3>{{ $t('contact-me.pbx.queues.caller-position-announcements') }}</h3>
          <el-form-item :label="$t('contact-me.pbx.queues.announce-position')">
            <el-switch v-model="form.announceposition" :active-text="$t('common.yes')" :inactive-text="$t('common.no')"></el-switch>
          </el-form-item>
          <div>
            <el-form-item :label="$t('contact-me.pbx.queues.frequency')">
              <el-select v-model="form.announcefreq">
                <el-option v-for="number, index in combo_numbers(0, 1200, 15)" :key="index" :value="number" :label="numberToSeconds( number )"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.queues.announce-hold-time')">
              <el-select v-model="form.announceholdtime">
                <el-option v-for="value in combo_announcement" :key="value" :label="$t('common.' + value)" :value="value" ></el-option>
              </el-select>
            </el-form-item>
            <el-button @click.native="setTab('advanceConfiguration')">{{ $t("common.back") }}</el-button>
            <el-button @click.native="setTab('periodic-announcements')" type="primary">{{ $t("common.next") }}</el-button>
          </div>
        </el-tab-pane>
        <!-- check-->
        <el-tab-pane :label="$t('contact-me.pbx.queues.periodic-announcements')" name="periodic-announcements" :disabled="!basicInformationIsValid">
          <el-form-item :label="$t('contact-me.pbx.queues.periodic-ivr')">
            <el-select v-model="form.announcemenu">
                <el-option v-for="ivr in ivrs" :key="ivr.id" :label="ivr.name" :value="ivr.id" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.queues.periodic-frequency')">
            <el-select v-model="form.pannouncefreq">
              <el-option v-for="number, index in combo_numbers(0, 1200, 15)" :key="index" :value="number" :label="numberToSeconds( number )">
              </el-option>
            </el-select>
        </el-form-item>
        <div>
            <el-button @click="setTab('announce-call-position')">{{ $t("common.back") }}</el-button>
            <el-button @click.native="setTab('action-no-answer')" type="primary">{{ $t("common.next") }}</el-button>
        </div>
        </el-tab-pane>
        <!-- check-->
        <el-tab-pane :label="$t('contact-me.pbx.queues.action-no-answer')" name="action-no-answer" :disabled="!basicInformationIsValid">
          <el-form-item :label="$t('contact-me.pbx.queues.type')">
            <el-select v-model="form.dest.type" @change="form.dest.id = ''; form.dest.name=''">
                <el-option v-for="(dest, index) in noAnswerDestination" :key="index" :value="dest.toLowerCase()" :label="dest"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.queues.value')">
            <el-select v-model="form.dest.id" @change="getComboText">
              <el-option v-for="(item, index) in destinations" :key="index" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
        </el-form-item>
        <div>
            <el-button @click="setTab('periodic-announcements')">{{ $t("common.back") }}</el-button>
            <el-button @click.native="setTab('finish')" type="primary">{{ $t("common.next") }}</el-button>
          </div>
        </el-tab-pane>
        
        <el-tab-pane label="Finish" name="finish" :disabled="!basicInformationIsValid">
          <h3>Review</h3>
          <the-details :queue="{ ...form }"></the-details>
          <el-alert
            v-if="Object.keys(errors).length > 0"
            title="You have some errors, check the forms and try again"
            type="error">
          </el-alert>
          <div>
            <el-button @click="setTab('action-no-answer')">{{ $t("common.back") }}</el-button>
            <el-button type="primary" @click="submit">Save</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class="message" v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 'saved'"/>
        <img src="@/img/error.svg" v-else/>
        <p> {{ message }} </p>
        <div class="buttons">
          <router-link :to="{ name: 'contact-me-pbx-queues' }">{{ $t("common.go-back-to-the-list") }}</router-link>
        </div>
      </div>
    </div>    
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TheDetails from "./TheDetails.vue";

export default {
  props: {
    entity: {
      required: false,
      default: () => ({
        name: "",
        number: "",
        maxwait: 0,
        cid: "",
        strategy: "ringall",
        agent_announcement: "",
        join_announcement: "",
        music: "default",
        recording: false,
        members: [],
        timeout: 0,
        retry: 0,
        wrapuptime: 0,
        cwignore: false,
        weigth: "",
        skip_busy: 1,
        eventwhencalled: "no",
        autofill: false,
        pannouncefreq: null,
        announceholdtime: null,
        announceposition: null,
        announcefreq: null,
        announcemenu: null,
        dest: {
          id: "",
          type: "",
        },
        ringing: 0
      })
    },
    id: {
      required: false,
      default: 0
    }
  },
  data() {
    return {
      form: this.entity,
      tab: "basic",
      errors: {},
      loading: false,
      status: "form",
      rules: {},
      loadingAgents: true,
      loadingSystemRecording: true,
      loadingMusicCategories: true,
      loadingExtensions: true,
      loadingIvrs: true,
      allValuesParsed: false,
      combo_announcement: [ 'yes', 'no', 'once' ],
      combo_numbers( min, max, interval = 1 ){
        const length = (max - min) / interval + 1;
        return Array.from({ length }, (_, i) => min + i * interval);
      },
      values:{
        members: null
      }
    }
  },
  methods: {
    ...mapActions("pbx_destinations", { fetchDestinations: "all" }),
    setTab(value) {
      this.tab = value;
    },
    getSecondsLabel( num ){
      return `${num} ${ num == 1 ? this.$t("common.second") : this.$t("common.seconds") }`;
    },
    getComboText( ) {
      const items = this.noAnswerDestinations[this.form.dest.type.toLowerCase()];
      const item = items.filter(i => i.id ==  this.form.dest.id );

      this.form.dest.name = item[0] ? item[0].name : ""; 
      return this.form.dest.name;
    },
    numberToSeconds(value) {
      const minutes = ~~(value / 60);
      const seconds = (value % 60 ? value % 60 : '0');
      const str_minutes = minutes > 0 ? `${ minutes } ${ minutes == 1 ? this.$t('common.minute') : this.$t('common.minutes') }` : '';
      const str_seconds = seconds > 0 ? `${ seconds } ${ seconds == 1 ? this.$t('common.second') : this.$t('common.seconds') }` : '';
      const str_comma = minutes > 0 && seconds > 0 ? ', ' : '';
      return minutes == 0 && seconds == 0 ? `0 ${ this.$t('common.seconds') }` : `${ str_minutes }${ str_comma }${ str_seconds }`;
		},
    async fetchAgents() {
      this.loadingAgents = true;
      await this.$store.dispatch("pbx_agents/all");
      this.loadingAgents = false;
    },
    async fetchSystemRecordings() {
      this.loadingSystemRecording = true;
      await this.$store.dispatch("pbx_system_recordings/all");
      this.loadingSystemRecording = false;
    },
    async fetchMusicCategories() {
      this.loadingMusicCategories = true;
      await this.$store.dispatch("pbx_music_categories/all");
      this.loadingMusicCategories = false;
    },
    async fetchExtensions() {
      this.loadingExtensions = true;
      await this.$store.dispatch("pbx_extensions/all");
      this.loadingExtensions = false;
    },
    async fetchIvrs() {
      this.loadingIvrs = true;
      await this.$store.dispatch("pbx_ivrs/all");
      this.loadingIvrs = false;
    },
    async fetchDestinations() {
      this.loadingDestinations = true;
      await this.$store.dispatch("pbx_destinations/all");
      this.loadingDestinations = false;
    },
    async submit() {
      this.loading = true;
      try{
        const action = !this.id ? "create" : "update";
        if( this.form.autofill == "no" ) this.$delete( this.form, 'autofill' );
        if( this.form.eventwhencalled == "no" ) this.$delete( this.form, 'eventwhencalled' );

        const payload = !this.id ? this.form : { resourceId: this.id, payload: this.form };
        const response = await this.$store.dispatch(`pbx_queues/${action}`, payload);
        this.status = "saved";
        console.log(response);
      }catch( error) {
        if (error.response) {
          const { data } = error.response;
          this.errors = data.errors;
        }
      }finally{
        this.loading = false;
      }
    },
    toNumber( index ){
      if( isNaN( parseInt( this.form[ index ] ) ) ) return this.form[index];
      this.form[index] = parseInt( this.form[ index ] );
    },
    parseFormValues( ){
      this.form.agent_announcement = this.form.agent_announcement?.id ?? null;
      this.form.join_announcement = this.form.join_announcement?.id ?? null;

      if( this.form.dest ) this.form.dest.type = this.form.dest.type.toLowerCase( );  
      if( this.form.retry != "none" ) this.toNumber( 'retry' );

      this.toNumber( 'timeout' );
      this.toNumber( 'weight' );
      this.toNumber( 'wrapuptime' );

      this.toNumber( 'announce-frequency' );
      this.toNumber( 'periodic-announce-frequency' );

      //mutacion
      this.$set( this.form, 'pannouncefreq', this.form['periodic-announce-frequency'] ),
      this.$set( this.form, 'announcefreq', this.form['announce-frequency'] ),
      this.$set( this.form, 'announceholdtime', this.form['announce-holdtime'] );
      this.$set( this.form, 'announceposition', this.form['announce-position'] == 'yes' );
      this.$set( this.form, 'cwignore', this.form.skip_busy );
  
      this.$delete( this.form, 'periodic-announce-frequency' );
      this.$delete( this.form, 'announce-frequency' );
      this.$delete( this.form, 'announce-holdtime' );
      this.$delete( this.form, 'announce-position' );
      this.$delete( this.form, 'skip_busy' );

      this.form.members = this.form.members.map( m => m.id ?? m );
      this.allValuesParsed = true;
    }
  },
  computed: {
    strategies() {
      return this.$store.getters["pbx_extensions/getRingStrategies"]
    },  
    systemRecordings() {
      return this.$store.getters["pbx_system_recordings/getDataAsArray"]
    },
    musicCategories() {
      return this.$store.getters["pbx_music_categories/getDataAsArray"]
    },
    ivrs(){
      return this.$store.getters["pbx_ivrs/getDataAsArray"]
    },
    extensions() {
      return ( 
        this.$store.getters["pbx_extensions/getDataAsArray"]
        .map((extension) => (
          {
            key: extension.extension,
            label: `${extension.name} (${extension.extension})`
          }
        ))
      ).concat(
        this.$store.getters["pbx_agents/getDataAsArray"]
        .map((agent) => {
          return (
          {
            key: 'A'+agent.number,
            label: `${agent.name} (Agent/${agent.number})`
          }
        )})
      );
    },
    noAnswerDestination() {
      return Object.keys(this.$store.getters["pbx_destinations/getData"]);
    },
    noAnswerDestinations() {
      return this.$store.getters["pbx_destinations/getData"];
    },
    destinations() {
      return this.noAnswerDestinations[this.form.dest.type] ?? [];
    },
    getFormMembersId( ){
      return this.form.members.map( m => m.id );
    },
    message() {
      if (this.status == "saved") {
        return this.$t("common.saved")
      }
      return this.$t("common.not-saved")
    },
    basicInformationIsValid() {
      return this.form.name && this.form.number && (
        !this.loadingSystemRecording && !this.loadingAgents && ! this.loadingMusicCategories && !this.loadingExtensions && !this.loadingIvrs && !this.loadingDestinations && this.allValuesParsed
      );
    }
  },
  components: {
    TheDetails
  },
  async created() {
    await this.fetchDestinations();
    await this.fetchIvrs();
    await this.fetchAgents();
    await this.fetchExtensions();
    await this.fetchMusicCategories();
    await this.fetchSystemRecordings();
    this.parseFormValues( );
  }
}
</script>

<style scoped>
.back {
  outline: none;
  border: none;
  background-color: transparent;
}

.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.queues-row {
  display: inline-flex; 
  width: 100%; 
  justify-content: space-between;
}

:deep( .el-transfer ){
  margin-bottom: 20px;
}

:deep( .el-transfer-panel ){
  width: 32%;
  min-width: 250px;
}

:deep( .disabled .el-input__inner ){
  background: #F5F7FA;
  color: #C3C4CC;
  border-color: #E4E7ED;
}
</style>