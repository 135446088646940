import store from '../store';
import http from './http';

import cookie from '@/plugins/cookie';

const authInterceptor = (config) => {
  const token = store.getters['zoho/getAuthToken']

  if (token && config.url !== '/zohoproxy/oauth/v2/token') {
    config.headers['Zoho-Authorization'] = `Zoho-oauthtoken ${token}`;
  }

  return config;
}

const zohoApiDomainInterceptor = (config) => {
  const zohoApiDomain = (config.url !== '/zohoproxy/oauth/v2/token') ? store.getters['zoho/getApiDomain'] : store.getters['zoho/getAccountDomain'];
  if (zohoApiDomain) {
    config.headers['Zoho-Api-Domain'] = zohoApiDomain;
  }
  return config;
}

const zohoAuthInterceptor = async (error) => {
  const originalRequest = error.config;
  if ((error.response.data.code === 'INVALID_TOKEN' || error.response.data.code === 'AUTHENTICATION_FAILURE') && error.response.data) {
    try {
      await refreshToken();
      originalRequest._retry = true;
      return http(originalRequest);
    } catch (error) {
      console.log(error);
    }
  }
  return Promise.reject(error)
}

const refreshToken = async () => {
  try {
    const config = {
      client_id: process.env.VUE_APP_ZOHO_CLIENT_ID,
      client_secret: process.env.VUE_APP_ZOHO_CLIENT_SECRET,
    }

    const params = {
      grant_type: 'refresh_token',
      client_id: config.client_id,
      client_secret: config.client_secret,
      refresh_token: store.getters['zoho/getRefreshToken']
    }

    const { data } = await http.post('/zohoproxy/oauth/v2/token', null, { params });
    if (data.error) {
      return Promise.reject(data.error);
    }

    store.dispatch('zoho/setZohoTokens', data)
    return data;

  } catch (error) {
    return Promise.reject(error);
  }
}

http.interceptors.request.use(authInterceptor);
http.interceptors.request.use(zohoApiDomainInterceptor);
http.interceptors.response.use(undefined, zohoAuthInterceptor);

export default {
  fetchDataCenters() {
    return http.get('/zohoproxy/oauth/serverinfo');
  },
  fetchAccessAndRefreshToken(body, params) {
    return http.post('/zohoproxy/oauth/v2/token', body, { params });
  },
  callNotify(body, params, headers) {
    return http.post('/zohoproxy/phonebridge/v3/callnotify', body, { params, headers });
  },
  toggleIntegration(enabled = true, params) {
    if (enabled) {
      return http.post(`/zohoproxy/phonebridge/v3/integrate`, null, { params });
    }
    return http.delete(`/zohoproxy/phonebridge/v3/integrate`, null, { params });
  },
  toggleClick2Dial(enabled = true) {
    const token = cookie.getCookie('hiper_token');
    const params = {
      clicktodialuri: process.env.VUE_APP_API_HOST + '/zoho/action/call',
      authorizationparam: {
        name: 'Authorization',
        value: `Bearer ${token}`
      }
    }

    if (enabled) {
      return http.post(`/zohoproxy/phonebridge/v3/clicktodial`, null, { params });
    }
    return http.delete(`/zohoproxy/phonebridge/v3/clicktodial`, null, { params });
  },
  callControls() {
    const token = cookie.getCookie('hiper_token');
    return http.post(`/zohoproxy/phonebridge/v3/callcontrol`, null, {
      params: {
        answeruri: process.env.VUE_APP_API_HOST + '/zoho/action/answer',
        hungupuri: process.env.VUE_APP_API_HOST + '/zoho/action/hangup',
        muteuri: process.env.VUE_APP_API_HOST + '/zoho/action/mute',
        unmuteuri: process.env.VUE_APP_API_HOST + '/zoho/action/unmute',
        holduri: process.env.VUE_APP_API_HOST + '/zoho/action/hold',
        unholduri: process.env.VUE_APP_API_HOST + '/zoho/action/unhold',
        keypressuri: process.env.VUE_APP_API_HOST + '/zoho/action/keypress',
        authorizationparam: {
          name: 'Authorization',
          value: `Bearer ${token}`
        }
      }
    })
  }
}