import http from '../api/http'

const getLastPage = (view) => ( (view['hydra:last']) ? parseInt(view['hydra:last'].split('=').pop()) : 1 );

const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}

const isObject = (object) => {
  return object != null && typeof object === 'object';
}

export const createStore = (resource) => {

  const parsedUrl = /\//.test(resource) ? resource.replace("/","") : resource;

  const state = {
    item : {},
    data : [],
    pagination : {
      page : 1,
      totalPages : 0,
      totalItems : 0
    },
    lastQueryParams : {},
    lastFetched : null,
    fetching : false,
  }

  const getters = {
    getData : (state) => state.data,
    getPagination : (state) => state.pagination,
    getFetching : (state) => state.fetching,
    getDataById: (state) => id => state.data.find(item => item.id === id)
  }

  const mutations = {
    SET_ITEM : (state, item) => (state.item = item),
    SET_DATA : (state, data) => (state.data = data),
    STORE_DATA : (state, data) => (state.data.push(data)),
    UPDATE_DATA : (state, data) => (state.data = state.data.map(item => item.id === data.id ? data : item)),
    DESTROY_DATA : (state, id) => (state.data = state.data.filter(item => item.id !== id)),
    SET_PAGINATION : (state, pagination) => (state.pagination = pagination),
    SET_LAST_FETCHED : (state, value) => (state.lastFetched = value),
    TOGGLE_FETCHING: (state) => (state.fetching = !state.fetching),
    SET_LAST_QUERYPARAMS : (state, value) => (state.lastQueryParams = value),
    ADD_DATA: (state, data) => (state.data = [...state.data, ...data])
  }

  const actions = {
    one({ commit }, id) {
      return new Promise((resolve, reject) => {
        http.get(`/${resource}/${id}`)
        .then((response) => {
          commit('SET_ITEM', response.data)
          resolve(response.data);
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    all({ commit }, queryParams = { page : 1 }) {
      return new Promise((resolve) => {
        const date = state.lastFetched
        if (date) {
          date.setMinutes(date.getMinutes() + 5)
        }

        if (date !== null && deepEqual(queryParams, state.lastQueryParams)) {
          if (new Date().getTime() < date.getTime()) {
            return resolve(state.data)
          }
        }

        commit('TOGGLE_FETCHING')
        commit('SET_LAST_QUERYPARAMS', queryParams)
        http.get(`/${resource}`, {
          params : queryParams
        })
        .then((response) => {
          commit('TOGGLE_FETCHING')
          commit('SET_DATA', response.data['hydra:member'])
          commit('SET_LAST_FETCHED', new Date())
          
          commit('SET_PAGINATION', {
            page : queryParams.page,
            totalPages : ('hydra:view' in response.data) ? getLastPage(response.data['hydra:view']) : 1, 
            totalItems : response.data['hydra:totalItems'],
            next: ('hydra:view' in response.data && 'hydra:next' in response.data["hydra:view"]) ? response.data["hydra:view"]["hydra:next"].replace("/api", "") : null,
          })

          return resolve(response.data['hydra:member'])
        })
      })
    },
    loadNextPage({ commit, getters }) {
      return new Promise((resolve) => {
        commit('TOGGLE_FETCHING')
        const pagination = getters.getPagination;
        if (pagination.next) {
          http.get(`${pagination.next}`)
          .then((response) => {
            commit("ADD_DATA", response.data['hydra:member']);
            commit('SET_PAGINATION', {
              page : null,
              totalPages : ('hydra:view' in response.data) ? getLastPage(response.data['hydra:view']) : 1, 
              totalItems : response.data['hydra:totalItems'],
              next: ('hydra:view' in response.data && 'hydra:next' in response.data["hydra:view"] && response.data["hydra:view"]['hydra:next']) ? response.data["hydra:view"]["hydra:next"].replace("/api", "") : null,
            })
            return resolve()
          })
        } else {
          return resolve()
        }
      })
    },
    create({ commit }, payload) {
      return new Promise((resolve, reject) => {
        http.post(`/${parsedUrl}`, payload)
        .then((response) => {
          commit('STORE_DATA', response.data)
          return resolve(response.data)
        }).catch(error => reject(error))
      })
    },
    update({ commit }, { id, payload}) {
      return new Promise((resolve, reject) => {
        http.put(`/${parsedUrl}/${id} `, payload)
        .then((response) => {
          commit('UPDATE_DATA', response.data)
          return resolve(response.data);
        })
        .catch(error => reject(error))
      })
    },
    destroy({ commit }, id) {
      return http.delete(`${parsedUrl}/${id}`)
      .then(() => {
        commit('DESTROY_DATA', id)
      })
    }
  }

  return {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
  }
}

export default createStore;