<template>
  <el-dialog
    :title="$t('contacts.import')"
    :visible.sync="show"
    width="30%"
  >
    <p>{{ $t('contacts.to-import-contacts') }}</p>
    <el-upload
      class="upload-demo"
      :action="action"
      :headers="headers"
      accept="text/x-vcard"
      :auto-upload="false"
      ref="upload"
      :on-success="success"      
      :on-error="error"
    >
      <el-button size="small" type="primary">{{ $t('common.select-file') }}</el-button>
    </el-upload>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="submit" type="primary">{{ $t('common.import') }}</el-button>
      <el-button size="small" @click="show = false">{{ $t('common.cancel') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
  data() {
    return {
      show: false,
    }
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    submit() {
      this.$refs.upload.submit();
    },
    success() {
      this.$message({
        message: this.$t('contacts.contacts-have-been-imported-successfully'),
        type: 'success',
        duration: 5000,
      });
      this.show = false;
      this.$refs.upload.clearFiles();
    },
    error() {
      this.$message({
        message: this.$t('contacts.an-error-occurred-and-the-contacts-were-not-imported'),
        type: 'success',
        duration: 5000,
      });
      this.show = false;
      this.$refs.upload.clearFiles();
    }
  },
  computed : {
    ...mapGetters(['geToken']),
    action() {
      return `${process.env.VUE_APP_API_HOST}/contacts/import`;
    },
    headers : function() {
      return {
        'Authorization' : `Bearer ${this.geToken}`
      }
    }
  }
};
</script>

<style>
</style>