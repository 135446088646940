import store from '../../store';
import ReportsBase from '@/views/reports/ReportsBase.vue'
import TheUsageReports from '@/views/reports/usage-reports/TheUsageReports'
import TheUsageReportByOrganization from '@/views/reports/usage-reports/TheUsageReportByOrganization'
import TheAgentsDashboard from '@/views/AgentsDashboard'
import TheQueuesDashboard from '@/views/QueuesDashboard'
import TheQueuesDetails from '@/views/reports/queues-reports/TheQueuesDetails'
import TheDashboardSwitcher from '@/views/DashboardsSwitcher'
import TheUserLoginLogoutReport from '@/views/reports/audit/TheUserLoginLogoutReport'
import TheExtensionsDashboard from '@/views/ExtensionsDashboard'
export default [
	{
		name: "Reports",
		path: "/reports",
		component: ReportsBase,
		meta: {
			isInMenu: true,
			title: 'main-menu.reports.title',
			icon: 'fas fa-chart-line',
			type: 'title',
			order: 1,
			access_control: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_RECEPTIONIST']
		},
		children: [
			{
				name: "usage_report",
				path: "/reports/usage-reports",
				meta: {
					isInMenu: true,
					title: "main-menu.reports.usage-report",
					type: 'link',
					access_control: ['ROLE_ADMIN', 'ROLE_MANAGER']
				},
				component: TheUsageReports
			},
			{
				name: "usage_report_by_organization",
				path: "/reports/usage-reports-by-organization",
				component: TheUsageReportByOrganization,
				meta: {
					isInMenu: false
				},
				props: route => ({ id: route.query.id, from: route.query.from, to: route.query.to })
			},
			{
				name: "switchBetweenDashboards",
				path: "/reports/dashboards",
				component: TheDashboardSwitcher,
				meta: {
					title: 'main-menu.reports.dashboard-switcher',
					access_control: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_RECEPTIONIST'],
					requiresAuth: true,
					isInMenu: true,
					type: 'link'
				}
			},
			{
				name: "agentsDashboard",
				path: "/reports/agents-dashboard",
				component: TheAgentsDashboard,
				meta: {
					title: 'main-menu.reportes.dashboard-switcher',
					access_control: ['ROLE_ADMIN', 'ROLE_MANAGER'],
					requiresAuth: true,
					isInMenu: false,
					type: 'link'
				}
			},
			{
				name: "queuesDashboard",
				path: "/reports/queues-dashboard",
				component: TheQueuesDashboard,
				meta: {
					title: 'main-menu.queue-dashboard',
					access_control: ['ROLE_ADMIN', 'ROLE_MANAGER'],
					requiresAuth: true,
					isInMenu: false,
					type: 'link'
				}
			},
			{
				name: "queuesDashboardDetails",
				path: "/reports/queues-dashboard/:organization/:id",
				component: TheQueuesDetails,
				meta: {
					access_control: ['ROLE_ADMIN', 'ROLE_MANAGER'],
					requiresAuth: true,
					isInMenu: false,
					type: 'link'
				},
				beforeEnter: (to, from, next) => {
					const id = to.params.id;
					if (!store.getters["getQueueDashboardFromList"](id)) {
						return next({ name : "queuesDashboard"})
					}
					return next();
				}
			},
			{
				name: "extensionsDashboard",
				path: "/reports/extensions-dashboard",
				component: TheExtensionsDashboard,
				meta: {
					title: 'main-menu.extensions-dashboard',
					access_control: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_RECEPTIONIST'],
					requiresAuth: true,
					isInMenu: false,
					type: 'link'
				}
			},
			{
				name: "users-audit",
				path: "users-audit",
				meta: {
					isInMenu: true,
					title: 'main-menu.reports.users-audit',
					access_control: ['ROLE_ADMIN', 'ROLE_MANAGER'],
					requiresAuth: true
				},
				component: TheUserLoginLogoutReport
			},
		]
	}
]