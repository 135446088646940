<template>
  <div class="keypad">
    <div v-if="viewKeypad">
      <div v-if="isRegistered">
        <div>
          <el-input v-model="destinationValue" ref="searchInput" @keydown.enter.native="call" @keydown.native="onKeydown"></el-input>
          <button v-if="mobile" @click.native="onDelete"><i class="fas fa-backspace"></i></button>
        </div>
        <TheNumpad :janus="janus" ref="numpad" @keyClicked="keyClicked"/>
        <div class="other-buttons" v-if="showOthersButtons">
          <el-tooltip effect="dark" :content="$t('keypad.call-history')" placement="bottom">
            <button @click="showCallHistory" class="info" :aria-label="$t('keypad.call-history')"><i class="fas fa-history"></i></button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('keypad.voicemail')" placement="bottom">
            <button @click="callVoicemail" class="info" :aria-label="$t('keypad.voicemail')"><i class="fas fa-voicemail"></i></button>
          </el-tooltip>
          <el-tooltip effect="dark" :content="$t('keypad.phonebook')" placement="bottom">
            <button @click="showViewPhonebook" class="info" :aria-label="$t('keypad.phonebook')"><i class="fas fa-address-book"></i></button>
          </el-tooltip>
        </div>
        <div style="display: flex; justify-content: space-around; width: 175px;">
          <PhoneButton class="keypad-phone-button"  v-if="showOthersButtons" :extension="destinationValue" :displayname="destinationValue" :janus="janus" @click.native="onPhoneButtonClick"/>
        </div>
      </div>
      <div v-else class="extension-not-registered center">
        <i class="fas fa-exclamation-triangle"></i>
        <p>{{ $t("keypad.extension-not-registered") }}</p>
      </div>
    </div>
    <the-call-history v-if="viewCallHistory" :go-back="showKeypad" :janus="janus"/>
    <the-phonebook v-if="viewPhonebook" :go-back="showKeypad" @dialTo="dialTo"></the-phonebook>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheNumpad from './TheNumpad'
import TheCallHistory from './TheCallHistory';
import ThePhonebook from './ThePhonebook.vue';
import PhoneButton from "@/components/webrtc/PhoneButton";
import { mapActions } from 'vuex';
export default {
  name : "TheKeypad",
  props : {
    janus: {
      required: true,
    },
    showOthersButtons : {
      required : false,
      type : Boolean,
      default : true
    }
  },
  data() {
    return {
      mobile : false,
      destinationValue : '',
      isRegistered : false,
      viewKeypad : true,
      viewCallHistory : false,
      viewPhonebook : false
    }
  },
  methods : {
    ...mapActions(['listenCallHistory']),
    showKeypad() {
      this.viewKeypad = true;
      this.viewCallHistory = false;
      this.viewPhonebook = false;
    },
    showCallHistory() {
      this.viewKeypad = false;
      this.viewCallHistory = true;
      this.viewPhonebook = false;
    },
    showViewPhonebook() {
      this.viewKeypad = false;
      this.viewCallHistory = false;
      this.viewPhonebook = true;
    },
    call() {
      this.janus.call(this.destinationValue, this.destinationValue);
      this.closeKeypad();
    },
    callVoicemail() {
      this.janus.call("*97", "Voicemail");
      this.closeKeypad();
    },
    onKeydown(evt) {
      this.$refs.numpad.pressedKey(evt.key);
    },
    dialTo(value) {
      if (!this.viewKeypad) {
        this.showKeypad();
      }
      this.destinationValue = value;
    },
    onDelete() {
      this.destinationValue = this.destinationValue.substring(0, this.destinationValue.length - 1);
    },
    setFocus() {
      setTimeout(() => {
        if (this.isRegistered) {
          this.$refs.searchInput.focus();
        }
      }, 200);
    },
    redial() {
      if (this.destinationValue === "") {
        if (this.history.length > 0) {
          this.destinationValue = this.history.pop().number;
        }
      }
    },
    closeKeypad() {
      this.$emit('closeKeypad');
    },
    onPhoneButtonClick() {
      if (this.destinationValue === "") {
        this.redial();
      } else {
        this.closeKeypad();
      }
    },
    keyClicked(value) {
      this.destinationValue += value
    }
  },
  computed :  {
    ...mapGetters({
      user : 'getUser'
    }),
    history() {
      return this.$store.getters['getCallHistory']('corpo')
    }
  },
  created() {
    setInterval(() => {
      if (this.janus) {
        this.isRegistered = this.janus.isRegistered();
      }
    }, 1000);
  }, 
  components : {
    TheNumpad,
    PhoneButton,
    TheCallHistory,
    ThePhonebook
  }
}
</script>


<style scoped>
.keypad {
  padding: var(--column);
  width: 216px;
}

.other-buttons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--column);
  grid-auto-rows: minmax(10px, auto);
  margin-top: var(--column);
}


button.info.phone{
  background-color: var(--green);
  color: white;
  grid-column: 2;
}

.extension-not-registered {
  color: var(--red);
  text-align: center;
}

.center .fas{
  font-size: 28px;
  margin: calc(var(--column) * 2) 0;
}

</style>
<style>

  .keypad-phone-button {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #49c772;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    margin: 0 auto;
    margin-top: var(--column);
  }

  .keypad-phone-button button.phone-button {
    background-color: #49c772!important;
    margin-top: 0!important;
    color: white;
  }
</style>