<template>
  <el-dialog :visible.sync="internalShow" width="50%" @closed="closeModal">
    <h3>{{ this.$t("contact-me.chat-center.classify-chat-with", { nick: chat.title }) }}</h3>
    <el-form label-position="top">
      <el-form-item :label="$t('contact-me.chat-center.chat-classification')">
        <el-select v-model="chatClassificationsValue" multiple :collapse-tags="true" :loading="loadingClassifications" style="width: 500px">
          <el-option v-for="classification in chatClassifications" :key="classification.id" :value="classification.id" :label="classification.name" :disabled="Boolean(savedClassifications.find(c => c.id === classification.id))"/>
        </el-select>
        <p v-if="loadingClassifications">{{ this.$t("contact-me.chat-center.loading-chat-classifications") }}</p>
      </el-form-item>
    </el-form>
    <el-collapse v-model="activeForm">
      <el-collapse-item v-for="chatClassification in selectedChatClassifications" :key="chatClassification.id" :title="chatClassification.name" :name="chatClassification.id" v-loading="savingForms[chatClassification.id]">
        <el-form :model="inputValues" label-width="120px" label-position="top" class="has-inputs" size="small"  :ref="chatClassification.form.id">
          <TheFormInput :input="input" v-model="inputValues[input.id]" v-for="(input, index) in orderInputs(chatClassification.form.inputs)" :key="index" class="input" :default-value="defaultValues[input.id]" :disabled="savedClassifications.find(c => c.id === chatClassification.id)"/>
          <footer v-if="!savedClassifications.find(c => c.id === chatClassification.id)">
            <el-button @click="handleCancel(chatClassification)" >{{ $t('common.cancel') }}</el-button>
            <el-button @click="handleSaveChatClassification(chatClassification)" type="primary">{{ $t('common.save') }}</el-button>
          </footer>
        </el-form>
      </el-collapse-item>
    </el-collapse>
  </el-dialog>
</template>

<script>
import TheFormInput from '@/components/chat-classification/TheFormInput.vue'
import { mapGetters } from 'vuex';
export default {
  components: {
    TheFormInput
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    roomId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      savingForms: {},
      inputValues: {},
      loadingClassifications: true,
      activeForm: 0,
      internalShow: false,
      chatClassificationsValue: []
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    orderInputs(formInputs) {
      const inputs  = [ ...formInputs]
      inputs.sort((a,b) => a.order - b.order)
      return inputs
    },
    async handleSaveChatClassification(chatClassification) {
      this.savingForms[chatClassification.id] = true
      const inputs = chatClassification.form.inputs.map((input) => {
        const { label, type } = input
        const errors = []
        const value = this.inputValues[input.id]
        const configuration = JSON.parse(input.configuration)

        if (configuration.required && !value) {
          errors.push({ id: input.id, message: 'The value is required'})
          return { label, value, errors, type }
        }

        return { 
          label, 
          value: value instanceof Date ? value.getTime() : value, 
          type,
          id: input.id 
        }
      }).filter(input => input.value)

      if (inputs.some(value => value.errors)) {
        this.savingForms[chatClassification.id] = false
        return
      }

      const classification = {
        id: chatClassification.id,
        formId: chatClassification.form.id, 
        name: chatClassification.name,
        description: chatClassification.description,
        agent: { name: this.agent.name, uid: this.user.uid, number: this.agent.number },
        inputs,
        createdAt: Date.now()
      }

      const chatClassifications = this.savedClassifications ?? []

      chatClassifications.push(classification)

      try {
         await this.$store.dispatch('agentConsoleChat/setChatClassification', { roomId: this.roomId, chatClassifications })
         this.assignChatClassifications()
         this.$message({
            message: this.$t('contact-me.chat-center.the-chat-was-classificated-successfully'),
            type: 'success',
            offset: 150,
            showClose: true
         })
      } finally {
        this.savingForms[chatClassification.id] = false 
      }
    },
    assignChatClassifications() {
      this.chatClassificationsValue = this.savedClassifications.map((v) => v.id)
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      agent: 'getAgent',
    }),
    chat () {
      return this.$store.getters["getChatInChatCenterByRoomId"](this.roomId);
    },
    organizationId () {
      return this.agent.organization_id
    },
    chatClassifications () {
      return this.$store.getters['chatClassifications/getData']
    },
    selectedChatClassifications () {
      return this.chatClassifications.filter(c => this.chatClassificationsValue.includes(c.id))
    },
    lengthOfClassifications() {
      return this.chat?.data?.chatClassifications?.length ?? 0
    },
    defaultValues() {
      const values = {}

      const classifications = this.chat?.data?.chatClassifications ?? []

      if (classifications.length === 0) {
        return values
      }

      this.savedClassifications.map(classification => classification.inputs).flat().forEach((input) => {
        // This converts the Firestore Timestamp instance to a Date instance
        const value = typeof input.value === 'object' && !Array.isArray(input.value) ? input.value.toDate() : input.value
        values[input.id] = value
      })

      return values
    },
    savedClassifications() {
      return this.chat.data.chatClassifications ?? []
    }
  },
  watch: {
    show (newValue) {
      this.internalShow = newValue;
    },
    lengthOfClassifications() {
      this.assignChatClassifications()
    }
  },
  async created () {
    this.loadingClassifications = true
    await this.$store.dispatch('chatClassifications/all', { ['organization.id']: this.organizationId })
    this.loadingClassifications = false

    if (this.chat.data.chatClassifications) {
      this.assignChatClassifications()
    }
  }
}
</script>