<template>
  <div class="login-form" :style="{
    'background-image' : 'url(' + src + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center right',
    'background-size': '100% 100%',
  }">
    <div style="display: flex; align-items: center; flex: 1">
      <div class="form">
        <div style="width: 70%; margin: 0 auto;">
          <h1 class="logo" style="display:none">HiperMe!</h1>
          <img src="@/img/hiperme_isologotipo_miscelanias.svg" class="img-logo">
          <p>
            <b>¡Nos renovamos!</b> Prepárate para conocer la nueva versión de <b>HiperMe!</b> <i>Extensión Remota</i>, con una nueva interfaz y nuevas funcionalidades.
            Agrega la nueva dirección <a href="https://me2.hiperpbx.com">https://me2.hiperpbx.com</a> a tus favoritos e inicia sesión con tus credenciales de siempre.
            <br><br>
            En breve serás redirigido...
          </p>
          <el-button type="success" @click="$router.push({ name: 'Login'})">Ir al login</el-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import http from "@/api/http";
export default {
  data() {
    return {
      src : ""
    }
  },
  created() {
    setTimeout(() => {
      this.$router.push({ name: "Login" });
    }, 20000) 

    http
      .get(`side_images/active`)
      .then((response) => {
        this.src = response.data
          ? process.env.VUE_APP_API_HOST.replace("/api", "") +
            "" +
            response.data.fullPath
          : "";
      })
  },
};
</script>

<style scoped>
.login-form {
  justify-content: space-evenly;
  align-items: stretch;
  display: flex;
  height: 100vh;
  width: 100%;
  background-image: --login-splash
}

.form {
  background: rgba(255,255,255,0.8);
  padding: 0 calc(var(--column) * 2);
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
  height: 100%;
  align-items: center;
  display:flex;
}

h1 {
  display: none;
  text-align: center;
}

h2 {
  text-align: center;
}

.error-message {
  color: var(--red);
  text-align: center;
  margin-top: var(--column);
}

button {
  width: 100%;
}

.login-form .el-button {
  margin-bottom: 1.5em;
}

.recaptcha >>> div:first-child {
  margin: 0 auto;
  margin-bottom: var(--column);
}

.img-logo {
  display:block; 
  margin: 0 auto;
  margin-top:10px;
  max-width: 50%;
}

.text-center {
  padding-bottom: var(--column)
}

@media (max-width: 1024px) {
  .form {
    width: 100%;
  }

  .text-center {
    padding-bottom: calc(var(--column) / 2);
  }

  .recaptcha {
    margin: calc(var(--column) / 2);
  }

}
</style>
