<template>
  <el-dialog
    :visible.sync="visible"
    :append-to-body="true"
    :width="!mobile ? '80%' : '90%'"
  >
    <el-tabs :tab-position="!mobile ? 'left' : 'top'">
      <el-tab-pane :label="$t('user-configuration.calls.title')">
        <p>{{ $t("user-configuration.calls.where-do-you-want-to-answer-the-calls") }}</p>
        <the-presence-configuration></the-presence-configuration>
      </el-tab-pane>
      <el-tab-pane :label="$t('user-configuration.audio.title')">
        <TheAudioConfiguration></TheAudioConfiguration>
      </el-tab-pane>
      <el-tab-pane :label="$t('user-configuration.notifications.title')">
        <TheNotificationConfiguration></TheNotificationConfiguration>
      </el-tab-pane>
      <el-tab-pane :label="$t('user-configuration.language.title')">
        <h2>{{ $t("user-configuration.language.title") }}</h2>
				<LocalChanger></LocalChanger>
      </el-tab-pane>
      <el-tab-pane :label="$t('user-configuration.theme.title')">
        <TheThemeConfiguration></TheThemeConfiguration>
      </el-tab-pane>
      <el-tab-pane :label="$t('user-configuration.about.title')">
        <p>{{ $t('user-configuration.about.version', { number : number }) }}</p>
      </el-tab-pane>
      <el-tab-pane :label="$t('user-configuration.integrations.title')">
        <the-integrations />
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import LocalChanger from "@/components/common/locale-changer";
import TheAudioConfiguration from '@/components/configuration/TheAudioConfiguration';
import TheThemeConfiguration from '@/components/configuration/TheThemeConfiguration';
import TheNotificationConfiguration from '@/components/configuration/TheNotificationConfiguration';
import ThePresenceConfiguration from '../configuration/ThePresenceConfiguration.vue'
import TheIntegrations from '@/components/integrations/TheIntegrations';
export default {
  name: "UserConfiguration",
  data() {
    return {
      mobile: false,
      visible : false,
    };
  },
  methods : {
    toggleVisible() {
      this.visible = !this.visible;
    }
  },
  components : {
    LocalChanger,
    TheAudioConfiguration,
    TheThemeConfiguration,
    TheNotificationConfiguration,
    ThePresenceConfiguration,
    TheIntegrations
  },
  computed : {
    number() {
      return process.env.VUE_APP_VERSION
    },
    ...mapGetters('integrations', {
      integrations : 'getIntegrations'
    })
  }
};
</script>

<style>
</style>