<template>
  <el-popover placement="bottom-start" trigger="hover">
    <div>
      <div style="display: inline-flex; width: 100%; align-items: top;">
          <user-item :user="user" :show-fullname="false" :show-status="false" :size="90"/>
          <div style="margin-left: calc(var(--column) / 2)">
              <p class="mb-0 bold" >{{ user.visiblename }}</p>
              <p class="mb-0" v-if="user.email">{{ user.email }}</p>    
          </div>
      </div>
      <div class="buttons" v-if="buttons.length > 0">
        <ul style="margin: 0; padding: 0">
          <li v-for="(button, index) in buttons" :key="index">
            <el-button circle icon="fas fa-comment" v-if="button.type === 'chat'" @click="initChat(user)"></el-button>
            <el-button circle v-if="button.type === 'phone'">
              <phone-button style="display: inline-flex" :janus="janus" :displayname="user.visiblename" :extension="button.number" />
            </el-button>
            <p style="display: inline-flex; margin-left: calc(var(--column) / 2); ">{{ button.label }}</p>
          </li>
        </ul>
      </div>
    </div>
    <el-card class="favorite" slot="reference" shadow="hover">
      <div class="content">
        <el-tooltip effect="dark" :content="$t('contacts.delete-favorite')" placement="bottom-start">
          <button class="favorite-button" @click="toggleFavorite(user)"><i class="fas fa-star"></i></button>
        </el-tooltip>
        <div class="center">
          <user-item :user="user" :show-fullname="false" :size="90" :show-status="false" style="width: auto"  :class="{ 'item' : !user.picture }" />
        </div>
      </div>
    </el-card>
  </el-popover>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import UserItem from '@/components/common/UserItem'
import PhoneButton from '@/components/webrtc/PhoneButton.vue';
export default {
  props: {
    user: {
      required: true,
    },
  },
  components : {
    UserItem,
    PhoneButton
  },
  methods : {
    ...mapActions("contacts", ["toggleFavorite"]),
    ...mapGetters('corpochat', ['getChatById']),
    ...mapActions('_chats', ['createNewChatroom']),
    ...mapActions('corpochat', ['addOpenedMinChat', 'toggleIsMaxOpenedMinChat']),
    async initChat(user) {
      let chat = this.getChatById()(user.uid)

      if (!chat) {
        try {
          await this.createNewChatroom(user.uid)
          this.$store.commit('corpochat/LAST_CREATED', user.uid)
        } catch (error) {
          console.log(error)
        }
      } else {
        if (!this.getOpenedMinChatById(chat.id)) {
          this.addOpenedMinChat(chat)
        } else {
          this.toggleIsMaxOpenedMinChat(chat)
        }
      }
    }
  },
  computed : {
    ...mapGetters({ janus : "getJanus"}),
    ...mapGetters('corpochat', {
      getOpenedMinChatById : 'getOpenedMinChatById',
    }),
    phones() {
      const buttons = [{
        type : 'phone',
        label : this.$t('contacts.extension') + " - " + this.user.visibleExtension,
        number : this.user.visibleExtension
      }]
      
      if (this.user.type !== "HiperMe") {
        buttons.push({ type : 'phone', number : this.user.phone, label : this.$t('contacts.extension') + " - "+ this.user.phone })
        buttons.push({ type : 'phone', number : this.user.phone_one, label : this.$t('contacts.props.phone_one') + " - " + this.user.phone_one })
        buttons.push({ type : 'phone', number : this.user.phone_two, label : this.$t('contacts.props.phone_two') + " - " + this.user.phone_two })
      }

      return buttons.filter(phone => phone.number)
    },
    otherButtons() {
      const buttons = []

      if (this.user.type === "HiperMe") {
        buttons.push({ type : 'chat', label : this.$t("common.open-chat") })
      }

      return buttons;
    },
    buttons() {
      return [...this.phones, ...this.otherButtons]
    }
  }
};
</script>

<style scoped>
.content {
  position: relative;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.name {
  text-align: center;
  display: -webkit-box;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.bold {
  font-weight: bold;
}

.favorite-button {
  outline: none;
  background: transparent;
  border: none;
  float: right;
  color: var(--orange);
  position: absolute;
  right: 0;
  z-index: 2;
}

.buttons {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  padding: calc(var(--column) / 2);
  border-top: 1px solid var(--blue);
  width: 100%;
  margin-top: var(--column)
}

ul {
  list-style: none;
}

.item {
  font-size: 48px;
}
</style>