import List from "@/views/servers/firebase/List.vue"
import Form from '@/views/servers/firebase/Form.vue'

const routes = [
  {
    name: "firebases",
    path: "/configuration/servers/firebase",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.servers.firebase',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
	name: "firebasesNew",
	path: "/configuration/servers/firebase/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    }
  },
  {
    name: "firebasesEdit",
    path: "/configuration/servers/firebase/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    },
    props: true,
  },
];

export default routes;
