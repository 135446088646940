<template>
  <div>
    <h4>{{ $t('contact-me.pbx.queues.basic-information') }}</h4>
    <ul>
      <li><span class="label">{{$t('contact-me.pbx.queues.name')}}</span>: {{ queue.name }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.number')}}</span>: {{ queue.number }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.callerid')}}</span>: {{ queue.cid === "" ? $t("common.none") : queue.cid }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.maxwait')}}</span>: {{ queue.maxwait }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.ringstrategy')}}</span>: {{ queue.strategy }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.agent-announcement')}}</span>: {{ getRecording( queue.agent_announcement ) }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.join-announcement')}}</span>: {{ getRecording( queue.join_announcement ) }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.music-hold')}}</span>: {{ queue.music }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.is-recording-calls')}}</span>: {{ queue.recording ? "Yes" : "No" }}</li>
    </ul>
    <h4>{{$t('contact-me.pbx.queues.members')}}</h4>
    <p v-if="queue.members.length == 0">{{ $t('common.is-not-configured') }}</p>
    <ul v-else>
      <li v-for="ext in getMembers" :key="ext.extension">{{ ext.extension ?? `Agent/${ext.number}` }} - {{ext.name}}</li>
    </ul>

    <h4>{{$t('contact-me.pbx.queues.advance-configuration')}}</h4>
    <ul>
      <li><span class="label">{{$t('contact-me.pbx.queues.timeout')}}</span>: {{ queue.timeout == 0 ? $t('common.unlimited' ) : numberToSeconds( queue.timeout ) }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.retry')}}</span>: {{ queue.retry == 'none' ? $t('common.no-retry' ) : numberToSeconds(queue.retry) }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.wrap-up-time')}}</span>: {{ numberToSeconds(queue.wrapuptime) }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.queue-weight')}}</span>: {{ queue.weight === "" ? "-" : queue.weight }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.skip-busy-agents')}}</span>: {{ queue.cwignore == 1 ? "Yes" : "No" }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.event-when-called')}}</span>: {{ queue.eventwhencalled | ucase }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.autofill')}}</span>: {{ queue.autofill ? "Yes" : "No" }}</li>
    </ul>

    <h4>{{$t('contact-me.pbx.queues.caller-position-announcements')}}</h4>
    <ul>
      <li><span class="label">{{$t('contact-me.pbx.queues.announce-position')}}</span>: {{ queue.announceposition ? "Yes" : "No" }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.frequency')}}</span>: {{ numberToSeconds( queue.announcefreq, $t("common.none") ) }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.announce-hold-time')}}</span>: {{ queue.announceholdtime ? $t('common.' +  queue.announceholdtime) : '-' }}</li>
    </ul>

    <h4>{{$t('contact-me.pbx.queues.periodic-announcements')}}</h4>
    <ul>
      <li><span class="label">{{$t('contact-me.pbx.queues.periodic-ivr')}}</span>: {{ getIvr( queue.announcemenu ) }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.periodic-frequency')}}</span>: {{ numberToSeconds( queue.pannouncefreq, $t("common.none") ) }}</li>
    </ul>

    <h4>{{$t('contact-me.pbx.queues.action-no-answer')}}</h4>
    <ul>
      <li><span class="label">{{$t('contact-me.pbx.queues.type')}}</span>: {{ queue.dest.type === "" ? $t("common.none") : queue.dest.type }}</li>
      <li><span class="label">{{$t('contact-me.pbx.queues.value')}}</span>: {{ queue.dest.name === undefined || queue.dest.name === "" ? '-' : queue.dest.name }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    queue: {
      required: true
    }
  },
  methods:{
    numberToSeconds(value, nullValue = '') {
      if( value === "" || value === null ) return nullValue;

      const minutes = ~~(value / 60);
      const seconds = (value % 60 ? value % 60 : '0');
      const str_minutes = minutes > 0 ? `${ minutes } ${ minutes == 1 ? this.$t('common.minute') : this.$t('common.minutes') }` : '';
      const str_seconds = seconds > 0 ? `${ seconds } ${ seconds == 1 ? this.$t('common.second') : this.$t('common.seconds') }` : '';
      const str_comma = minutes > 0 && seconds > 0 ? ', ' : '';
      return minutes == 0 && seconds == 0 ? `0 ${ this.$t('common.seconds') }` : `${ str_minutes }${ str_comma }${ str_seconds }`;
		}
  },
  computed: {
    extensions() {
      return this.$store.getters["pbx_extensions/getData"]
    },
    agents() {
      return this.$store.getters["pbx_agents/getData"]
    },
    ivrs() {
      return this.$store.getters["pbx_ivrs/getData"]
    },
    musics() {
      return this.$store.getters["pbx_music_categories/getData"]
    },
    recordings() {
      return this.$store.getters["pbx_system_recordings/getData"]
    },
    destinations() {
      const dests = this.$store.getters["pbx_destinations/getData"];
      return dests;
    },
    getRecording( ){
      return v => {
        const rec = Object.values( this.recordings ).find( r => r.id == v );
        return rec ? rec.name : this.$t("common.none");
      }
    },
    getMembers( ){
      if( this.queue.members.length == 0 ) return [];
      return ( 
        Object.values( this.extensions ).filter( e => this.queue.members.includes( e.extension ) )
      ).concat(
        Object.values( this.agents ).filter( a => this.queue.members.includes( a.number.toString( ).startsWith('A') ? a.number : 'A'+a.number ) )
      );
    },
    getIvr( ){
      return v => {
        const ivr = Object.values( this.ivrs ).find( i => i.id == v );
        return ivr ? ivr.name : this.$t("common.none");
      }
    }
  }
}
</script>

<style scoped>
.label {
  font-weight: bold;
  color: #5A5B5B;
}

li {
  margin-bottom: calc(var(--column) / 2 );
  font-size: 14px
}

.destinations .tag {
  margin-right: var(--column)
}
</style>