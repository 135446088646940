<template>
  <div class="crud">
    <div class="crud-content">
      <div class="header">
        <h2>Mail Center</h2>
      </div>
      <el-tabs v-model="value">
        <el-tab-pane label="Usuarios" name="users">
          <the-users-list />
        </el-tab-pane>
        <el-tab-pane label="Organizationes" name="organizations">
          <the-organizations-list />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import TheUsersList from '@/views/mail-center/TheUsersList'
import TheOrganizationsList from '@/views/mail-center/TheOrganizationsList'
export default {
  props: {
    tab: {
      required: false,
      default: "users",
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      value : this.tab
    }
  },
  components: {
    TheUsersList,
    TheOrganizationsList,
  }
};
</script>

<style>
</style>