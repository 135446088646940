import http from '@/api/http'

import createStore from "../helper";
const formsInputs = createStore('forms')

const actions = {
  getLastUpdatedForm({ commit }, id) {
    return http.get(`/forms/${id}`)
    .then((response) => {
      commit('UPDATE_DATA', response.data)
    })
  }
}

formsInputs.actions = { ...formsInputs.actions, ...actions }

export default formsInputs