const state = {
	groups : [],
	showCreateGroupModal : false
}

const getters = {
	getGroups: (state) => state.groups,
	getShowCreateGroupModal : (state) => state.showCreateGroupModal
}

const mutations = {
	TOGGLE_SHOW_CREATE_GROUP_MODAL : (state) => state.showCreateGroupModal = !state.showCreateGroupModal
}

const actions = {
	toggleShowCreateGropModal({commit}) {
		commit('TOGGLE_SHOW_CREATE_GROUP_MODAL')
	},
	createGroup({ rootGetters }, group) {
		const users = []
		const user = rootGetters.getUser
		const firebase = rootGetters.getFirebase

		group.users.forEach((uid) => {
			const user = rootGetters['_users/getUsers'].find(user => user.uid === uid)
			if (user) {
				users.push({ uid : user.uid, fullname : user.fullname })
			}
		})

		return firebase.functions().httpsCallable('createGroup')({ owner : user.uid, participants : users, name : group.name })
	},
	editGroup({ rootGetters }, { participants, roomId }) {
		const users = []
		const firebase = rootGetters.getFirebase

		participants.forEach((uid) => {
			const user = rootGetters['_users/getUsers'].find(user => user.uid === uid)
			if (user) {
				users.push({ uid : user.uid, fullname : user.fullname })
			}
		})

		return firebase.functions().httpsCallable('addParticipants')({ participants : users, roomId })
	},
	getMembersOfGroup({ rootGetters }, { roomId } ) {
		const firebase = rootGetters.getFirebase
		roomId = /^@/.test(roomId) ? roomId : "@" + roomId;
		return firebase.firestore().collection("chats").doc(roomId).collection("participants")
	},
	deleteMemberOfGroup({ rootGetters },{ roomId, uid, action, deleteForever = false }) {
		const firebase = rootGetters.getFirebase

		if (action === 'kick') {
			return firebase.functions().httpsCallable('userKicked')({ roomId, uid })
		}
		return firebase.functions().httpsCallable('userLeaveGroupv2')({ roomId, uid, deleteForever })
	},
	getMembersOfGroupLimit({ rootGetters }, {roomId, latesUser } ) {
		const firebase = rootGetters.getFirebase
		roomId = /^@/.test(roomId) ? roomId : "@" + roomId;
		if (latesUser) {
			return firebase.firestore().collection("chats").doc(roomId).collection("participants").orderBy("fullname", 'asc').startAfter(latesUser).limit(10)
		}
		return firebase.firestore().collection("chats").doc(roomId).collection("participants").orderBy("fullname", 'asc').limit(10)
	}
}

export default {
	state,
	getters,
	mutations,
	actions,
}