<template>
  <div class="chat-little" ref="chat" :class="{ min: !chat.isMax, unread: chat.unread > 0 }" :style="styles" @keyup.esc="closeChat">
    <div class="resizes">
      <div @mousedown="startResize('height')"></div>
      <div @mousedown="startResize('both')"></div>
      <div @mousedown="startResize('width')"></div>
    </div>
    <the-corpo-chat :id="chat.id" :chat="chat" @headerClicked="headerClicked">
      <template slot="buttons">
        <button @click="removeOpenedMinChat(chat)"><i class="fas fa-times"></i></button>
        <button @click="maxChat"><i class="fas fa-arrow-up"></i></button>
      </template>
    </the-corpo-chat>
  </div>
</template>

<script>
import TheCorpoChat from "@/components/chat/TheCorpoChat"
import { mapActions, mapGetters } from "vuex"
export default {
  props: {
    id: {
      required: true,
    },
    chat: {
      required: true,
    },
  },
  components: {
    TheCorpoChat,
  },
  data() {
    return {
      isResizing : false,
      resizeType : ''
    };
  },
  computed : {
    ...mapGetters("corpochat", ['getChatById']),
    styles : function() {
      let width = this.chat.width
      let height = (this.chat.height !== 'initial') ? this.chat.height : ''
      
      if (!this.chat.isMax) {
        height = '',
        width = ''
      }

      return {
        height,
        width
      }
    }
  },
  methods: {
    ...mapActions("corpochat", [
      "removeOpenedMinChat",
      "getOpenedMinChats",
      "toggleIsMaxOpenedMinChat",
      "updateOpenedMinChatHeight",
      "updateOpenedMinChatWidth",
      "toggleHideAllChats"
    ]),
    headerClicked(evt) {
      if (evt.target.classList.contains("chat-header")) {
        this.toggleIsMaxOpenedMinChat(this.chat)
      }
    },
    maxChat() {
      this.toggleHideAllChats()
      this.$router.push({
        name: "MessagesWith",
        params: {
          id: this.id,
          chat: this.getChatById(this.chat.id),
        },
      }),
      this.removeOpenedMinChat(this.chat)
    },
    startResize(type) {
      const resizesTypes = {
        "both" : "w-resize",
        "height" : "n-resize",
        "width" : "w-resize"
      }
      this.isResizing = true
			this.resizeType = type
      document.documentElement.addEventListener("mousemove", this.initResize, false)
			document.documentElement.addEventListener("mouseup",this.stopResize,false)
			document.documentElement.style.userSelect = "none"
      document.documentElement.style.cursor = resizesTypes[type]      
    },
    initResize(e) {
      if (!this.isResizing) {
				return;
			}

      if (this.resizeType == "height" || this.resizeType == "both") {
				const height = window.outerHeight - e.screenY
				if (height > 394 && height < 900) {
					this.updateOpenedMinChatHeight({id : this.id, height: height + 'px'})
				}
			}

      if (this.resizeType == "width" || this.resizeType == "both") {
        let width = window.outerWidth - e.screenX
				if (this.getOpenedMinChats.length > 0) {
          let totalWidth = 0;
					document.documentElement.querySelectorAll(".chat.little").forEach((Element) => {
							if (Element.getAttribute("data-id") !== this.id) {
								totalWidth += Element.offsetWidth;
							}
						})
					width -= totalWidth;
        }

        if (width > 309 && width < 600) {
          this.updateOpenedMinChatWidth({ id : this.id, width : width + "px"})
				}
      }

		},
		stopResize() {
			this.isResizing = false;
			document.documentElement.removeEventListener("mousemove",this.initResize, false)
			document.documentElement.removeEventListener("mouseup",this.stopResize,false)
			document.documentElement.style.userSelect = "text"
			document.documentElement.style.cursor = "auto"
		},
    closeChat() {
      this.removeOpenedMinChat(this.chat)
    }
  },
}
</script>

<style scoped>
.chat-little {
  box-shadow: var(--shadow);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 60vh;
  padding: 0;
  min-width: 10vw;
  visibility: visible;
  bottom: 0;
  right: 0;
  margin-left: var(--column);
  position: relative;
  width: 400px;
}

.chat-little :deep(.chat-container)  {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat-little :deep(.chat-header)  {
  background-color: var(--blue);
  padding: var(--column);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: none;
  font-size: 14px;
  margin-bottom:0px;
  min-height:60px;
  height: initial;
}

.chat-little :deep(.chat-header)  h2 {
  color: white;
}

.chat-little :deep(.chat-header)  .status-text {
  display: none;
}

.chat-little :deep(.chat-header)  button {
  color: white;
  background-color: var(--blue);
}

.chat-little :deep(.corpo-chat)  {
  /* height: 85%; */
  flex: 1;
}

.chat-little :deep(.chat-messages-display)  {
  padding-left: var(--column);
}

.chat-little :deep(.chat-textarea)  {
  border-radius: 0;
}

.chat-little :deep(.chat-container)  {
  border: none;
}

.buttons button {
  border: none;
  outline: none;
}

.fa-arrow-up {
  transform: rotate(45deg);
}

.chat-little.unread :deep(.chat-header)  button,
.chat-little.unread :deep(.chat-header)  {
  -webkit-animation-name: unreadMessage;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
  animation-name: unreadMessage;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
}

.resizes div:nth-child(1) {
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  cursor: n-resize;
  visibility: visible;
}

.resizes div:nth-child(2) {
  position: absolute;
  top: -3px;
  left: -3px;
  width: 9px;
  height: 9px;
  cursor: nw-resize;
  visibility: visible;
}

.resizes div:nth-child(3) {
  position: absolute;
  left: -3px;
  bottom: 0;
  width: 6px;
  height: 100%;
  cursor: w-resize;
  visibility: visible;
}

@keyframes unreadMessage {
  from {
    background-color: var(--blue);
  }

  to {
    background-color: #007bff;
  }
}

.chat-little.min {
  height: 60px;
}
/* 
.chat-little.min :deep(.corpo-chat)  {
  height: 0;
  visibility: hidden;
}

.chat-little.min :deep(.chat-header)  {
  margin-bottom: 0;
}*/

.chat-little.min :deep(.chat-header)  .phone-button,
.chat-little.min :deep(.chat-header)  .videocall-button {
  display: none;
}
</style>