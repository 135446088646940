import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from './i18n/index';
import * as Formated from './filters/format'
import * as Dates from './filters/dates'
import VueApexCharts from 'vue-apexcharts'
import { Auth0Plugin } from '@/auth'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueGtm from '@gtm-support/vue2-gtm';
import { RecycleScroller } from 'vue-virtual-scroller'

Vue.use(ElementUI, {
	i18n: (key, value) => i18n.t(key, value)
});


if (process.env.VUE_APP_SENTRY_DSN) {
	Sentry.init({
		Vue,
		dsn: process.env.VUE_APP_SENTRY_DSN,
		integrations: [
			new Integrations.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ["localhost", "my-site-url.com", /^\//],
			}),
		],
		attachProps: true,
		logErrors: true,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.NODE_ENV === "development" ? 0 : 1.0,
	release: process.env.VUE_APP_SENTRY_RELEASE,
	ignoreErrors: ['ResizeObserver loop limit exceeded'],
	});
}

if (process.env.VUE_APP_GTM_ID) {
	Vue.use(VueGtm, {
		id: process.env.VUE_APP_GTM_ID,
		defer: false,
		enabled: true,
		debug: false,
		loadScript: true,
		vueRouter: router,
		trackOnNextTick: false,
	});
}

Vue.use(VueApexCharts)

const { domain, clientId } = {
	domain : process.env.VUE_APP_DOMAIN,
	clientId : process.env.VUE_APP_CLIENTID,
}

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  },
})

Vue.component('apexchart', VueApexCharts)
Vue.component('RecycleScroller', RecycleScroller)

Vue.config.productionTip = false;

/* if (localStorage.getItem("firebase_configuration") && cookie.getCookie()) {
	store.dispatch("initializeFirebaseApp");
	moment.locale(i18n.locale);

	store.getters.getFirebase.auth().onAuthStateChanged((user) => {
		store.dispatch('setFirebaseLogged', user ? true : false)
		if (user) {

			if (user.uid !== store.state.user.user.uid) {
				if (window.location.pathname !== '/logout') {
					window.location.href = '/logout';
				}
				return
			}

			if (process.env.VUE_APP_SENTRY_DSN) {
				Sentry.setUser({ email: user.email });
			}

			const status = localStorage.getItem('status')
			store.dispatch("updateStatus", status ? status : 'connected');
			store.dispatch("listenToUser", store.state.user.user.uid);
			store.dispatch("listenToNewNotifications", store.state.user.user.uid);
			store.dispatch("_users/fetchUsers")
			i18n.locale = store.state.user.user.language;
			// TODO
			// Buscar los usuarios por organización
			// store.state.user.user.organizations.forEach(function(organization){
			// 	store.dispatch("fetchUsersFromOrganization", organization.id + "");
			// });
		} else {
			if (process.env.VUE_APP_DOMAIN && process.env.VUE_APP_CLIENTID) {
				getInstance().logout()
			} else {
				if (window.location.pathname !== '/logout') {
					window.location.href = '/logout';
				}
			}
		}	
	});
} */

Vue.filter('utf8', Formated.decode)
Vue.filter('calendar', Dates.calendar)
Vue.filter('chat', Dates.chatRedeableDate)
Vue.filter('hhmmss', Dates.secondsToFormat)
Vue.filter('redeableDate', Dates.redeableDate)
Vue.filter('firstLetter', Formated.firstLetter)
Vue.filter('usersWriting', Formated.usersWriting)
Vue.filter('hhmm', Dates.secondsToHourMinutes)
Vue.filter('date', Dates.secondsToDate)
Vue.filter('ticketDate', Dates.ticketDate)

new Vue({
	i18n,
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
