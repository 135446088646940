<template>
  <div>
    <el-form :model="model" :rules="rules" size="small" label-position="right" ref="form" v-loading="saving || loading || updating" :element-loading-text="loadingtext" v-if="status === null" :class="formClass" target="#app-main-content"> 
      <h2>{{ title }}</h2>
      <the-item v-for="(node, index) in form.nodes" :key="index" :node="node" :edit="edit"></the-item>
      <slot name="inputs" />
      <el-button size="small" @click="$router.go(-1)">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" size="small" @click="_submit">{{ $t("common.save") }}</el-button>
    </el-form>
    <div class="message" v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 200"/>
        <img src="@/img/error.svg" v-else/>
        <p> {{ message }} </p>
        <div class="buttons">
          <el-button type="text" @click="$router.go(-1)">{{ $t("common.go-back-to-the-list") }}</el-button>
          <el-button type="text" @click="status = null" v-if="status !== 404">{{ $t("common.show-the-form") }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheItem from './TheItem';
export default {
  props: {
    form: {
      require: true,
    },
    edit : {
      required : false,
      default : false,
    },
    store : {
      required : true,
      type : String
    },
    formClass : {
      required : false,
      type: String
    },
    id : {
      required : false,
      type : String,
      default : "0"
    },
    entity : {
      required : false,
      type : Object
    },
    title : {
      required : true,
      type : String
    },
    submit : {
      required : false,
      type : Function
    },
    afterSubmit : {
      required : false,
      type : Function
    },
    updating : {
      required : false,
      type : Boolean,
      default : false
    },
  },
  data() {
    return {
      loading : false,
      saving : false,
      status : null,
      errors : {},
      submenu_routes: ['servers'] //secciones que tienen submenu
    }
  },
  components : {
    TheItem
  },
  computed : {
    ...mapGetters('form', {
      getFormValues: 'getFormValues',
      rules : 'getRules'
    }),
    model() {
      const form = {};
      this.getFormValues.forEach(input => form[input.index] = input.value)
      return form
    },
    loadingtext() {
      if (this.saving || this.updating) {
        return this.$t('common.saving')
      }

      if (this.loading) {
        return this.$t('common.loading-please-wait')
      }

      return "";
    },
    message() {
      if (this.status === 200) {
        return this.$t("common.saved")
      }

      if (this.status === 404) {
        return this.$t("common.not-found")
      }

      return this.$t("common.not-saved")
    }
  },
  methods : {
    _submit() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          this.saving = true;
          let form = {};

          this.getFormValues.forEach(input => form[input.index] = input.value );
          this.getFormValues.forEach(input => this.$store.dispatch('form/setError', { index : input.index, error : '' }))

          const action = (!this.edit) ? 'create' : 'update'
          try {

            if (this.submit !== undefined) {
             form = this.submit(form);
            }
            

            const data = (action === 'create') ? form : { id : this.id, payload : form }
            
            const result = await this.$store.dispatch(this.store + '/' + action, data)

            if (this.afterSubmit) {
              await this.afterSubmit({result, form})
              this.status = 200
            } else {
              this.status = 200
            }

          } catch (error) {
            if (error.response.status === 400) {
              const { data } = error.response
              data.violations.forEach(err => this.$store.dispatch('form/setError', { index : err.propertyPath, error : err.message }))
            } else {
              this.status = error.response.status
            }
          } finally {
            this.saving = false
          }
        }
      })
    },
    initValues(nodes, model = {}) {
      nodes.forEach((node) => {
        if (node.component === 'input' || node.component === 'select' || node.component === 'radio' || node.component === 'checkbox' || node.component === "switch") {

          if (model[node.index]) {
            let value = model[node.index]

            if (node.component === 'select') {
              if (node.multiple) {
                const _values = Array.isArray(model[node.index]) ? model[node.index] : Object.values(model[node.index]);
                value = _values.map((value) => {
                  if (typeof value === 'string') {
                    return value
                  }
                  
                  return value['@id']
                })
              } else {
                value = typeof model[node.index] === 'string' || typeof model[node.index] === 'number' ? model[node.index] : model[node.index]['@id']

                if ('index-value' in node) {
                  value = model[node.index][node['index-value']]
                }
              }
            }

            if (node.component === 'checkbox' || node.component === "switch") {
              value = value === 'yes' || value === '1' || value === 1 ? true : false
            }

            this.$store.dispatch('form/setValue', { index: node.index, value })
            this.$store.dispatch('form/setFormFullValue', { index: node.index, value : model[node.index] })

            if (node.hideIndex) {
              this.$store.dispatch('form/setVisible', { index : node.hideIndex, value })
            }
          } else {
            this.$store.dispatch('form/setValue', { index: node.index, value : node.nulleable ? null : node.value ?? '' })
          }
        }

        if (node.hidden && Object.keys(model).length === 0) {
          this.$store.dispatch('form/setVisible', { index : node.index, value : false })
        }

        if (node.validations) {
          this.$store.dispatch('form/setRule', { index : node.index, rules : node.validations })
        }

        if ('children' in node) {
          this.initValues(node.children, model);
        }
      })
    }
  },
  async created() {
    this.$store.commit('form/setForm', this.form)
    this.initValues(this.form.nodes);
    if (this.edit && this.id) {
      if (this.entity) {
        this.initValues(this.form.nodes, this.entity)
      } else {
        this.loading = true
        try {
          const model  = await this.$store.dispatch(this.store + '/one', this.id)
          this.initValues(this.form.nodes, model)
        } catch (error) {
          this.status = error.response.status
        } finally {
          this.loading = false;
        }
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("form/setDefaultValues");
  },
  watch : {
    updating(value) {
      this.status = value ? null : 200
    }
  }
};
</script>

<style scoped>
.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
}
</style>