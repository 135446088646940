<template>
    <el-drawer
    :title="$t('tickets.new-client')"
    :close-on-press-escape="!saving"
    :show-close="!saving"
    :wrapperClosable="!saving"
    :visible.sync="show"
    :modal="false"
    :append-to-body="true"
    direction="rtl"
    ref="drawer"
    @close="close"
    @opened="open"
  >
    <the-new-user-form class="form" ref="userForm" @close="show = false" @created="userCreated"></the-new-user-form>
  </el-drawer>

</template>

<script>
import TheNewUserForm from './TheNewUserForm'
export default {
  components : {
    TheNewUserForm
  },
  data() {
    return {
      show: false,
      saving: false,
      form: {
        name: '',
      }
    }
  },
  methods : {
    close() {
      this.$refs.userForm.reset()
    },
    open() {
      this.$refs.userForm.fetchForm()
    },
    userCreated() {
      this.$message({
        message: 'El usuario fue creado',
        type: 'success'
      })
      this.close()
    }
  }
}
</script>

<style scoped>
.form {
  padding: var(--column)
}
</style>