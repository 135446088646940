import store from '../../../store';
import ThePBXPanel from '@/views/contact-me-configuration/ThePBXPanel.vue';
import TheExtensions from "@/views/contact-me-configuration/extensions/TheList.vue";
import TheForm from "@/views/contact-me-configuration/extensions/TheForm.vue";

const routes = [
  {
    name: "contact-me-pbx",
    path: "/contact-me/pbx",
    component: ThePBXPanel,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "contact-me-pbx-extensions",
    path: "extensions",
    component: TheExtensions,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "contact-me-pbx-extensions-new",
    path: "extensions/new",
    component: TheForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
  },
  {
    name: "contact-me-pbx-extensions-edit",
    path: "extensions/:id/edit",
    component: TheForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
    beforeEnter: (to, from, next) => {
      const id = to.params.id;
      if (!store.getters['pbx_extensions/getItemById'](id)) {
        return next({ name : "contact-me-pbx-extensions"})
      }
      return next();
    }
  },
]

export default routes;