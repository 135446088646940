const state = {
    form : {},
    formValues : [],
    formVisible : [],
    rules : {},
    errors : [],
    formFullValues : []
}

const getters = {
    get : (state) => state.formVisible, 
    getForm : (state) => state.form,
    getRules : (state) => state.rules,
    getFormValues : (state) => state.formValues,
    getError: (state) => (index) => (state.errors.find(item => item.index === index)),
    getFormValue : (state) => (index) => (state.formValues.find(item => item.index === index)),
    getItemIsHidden: (state) => (index) => (state.formVisible.find(item => item.index === index)),
    getFormFullValue: (state) => (index) => (state.formFullValues.find(item => item.index === index)),
    
}

const mutations = {
    setForm : (state, form) => (state.form = form),
    setRule: (state, {index, rules}) => (state.rules[index] = rules),
    toggleItemIsHidden : (state, index) => (state.formVisible[index] = !state.formVisible[index]),
    updateValue : (state, { index, value }) => {
        const indexOf = state.formValues.findIndex(input => input.index === index);
        if (indexOf > -1) {
            state.formValues[indexOf].value = value
        } else {
            state.formValues.push({index, value})
        }
    },
    setVisible : (state, {index, value} ) => {
        const indexOf = state.formVisible.findIndex(input => input.index === index);
        if (indexOf > -1) {
            state.formVisible[indexOf].value = value
        } else {
            state.formVisible.push({index, value})
        }
    },
    setError : (state, {index, error} ) => {
        const indexOf = state.errors.findIndex(input => input.index === index);
        if (indexOf > -1) {
            state.errors[indexOf].error.push(error)
        } else {
            if (error) {
                state.errors.push({index, error : [ error ]})
            }
        }
    },
    formFullValues : (state, { index, value}) => {
        const indexOf = state.formFullValues.findIndex(input => input.index === index);
        if (indexOf > -1) {
            state.formFullValues[indexOf].value = value
        } else {
            state.formFullValues.push({index, value})
        }
    },
    setDefaultValues: (state) => {
        state.form = {};
        state.formValues = [];
        state.formVisible = [];
        state.rules = {};
        state.errors = [];
        state.formFullValues = [];
    }
}

const actions = {
    setValue({commit}, {index, value}) {
        commit('updateValue', {index, value})
    },
    setVisible({commit}, {index, value}) {
        commit('setVisible', {index, value})
    },
    setRule({commit}, {index, rules}) {
        commit('setRule', {index, rules})
    },
    setError({commit}, {index, error}) {
        commit('setError',  {index, error})
    },
    setFormFullValue({commit}, {index, value}) {
        commit('formFullValues', {index, value})
    },
}

export default {
    namespaced : true,
    state,
    actions,
    getters,
    mutations 
}