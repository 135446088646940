<template>
  <div>
    <button @click="goBack" class="goBack">
      <i class="fas fa-angle-left"></i>
      {{ $t('common.go-back') }}
    </button>
    <div>
      <h2>{{ $t("agents-dashboard.title") }}</h2>
      <div class='filters-row'>
        <el-select v-model="selected" filterable collapse-tags :placeholder="loading ? 'Loading...' : 'Organizations'"
          @change="fetchOrganizationAgents">
          <el-option v-for="item in organizations" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <el-select v-model="filters" multiple collapse-tags>
          <el-option v-for="item in options" :key="item" :label="$t(`agent-console.dashboard-filters.${item}`)"
            :value="item">
          </el-option>
        </el-select>

        <div class='grid-table-filter'>{{ $t("agent-console.dashboard.grid-table-filter") }} <el-switch
            v-model="table_view"></el-switch>
        </div>
      </div>
    </div>
    <div id="agent-dashboard-cards" class="agent-dashboard margin-top"
      v-if="table_view == false && agentslist.length > 0">
      <el-row :gutter="10" class="agents-cards-rows">
        <el-col :xs="24" :sm="12" :md="2" :lg="6" :xl="4" v-for="agentRecord in agentslist" :key="agentRecord.uid">
          <TheAgentCard :agent="agentRecord" class="agent-card" :ellipsis="true" :stats="true" :spy_whisper="true">
          </TheAgentCard>
        </el-col>
      </el-row>
    </div>
    <div v-else-if="table_view && agentslist.length > 0">
      <TheAgentTableRow :records="agentslist" :ellipsis="true" :stats="true" :spy_whisper="true" />
    </div>
    <div v-else class='margin-top'>
      <p>{{ $t("agent-console.dashboard.no-agents-connected") }}</p>
    </div>
    <el-dialog :title="$t('agent-console.dashboard.break.title', { name: agentDashboard ? agentDashboard.name : '' })" :visible.sync="showBreakModal" center>
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-form-item :label="$t('agent-console.dashboard.break.breaks')" prop="break">
          <el-select v-model="form.break" :placeholder="
            $t('agent-console.dashboard.break.select-the-break-that-you-want-to-put-the-agent')
          ">
            <el-option v-for="item in breaks" :key="item.id" :label="item.name | utf8" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-button type="primary" :disabled="!form.break" @click.native="setAgentInBreak">{{ $t("agents-dashboard.actions.break") }}</el-button>
        <el-button @click="$store.commit('TOGGLE_BREAK_MODAL')">{{ $t("common.cancel") }}</el-button>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import TheAgentCard from '@/components/dashboard/TheAgentCard';
import TheAgentTableRow from '@/components/dashboard/TheAgentTableRow';

export default {
  name: "AgentDashboard",
  data() {
    return {
      selected: null,
      loading: true,
      online: false,
      table_view: false,
      options: [
        "in_call",
        "in_chat",
        "pause",
        "available",
        "offline"
      ],
      filters: [
        "in_call",
        "in_chat",
        "pause",
        "available"
      ],
      form: {
        break: "",
      },
      rules: {
        break: [
          {
            required: true,
            message: this.$t("agent-console.break.select-a-break"),
            trigger: "change",
          },
        ],
      }
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "switchBetweenDashboards"});
    },
    async fetchOrganizations() {
      this.loading = true;
      try {
        await this.$store.dispatch(`organizations/all`, { pagination: false });
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false;
      }
    },
    async fetchOrganizationAgents() {
      let selectedArray = [this.selected];
      this.$store.state.selectedOrganizations = selectedArray; ///this.selected;
      this.$store.state.filterOnlineAgents = this.online;
      this.$store.dispatch(`listenAgentsDashboardArray`, selectedArray); //this.selected);
      this.$store.dispatch('fetchBreaksByOrganization', this.selected);
    },
    setAgentInBreak() {
      this.$store.dispatch('doBreakAction', this.breaks.find(_break => _break.id == this.form.break));
      this.$store.commit('TOGGLE_BREAK_MODAL')
    }
  },
  computed: {
    ...mapActions(['listenAgentsDashboardArray']),
    ...mapGetters({
      tmpagentsList: 'getAgentsDashboardList',
      organizationlist: 'organizations/getData',
      breaks: "getBreaksDashboard",
      agentDashboard: "getAgentDashboardBreak"
    }),
    organizationIdsArray() {
      return this.selected;
    },
    organizationId() {
      return this.agent.organization_id
    },
    agentslist() {
      let list = this.tmpagentsList.filter(agent => agent.number !== undefined); //fix error cards vacíos
      const has_filters = this.filters.length < this.options.length;
      return list
        .filter(agent => {
          if (!has_filters) return true; //no filters, return every row
          return (
            (this.filters.includes('pause') && ((agent.chat_logged || agent.call_logged) && agent.in_break)) ||
            (this.filters.includes('in_call') && agent.call_logged && agent.call_type) ||
            (this.filters.includes('in_chat') && agent.chat_logged) ||
            (this.filters.includes('available') && ((agent.chat_logged || agent.call_logged) && !agent.in_break && !agent.call_type)) ||
            (this.filters.includes('offline') && (!agent.chat_logged && !agent.call_logged))
          )
        })
    },
    organizations() {
      return this.organizationlist;
    },
    showBreakModal: {
      get() {
        return this.$store.getters.getShowBreakModal
      },
      set() {
        this.$store.commit("TOGGLE_BREAK_MODAL")
      }
    }
  },
  created() {
    this.selected = this.$store.state.selectedOrganizations != undefined ? this.$store.state.selectedOrganizations[0] || null : null;
    this.online = this.$store.state.filterOnlineAgents || false;
    this.fetchOrganizations();
    this.fetchOrganizationAgents();
  },
  components: {
    TheAgentCard,
    TheAgentTableRow
  }
}

</script>

<style scoped>
.goBack {
  outline: none;
  border: none;
  background-color: transparent;
}


tr{
    --font-size: 0.79em;
    --icon-size: calc( var(--font-size) * 1.3 );
}
.agents-cards-rows{
    display: flex;
    flex-wrap: wrap;
}

.agents-cards-rows {
  display: flex;
  flex-wrap: wrap;
}

.margin-top {
  margin-top: 20px;
}

.agent-dashboard {
  height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.filters-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.online-filter {
  padding: 6px 0;
}

.agent-card {
  margin-bottom: var(--column);
}

.compact-table {
  width: 100%;
  margin-top: 1em;
}

.compact-table th {
  font-size: var(--font-size);
  padding: 1px 10px;
}

.compact-table th.center {
  text-align: center;
}
</style>