<template>
  <div class="crud-content crud">
    <div class="header">
      <h2>{{ $t('reports.user-login-logout') }}</h2>
      <el-button @click="exportToCsv" type="primary" size="small">{{ $t("common.export-to-csv") }}</el-button>
    </div>
    <el-date-picker v-model="date" type="daterange" size="small" style="margin-bottom:var(--column)" @change="fetch" value-format="yyyy-MM-dd" default-value="defaultDates"></el-date-picker>
    <the-table :columns="columns" :rows="rows" :pagination="pagination" :loading="loading" :filters="filters" :search="search" @fetch="fetch" @change-page="fetch"></the-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheTable from '@/components/crud/TheTable'
export default {
  data() {
    const today = new Date()
    return {
      defaultDates : [
        today,
        today
      ],
      date : [today, today],
      columns: [
        { index: "fullname", label: this.$t('common.fullname') },
        { index: "email", label: this.$t('common.email') },
        { index: "datetime_init.value", label: this.$t('reports.datetime_init') },
        { index: "datetime_end.value", label: this.$t('reports.datetime_end') },
        { index: "duration", label: this.$t('reports.duration'), type : 'time' },
      ],
      search: [{ index: "fullname", label: this.$t('common.fullname'), properties: ["fullname"] }],   
      pagination: {
        page: 1,
        pageSize: 1,
        totalItems: 0
      },
      loading: true
    }
  },
  components: {
    TheTable
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapGetters('bigQueryReports', ['getUserLoginLogoutReport']),
    ...mapGetters('organizations', { organizations: 'getData' }),
    rows() {
      return this.getUserLoginLogoutReport.map((report) => {
        report.duration = new Date(report.datetime_end.value).getTime() - new Date(report.datetime_init.value).getTime()
        return report
      })
    },
    filters() {
      return [
        {
          index: 'organizations',
          label: this.$t("organizations"),
          type: 'select',
          multiple: true,
          options: this.organizations.map(organization => ({ value : organization['@id'].replace('/api/organizations/', ''), label : organization.name })),
          showLabel : true
        },
        {
          type : 'text',
          index : 'email',
          label : this.$t("common.email"),
          options : ['contains']
        },
      ]
    },
    csv() {
      let csv = [ this.columns.map(column => column.label).join(",")] 
      this.rows.map((_row) => {
        let row = []
        this.columns.forEach((column) => {
          if (column.index === "datetime_init.value" ||  column.index === "datetime_end.value" ) {
            const index = column.index.split(".").shift();
            row.push(_row[index].value)
          } else {
            if (column.index === "duration") {
              row.push(Math.floor(_row[column.index] / 3600) === 0 ? new Date(_row[column.index] * 1000).toISOString().substr(11, 8)  : '00:' + new Date(_row[column.index] * 1000).toISOString().substr(14, 5))
            } else {
              row.push(_row[column.index]);
            }
          }
        })
        csv.push(row);
      })
      csv = csv.join("\r\n");
      return new Blob([csv], { type: 'text/csv' })
    },
  },
  methods: {
    ...mapActions('organizations', { fetchOrganizations: 'all' }),
    ...mapActions('bigQueryReports', ['fetchReportUsersLoginLogout']),
    exportToCsv() {
      const a = document.createElement("a");
      const _url = window.URL.createObjectURL(this.csv);
      a.style = "display: none";
      a.href = _url;
      a.download = "download.csv"
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(_url);
    },
    async fetch(params) {
      if (this.date.length > 0) {
        params.dateInit = new Date(this.date[0]).toISOString().split('T')[0];
        params.dateEnd = new Date(this.date[1]).toISOString().split('T')[0];
      }
      
      if (!this.isAdmin) {
        params.organizations = this.organizations.map(organization => organization.id)
      }
      
      this.loading = true
      const pagination = await this.fetchReportUsersLoginLogout(params);
      this.loading = false
      this.pagination = pagination
    },
    milisecondsToFormat(ms) {
      let seconds = ms / 1000;
      const hours = parseInt(seconds / 3600);
      seconds = seconds % 3600;
      const minutes = parseInt(seconds / 60);
      seconds = seconds % 60;
      return hours + ":" + minutes + ":" + seconds
    }
  },
  async created() {
    const params = {
      page : 1
    }
    await this.fetchOrganizations({ pagination: false });

    if (!this.isAdmin) {
      params.organizations = this.organizations.map(organization => organization.id)
    }

    this.fetch(params)  
  }
}
</script>

<style>
</style>