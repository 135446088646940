<template>
  <div>
    <el-form-item v-for="field in fields" :key="field.id" :label="field.label" :prop="field.name" :error="(errors[field.name]) ? errors[field.name][0] : ''">
      <el-input v-model="form[field.name]" ></el-input>
    </el-form-item>
  </div>
</template>

<script>
export default {
  props: {
    fields : {
      required : true,
      type: Array,
      default: () => ([])
    },
    errors : {
      required : false,
      type: Object,
      default: () => ({})
    },
    rules : {
      required : false,
      type: Object,
      default: () => ({})
    },
    values : {
      required : false,
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      form: this.values
    }
  },
  methods: {
    getValues() {
      return this.form;
    }
  },
  watch: {
    values(val) {
      this.form = val
    }
  }
}
</script>

<style>

</style>