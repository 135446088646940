<template>
  <div class="pulse">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name : "Pulse"
}
</script>

<style scoped> 
.pulse {
  background-color: var(--hiperpbx-dark-green);
  border-radius: 50%;
  height: 5em;
  position: relative;
  width: 5em;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

 .pulse::after {
   background-color: var(--hiperpbx-dark-green);
   content : '';
   border-radius: 50%;
   height: 5em;
   position: absolute;
   width: 5em;
   z-index: 1;
   animation: pulse 4.9s ease infinite;
   animation-delay: 0.8s;
   left: 0;
 }

 @keyframes pulse {
   0% {
    transform: scale(1);
    opacity: 1;
   }
   20%, 100% {
     transform: scale(2);
     opacity: 0;
   }
 }
</style>