<template>
  <div v-loading.fullscreen.sync="loading" :element-loading-text="message" class='user-not-found'>
    <div v-if="notFound">
      <p>{{ $t("login.user-not-found") }}</p>
      <el-button type="text" @click="$auth.logout()">{{ $t('common.go-back') }}</el-button>
    </div>
  </div>
</template>

<script>
import http from '@/api/auth'
import cookie from '@/plugins/cookie'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      notFound: false,
      loading: true,
      message: this.$t("login.loading-your-data"),
      auth0: process.env.VUE_APP_DOMAIN && process.env.VUE_APP_CLIENTID
    }
  },
  methods: {
    ...mapActions([
      'fetchUserData',
      'saveFirebaseConfiguration',
      'initializeFirebaseApp',
      'updateData',
      'setToken',
      'listenToUser',
      'listenToNewNotifications'
    ]),
    ...mapActions('zoho', ['fetchDataCenters', 'fetchAccessAndRefreshToken', 'setCode', 'setZohoTokens']),
    async start() {

      if (this.$auth.isAuthenticated) {
        const response = await http.loginApiKey(this.$auth.user.email)

        if (!response) {
          this.$auth.logout()
        }

        try {
          const { token, customToken } = response.data
          this.setToken(token)
          const firebaseConfig = await this.fetchUserData()

          this.saveFirebaseConfiguration(firebaseConfig)
          this.initializeFirebaseApp()

          await this.firebase.auth().signInWithCustomToken(customToken)

          this.listenToUser(this.user.uid);
          // this.listenToNewNotifications({uid:this.user.uid, last: 10});
          this.$store.dispatch("_users/fetchUsers");
            
          if (this.zohoClientId && this.zohoClientSecret) {
            this.initZoho()
          }

          window.location.href = '/home'
        } catch (error) {
          cookie.deleteCookie()

          if ('sessions' in error) {
            this.message = this.$t('login.the-limit-of-available-sessions-has-been-reached-please-try-again-later')
            setTimeout(() => {
              this.$auth.logout()
            }, 5000)
          }

          if ('redirect' in error) {
            this.message = this.$t('login.you-will-be-redirected-to', { url: error.redirect })
            this.logout(error.redirect)
          }

          if ('not-found' in error) {
            this.loading = false
            this.notFound = true
          }
        }
      } else {
        this.close()
      }
    },
    close() {
      this.message = this.$t("login.closing-session")
      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 5000)
    },
    logout(location) {
      this.$auth.logout({
        localOnly: true
      }),
        setTimeout(() => {
          window.location = location
        }, 5000)
    },
    async initZoho() {
      try {
        if (this.$route.query.code) {
          await this.setCode(this.$route.query.code);
        }

        if (this.$route.query.location) {
          await this.fetchDataCenters(this.$route.query.location);
        }

        await this.fetchAccessAndRefreshToken();

        if (this.$route.query.location && this.$route.query.code && this.$route.query.state && this.$route.query['accounts-server']) {
          if (this.$route.query.state === 'testing' && this.$route.query['accounts-server'] === 'https://accounts.zoho.com') {
            this.$store.commit('zoho/setConnected', true)
          }
        }
      } finally {
        this.$router.push({ name: 'Home' })
      }
    }
  },
  computed: {
    ...mapGetters({
      firebase: 'getFirebase',
      user: 'getUser',
    }),
    ...mapGetters('zoho', {
      zohoClientId: 'getClientId',
      zohoClientSecret: 'getClientSecret'
    }),
    authReady() {
      return this.$auth.loading
    }
  },
  watch: {
    authReady(value) {
      if (!value && this.auth0) {
        this.start()
      }
    }
  },
  async created() {
    if (!this.auth0) {
      const firebaseConfig = await this.fetchUserData();
      this.saveFirebaseConfiguration(firebaseConfig)
      await this.initializeFirebaseApp()
      this.listenToUser(this.user.uid);
      this.listenToNewNotifications(this.user.uid);
      this.$store.dispatch("_users/fetchUsers");

      if (this.zohoClientId && this.zohoClientSecret) {
        this.initZoho()
      }
    }
  }
}
</script>

<style scoped>
.user-not-found {
   height: 100vh;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-around;
   text-align: center;
 }
</style>