import AgentConsole from '../../views/AgentConsole'
import AgentDashboard from '../../views/AgentDashboard'
import CallDisposition from '../../views/agent-console/TheCallDisposition'
import TranscriptionBox from '../../views/agent-console/TheTranscriptionBox'
import TheNewCustomer from '../../views/agent-console/TheNewCustomer'
// import TheSearchCustomer from '../../views/agent-console/TheSearchCustomer'
import store from '@/store';

export default [
  {
    name : "AgentConsole",
    path : "/agent-console",
    component : AgentConsole,
    meta : {
      requiresAuth : true,
      access_control : ['ROLE_ADMIN','ROLE_AGENT','ROLE_MANAGER'],
      icon : "fas fa-headset",
      type : "link",
      isInMenu : true,
      title : 'main-menu.agent-console.title',
      order : 6
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters.getAgent) {
        return next({ name : 'Home'});
      }
      return next()
    },
    children : [
      {
        name : "AgentDashboard",
        path : "dashboard",
        components : {
          agentConsoleTools : AgentDashboard
        }
      },
      {
        name : "CallClasification",
        path : "call-clasification",
        components : {
          agentConsoleTools : CallDisposition
        }
      },
      {
        name : "CallTranscription",
        path : "call-transcription",
        components : {
          agentConsoleTools : TranscriptionBox
        }
      },
      {
        name : "AddNewCustomer",
        path : "new-customer",
        components : {
          agentConsoleTools : TheNewCustomer
        }
      },
      // {
      //   name : "SearchCustomer",
      //   path : "search-customer",
      //   components : {
      //     agentConsoleTools : TheSearchCustomer
      //   }
      // }
    ]
  }
]