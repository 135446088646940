
const createAgentGridItem = (user, organizationId, snap, extra) => {
  if (snap.key !== user.uid) {
    return {
      uid: snap.key,
      number: snap.val().number,
      extension: snap.val().extension,
      name: snap.val().name,
      call_started: snap.val().call_started,
      call_status: snap.val().call_status,
      call_logged: snap.val().call_logged,
      chat_logged: snap.val().chat_logged,
      chat_center_id: snap.val().chat_center_id,
      in_break: snap.val().in_break,
      break_name: snap.val().in_break ? snap.val().break_name : "",
      break_started_at: snap.val().in_break ? snap.val().break_started_at : null,
      organization: organizationId,
      extra: extra,
      calls_total: snap.val().calls_total,
      logged_started_at: snap.val().logged_started_at,
      call_type: snap.val().call_type || null,
      picture: snap.val().picture ?? "",
      groupNumber: snap.val( ).groupNumber ?? 0
    }
  }
  return { ...snap.val(), uid: snap.key, organization: organizationId, extra: extra }
}

const defaultDashboardValues = () => ({
  times: {
    loggin: 0,
    talking: 0,
    break: 0
  },
  logged_started_at: 0,
  break_started_at: 0,
  calls_per_hour: [],
  calls_total: {
    incoming: 0,
    outgoing: 0,
    manual: 0,
    transfer: 0
  },
  chats_total: {
    normal: 0,
    whatsapp: 0
  }
})

const state = {
  showBreakModal: false,
  listeningOrganizationsIds: [],
  alreadyListening: false,
  agentsDashboardList: [],
  defaultDashboardValues: defaultDashboardValues(),
  agentsRefsList: [],
  agent: null,
  breaksDashboard: []
}

const getters = {
  getAgentDashboardBreak: (state) => state.agent,
  getBreaksDashboard: (state) => state.breaksDashboard,
  getShowBreakModal: (state) => state.showBreakModal,
  getAgentsDashboardList: (state) => state.agentsDashboardList,
  getDefaultDashboardListValues: (state) => state.defaultDashboardValues,
  getAgentDashboardFromList: (state) => (uid) => state.agentsDashboardList.find(agent => agent.uid === uid),
}

const mutations = {
  SET_AGENT: (state, agent) => state.agent = agent,
  TOGGLE_ALREADY_LISTENING_LIST: (state) => (state.alreadyListening = !state.alreadyListening),
  ADD_AGENT_DASHBOARD_LIST: (state, agent) => state.agentsDashboardList.push(agent),
  REMOVE_AGENT_DASHBOARD_LIST: (state, uid) => state.agentsDashboardList = state.agentsDashboardList.filter(agent => uid !== agent.uid),
  UPDATE_AGENT_DASHBOARD_LIST: (state, agent) => (state.agentsDashboardList = state.agentsDashboardList.map(a => a.uid === agent.uid ? { ...agent } : a)),
  UPDATE_AGENT_LIST: (state, agent) => state.defaultDashboardValues = { ...state.defaultDashboardValues, ...agent },
  ADD_REF_LIST: (state, data) => (state.agentsRefsList.push(data)),
  REMOVE_REF_LIST: (state, key) => state.agentsRefsList = state.agentsRefsList.filter(ref => ref.uid !== key),
  TOGGLE_BREAK_MODAL: (state) => state.showBreakModal = !state.showBreakModal,
  SET_BREAKS_DASHBOARD: (state, value) => state.breaksDashboard = value,
}

const actions = {
  listenAgentsDashboardArray({ dispatch }, organizationIdsArray = []) {
    Array.from(organizationIdsArray).forEach(organizationId => {
      //salteo las que ya estaba escuchando...
      if (state.listeningOrganizationsIds.includes(organizationId)) return;

      //agrego si no la estaba escuchando...
      state.listeningOrganizationsIds.push(organizationId);
      dispatch('startListenAgentsDashboard', organizationId);
    });


    //elimino las que dejé de escuchar.
    [...state.listeningOrganizationsIds].forEach((storeId, idx) => {
      if (!organizationIdsArray.includes(storeId)) {
        state.listeningOrganizationsIds.splice(idx, 1);
        dispatch('stopListeningAgentsDashboard', storeId);
        return;
      }
    });
  },

  stopListeningAgentsDashboard({ commit }, organizationId) {
    const agentsToRemove = state.agentsDashboardList.filter(agent => agent.organization === organizationId);

    Array.from(agentsToRemove).forEach(agent => {
      const agentRef = state.agentsRefsList.find(ref => ref.uid === agent.uid)
      agentRef.ref.off()
      commit('REMOVE_REF_LIST', agent.uid)
      commit('REMOVE_AGENT_DASHBOARD_LIST', agent.uid);
      commit('UPDATE_AGENT_LIST', defaultDashboardValues())
    });

    //TODO: Ver si también tengo que dejar de escuchar el firebase.ref( )

  },

  startListenAgentsDashboard({ rootGetters, commit, state }, organizationId) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase;

    firebase.database().ref(`organization/${organizationId}/dashboard`).on('child_added', (snap) => {
      const agent = createAgentGridItem(user, organizationId, snap, 'child-added-grid');
      const alreadyListening = state.agentsDashboardList.find(a => a.uid === agent.uid);
      if (!alreadyListening) {
        commit('ADD_AGENT_DASHBOARD_LIST', agent);
      }

      if (agent.uid === user.uid) {
        commit('UPDATE_AGENT', agent)
      }

      const ref = firebase.database().ref(`organization/${organizationId}/dashboard/${snap.key}`);

      ref.on('value', (snap) => {
        const agent = createAgentGridItem(user, organizationId, snap, 'onvalue-grid');

        commit('UPDATE_AGENT_DASHBOARD_LIST', agent);
        if (agent.uid === user.uid) {
          commit('UPDATE_AGENT', agent)
        }
      });

      commit('ADD_REF_LIST', { ref, uid: agent.uid })
    });

    firebase.database().ref(`organization/${organizationId}/dashboard`).on('child_removed', (snap) => {
      const agentRef = state.agentsRefsList.find(ref => ref.uid === snap.key)

      if (agentRef && snap.key !== user.uid) {
        agentRef.ref.off()
        commit('REMOVE_REF_LIST', snap.key)
      }

      commit('REMOVE_AGENT_DASHBOARD_LIST', snap.key);
      commit('UPDATE_AGENT', defaultDashboardValues())
    });
  },
  doLogoutAction({ rootGetters }, { organizationId, groupNumber, uid }) {
    const firebase = rootGetters.getFirebase;
    firebase.database().ref(`organization/${organizationId}/${groupNumber}/agents/${uid}`).remove();
  },
  unBreakAction({ rootGetters }, { organizationId, groupNumber, uid }) {
    const firebase = rootGetters.getFirebase;
    firebase.database().ref(`organization/${organizationId}/${groupNumber}/agents/${uid}/actions/call_center`).push({
      type: 'unbreak',
    });
  },
  doBreakAction({ rootGetters }, data) {
    const uid = state.agent.uid
    const firebase = rootGetters.getFirebase;
    const groupNumber = state.agent.groupNumber;
    const organizationId = state.agent.organization;

    firebase.database().ref(`organization/${organizationId}/${groupNumber}/agents/${uid}/actions/call_center`).push({
      type: 'break',
      id_break: data.id,
      name: data.name
    });
  },
  fetchBreaksByOrganization({ rootGetters, commit }, organizationId) {
    const firebase = rootGetters.getFirebase;
    firebase.firestore().collection('breaks').doc(organizationId + "").onSnapshot((data) => {
      if (data.exists) {
        const breaks = data.data().breaks.filter(b => !('status' in b) || b.status === 'A');
        commit('SET_BREAKS_DASHBOARD', breaks);
      } else {
        commit("SET_BREAKS_DASHBOARD", []);
      }
    });
  },
  restartAgentDashboard({ rootGetters }, organizationId) {
    const firebase = rootGetters.getFirebase;
    firebase.database().ref(`organization/${organizationId}/actions`).push({
      type: "generate_agents_dashboard"
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
} 