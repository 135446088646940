<template>
    <div>
        <el-dropdown trigger="click" v-if="!mobile" @visible-change="isVisible" ref="dropdown">
            <span class="el-dropdown-link">
                <el-tooltip effect="dark" :content="$t('keypad.title')" :placement="tooltipPosition">
                    <button :class="{'rounded' : roundedButton}">
                        <i class="icon-hiperme_iconos95 icon"></i>
                    </button>
                </el-tooltip>
            </span>
            <el-dropdown-menu slot="dropdown">
                <the-keypad @closeKeypad="closeKeypad" :showOthersButtons="onlyKeypad" :janus="janus" :_index="_index" ref="keypad"></the-keypad>
            </el-dropdown-menu>
        </el-dropdown>
        <div v-else>
            <button :class="{'rounded' : roundedButton}">
                <i class="icon-hiperme_iconos95 icon"  @click="drawer = true"></i>
            </button>
            <el-drawer :visible.sync="drawer" direction="btt" size='60%' :with-header="false"  :append-to-body="true">
                <the-keypad :janus="janus" :_index="_index"></the-keypad>
            </el-drawer>
        </div>
    </div>
</template>

<script>

import TheKeypad from '@/components/webrtc/keypad/TheKeypad';

export default {
    name : "Keypad",
    props : {
        showOthersButtons : {
            type : Boolean,
            default : true
        },
        roundedButton : {
            type : Boolean,
            default : false
        },
        janus : {
            required : true
        },
        _index : {
            default : ''
        },
        onlyKeypad : {
            required : false,
            default : false,
            type : Boolean
        },
        tooltipPosition : {
            required : false,
            default : 'bottom',
            type : String
        }
    },
    data() {
        return {
            mobile : false,
            drawer: false,
            isClosed : false,
        }
    },
    components : {
        TheKeypad,
    },
    methods : {
        isVisible(value) {
            if (this.presence) {
                if (value) {
                    this.$refs.keypad.setFocus();
                } else {
                    this.$refs.keypad.showKeypad();
                }
            }
        },
        closeKeypad() {
          this.$refs.dropdown.hide();
        }
    }
}

</script>

<style scoped>
    button {
        margin-top: 0px!important;
        border: none;
        outline: none;
        color: var(--menu-color);
        background-color: rgba(0,0,0,0)
    }


    .rounded {
        border-radius: 50%!important;
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        transition: all 0.2s ease-in-out;
    }

    .rounded:hover {
        background-color: var(--blue-grey);
        color: white
    }

    .hide {
      display: none;
    }
</style>