<template>
  <el-drawer :title="$t('tickets.search-client')" :visible.sync="show" :append-to-body="true" direction="rtl" ref="drawer" @closed="closed" :modal="false">
    <div class="container"  v-loading="searching">
      <div v-if="!user">
        <el-form :model="form">
          <el-input v-model="form.search" :placeholder="$t('tickets.search-client-by')" @keydown.enter.prevent.native="search"></el-input>
          <el-button type="primary" @click="search">{{ $t("common.search") }}</el-button>
          <el-button @click="closed" v-if="form.search && searched">{{ $t("common.reset") }}</el-button>
        </el-form>
        <div v-if="searched">
          <p>{{ message }}</p>
          <div v-if="users.length > 0">
            <div v-for="user in users" :key="user.id" class="search-result" @click="fetchUser(user.id)">
              <user-item :user="{ fullname : user.name, email : user.email, picture : user.avatar }" :show-status="false" :show-email="true" ></user-item>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-page-header @back="user = null" content=""></el-page-header>
        <div class="client-info">
          <the-client-info-item label="Nombre" :value="user.name"></the-client-info-item>
          <the-client-info-item label="Email" :value="user.email"></the-client-info-item>
          <the-client-info-item v-for="info in user.contact_info" :key="info.label" :label="info.label" :value="info.value" :type="info.type"></the-client-info-item>
          <!-- <el-button>{{ $t("common.edit") }}</el-button> -->
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import ticket from '@/api/tickets'
import UserItem from '@/components/common/UserItem'
import TheClientInfoItem from '@/components/agent-console/TheClientInfoItem'
export default {
  components: {
    UserItem,
    TheClientInfoItem
  },
  data() {
    return {
      show : false,
      form : {
        search : ""
      },
      searching : false,
      users : [],
      searched : false,
      user : null
    }
  },
  methods : {
    async search() {
      try {
        this.searched = false
        this.searching = true
        const response = await ticket.searchClient(this.form.search)
        this.searched = true
        this.users = response.data.data
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.searching = false
      }
    },
    async fetchUser(id) {
      try {
        this.searching = true
        const response = await ticket.fetchUser(id, {
          info : true
        })
        this.user = response.data.data
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.searching = false
      }
    },
    closed() {
      this.form.search = ''
      this.searching = false
      this.users = []
      this.searched = false
      this.user = null
    }
  },
  computed : {
    message() {
      if (this.users.length === 0) {
        return "no hay resultados de la busqueda"
      }
      return this.$t("agent-console.search-results")
    }
    
  }
}
</script>

<style scoped>
.container {
  padding: var(--column)
}

ul {
  list-style: none;
  margin-bottom: var(--column);
}

form,
.search-result {
  margin-bottom: var(--column);
}

.search-result {
  border:1px solid white;
  padding: var(--column);
  border-radius: var(--radius);
}

.search-result:hover {
  border:1px solid var(--blue-grey);
  cursor: pointer;
}

.client-info {
  margin-top: var(--column);
}
</style>