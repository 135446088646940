<template>
  <div>
    <div class="avatar">
      <!-- <el-avatar :size="150" :src="avatar"></el-avatar> -->
      <user-item :user="{ picture : avatar }" :show-fullname="false" :show-status="false" class="picture" :size="90"></user-item>
      <el-button icon="fas fa-camera" circle class="button" @click="show = true; $emit('changeProfile')"></el-button>
    </div>
    <el-dialog :visible.sync="show" width="25%" :close-on-click-modal="close" :close-on-press-escape="close" :show-close="close" @closed="closed" append-to-body>
      <div v-if="active === 'picture'">
        <h2>{{ $t("profile-picture.profile-picture") }}</h2>
        <!-- <el-avatar :size="150" :src="avatar" class="picture" ></el-avatar> -->
        <user-item :user="{ picture : avatar }" :show-fullname="false" :show-status="false" class="picture" :size="150"></user-item>
        <div class="buttons">
          <el-button class="button" type="primary" @click="active = 'change'">{{ $t("profile-picture.change") }}</el-button>
          <el-button class="button" @click="active = 'remove'" v-if="!isUsingDefault">{{ $t("profile-picture.remove") }}</el-button>
        </div>
      </div>
      <div v-if="active === 'change'">
        <el-page-header @back="active = 'picture'" v-if="!loading"/>
        <div class="upload">
          <el-upload drag action="" :http-request="upload" :limit="1" :show-file-list="false" :thumbnail-mode="true" :disabled="loading" v-loading="loading">
            <div>
              <!-- <el-avatar :size="100" :src="avatar" ></el-avatar> -->
              <user-item :user="{ picture : avatar }" :show-fullname="false" :show-status="false" class="picture" :size="100"></user-item>
              <div class="el-upload__text" v-if="!loading">{{ $t("profile-picture.drop-your-file-here-or") }} <em>{{ $t("profile-picture.click-to-load") }}</em></div>
            </div>
          </el-upload>
        </div>
        <div class="upload message" style="margin-top:var(--column); align-items: center;" v-if="loading">
          <p style="margin-bottom: 0">{{ $t("profile-picture.uploading-image") }}</p>
          <el-button @click="cancelUpload" type="danger">{{ $t("common.cancel") }}</el-button>
        </div>
      </div>
      <div v-if="active === 'remove'">
        <el-page-header @back="active = 'picture'"/>
        <h2>{{ $t("profile-picture.remove-profile-picture?") }}</h2>
        <!-- <el-avatar :size="100" :src="defaultUri" class="picture" ></el-avatar> -->
        <user-item :user="{ picture : defaultUri }" class="picture" :size="150" :show-status="false" :show-fullname="false"></user-item>
        <p class="text-center" v-if="!loading">{{ $t("profile-picture.this-image-will-be-used-in-your-profile") }}</p>
        <p class="text-center" v-if="loading">{{ $t("profile-picture.removing-profile-picture") }}</p>
        <div class="buttons" v-if="!loading">
          <el-button class="button" type="danger" @click="remove">{{ $t("common.remove") }}</el-button>
          <el-button class="button" @click="active = 'remove'">{{ $t("common.cancel") }}</el-button>
        </div>
      </div>
      <div v-if="active === 'success'">
        <h2>{{ $t("profile-picture.this-will-be-your-profile-picture") }}</h2>
        <!-- <el-avatar :size="100" :src="avatar" class="picture"></el-avatar> -->
        <user-item :user="{ picture : avatar }" :show-fullname="false" :show-status="false" :size="150" class="picture"></user-item>
        <p class="text-center">{{ $t("profile-picture.the-change-may-take-a-couple-of-days-to-apply") }}</p>
        <div class="buttons">
          <el-button class="button" type="primary" @click="show = false">{{ $t("common.understood") }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UserItem from '@/components/common/UserItem';
import { mapGetters, mapActions } from 'vuex'
export default {
  props : {
    size: {
      required: false,
      default : 150,
      type : Number
    }
  },
  data() {
    return {
      show : false,
      active : 'picture',
      file : null,
      uploadTask : null,
      loading : false,
    }
  },
  computed : {
    ...mapGetters({ user : 'getUser', token : 'geToken', firebase : 'getFirebase', config : 'getConfiguration' }),
    close() {
      return !this.loading;
    },
    avatar() {
      return this.user.picture
    },
    defaultUri() {
      return `https://storage.googleapis.com/${this.config.storageBucket}/profile-pictures/default_${this.user.uid}.svg`
    },
    isUsingDefault() {
      return this.avatar === this.defaultUri
    }
  },
  methods : {
    ...mapActions(['updateProfilePicture']),
    upload(xhr) {
      this.file = xhr.file
      this.loading = true
      const storage = this.firebase.storage()
      this.uploadTask = storage.ref(`profile-pictures/${this.user.uid}`).put(this.file)
      setTimeout(() => {
        this.uploadTask.on('state_changed', null, () => {
          this.loading = false
        }, async () => {
          try {
            const url = await this.uploadTask.snapshot.ref.getDownloadURL()
            const user = this.firebase.auth().currentUser
            await user.updateProfile({ photoURL: url })
            await this.updateProfilePicture(url)
            this.active = "success"
            this.loading = false
          } catch (error) {
            console.log(error)
          }
        })
      }, 5000)
    },
    cancelUpload() {
      this.uploadTask.cancel()
      this.closed()
    },
    async remove() {
      this.loading = true
      try {
        const user = this.firebase.auth().currentUser
        await user.updateProfile({ photoURL: this.defaultUri })
        this.updateProfilePicture(this.defaultUri)
        this.loading = false
        this.active = "success"
      } catch (error) {
        console.log(error)
      }
    },
    closed() {
      this.active = 'picture'
      this.file = null
      this.uploadTask = null
      this.loading = false
    }
  },
  components : {
    UserItem
  }
}
</script>

<style scoped>
.avatar {
  position: relative;
  width: fit-content;
}
.avatar .button {
  position: absolute;
  bottom: 0;
  right: 0;
}

.upload {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.message {
  border: 1px solid #EBEEF5;
  padding: var(--column);
  width: 80%;
  margin: 0 auto;
  border-radius: var(--radius)
}

.dialog-footer {
  display: inline-flex;
  width: 100%;
}

h2 {
  text-align: center;
  word-break: break-word;
}
.picture {
  display: block;
  margin: 0 auto;
  margin-bottom: var(--column);
  width: fit-content;
}

.buttons {
  display: inline-flex;
  width: 100%;
  margin-bottom: var(--column)
}

.buttons button {
  flex: 1
}
</style>