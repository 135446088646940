<template>
  <div>
    <div class="keypad-header">
      <button aria-label="Go back" @click="goBack"><i class="fas fa-arrow-left"></i></button>
      <slot name="header"></slot>
    </div>
    <div class="keypad-container center">
      <slot name="container"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name : "TheKeypadContainer",
  props : {
    goBack : {
      required : false,
    }
  }
}
</script>

<style scoped>
.keypad-header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.keypad-container {
  overflow-y: auto;
  height: 350px;
  padding-right: 5px;
}

button {
  background-color: white;
  border: none;
  color: var(--blue-grey);
  outline: none;
}

.information {
  text-align: center;
  color: var(--blue-grey);
}

.center .fas{
  font-size: 28px;
  margin: calc(var(--column) * 2) 0;
}
</style>