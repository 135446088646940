<template>
	<div class="activity box">
        <div class="header" :class="{ 'focus' : focusHeader }">
            <input type="search" :placeholder='$t("common.search")' v-model="itemToSearch" @focus="focusHeader = true" @focusout="focusHeader = false">
            <el-dropdown trigger="click" placement="bottom-start" v-if="activeServices >= 2"> 
              <i class="el-dropdown-link fas fa-filter"></i>
              <el-dropdown-menu slot="dropdown">
                <el-checkbox-group v-model="activeFilter">
                    <el-dropdown-item v-for="item in availableFilters" :key="item.value">
                        <el-checkbox :label="item.value">{{ item.label }}</el-checkbox>
                    </el-dropdown-item>
                </el-checkbox-group>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
		<el-collapse class="items" v-model="activeList" accordion>
			<el-collapse-item name="active" :class="[{'acitivity-item--notEnabledNotification':!enabledNewMessageNotification}]">
                <template slot="title" >
                    <i :class="[{'header-icon fas fa-bell': enabledNewMessageNotification, 'header-icon fas fa-bell-slash': !enabledNewMessageNotification}]" style="margin-right: 10px;" @click="desactiveNewMessageNotification($event)"> </i>{{$t('agent-console.my-activity') + ' (' + (list.length) + ')'}}
                </template>
                <RecycleScroller :items="list" :item-size="97" key-field="key" v-slot="{ item }" v-if="list.length > 0" class='activity-items'>
                    <TheActivityItem :item="item"  :enabledNotification="enabledNewMessageNotification" :class="{ 'active' : item.activeItem, 'new-call' : item.active && item.type === 'call'}" @click.native="changeActive(item)"/>
                </RecycleScroller>
                <div v-else>
                    <p class="text-center">{{ $t('agent-console.no-activity') }}</p>
                </div>
            </el-collapse-item>
			<el-collapse-item :class="[{ 'pending' : pendingChats.length > 0 }, {'acitivity-item--notEnabledNotification':!enabledPendingNotification}]" name="pendingchats" v-if="getAgent.chat ">
                <template slot="title">
                    <i @click="desactivePendingNotification($event)" :class="[{'header-icon fas fa-bell': enabledPendingNotification, 'header-icon fas fa-bell-slash': !enabledPendingNotification}]" style="margin-right: 10px;"></i>{{$t('agent-console.pending-chats') + ' (' + (pendingChats.length) + ')'}}
                </template>
                <ul v-if="pendingChats.length > 0" class="activity-items">
                    <li v-for="(item, key) in pendingChats" :key="key" @click="showAceptChatWindow(item)">
                        <ThePendingChatItem :title="item.nick" :date="item.time" :type="item.type" :dept="item.dep_name" :tags="item.tags"></ThePendingChatItem>
                    </li>
                </ul>
                <div v-else>
                    <p class="text-center">{{ $t('agent-console.no-pending-chats') }}</p>
                </div>
            </el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import TheActivityItem from '@/components/agent-console/TheActivityItem';
import ThePendingChatItem from '@/components/agent-console/ThePendingChatItem';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'


export default {
    name: "TheActivityBox",
    data() {
        return {
            allFilters : [
                { index: 'call', label : this.$t('agent-console.filters.call'), value : "call" },
                { index: 'chat', label : this.$t('agent-console.filters.chat_open'), value : "chat_open" },
                { index: 'chat', label : this.$t('agent-console.filters.chat_closed'), value : "chat_closed" },
                { index: 'mail', label : this.$t('agent-console.filters.tickets'), value : "ticket" }
            ],
            itemToSearch : '',
            activeList : ['active'],
            activeFilter : [],
            focusHeader : false,
            enabledNewMessageNotification: true,
            enabledPendingNotification: true
        }
    },
    components : {
        TheActivityItem,
        ThePendingChatItem
    },
    methods: {
        ...mapActions(['setActiveItem','setActiveItem','setPendingChatAcept','toggleShowAcceptWindow']),
        ...mapGetters(['getPendingChats', 'getLoggedAt','getInteractWithPage']),
        changeActive(item) {
            if (item.type !== 'ticket') {
                this.setActiveItem(item.key)     
            } else {
                this.$router.push({ name : 'ticket', params: { ticketNumber: item.number} })
            }
        },
        showAceptChatWindow(item) {
            this.setPendingChatAcept(item);
            this.toggleShowAcceptWindow();
        },
        desactiveNewMessageNotification(event){
            const enabledNewMessageNotification = JSON.parse(localStorage.getItem('enabledNewMessageNotification'))
            localStorage.setItem('enabledNewMessageNotification',!enabledNewMessageNotification)
            this.enabledNewMessageNotification = !enabledNewMessageNotification
            event.stopPropagation()
        },
        desactivePendingNotification(event){
            const enabledPendingNotification = JSON.parse(localStorage.getItem('enabledPendingNotification'))
            localStorage.setItem('enabledPendingNotification',!enabledPendingNotification)
            this.enabledPendingNotification = !enabledPendingNotification
            event.stopPropagation()
        }
    },
    computed : {
        ...mapGetters(["getActivityList", "getAgent"]),
        tickets() {
            return this.$store.getters["getAllTickets"].map((ticket) => {
                return {
                    ...ticket,
                    key: ticket.number,
                    title: ticket.user.name,
                    type: 'ticket',
                    date: new Date(ticket.lastupdate)
                }
            })
        },
        list : {
            get() {
                const regexp = new RegExp(this.itemToSearch, 'ig');
                return [...this.getActivityList, ...this.tickets]
                .filter(item => {
                    if (this.activeFilter.length === 0) return item

                    if (this.activeFilter.includes('chat_closed') && item.type === 'chat') {
                        return item.active === false
                    }

                    if (this.activeFilter.includes('chat_open') && item.type === 'chat') {
                        return item.active === true
                    }

                    return this.activeFilter.includes(item.type)
                })
                .filter(item => this.itemToSearch === '' || regexp.test(item.title))
                .sort((a, b) => {      
                    const date1 = a.type === 'chat' || a.type === 'ticket' ? a.date : new Date(a.date)
                    const date2 = b.type === 'chat' || b.type === 'ticket' ? b.date : new Date(b.date)
                    if (date1 > date2) return -1;
                    if (date1 < date2) return 1;
                    return 0;
                })
            },
            set() {
                return []
            }
        },
        pendingChats() {
            return this.getPendingChats().sort((a, b) => {
                const date1 = a.date;
                const date2 = b.date;
                if (date1 > date2) return -1;
                if (date1 < date2) return 1;
                return 0;
            })
        },
        loggedAt() {
            return this.getLoggedAt()
        },
        allTheList() {
            return this.getActivityList
        },
        activeServices(){
            const services = [this.getAgent.call, this.getAgent.chat, this.getAgent.mail];
            console.log(services)
            return services.filter((element) => {
                return element
            }).length
        },
        availableFilters(){
            return this.allFilters.filter((element) => {
                return this.getAgent[element.index]
            })
        }
    },
    watch : {
        allTheList : {
            handler: function (val, old) {
                if (val.length > old.length && this.getInteractWithPage() && this.enabledNewMessageNotification) {
                    if (document.querySelector('#agent-notifications')) {
                        document.querySelector('#agent-notifications').play();
                    }
                }
            },
            deep: true
        },
        pendingChats : {
            handler: function (val, old) {
                if (val.length > old.length  && this.getInteractWithPage() && this.enabledPendingNotification) {
                    document.querySelector('#agent-pending-chat').play();
                }
            },
            deep: true
        }
    },
    created(){
        this.enabledNewMessageNotification = JSON.parse(localStorage.getItem('enabledNewMessageNotification')) ?? true
        this.enabledPendingNotification = JSON.parse(localStorage.getItem('enabledPendingNotification')) ?? true
        console.log(this.getAgent);
        
    }
};
</script>

<style>
.activity {
    flex: 1;
}

.activity .header {
    align-items: baseline;
    border: 1px solid #dcdfe6;
    border-radius: var(--radius);
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: var(--column);
    padding: calc(var(--column) / 2) var(--column);
    width: 100%;
}

.header input[type="search"] {
    flex: 1;
    border: none;
    width: 90%;
}

.header input[type="search"]:focus {
    outline: none;
}

.activity .header.focus {
    border-color: #409EFF;
}

.activity .el-collapse-item__header,
.activity .el-collapse-item__wrap {
    border-bottom: none!important;
}

.el-collapse {
    border: none!important;
}

.items li.active {
    border-radius: var(--radius);
    background-color: var(--light-blue);
}

.items li.new-call {
    background-color: var(--light-green);
}

.el-collapse-item__content  {
    padding-bottom: 0;
}

.el-collapse-item__wrap {
    max-height: 40vh;
    overflow-y: auto!important;
}
.acitivity-item--notEnabledNotification .el-collapse-item__header{
    background-color: rgba(128 , 128, 128, 0.2) !important;
}
.full {
    min-height: 93%
}

.full .el-collapse-item__wrap {
    max-height: 100%;
}

.pending .el-collapse-item__header{
    animation-name: pendings;
    animation-duration: 0.7s;
    animation-iteration-count: infinite;
}

@keyframes pendings {
  from { color: #303133;}
  to {color: var(--red)}
}

.activity-items { 
    display: flex;
    flex-direction: column;
}

.activity-items li {
    border-bottom: 1px solid#dcdfe6;
    padding: var(--column)
}

</style>
