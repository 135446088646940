import http from './http';

export default {
	loginCheck(email, password) {
		return http.post(`/login_check`, { username: email, password : password });
	},	
	forgotPassword(email) {
		return http.post("/forgot-password", { email : email })
	},
	checkRecoverPasswordToken(token) {
		return http.get(`/recover-password/${token}`)
	},
	recoverPassword(token, form) {
		return http.put(`/recover-password/${token}`, form)
	},
	loginApiKey(email) {
		return http.post("/login_api_key", { apikey : process.env.VUE_APP_APIKEY, email : email })
	}
};
