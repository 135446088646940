import List from "@/views/servers/mediacenter/List.vue"
import Form from '@/views/servers/mediacenter/Form.vue'

const routes = [
  {
    name: "mediaservers",
    path: "/configuration/servers/mediacenters",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.servers.mediacenter',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
	name: "mediaserversNew",
	path: "/configuration/servers/mediacenters/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    }
  },
  {
    name: "mediaserversEdit",
    path: "/configuration/servers/mediacenters/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    },
    props: true,
  },
];

export default routes;
