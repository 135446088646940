import * as firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/performance';
import 'firebase/functions';
import 'firebase/messaging';
import userapi from "@/api/user"
import createNotification from '../../plugins/notification';

const state = {
    firebase : false,
    configuration : {},
    fmcToken: null
};

const getters = {
    getConfiguration : (state) => state.configuration,
    getFirebase : (state) => state.firebase,
    getFmcToken: (state) => state.fmcToken
}

const mutations = {
    SET_CONFIGURACTION: (state, config) => (state.configuration = config),
    INIT_APP(state) {
        firebase.initializeApp(state.configuration)
        state.firebase = firebase;
    },
    SET_FIREBASE: (state, firebases) => (state.firebases = firebases),
    SET_FMC_TOKEN: (state, value) => state.fmcToken = value
}

const actions = {
    notifyUser(data) {
        return state.firebase.database().ref('users/' + data.uid).update(data.info)
    },
    notifyUserNewVideocall(data){
        let info = {}
        info['newVideocall@' + data.name ] = data.roomId + '@' + data.name;
        return state.firebase.database().ref('users/' + data.user.uid).update(info);
    },
    saveFirebaseConfiguration({commit}, configuration) {
        commit('SET_CONFIGURACTION', configuration);
    },
    initializeFirebaseApp({commit, getters, rootGetters }) {
        return new Promise((resolve) => {
            commit('INIT_APP');
            userapi.customToken()
            .then((response) => {
                const { data } = response;
                const token = data.customToken;
                const firebase = getters.getFirebase;
                firebase.auth().signInWithCustomToken(token)
                .then((user) => {
                    if (user) {
                        resolve()
                    }
                });
            })

            const messaging = firebase.messaging()
            messaging.onMessage((message) => {
                if (rootGetters.getShowNotifications) {
                    createNotification(message.notification.title)
                }
            })    

        })
    },
    firebaseLogout({ rootGetters }) {
        return new Promise((resolve) => {
            const user = rootGetters.getUser;
            const firebase = rootGetters.getFirebase
            if (firebase) {
                firebase.database().ref('logout').child(user.uid).remove().then(() => {
                    if (window.location.pathname !== '/logout') {
                        window.location.href = '/logout';
                    }
                    return resolve();
                })
            } else {
                return resolve();
            }
        })
    },
    async geTokenAndSaveIt({ rootGetters, commit }) {
        const user = rootGetters.getUser
        const firebase = rootGetters.getFirebase
        const messaging = firebase.messaging()
        const token = await messaging.getToken({ vapidKey: process.env.VUE_APP_CLOUD_MESSAGING_KEY_PAR })
        await firebase.firestore().collection('users').doc(user.uid).collection('tokens').doc(token).set({ active : true, date : firebase.firestore.FieldValue.serverTimestamp() , deviceType : 'web' })
        commit('SET_FMC_TOKEN', token)
    },
    async removeToken({ rootGetters }) {
        const user = rootGetters.getUser
        const firebase = rootGetters.getFirebase
        const messaging = firebase.messaging()
        const token = await messaging.getToken({ vapidKey: process.env.VUE_APP_CLOUD_MESSAGING_KEY_PAR })
        await messaging.deleteToken()
        await firebase.firestore().collection('users').doc(user.uid).collection('tokens').doc(token).delete()
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
