<template>
  <div class="input el-input el-input--prefix">
    <input type="search" autocomplete="off" :placeholder="placeholder" class="el-input__inner" :value="value" @input="$emit('update', $event.target.value)" v-on="$listeners" @keyup.enter="$emit('enter')" @search="$emit('enter')"/>
    <span class="el-input__prefix"><i class="el-input__icon fas fa-search"></i></span>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      required: false,
      type: String,
      default: "Buscar",
    },
    value: {
      required: false,
      type: String,
      default: "",
    }
  },
  model: {
    prop: "value",
    event: "update",
  },
};
</script>

<style scoped>
.fa-search {
  color: var(--hiperpbx-green)
}

input {
  background-color: rgb(243,243,243); 
  color: rgb(36,37,36);
  border-radius: 4px;
  border: 1px solid #DCDFE6;
}

.light input{
  background-color: white;
}

::placeholder {
  color: rgb(125,126,125)
}
</style>