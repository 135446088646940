<template>
    <div>
    <the-form :form="form" :store="store" class="crud form help-padding" :id="id" :edit="edit" :entity="entity" :title="title" :submit="submit"></the-form>
    </div>
</template>

<script>
import form from './form'
import TheForm from '@/components/crud/TheForm'
export default {
  props : {
    entity : {
      required : false,
    },
    id : {
      required : false,
      type : String,
      default : "0"
    }
  },
  data() {
    return {
      store : "livehelperchats",
      form
    }
  },
  components : {
    TheForm
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? 'Editar Chat Helper' : "Crear servidor Chat Helper"
    }
  },
  methods : {
    submit(data) {
      return data;
    }
  }
}
</script>