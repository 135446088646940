<template>
  <div>
    <audio ref="audio" loop>
      <source :src="src" type="audio/ogg" />
    </audio>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    stopAudio: {
      required: false,
      default: true,
    },
    src: {
      required: false,
      default: process.env.VUE_APP_DEFAULT_RINGTONE
    }
  },
  methods: {
    play() {
      //if (this.interactWithPage) {
      this.$refs.audio.play()
      //}
    },
    stop() {
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
    }
  },
  mounted() {
    this.$refs.audio.volume = this.volume
    if (typeof this.$refs.audio.sinkId !== "undefined") {
      if (this.$refs.audio.setSinkId) {
        this.$refs.audio.setSinkId(this.outputDevice)
      }
    }
    //this.play();
  },
  unmouted() {
    this.stop()
  },
  beforeDestroy() {
    this.stop()
  },
  computed: {
    ...mapGetters({
      volume: 'getOutputAudioVolume',
      outputDevice: 'getOutputAudioDevice',
      interactWithPage: 'getInteractWithPage'
    })
  },
  watch: {
    volume(value) {
      this.$refs.audio.volume = value
    },
    outputDevice(value) {
      if (typeof this.$refs.audio.sinkId !== "undefined") {
        if (this.$refs.audio.setSinkId) {
          this.$refs.audio.setSinkId(value)
        }
      }
    },
    stopAudio(value) {
      if (value) {
        this.stop();
      } else {
        this.play();
      }
    }
  }
}
</script>

<style>
</style>