<template>
<div class="crud">
  <the-crud :title="title" :store="store" :columns="columns" :canDelete="true" :search="search">
    <template v-slot:action-buttons="scope">
      <el-dropdown-item icon="fas fa-key" @click.native="viewLicenses(scope.row)">{{ $t('administration.licenses.title') }}</el-dropdown-item>
      <el-dropdown-item icon="fas fa-sync-alt" @click.native="refreshLicense(scope.row)">{{ $t('administration.licenses.refresh') }}</el-dropdown-item>
    </template>
  </the-crud>
</div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'
export default {
  components : {
    TheCrud
  },
  data() {
    return { 
        closeModal : true,
        server : null,
        confirmationValue: '',
        confirmationMessage: '',
        title : this.$t("administration.clients.title"),
        store : 'clients',
        search : ['name'],
        columns : [
            { index : 'id', label : '#id', sortable : true },
            { index : 'name', label : this.$t('administration.clients.name'), sortable : true },
            { index : 'organizations.name', label : this.$t('administration.clients.organizations'), type : 'tags' },
            { index : 'detail', label : this.$t('administration.clients.description'), "hide in table": true },
            { index : 'user', label : this.$t('administration.clients.user'), "hide in table": true , type:'join', options : ['user.name'," ",'user.lastname'] },
            { index : 'created', label : this.$t('administration.clients.date'), type: 'date', "hide in table": true },
        ]
    }
  },
  methods: {
    viewLicenses(client) {
      this.$router.push({ name: 'clientsLicenses', params: { id: client.id.toString() }})
    },
    async refreshLicense(client) {
      await this.$store.dispatch('clients/refreshLicense', client.id)
      this.$message({
        type: 'success',
        showClose: true,
        message: this.$t('administration.licenses.the-licenses-values-were-updated')
      });
    }
  }
}
</script>