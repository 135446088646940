import http from './http';

export default {
  changeLogo(id, file) {
    const form = new FormData();
    form.append('file', file);
    return http.post(`organizations/${id}/update-logo` , form, {
      headers: { "Content-Type": "multipart/form-data" }
    })
  },
  removeLogo(id) {
    return http.delete(`organizations/${id}/remove-logo`)
  }
};
