<template>
  <div>
    <div class="ticket-info user">
      <h3>{{ $t("tickets.client-info") }}</h3>
      <div v-if="user" class="ticket-user">
        <ul>
          <li><span>{{ $t("common.name") }}</span>: {{ user.name }}</li>
          <li><span>{{ $t("common.email") }}</span>: {{ user.email }}</li>
        </ul>
        <ul v-if="user.info">
          <li v-for="(item, index) in user.info.filter(_item => _item.value)" :key="index">
            <span>{{ item.label }}</span>: {{ item.value }}
            <phone-button v-if="item.type === 'phone' && getLoggedInCallCenter" :janus="janus" :extension="item.value"
              :displayname="item.value" @click.native="calling({ number: item.value, title: item.value })">
            </phone-button>
          </li>
        </ul>
      </div>
    </div>

    <div class="ticket-info user">
      <h3>{{ $t("tickets.ticketinfo") }}</h3>
      <p>{{ $t("tickets.created") }} {{ created | calendar }}</p>
      <el-form :model="ticketInfo" size="small" label-position="left" v-loading="!dataLoaded" label-width="120px">
        <el-form-item :label="$t('tickets.status')">
          <el-select v-model="ticketInfo.status" :placeholder="$t('common.no-selected')" class="ticket-status"
            @change="dialogVisible = true; updatingAttr = 'status'">
            <el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
              <div class="ticket-option">
                <span>{{ item.name }}</span>
                <TheTickePriorityTag :name="item.state" :state="item.state"></TheTickePriorityTag>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tickets.assigned-to-staff')">
          <el-select v-model="ticketInfo.assigned" :placeholder="$t('common.no-selected')" @change="updateAssigned('staff')" filterable>
            <el-option v-for="item in staffOptions" :key="item.staff_id" :label="`${item.firstname} ${item.lastname}`"
              :value="item.staff_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tickets.assigned-to-team')">
          <el-select v-model="ticketInfo.team" :placeholder="$t('common.no-selected')" @change="updateAssigned('team')" filterable>
            <el-option v-for="item in teamsOptions" :key="item.staff_id" :label="item.name"
              :value="item.team_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tickets.priority')">
          <el-select v-model="ticketInfo.priority" :placeholder="$t('common.no-selected')" @change="updatePriority">
            <el-option v-for="item in priorityOptions" :key="item.priority_id" :label="item.priority_desc"
              :value="item.priority_id">
              <div class="ticket-option">
                <span>{{ item.priority_desc }}</span>
                <el-tag class="ticket-tag" size="mini" effect="dark" :type="item.tag_type">{{ item.priority }}</el-tag>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tickets.departament')">
          <el-select v-model="ticketInfo.department" :placeholder="$t('common.no-selected')"
            @change="updateDepartament" filterable>
            <el-option v-for="item in departmentOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('tickets.sla')">
          <el-select v-model="ticketInfo.sla" :placeholder="$t('common.no-selected')"
            @change="dialogVisible = true; updatingAttr = 'sla'">
            <el-option v-for="item in slaOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <div class="ticket-info ">
      <h3>{{ $t("tickets.events") }}</h3>
      <el-timeline class="eventsContainer" ref="scrollContainerEvents" @scroll="handleScroll">
        <el-timeline-item v-for="event in ticket.thread.events.data" :key="event.id" :timestamp="getCreatedDateFromEvent(event.created)">
          <TheThreadEvent :event="event" />
        </el-timeline-item>
      </el-timeline>
    </div>

    <el-dialog width="40%" v-loading="saveing" title="Cambiar estado de ticket" :visible.sync="dialogVisible">
      <el-form :model="formStatus" size="small" label-position="left">
        <el-form-item :label="$t('tickets.status')">
          <el-select v-model="upadtingValue" placeholder="Select" class="ticket-status">
            <el-option v-for="item in updatingOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <vue-editor v-model="reason" class="text-editor"></vue-editor>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updatAttr">{{
            $t("common.save")
        }}</el-button>
        <el-button @click="dialogVisible = false">{{
            $t("common.cancel")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import TheTickePriorityTag from "@/components/ticket/TheTickePriorityTag";
import PhoneButton from '../webrtc/PhoneButton.vue';
import TheThreadEvent from '@/components/ticket/TheThreadEvent';

export default {
  name: "TheTicketInfo",
  props: {
    ticketId: {
      required: true,
      default: 0,
      type: Number,
    },
    status: {
      required: true,
    },
    priority: {
      required: true,
    },
    created: {
      required: true,
    },
    department: {
      required: true,
    },
    assigned: {
      required: true,
    },
    user: {
      required: true,
    },
    ticket: {
      required: true
    }
  },
  data() {
    return {
      ticketInfo: {
        status: this.status.id,
        priority: this.priority.priority_id,
        department: this.department ? this.department.id : null,
        assigned: this.assigned ? this.assigned.staff_id : null,
        sla: this.ticket.sla ? this.ticket.sla.id : null,
        team: this.ticket.team ? this.ticket.team.team_id : null,
      },
      teamsOptions: [],
      staffOptions: [],
      statusOptions: [],
      priorityOptions: [],
      departmentOptions: [],
      slaOptions: [],
      dataLoaded: false,
      dialogVisible: false,
      title: "",
      formStatus: {
        id: 0,
      },
      reason: "",
      saveing: false,
      showModalEdit: false,
      fieldEdit: null,
      updating: false,
      activeForm: 0,
      updatingAttr: "",
      loadingEvents: false,
      currentEventsPage: 0
    };
  },
  methods: {
    ...mapActions(["fetchDataToUpdateTheTicket", "updateTicket", "updateFormFields", 'fetchEventsByTicketId']),
    getCreatedDateFromEvent(created) {
      const [datePart, timePart] = created.split(' ');
      const [year, month, day] = datePart.split('-').map(Number);
      const [hours, minutes, seconds] = timePart.split(':').map(Number);
      const utcDate = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
      return utcDate.toLocaleString('es-ES')
    },
    getDataToUpdateTheTicket() {
      this.fetchDataToUpdateTheTicket().then((data) => {
        this.dataLoaded = true;
        this.staffOptions = data.staff.sort((a, b) => {
          var textA = a.firstname.toUpperCase();
          var textB = b.firstname.toUpperCase();
          return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        });
        this.teamsOptions = data.teams;
        this.statusOptions = data.ticket_status.map((status) => {
          status.tag_type = "success";
          if (status.state === "open") {
            status.tag_type = "";
          }
          return status;
        });
        this.priorityOptions = data.ticket_priority.map((priority) => {
          priority.tag_type = "";

          if (priority.priority === "high") {
            priority.tag_type = "warning";
          }

          if (priority.priority === "emergency") {
            priority.tag_type = "danger";
          }

          return priority;
        });
        this.departmentOptions = data.departments;
        this.slaOptions = data.sla;
      });
    },
    updateStatus() {
      this.saveing = true;
      this.updateTicket({
        ticket_id: this.ticketId,
        update: "change-status",
        id: this.ticketInfo.status,
        reason: this.reason
      }).then(() => {
        this.saveing = false;
        this.dialogVisible = false;
        this.$message({
          message: this.$t("tickets.ticket-updated-status-to", {
            status: this.statusOptions.find(
              (s) => s.id === this.ticketInfo.status
            ).name,
          }),
          type: "success",
        });
      });
    },
    updateAssigned(type) {
      this.updateTicket({
        ticket_id: this.ticketId,
        update: type === "staff" ? "assignee" : "team",
        id: type === "staff" ? this.ticketInfo.assigned : this.ticketInfo.team,
      }).then(() => {
        const user = type === "staff" ? this.staffOptions.find((u) => u.staff_id === this.ticketInfo.assigned) : this.teamsOptions.find((t) => t.team_id === this.ticketInfo.team);
        this.$message({
          message: this.$t("tickets.ticket-assigned-to", {
            fullname: (type === 'user') ? `${user.firstname} ${user.lastname}` : user.name,
          }),
          type: "success",
        });
      });
    },
    updatePriority() {
      this.updateTicket({
        ticket_id: this.ticketId,
        update: "change-priority",
        id: this.ticketInfo.priority,
      }).then(() => {
        const priority = this.priorityOptions.find(
          (p) => p.priority_id === this.ticketInfo.priority
        );
        this.$message({
          message: this.$t("tickets.ticket-updated-priority-to", {
            priority: priority.priority_desc,
          }),
          type: "success",
        });
      });
    },
    updateDepartament() {
      this.updateTicket({
        ticket_id: this.ticketId,
        update: "transfer",
        id: this.ticketInfo.department,
      }).then(() => {
        const department = this.departmentOptions.find((d) => d.id === this.ticketInfo.department
        );
        this.$message({
          message: this.$t("tickets.ticket-updated-priority-to", {
            priority: department.name,
          }),
          type: "success",
        });
      });
    },
    updateSla() {
      this.saveing = true;
      this.updateTicket({
        ticket_id: this.ticketId,
        update: "change-sla",
        id: this.ticketInfo.sla,
        reason: this.reason
      }).then(() => {
        this.saveing = false;
        this.dialogVisible = false;
        this.$message({
          message: this.$t("tickets.ticket-updated-sla-to", {
            sla: this.statusOptions.find(
              (s) => s.id === this.ticketInfo.sla
            ).name,
          }),
          type: "success",
        });
      });
    },
    updatAttr() {
      if (this.updatingAttr === 'status') {
        this.updateStatus();
      } else {
        this.updateSla();
      }
    },
    calling(value) {
      this.$store.commit('SET_CALLING_FROM_MAILCENTER', value)
      this.$router.push('/agent-console')
    },
    async updateFormField() {
      this.updating = true
      const values = await this.$refs.formUpdate.getFieldsAndValues()
      try {
        await this.updateFormFields({ ticketId: this.ticketId, data: { forms: [values] } });
        this.$message({ message: 'Se ha actualizado el ticket', type: "success" });
        this.showModalEdit = false
      } finally {
        this.updating = false;
      }
    },
    beforeClose() {
      this.showModalEdit = false
      this.fieldEdit = null
    },
    async updateEvents(){
      await this.fetchEventsByTicketId({ticketId: this.ticket.ticket_id, page: this.currentEventsPage, initialize: false})
    },
    async handleScroll() {
      // const scrollHeight = this.$refs.scrollContainer.scrollHeight;
      const scrollTop = this.$refs.scrollContainerEvents.scrollTop;
      // const clientHeight = this.$refs.scrollContainer.clientHeight;
      if (scrollTop == 0 && !this.loading) {
        if(this.currentEventsPage > 0){
          this.loadingEvents = true;
          await this.updateEvents();
          this.currentEventsPage--;
          this.$forceUpdate();
          this.moveScrollTo('bottom');
        }
        this.loading = false;
      }
      
    },
    moveScrollTo(position){
      this.$nextTick(() => {
        const scrollContainer = this.$refs.scrollContainerEvents;
        if (scrollContainer) {
          switch(position){
            case 'top':
              scrollContainer.scrollTop = 0;
            break;
            case 'mid':
              scrollContainer.scrollTop = scrollContainer.scrollHeight/2;
            break;
            case 'bottom':
              scrollContainer.scrollTop = scrollContainer.scrollHeight;
            break;
            default:
              scrollContainer.scrollTop = scrollContainer.scrollHeight;
          }
        }      
      });
    }
  },
  async created() {
    this.getDataToUpdateTheTicket();
    
    this.currentEventsPage = this.ticket.thread.events.total_pages;
    await this.fetchEventsByTicketId({ticketId: this.ticket.ticket_id, page:this.currentEventsPage, initialize: true});
    this.currentEventsPage--;
    while(this.currentEventsPage > 0 && this.ticket.thread.events.data.length < 4){
      await this.fetchEventsByTicketId({ticketId: this.ticket.ticket_id, page:this.currentEventsPage, initialize: false});
      this.currentEventsPage--;
    }
    this.$forceUpdate();
    this.moveScrollTo('bottom');
    
    
  },
  components: {
    VueEditor,
    TheTickePriorityTag,
    PhoneButton,
    TheThreadEvent
    // TheForm
  },
  computed: {
    ...mapGetters({
      janus: 'getAgentJanus',
      'getLoggedInCallCenter': 'getLoggedInCallCenter'
    }),
    forms() {
      let forms = this.ticket.forms
      const form = forms.find(form => form.id == 2)
      if (form) {
        if (form.entry_values) {
          form.entry_values = form.entry_values.filter(_field => _field.field.type !== 'priority' && _field.field.type !== 'thread' && _field.field.id != 20)
        }
        forms = forms.map(_form => _form.id == 2 ? form : _form).filter(form => form.entry_values.length > 0)
      }
      return forms
    },
    updatingOptions() {
      return this.updatingAttr === 'status' ? this.statusOptions : this.slaOptions
    },
    upadtingValue: {
      set(value) {
        if (this.updatingAttr === 'status') {
          this.ticketInfo.status = value
        } else {
          this.ticketInfo.sla = value
        }
      },
      get() {
        return this.updatingAttr === 'status' ? this.ticketInfo.status : this.ticketInfo.sla
      }
    },
    staffs() {
      if (!this.staffOptions) return [];

      const staffs = this.staffOptions;

      return staffs.map(staff => ({
        label: staff.firstname + " " +  staff.lastname,
        value: `staff:${staff.staff_id}`,
      })).sort((a, b) => {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
    },
    teams() {
      if (!this.teamsOptions) return [];

      const teams = this.teamsOptions

      return teams.map(team => ({
        label: team.name,
        value: `team:${team.team_id}`
      })).sort((a, b) => {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
    },
    assignedOptions() {
      return [{
        "id" : "staff",
        "label" : "Staff",
        "options" : this.staffs,
      },
      {
        "id" : "teams",
        "label" : "Teams",
        "options" : this.teams,
      }];
    }
  },
};
</script>

<style scoped>
.ticket-info {
  background-color: white;
  border-radius: var(--radius);
  padding: var(--column);
  margin-left: var(--column);
}

.ticket-info h3 {
  font-size: 18px;
  margin-bottom: 8px;
}

.ticket-info h4 {
  font-size: 16px;
}

.ticket-user ul {
  list-style: none;
}

.ticket-option {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.ticket-tag {
  text-transform: uppercase;
}

.ticket-user li {
  font-size: 14px;
  margin-bottom: var(--column);
}

.ticket-user ul span {
  color: #606266;
}

.user {
  margin-bottom: var(--column);
}

.item {
  display: inline-flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
  ;
}

.item .label {
  font-weight: bold;
}

.item .button {
  margin-left: var(--column)
}

.values {
  display: inline-flex;
  align-items: baseline;
}
.eventsContainer{
  max-height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.eventsContainer .event{
  margin-bottom: 2px;
}
</style>