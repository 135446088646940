<template>
  <div v-loading="saving || loading" :element-loading-text="saving ? $t('common.saving') : $t('common.please-wait')">
    <h2>Editar organización</h2>
    <el-form size="small" ref="form" v-if="!saved"> 
      <the-fields :fields="fields" :errors="errors" ref="fields" :values="values"/> 
      <el-button size="small" @click="goBack">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" size="small" @click="save">{{ $t("common.save") }}</el-button>
    </el-form>
    <NotificationABM route="mailcenter.organizationList" :status="saved"
      :successTitle="$t('configuration.organization.created')"
      :text="saved ? this.name : ''"
      v-else
    />
  </div>
</template>

<script>
import ticketapi from '@/api/tickets'
import TheFields from '@/components/mail-center/TheFields';
import NotificationABM from "@/components/common/NotificationABM";
export default {
  props: {
    id : {
      required: true,
      default: 0,
    }
  },
  data() {
    return {
      fields: [],
      errors: {},
      saving: false,
      loading: true,
      saved : false,
      name: '',
      values : {},
    }
  },
  components: {
    TheFields,
    NotificationABM
  },
  methods: {
    async fetchForm() {
      this.loading = true;
      const response = await ticketapi.fetchOrganizationForm()
      this.fields = response.data.data.fields
      this.loading = false;
    },
    async save() {
      this.saving = true;
      try {
        const organization = this.$refs.fields.getValues();
        const response = await ticketapi.updateOrganization(organization)
        this.name = response.data.data.name
        this.saved = true;
      } catch (error) {
        this.errors = error.response.data.errors;
      } finally {
        this.saving = false;
      }
    },
    goBack() {
      this.$router.push({ name: 'mailcenter.organizationList' })
    }
  },
  async created() {
    try {
      this.loading = true;
      await this.fetchForm()
      if (this.id > 0) {
        const response = await ticketapi.fetchOrganization(this.id)
        this.values = response.data.data;
        this.loading = false;
      }
    } catch (error) {
      console.log(error)
    }
  } 
}
</script>

<style>

</style>