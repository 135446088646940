<template>
  <div class="item">
    <div class="item-header">
      <div class="type" :class="[type]">
        <i :class="{
           'fab fa-facebook-f' : type === 'facebook',
           'fab fa-instagram' : type === 'instagram',
           'fab fa-whatsapp' : type === 'whatsapp', 
           'fas fa-comment' : type === 'lhc' || type === 'web'
          }"></i>
      </div>
      <p class="mb-0 title">{{ name }} - {{ dept }}</p>
      <p class="mb-0 time">{{ datetime | redeableDate }}</p>
    </div>
    <div>
      <the-tag v-for="tag in tags" :key="tag.id" :tag="tag"/>
    </div>
  </div>
</template>

<script>
import TheTag from './TheTag'
export default {
  name: "ThePendingChatItem",
  props: {
    title: {
      required: true,
      type: String,
    },
    date: {
      required: false,
    },
    type : {
      required : true,
      type : String
    },
    dept: {
      required : true,
      type : String
    },
    tags: {
      required: true,
      type: Array,
      default: () => ([])
    }
  },
  computed : { 
    datetime() {
      return this.date * 1000;
    },
    name() {
      if (/^sms whatsapp:\+/i.test(this.title)) {
        return this.title.replace(/sms whatsapp:\+/i,'');
      }
      return this.title;
    }
  },
  components: {
    TheTag
  }
};
</script>

<style scoped>
.item  {
  cursor: pointer;
  gap: calc(var(--column) / 2);
  display: flex;
  width: 100%;
  flex-direction: column
}
.item-header {
  display: inline-flex;
  gap: calc(var(--column) / 2);
  width: 100%;
}

.type {
  background-color: var(--blue);
  border-radius: 3px;
  color: white;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  width: 32px;
  font-size: 14px;
  padding:0 10px
}

.title {
  color: var(--blue-grey);
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}

.time {
  color: #67747a;
  font-size: 0.9em;
  margin-left: auto;
}

.type.whatsapp {
	background-color: var(--whatsapp-green);
}

.type.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.type.facebook {
  background-color: #3b5998 
}

</style>
