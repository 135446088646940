import i18n from '@/i18n'

export default {
    nodes : [
      { component : "h3" , label : i18n.tc('common.basic_information') },
      { component : "input", label : i18n.tc('common.name'), index : "name", required : true },
      { component : "input", label : i18n.tc('configuration.servers.chat.hash'), index : "hash", required : true },
      { component : "input", label : i18n.tc('configuration.servers.chat.address'), index : "address", required : true },
      { component : "input", label : i18n.tc('configuration.servers.chat.api_address'), index : "restApiAddress", required : true },
      { component : "input", label : i18n.tc('configuration.servers.chat.username'), index : "adminUsername", required : true },
      { component : "input", label : i18n.tc('configuration.servers.chat.password'), index : "adminPassword", required : true }
    ]
  }