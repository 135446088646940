import axios from 'axios'
import i18n from '../i18n'
import route from '../router'
import { MessageBox } from 'element-ui'

const messages = i18n.messages[i18n.locale]

const http = axios.create({
  baseURL: process.env.VUE_APP_API_HOST + '',
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  },
})

const getAuthToken = () => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; hiper_token=`)
  if (parts.length === 2) {
    return parts.pop().split(';').shift()
  }
  return ''
}

const authInterceptor = (config) => {
  const token = getAuthToken()
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
}

const handleErrors = (error) => {
  if (error.response.status === 500) {
    const message = `${messages.common['the-request-could-not-be-processed-please-try-again-later']}`
    const errMsg = error?.response?.data?.message ? `(Error: ${error.response.data.message})` : ''
    MessageBox.alert(`${message} ${errMsg}`, {
      confirmButtonText: messages.common.continue,
      distinguishCancelAndClose: true,
      center: true,
    })
  }

  const originalRequest = error.config;

  if (error.response.status === 401 && !/mail-center/.test(originalRequest.url) && !/login_check/.test(originalRequest.url) && window.location.pathname !== '/logout') {
    const message = error.response.data.message ? error.response.data.message : `${messages.auth['session-time-expired']}` 
    MessageBox.alert(message, {
      confirmButtonText: messages.common.continue,
      distinguishCancelAndClose: true,
      center: true,
      beforeClose : () => {
        window.location.href = '/logout';
      }
    })
  }

  if (error.response.status === 404 || error.response.status === 403) {
    MessageBox.alert(`${messages.common['we-couldnt-find-the-resource-you-were-looking-for']}`, {
      confirmButtonText: messages.common.continue,
      distinguishCancelAndClose: true,
      center: true,
      beforeClose : (action, instance, done) => {
        if(!(/^\/organizations\/[^/]+\/customers$/.test(error.response.config.url))){
          route.go(-1)  
        }
        done();
      }
    })
  }

  return Promise.reject(error)
}

http.interceptors.request.use(authInterceptor)

http.interceptors.response.use(null, handleErrors)

export default http