/* eslint-disable */

import userapi from '@/api/user'
import createStore from '../helper'

const store = createStore('users')

const actions = {
  	toggleUserActive({ commit }, { active, id}) {
  	  return new Promise((resolve, reject) => {
				userapi.toggleUserActive(id, active)
				.then((response) => {
					const user = response.data.user
					commit('UPDATE_DATA', user)
					return resolve(user)
				})
				.catch((error) => {
					reject(error)
				});
			})
  	},
  	deleteChatsFromUser({}, id) {
		return userapi.deleteChatsFromUser(id)
	},
	deleteProfilePictureFromUser({}, id) {
		return userapi.deleteProfilePictureFromUser(id)
	},
}

store.actions = {...store.actions, ...actions}

export default store