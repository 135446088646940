<template>
  <div>
    <h2>{{ $t('user-configuration.integrations.title') }}</h2>
    <div class="integrations">
      <the-integration v-for="(integration, index) in integrations" :key="index" :integration="{...integration, name: index }"></the-integration>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheIntegration from './TheIntegration'
export default {
  data() {
    return {
      active: 'zoho'
    }
  },
  components: {
    TheIntegration
  },
  computed: {
    ...mapGetters('integrations', {
      integrations: 'getIntegrations'
    })
  }
}
</script>

<style scoped>
.integrations {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}
</style>
