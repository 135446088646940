import ticketApi from '@/api/tickets'

// src/directives/lazyLoad.js
export default {
  inserted: (el) => {
    function loadImage() {
      const imageElements = Array.from(el.querySelectorAll('img')).filter(img => !img.dataset.loaded);
      imageElements.forEach(async img => {
        const src = /base64/.test(img.src) ? img.getAttribute('data-src') : img.src
        const queryString = src.split('?')[1];

        if (!queryString) {
          return;
        }

        const url = new URLSearchParams(queryString)
        const signature = url.get("signature")
        const key = url.get('cid')
        if (key && signature) {
          const response = await ticketApi.downloadFile(key, signature)
          const { data, type } = response.data
          img.src = `data:${type};base64,${data}`
          img.dataset.loaded = true
        }
      });
    }

    function createObserver() {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            loadImage();
            observer.unobserve(el);
          }
        });
      });
      observer.observe(el);
    }

    if (window['IntersectionObserver']) {
      createObserver();
    } else {
      loadImage();
    }
  }
}
