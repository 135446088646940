const state = {
  callhistory : {
    call_center : [],
    corpo : [],
  },
  lastDoc : {
    call_center : null,
    corpo : null,
  },
  noMoreResults : {
    call_center : false,
    corpo : false,
  }
};

const getters = {
  getCallHistory : (state) => (index) => (state.callhistory[index]),
  getLastDoc : (state) => (index) => (state.lastDoc[index]),
  getNoMoreResults : (state) => (index) => (state.noMoreResults[index]),
};

const mutations = {
  ADD_TO_LAST_CALL : (state, {from, call}) => (state.callhistory[from].unshift(call)),
  ADD_CALL : (state, {from, call}) => (state.callhistory[from].push(call)),
  REMOVE_CALL : (state, {from, id}) => (state.callhistory[from] = state.callhistory[from].filter(call => call.id !== id)),
  UPDATE_CALL : (state, {from, call}) => (state.callhistory[from] = state.callhistory[from].map(c => c.id === call.id ? call : c)),
  SET_LAST_DOC : (state, {from, doc}) => (state.lastDoc[from] = doc),
  SET_NO_MORE_RESULTS : (state, {from, value}) => (state.noMoreResults[from] = value),
  UPDATE_CALL_TO_LOST : (state, {from, id}) => (state.callhistory[from] = state.callhistory[from].map(call => call.id === id ? {...call, type : 'lost'} : call)),
}

const actions = {
  async listenCallHistory({ rootGetters, commit }, {from, lastCall = null}) {
    let ref = null
    const limit = 30
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;

    if (lastCall) {
      ref = firebase.firestore().collection('call_history').doc(user.uid).collection(from).orderBy('date','desc').startAfter(lastCall).limit(limit)
    } else {
      ref = firebase.firestore().collection('call_history').doc(user.uid).collection(from).orderBy('date','desc').limit(limit)
    }

    const snap = await ref.get()

    if (snap.empty) {
      commit('SET_NO_MORE_RESULTS', {from, value: true})
    }

    snap.docs.forEach(doc => {
      const call = { ...doc.data(), id: doc.id };
      commit('ADD_CALL', { from, call});
      commit('SET_LAST_DOC', {from, doc})
    })
  },
  async addCallHistory({ rootGetters, commit }, data) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    try {
      const snap = await firebase.firestore().collection('call_history').doc(user.uid).collection(data.from).add(data.call)
      commit('ADD_TO_LAST_CALL', { from: data.from, call : {...data.call, id : snap.id }});
      return snap.id
    } catch (err) {
      console.log(err)
    }
  },
  updateCallToLost({ rootGetters, commit }, { from , id }) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    firebase.firestore().collection('call_history').doc(user.uid).collection(from).doc(id).update({
      type : 'lost'
    })
    commit('UPDATE_CALL_TO_LOST', {from, id})
  },
  cleanCallhistory({ rootGetters, commit }, from ) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    firebase.firestore().collection('call_history').doc(user.uid).collection(from).get().then((collection) => {
      collection.docs.forEach((doc) => {
        doc.ref.delete();
        commit('REMOVE_CALL', {from, id: doc.id})
      });
    });
  },
  deleteCallHistory({ rootGetters }, { from, id }) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    return firebase.firestore().collection('call_history').doc(user.uid).collection(from).doc(id).delete()
  }
}

export default {
    state,
    getters,
    mutations,
    actions
}