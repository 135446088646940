import RequireAuth from '../auth'
import AppView from '../../views/AppLayout'
import ClientsRoutes from './clients'

export default [
    {
      name: "Administration",
      path: "/administration",
      meta: {
        isInMenu: true,
        title: 'main-menu.administration',
        icon: 'fas fa-cogs',
        type: 'title',
        order: 2,
        access_control : ['ROLE_ADMIN']
      },
      children: [
        ...ClientsRoutes
      ],
      components : { 
        appView : AppView
      },
      beforeEnter : RequireAuth,
    }
  ]
  