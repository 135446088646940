<template>
  <el-dialog
  v-loading="loading"
  :visible.sync="show"
  width="30%">
  
  <p>{{message}}</p>
  <p>{{ $t('confirm-that-you-want-to-continue-typing') }}: <b>{{ confirmationValue }}</b> </p>

  <el-input type="text" v-model="value" :placeholder="confirmationValue" />

  <span slot="footer" class="dialog-footer">
    <el-button @click="show = false">{{ $t("common.cancel") }}</el-button>
    <el-button :type="confirmButtonType" :disabled="!isValid" @click="handleClick">{{ confirmButtonText }}</el-button>
  </span>
</el-dialog>

</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    message: {
      required: true,
      type: String
    },
    confirmationValue: {
      required: true,
      type: String,
    },
    callback: {
      required: true,
      type: Function,
    },
    confirmButtonText: {
      required: true,
      type: String,
    },
    confirmButtonType: {
      required: true,
      type: String,
      default: 'default'
    },
  },
  data() {
    return {
      value: '',
      show: false
    }
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    handleClick() {
      this.callback(this.value)
    },
  },
  computed:{
    isValid() {
      return this.value === this.confirmationValue
    }
  }
}
</script>

<style>

</style>