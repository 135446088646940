<template>
  <the-corpo-chat :id="id" :chat="chat">
    <template slot="subheader" v-if="chat.type === 'group'">
      <div class="members">
        <user-item v-for="user in members" :key="user.uid" :user="user" :showStatus="false" :showFullname="false"></user-item>
        <p v-if="chat.totalParticipants > members.length" class="plusmember">+{{ chat.totalParticipants - members.length }}</p>
      </div>
    </template>
    <template slot="buttons" >
      <button @click="minChat" class="min-button"><i class="fas fa-arrow-up"></i></button>
    </template>
  </the-corpo-chat>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserItem from "@/components/common/UserItem"
import TheCorpoChat from "@/components/chat/TheCorpoChat"
export default {
  components: {
    UserItem,
    TheCorpoChat,
  },
  props: {
    id: {
      type: String,
      default: "none",
    }
  },
  methods: {
    ...mapGetters('corpochat',['getChatById']),
    ...mapActions("corpochat", ['toggleHideAllChats','addOpenedMinChat','toggleIsMaxOpenedMinChat','setAllChatsMin', 'getParticipantsOfChat']),
    minChat() {
      this.setAllChatsMin()
      this.toggleHideAllChats()
      if (!this.getOpenedMinChatById(this.chat.id)) {
        this.addOpenedMinChat(this.chat)
      } else {
        this.toggleIsMaxOpenedMinChat(this.chat)
      }
      this.$router.push({
        name: "Home"
      })
    },
  },
  computed : {
    ...mapGetters('corpochat',['getOpenedMinChatById','getChatViewMembers']),
    ...mapGetters(['getUserByUid','getUser']),
    members() {
      if (this.chat.type === "group") {
        return this.getChatViewMembers
      }
      return []
    },
    chat() {
      return this.getChatById()(this.id)
    }
  },
  mounted() {
    if (!this.chat) {
      return this.$router.push({
        name: "Messages"
      })
    } else {
      this.getParticipantsOfChat(this.chat.roomId)
    }
    this.$title = this.chat.name
  }
}
</script>

<style scoped>
.main {
  margin: 0 var(--column);
  /* border: var(--column) solid #F9F9F9; */
  border-radius: var(--radius);
}

.chat-header button .fa-arrow-up {
  color: var(--blue-grey);
  transform: rotate(-135deg);
}

.members {
  align-items: center;
  display: inline-flex;
  width: 100%
}

.members :deep(.user) {
  border: 1px solid white;
  width: auto;
  margin-right: calc(var(--column) / 2)
}

.members :deep(.user)  .user-profile {
  height: 1.5em;
  line-height: 1.5em;
  width: 1.5em;
}

.clasic button .fa-arrow-up{
  color: var(--hiperpbx-green)
}

.plusmember {
  display: inline-flex;
  border-radius: 50%;
  background-color: var(--hiperpbx-green);
  color: white;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  margin-bottom: 0;
}
</style>