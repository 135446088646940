function getUrls(texto) {
  const regex = /https?:\/\/[^\s/$.?#].[^\s]*/g;
  return texto.match(regex) || [];
}

export function parseMessageFromFirebase(tmpMessage) {

  if (tmpMessage.version) {
    let isExternalFile = false

    if (tmpMessage.type !== 'text' && !/^https:\/\/firebasestorage.googleapis.com/.test(tmpMessage.payload.url)) {
      isExternalFile = true
    }

    return { 
      isExternalFile,
      ...tmpMessage, 
      user: {
        id: tmpMessage.user_id,
      }
    }
  }


  let type = tmpMessage.type ?? 'text';
  let filename = tmpMessage.filename ?? null;
  let isExternalFile = true;

  if (tmpMessage.from === "system") {
    tmpMessage.type = "system";
  }

  if (tmpMessage.type === "system" || tmpMessage.type === "bot") {
    return {
      user : {
        id : null
      },
      ...tmpMessage
    }
  }

  if (/^https:\/\/firebasestorage.googleapis.com/.test(tmpMessage.text)) {
    type = 'file';
    isExternalFile = false;

    filename = decodeURI(tmpMessage.text.substring(tmpMessage.text.lastIndexOf("?") + 1, tmpMessage.text.lastIndexOf("o/")).slice(0, -1).slice(2)).replace("files%2F", "")

    if (/(png|jpg|jpeg)$/.test(filename)) {
      type = 'image';
    }

    if (/(mp3|wav|ogg)$/.test(filename)) {
      type = 'audio';
    }

    if (/(mp4|mov|mkv|mov|wmv)$/.test(filename)) {
      type = 'video';
    }
  } else {
    if (tmpMessage.text && tmpMessage.type === "text") {
      getUrls(tmpMessage.text).forEach((url) => {
        tmpMessage.text = tmpMessage.text.replace(url, `<a href="${url}" target="_blank">${url}</a>`)
      });
    }
  }

  return {
    user: {
      id: tmpMessage.user_id,
      name: tmpMessage.from,
      picture: null,
    },
    text: tmpMessage.text,
    date: tmpMessage.date,
    type: type,
    filename: filename,
    id : tmpMessage.id,
    contentType: tmpMessage.contentType ?? null,
    isExternalFile,
    status: !tmpMessage.status ? 'sent' : tmpMessage.status
  }
}