<template>
    <div class="info">
        <div v-for="item in columns" :key="item.index">
            <div class="item"><p class="label">{{ item.label }}</p><theCell :value="value" :row="item" class="cell" /></div>
        </div>
    </div>
</template>

<script>
// import TheCell from './TheCell'
export default {
    name : 'TheList',
    props : {
        columns : {
            required : true,
            type : Array,
            default : () => ([])
        },
        value : {
            required : true,
            type : Object,
            default : () => ({})
        }
    },
    components : {
        TheCell : () => import('./TheCell')
    }
}
</script>

<style scoped>
.info {
  padding: var(--column)
}

.info .item {
  justify-content: space-between;
  display: inline-flex;
  width: 100%
}

.item .label {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}

.item .cell {
  font-size: 14px;
}

</style>