<template>
  <div>
    <h3>{{form.title}}</h3>
    <div v-html="form.instructions"></div>
    <el-form label-position="top" ref="form" :rules="rules" :model="values" size="small">
        <div v-for="(field, index) in form.fields" :key="field.name">
            <el-form-item :label="field.label" :prop="field.name" :required="field.required" :key="field.name" :error="index in errors ? errors[index][0] : null">
                <vue-editor v-model="values[field.name]" class="editor" v-if="field.type === 'thread'" :key="field.name"></vue-editor>
                <the-date-picker v-if="field.type === 'datetime'" :field="field" :ref="field.name" :key="field.name"/>
                <el-input v-model="values[field.name]" v-if="field.type === 'text' || field.type === 'memo'" :type="field.type === 'memo' ? 'textarea' : field.type" :key="field.name"/>
                <el-select v-if="field.type === 'choices' || field.type === 'priority'" v-model="values[field.name]" :multiple="field.configuration.multiselect" :filterable="field.configuration.choices.length > 5" :key="field.name">
                    <el-option v-for="(choice, i) in field.configuration.choices" :key="i" :label="choice.label" :value="choice.value" />
                </el-select>
                <el-checkbox v-model="values[field.name]" v-if="field.type === 'bool'" :key="field.name"/>
                <el-input v-if="field.type === 'files' " v-model="values[field.name]" type="hidden" :key="field.name"/>
                <div @click="fileName = field.type === 'files' ? field.name : field.name +'_attachments'" v-if="hasAttachments(field)">
                    <el-upload ref="upload" action="" :auto-upload="false" :multiple="true" :on-change="onChange" :on-remove="onRemove" :limit="limit(field)" :key="field.name">
                        <el-button slot="trigger" size="small" type="primary">Selecciona un archivo</el-button>
                    </el-upload>
                </div>
            </el-form-item>
        </div>
    </el-form>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import TheDatePicker from './TheDatePicker';
export default {
    props: {
        form: {
            required: true
        },
        errors: {
            required: false,
            type: Array,
            default: () => []
        },
    },
    data() {
        const values = this.resetValues(this.form.fields)
        return {
            fileName : '',
            values,
            files : []
        }
    },
    methods: {
        onChange(file, fileList) {
            this.files = fileList.map(file => file.raw)
        },
        onRemove(file, fileList) {
            this.files = fileList.map(file => file.raw)
        },
        getFieldsAndValues() {
            return new Promise((resolve) => {
                this.$refs.form.validate((isValid) => {
                    if (isValid) {
                        const fields = this.form.fields.map(({ id, name, type }) => {
                            let value = this.values[name]

                            if (type === 'datetime') {
                                value = this.$refs[name][0].getValue()
                            }

                            return {
                                id,
                                value,
                                is_attachment: /_attachments/.test(name)
                            }
                        })

                        resolve({
                            id: this.form.id,
                            files: this.files,
                            fields,
                        })
                    } 
                })
            })

        },
        limit(field) {
            if (field.configuration && field.configuration.max) {
                return parseInt(field.configuration.max)
            }

            return 0
        },
        hasAttachments(field) {
            return field.type === 'files' || (field.configuration !== null && field.configuration.attachments)
        },
        getDefaultValueForField(type) {
            const values = {
                text: '',
                priority: '',
                choices: '',
                bool: false,
                datetime: new Date(),
                memo: ''
            }

            return values[type]
        },
        resetValues(fields) {
            const values = { ...this.values }
            fields.forEach((field) => {
                values[field.name] = field.value ?? values[field.name] ?? this.getDefaultValueForField(field.type)
            })
            return values
        }
    },
    computed: {
        rules() {
            const rules = {}
            this.form.fields
            .filter(field => field.required)
            .forEach((field) => {
                rules[field.name] = [{
                    required: true,
                    message: this.$t('the-ticket.validation.field-is-required', { field: field.label }),
                    trigger: 'blur'
                }]
            })
            return rules;
        },
    },
    components: {
        VueEditor,
        TheDatePicker,
    },
    watch: {
        form({ fields } = { fields : []}) {
            const values = this.resetValues(fields)
            this.values = {}
            this.values = values
        }
    }
    
}
</script>

<style scoped>
.editor {
    background-color:white
}
</style>
