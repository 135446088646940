<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :filters="filters" :masive-creation-columns="masiveCreationColumns" can-delete :search="search">
      <template v-slot:action-buttons="scope">
        <el-dropdown-item v-if="isAdmin" icon="fas fa-trash" @click.native="showConfirmationModal(scope.row)">{{ $t('configuration.agents.delete-agent-firebase-node') }}</el-dropdown-item>
        <el-dropdown-item icon="fas fa-search" @click.native="showUser(scope.row)">{{ $t('configuration.agents.view-user') }}</el-dropdown-item>
      </template>
    </the-crud>
    <the-confirmation-modal
      :loading="cleaningAgent"
      :message="confirmationMessage" 
      :confirmation-value="confirmationValue" 
      :callback="cleanAgent" 
      :confirm-button-text="$t('common.delete')" 
      confirm-button-type='danger' 
      ref="confirmationModal"
    />
  </div>
</template>

<script>
import agentapi from "@/api/agent";
import { mapGetters } from 'vuex'
import TheCrud from '@/components/crud/TheCrud'
import TheConfirmationModal from '@/components/common/TheConfirmationModal'
export default {
  components : {
    TheCrud,
    TheConfirmationModal
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },  
  data() {
    return {
      cleaningAgent: false,
      confirmationMessage: "",
      confirmationValue: "",
      cleanUser: null,
      title : this.$t("configuration.agents.title"),
      store : "agents",
      columns: [
        { index : 'id', label : '#id', sortable : true, width: '100'},
        { index : 'user.name', label : this.$t("configuration.agents.name"), details: true, 'load-from' : 'user'},
        { index : 'user.lastname', label : this.$t("configuration.agents.lastname"), details: true, 'load-from' : 'user'},
        { index : 'organization.name', label : this.$t("configuration.agents.organization") },
        { index : 'number', label : this.$t("configuration.agents.number")},
        { index : 'call', label : this.$t("configuration.agents.answer-calls"), type : 'boolean'},
        { index : 'name.lastname', label : this.$t("configuration.agents.extension") , type : 'join', options : ["extension.name", "extension.extension", "extension.organization.name"], joinBy : " ", 'hide in table' : true },
        { index : 'hasDashboard', label : this.$t("configuration.agents.has_dashboard"), type : 'boolean' ,'hide in table' : true},
        { index : 'chat', label : this.$t("configuration.agents.answer-chats"), type : 'boolean'},
        { index : 'osticketUserPasswordAlt', label : this.$t("configuration.agents.osticket-password") ,'hide in table' : true},
        { index : 'mail', label : this.$t("configuration.agents.answer-mails"), type : 'boolean'},
        { index : 'departments', label : this.$t("configuration.agents.answer-mails"), type : 'tags', options: ["name"], 'hide in table' : true},
      ],
      filters : [
        { type : 'text', index : 'user.name', label : this.$t('configuration.users.name'), options : ['contains'] },
        { type : 'text', index : 'user.email', label : this.$t('configuration.users.email'), options : ['contains'] },
        { type : 'text', index : 'organization.name', label : this.$t('configuration.users.organizations'), options : ['contains']},
        { type : 'boolean', index : 'chat', label : this.$t("configuration.agents.answer-chats"), booleanType : 'yes|no'},
        { type : 'boolean', index : 'call', label : this.$t("configuration.agents.answer-calls"), booleanType : 'yes|no'},
        { type : 'boolean', index : 'mail', label : this.$t("configuration.agents.answer-mails"), booleanType : 'yes|no'}
      ],
      search : ['number'],
      masiveCreationColumns : [
        { title : 'user', description:  this.$t("masive-creation.agents.user"), example : '2',  type : 'string' },
        { title : 'number', description:  this.$t("masive-creation.agents.number"), example : '1000',  type : 'string' },
        { title : 'call', description:  this.$t("masive-creation.agents.call"), example : 'yes',  type : 'yes|no' },
        { title : 'mail', type: 'yes|no' , example : 'yes',  description : this.$t("masive-creation.agents.mail"), },
        { title : 'chat', type: 'yes|no' , example : 'no',  description : this.$t("masive-creation.agents.chat"), },
        { title : 'group_number', type: 'number' , example : '1',  description : this.$t("masive-creation.agents.group_number"), },
        { title : 'organization_session_id', type: 'number' , example : '2',  description : this.$t("masive-creation.agents.organization_session_id"), },
        { title : 'extension', type: 'number' , example : '200',  description : this.$t("masive-creation.agents.extension"), },
        { title : 'osticket_user_password', type: 'string' , example : '1234',  description : this.$t("masive-creation.agents.osticket_user_password"), },
        { title : 'has_dashboard', type: 'yes|no' , example : 'yes',  description : this.$t("masive-creation.agents.has_dashboard") },
      ]
    }
  },
  methods : {
    showConfirmationModal(row) {
      this.cleanUser = row;
      this.confirmationMessage = this.$t('configuration.agents.you-want-to-clean-the-agent-from-firebase', { fullname: row.user.name +
       " " + row.user.lastname } );
      this.confirmationValue = row.user.uid
      this.$refs.confirmationModal.toggleShow();
    },
    async cleanAgent() {
      this.cleaningAgent = true;
      await agentapi.cleanAgentFirebase(this.cleanUser.id);
      this.$message({
        message: this.$t("configuration.agents.agent-cleaned", { fullname: this.cleanUser.user.name + " " + this.cleanUser.user.lastname }),
        type: 'success'
      });
      this.cleaningAgent = false;
    },
    showUser(row) {
      this.$router.push({
        name: "user",
        query: {
          simplesearch: row.user.email
        }
      })
    }
  }
}
</script>

<style>

</style>