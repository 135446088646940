<template>
  <div class="files-attachments">
    <div class="files">
      <File
        v-for="(file, index) in filesParsed"
        :key="index"
        :file="file"
        :download="true"
        @click.native="download(files[index])"
      ></File>
    </div>
    <div class="data" v-if="filesParsed.length > 1">
      <p class="mb-0">{{ filesParsed.length }} adjuntados</p>
      <a href="" @click.prevent="downloadAll">Descargar todo</a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TicketAPI from "@/api/tickets";
import File from "@/components/common/File";
export default {
  name: "TheFileAttachmentsDisplay",
  props: {
    files: {
      required: true,
    },
  },
  components: {
    File,
  },
  computed: {
    filesParsed() {
      return this.files.map((file) => ({ name: file.name, type: file.type }));
    },
  },
  methods: {
    ...mapGetters(["getOsticketToken"]),
    download(file) {
      TicketAPI.downloadFile(file.key, file.signature).then(
        (response) => {
          const data = response.data;
          const byteCharacters = atob(data.data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: data.type });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = data.name;
          link.target = "_blank";
          link.click();
        }
      );
    },
    downloadAll() {
      TicketAPI.downloadFiles(this.files.map((file) => (`${file.key}@${file.signature}`)))
      .then((response) => {
          const data = response.data;
          const blob = new Blob([data], { type: 'aplication/zip' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = new Date().getTime() + ".zip";
          link.target = "_blank";
          link.click();
      })
    }
  },
};
</script>

<style scoped>
.files-attachments {
  border: 2px solid #f9f9f9;
  border-radius: var(--radius);
}

.files-attachments .data {
  align-items: center;
  background-color: #f9f9f9;
  color: var(--bubble-text-color);
  display: inline-flex;
  font-size: 14px;
  justify-content: space-between;
  padding: calc(var(--column) / 2) var(--column);
  width: 100%;
}
</style>