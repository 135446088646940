<template>
  <div v-loading="loading">
    <h2>{{ $t("configuration.users.edit-user") }}</h2>
    <div v-if="!saved"> 
      <the-new-user-form ref="userForm" :values="userEdit" @created="updated"/> 
    </div>
    <NotificationABM route="mailcenter.usersList" :status="saved"
      :successTitle="$t('configuration.users.the-user-was-edited-successfully')"
      :text="
        saved
          ? this.name
          : $t('configuration.users.the-user-could-not-be-edited')
      "
      v-else
    />
  </div>
</template>

<script>
import ticketapi from '@/api/tickets';
import NotificationABM from "@/components/common/NotificationABM";
import TheNewUserForm from '../../components/ticket/TheNewUserForm';
export default {
  components : {
    NotificationABM,
    TheNewUserForm,
  },
  props : {
    id : {
      required : true
    },
    user : {
      type : Object,
      required : false,
      default : () => ({
        id: 0,
        name : "",
        email : "",
        password : null,
        repeat_password : null,
        organization : {
          id: 0
        } 
      })
    }
  },
  data() {
    return {
      userEdit: this.user,
      loading : false,
      saved : false,
      passErrors : {},
      name : this.user.name,
    }
  },
  methods : {
    async fetchUser() {
      this.loading = true
      try {
        const response = await ticketapi.fetchUser(this.id)
        this.userEdit = response.data.data
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    updated(user) {
      this.name = user.name
      this.saved = true
    }
  },
  async mounted() {
    await this.$refs.userForm.fetchForm()
    if (this.user.id === 0) {
      this.fetchUser();
    }
  }
}
</script>

<style>

</style>