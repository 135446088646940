<template>
    <div class="content">
      <p>{{ $t("call.transfer") }}</p>
      <div v-if="!value">
        <the-search-input v-model="search"></the-search-input>
        <p v-if="!search && list.length === 0" class="mt-4 text-center">{{ $t('call.you-have-not-made-any-transfer') }}</p>
        <p v-if="!search && list.length > 0" class="mt-4">{{ $t('common.recent') }}</p>
        <p v-if="search" class="mt-4">{{ $t("common.search-results") }}</p>
        <ul>
          <the-list-item v-if="search && list.length === 0" @click.native="value = { name : search, lastname : '', destination : search }">
            <template slot="content">
              <user-item :user="{name : search, lastname : '', picture : ''}" :show-status="false" ></user-item>
            </template>
          </the-list-item>
          <the-list-item v-for="(item, index) in list" :key="index" @click.native="value = item">
            <template slot="content">
              <user-item :user="item" :show-status="false" ></user-item>
            </template>
          </the-list-item>
        </ul>
      </div>
      <div v-else>
        <header>
          <button @click="value = null"><i class="fas fa-arrow-left"></i></button>
        </header>
        <main>
          <user-item :user="value" :show-status="false" :show-fullname="false" style="width: auto"></user-item>
          <p v-if="value.visiblename">{{ value.visiblename }}</p>
          <p v-else>{{ value.name }}</p>
          <p>{{ value.destination }}</p>
        </main>
        <footer v-if="!assistedTransfer">
          <el-button style="margin:0px" @click.native="attendedTransfer">{{ $t("call.transfer-ask") }}</el-button>
          <el-button style="margin:0px" type="success" @click.native="bindTransfer">{{ $t("call.transfer-now") }}</el-button>
        </footer>
        <footer v-else>
          <el-button style="margin:0px" @click.native="goBackToCall">{{ $t("common.go-back") }}</el-button>
          <el-button style="margin:0px" type="success" @click.native="hangup">{{ $t("call.transfer-now") }}</el-button>
        </footer>
      </div>
    </div>
</template>

<script>
import TheSearchInput from '@/components/html/TheSearchInput'
import TheListItem from '@/components//html/TheListItem'
import UserItem from '@/components/common/UserItem'
import { mapActions, mapGetters } from 'vuex'
export default {
  components : {
    TheSearchInput,
    TheListItem,
    UserItem
  },
  data() {
    return {
      search : '',
      innerDrawer : false,
      value : null,
      assistedTransfer: false
    }
  },
  computed : {
    ...mapGetters('corpocalls', { recentlyTransferred : 'getRecentlyTransferred'}),
    ...mapGetters({ janus : 'getJanus'}),
    ...mapGetters("contacts", { contacts: "getAllContacts",}),
    list : function() {
      if (this.search === "") {
        return this.recentlyTransferred;
      }

      return this.contacts.concat(this.recentlyTransferred)
      .reduce((acc, current) => {
          current.phones?.forEach((phone) => {
            acc.push({
              name: `${current.visiblename} (${phone.value} - ${phone.label})`,
              label: phone.label,
              value: phone.value,
              status: current.status,
              picture: current.picture,
              visiblename: current.visiblename,
              destination: phone.value,
            });
          });
          return acc;
        }, [])
        .filter(item => !this.search || item.visiblename && item.visiblename.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    }
  }, 
  methods : {
    ...mapActions('corpocalls', ['addToRecentrlyTransfer']),
    bindTransfer() {
      this.addToRecentrlyTransfer(this.value)
      this.janus.sendDtmf(`##${this.value.destination}`)
    },
    attendedTransfer() {
      this.assistedTransfer = true
      this.addToRecentrlyTransfer(this.value)
      this.janus.sendDtmf(`*02${this.value.destination}`);
    },
    hangup() {
      this.janus.hangup()
      this.assistedTransfer = false
    },
    goBackToCall() {
      this.janus.sendDtmf('**')
      this.assistedTransfer = false
    }
  }
}
</script>

<style scoped>
  ul {
    list-style: none;
    display: block;
    height: 35vh;
    overflow: auto;
  }

  ul :deep(li) {
    cursor: pointer
  }

  .content {
    width: 100%;
    display: block;
  }

  header button {
    outline: none;
    border: none;
    color: var(--hiperpbx-green);
    background-color: transparent;
  }

   /*.light button {
    background-color: #e1e1e1;
    color: #030303;
  } */

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
  }
</style>