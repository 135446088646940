<template>
  <div class="agent-card">
    <h3>{{ title }}</h3>
    <p>{{ t | hhmmss }} hs</p>

  </div>
</template>

<script>
export default {
  props : {
    title : {
      required : true,
      type : String
    },
    seconds : {
      required : true,
      type : Number
    },
    started : {
      required : false,
      type : Number
    }
  },
  data() {
    return {
      interval : null,
      totalSeconds: this.seconds,
    }
  },
  methods: {
    sumSeconds() {
      if (this.interval) {
        return
      }

      this.interval = setInterval(() => {
        this.totalSeconds++;
      }, 1000)
    },
    startToCount() {
      const diffSeconds = parseInt((new Date().getTime() - new Date(this.started).getTime()) / 1000);
      this.totalSeconds = diffSeconds + this.seconds;
    },
    stopSumSeconds() {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  computed: {
    t() {
      return this.totalSeconds
    }
  },
  watch: {
    started(value) {
      if (value > 0) {
        this.startToCount()
        this.sumSeconds()
      } else {
        this.stopSumSeconds()
      }
    },
    seconds(value) {
      this.totalSeconds = value
    }
  },
  created() {
    if (this.started) {
      this.startToCount()
      this.sumSeconds()
    }
  }
}
</script>

<style scoped>
.agent-card {
  text-align: center;
  background-color: white;
  padding: 18px;
}

.agent-card h3 {
  color: #91A0A5;
  font-size: 14px;
}

.agent-card p {
  color: var(--dark-blue);
  font-weight: bold;
  font-size: 22px
}
</style> 