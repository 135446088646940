<template>
  <div>
    <el-dialog :center="true" :visible.sync="show" :modal="false" class="incoming-call" width="20%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" :append-to-body="true">
      <div v-if="contact" style="width: 100%; display: flex; align-items: center; flex-direction: column;">
        <p class="text-center" style="width: 100%" >Te esta llamando</p>
        <user-item :user="contact"  :show-fullname="false" :show-status="false"  class="contact" style="width: auto"/>
        <p class="text-center name mb-0">{{ contact.visiblename }}</p>
      </div>
      <p v-else v-html="$t('call.incoming-call', { callerid: callerid })"></p>
      <div class="buttons">
        <answer-button :janus="janus" :jsep="jsep" @click.native="$emit('answer')"></answer-button>
        <decline-button :janus="janus"></decline-button>
      </div>
    </el-dialog>
    <the-ringtone-audio ref="theRingtoneAudio" :stop-audio="false"></the-ringtone-audio>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TheRingtoneAudio from '../common/TheRingtoneAudio';
import UserItem from '../common/UserItem';
import AnswerButton from "./AnswerButton"
import DeclineButton from './DeclineButton'
export default {
  name: "IncomingCall",
  data() {
    return {
      show: false,
    };
  },
  props: {
    callerid: {
      type: String,
      required: true,
    },
    jsep : {
      required : false,
      default : false
    },
    janus : {
      required : true
    }
  },
  methods: {
    toggleVisible() {
      this.show = !this.show
    }
  },
  components: {
    UserItem, 
    AnswerButton,
    DeclineButton,
    TheRingtoneAudio
  },
  watch: {
    show: {
      handler: function(value) {
        if (value) {
          this.$refs.theRingtoneAudio.play();
        } else {
          this.$refs.theRingtoneAudio.stop();
        }
      },
      deep: true,
    }
  },
  computed : {
    ...mapGetters('contacts', ['getContactByCallerId']),
    contact() {
      return this.getContactByCallerId(this.callerid.replace(/"/i, ''))
    }
  },
}
</script>

<style scoped>

.incoming-call p {
  color: var(--blue-grey);
  font-size: 18px;
  font-weight: bold;
  line-height: 35px;
  text-align: center;
}

.incoming-call .buttons {
  display: flex;
  margin: 0 auto;
  margin-top: calc(var(--column) * 2);
  justify-content: space-between;
  width: 150px;
}

.incoming-call .fas {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
}

.incoming-call .fa-phone-slash {
  background-color: var(--red);
  border-radius: 50%;
  color: white;
}

.incoming-call .fa-phone {
  background-color: var(--green);
  border-radius: 50%;
  color: white;
}
</style>