<template>
  <el-dialog :visible.sync="internalShow" width="30%" @closed="closeModal">
    <h3>{{ $t("agent-console.chat.chat-disposition") }}</h3>
    <el-form ref="form" :model="formChatDisposition" label-position="top">
      <el-form-item :label="$t('agent-console.chat.chat-disposition')" prop="value" style="width: 100%">
        <el-select v-model="formChatDisposition.value" multiple style="width: 100%" :loading="loading">
          <el-option
            v-for="item in chatDispositionsFiltered"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <div style="display: inline-flex; gap: 8px">
              <span>{{ item.name }}</span>
              <span style="color: #8492a6; font-size: 13px">{{ item.description }}</span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <div style="display:inline-flex; justify-content: end; width: 100%">
        <el-button @click="closeModal">{{ $t("common.cancel") }}</el-button>
        <el-button type="primary" @click="saveChatDisposition" :disabled="!formChatDisposition.value">{{ $t("common.save") }}</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    roomId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      internalShow: false,
      formChatDisposition: {
        value: []
      }
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
    async saveChatDisposition() {
      this.$emit('closeModal')
      const chatDispositions = this.chatDispositions.filter(item => this.formChatDisposition.value.includes(item.id) ).map(item => ({ id: item.id, name: item.name, description: item.description, color: item.color }))
      await this.$store.dispatch('agentConsoleChat/setChatDispositions', { roomId: this.roomId, chatDispositions })
      this.showTagModal = false
      this.$message({
        showClose: true,
        message: this.$t("agent-console.chat.the-disposition-was-saved"),
        type: 'success'
      });
    },
    fetchChatDispositionValues() {
      this.formChatDisposition.value = this.chat.data.tags.map(item => item.id) ?? []
    }
  },
  computed:{
    chatDispositions() {
      return this.$store.getters["chatDispositions/getData"]
    },
    chat() {
      return this.$store.getters["getChatInChatCenterByRoomId"](this.roomId);
    },
    loading() {
      return this.$store.getters["chatDispositions/getFetching"]
    },
    chatDispositionsFiltered() {
      const departments = this.$store.getters["getAgent"].departments;
      if(departments.length === 0) return []

      return this.chatDispositions.filter((element) => {
        if(!element.department){
          return element
        }

        if(element.department.id){
          if(departments.find((department) => department.id === element.department.id)){
            return element
          }
        }
      })
    }
  },
  watch: {
    show(newValue) {
      this.internalShow = newValue;
    },
    roomId() {
      this.fetchChatDispositionValues()
    }
  },
  created() {
    this.fetchChatDispositionValues()
  },
}
</script>