<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="bot" :title="title" search="name" :submit="beforeSubmit"></the-form>
</template>

<script>
import TheForm from '@/components/crud/TheForm'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data() {

    let bot = { };

    if (this.entity && this.entity.config) {
      bot = { ...this.entity, ...this.entity.config}
    }

    return {
      store: "bots",
      form: {
        nodes: [
          { component : 'input', index : 'name', label : this.$t('configuration.users.name'), required : true },
          { disabled: this.id !== "0", component : "select", index : "type", label : this.$t("contact-me.chat-center.robot-type"), required : true, values : [
            { label: "Bot Platform", value : "BotPlatform"},
            { label: "Live Helper Chat", value : "LiveHelperChat"},
            { label: "Gupshup", value : "Gupshup"},
          ]},
          {
            component: "h3", 
            label: this.$t('contact-me.chat-center.bot-config'),
            children: [
              { "hidden": "type:LiveHelperChat", component: "input", index: "url", label : "URL"},
              { "hidden": "type:LiveHelperChat", component: "input", index: "username", label : "Username" },
              { "hidden": "type:LiveHelperChat", component: "input", index: "password", label : "Password" },
              { "hidden": "type:LiveHelperChat", component: "input", index: "dept_id", label : "Department Id" },

              { "hidden": "type:BotPlatform", component: "input", index: "bot_platform_id", label : "Bot Platform Id" },

              { "hidden": "type:Gupshup", component: "input", index: "url", label : "URL" },
            ]
          }
        ]
      },
      bot
    }
  },
  components: {
    TheForm
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("contact-me.chat-center.edit-bot") : this.$t("contact-me.chat-center.new-bot")
    }
  },
  methods: {
    beforeSubmit(data) {
      data.organization = this.$store.getters["contactMe/getOrganizationId"];
      return data;
    }
  }
}
</script>