<template>
  <div>
    <el-form size="small" ref="form" :rules="rules" :model="userForm">
      <el-form-item :label="$t('tickets.user')" prop="value">
        <el-select
          v-model="value"
          :placeholder="$t('tickets.select-user')"
          filterable
          remote
          :remote-method="searchUser"
          allow-create
        >
          <el-option
            v-for="item in users"
            :key="item.id"
            :label="`${item.name} (${getEmail(item)})`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <el-dialog
      title="Crear usuario"
      :visible.sync="show"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div v-loading="creatingUser" v-if="!userCreated">
        <el-form
          :model="form"
          label-width="120px"
          label-position="top"
          class="form"
          size="small"
        >
          <el-form-item
            :label="$t('common.fullname')"
            prop="name"
            :error="errors.name"
          >
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item
            :label="$t('common.email')"
            prop="email"
            :error="errors.email"
          >
            <el-input v-model="form.email"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="show = false">{{
            $t("common.cancel")
          }}</el-button>
          <el-button size="small" type="primary" @click="createUser">{{
            $t("common.save")
          }}</el-button>
        </span>
      </div>
      <div v-else class="created">
        <Success class="success" />
        <p>
          {{ $t("common.click") }}
          <el-button type="text" @click="show = false">{{
            $t("common.here")
          }}</el-button>
          {{ $t("common.to-go-back-to-the-form") }}
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Success from "@/components/icons/Success";
import ticketapi from "@/api/tickets";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "TheUserInput",
  data() {
    return {
      userForm : {
        value : '',
      },
      users: [],
      show: false,
      form: {
        name: "",
        email: "",
      },
      errors: {
        name: "",
        email: "",
      },
      creatingUser: false,
      userCreated: false,
      rules : {
        value : [
          { required : true, message : 'Falta el usuario'}
        ]
      }
    };
  },
  components: {
    Success,
  },
  methods: {
    ...mapGetters(["getEraserTicket"]),
    ...mapActions(["setEraserTicketUserId"]),
    ...mapMutations(['ADD_USER_TO_DATA_TO_CREATE_TICKET']),
    createUser() {
      this.creatingUser = true;
      ticketapi
        .createUser(this.form)
        .then((response) => {
          this.userCreated = true;
          this.creatingUser = false;
          this.ADD_USER_TO_DATA_TO_CREATE_TICKET(response.data.data)
          this.users.push(response.data.data);
          this.userForm.value = response.data.data.id;
        })
        .catch((errors) => {
          console.log(errors)
          this.creatingUser = false;
          if (errors.response.status === 422) {
            const data = errors.response.data;
            if (data.detail) {
              if (data.detail.name) {
                this.errors.name = data.detail.name[0];
              }

              if (data.detail.email) {
                this.errors.email = data.detail.email[0];
              }
            }
          }
        });
    },
    searchUser(value) {
      if (value !== "") {
        setTimeout(() => {
          this.users = this.ticketData.users.filter((item) => {
            return (
              item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
              item.email.address.toLowerCase().indexOf(value.toLowerCase()) > -1
            );
          });
        }, 200);
      } else {
        this.users = this.ticketData.users;
      }
    },
    getForm() {
      return this.$refs.form
    },
    getEmail(item) {
      return typeof item.email === 'string' ? item.email : item.email.address
    }
  },
  computed: {
    ...mapGetters({
      ticketData: "getDataToCreateTicket",
    }),
    value: {
      get() {
        return this.getEraserTicket().user_id;
      },
      set(value) {
        this.setEraserTicketUserId(value);
      },
    },
  },
  mounted() {
    if (this.users.length === 0) {
      this.users = this.ticketData.users ? this.ticketData.users : []
    }
  },
  watch: {
    value(newValue) {
      if (!this.users.find((user) => user.id === newValue)) {
        this.form.name = this.value;
        this.show = true;
      }
      this.userForm.value = newValue
    },
  },
};
</script>

<style scoped>
.created > .success {
  display: block;
  margin: 0 auto;
}

.created {
  text-align: center;
}
</style>