<template>
  <div>
    <h4>{{ $t('contact-me.pbx.extensions.basic-information') }}</h4>
    <ul>
      <li><span class="label">{{$t('contact-me.pbx.extensions.name')}}</span>: {{ extension.name }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.extension')}}</span>: {{ extension.extension }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.secret')}}</span>: {{ extension.secret }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.outbound-callerid')}}</span>: {{ extension.outboundcid }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.is-recording-calls')}}</span>: {{ extension.recording ? "Yes" : "No" }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.is-callwaiting')}}</span>: {{ extension.callwaiting == 'enabled' || extension.callwaiting === true ? "Yes" : "No" }}</li>
    </ul>
    <h4>{{$t('contact-me.pbx.extensions.voicemail.title')}}</h4>
    <p v-if="!isVoicemailConfigured">{{ $t('common.is-not-configured') }}</p>
    <ul v-else>
      <li><span class="label">{{$t('contact-me.pbx.extensions.voicemail.email')}}</span>: {{ extension.voicemail.email }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.voicemail.password')}}</span>: {{ extension.voicemail.password }}</li>
    </ul>
    <h4>{{ $t('contact-me.pbx.extensions.followme.title') }}</h4>
    <p v-if="!isFollowmeConfigured">{{ $t('common.is-not-configured') }}</p>
    <ul v-else>
      <li><span class="label">{{$t('contact-me.pbx.extensions.followme.initial-wait-time')}}</span>: {{ extension.followme.pre_ring }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.followme.wait-time')}}</span>: {{ extension.followme.ring }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.followme.ring-strategy')}}</span>: {{ extension.followme.strategy }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.followme.action-if-none-one-answers')}}</span>: {{ extension.followme.action_no_answer.name }} ({{extension.followme.action_no_answer.type }})</li>
      <p class="label">{{$t('contact-me.pbx.extensions.followme.list')}}</p>
      <el-tag v-for="(destination, index) in followMeDestinationsParsed" :key="index" class="tag">
        {{ destination.type }}: {{ destination.label }}
      </el-tag>
    </ul>
    <h4>{{$t('contact-me.pbx.extensions.advance-configuration.title')}}</h4>
    <ul>
      <li><span class="label">Pickup group</span>: {{ extension.pickupgroup }}</li>
      <li><span class="label">Account Code</span>: {{ extension.accountcode }}</li>
      <li><span class="label">Call Group</span>: {{ extension.callgroup }}</li>
      <li><span class="label">{{$t('contact-me.pbx.extensions.advance-configuration.context')}}</span>: {{ extension.context }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    extension: {
      required: true
    }
  },
  computed: {
    extensions() {
      return this.$store.getters["pbx_extensions/getData"]
    },
    queues() {
      return this.$store.getters["pbx_queues/getData"]
    },
    isVoicemailConfigured() {
      return this.extension.voicemail.email && this.extension.voicemail.password
    },
    isFollowmeConfigured() {
      return this.extension.followme
    },
    followMeDestinationsParsed() {
      return this.extension.followMeDestinations.map((destination) => {
        let dest = {
          label: "",
          type: "",
          value: destination,
        }
        const extension = Object.values(this.extensions).find(exten => exten.extension == destination);
        if (extension) {
          dest.type = "Extension"
          dest.label = extension.name
        }

        const queue = Object.values(this.queues).find(queue => queue.number == destination);
        if (queue) {
          dest.type = "Queue"
          dest.label = queue.name;
        }

        return dest;
      })
    }
  }
}
</script>

<style scoped>
.label {
  font-weight: bold;
  color: #5A5B5B;
}

li {
  margin-bottom: calc(var(--column) / 2 );
  font-size: 14px
}

.destinations .tag {
  margin-right: var(--column)
}
</style>