<template>
	<div class="call">
		<the-ping :stats="stats" :janus="janus"/>
		<HangupButton :janus="janus"></HangupButton>
		<HoldButton :janus="janus" class="white"></HoldButton>
		<MuteButton :janus="janus" class="white"></MuteButton>
		<el-tooltip effect="dark" :content="$t('call.trasnfer')" placement="bottom">
			<button class="info white" @click="showTransferDrawer">
				<i class="fas fa-reply"></i>
			</button>
		</el-tooltip>
		<Keypad :janus="janus" :roundedButton="true" :onlyKeypad="false" class="console-keypad"></Keypad>
		<el-tooltip effect="dark" placement="bottom" v-for="(button, index) in quickButtons" :key="index" :content="button.label">
			<el-button circle @click="handleQuickButton(button)">
				<i class="fas fa-reply"></i>
			</el-button>
		</el-tooltip>
	</div>
</template>

<script>

import Keypad from "@/components/webrtc/Keypad";
import HoldButton from "@/components/webrtc/HoldButton";
import MuteButton from "@/components/webrtc/MuteButton";
import HangupButton from "@/components/webrtc/HangupButton";
import ThePing from "@/components/webrtc/ThePing";

export default {
	name : "CallButtons",
	props : {
		janus : {
			required : true
		},
		stats: {
			required: true
		},
		quickButtons: {
			required: false,
			type: Array,
			default: () => ([])
		}
	},
	methods : {
		showTransferDrawer() {
			this.$emit('showTransferDrawer', { type : 'call'})
		},
		handleQuickButton(button) {
			if (button.type === 'transfer_to') {
				this.janus.sendDtmf(`##${button.value}`)
			}
		}
	},
	components : {
		ThePing,
		HangupButton,
		HoldButton,
		MuteButton,
		Keypad
	}
};
</script>

<style scoped>
.call {
	display: inline-flex;
	gap: 8px;
	align-items: center;
}


button.white,
.call .console-keypad :deep(button) {
	background-color: white;
}

button.white:hover,
.call .console-keypad :deep(button:hover)  {
	background-color: var(--blue-grey);
  color: #f7f7f7;
}

.ping {
	margin-left: 10px;
	display: inline-block
}
</style>
