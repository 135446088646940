<template>
  <nav :class="{ 'min': min }" class="the-main-menu">
    <button @click="showMenu = true" class="mt-0 open-menu"><i class="fas fa-bars"></i></button>
    <el-drawer :visible.sync="showMenu" direction="ltr" size="80%">
      <div v-if="childrenMenu" class="sub-header">
        <button @click="resetMainMenu" class="back"><i class="fas fa-arrow-left"></i></button>
        <h2 class="mb-0 d-inline-block">{{ parentTitle }}</h2>
      </div>
      <ul class="links">
        <the-menu-item v-for="(route, index) in routes" :key="index" :route="route" @childrenMenu="showChildrenMenu"
          @clicked="clicked" :min="min"></the-menu-item>
      </ul>
    </el-drawer>

    <div class="header">
      <h1 :style="logo">HiperMe</h1>
    </div>


    <div v-if="childrenMenu" class="sub-header header">
      <button @click="resetMainMenu" class="back"><i class="fas fa-arrow-left"></i></button>
      <h2 class="mb-0 d-inline-block ">{{ parentTitle }}</h2>
    </div>
    <ul class="main links">
      <the-menu-item v-for="(route, index) in routes" :key="index" :route="route" @childrenMenu="showChildrenMenu"
        :min="min"></the-menu-item>
    </ul>
    <user-buttons :min="min" />
    <p class="user-email">{{ user.email }}</p>
    <button @click="toggleCollapseMenu" class="button-open-close"><i class="fas"
        :class="{ 'fa-chevron-left': !min, 'fa-chevron-right': min }"></i></button>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import TheMenuItem from './TheMenuItem'
import UserButtons from '@/components/common/UserButtons'
export default {
  data() {
    return {
      parentTitle: '',
      childrenMenu: false,
      default: this.$router.options.routes[7].children.concat(this.$router.options.routes[8]).concat(this.$router.options.routes[9]).concat(this.$router.options.routes[10]),
      tmpMenu: this.$router.options.routes[7].children.concat(this.$router.options.routes[8]).concat(this.$router.options.routes[9]).concat(this.$router.options.routes[10]),
      min: (localStorage.getItem('collapse-menu')) ? JSON.parse(localStorage.getItem('collapse-menu')) : false,
      showMenu: false,
    }
  },
  components: {
    TheMenuItem,
    UserButtons
  },
  methods: {
    resetMainMenu() {
      if (this.subChildrenMenu) {
        this.subChildrenMenu = false;
        this.parentTitle = this.tmpPrevMenu.name;
        delete (this.tmpPrevMenu.name);

        this.tmpMenu = this.tmpPrevMenu;
        this.tmpPrevMenu = null;
      } else {
        this.childrenMenu = false
        this.tmpMenu = this.default
        this.parentTitle = ''
      }
    },
    toggleCollapseMenu() {
      this.min = !this.min;
      localStorage.setItem('collapse-menu', this.min)
      if (this.min) {
        this.resetMainMenu()
      }
    },
    showChildrenMenu(route) {
      if (this.childrenMenu) {
        this.subChildrenMenu = true;
        this.tmpPrevMenu = this.tmpMenu;
        this.tmpPrevMenu.name = this.parentTitle;
      } else {
        this.childrenMenu = true
      }
      this.tmpMenu = route.children
      this.parentTitle = route.name
    },
    clicked() {
      this.showMenu = false;
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      theme: 'getTheme'
    }),
    routes() {
      return this.tmpMenu
        .filter(menu => menu.meta.isInMenu)
        .sort((a, b) => a.meta.order - b.meta.order)
        .filter((menu) => {
          let access = false

          if (this.user.roles.includes('ROLE_ADMIN')) {
            access = true;
          }

          if (!menu.meta.access_control) {
            access = true
          } else {
            menu.meta.access_control.forEach((role) => {
              if (this.user.roles.includes(role)) {
                access = true;
              }
            })
          }

          if (menu.meta.custom_access_control) {
            return menu.meta.custom_access_control(this.$store.getters.getUser, this.$store.getters.getAgent);
          }

          if (access) {
            return menu
          }
        })
    },
    logo() {
      let url = '/images/hiperme_isologotipo.png'

      // if (this.min) {
      //   url = '/images/hiperme_isologotipo.png'
      // }

      if (this.user.theme) {
        //const type = { false: 'logoMax', true: 'logoMin' }
        const image = this.user.theme.logo
        url = `${process.env.VUE_APP_API_HOST.replace('/api', '')}${image}`
      }

      return {
        backgroundImage: 'url(\'' + url + '\')',
      }
    }
  }
}
</script>

<style scoped>
.open-menu {
  display: none;
}

nav {
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  width: 200px;
  transition: width 0.3s, left 0.3s;
}

.header {
  display: inline-flex;
  padding: var(--column);
  width: 100%;
}

h1 {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  text-indent: -50000px;
  width: 100%;
  margin-bottom: 0;
  color: white;
  text-align: center;
  width: 100%
}

.back,
.button-open-close,
.open-menu {
  border: none;
  outline: none;
  margin-bottom: var(--column);
  color: var(--blue-grey);
  margin-top: 0px !important;
  background: transparent;
}

.sub-header {
  padding: 0 var(--column);
  width: 100%;
}

.sub-header p {
  margin-left: var(--column);
  display: inline-block
}

.user-email {
  color: var(--blue-grey);
  font-size: 12px;
  text-align: center;
  display: -webkit-box;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.clasic .user-email {
  color: white
}

.links {
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: auto;
}

.min {
  align-items: center;
  width: 68px;
}

.min .user-email {
  display: none;
}

.light .back,
.light .button-open-close,
.light nav {
  background-color: var(--light-menu-color);
}

.light .open-menu,
.light .menu-icon-item,
.light .button-open-close {
  color: var(--light-menu-links-color);
}

.clasic .back,
.clasic .button-open-close {
  background: transparent;
}

.clasic .open-menu,
.clasic .back,
.clasic .menu-icon-item,
.clasic .button-open-close {
  color: white
}

.clasic nav {
  background-color: var(--hiperpbx-grey);
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 1.3em;
  color: var(--blue-grey);
  margin-left: 10px;
}

.min h1 {
  /* background-size: 40px; */
  background-position: left;
  margin: 0
}

.clasic h2 {
  color: white;
}

.open-menu {
  font-size: 25px;
  margin-bottom: 0;
  background-color: transparent;
}
</style>