<template>
  <button>{{ value }} <span v-if="text">{{ text }}</span></button>
</template>

<script>
export default {
  props : {
    value : {
      required : true,
    },
    text : { 
      required : false,
    }
  }
}
</script>

<style scoped>
  button {
    outline: 0;
    border: 0;
    background-color: #e1e1e1;
    color: #030303;
    border-radius: 50%;
    font-size: 20px;
    height: 70px;
    width: 70px;
    font-weight: bold;
    text-align: center;
  }

  button:hover,
  button.active {
    background-color: var(--light-menu-links-color);;
    color: #e1e1e1;
  }

  span {
    display: block;
    font-size: 12px;
  }

  
</style>