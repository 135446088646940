<template>
  <div class="messages">
    <the-messages-menu-header class="header" :isMax="isMax" :show-users="showUsers" @newMessage="toggleShowUsers" @toggleSearchBar="toggleSearchBar"></the-messages-menu-header>
    <the-messages-menu-list class="list" :isMax="isMax" :show-users="showUsers" :search-bar="searchBar" @toggleShowUsers="toggleShowUsers"></the-messages-menu-list>
  </div>
</template>

<script>
import TheMessagesMenuList from './TheMessagesMenuList'
import TheMessagesMenuHeader from './TheMessagesMenuHeader'
import { mapActions } from 'vuex'

export default {
  name : 'TheMessageMenu',
  props : {
    isMax : {
      required : true,
      type : Boolean
    }
  },
  data() {
    return {
      show: false,
      showUsers : false,
      searchBar : false
    }
  },
  methods : {
    ...mapActions('corpochat', ['listenChats']),
    toggleIsMax() {
      this.isMax = !this.isMax
    },
    toggleShowUsers() {
      this.showUsers = !this.showUsers
    },
    toggleSearchBar() {
      this.searchBar = !this.searchBar
    },
    toggleShow() {
      if (this.$route.path === '/messages') {
        this.show = true;  
      } else {
        this.show = false;  
      }
    }
  },
  created() {
    setTimeout(() => {
      this.listenChats()
    }, 7000)
    this.toggleShow();
  },
  components : {
    TheMessagesMenuHeader,
    TheMessagesMenuList,
  },
  watch: {
    $route : {
      handler: function() {
        this.toggleShow();
      },
      deep: true,
    }
  },
}
</script>

<style scoped>
.messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 93%;
}

.list {
  overflow-y: auto;
}
</style>