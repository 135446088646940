<template>
  <div :class="{ max: isMax, min: !isMax, show: show }" class="the-left-menu">
    <el-button v-if="showCalls && !user.roles.includes('ROLE_BASIC')" @click.native="$store.commit('SET_SHOW_MODAL', false)" style="display: block; margin: 0 auto; margin-bottom: var(--column)" type="danger">
      {{ $t("common.close") }}
    </el-button>
    <the-messages-menu v-show="!showCalls" :is-max="isMax"></the-messages-menu>
    <the-soft-phone v-show="showCalls" style="flex: 1" v-if="hasExtension"></the-soft-phone>
    <footer v-if="!onlyBasic">
      <button class="min-max" ><i class="fas" :class="{ 'fa-chevron-left': isMax, 'fa-chevron-right': !isMax }" @click="$store.commit('SET_SHOW_LEFT_MENU', !isMax)"></i></button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheMessagesMenu from "@/components/messages/TheMessagesMenu"
import TheSoftPhone from "@/components/softphone/TheSoftPhone"
export default {
  components : {
    TheMessagesMenu,
    TheSoftPhone
  },
  data() {
    return {
      show: false,
    };
  },
  computed : {
    ...mapGetters("corpocalls", {
      isCalling: "getCalling",
    }),
    ...mapGetters(['getShowSoftphoneModal','getCorpoIsInCall', 'getShowLeftMenu']),
    ...mapGetters({
        user : 'getUser',
    }),
    showChat() {
       if (this.user) {
        return ( Object.keys(this.user.roles).includes("ROLE_USER") || Object.keys(this.user.roles).includes("ROLE_ADMIN") || this.user.roles.includes("ROLE_ADMIN") || this.user.roles.includes("ROLE_USER") ) && this.active == 'chat'
      }
      return false
    },
    showCalls() {
      return this.user.localExtension && this.active === 'calls' && this.isMax ? true : false
    },
    hasExtension() {
      return this.user.localExtension && (this.user.roles.includes('ROLE_USER') || this.user.roles.includes('ROLE_ADMIN') || this.user.roles.includes('ROLE_BASIC')) ? true : false
    },
    active() {
      if (this.user) {
        if ( Object.keys(this.user.roles).includes("ROLE_BASIC") || this.user.roles.includes("ROLE_BASIC") ) {
          return "calls";
        }
      }
      return this.getShowSoftphoneModal ? 'calls' : 'chat'
    },
    onlyBasic() {
      return Object.keys(this.user.roles).includes("ROLE_BASIC") || this.user.roles.includes("ROLE_BASIC")
    },
    isMax: {
      get() {
        return this.getShowLeftMenu;
      },
      set(value) {
        this.$store.commit("SET_SHOW_LEFT_MENU", value)
      }
    }
  },
  methods : {
    toggleShow() {
      if (this.$route.path === '/messages') {
        this.show = true;  
      } else {
        this.show = false;  
      }
    }
  },
  watch: {
    $route : {
      handler: function() {
        this.toggleShow();
      },
      deep: true,
    },
    getCorpoIsInCall(value) {
      if (value) {
        this.$store.commit("SET_SHOW_LEFT_MENU", true)
        this.$store.commit("SET_SHOW_MODAL", true);
      }
    },
    isCalling(value) {
      if (value) {
        this.$store.commit("SET_SHOW_LEFT_MENU", true)
        this.$store.commit("SET_SHOW_MODAL", true);
      }
    }
  },
  created() {
    if (this.onlyBasic) {
      this.show = true
      this.$store.commit("SET_SHOW_LEFT_MENU", true)
    }
  }
};
</script>

<style scoped>
.the-left-menu {
  background-color: var(--other-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  padding: var(--column);
  overflow-y: auto;
}

/* .the-left-menu nav {
  display: inline-flex;
} */

.light .the-left-menu {
  background-color: var(--light-menu-chat);;
}

.the-left-menu.max {
  width: 300px;
  transition: width 0.1s, left 0.3s;   
}

button.min-max {
  display: block;
  border: none;
  outline: none;
  margin: 0 auto;
  color: var(--blue-grey);
  background-color: transparent;
}

.light button.min-max {
  color: var(--light-menu-links-color);
}

.clasic button.min-max {
  color: var(--hiperpbx-green);
}

.clasic .the-left-menu {
  background-color: #fff;
}

.light .the-left-menu {
  background-color: var(--light-menu-chat)
}

</style>