<template>
  <div v-loading.fullscreen.lock="updating">
      <h3>{{ $t("general-configurations.side-images.title") }}</h3>
      <el-button type="primary" @click.native="dialogTableVisible = true">{{ $t("general-configurations.side-images.upload-image") }}</el-button>
      <div class="images" v-loading="loading" :element-loading-text="$t('general-configurations.side-images.loading-images')">
        <TheSideImage v-for="image in images" :key="image.id" :image="image" @toggleUpdating="toggleUpdating"></TheSideImage>
      </div>
      <el-dialog :title="$t('general-configurations.side-images.upload-image')" :visible.sync="dialogTableVisible" :close-on-click-modal="creating" :close-on-press-escape="creating" v-loading="creating">
        <el-form :model="form" label-width="120px" :rules="rules" ref="form">
          <el-form-item :label="$t('general-configurations.side-images.name')" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('general-configurations.side-images.active')">
            <el-switch v-model="form.active"></el-switch>
          </el-form-item>
          <el-form-item prop="file">
          <el-upload
            ref="upload"
            class="upload-demo"
            :auto-upload="false"
            accept="image/*"
            list-type="picture"
            :action="action"
            :headers="headers"
            :data="form"
            :on-success="success">
            <el-button slot="trigger" size="small" type="primary">{{ $t('general-configurations.side-images.select-the-image') }}</el-button>
            <div slot="tip" class="el-upload__tip">{{ $t("general-configurations.side-images.only-jpg-and-png-files") }}</div>
          </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click.native="createSideImage">{{ $t('general-configurations.side-images.create') }}</el-button>
            <el-button @click.native="dialogTableVisible = false">{{ $t('general-configurations.side-images.cancel') }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TheSideImage from '@/components/general-configuration/TheSideImage';
export default {
  name : "TheSideImageConfiguration",
  data() {
    return {
      loading : false,
      dialogTableVisible : false,
      updating : false,
      form : {
        name : '',
        active : '1'
      },
      rules: {
        name: [
          { required: true, message: this.$t("general-configurations.side-images.upload-image"), trigger: 'blur' },
        ]
      },
      creating : false
    }
  },
  methods : {
    ...mapActions(['fetchSideImages','addSideImage']),
    ...mapGetters(['getSideImages']),
    createSideImage() {
      this.$refs['upload'].data.active = (this.$refs['upload'].data.active) ? 1 : 0;
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return false;
        }

        this.creating = true;
        this.$refs['upload'].submit();
      });
    },
    success(image) {
      this.creating = false;
      this.dialogTableVisible = false;
      this.addSideImage(image)
      this.form.name = '';
      this.active = 0;
      this.$refs['upload'].clearFiles();
    },
    toggleUpdating() {  
      this.updating = !this.updating;
    }
  },
  created() {
    this.loading = true;
    this.fetchSideImages().then(() => {
      this.loading = false;
    });
  },
  computed : {
    images() {
      return this.getSideImages()
    },
    action() {
      return `${process.env.VUE_APP_API_HOST}/side_images`;
    },
    headers() {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; hiper_token=`);
      const token = parts.pop().split(';').shift();

      return {
        Authorization : `Bearer ${token}`
      }
    }
  },
  components : {
    TheSideImage
  }
}
</script>

<style scoped>
.images {
  display: flex;
  margin-top: var(--column);
  width: 100%;
  flex-wrap: wrap;
  min-height: 50px;
}
</style>