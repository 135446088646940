
const createAgentItem = (user, snap) => {
  return { ...snap.val(), uid : snap.key }
}

const defaultDashboardValues = () => ({
  times : {
    loggin : 0,
    talking : 0,
    break : 0
  },
  logged_started_at : 0,
  break_started_at : 0,
  calls_per_hour : [],
  calls_total : {
    incoming : 0,
    outgoing : 0,
    manual : 0,
    transfer : 0
  },
  chats_total : {
    normal : 0,
    whatsapp : 0
  }
})

const state = {
  alreadyListening : false,
  agentsDashboard: [],
  defaultDashboardValues : defaultDashboardValues(),
  agentsRefs : []
}

const getters = {
  getAgentsDashboard : (state) => state.agentsDashboard,
  getDefaultDashboardValues : (state) => state.defaultDashboardValues,
  getAgentDashboard : (state) => (uid) => state.agentsDashboard.find(agent => agent.uid === uid),
}

const mutations = {
  TOGGLE_ALREADY_LISTENING : (state) => (state.alreadyListening = !state.alreadyListening),
  ADD_AGENT_DASHBOARD: (state, agent) => state.agentsDashboard.push(agent),
  REMOVE_AGENT_DASHBOARD: (state, uid) => state.agentsDashboard = state.agentsDashboard.filter(agent => uid !== agent.uid),
  UPDATE_AGENT_DASHBOARD : (state, agent) => (state.agentsDashboard = state.agentsDashboard.map(a => a.uid === agent.uid ? {...agent} : a)),
  UPDATE_AGENT : (state, agent) => state.defaultDashboardValues = { ...state.defaultDashboardValues, ...agent },
  ADD_REF : (state, data) => (state.agentsRefs.push(data)),
  REMOVE_REF : (state, key) => state.agentsRefs = state.agentsRefs.filter(ref => ref.uid !== key)
}

const actions = {
  listenAgentsDashboard({ rootGetters, commit }, organizationId) {
    if (state.alreadyListening) {
      return;
    }

    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase;

    firebase.database().ref(`organization/${organizationId}/dashboard`).on('child_added', (snap) => {
      const agent = createAgentItem(user, snap);
      if (agent) {
        commit('ADD_AGENT_DASHBOARD', agent);
        if (agent.uid === user.uid) {
          commit('UPDATE_AGENT', agent)
        }
      }

      const ref = firebase.database().ref(`organization/${organizationId}/dashboard/${snap.key}`);

      ref.on('value', (snap) => {
        const agent = createAgentItem(user, snap);
        if (agent) {
          commit('UPDATE_AGENT_DASHBOARD', agent);
          if (agent.uid === user.uid) {
            commit('UPDATE_AGENT', agent)
          }
        }
      });

      commit('ADD_REF', { ref, uid : agent.uid })
    });

    firebase.database().ref(`organization/${organizationId}/dashboard`).on('child_removed', (snap) => {
      const agentRef = state.agentsRefs.find(ref => ref.uid === snap.key)

      if (agentRef && snap.key !== user.uid) {
        agentRef.ref.off()
        commit('REMOVE_REF', snap.key)
      }

      commit('REMOVE_AGENT_DASHBOARD', snap.key);
      commit('UPDATE_AGENT', defaultDashboardValues())
    });

    commit('TOGGLE_ALREADY_LISTENING')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}