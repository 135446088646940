import gupshupapi from '@/api/gupshup'
/* eslint-disable */
const state = {
  gupshup_templates : []
};

const getters = {
  alltemplates : (state) => state.gupshup_templates
};

const mutations = {
  settemplates: (state, gupshup_templates) => (state.gupshup_templates = gupshup_templates)
};

const actions = {
  fetchtemplates({ commit, rootGetters }, idOrganization ) {
    const organizationId = rootGetters.getOrganizationId;

    return new Promise((resolve, reject) => {
      gupshupapi.fetchGupshupTemplates(organizationId)
        .then((response) => {
          const data = response.data['templates'];
          commit("settemplates", data);
        })
        .catch((e) => {
          reject(e)
        })
    })
  },

  sendwhatsapptest( { rootGetters }, message ){
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    return firebase.database().ref(`chat_engine_actions`).push({...message, uid: user.uid })
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}