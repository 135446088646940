<template>
  <div class="chat-container" @click="handleClick">
    <div class="chat-header" @click="$emit('headerClicked', $event)">
      <div>
        <div style="display:inline-flex;align-items:center"><span class="user-status" :class="userStatus" v-if="chat.type === 'user'"></span><h2 :class="{ 'user' : chat.type === 'user' }">{{ name }}</h2></div>
        <p v-if="chat.type === 'user'" class="status-text mb-0" :class="userStatus">{{ status }}</p>
        <slot name="subheader"></slot>
      </div>
      <div class="buttons">
        <phone-button v-if="getJanus && userWith.extension && chat.type === 'user' && userStatus !== 'disconnected'" :janus="getJanus" :displayname="userWith.fullname" :extension="userWith.extension"></phone-button>
        <videocall-button :type="chat.type" :room-id="chat.roomId"></videocall-button>
        <slot name="buttons" class="other-buttons"></slot>
      </div>
    </div>
    <Chat
      class="corpo-chat"
      path="chat"
      :emojis="true"
      :myself="user"
      :roomId="chat.roomId"
      :display-header="false"
      :display-textarea="chat.join"
      :new-message="chat.unread"
      @userWriting="userWriting"
    >
    </Chat>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Chat from '@/components/chat/Chat'
import PhoneButton from '@/components/webrtc/PhoneButton'
import VideocallButton from '@/components/webrtc/VideocallButton'

export default {
  name: "TheCorpoChat",
  props: {
    id: {
      type: String,
      default: "none",
    },
    chat : {
      required : true
    }
  },
  components: { 
    Chat,
    PhoneButton,
    VideocallButton 
  },
  methods : {
    ...mapActions('corpochat', ['updateUnreadMessage']),
    ...mapActions('_chats', ['fetchLastMessagesFromChatroomFromTimestamp']),
    handleClick() {
      this.updateUnreadMessage({ id : this.chat.id, type : this.chat.type })
    },
    userWriting() {
      if (this.chat.unread > 0) {
        this.updateUnreadMessage({ id : this.chat.id, type : this.chat.type })
      }
    }
  },
  computed : {
    ...mapGetters(['getUser','_users/getUserByUid','getJanus']),
    user : function() {
      return {
        id : this.getUser.uid,
        name : this.getUser.fullname 
      }
    },
    userWith : function() {
      const tmp = this['_users/getUserByUid'](this.chat.user.uid)
      return (this.chat.type === 'user') ? (tmp ?? {
        uid : this.chat.user.uid,
        fullname : 'Unknow',
        extension : '',
        picture : '',
        status : 'disconnected'
      }  ) : ''
    },
    userStatus : function() {
      return this.userWith.status ? this.userWith.status : 'disconnected'
    },
    status : function() {
      return this.$t(`user-status.${this.userStatus}`)
    },
    name : function () {
      return this.chat.name
    },
    showVideocallButton : function() {
      return this.chat.type === 'group' || this.chat.type === 'user' && this.userStatus !== 'disconnected'
    },
    participants : function() {
      return (this.chat.type === 'group') ? [...this.chat.participants, this.chat.owner] : [this.userWith]
    }
  },
};
</script>

<style scoped>
h2 {
  color: var(--dark-grey);
  font-size: 1.5em;
  margin-bottom: calc(var(--column) / 5);
}

h2.user {
  margin-left: 10px
}

.user-status {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}

.chat-container {
  display: flex!important;
  flex-direction: column!important;
  background-color: white;
  height: 100vh;
  border: var(--column) solid #F9F9F9;
  padding: 0!important;
  overflow: hidden
}

.chat-container.little {
  width: 150px
}

.chat-header {
  align-items: center;
  border-bottom: 1px solid var(--blue-grey);
  display:inline-flex;
  justify-content: space-between;
  height : 10vh;
  width: 100%;
  padding: var(--column)
}

.chat-header .buttons {
  align-items: center;
  display: inline-flex;
}

.chat-header button {
  background-color: white;
  border:none;
  margin-top: 0px!important;
  margin-left: var(--column);
  outline:none;
}

.corpo-chat {
  height: 90%;
}
</style>