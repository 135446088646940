<template>
  <el-dialog :title="title" :width="width" :visible.sync="dialogVisible" :close-on-press-escape="!uploading" :close-on-click-modal="!uploading" :show-close="!uploading" @close="reset" >
    <div v-if="!hasError && !created">
      <el-upload drag accept=".csv" :action="action" :headers="headers" name="file_csv" :on-error="error" class="upload" :on-progress="progress" :on-success="success">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <p>{{ $t("account-modal.drop-your-file-were") }}</p>
        </div>
        <div slot="tip" class="el-upload__tip">
          <p class="text-center">{{ $t("masive-creation.only-csv-files") }}</p>
        </div>
      </el-upload>
      <div>
        <p>{{ $t('masive-creation.column-order') }}</p>
        <p>{{ $t('masive-creation.field-array') }}</p>
        <table class="table">
          <thead>
            <tr>
              <th v-for="(column, index) in columns" :key="index">
                <el-popover trigger="hover">
                    <ul>
                      <li>{{ $t("masive-creation.name") }}: {{ column.title }}</li>
                      <li>{{ $t("masive-creation.description") }}: {{ column.description }}</li>
                      <li>{{ $t("masive-creation.type") }}: {{ column.type }}</li>
                      <li>{{ $t("masive-creation.example") }}: {{ column.example }}</li>
                    </ul>
                  <span slot="reference">{{ column.title }}</span>
                </el-popover>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div v-else-if="hasError">
      <header>
        <button class="mt-0" @click="back"><i class="fas fa-arrow-left"></i></button>
        <p class="mb-0 ml-2">{{ $t("masive-creation.found-errors") }}: </p>
      </header>
      <ul v-if="code === 3">
          <li v-for="(item, index) in errorList" :key="index">{{ item }}</li>
      </ul>
      <el-table v-else :data="errorTable.errors" border style="width: 100%">        
        <el-table-column width="50" prop="row" label="Fila"></el-table-column>
        <el-table-column v-for="column in errorTable.columns" :key="column" :prop="column" :label="column">
            <el-table-column label="Valor">
              <template slot-scope="scope">
                {{ scope.row[column].value }}
              </template>
            </el-table-column>
            <el-table-column label="Errores">
              <template slot-scope="scope">
                <p v-for="(error, index) in scope.row[column].errors" :key="index">{{ error }}</p>
              </template>
            </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <NotificationABM :status="true" :success-title="$t('masive-creation.success')" v-else></NotificationABM>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationABM from '../common/NotificationABM.vue';
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    resource : {
      required : true,
      type : String,
    },
    columns : {
      required : true,
    },
    initWidth : {
      required : false,
      default : '80%'
    }
  },
  components : {
    NotificationABM
  },
  data() {
    return {
      width : this.initWidth,
      code : 0,
      hasError : false,
      uploading: false,
      dialogVisible: false,
      errorList : [],
      errorTable : {},
      created : false
    };
  },
  computed : {
    ...mapGetters(['geToken']),
    action : function() {
      return process.env.VUE_APP_API_HOST + "/" + this.resource + "/masive"
    },
    headers : function() {
      return {
        'Authorization' : `Bearer ${this.geToken}`
      }
    }
  },
  methods : {
    progress() {
      this.uploading = true;
    },
    reset() {
      this.code =  0,
      this.hasError =  false,
      this.uploading =  false,
      this.dialogVisible =  false,
      this.errorList =  [],
      this.errorTable =  {}
      this.width = '80%'
    },
    back() {
      this.code =  0,
      this.hasError =  false,
      this.uploading =  false,
      this.errorList =  [],
      this.errorTable =  {}
      this.width = '80%'
    },
    success() {
      this.created = true
      this.uploading = false;
    },
    error(err) {
      this.uploading = false
      this.hasError = true
      const response = JSON.parse(err.message)
      const errors = {
        3 : this.showModalWithList,
        4 : this.showModalWithErrors
      }
      this.code = response.code
      errors[response.code](response)
    },
    showModalWithList(response) {
      Object.keys(response.errors).map(index => {
        this.errorList.push(response.errors[index])
      });
    },
    showModalWithErrors(response) {
      this.width = '80%';
      this.errorTable.errors = []
      this.errorTable.columns = response.columns
      Object.keys(response.errors).map(index => {
        this.errorTable.errors.push({
          ...response.errors[index],
          row : index
        })
      })
    },
    toggle() {
      this.dialogVisible = !this.dialogVisible
    }
  }
};
</script>

<style scoped>
ul {
  list-style: none;
}

button {
  outline:none;
  border:none;
  color: var(--hiperpbx-green);
  background-color: white;;
}

header {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--column);
}
.upload :deep(.el-upload)  {
  margin: 0 auto;
    display: block;
    width: fit-content;
}

.main-content {
  display: inline-flex;
  justify-content: space-around;
  width: 100%;
}

.descriptions {
  height: 50vh;
  overflow: auto;
}
</style>