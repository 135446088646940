<template>
  <div class="crud" v-loading="init">
    <header>
      <button @click="goBack">
        <i class="fas fa-angle-left"></i>
        {{ $t("common.go-back") }}
      </button>
      <h2 v-if="client">{{ $t('administration.clients.licenses-of', { name: client.name }) }}</h2>
    </header>
    <el-button type="primary" @click.native="showDialog = true">{{ $t('administration.licenses.add-license-key')  }}</el-button>
    <div>
      <el-table :data="licenses">
        <el-table-column :label="$t('administration.clients.organizations')">
          <template slot-scope="scope">
            {{ getOrganization(scope.row.organization) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" :label="$t('administration.licenses.license_type')" >
          <template slot-scope="scope">
            {{ $t("administration.licenses." + scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="licenseKey" :label="$t('administration.licenses.licenseKey')" ></el-table-column>
        <el-table-column prop="orderId" :label="$t('administration.licenses.orderId')" ></el-table-column>
        <el-table-column prop="customFields" :label="$t('administration.licenses.customFields')" >
          <template slot-scope="scope">
            {{ JSON.stringify(scope.row.customFields) }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button type="danger" @click.native="destroy(scope.row.id)">{{ $t("common.delete") }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog :visible.sync="showDialog" width="500px" :close-on-press-escape="!loading" :close-on-click-modal="!loading" >
      <el-form :rules="rules" ref="form" :model="form" label-width="200px" v-loading="loading" label-position="left" style="margin-bottom: 4px">
        <el-form-item :label="$t('administration.clients.organizations')" prop="organization">
          <el-select v-model="form.organization" >
            <el-option v-for="organization in organizations" :key="organization.id" :value="organization.id" :label="organization.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('administration.licenses.license_type')"  prop="type">
          <el-select v-model="form.type">
            <el-option v-for="licenseType in licenseTypes" :key="licenseType" :value="licenseType" >{{ $t("administration.licenses." + licenseType) }}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('administration.licenses.licenseKey')" prop="licenseKey"  :error="errors.licenseKey" >
          <el-input v-model="form.licenseKey"></el-input>
        </el-form-item>
        <el-form-item  :label="$t('administration.licenses.orderId')" prop="orderId"  :error="errors.orderId" >
          <el-input v-model="form.orderId"></el-input>
        </el-form-item>
        <div>
          <el-button @click.native="showDialog = false">{{ $t("common.cancel") }}</el-button>
          <el-button type="primary" @click.native="save">{{ $t("common.save") }}</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props : {
    id : {
      type : String,
      default : "0"
    }
  },
  data() {
    return {
      init: true,
      client: null,
      showForm: false,
      form: {
        type: null,
        organization: null,
        licenseKey: '',
        orderId: ''
      },
      loading: false,
      licenseTypes: ['agent_session','user_session'],
      rules: {
        organization: [
          { required: true, message: this.$t('administration.licenses.the-organization-is-required'), trigger: 'blur' },
        ],
        type: [
          { required: true, message: this.$t('administration.licenses.the-type-is-required'), trigger: 'blur' },
        ],
        licenseKey: [
          { required: true, message: this.$t('administration.licenses.the-license-key-is-required'), trigger: 'blur' },
        ],
        orderId: [
          { required: true, message: this.$t('administration.licenses.the-order-id-is-required'), trigger: 'blur' },
        ],
      },
      errors: {},
      showDialog: false
    }
  },
  computed: {
    licenses() {
      return this.$store.getters["clients/getClientLicenses"]
    },
    organizations() {
      const ids = this.licenses.map(license => license.organization)
      const organizations = this.client.organizations.filter(organization => !ids.includes(organization['@id']))
      return organizations
    }
  },
  methods: {
    save() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          try {
            const license = await this.$store.dispatch("licenses/create", {
              "type": this.form.type,
              "licenseKey": this.form.licenseKey,
              "orderId": this.form.orderId,
              "organization": `/api/organizations/${this.form.organization}`
            })
            this.showDialog = false
            this.$store.commit('clients/ADD_LICENSE', license)
            this.$message({
              type: 'success',
              showClose: true,
              message: this.$t('administration.licenses.the-license-was-added')
            });
          } catch (error) {
            const { data } = error.response
            data.violations.forEach(error => this.errors[error.propertyPath] = error.message)
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async destroy(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("licenses/destroy", id)
        this.$store.commit('clients/REMOVE_LICENSE', id)
      } finally {
        this.loading = false;
      }
    },
    async update(license) {
      this.loading = true;
      try {
        await this.$store.dispatch("licenses/update", { id: license.id, payload: license })
        this.$store.commit('clients/UPDATE_LICENSE', license)
      } finally {
        this.loading = false;
      }
    },
    getOrganization(id) {
      return this.client.organizations.find(organization => organization['@id'] === id).name
    },
    goBack() {
      this.$router.push({ name: 'clients'})
    },
  },
  async created() {
    const client = await this.$store.dispatch("clients/one", this.id)
    await this.$store.dispatch('clients/fetchLicenses', this.id)
    this.init = false;
    this.client = client
  },
}
</script>

<style scoped>
header {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

header > button {
  outline: none;
  border: none;
  background-color: transparent;
}
</style>