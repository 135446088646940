<template>
  <div class="answering">
    <div>
      <p class="mb-0" v-if="!onlyMessage">Respondiendo a:</p>
      <chat-message :message="message" :answer="false"></chat-message>
    </div>
    <button @click="close" v-if="!onlyMessage"><i class="fas fa-times"></i></button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ChatMessage from './ChatMessage'
export default {
  name : "TheAnsweringMessage",
  props : {
    roomId : {
      required : true
    },
    message : {
      required : true
    },
    onlyMessage : {
      required : false,
      default : true
    }
  },
  methods : {
    ...mapActions('_chats', ['setAnswerMessage']),
    close() {
      this.setAnswerMessage({ roomId : this.roomId, message : null })
    }
  },
  components : {
    ChatMessage
  }
}
</script>

<style scoped>
  .answering {
    background-color: var(--bubble-self-background-color);
    border-radius: var(--radius);
    display: inline-flex;
    padding: var(--column);
    margin-bottom: var(--column);
    justify-content: space-between;
    width: 100%
  }

  button {
    border: none;
    outline: none;
    background-color: var(--bubble-self-background-color);
    color: var(--blue-grey);
  }

  .answering :deep(.chat-message) .chat-message-data .background{
    margin: 0;
    padding: 0;
    background-color: var(--bubble-self-background-color)
  }

  .answering :deep(.chat-message) .date,
  .answering :deep(.chat-message) .fa-arrow-circle-down,
  .answering :deep(.chat-message) .user {
    display: none;
  }
</style>