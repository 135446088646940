<template>
  <el-dialog
    :show-close="!loading"
    :close-on-click-modal="!loading"
    :close-on-press-escape="!loading"
    :visible.sync="visible"
    :before-close="handleClose"
    width="30%"
  >
    <div v-if="!sended" class="upload">
      <el-upload
        drag
        ref="upload"
        name="file_csv"
        :action="action"
        :headers="headers"
        :file-list="fileList"
        :disabled="loading"
        :auto-upload="false"
        :on-success="success"
        :on-error="error"
        accept=".csv"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <p >{{ $t('contacts.drop-your-file-were') }}</p>
        </div>
        <div slot="tip" class="el-upload__tip"><p class="mb-0">{{ $t("contacts.only-csv") }}</p></div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="visible = false">{{ $t("common.cancel") }}</el-button>
        <el-button size="mini" @click="uploadFile" type="primary">{{ $t("contacts.upload") }}</el-button>
      </span>
    </div>
    <div v-else>
      <NotificationABM
        :status="status"
        :errorTitle="$t('contacts.there-was-a-problem')"
        :successTitle="$t('contacts.the-contacts-were-created')"
        :showMessage="false"
      ></NotificationABM>
      <div>
        <p v-html="errorMessage"></p>
        <div v-if="errorsCode === 0" >
          <p v-for="(error, index) in errors[errorsCode].errors" :key="index">{{ $t("contacts." + error.key, { row : error.row }) }}</p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import NotificationABM from "@/components/common/NotificationABM"
import { mapGetters } from "vuex"
export default {
  data() {
    return {
      sended: false,
      action: process.env.VUE_APP_API_HOST + "/organization_contact/masive-creation",
      loading: false,
      visible: false,
      fileList: [],
      status: false,
      errors: [
        { text : "contacts.the-file-have-the-following-errors", },
        { text : "contacts.headers-bad-formared", },

      ],
      errorsCode : null
    };
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible;
    },
    handleClose() {
      if (!this.loading) {
        this.visible = false;
        this.sended = false;
        this.status = false;
        this.errors = []
      }
    },
    uploadFile() {
      this.loading = true;
      this.sended = false;
      this.$refs.upload.submit();
    },
    success() {
      this.sended = true;
      this.loading = false;
      this.status = true;
    },
    error(error) {
      this.sended = true;
      this.loading = false;
      const response = JSON.parse(error.message)
      this.errorsCode = response.code
      this.errors[response.code] = { ...response, ...this.errors[response.code], }

    }
  },
  computed: {
    ...mapGetters({
      token: "geToken",
    }),
    headers: function () {
      return {
        Authorization: `Bearer ${this.token}`,
      };
    },
    errorMessage: function() {
      if (this.errorsCode) {
        return this.$t(this.errors[this.errorsCode].text, this.errors[this.errorsCode])
      }
      return ''
    }
  },
  components: {
    NotificationABM,
  },
};
</script>

<style scoped>
.upload {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

ul {
  list-style: none;
}
</style>