<template>
  <the-form 
    :form="form" 
    :store="store" class="crud form" 
    :id="id" 
    :edit="edit" 
    :entity="entity" 
    :title="title"
    :updating="updating"
    :after-submit="afterSubmit"
    :submit="beforeSubmit"></the-form>
</template>

<script>

import TheForm from '@/components/crud/TheForm'
import Rules from '@/components/crud/rules'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("configuration.users.edit-user") : this.$t("configuration.users.create")
    },
    form() {
      const roles = [
        { label: this.$t("configuration.users.agent"), value: "ROLE_AGENT" },
        { label: this.$t("configuration.users.user"), value: "ROLE_USER" },
        { label: this.$t("configuration.users.basic"), value: "ROLE_BASIC" },
      ]

      if (this.$store.getters.isAdmin) {
        roles.push({ label: this.$t("configuration.users.admin"), value: "ROLE_ADMIN" })
        roles.push({ label: this.$t("configuration.users.manager"), value: "ROLE_MANAGER" })
        roles.push({ label: this.$t("configuration.users.receptionist"), value: "ROLE_RECEPTIONIST" })
      }

      let nodes = {
        nodes: [
          { component: 'input', index: 'name', label: this.$t('configuration.users.name'), required: true },
          { component: 'input', index: 'lastname', label: this.$t('configuration.users.lastname'), required: true },
          { component: 'input', index: 'email', label: this.$t('configuration.users.email'), required: true },
          {
            component: 'input', index: 'password', label: this.$t('configuration.users.password'), required: true, type: 'password', validations:
              [
                { validator: Rules.lengthIsGreaterThan(10), trigger: 'blur', show: true, label: this.$t('configuration.users.ten-character-minimum') },
                { validator: Rules.atLeastOneNumber, trigger: 'blur', show: true, label: this.$t('configuration.users.at-least-one-number-(0-9)') },
                { validator: Rules.atLeastOneLetterMinus, trigger: 'blur', show: true, label: this.$t('configuration.users.at-least-one-lowercase-letter-(a-z)') },
                { validator: Rules.atLeastOneLetterMayus, trigger: 'blur', show: true, label: this.$t('configuration.users.at-least-one-capital-letter-(A-Z)') },
                { validator: Rules.atLeastOneSpecialChar, trigger: 'blur', show: true, label: this.$t('configuration.users.at-least-one-special-character-(%-#-$-&-*!?-¡¿)') },
              ]
          },
          { component: 'input', index: 'password-repeat', label: this.$t('configuration.users.repeat-password'), required: true, type: 'password', repeat: true },
          { component: 'select', index: 'roles', required: true, label: this.$t('configuration.users.roles'), values: roles, multiple: true, value: null },
          { component: 'select', index: 'organizations', required: true, label: this.$t('configuration.users.organizations'), store: 'organizations', multiple: true, value: null, filterable: true, 'label-value': 'name' },
          { component: 'select', index: 'organizationSessionsAlt', required: true, label: this.$t('configuration.users.where-to-take-the-sessions'), store: 'organizations', value: null, filterable: true, 'filter-by': { 'field-value': 'organizations', index: '@id' }, 'label-value': 'name' },
          {
            component: 'select',
            index: 'extensions',
            required: false,
            label: this.$t('configuration.users.extensions'),
            store: 'extensions',
            multiple: true,
            value: null,
            'label-value': ['name', 'extension'],
            filterable: true,
            params: { "used": "false" },
            'group-by': {
              prop: 'organization',
              label: 'name'
            },
            'filter-by': {
              'field-value': 'organizations',
              prop: 'organization',
              index: '@id'
            },
            'add-value-to-list': true,
            'fetch-when': {
              index: "organizations",
              param: "organization.id"
            }
          },
          {
            component: 'radio',
            index: 'hasVideoCall',
            label: this.$t('configuration.users.has-videocall'),
            value: 'no',
            values: [{
              value: 'no',
              label: 'No'
            },
            {
              value: 'yes',
              label: 'Si'
            }]
          },
          {
            component: "switch",
            index: "zohoIsEnable",
            label: this.$t('configuration.users.zoho_enabled'),
            value: false,
          },
          {
            component: "switch",
            index: "zohoClickDial",
            label: this.$t('configuration.users.zoho_click_dial'),
            value: false,
         } 
        ]
      }

      nodes = this.edit ? nodes.nodes.filter(field => field.index !== 'password' && field.index !== 'password-repeat') : nodes.nodes

      return {
        nodes
      }
    }
  },
  data() {
    return {
      store: 'users',
      updating : false,
    }
  },
  components: {
    TheForm
  },
  created() {
    if (this.edit && this.entity) {
      this.$store.dispatch("extensions/all", {
        used: false,
        pagination: false,
        ["organization.id"]: this.entity.organizations.map((organization) => organization.id)
      });
    }
  },
  methods: {
    beforeSubmit(form) {
      if (this.edit) {
        delete form.password
      } 
      form.zohoIsEnable = form.zohoIsEnable ? 1 : 0;
      form.zohoClickDial = form.zohoClickDial ? 1 : 0;
      return form
    },
    afterSubmit(data) {
      if (this.entity) { 
        this.updating = true
        const extensions = data.extensions ? Array.from(data.extensions) : []
        const deleteExtensions = this.entity.extensions.filter(id => !extensions.includes(id))
        const promises = deleteExtensions.map(async (ext) => {
          const id = ext['@id'].replace('/api/extensions/', '')
          const _extension = await this.$store.dispatch('extensions/one', id)
          _extension.used = "false"
          _extension.organization = _extension.organization['@id'];
          await this.$store.dispatch('extensions/update', { id, payload : _extension })
        })
        Promise.all(promises).finally(() => {
          this.updating = false
        })
      }
    }
  },
}
</script>

<style></style>