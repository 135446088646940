import NotFound from '../../views/errors/404'

export default [
  {
		path: '*',
		name: 'notFound',
		components: {
			appView : NotFound
		}
	}
]