import TheContactList from '../../views/contacts/TheContactList'
import TheNewContactForm from '../../views/contacts/TheNewContactForm'
import TheEditContactForm from '../../views/contacts/TheEditContactForm'

export default [
  {
    name : "Contacts",
    path : "/contacts",
    component : TheContactList,
    meta : {
      title : 'main-menu.contacts',
      requiresAuth : true,
      access_control : ['ROLE_ADMIN','ROLE_USER','ROLE_BASIC','ROLE_MANAGER', 'ROLE_AGENT'],
      isInMenu : true,
      type : 'link',
      icon : 'fas fa-address-book',
      order : 4,
    },
  },
  {
    name : "EditContact",
    path : "/contacts/:id/edit",
    component : TheEditContactForm,
    meta : {
      requiresAuth : true,
      access_control : ['ROLE_ADMIN','ROLE_USER','ROLE_BASIC','ROLE_AGENT']
    },
    props: true,
    beforeEnter : (to, from, next) => {
      if (!to.params.user) {
        next('/contacts')
      } else {
        next()
      }
    }
  },
  {
    name : "NewContact",
    path : "/contacts/new",
    component : TheNewContactForm,
    props : true,
    meta : {
      requiresAuth : true,
      access_control : ['ROLE_ADMIN','ROLE_USER','ROLE_BASIC','ROLE_AGENT']
    },
  }
]