var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('h2',[_vm._v(_vm._s(_vm.$t("main-menu.reports.usage-report")))]),_c('el-date-picker',{staticStyle:{"margin-bottom":"var(--column)"},attrs:{"type":"daterange","align":"left","unlink-panels":"","picker-options":_vm.pickerOptions,"size":"small"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('the-table',{attrs:{"columns":_vm.columns,"pagination":{},"rows":_vm.rows,"loading":_vm.loading,"search":['organization.name']},on:{"fetch":_vm.fetch},scopedSlots:_vm._u([{key:"organization.name",fn:function(scope){return [_c('router-link',{attrs:{"to":{
          name: 'usage_report_by_organization', 
          params: { 
            id: scope.row.organization.id, 
            name: scope.row.organization.name, 
            from: _vm.formatDate(_vm.dateRange[0]), 
            to: _vm.formatDate(_vm.dateRange[1]) 
          },
          query : {
            id: scope.row.organization.id,
            from: _vm.formatDate(_vm.dateRange[0]), 
            to: _vm.formatDate(_vm.dateRange[1]) 
          }
        }}},[_vm._v(" "+_vm._s(scope.row.organization.name)+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }