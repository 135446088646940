import sideImages from '@/api/side-images';

const state = {
    sideImages : []
}

const getters = {
    getSideImages : (state) => state.sideImages
}

const mutations = {
    SET_SIDE_IMAGES : (state, sideImages) => state.sideImages = sideImages,
    ADD_SIDE_IMAGE : (state, sideImage) => state.sideImages.push(sideImage),
    REMOVE_SIDE_IMAGE : (state, sideImage) => state.sideImages = state.sideImages.filter(image => image.id !== sideImage.id),
    RESERT_ALL_ACTIVE : (state) => {
        state.sideImages = state.sideImages.map((image) => {
            image.active = 0;
            return image;
        });
    },
    UPDATE_SIDE_IMAGE : (state, sideImage) => {
        state.sideImages.map((image, index) => {
            if (image.id === sideImage.id) {
                state.sideImages[index].active = sideImage.active;
            }
        });
    }
};

const actions = {
    fetchSideImages({commit}) {
        return new Promise((resolve) => { 
            sideImages.fetchSideImages().then((response) => {
                commit('SET_SIDE_IMAGES', response.data['hydra:member']);
                return resolve();
            })
        });
    },
    addSideImage({commit}, image) {
        if (parseInt(image.active) === 1) {
            commit('RESERT_ALL_ACTIVE', image);    
        }

        commit('ADD_SIDE_IMAGE', image);
    },
    updateSideImage({commit}, image) {
        sideImages.updateSideImage(image).then(() => {

            if (parseInt(image.active) === 1) {
                commit('RESERT_ALL_ACTIVE', image);    
            }

            commit('UPDATE_SIDE_IMAGE', image);
        })
    },
    deleteSideImage({commit}, image) {
        sideImages.deleteSideImage(image.id).then(() => {
            commit('REMOVE_SIDE_IMAGE', image);
        });
    }
}

export default {
    state,
    getters,
    mutations,
    actions,
}