<template>
  <div>
    <h2>{{ $t("user-configuration.theme.title") }}</h2>
    <el-radio-group v-model="theme" @change.native="setTheme(theme)">
        <el-radio label="dark" border>{{ $t("user-configuration.theme.dark-theme") }}</el-radio>
        <el-radio label="light" border>{{ $t("user-configuration.theme.light-theme") }}</el-radio>
        <el-radio label="clasic" border>{{ $t("user-configuration.theme.default") }}</el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TheThemeConfiguration",
  data() {
      return {
        theme : this.getTheme()
      }
  },
  methods: {
    ...mapGetters(["getTheme"]),
    ...mapActions(["setTheme"]),
  },
};
</script>

<style scoped>
h2 {
    margin-bottom: var(--column);
}
</style>