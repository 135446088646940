import zohoapi from "../../api/zoho";

const config = {
  client_id : process.env.VUE_APP_ZOHO_CLIENT_ID,
  client_secret : process.env.VUE_APP_ZOHO_CLIENT_SECRET,
}

const redirectUri = () => (window.location.origin + '/loading')

const getAuthLink = (clientId) => {
  return `https://accounts.zoho.com/oauth/v2/auth?scope=PhoneBridge.call.log,PhoneBridge.zohoone.search
  &client_id=${clientId}&redirect_uri=${redirectUri()}&state=testing&response_type=code&access_type=offline`
}

const state = {
  client_id: config.client_id,
  client_secret: config.client_secret,
  redirect: getAuthLink(config.client_id),
  auth_token : '',
  code: '',
  access_token: '',
  refresh_token: '',
  api_domain: '',
  zoho_enabled : false,
  account_domain : '',
  listening : false,
  click2call : false,
  connected: false
}

const getters = {
  getListening: (state) => state.listening,
  getClientId : (state) => state.client_id,
  getClientSecret: (state) => state.client_secret,
  getAuthToken: (state) => state.access_token,
  getUri: (state) =>  state.redirect,
  getCode: (state) => state.code,
  getZohoEnabled : (state) => state.zoho_enabled,
  getRefreshToken: (state) => state.refresh_token,
  getApiDomain: (state) => state.api_domain,
  getAccountDomain : (state) => state.account_domain,
  getClick2Call : (state) => state.click2call,
  getConnected: (state) => state.connected
}

const mutations = {
  setListening: (state, value) => state.listening = value,
  setCode: (state, value) => state.code = value,
  setAuthToken: (state, value) => state.auth_token = value,
  setApiDomain: (state, value) => state.api_domain = value,
  setAccessToken: (state, value) => state.access_token = value,
  setRefreshToken: (state, value) => state.refresh_token = value,
  setLoading: (state, value) => state.loading = value,
  setZohoEnabled: (state, value) => state.zoho_enabled = value,
  setAccountDomain : (state, value) => state.account_domain = value,
  setClick2Call: (state, value) => state.click2call = value,
  setConnected: (state, value) => state.connected = value
}

const actions = {
  enable() {
    window.location.href = state.redirect
  },
  async disable({rootGetters}) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    firebase.database().ref(`zoho/${user.uid}`).set({
      zoho_enabled : null,
      access_token : null,
      refresh_token : null,
      api_domain : null,
      account_domain : null,
      click2call : null
    })
  },
  listenConfig({ commit, rootGetters }) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    commit('setListening', true);
    firebase.database().ref(`zoho/${user.uid}`).on('value', (snap) => {
      const data = snap.val();
      commit('setZohoEnabled', snap.exists() ? data.zoho_enabled : false);
      commit('setAccessToken', snap.exists() ? data.access_token : '');
      commit('setRefreshToken', snap.exists() ? data.refresh_token : '');
      commit('setApiDomain', snap.exists() ? data.api_domain : '');
      commit('setAccountDomain', snap.exists() ? data.account_domain : '');
      commit('setClick2Call', snap.exists() ? data.click_2_call : false);
    })
  },
  setCode({commit}, code) {
    commit('setCode', code)
  },
  setZohoTokens({getters, rootGetters}, data) {
    const user = rootGetters.getUser;
    const firebase = rootGetters.getFirebase;
    const zoho = rootGetters["integrations/getIntegrationByName"]("zoho");
    data.click2call = zoho ? zoho.click_two_call : false;
    firebase.database().ref(`zoho/${user.uid}`).update({
      zoho_enabled: true,
      access_token: data.access_token ? data.access_token : getters.getAccessToken,
      refresh_token: data.refresh_token ? data.refresh_token : getters.getRefreshToken,
      api_domain: data.api_domain ? data.api_domain : getters.getApiDomain,
      click_2_call : data.click2call ? data.click2call : getters.getClick2Call
    })
  },
  async fetchDataCenters({rootGetters}, location) {
    try {
      const { data } = await zohoapi.fetchDataCenters();

      const user = rootGetters.getUser;
      const firebase = rootGetters.getFirebase;
      firebase.database().ref(`zoho/${user.uid}`).update({
        account_domain: data.locations[location]
      })


    } catch (error) {
      console.log(error);
    }
  },
  async fetchAccessAndRefreshToken({dispatch, getters}) {
    try {
      const params = {
        grant_type: 'authorization_code',
        client_id: state.client_id,
        client_secret: state.client_secret,
        redirect_uri: redirectUri(),
        code: getters.getCode,
      };

      const {data} = await zohoapi.fetchAccessAndRefreshToken(null, params);

      if (data.error) {
        return Promise.reject(data.error);
      }

      data.zoho_enabled = true;

      dispatch('setZohoTokens', data)

      return Promise.resolve(data);

    } catch (error) {
      return Promise.reject(error);
    }
  },
  async callNotify({commit}, params) {
    commit('setLoading', true)
    try {
      await zohoapi.callNotify(null, params)
    } catch (error) {
      console.log(error);
    }
  },
  async toggleIntegration({commit}, enabled = true) {
    commit('setLoading', true)
    try {
      const {data} = await zohoapi.toggleIntegration(enabled);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  },
  async toggleClick2Dial({commit}, enabled = true) {
    commit('setLoading', true)
    try {
      const {data} = await zohoapi.toggleClick2Dial(enabled);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  },
  deleteCookies({commit}) {
    commit('setAccessToken', '');
    commit('setRefreshToken', '');
    commit('setApiDomain', '');
    commit('setDataCenter', '');
    commit('setClick2call' , '')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}