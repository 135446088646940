<template>
  <el-tooltip effect="dark" :content="$t('call.decline')" placement="bottom">
    <button><i @click="decline" class="fas fa-phone-slash"></i></button>
  </el-tooltip>
</template>

<script>
export default {
  props : {
    janus: {
      required : true
    }
  },
  methods: {
    decline() {
      this.janus.decline()
    }
  }
}
</script>

<style scoped>
.fa-phone-slash {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  background-color: var(--red);
  border-radius: 50%;
  color: white;
}

button {
  margin-top: 0 !important;
  border: 0 !important;
  outline: none;
  background-color: transparent;
}
</style>