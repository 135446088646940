<template>
  <div class="crud">
    <h2>{{ title }}</h2>
    <div class="content">
      <section class="input-type">
        <ul class="list">
          <li v-for="inputType in inputTypes" :key="inputType.type" class="item" draggable="true"
            @dragstart="dragStart(inputType, $event)">
            <i :class="['icon', inputType.icon]"></i>
            <span>
              <span class="label">{{ inputType.label }}</span>
              <span class="description">{{ inputType.description }}</span>
            </span>
          </li>
        </ul>
      </section>
      <el-form :model="form" label-width="120px" label-position="top" target="#app-main-content" ref="form" >
        <el-form-item :label="$t('common.name')" required prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('common.description')" prop="description">
          <el-input type="textarea" v-model="form.description" maxlength="255" show-word-limit
            :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
        </el-form-item>
        <section class="inputs">
          <div>
            <el-form :model="lastDraggedInput" label-width="120px" label-position="top" class="has-inputs" size="small">
              <div v-for="(input, index) in inputs" :key="index" class="input-container">

                <div :class="{ 'selected': lastDraggedInput && lastDraggedInput.id === input.id }" @click="setEditInput(input)" class="input">
                <div class="sort-inputs">
                    <el-button size="small" type="text" @click.prevent.stop="handleMoveUp(index)" :disabled="index === 0">
                      <i class="fas fa-arrow-up"></i>
                    </el-button>
                    <el-button size="small" type="text" @click.prevent.stop="handleMoveDown(index)" :disabled="index === inputs.length - 1">
                      <i class="fas fa-arrow-down"></i>
                    </el-button>
                  </div>
                  <div class="input-content">
                        <TheFormInput :input="input"></TheFormInput>
                      </div>
                    <el-button type="danger" size="small" @click="handleDeleteInput(input)">{{ $t('common.delete') }}</el-button>
                  </div>
                  <div @dragover.prevent @dragenter="dragEnter($event)" @dragleave="dragOut($event)" @drop="drop($event, index + 1)" class="drop-between">
                  <p>{{ $t('contact-me.form.drag-here')  }}</p>
                </div>
                </div>
            </el-form>
          </div>
          <div @dragover.prevent @dragenter.prevent @drop="drop($event)" class="drop" v-if="inputs.length === 0">
            <p class="help">{{ $t('contact-me.form.drag-elements-here') }}</p>
            <p class="help">{{ $t('contact-me.form.grab-an-element-from-the-left-and-drop-it-here') }}</p>
          </div>
          <p v-if="haveNoInputs" class="error">{{ $t('contact-me.form.should-at-least-add-one-field') }}</p>
        </section>
        <footer>
          <el-button @click="handleCancel">{{ $t('common.cancel')}}</el-button>
          <el-button type="primary" @click="handleSave">{{ $t('common.save') }}</el-button>
        </footer>
      </el-form>
      <section class="input-properties" v-if="lastDraggedInput">
        <el-form :model="lastDraggedInput" label-width="120px" label-position="top">
          <el-form-item :label="$t('common.label')" required>
            <el-input v-model="lastDraggedInput.label" @keydown="handleChangeProperty('label')" maxlength="30"
              show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="URL" required v-if="lastDraggedInput.type === 'link'">
            <el-input v-model="lastDraggedInput.configuration.url" @keydown="handleChangeProperty('url')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('common.description')">
            <el-input v-model="lastDraggedInput.description"></el-input>
          </el-form-item>
          <div v-if="lastDraggedInput.type === 'select'">
            <el-form-item :label="$t('contact-me.form.options')">
              <el-input v-model="optionsString" @change="handleChangeProperty('options')" type="textarea"></el-input>
            </el-form-item>
            <p class="help">{{  $t('contact-me.form.enter-one-option-per-line') }}</p>
            <el-form-item :label="$t('contact-me.form.is-multiple')" class="switch">
              <el-switch v-model="lastDraggedInput.configuration.multiple" @change="handleChangeProperty('multiple')" />
            </el-form-item>
          </div>
          <div v-if="lastDraggedInput.type === 'date' || lastDraggedInput.type === 'datetime'">
            <el-form-item :label="$t('contact-me.form.minDate')">
              <el-date-picker type="date" v-model="lastDraggedInput.configuration.minDate" ></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('contact-me.form.maxDate')">
              <el-date-picker type="date" v-model="lastDraggedInput.configuration.maxDate" ></el-date-picker>
            </el-form-item>
          </div>
          <el-form-item :label="$t('contact-me.form.is-required')" class="switch" v-if="lastDraggedInput.type !== 'link'">
            <el-switch v-model="lastDraggedInput.configuration.required" @change="handleChangeProperty('required')" />
          </el-form-item>
        </el-form>
      </section>
    </div>
  </div>
</template>

<script>
import TheFormInput from '@/components/chat-classification/TheFormInput.vue'
export default {
  components: {
    TheFormInput
  },
  props: {
    entity: {
      required: false,
    },
    deleteFormInput: {
      required: false,
    }
  },
  data () {
    const inputs = this.entity ? this.entity.inputs : []

    inputs.sort((a, b) => a.order - b.order )

    return {
      inputs,
      form: {
        name: this.entity ? this.entity.name : '',
        description: this.entity ? this.entity.description : '',
      },
      formRules: {
        name: [
          { required: true, message: 'The name is required' },
          { min: 4, max: 255, message: 'Length should be 3 to 255' }
        ]
      },
      title: !this.entity ? this.$t('contact-me.form.create-form') : this.$t('contact-me.form.edit-form'),
      inputTypes: [
        {
          icon: 'fas fa-font',
          type: 'text',
          label: this.$t('contact-me.form.forms-labels.text'),
          description:  this.$t('contact-me.form.forms-description.text'),
          configuration: {
            required: false,
            label: this.$t('contact-me.form.forms-labels.text')
          }
        },
        {
          icon: 'fas fa-font',
          type: 'number',
          label: this.$t('contact-me.form.forms-labels.number'),
          description: this.$t('contact-me.form.forms-description.number'),
          configuration: {
            required: false,
            label: this.$t('contact-me.form.forms-labels.number')
          }
        },
        {
          icon: 'fas fa-at',
          type: 'email',
          label: this.$t('contact-me.form.forms-labels.email'),
          description: this.$t('contact-me.form.forms-description.email'),
          configuration: {
            required: false,
            label: this.$t('contact-me.form.forms-labels.email')
          }
        },
        {
          icon: 'fas fa-calendar-alt',
          type: 'date',
          label: this.$t('contact-me.form.forms-labels.date'),
          description: this.$t('contact-me.form.forms-description.date'),
          configuration: {
            required: false,
            label: this.$t('contact-me.form.forms-labels.date'),
            minDate: null,
            maxDate: null,
          }
        },
        {
          icon: 'fas fa-calendar-alt',
          type: 'datetime',
          label: this.$t('contact-me.form.forms-labels.datetime'),
          description: this.$t('contact-me.form.forms-description.datetime'),
          configuration: {
            required: false,
            label: this.$t('contact-me.form.forms-labels.datetime')
          }
        },
        {
          icon: 'fas fa-sort-down',
          type: 'select',
          label: this.$t('contact-me.form.forms-labels.select'),
          description: this.$t('contact-me.form.forms-description.select'),
          configuration: {
            required: false,
            label: this.$t('contact-me.form.forms-labels.select'),
            options: ['Option 1', 'Option 2', 'Option 3'],
            multiple: false
          }
        },
        {
          icon: 'fas fa-external-link-alt',
          type: 'link',
          label: this.$t('contact-me.form.forms-labels.link'),
          description: this.$t('contact-me.form.forms-description.link'),
          configuration: {
            label: this.$t('contact-me.form.forms-labels.link'),
            url: 'http://example.com',
          }
        }
      ],
      draggedInput: null,
      lastDraggedInput: null,
      inputValues: {},
      optionsString: ''
    }
  },
  methods: {
    async deleteInput (input) {
      this.inputs = this.inputs.filter(_input => input.id !== _input.id)
      if (this.draggedInput && this.draggedInput.id === input.id) {
        this.draggedInput = null
      }
    },
    handleDeleteInput (input) {
      this.$confirm(this.$t('contact-me.form.are-you-sure-that-you-want-to-delete-the-input', { label: input.label}), this.$t('contact-me.form.delete-input'), {
        confirmButtonText: this.$t('common.yes'),
        cancelButtonText: this.$t('common.no'),
        type: 'warning'
      }).then(async () => {
        this.deleteInput(input)
        if (typeof input.id !== "string") {
          await this.deleteFormInput(input)
        } 
        this.$message({
          type: 'success',
          message: this.$t("contact-me.form.the-input-was-deleted")
        })
      })
    },
    dragStart (input, event) {
      this.draggedInput = input
      event.dataTransfer.effectAllowed = 'move'
    },
    drop (event, index = 0) {
      event.target.classList.remove('drag-enter')
      if (this.draggedInput) {

        const targetElement = event.target;
        const targetRect = targetElement.getBoundingClientRect();
        const dropPosition = event.clientY - targetRect.top;
        const isDropAbove = dropPosition < targetRect.height / 2;
        
        const input = this.createFormField({...this.draggedInput})

        if (isDropAbove) {
          this.inputs.splice(index, 0, input);
        } else {
          this.inputs.splice(index + 1, 0, input);
        }

        this.dragOut(event)
        this.lastDraggedInput = input
        if (input.type === "select") {
          this.optionsString = input.configuration.options.join('\n')
        }
        this.draggedInput = null
      }
    },
    dragEnter(event) {
      event.preventDefault();
      const targetElement = event.target.closest('.drop-between');
      if (targetElement) {
        targetElement.classList.add('drag-enter');
      }
    },
    dragOut(event) {
      const targetElement = event.target.closest('.drop-between');
      if (targetElement) {
        targetElement.classList.remove('drag-enter');
      }
    },
    createFormField (input) {
      const { type, configuration, id = crypto.randomUUID(), description = '' } = input
      const { label } = configuration

      return { type, label, id, configuration, description }
    },
    setEditInput (input) {
      input.configuration = typeof input.configuration === "string" ? JSON.parse(input.configuration) : input.configuration
      if (input.type === "select") {
        this.optionsString = input.configuration.options.join('\n')
      }
      this.lastDraggedInput = input
    },
    handleChangeProperty (property) {
      this.inputs = this.inputs.map((input) => {
        if (input.id === this.lastDraggedInput.id) {
          if (property === 'options') {
            input.configuration.options = this.optionsString.split('\n').filter(option => option)
          }
          if (property === 'multiple') {
            input.configuration.multiple = this.lastDraggedInput.configuration.multiple
          }

          if (property === 'required') {
            input.configuration.required = this.lastDraggedInput.configuration.required
          }

          if (property === 'minDate') {
            input.configuration.minDate = this.lastDraggedInput.configuration.minDate
          }

          if (property === 'maxDate') {
            input.configuration.maxDate = this.lastDraggedInput.configuration.maxDate
          }

          if (property === 'url') {
            input.configuration.url = this.lastDraggedInput.configuration.url
          }
        }
        return input
      })
    },
    handleMoveUp (index) {
      if (index > 0) {
        const item = this.inputs.splice(index, 1)[0]
        this.inputs.splice(index - 1, 0, item)
      }
    },
    handleMoveDown (index) {
      if (index < this.inputs.length - 1) {
        const item = this.inputs.splice(index, 1)[0]
        this.inputs.splice(index + 1, 0, item)
      }
    },
    handleSave() {
      const form = {...this.form }

      this.$refs.form.validate((valid) => {
        if (!valid || this.haveNoInputs) {
          return false
        }

        const { name, description } = form

        const inputs = this.inputs.map((input, index) => {
          const formField =  Object.assign({}, input)
          formField.order = index + 1
          formField.configuration = typeof formField.configuration !== "string" ?  JSON.stringify(formField.configuration) : formField.configuration
          return formField
        })

        this.$emit('saveForm', { inputs, name, description })  
      })
    },
    handleCancel() {
      this.$emit('cancel')
    },
  },
  computed: {
    haveNoInputs() {
      return this.inputs.length === 0
    }
  }
}
</script>

<style scoped>
.input-type {
  width: 35%;
  border-right: 1px solid #DCDFE6
}

.input-properties {
  width: 45%;
  border-left: 1px solid #DCDFE6;
  padding-left: 16px
}

.input-type .list {
  display: flex;
  flex-direction: column;
  gap: 4px
}

.input-type .item {
  cursor: pointer;
  display: inline-flex;
  gap: 4px;
  border-radius: 4px;
  padding: 8px;
  align-items: center;
}

.input-type .item:hover {
  background-color: rgb(236, 245, 255);
}

.input-type .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DCDFE6;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  color: #2e2e2e;
}

.input-type .label {
  font-weight: bold;
  color: #606266;
}

.input-type .description {
  font-size: 14px;
  display: block;
  color: #8492a6;
}

.content {
  display: inline-flex;
  gap: 16px;
  width: 100%;
}

.content form {
  width: 100%;
}

.inputs {
  width: 100%;
  min-height: 150px;
  border-radius: 8px;
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  padding: 8px;
}

.inputs p {
  text-align: center;
  margin-bottom: 8px;
}

.input p.help {
  text-align: left;
}

.input {
  padding: 4px;
  margin-bottom: 8px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  border: 1px solid transparent;
  width: 100%;
}

.input:hover {
  cursor: pointer;
  border: 1px solid #409EFF;
}

.input.selected {
  border: 1px solid #409EFF;
  background-color: rgb(236, 245, 255);
}

.input .sort-inputs {
  display: flex;
  flex-direction: column;
}

.input label {
  padding: 0!important;
  margin: 0!important
}

.input a {
  display: block;
  width: 100%
}

.input-properties .el-form-item {
  margin-bottom: 8px;
}

.has-inputs {
  display: flex;
  flex-direction: column;
}

.help {
  font-size: 14px;
  color: #8492a6;
}

.el-button+.el-button {
  margin: 0
}

.switch {
  display: inline-flex;
  gap: 8px;
}

.drop {
  padding: 8px;
  border-radius: 4px;
  border: 1px dashed #8492a6;
  width: 370px;
}

footer {
  display: inline-flex;
  gap: 8px;
  width: 100%;
  margin-top: 16px;
  justify-content: end;
}

.error {
   color: #F56C6C;
   font-size: 14px;
   margin-top: 8px;
}

.drop-between {
  height: 6px;
  background-color: #409EFF;
  margin-bottom: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: 0
}

.drop-between.drag-enter {
  opacity: 1
}

.drop-between p {
  position: absolute;
  z-index: 100;
  font-size: 12px;
  text-align: center;
  margin-bottom: 0;
  background-color: #409EFF;
  color: white;
  padding: 2px;
  border-radius: 4px
}

.input-container{
  width: 400px
}
</style>