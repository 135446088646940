import TheAgentDashboard from '../../views/Dashboard'

export default [ 
  {
    name : "dashboard",
    path : "/dashboard",
    meta : {
      title : 'main-menu.dashboard',
      access_control : ['ROLE_ADMIN','ROLE_AGENT'],
      requiresAuth : true,
      isInMenu : true,
      type : 'link',
      icon : 'fas fa-tachometer-alt',
      order : 2
    },
    component : TheAgentDashboard
  }
]