<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="entity" :title="title" :submit="submit" :after-submit="afterSubmit">
    <template #inputs>
      <section>
        <el-button size="small" @click.native="handleAddButton" :disabled="!haveReachLimitOfQuickButtons" style="margin-bottom: 8px;">
          {{ $t("configuration.extensions.quick-buttons.add")}}
        </el-button>
        <section v-for="(button, index) in buttons" :key="index" class="quick-buttons">
          <el-form-item :label="$t('configuration.extensions.quick-buttons.label')">
            <el-input v-model="button.label" />
          </el-form-item>
          <el-form-item :label="$t('configuration.extensions.quick-buttons.action')">
            <el-select v-model="button.type">
              <el-option v-for="action in actions" :key="action" :value="action" :label="$t('configuration.extensions.quick-buttons.' + action )">
                {{ $t('configuration.extensions.quick-buttons.' + action ) }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('configuration.extensions.quick-buttons.value')">
            <el-input v-model="button.value" />
          </el-form-item>
          <div>
            <el-button type="danger" @click.native="handleDeleteButton(index)">{{ $t('common.delete') }}</el-button>
          </div>
        </section>
      </section>
    </template>
  </the-form>
</template>

<script>
import TheForm from '@/components/crud/TheForm'
import form from './form'
export default {
  props : {
    entity : {
      required : false,
    },
    id : {
      required : false,
      type : String,
      default : "0"
    }
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("configuration.extensions.edit-extension") : this.$t("configuration.extensions.create")
    },
    haveReachLimitOfQuickButtons() {
      return this.buttons.length < 3
    }
  },
  data() {
    return {
      form,
      buttons: this.entity && this.entity.quickButtons ? this.entity.quickButtons : [],
      store: 'extensions',
      actions: ['transfer_to','quick_dial'],
      limit: 3
    }
  },
  components : {
    TheForm 
  },
  methods : {
    submit(form) {
      form.extension = form.extension.toString()
      return form
    },
    handleAddButton() {
      if (this.haveReachLimitOfQuickButtons) {
        this.buttons.push({
          label: '',
          type: 'transfer_to',
          value: ''
        })
      }
    },
    async handleDeleteButton(index) {
      const button = this.buttons[index]
      this.buttons = this.buttons.filter((_button, i) => i !== index )
      if (button.id) {
        await this.$store.dispatch(`quickButtons/destroy`, button.id)
      }
    },
    async afterSubmit({ result }) {
      if (this.buttons.length === 0) {
        return
      }
      const extension = result['@id']
      const promisees = this.buttons.map((button) => {
        const payload = {...button, extension }
        const data = button.id === undefined ? payload : { id : button.id, payload }
        const action = button.id === undefined ? 'create' : 'update'
        return this.$store.dispatch(`quickButtons/${action}`, data)
      })
      await Promise.all(promisees)
    }
  }
}
</script>

<style scoped>
.quick-buttons {
  display: inline-flex;
  width: 100%;
  gap:16px;
  align-items: end;
}
</style>