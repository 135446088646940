<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :can-delete="true" :search='search' :goBack="goBack" :init-query-params="{
        ['organization.id'] : organizationId
      }"></the-crud>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'
export default {
  data() {
    return {
      search: ['name'],
      title: this.$t("contact-me.chat-center.quick-response-template.menu-title"),
      store: "quickResponseTemplates",
      columns: [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t("common.name")},
        // { index : 'type', label : this.$t('contact-me.chat-center.quick-response-template.type') },
        { index : 'department.name', label : this.$t('contact-me.chat-center.quick-response-template.department'), type: 'department-template'}
      ]
    }
  },
  components: {
    TheCrud
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-chat-center"})
    }
  },
  computed: {
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  }
}
</script>