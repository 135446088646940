const initialBreakState = () => ({
    id: 0,
    startedAt: null,
    name: '',
});

// const getAgentInit = () => {
//     if (localStorage.getItem('agent')) {
//         const tmpAgent = JSON.parse(localStorage.getItem('agent'));
//         return { ...tmpAgent, chat_center_id: 0 }
//     }
//     return {};
// }

//const organizationId = getAgentInit().organization_id;

const state = {
    key: null,
    break: initialBreakState(),
    logged: false,
    isInCall: false,
    active: null,
    activity: [],
    agent: {},
    logged_call_center: false,
    logged_chat_center: false,
    recentlyTransferred: [],
    breaks: [],
    integrationsData: [],
    pendingChats: [],
    pendingChatAccept: null,
    lastChatAcceptedId: null,
    activeTabId: null,
    agentJanus: null,
    showAcceptChatWindow: false,
    chatDepartments : [],
    logged_at : (localStorage.getItem("t")) ? parseInt(localStorage.getItem("t")) : null,
    click2call : null,
    lastCallUniqueId : null,
    callingFromMailCenter : false,
    callKeyActive: '',
    DO_UNREGISTER_REGISTER: false,
    isAlreadyListening: false,
};

const getters = {
    isUsingNewChatEngine: (state) => state.agent.departments.length > 0,
    DO_UNREGISTER_REGISTER: (state) => state.DO_UNREGISTER_REGISTER,
    getLoggedAt : (state) => state.logged_at,
    getShowAcceptChatWindow: (state) => state.showAcceptChatWindow,
    getAgentJanus: (state) => state.agentJanus,
    getActiveTabId: (state) => state.activeTabId,
    getLastChatAcceptedId: (state) => state.lastChatAcceptedId,
    getPendingChatAcept: (state) => state.pendingChatAccept,
    getPendingChats: (state) => state.pendingChats.filter((chat) => {
        return chat.status === 0 && chat.user_id === 0 && state.chatDepartments.includes(chat.dep_id)
    }),
    getChatPath: (state) => {
        const organizationId = state.agent.organization_id;
        return `livehelperchat/${organizationId}/chats`;
    },
    getBreaks: (state) => state.breaks,
    getActiveKey: (state) => state.key,
    getAgent: (state) => state.agent,
    getBreak: (state) => state.break,
    getLogged: (state) => state.logged,
    getIsInCall: (state) => state.isInCall,
    getActivityList(state) { 
        const now = new Date(state.logged_at);
        now.setDate(now.getDate() - 1);
        return state.activity.filter((item) => {
            if (item.date) {
                const date = (item.type === 'call') ? new Date(item.date) : item.date;
                if (now.getTime() < date.getTime()  && item.title) {
                    return item;
                }
            }
        })
    },
    getActiveItem: (state) => state.active,
    getLoggedInCallCenter: (state) => state.logged_call_center,
    getLoggedInChatCenter: (state) => state.logged_chat_center,
    getActivityByKey: (state) => (key) => {
        return state.activity.find(item => item.key === key);
    },
    getRecentlyTransferred: (state) => state.recentlyTransferred,
    getChatInChatCenterByRoomId: (state) => (roomId) => {
        return state.activity.find(item => item.data.roomId === roomId)
    },
    getIntegrationShowData: (state) => state.integrationsData,
    getChatDepartments : (state) => state.chatDepartments,
    getClick2Call : (state) => state.click2call,
    getLastCallUniqueId : (state) => state.lastCallUniqueId,
    getCallingFromMailCenter : (state) => state.callingFromMailCenter,
    getCallKeyActive: (state) => state.callKeyActive,
    getOrganizationId: (state) => state.agent.organization_id ? state.agent.organization_id :  0,
    getIsAlreadyListening: (state) => state.isAlreadyListening
};

const mutations = {
    SET_AGENT: (state, value) => state.agent = value,
    SET_DO_UNREGISTER_REGISTER: (state, value) => state.DO_UNREGISTER_REGISTER = value,
    SET_CALL_KEY_ACTIVE: (state, value) => state.callKeyActive = value,
    SET_CLICK_2_CALL : (state, value) => state.click2call = value,
    SET_LOGGED_AT : (state, value) => state.logged_at = value,
    SET_CHAT_DEPARTMENTS : (state, depts) => state.chatDepartments = depts,
    TOGGLE_SHOW_ACCEPT_CHAT_WINDOW: (state) => state.showAcceptChatWindow = !state.showAcceptChatWindow,
    SET_AGENT_JANUS: (state, janus) => (state.agentJanus = janus),
    SET_ACTIVE_TAB_ID: (state, id) => (state.activeTabId = id),
    SET_LAST_CHAT_ACCEPTED_ID: (state, id) => (state.lastChatAcceptedId = id),
    SET_PENDING_CHAT_ACCEPT: (state, chat) => (state.pendingChatAccept = chat),
    ADD_TO_PENDING_CHATS: (state, chat) => (state.pendingChats.push(chat)),
    ADD_SHOW_DATA: (state, value) => (state.integrationsData.push(value)),
    SET_INCALL: (state, value) => (state.isInCall = value),
    SET_BREAK: (state, data) => (state.break = data),
    SET_LOGGED: (state, value) => (state.logged = value),
    ADD_ACTIVITY: (state, data) => (state.activity.unshift(data)),
    SET_CALL_CENTER_LOGGED: (state, value) => (state.logged_call_center = value),
    SET_CHAT_CENTER_LOGGED: (state, value) => (state.logged_chat_center = value),
    SET_CHAT_CENTER_ID: (state, value) => (state.agent.chat_center_id = value),
    SET_ACTIVE_KEY: (state, key) => (state.key = key),
    RESET_BREAK: (state) => (state.break = initialBreakState()),
    ADD_TO_RECENTLY_TRANSFERRED: (state, data) => (state.recentlyTransferred.push(data)),
    SET_BREAKS: (state, breaks) => (state.breaks = breaks),
    SET_ACTIVE_ITEM: (state, key) => {
        const activity = state.activity.find((activity) => key === activity.key)
        state.active = (!activity.activeItem) ? activity : null;
        state.activity = state.activity.map((activity) => {
            activity.activeItem = key === activity.key;
            if (state.active == null && activity.activeItem) {
                activity.activeItem = false;
            }
            return activity
        });
        state.showAcceptChatWindow = false;
    },
    FORCE_ACTIVE_ITEM: (state, key) => {
        const activity = state.activity.find((activity) => key === activity.key)
        state.active = activity;
        state.activity = state.activity.map((activity) => {
            activity.activeItem = key === activity.key;
            return activity
        });
    },
    SET_ACTIVITY_TO_INACTIVE_EXCEPT: (state, roomId) => state.activity = state.activity.map((activity) => {
        activity.activeItem = roomId === activity.key;
        return activity
    }),
    UPDATE_ACTIVITY: (state, item) => {
        state.activity.forEach((data, index) => {
            if (data.key === item.key) {
                state.activity[index].title = item.title || state.activity[index].title;
                state.activity[index].clientinfo = item.clientinfo || state.activity[index].clientinfo

                if ('active' in item) {
                    state.activity[index].active = item.active
                }

                if (data.type === 'chat') {
                    state.activity[index].data.chatClassifications = item.data.chatClassifications ?? state.activity[index].data.chatClassifications
                    state.activity[index].data.tags = item.data.tags ?? state.activity[index].data.tags
                    state.activity[index].data.unreadMessages = ('unreadMessages' in item.data) ? item.data.unreadMessages : state.activity[index].data.unreadMessages
                    state.activity[index].data.status = (item.data.status) ? item.data.status : state.activity[index].data.status
                    if ('unsubscribe' in item) {
                        if (item.unsubscribe) {
                            state.activity[index].unsubscribe = item.unsubscribe;
                        }
                    }
                }

                if (data.type === 'call') {
                    state.activity[index].queue = item.queue ?? null;
                    state.activity[index].id_call = item.id_call;
                    state.activity[index].data.ended = item.data.ended;
                    state.activity[index].data.script = item.data.script;
                    state.activity[index].data.status = item.data.status;
                    state.activity[index].integrations = item.integrations;
                    //state.activity[index].transcription = item.transcription;
                    
                    if (state.activity[index].forms.length > 0) {
                        for (let iForm in state.activity[index].forms) {
                            state.activity[index].forms[iForm].status = (item.forms[iForm].status) ? item.forms[iForm].status : '';
                        }
                    } else {
                        state.activity[index].forms = item.forms
                    }

                    if (state.activity[index].call_disposition_forms.length > 0) {
                        for (let iForm in state.activity[index].forms) {
                            state.activity[index].call_disposition_forms[iForm].status = (item.call_disposition_forms[iForm].status) ? item.call_disposition_forms[iForm].status : '';
                        }
                    } else {
                        state.activity[index].call_disposition_forms = item.call_disposition_forms
                    }

                    state.activity[index].call_disposition_values = item.call_disposition_values;
                    state.activity[index].data.call_disposition_saved = item.data.call_disposition_saved;

                }
            }
        })
    },
    UPDATE_ACTIVITY_FORMS: (state, item) => {
        state.activity.forEach((data, index) => {
            if (state.activity[index].forms) {
                if (state.activity[index].forms.length > 0) {
                    for (let iForm in state.activity[index].forms) {
                        state.activity[index].forms[iForm].status = (item.forms[iForm].status) ? item.forms[iForm].status : '';
                    }
                } else {
                    state.activity[index].forms = item.forms
                }
            }
        })
    },
    REMOVE_FORM_FROM_LIST: (state, { id, callkey }) => {
        const activity = state.activity.find(activity => activity.callKey === callkey)
        if (activity) {
            const forms = {}
            for (let index in activity.forms) {
                const form = activity.forms[index];
                if (form.id !== id) {
                    forms[index] = form;
                }
            }
            for (let index in state.activity) {
                if (state.activity[index].callKey === callkey) {
                    state.activity[index].forms = forms
                }
            }
        }
    },
    SET_PENDING_CHAT_STATUS: (state, { chat_id, status }) => {
        state.pendingChatAccept.msg_status = status
        state.pendingChats = state.pendingChats.map((chat) => {
            if (chat.chat_id == chat_id) {
                chat.msg_status = status
            }
            return chat;
        })
    },
    UPDATE_PENDING_CHATS: (state, chat) => {
        state.pendingChats = state.pendingChats.map((pending) => pending.id === chat.id ? chat : pending);
        if (state.pendingChatAccept) {
            if (state.pendingChatAccept.id === chat.id) {
                state.pendingChatAccept.agentIsTryingToAccept = chat.agentIsTryingToAccept;
                state.pendingChatAccept.status = chat.status;
                state.pendingChatAccept.dep_id = chat.dep_id;
            }
        }
    },
    REMOVE_FROM_PENDING : (state, chat) => state.pendingChats = state.pendingChats.filter(c => c.roomId !== chat.roomId),
    RESTART_VALUES: (state) => {
        state.key = null;
        state.break = initialBreakState();
        state.logged = false;
        state.isInCall = false;
        state.active = {};
        state.logged_call_center = false;
        state.logged_chat_center = false;
        state.integrationsData = [];
        state.pendingChatAccept = null;
        state.lastChatAcceptedId = null;
    },
    REMOVE_ACTIVITY : (state, key) => state.activity = state.activity.filter(activity => activity.key !== key),
    SET_LAST_CALL_UNIQUE_ID : (state, id) => state.lastCallUniqueId = id,
    SET_CALLING_FROM_MAILCENTER : (state, value) => state.callingFromMailCenter = value,
    UPDATE_TRANSCRIPTION: (state, { key, transcription }) => {
        state.activity.forEach((data, index) => {
            if (data.key === key) {
                state.activity[index].transcription.push(transcription)
            }
        })
    },
    SET_ALREADY_LISTENING: (state, value) => state.isAlreadyListening = value
};

const actions = {
    closeAcceptChatWindow({commit}) {
        commit('TOGGLE_SHOW_ACCEPT_CHAT_WINDOW', false)
    },
    toggleShowAcceptWindow({ commit }) {
        commit('TOGGLE_SHOW_ACCEPT_CHAT_WINDOW')
    },
    setAgentJanus({ commit }, janus) {
        commit('SET_AGENT_JANUS', janus);
    },
    checkIfLogged({ rootGetters }) {
        return new Promise((resolve) => {
            const user = rootGetters.getUser;
            const agent = rootGetters.getAgent;
            const firebase = rootGetters.getFirebase;
            const organizationId = rootGetters.getOrganizationId;
            firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}`).once('value', (snap) => {
                return resolve(snap.val() ? true : false);
            });
        });
    },
    /**
     * TODO
     * Hay que mover funciones de lugar así queda mas legible
     */
    listenAgent({ rootGetters, commit, dispatch }) {

        if (rootGetters.getIsAlreadyListening) {
            return;
        }

        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        const path = `organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}`;
        commit('SET_ALREADY_LISTENING', true)

        firebase.database().ref(`${path}/info`).on('value', (snap) => {
            if (snap.val()) {
                const data = snap.val();
                commit('SET_LOGGED', data.logged);
                commit('SET_CALL_CENTER_LOGGED', (data.call_logged && data.call_center_id_audit));
                commit('SET_CHAT_CENTER_LOGGED', (data.chat_center_logged && data.chat_center_session_id));
                commit('SET_CHAT_CENTER_ID', (data.chat_center_id));
                commit('SET_DO_UNREGISTER_REGISTER', data.DO_UNREGISTER_REGISTER || false);

                if (data.chat_center_depts) {
                    commit('SET_CHAT_DEPARTMENTS', data.chat_center_depts);
                }

                if (data.tabId) {
                    commit('SET_ACTIVE_TAB_ID', data.tabId)
                }

            } else {
                commit('SET_LOGGED', false);
                commit('SET_CALL_CENTER_LOGGED', false);
                commit('SET_CHAT_CENTER_LOGGED', false);
            }
        });

        firebase.database().ref(`${path}/integrations`).on('child_added', (snap) => {
            if (snap.val()) {
                const data = snap.val();
                if (data.type === 'show_data') {
                    data.id = snap.key;
                    commit('ADD_SHOW_DATA', data);
                }
            }
        });

        firebase.database().ref(`${path}/call_center_break`).on('value', (snap) => {
            if (snap.val()) {
                const data = snap.val()
                commit('SET_BREAK', {
                    id: data.id_break,
                    started_at: new Date(data.started_at).getTime(),
                    name: data.name,
                })
            } else {
                commit('RESET_BREAK');
            }
        })

        if (agent.call) {

            const date = new Date()
            date.setMinutes(date.getMinutes() - agent.agentActivityTimeout);

            const callCenterCallRef = firebase.database().ref(`call_center/${user.uid}`).orderByChild('started').startAt(date.getTime());

            // Tendria que mandar esto a otro lado ....
            callCenterCallRef.on('child_added', (snap) => {
                commit('SET_CALL_KEY_ACTIVE', snap.key);
                firebase.database().ref(`organization/${organizationId}/calls`).child(snap.key).on('value', (snapshot) => {
                    if (snapshot.val()) {
                        const data = snapshot.val();
                        let forms = {};
                        let count = 0;
                        if (data.forms) {
                            for (let key in data.forms) {
                                data.forms[key].position = count;
                                data.forms[key].key = key;
                                if (data.forms[key].inputs) {
                                    data.forms[key].inputs = data.forms[key].inputs.map((input, index) => ({...input, index}))
                                }
                                forms[key] = data.forms[key];
                                count++;
                            }
                        }

                        let call_disposition_forms = {}
                        if (data.call_disposition_forms) {
                            for (let key in data.call_disposition_forms) {
                                data.call_disposition_forms[key].key = key;
                                if (data.call_disposition_forms[key].inputs) {
                                    data.call_disposition_forms[key].inputs = data.call_disposition_forms[key].inputs.map((input, index) => ({...input, index}))
                                }
                                call_disposition_forms[key] = data.call_disposition_forms[key];
                            }
                        }

                        const item = {
                            title: data.callerid || 'Unknow',
                            date: data.started,
                            type: 'call',
                            data: {
                                type: data.type,
                                script: data.script,
                                callerid: data.callerid,
                                ended : data.ended && !data.active ? data.ended : 0,
                                status : 'status' in data ? data.status : '',
                                uniqueid : snap.key.replace('@', '.'), 
                                call_disposition_saved : Object.keys(call_disposition_forms).map(index => call_disposition_forms[index]).filter(form => form.status === "saved")
                            },
                            forms: forms,
                            clientinfo: (data.clientInfo) ? data.clientInfo.filter((data) => data.value !== '') : [],
                            key: snapshot.key,
                            active: data.active,
                            id_call: data.id_call,
                            callKey: snap.key,
                            integrations: data.integrations || null,
                            activeItem : false,
                            realyEnded: false,
                            iTransfered: false,
                            call_disposition_forms : call_disposition_forms,
                            call_disposition_values : 'call_disposition_values' in data ? data.call_disposition_values : [],
                            transcription: data.transcription ?? [],
                            queue: data.queue ?? ''
                        }

                        const activity = rootGetters.getActivityByKey(snapshot.key);
                        if (!activity) {
                            item.activeItem = false,
                            commit('ADD_ACTIVITY', item);
                            if (data.active) {
                                commit('SET_ACTIVE_ITEM', item.key);
                            }
                        } else {
                            //item.active = !activity.realyEnded
                            if (!activity.iTransfered) {
                                commit('UPDATE_ACTIVITY', item);
                            } else {
                                commit('UPDATE_ACTIVITY_FORMS', item)
                            }
                        }

                        commit('SET_LAST_CALL_UNIQUE_ID', snapshot.key);
                    }
                })

                firebase.firestore().collection(`organizations/${organizationId}/calls/${snap.key}/transcription`).onSnapshot((snapshot) => {
                    for (const change of snapshot.docChanges()) {
                        if (change.type === "added") {
                            commit('UPDATE_TRANSCRIPTION', { key: snap.key, transcription: change.doc.data() });
                        }
                    }
                })
            });

            callCenterCallRef.on('child_changed', (snap) => {
                const data = snap.val();
                const activity = rootGetters.getActivityByKey(snap.key);
                if (activity) {
                    if ('ended' in data) {
                        activity.data.ended = data.ended;
                    }
                    activity.active = data.active;
                    activity.realyEnded = !data.active;
                    activity.iTransfered = ('transfered' in data) ? data.transfered : false
                    commit('UPDATE_ACTIVITY', activity);
                }
            })

            firebase.database().ref(`call_center/${user.uid}`).on('child_removed', (snap) => {
                commit('REMOVE_ACTIVITY', snap.key);
            });
        }

        if (agent.chat) {
            dispatch('agentConsoleChat/listenNewAgentsChats');
        }

        firebase.database().ref(`${path}/other-actions`).on('child_added', (snap) => {
            const data = {...snap.val(), id : snap.key }
            switch (data.type) {
                case 'click2call':
                    commit('SET_CLICK_2_CALL', data)
                    break;
            }
        })
    },
    removeClick2Call({ rootGetters, commit }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        const path = `organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}`;
        const click2call = rootGetters.getClick2Call;
        firebase.database().ref(`${path}/other-actions`).child(click2call.id).remove()
        commit('SET_CLICK_2_CALL', null)
    },
    resetUnreadMesages({ commit, dispatch }, roomId) {
        commit('UPDATE_ACTIVITY', {
            key: roomId,
            data: {
                unreadMessages: 0
            }
        }),
            dispatch('_chats/updateUnreadMessages', roomId);
    },
    doLogin({ rootGetters, commit, dispatch }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;   
        const organizationId = rootGetters.getOrganizationId;

        const data = {
            logged: true,
            number: agent.number,
            name: user.name + ' ' + user.lastname,
            email: user.email,
            has_chat_center: (agent.chat) ? true : null,
            extension: (agent.call) ? agent.extension.extension : null,
            chat_center_id: (agent.chat_center_id) ? agent.chat_center_id : null,
            picture: user.picture ?? "",
            logged_started_at : new Date().getTime(),
        }

        firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/info`).remove().then(() => {

            firebase.database().ref(`organization/${organizationId}/dashboard/${user.uid}`).update({
                in_break : false,
                number: agent.number,
                name: user.name + ' ' + user.lastname,
                logged_started_at : new Date().getTime(),
                picture: user.picture ?? "",
            });

            firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/info`).set(data).then(() => {
                if (agent.call) {
                    firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center/${user.uid}`).set({
                        type: 'login'
                    });
                }

                if (agent.chat) {
                    firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/chat_center/${user.uid}`).set({
                        type: 'login'
                    });

                    if (getters.isUsingNewChatEngine) {
                        firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/info`).update({
                            chat_center_logged: true, 
                            chat_center_id: agent.id,
                            chat_center_depts: agent.departments.map(departments => departments.id),
                            chat_center_session_id: 1,
                        }) 

                        firebase.database().ref(`organization/${organizationId}/dashboard/${user.uid}`).update({
                            chat_logged : true,
                            chat_center_id : agent.id,
                        })

                        dispatch('listenAgentChatStatus')
                    }
                }

                firebase.database().ref(`organization/${organizationId}/dashboard/${user.uid}`).update({
                    have_call_center: agent.call,
                    have_chat_center: agent.chat,
                    have_mail_center: agent.mail,
                })
            });
        })

        const now = new Date().getTime()
        commit('SET_LOGGED_AT', now)
        localStorage.setItem('t', now)
        commit('SET_LOGGED', true);
    },
    doCallCenterLogin({ rootGetters }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        const data = {
            logged: true,
            number: agent.number,
            name: user.name + ' ' + user.lastname,
            has_chat_center: (agent.chat) ? true : null,
            extension: (agent.call) ? agent.extension.extension : null,
        }

        firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/info`).update(data).then(() => {
            if (agent.call) {
                firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
                    type: 'login'
                });
            }
        });
    },
    doLogout({ rootGetters, commit }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;

        if (agent.chat && !agent.call) {
            firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}`).remove();
            return;
        }

        firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center/logout`).set({
            type: 'logout',
            need_response: true
        }).then(() => {

            setTimeout(() => {
                firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}`).once('value', (snap) => {
                    if (snap.val()) {
                        firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}`).remove();
                    }
                    commit('RESTART_VALUES')
                })
            }, 1000)

            //
        })

        if (getters.isUsingNewChatEngine) {
            firebase.database().ref(`organization/${organizationId}/dashboard/${user.uid}`).update({
                chat_logged : false,
                chat_center_id : agent.id
            })

            /* firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/info`).set({
                chat_center_depts: null,
                chat_center_id: null,
            }) */
        }
    },
    doBreak({ rootGetters }, data) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        if (agent.call) {
            firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
                type: 'break',
                id_break: data.id,
                name : data.name
            });
        }
    },
    unBreak({ rootGetters }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        if (agent.call) {
            firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
                type: 'unbreak',
            });
        }
    },
    doCall({ rootGetters }, data) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;

        return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
            type: 'call',
            callTo: data.number,
            need_response: false
        });
    },
    doBlindTransfer({ rootGetters }, destination) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
            type: 'blind_transfer',
            destination: destination,
            need_response: true
        });
    },
    doAssistedTransfer({ rootGetters }, destination) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
            type: 'attended_transfer',
            destination: destination,
            need_response: false
        });
    },
    doChatTransfer({ rootGetters, dispatch }, { destination, roomId, transferType }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        if (!getters.isUsingNewChatEngine) {
            return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/chat_center`).push({
                type: 'transfer',
                roomId: roomId,
                destination: destination,
                need_response: true
            });
        } else {
            return dispatch('agentConsoleChat/createAction', {
                type: 'transfer',
                roomId,
                destination,
                need_response: true,
                organizationId,
                transferType
            });
        }
    },
    doCloseChat({ getters, rootGetters, dispatch }, { roomId, chat_id }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        if (!getters.isUsingNewChatEngine) {
            return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/chat_center`).push({
                type: 'close',
                roomId: roomId,
                chat_id: chat_id,
                need_response: true
            });
        } else {
            return dispatch('agentConsoleChat/createAction', {
                type: 'close',
                roomId: roomId,
                chat_id: chat_id,
                need_response: true,
                organizationId: organizationId,
            });
        }
    },
    doBanUser({ rootGetters }, roomId) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        if (!getters.isUsingNewChatEngine) {
            return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/chat_center`).push({
                type: 'ban',
                roomId: roomId,
                need_response: true
            });
        }
    },
    async doAddPropertyToClientInfo({ dispatch,rootGetters }, { roomId, payload} ) {
        const organizationId = rootGetters.getOrganizationId;
        const response = await dispatch('agentConsoleChat/createAction', {
            type: 'add_property_client_info',
            roomId,
            organizationId,
            ...payload,
            need_response: true
        });
        return response;
    },
    doUpdatePropertyValue({ dispatch, rootGetters }, { roomId, value, id } ) {
        const organizationId = rootGetters.getOrganizationId;
        return dispatch('agentConsoleChat/createAction', {
            type: 'update_property_client_info',
            roomId,
            organizationId,
            value,
            id,
            need_response: true
        });
    },
    markChatAsPending({ dispatch, rootGetters }, { roomId }) {
        const organizationId = rootGetters.getOrganizationId;
        return dispatch('agentConsoleChat/createAction', {
            type: 'mark_chat_as_pending',
            roomId,
            organizationId,
            need_response: true
        })
    },
    setInCall({ commit }, value = true) {
        commit('SET_INCALL', value);
    },
    setActiveItem({ commit }, key) {
        commit("SET_ACTIVE_ITEM", key);
    },
    setFormStatusToActive({ rootGetters }, path) {
        const firebase = rootGetters.getFirebase;
        firebase.database().ref(path).update({
            status: 'saving'
        });

        setTimeout(() => {
            firebase.database().ref(path).once('value', (snap) => {
                const values = snap.val();
                if (values.status === "saving") {
                    firebase.database().ref(path).update({
                        status: 'timeout'
                    })
                }
            })
        }, 30000);
    },
    saveForm({ rootGetters }, data) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
            type: 'save_form',
            form: data.form,
            id_call: data.id_call,
            call_type: data.call_type,
            call_key: data.call_key,
            form_key: data.form_key
        });
    },
    listenToAction({ rootGetters }, data) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        const ref = `organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/${data.path}/${data.actionId}/`;
        firebase.database().ref(ref).on('value', (snap) => {
            if (snap) {
                data.callback(snap.val());
            }
        })
    },
    removeAction({ rootGetters }, { actionId, path }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        const ref = `organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/${path}`;
        firebase.database().ref(ref).child(actionId).remove();
    },
    removeForm({ commit, rootGetters }, { id, callkey, formKey }) {
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        firebase.database().ref(`organization/${organizationId}/calls/${callkey}/forms`).child(formKey).remove().then(() => {
            commit('REMOVE_FORM_FROM_LIST', { id, callkey });
        })
    },
    addRecentlyTransferred({ commit }, data) {
        commit('ADD_TO_RECENTLY_TRANSFERRED', data);
    },
    fetchBreaks({ rootGetters, commit }) {
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        firebase.firestore().collection('breaks').doc(organizationId + "").onSnapshot((data) => {
            if (data.exists) {
                const breaks = data.data().breaks.filter(b => !('status' in b) || b.status === 'A');
                commit('SET_BREAKS', breaks);
            }
        });
    },
    fetchAgentsWitChat({ rootGetters }) {
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        const ref = `organization/${organizationId}/group-${agent.groupNumber}/agents/`;
        return new Promise((resolve) => {
            const list = []
            firebase.database().ref(ref).once('value', (snap) => {
                const agents = snap.val();
                for (let index in agents) {
                    const agent = agents[index];
                    if (agent.info.chat_center_id && agent.info.chat_center_session_id) {
                        list.push(agent.info);
                    }
                }
                return resolve(list);
            });
        });
    },
    listenToInactiveChats({ rootGetters, commit }) {
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        const date = new Date()
        date.setMinutes(date.getMinutes() - agent.agentActivityTimeout);
        const time = date.getTime() / 1000;
        
        firebase.firestore().collection('livehelperchat').doc(`${organizationId}`).collection('chats').where("time",">", time).onSnapshot((querySnapshot) => {
            querySnapshot.docChanges().forEach(function (change) {
                const val = change.doc.data()
                const data = { ...val, roomId: change.doc.id, tags: val.chatDispositions ?? [] };
                data.agentIsTryingToAccept = ('agentIsTryingToAccept' in data) ? data.agentIsTryingToAccept : 0;

                if (change.type === "added") {
                    commit('ADD_TO_PENDING_CHATS', data);
                }
                
                if (change.type === "modified") {
                    commit('UPDATE_PENDING_CHATS', data)
                }

                if (change.type === "removed") {
                    commit('REMOVE_FROM_PENDING', data);
                }
            });
        })
    },
    setPendingChatAcept({ commit }, item) {
        commit('SET_PENDING_CHAT_ACCEPT', item);
    },
    acceptPendingChat({ rootGetters, commit, dispatch }, item) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        commit('SET_PENDING_CHAT_STATUS', { chat_id: item.chat_id, status: 'waiting' });
        if (agent.departments.length === 0) {
            return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/chat_center`).push({
                type: 'accept_pending_chat',
                chat_id: item.id,
                roomId: item.roomId
            });
        } else {
            dispatch('agentConsoleChat/createAction', {
                type: 'accept_pending_chat',
                chat_id: item.id,
                roomId: item.roomId,
                organizationId: organizationId,
            });
        }
    },
    doActionBeforeCloseSession({ rootGetters, getters, dispatch }, {action, chats}) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        if (!getters.isUsingNewChatEngine) {
            return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/chat_center`).push({
                type : 'before_close',
                action : action,
                chats : chats
            });
        } else {
            dispatch('agentConsoleChat/createAction', {
                type : 'before_close',
                action : action,
                chats : chats,
                organizationId: organizationId,
            });
        }
    },
    updateValueOfForm({ rootGetters }, { path, index, value} ) {
        const firebase = rootGetters.getFirebase;
        //firebase.database().ref(`organization/${organizationId}/calls/${callKey}/forms/${formKey}/inputs/${index}/value`).set(value)
        firebase.database().ref(`${path}/inputs/${index}/value`).set(value)
    },
    closeLastCall({ rootGetters }) {
        const user = rootGetters.getUser;
        const firebase = rootGetters.getFirebase;
        const key = rootGetters.getLastCallUniqueId
        const organizationId = rootGetters.getOrganizationId;
        if (key) {
            firebase.database().ref(`call_center/${user.uid}`).child(key).update({ active : false, ended: new Date().getTime() })
            firebase.database().ref(`organization/${organizationId}/calls`).child(key).update({ active : false, ended: new Date().getTime(), holding: false })
        }
    },
    setCallTransfered({ rootGetters }) {
        const user = rootGetters.getUser;
        const firebase = rootGetters.getFirebase;
        const callKey = rootGetters.getCallKeyActive;
        firebase.database().ref(`call_center/${user.uid}`).child(callKey).update({ transfered : true })
    },
    SET_DO_UNREGISTER_REGISTER({ rootGetters }) {
        const user = rootGetters.getUser;
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;

        return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/info`).update({
            DO_UNREGISTER_REGISTER: null,
            ACK: true
        });
    },
    doRemoveSessionId({ rootGetters }) {
        return new Promise((resolve) => {
            const user = rootGetters.getUser;
            const agent = rootGetters.getAgent;
            const firebase = rootGetters.getFirebase;
            const organizationId = rootGetters.getOrganizationId;
            setTimeout(() => {
                firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
                    type : 'remove_session_id',
                }).then(() => resolve());
            }, 5000)
            
        })
    },
    addTranscription({ rootGetters }, { transcription }) {
        const firebase = rootGetters.getFirebase;
        const key = rootGetters.getLastCallUniqueId
        const organizationId = rootGetters.getOrganizationId;
        firebase.firestore().collection(`organizations/${organizationId}/calls/${key}/transcription`).add(transcription)
    },
    setHoldingValue({ rootGetters, getters }, value) {
        const agent = rootGetters.getAgent;
        const firebase = rootGetters.getFirebase;
        const key = getters.getCallKeyActive
        const organizationId = rootGetters.getOrganizationId;
        const call = getters.getActiveItem
        firebase.database().ref(`organization/${organizationId}/calls`).child(key).update({ holding: value })
        if (call && call.queue) {
            if (value) {
                firebase.database().ref(`organization/${organizationId}/queues_dashboard/${call.queue}/inHoldCalls`).child(key).set({
                    agent: agent.number,
                    callerid: call.data?.callerid ?? 'Unknown',
                    timestamp: call.date, 
                    uniqueid: call.data.uniqueid ?? 'Unknown',
                    holding: true
                })
            } else {
                firebase.database().ref(`organization/${organizationId}/queues_dashboard/${call.queue}/inHoldCalls`).child(key).remove()
            }
        }

        
    },
    listenAgentChatStatus({ rootGetters, getters }) {
        // const agent = rootGetters.getAgent;
        const user = rootGetters.getUser
        const firebase = rootGetters.getFirebase;
        const organizationId = rootGetters.getOrganizationId;
        if (getters.isUsingNewChatEngine) {
            firebase.database().ref('.info/connected').on('value', (snapshot) => {
                if (snapshot.val() == false) {
                    return
                }

                const dashboardRef = firebase.database().ref(`organization/${organizationId}/dashboard/${user.uid}`)
                dashboardRef.onDisconnect().update({ chat_logged: false}).then(() => {
                    dashboardRef.update({ chat_logged: true })
                })
            })
        }
    }
};

export default {
    state,
    getters,
    mutations,
    actions
}