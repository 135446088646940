<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="entity" :title="title" :submit="beforeSubmit"></the-form>
</template>

<script>
import TheForm from '@/components/crud/TheForm'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data() {
    return {
      store: "quickResponseTemplates",
      form: {
        nodes: [
          { component : 'input', index : 'name', label : this.$t('contact-me.chat-center.quick-response-template.name'), required : true },
          { component : "select", index : "department", label : this.$t("contact-me.chat-center.quick-response-template.department"), clearable: true, clearableLabel: 'Ver en todos los departamentos', store : 'departments', required : false, 'label-value' : ['name'], 'index-value' : "@id", params: { ["organization.id"] : this.$store.getters["contactMe/getOrganizationId"]}},
          // { component : "select", index : "type", label : this.$t("contact-me.chat-center.quick-response-template.type") , required: true, values: [
          //   { label: 'text', value: "TEXT" }
          // ]},
          { component : 'input', index : 'description', label : this.$t('contact-me.chat-center.quick-response-template.description'), type: 'textarea', required : true },
          
        ]
      }
    }
  },
  components: {
    TheForm
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("contact-me.chat-center.quick-response-template.edit") : this.$t("contact-me.chat-center.quick-response-template.new")
    },
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  },
  methods: {
    beforeSubmit(data) {
      const organizationId = this.$store.getters["contactMe/getOrganizationId"];
      data.organization = `/api/organizations/${organizationId}`;
      if(data.department.length === 0) data.department = null
      return data;
    }
  }
}
</script>