import Chat from '../../views/Chat'
import Messages from '../../views/Messages'
import store from '../../store';

export default [
  {
    name: 'Messages',
    path: '/messages',
    components: {
      default: Messages
    },
    meta: {
      requiresAuth: true,
      access_control: ['ROLE_ADMIN', 'ROLE_USER','ROLE_MANAGER'],
      icon : "fas fa-comment",
      type : "link",
      isInMenu : true,
      title : 'main-menu.chat.title',
      order : 5,

    }
  },
  {
    name: 'MessagesWith',
    path: '/messages/:id',
    components: {
      default: Chat
    },
    props: {
      default: true
    },
    meta: {
      requiresAuth: true,
      access_control: ['ROLE_ADMIN', 'ROLE_USER','ROLE_MANAGER'],
    },
    beforeEnter: (to, from, next) => {
      const id = to.params.id;
      if (!store.getters['corpochat/getChatById'](id)) {
        return next({ name : "Messages"})
      }

      return next();
    }
  }
]