

<template>
	<el-dialog
		:close-on-click-modal="closeOnClickModal"
		:show-close="closeOnClickModal"
		:close-on-press-escape="closeOnClickModal"
		:visible.sync="show"
		@closed="initState()"
		width="60%"
		id="group-modal"
	>
		<h2 v-if="showForm">{{ $t('groups.create-group.title') }}</h2>
		<el-form ref="groupForm" :model="groupForm" :rules="rules" v-if="showForm">
			<el-form-item :label="$t('groups.create-group.name')" prop="name">
				<el-input v-model="groupForm.name"></el-input>
			</el-form-item>
			<div class="list">
				<el-form-item prop="users">
					<el-checkbox-group v-model="groupForm.users">
						<el-checkbox
							v-for="user in users"
							:label="user.uid"
							:key="user.uid"
						>
							{{ user.fullname }}
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</div>
			<el-form-item :label="$t('groups.create-group.is-visible')" >
				<el-switch v-model="groupForm.isVisible"></el-switch>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer" v-if="showForm">
				<el-button type="primary" @click="createNewGroup('groupForm')">{{ $t('groups.create-group.create') }}</el-button>
			</span>
		<div v-if="creating" class="messages"> 
			<h2>{{ $t("groups.create-group.creating-group") }}</h2>
			<p>{{ groupForm.name }}</p>
			<p>{{ $t("common.please-wait") }}</p>
			<i class="fas fa-circle-notch fa-spin"></i>
		</div>
		<div v-if="successCreating" class="messages">
			<Success></Success>
			<h2>{{ $t("groups.create-group.group-created-successfully")}}</h2>
			<p>{{ groupForm.name }}</p>
			<el-button type="success" @click="show = false">{{ $t("common.close") }}</el-button>
		</div>
		<div v-if="errorCreating" class="messages">
			<Error></Error>
			<h2>{{ $t("common.error-message") }}</h2>
			<p>{{ $t("groups.create-group.errors.creating", { name : groupForm.name }) }}</p>
		</div>
	</el-dialog>
</template>

<script>

import Error from '@/components/icons/Error';
import Success from '@/components/icons/Success';
import { mapGetters, mapActions } from "vuex";
export default {
	name : "TheGroupModal",
	data() {
		return {
			showForm : true,
			errorCreating: false,
			successCreating : false,
			closeOnClickModal : true,
			creating : false,
			groupForm: {
				name: "",
				isVisible: false,
				users: [],
			},
			rules: {
				name: [
					{
						required: true,
						message: this.$t("groups.create-group.errors.empty-name	"),
						trigger: "blur",
					},
				],
			},
			users: [],
		};
	},
	components : {
		Error,
		Success
	},
	methods: {
		...mapGetters('_users',["getUsers"]),
		...mapActions(["createGroup","toggleShowCreateGropModal"]),
		toggleDialogVisible() {
			this.show = !this.show;
		},
		initState() {
			this.showForm = true;
			this.creating = false;
			this.errorCreating = false;
			this.successCreating = false;
			this.closeOnClickModal = true;
			this.groupForm.name = "";
			this.groupForm.isVisible = false;
			this.groupForm.users = [];
		},
		createNewGroup(formName) {
			const app = this;
			this.showForm = false;
			this.creating = true;
			this.closeOnClickModal = false;
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.createGroup(this.groupForm)
					.then(function() {
						app.creating = false;
						app.successCreating = true;
						app.closeOnClickModal = true;
					})
					.catch(function() {
						app.creating = false;
						app.errorCreating = true;
						app.closeOnClickModal = true;
					});
				} else {
					return false;
				}
			});
		},
	},
	computed : {
		...mapGetters(['getShowCreateGroupModal']),
		show : {
			get() {
				return this.getShowCreateGroupModal
			},
			set() {
				this.toggleShowCreateGropModal()
			}
		}
	},
	watch: {
		show() {
			if (this.users.length == 0) {
				this.users = this.getUsers();
				this.groupForm.users = [];
			}
		},
	},
};
</script>

<style>
#group-modal .el-form-item {
	margin-bottom: 0;
}

#group-modal .el-dialog__body {
	padding-top: 0;
}

#group-modal .list {
	height: inherit;
	overflow-y: auto;
	max-height: 200px;
	margin-top: calc(var(--column) * 2);
}

#group-modal .el-checkbox-group {
	display: flex;
	flex-direction: column;
}

#group-modal .el-checkbox-group .el-checkbox {
	margin-bottom: var(--column);
}

#group-modal .messages {
	color: var(--blue-grey);
	text-align: center;
}

#group-modal .messages h2 {
	color: var(--blue-grey);
	font-size: 24px;
	margin-bottom: var(--column);
}

#group-modal .error,
#group-modal .success {
	margin: 0 auto;
	margin-bottom: var(--column);
}

</style>
