//import Rules from '@/components/crud/rules'
import i18n from '@/i18n'

export default {
    nodes : [
        { component : 'input', index : 'name', label : i18n.tc('configuration.extensions.name'), required : true },
        { component : 'input', index : 'extension', label : i18n.tc('configuration.extensions.number'), type : "number", required : true },
        { component : 'input', index : 'secret', label : i18n.tc('configuration.extensions.password'), type : "password", required : true },
        { component : "select", index : "organization", label : i18n.tc('configuration.extensions.organization'), store : 'organizations', required : true, 'label-value' : 'name'},
    ]
}