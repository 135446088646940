<template>
  <div clasS="box searching-tickets">
    <div class="header" v-if="!searchingTicket">
        <p >Resultados de la busqueda ({{tickets.length}})</p>
        <i class="fas fa-times" @click="cancel"></i>
    </div>
    <div v-if="searchingTicket" class="main">
      <p >Buscando tickets</p>
      <img src="@/img/undraw_Search_1px8.svg">
      <p class="mb-0">Espere por favor</p>
      <el-button type="text" @click.native="cancel">Cancelar</el-button>
    </div>
    <div v-else>
      <div v-if="tickets.length > 0">
        <el-table :data="tickets" style="width: 100%">
          <el-table-column prop="cdata.subject" label="Asunto" width="180">
            <template slot-scope="scope">
              <router-link :to="{ name: 'ticket', params: { ticketNumber: scope.row.number } }" target="_blank">{{ scope.row.cdata.subject }}</router-link>
            </template>
          </el-table-column>
          <el-table-column prop="created" label="Creado" width="180"></el-table-column>
        </el-table>
      </div>
      <div v-else class="main">
        <p>No se han encontrado resultados para la busqueda del email "{{ email }}"</p>
      </div>
    </div>
  </div>
</template>

<script>
import ticketsapi from '@/api/tickets';
export default {
  name : "TheSearchTicket",
  props : {
    email : {
      required : true,
      default : '',
    }
  },
  data() {
    return {
      searchingTicket : true,
      tickets : [],
      request : null,
      source : null
    }
  },
  methods : {
    searchTicket() {
      const api = ticketsapi.fetchTicketsByEmail('support@osticket.com')
      this.request = api.request;
      this.source = api.source;

      this.request.then((response) => {
        this.tickets = response.data;
      }).finally(() => {
        this.searchingTicket = false;
      });
    },
    cancel() {
      this.source.cancel();
      this.$emit('cancelSearchTicket')
    }
  }
}
</script>

<style scoped>
.main {
  text-align: center;
}

.searching-tickets img {
  width: 15em;
  margin-bottom: var(--column);
}

.searching-tickets {
  flex:1;
}

.header {
    align-content: center;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
</style>