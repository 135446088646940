<template>
  <div class="crud main-view">
    <div class="header">
      <the-header :title="$t('contact-me.pbx.audios.title')"></the-header>
      <div class="buttons">
        <el-button type="primary" @click="$router.push({ name: 'contact-me-pbx-audios-new'})">{{ $t("common.new") }}</el-button>
      </div>
    </div>
    <div>
      <the-table :rows="items" :columns="columns" :loading="loading" :search="search" :real-time="true">
        <template v-slot:actions="scope">
          <el-dropdown trigger="click">
              <el-button icon="fas fa-ellipsis-h" type="text"></el-button>
              <el-dropdown-menu slot="dropdown">
                  <slot name="action-buttons" v-bind:row="scope.row"></slot>
                  <el-dropdown-item icon="fas fa-pencil-alt" @click.native="handleEdit(scope.row)">{{ $t("common.edit") }}</el-dropdown-item>
                  <el-dropdown-item icon="fas fa-trash" @click.native="deleteEntity = scope.row; showDialogDeleteConfirm = true">{{ $t("common.delete") }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </template>
      </the-table>
      <el-dialog 
        :visible.sync="showDialogDeleteConfirm" 
        :close-on-click-modal="!deletingEntity" 
        :close-on-press-escape="!deletingEntity" 
        :show-close="!deletingEntity"
        @closed="deletingClosed"
        width="500px"
      >
      <div v-if="deletingStatus == 0">
        <div v-if="!deletingEntity">
          <h3>{{ this.$t("contact-me.are-you-sure-you-want-to-delete", { name: deleteEntityName } ) }}</h3>
          <p>{{ this.$t("contact-me.this-action-can-not-be-undone") }}</p>
        </div>
        <div v-else>
          <h3>{{ this.$t("contact-me.deleting-please-wait", { name: deleteEntityName }) }}</h3>
        </div>
      </div>
      <div v-else-if="deletingStatus == 200">
        <h3>{{ this.$t("contact-me.has-been-deleted-successfully", { name: deleteEntityName }) }}</h3>
      </div>
      <div v-else>
        <h3>{{ this.$t("contact-me.an-error-occurred-while-trying-to-delete", { name: deleteEntityName }) }}</h3>
      </div>
        <span slot="footer" class="dialog-footer" v-if="!deletingEntity && deletingStatus == 0">
          <el-button @click="showDialogDeleteConfirm = false; deleteEntity = null">{{ this.$t("common.no") }}</el-button>
          <el-button type="primary" @click="handleDelete(deleteEntity)">{{ this.$t("common.yes") }}</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import TheHeader from "../TheHeader.vue";
import TheTable from '@/components/crud/TheTable';
export default {
  data() {
    return {
      columns: [
        { index: "name", label: this.$t("contact-me.pbx.audios.name") },
        { index: "type", label: this.$t("contact-me.pbx.audios.type") },
        { index: "filename", label: this.$t("contact-me.pbx.audios.recording") }, 
        { index: "actions", label: "" }
      ],
      search:  ["name"],
      loading: true,
      deleteEntity: null,
      deletingEntity: false,
      deletingStatus: 0,
      showDialogDeleteConfirm: false,
      recordings: [],
      musics: [],
    }
  },
  computed: {
    items() {
      this.getRecordings( );
      return [ ...this.recordings, ...this.musics ];
    },
    deleteEntityName() {
      return this.deleteEntity ? this.deleteEntity[this.name] : ""
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-pbx"});
    },
    async fetch() {
      this.loading = true;
      await this.$store.dispatch(`pbx_system_recordings/all`);
      await this.$store.dispatch(`pbx_music_categories/all`);
      this.loading = false;
    },
    async getRecordings( ){
      this.recordings =  this.$store.getters[`pbx_system_recordings/getData`];
      this.musics = this.$store.getters[`pbx_music_categories/getData`];

      if (! Array.isArray(this.recordings)) this.recordings = Object.values(this.recordings);
      if (! Array.isArray(this.musics)) this.musics = Object.values(this.musics);

      this.recordings.map( r => {
        r.type = 'system-recording';
        this.$store.commit(`pbx_system_recordings/SET_ID`, 'recordings');
      } );
  
     this.musics.map( r => {
        r.type = 'moh'; 
        r.id = r.name;
        this.$store.commit(`pbx_music_categories/SET_ID`, 'musics');
      } );
    },
    handleEdit(row) {
      this.$router.push({
        name : `contact-me-pbx-audios-edit`,
        params : {
          id: row.id,
          entity : row
        }
      });
    },
    async handleDelete(row) {
      this.deletingEntity = true;
      try {
        let entity = row.type == 'moh' ? `music_categories`: `system_recordings`;
        let storage = row.type == 'moh' ? 'musics': 'recordings';

        await this.$store.dispatch(`pbx_${entity}/delete`, row.id);
        this.deletingStatus = 200;
        this.$emit("deleted", this.deleteEntity);

        const index = this[storage].findIndex( e => e.id === row.id );
        this.$delete( this[storage], index );
      } catch {
        this.deletingStatus = 400;
      } finally {
        this.deletingEntity = false; 
      }
    },
    deletingClosed() {
      this.deletingStatus = 0;
      this.deleteEntity = null;
      this.deletingEntity = false;
    }
  },
  created() {
    this.fetch();
    this.$store.commit(`pbx_system_recordings/SET_ID`, this.id);
  },
  components: {
    TheHeader,
    TheTable
  }
}
</script>

<style scoped>
.main-view .header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>