import i18n from '../../i18n';

const state = {
  tours : [
    {
      id : 'agent-console-basic',
      active : true,
      title : i18n.t('main-menu.agent-console.title'),
      to : 'agent-console',
      description : 'Toma los primeros pasos para utilizar la consola de agente',
      icon : 'fas fa-headset',
      stepts : [
        {
          description : i18n.t("tours.agent-console.first-page"),
          image : '/images/active-console.gif',
          width: '600'
        },
        {
          description : i18n.t("tours.agent-console.second-page"),
          image : '/images/is-active.png',
          fit : true,
          width: '600'
        },
        {
          description : i18n.t("tours.agent-console.third-page"),
          image : '/images/activity.gif',
          fit : true,
          width: '600'
        },
        {
          description : i18n.t("tours.agent-console.four-page"),
          image : '/images/break.gif',
          width: '600'
        },
        {
          description : i18n.t("tours.agent-console.fifth-page"),
          image : '/images/new-call.gif',
          width: '600'
        }
      ]
    },
    {
      id : 'keypad-basic',
      active : true,
      title : i18n.t('keypad.title'),
      description : 'Primeros pasos para realizar llamadas',
      icon : 'icon-hiperme icon-keypad',
      stepts : [
        {
          description : i18n.t("tours.keypad.first-page"),
          image : '/images/making-call.gif',
          width : '150'
        },
        {
          description : i18n.t("tours.keypad.second-page"),
          image : '/images/history-call.gif',
          width : '150'
        }
      ]
    },
    {
      id : 'new-corpo-calls',
      active : true,
      title : 'Nueva interfaz de llamadas corporativas',
      description : 'Primeros pasos para realizar llamadas',
      icon : 'icon-hiperme icon-keypad',
      stepts : [
        {
          description : 'Hemos creado una nuevo lugar para realizar las llamadas corporativas',
          image : '/images/new-ui.gif',
          height : '275'
        },
        {
          description : 'Podras llamar directamente a tus contactos ',
          image : '/images/contacts.png',
        },
        {
          description : 'Podras ver si tienes voicemails y escucharlos',
          image : '/images/voicemail.png',
          width: '200'
        },
        {
          description : 'No te preocupes, podras seguir utilizando HiperMe mientras estas en llamada. La nueva interfaz se cierra al pasar unos segundos de estar en llamada. Si necesitas volver a los controles de llamadas, podras hacerlo utilizando el botón verde',
          image : '/images/call.gif',
          height : '250'
        }
      ]
    }
  ]
}

const getters = {
  getTours : (state)  => state.tours,
  getTour : (state) => (value) => state.tours.find(tour => tour.id === value)
}

const mutations = {
  SET_TOUR : (state, { index, value }) => state.tours = state.tours.map(tour => tour.id === index ? {...tour, active : value} : tour),
  SET_ALL_TOUR : (state) => state.tours = state.tours.map(tour => ({...tour, active : true}))
}

const actions = {
  listenTours({ commit, rootGetters }) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    firebase.firestore().collection('tours').doc(user.uid).onSnapshot((doc) => {
      if (!doc.exists) {
        commit('SET_ALL_TOUR')
      } else {
        const data = doc.data()
        for (const index in data) {
          commit('SET_TOUR', { index, value : data[index]})
        }
      }
    })
  },
  async updateTour({ rootGetters }, { tour, value }) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    const doc = await firebase.firestore().collection('tours').doc(user.uid).get()
    const ref = firebase.firestore().collection('tours').doc(user.uid)

    if (doc.exists) {  
      return ref.update({[tour] : value})
    }

    return ref.set({[tour] : value})
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}