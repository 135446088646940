<template>
  <div class="crud" v-loading="deleting">
    <the-crud 
      :title="title" 
      :store="store" 
      :columns="columns" 
      :can-delete="true" 
      :can-edit="false" 
      :can-create="false"
      :goBack="goBack" 
      :search="['phone']"
      select="multiple"
      :hidden-query-params="{ ['organization.id']: organizationId}" 
      @selection-change="selectionChange">
    <template #buttons>
      <el-button :disabled="selectedChats.length === 0" @click.native="handleDeleteChats">Delete chats</el-button>
    </template>
    </the-crud>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'

export default {
  data () {
    return {
      title: this.$t("contact-me.chat-center.cache-chats"),
      store: "cacheChats",
      columns: [
        { index: 'room_id', label: this.$t("contact-me.chat-center.room_id") },
        { index: 'id', label: "id" },
        { index: 'channel', label: this.$t("contact-me.chat-center.channel") },
        { index: 'department_name', label: this.$t("contact-me.chat-center.department") },
        { index: 'created_at', label: this.$t("common.created_at") },
        { index: 'closed_at', label: this.$t("common.closed_at") },
        { index: 'phone', label: this.$t("common.phone"), hide: true },
      ],
      deleting: false,
      selectedChats: []
    }
  },
  components: {
    TheCrud
  },
  methods: {
    goBack () {
      this.$router.push({ name: "contact-me-chat-center" })
    },
    selectionChange(values) {
      this.selectedChats = values
    },
    async handleDeleteChats() {
      const response = await this.$confirm('Estas seguro de querer eliminar los chats seleccionados? Esta acción no se puede deshacer')
      if (response === "confirm") {
        this.deleting = true
        const promises = this.selectedChats.map(async ({ id }) => {
          return await this.$store.dispatch('cacheChats/destroy', id)
        })
        await Promise.all(promises)
        this.deleting = false
        this.$message({ type: 'success', message: 'Los chats fueron eliminados correctamente'})
      }
    }
  },
  computed: {
    organizationId () {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  }
}
</script>