<template>
  <div>
    <div v-if="contact" style="width: 100%; display: flex; align-items: center; flex-direction: column;">
      <p>{{ $t("call.calling" , { callerid : '' }) }}</p>  
      <user-item :user="contact"  :show-fullname="false" :show-status="false" style="width: auto"/>
      <p class="text-center name">{{ contact.visiblename }}</p>
    </div>
    <p v-else>{{ $t("call.calling", { callerid: displayName }) }}</p>
    <hangup-button :janus="janus" class="button hangup-button"/>
    <audio ref="audio" loop >
      <source :src="src" type="audio/ogg"/>
    </audio>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserItem from '../common/UserItem';
import HangupButton from '@/components/webrtc/HangupButton'
export default {
  props : {
    displayName : {
      type : [String, null],
      required : false,
    }
  },
  computed : {
    ...mapGetters({janus : 'getJanus' }),
    ...mapGetters('contacts', ['getContactByVisibleExtension']),
    contact() {
      return this.getContactByVisibleExtension(this.displayName)
    }
  },
  components : {
    UserItem,
    HangupButton,
  },
  data() {
    return {
      src : process.env.VUE_APP_DEFAULT_RINGBACK
    }
  },
  methods: {
    play() {
      this.$refs.audio.play()
    },
    stop() {
      this.$refs.audio.pause()
      this.$refs.audio.currentTime = 0
    }
  },
  mounted() {
    this.play()
  },
  beforeDestroy() {
    this.stop()
  }
}
</script>

<style scoped>
p {
  color: var(--hiperpbx-green);
  font-weight: bold;
  text-align: center;
}

button {
  margin: 0 auto;
  display: block;
  height: 60px;
  width: 60px;
}
</style>