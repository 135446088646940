<template>
    <div class="box">
        <div v-if="openTabs.length > 0">
            <ul class="urls">
                <li v-for="(integration, index) in openTabs" :key="index">
                    <a :href="integration.url" target="_blank">{{ integration.url }}</a>
                </li>
            </ul>
        </div>
        <div v-if="displayInfoIntegrations.length > 0">
            <el-collapse v-model="activeNames">
                <el-collapse-item v-for="(integration, index) in displayInfoIntegrations" :key="index" :title="integration.title" :name="index">
                    <TheClientInfoItem v-for="(item, key) in integration.data" :key="key" :clientInfoItem="item" activity-key="1" :enabled-edit="false"></TheClientInfoItem>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
import TheClientInfoItem from './TheClientInfoItem';
export default {
    name : "TheIntegrationsBox",
    components : {
        TheClientInfoItem
    },
    data() {
        return {
            activeNames : []
        }
    },
    props : {
        integrations : {
            required : true
        }
    },
    computed : {
        displayInfoIntegrations() {
            const data = [];
            for(let index in this.integrations) {
                const integration = this.integrations[index];
                if (integration.type === 'show_data') {
                    data.push(integration);
                }
            }
            return data;
        },
        openTabs() {
            const data = [];
            for(let index in this.integrations) {
                const integration = this.integrations[index];
                if (integration.type === 'open_tab') {
                    data.push(integration);
                }
            }
            return data;
        }
    }
}
</script>

<style scoped>
    .integration-box .box{
        padding-left : var(--column);
    }

    .urls li {
        list-style: none;
    }

    .urls li a {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
</style>