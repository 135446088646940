import http from './http';

export default {
	fetchSideImages() {
		return http.get(`/side_images`);
	},
	fetchSideImage(id) {
		return http.get(`/side_images/` + id);
	},
	newSideImage(data) {
		return http.post(`/side_images`, data);
	},
	updateSideImage(data) {
		return http.put(`/side_images/` + data.id, data);
	},
	deleteSideImage(id) {
		return http.delete(`/side_images/` + id);
	}
};
