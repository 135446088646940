<template>
  <div class="main-content" v-loading="loading">
    <button class="back" @click="$router.push({ name: 'contact-me-pbx-audios' })"><i class="fas fa-angle-left"></i> {{ $t('common.go-back') }}</button>
    <h2>{{ !id ? $t("contact-me.pbx.audios.create") : $t("contact-me.pbx.audios.edit") }}</h2>
    <el-form label-position="top" size="large" :model="form" :rules="rules" v-if="status === 'form'" ref="form">
      <el-tabs v-model="tab">
        <el-tab-pane :label="$t('contact-me.pbx.audios.basic-information')" name="basic" >
          <h3>{{ $t('contact-me.pbx.audios.basic-information') }}</h3>
          <el-form-item :label="$t('contact-me.pbx.audios.name')" required :error="errors.name" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.audios.type')" required :error="errors.type">
            <el-select v-model="type" @change="setType">
              <el-option v-for="item in types" :key="item" :value="item" :label="$t(`contact-me.pbx.audios.${item}`)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.audios.file')" :error="errors.file">
          <el-upload
            ref="filesUpload"
            action=""
            :multiple="form.type == 'moh'"
            :accept="form.type == 'moh' ? 'audio/mpeg': 'audio/wav'"
            :limit="form.type == 'moh' ? 0: 1"
            :file-list="old_files"
            :on-remove="remove_file"
            :auto-upload="false">
            <el-button size="mini" type="primary" :disabled="isButtonDisabled">{{ addFileText }}</el-button>
          </el-upload>
          </el-form-item>
          <div>
            <el-button type="primary" @click="submit" :disabled="!basicInformationIsValid">Save</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class='message' v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 'saved'"/>
        <img src="@/img/error.svg" v-else/>
        <p> {{ message }} </p>
        <div class="buttons">
          <router-link :to="{ name: 'contact-me-pbx-audios' }">{{ $t("common.go-back-to-the-list") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity: {
      required: false,
      default: () => ({
        name: "",
        type: "system-recording",
        recording: []
      })
    },
    id: {
      required: false,
      default: 0
    }
  },
  data() {
    const checkName = (rule, value, callback) => {
      setTimeout(() => {this.checkNameAndType
        if ( this.checkNameAndType( value, this.form.type  )) {
          return callback();
        }
        return callback(new Error());
      }, 300)
    }

    return {
      form: this.entity,
      tab: "basic",
      errors: {},
      loading: false,
      status: "form",
      validMohNameER: /^[a-z0-9]+$/i,
      type: "",
      old_files: [],
      deleted_files: [],
      types: [ "system-recording", "moh" ],
      rules: {
        name: [
          { validator: checkName, message: this.$t("contact-me.pbx.audios.invalid-moh-name"), trigger: ['blur', 'change'] },
        ]
      },
      isMounted: false
    }
  },
  methods: {
    setTab(value) {
      this.tab = value;
    },
    throwError( field ){
      return { response: { data: { errors: field } } } 
    },
    async submit() {
      this.errors = {};
      this.loading = true;
      try {
        const selectedFiles = this.$refs.filesUpload.uploadFiles;
        if( selectedFiles.length == 0 ) throw this.throwError({ file: 'File is required' });

        const FD = new FormData( );
        const isMoH = this.form.type == 'moh';
        const nameField = isMoH ? 'name' : 'description';
        const inputField = isMoH ? 'music' : 'recording';
        const endpoint = isMoH ? 'music_categories': 'system_recordings';

        if( this.form.name ) FD.append( nameField, this.form.name);
        if( this.deleted_files ) FD.append( 'removed_files', this.deleted_files );
        FD.append( 'origin', 'hiperme' );

        selectedFiles.map( (f,i) => { if( f.raw ){ FD.append(`${inputField}[${i}]`, f.raw ); } } );  

        const action = !this.id ? "create" : "update";
        const payload = !this.id ? FD : { resourceId: this.id, payload: FD };
        const response = await this.$store.dispatch( `pbx_${ endpoint }/${action}`, payload);
        if( response.status ){
          this.status = "saved";
        }else{
          this.status = "fail";
        }
      }catch( error) {
        if (error.response) {
          const { data } = error.response;
          this.errors = data.errors;
        }
      }finally{
        this.loading = false;
      }
    },
    checkNameAndType( name, type ){
      return name != "" && ( type == 'moh' ? this.validMohNameER.test( name ) : true )
    },
    remove_file( file, list ){
      { list }
      if( file.status != "ready" ){
        this.deleted_files.push( file.name );
      }
    },
    setType( ){
      this.form.type = this.type;
      this.$refs.filesUpload.clearFiles();
      this.$refs.form.validateField('name');
    }
  },
  computed: {
    message() {
      if (this.status == "saved") {
        return this.$t("common.saved")
      }
      return this.$t("common.not-saved")
    },
    basicInformationIsValid() {
      return this.checkNameAndType( this.form.name, this.form.type );
    },
    addFileText( ){
      return this.form.type == 'moh' ? 'Add file(s)' : 'Change file';
    },
    isButtonDisabled( ){
      if( ! this.isMounted ) return true;
      return this.form.type != 'moh' && this.$refs.filesUpload.uploadFiles.length > 0
    }
  },
  mounted( ){
    this.isMounted = true;
  },
  created( ){
    if( this.form.type ) this.type = this.form.type;
    let files = this.form.type == 'moh' ? this.form.files : ( this.form.filename  ? [ this.form.filename ] : [] ); //files | filename
    this.old_files = files.map( f => { return { name: f, url: f }; } );
  }
}
</script>

<style scoped>
.back {
  outline: none;
  border: none;
  background-color: transparent;
}

.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.destinations span{
  margin-top: 5px;
}
</style>