<template>
  <div class="recover">
    <div v-if="!sended" v-loading="loading">
      <h1 class="logo">HiperPBX</h1>
      <p class="mb-0">{{ $t("recover-password.write-your-new-password") }}</p>
      <el-form :model="form" size="medium" class="form" :rules="rules">
        <el-form-item :label="$t('configuration.users.password')" prop="password" required>
          <el-input show-password v-model="form.password" type="password" @keyup.native="verifyPassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('configuration.users.repeat-password')" required prop="repeatPassword">
          <el-input show-password v-model="form.repeatPassword" type="password"></el-input>
        </el-form-item>

        <p>{{ $t("configuration.users.the-password-must-be") }}</p>
        <div class="tags">
          <el-tag
            size="small"
            :type="passwordRules.minCharacters ? 'success' : 'info'"
            >{{ $t("configuration.users.ten-character-minimum") }}
          </el-tag>
          <el-tag
            class="ml-1"
            size="small"
            :type="passwordRules.atLeastOneMinus ? 'success' : 'info'"
            >{{ $t("configuration.users.at-least-one-lowercase-letter-(a-z)") }}
          </el-tag>
          <el-tag
            class="ml-1"
            size="small"
            :type="passwordRules.atLeastOneMayus ? 'success' : 'info'"
            >{{ $t("configuration.users.at-least-one-capital-letter-(A-Z)") }}
          </el-tag>
          <el-tag
            class="ml-1"
            size="small"
            :type="passwordRules.atLeastOneNumber ? 'success' : 'info'"
            >{{ $t("configuration.users.at-least-one-number-(0-9)") }}
          </el-tag>
          <el-tag
            class="mt-1"
            size="small"
            :type="passwordRules.atLeastOneChar ? 'success' : 'info'"
            >{{
              $t(
                "configuration.users.at-least-one-special-character-(%-#-$-&-*!?-¡¿)"
              )
            }}
          </el-tag>
        </div>



        <el-form-item class="mb-0">
          <el-button type="primary" class="mb-0" :disabled="!isButtonEnabled" @click="changePassword">{{ $t("recover-password.change-password") }}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else>
      <div v-if="success">
        <h1 class="text-center">{{ $t("recover-password.your-password-was-updated-successfully") }}</h1>
        <Success class="svg mb-2"></Success>
        <p class="text-center">{{ $t('common.click') }} <router-link :to="{ name : 'Login' }">{{ $t("common.here") }}</router-link> {{ $t("recover-password.to-return-to-the-login-screen") }}</p>
      </div>
      <div v-else>
        <ErrorSvg class="svg"></ErrorSvg>
        <p class="text-center">{{ $t("recover-password.there-was-a-problem-trying-to-change-your-password") }}</p>
        <p class="text-center">{{ $t('common.click') }} <a href="#" @click="sended = false">{{ $t("common.here") }}</a> {{ $t("recover-password.to-try-again") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/api/auth'
import ErrorSvg from '@/components/icons/Error'
import Success from '@/components/icons/Success'
export default {
  data() {
    const checkPassword = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('Please input valid username'));
      }

      setTimeout(() => {
        if ( this.passwordRules.atLeastOneMinus && this.passwordRules.atLeastOneMayus && this.passwordRules.atLeastOneNumber && this.passwordRules.atLeastOneChar && this.passwordRules.minCharacters) {
          return callback();
        }
        return callback(new Error());
      }, 1000)
    }

    const checkSamePassword = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('Please input valid username'));
      }

      setTimeout(() => {
        if (this.form.password && this.form.repeatPassword) {
          return callback(new Error());
        }
        return callback();
      }, 1000)
      
    }

    return {
      form : {
        password : '',
        repeatPassword : ''
      },
      loading : false,
      sended : false,
      success : false,
      passwordRules: {
        minCharacters: false,
        atLeastOneMayus: false,
        atLeastOneMinus: false,
        atLeastOneNumber: false,
        atLeastOneChar: false,
      },
      rules: {
        password: [
          { required: true, message: this.$t("configuration.users.the-password-is-required")},
          { validator: checkPassword, message: this.$t("configuration.users.verify-the-password-requirements"), trigger: "blur",},
        ],
        repeatPassword: [
          { required: true, message: this.$t("configuration.users.the-password-is-required")},
          { validator: checkSamePassword, message: this.$t("configuration.users.the-password-are-not-the-same"), trigger: "blur",},
        ]
      }
    }
  },
  methods : {
    verifyPassword() {
      const value = this.form.password;
      this.passwordRules.atLeastOneMinus = /[a-z]/.test(value);
      this.passwordRules.atLeastOneMayus = /[A-Z]/.test(value);
      this.passwordRules.minCharacters = value.length >= 10;
      this.passwordRules.atLeastOneNumber = /[0-9]/.test(value);
      this.passwordRules.atLeastOneChar = /[%$&#\\/*!?¡¿]/.test(value);
    },
    changePassword() {
      this.loading = true
      auth.recoverPassword(this.$route.params.token, this.form)
      .then(() => {
        this.success = true
      }).finally(() => {
        this.loading = false
        this.sended = true;
      })
    }
  },
  components : {
    Success,
    ErrorSvg
  },
  computed : {
    isButtonEnabled() {
      return this.passwordRules.atLeastOneMinus
        && this.passwordRules.atLeastOneMayus
        && this.passwordRules.minCharacters
        && this.passwordRules.atLeastOneNumber
        && this.passwordRules.atLeastOneChar
        && this.form.password === this.form.repeatPassword
    }
  }
}
</script>

<style scoped>
.recover {
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 100vh;
  width: 100%;
}

.recover > div {
  background-color: white;
  width: 40%;
  padding: var(--column)
}

.svg {
  margin: 0 auto
}

h1.logo {
  background: url('/images/logo.png') no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  text-indent: -50000px;
  width: 100%;
}
</style>