<template>
  <router-link :to="to" class="tour" @click.native="updateTour({ tour : tour.id, value : true })">
    <div class="icon" :class="tour.icon"></div>
    <div>
      <p class="mb-0 title">{{ tour.title }}</p>
      <p class="mb-0">{{ tour.description }}</p>
    </div>
  </router-link>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props : {
    tour : {
      required : true
    }
  },
  methods : {
    ...mapActions('tours', ['updateTour'])
  },
  computed : {
    to() {
      return { path: this.tour.to, query: { t: 'yes' }}
    }
  }
}
</script>

<style scoped>
.tour {
  display: inline-flex;
  border:1px solid var(--blue-grey);
  border-radius: var(--radius );
  padding: var(--column);
  text-decoration: none;
  margin-bottom: var(--column);
  width: 500px
}

.icon {
  font-size: 33px;
  margin-right: calc(var(--column) / 2);
}

.title {
  font-weight: bold;
}
</style>