import { render, staticRenderFns } from "./TheUsageReportByOrganization.vue?vue&type=template&id=7c96a95a&scoped=true&"
import script from "./TheUsageReportByOrganization.vue?vue&type=script&lang=js&"
export * from "./TheUsageReportByOrganization.vue?vue&type=script&lang=js&"
import style0 from "./TheUsageReportByOrganization.vue?vue&type=style&index=0&id=7c96a95a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c96a95a",
  null
  
)

export default component.exports