<template>
  <div>
    <p class="mb-0" v-html="text"></p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props : {
    message : {
      required : true
    }
  },
  computed : {
    ...mapGetters({
      'user' : 'getUser',
      'users' : '_users/getUsers'
    }),
    systemMessage : function() {

      let action = this.message.messageType

      const searchBy = {
        'group_created' : 'createdBy',
        'kick' : 'name',
        'leave' : 'name',
        'new_owner' : 'name',
        'start_callgroup' : 'name',
        'lost_videocall' : 'call_from',
        'add_to_group' : 'name'
      }

      let by = {
        fullname : 'hola'
      }

      let isUser = this.message[searchBy[action]] === this.user.uid
      let user = (!isUser) ? this.users.find(user => user.uid === this.message[searchBy[action]]) : this.user

      if (action === 'lost_videocall' && isUser) {
        user = this.users.find(user => user.uid === this.message.call_to)
      }

      if (user === undefined) {
        user = {
          fullname : 'Unknow'
        }
      }

      const keys = {
        'group_created' : isUser ? 'chat.you-created-this-group' : 'chat.created-this-group',
        'kick' : isUser ? 'chat.kicked-by' : 'chat.kicked-user',
        'leave' : isUser ? "chat.you-leave-the-group" : "chat.leave-the-group",
        'new_owner' : isUser ? "chat.you-are-the-new-owner" : "chat.is-the-new-owner",
        'start_callgroup' : isUser ? "chat.you-have-init-videocall" : "chat.init-videocall",
        'lost_videocall' : isUser ? "videocall.has-lost-videocall" : "videocall.you-have-a-lost-videocall",
        'add_to_group' : isUser ? 'chat.you-were-added' :  'chat.user-was-added'
      }

      if ('by' in this.message) {
        if (this.message.by === this.user.uid) {
          keys.kick = 'chat.you-kick-user'
          keys.add_to_group = 'chat.you-added'
        } else {
          by = this.users.find(user => user.uid === this.message.by)
        }
      } else {
        if (this.message.messageType === 'kick') {
          keys.kick = 'chat.kicked_by_null'
        }

        if (this.message.messageType === 'add_to_group') {
          keys.add_to_group = 'chat.you-were-added_null'
        }
      }

      const props = {
        'group_created' : {
          name : user.fullname,
        },
        'kick' : {
          name : user.fullname,
          by : by.fullname
        },
        'leave' : {
          name : user.fullname,
        },
        'new_owner' : {
          name : user.fullname,
        },
        'start_callgroup' : {
          name : user.fullname,
        },
        'lost_videocall' : {
          name : user.fullname,
        },
        add_to_group : {
          name : user.fullname,
          by : by.fullname
        }
      }
      return { key : keys[action], ...props[action]}
    },
    text() {
      if (!this.message.version) {
        if (!this.message.code) {
          return this.$t(this.systemMessage.key, { ...this.systemMessage})
        }
        return this.message.text
      }

      if (this.message.type === "text") {
        return this.message.payload.text
      }

      if (this.message.type === "quick_reply") {
        return this.message.payload.content.text
      }

      return ''
    }
  }
}
</script>

<style>

</style>