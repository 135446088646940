<template>
    <li class="menu-icon-item" :class="{ 'active' : active }">
        <i :class="link.icon"></i>
        <span v-if="showName">{{ link.title }}</span>
    </li>
</template>

<script>
export default {
    name : "MenuIconItem",
    props : {
        link : {
            required : true,
            type : Object,
            default : () => ({
                icon : '',
                title : ''
            })
        },
        showName : {
            required : false,
            type : Boolean,
            default : true
        },
        active : {
            required : false,
            type : Boolean
        }
    }
}
</script>

<style scope>
    .menu-icon-item {
        border-radius: var(--radius);
        color: var(--menu-color);
        cursor: pointer;
        display: block;
        text-decoration: none;
        padding: calc(1em /2);
        transition: color 0.2s;
    }

    .menu-icon-item.active {
        color: var(--menu-color-active);
    }

    .menu-icon-item:hover {
        color: var(--menu-color-active);
        background-color: rgba(255,255,255,0.1);
    }

    .menu-icon-item span {
        margin-left: 10px;
    }

    .light .menu-icon-item {
        color: var(--light-menu-links-color);
    }

    .light .menu-icon-item.active {
        color: var(--blue);
    }

    .light .menu-icon-item:hover {
        background-color: #ffffff;
    }

    .clasic .menu-icon-item {
        color: white;
    }

    .clasic .menu-icon-item.active {
        background-color: rgba(255,255,255,0.1);
    }


</style>