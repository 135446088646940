var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-form",style:({
  'background-image' : 'url(' + _vm.src + ')',
  'background-repeat': 'no-repeat',
  'background-position': 'center right',
  'background-size': '100% 100%',
})},[_c('div',{staticStyle:{"display":"flex","align-items":"center","flex":"1"}},[_c('div',{staticClass:"form"},[_c('div',{staticStyle:{"width":"70%","margin":"0 auto"}},[_c('h1',{staticClass:"logo",staticStyle:{"display":"none"}},[_vm._v("HiperMe!")]),_c('img',{staticClass:"img-logo",attrs:{"src":require("@/img/hiperme_isologotipo_miscelanias.svg")}}),_vm._m(0),_c('el-button',{attrs:{"type":"success"},on:{"click":function($event){return _vm.$router.push({ name: 'Login'})}}},[_vm._v("Ir al login")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("¡Nos renovamos!")]),_vm._v(" Prepárate para conocer la nueva versión de "),_c('b',[_vm._v("HiperMe!")]),_vm._v(" "),_c('i',[_vm._v("Extensión Remota")]),_vm._v(", con una nueva interfaz y nuevas funcionalidades. Agrega la nueva dirección "),_c('a',{attrs:{"href":"https://me2.hiperpbx.com"}},[_vm._v("https://me2.hiperpbx.com")]),_vm._v(" a tus favoritos e inicia sesión con tus credenciales de siempre. "),_c('br'),_c('br'),_vm._v(" En breve serás redirigido... ")])
}]

export { render, staticRenderFns }