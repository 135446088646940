<template>
    <div class="crud">
        <TheCrud :title="title" :store="store" :columns="columns" :can-delete="true" :goBack="goBack" :init-query-params="{
        ['organization.id'] : organizationId
      }">
      <template v-slot:action-buttons="scope">
        <el-dropdown-item icon="fas fa-copy" @click.native="handleCopy(scope.row)">{{ $t('contact-me.chat-center.copy-callback') }}</el-dropdown-item>
      </template>    
    </TheCrud>
    </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'
export default {
  data() {
    return {
      title: this.$t("contact-me.chat-center.time-conditions"),
      store: "timeConditions",
      columns: [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('configuration.organization.name') },
        { index : 'description', label : this.$t('administration.clients.description') },
        { index : 'callback', label : this.$t('contact-me.chat-center.callback'), width: 400 },
      ]
    }
  },
  components: {
    TheCrud
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-chat-center"})
    },
    handleCopy(item) {
      const url = `${process.env.VUE_APP_CHAT_ENGINE_HOST}/api/callback/${item.callback}`
      navigator.clipboard.writeText(url);
      this.$message({
        message: this.$t("contact-me.chat-center.callback-copied-success"),
        type: 'success'
      });
    }
  },
  computed: {
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  }
};
</script>