import List from "@/views/administration/clients/List.vue"
import Form from '@/views/administration/clients/Form.vue'
import LicensesForm from '@/views/administration/clients/LicensesForm.vue'

const routes = [
    {
      name: "clients",
      path: "/administration/clients",
      component: List,
      meta : {
        isInMenu : true,
        title : 'administration.clients.title',
        access_control : ['ROLE_ADMIN'],
        requiresAuth : true
      }
    },
    {
      name: "clientsNew",
      path: "/administration/clients/new",
      component: Form,
      meta : {
        access_control : ['ROLE_ADMIN'],
        requiresAuth : true
      }
    },
    {
      name: "clientsEdit",
      path: "/administration/clients/:id/edit",
      component: Form,
      meta : {
        access_control : ['ROLE_ADMIN'],
        requiresAuth : true
      },
      props: true,
    },
    {
      name: "clientsLicenses",
      path: "/administration/clients/:id/licenses",
      component: LicensesForm,
      meta : {
        access_control : ['ROLE_ADMIN'],
        requiresAuth : true
      },
      props: true,
    },
  ];
  
  export default routes;