<template>
  <div>
    <el-tooltip effect="dark" :content="$t('call.answer-call')" placement="bottom">
      <button @click="answer">
        <i class="fas fa-phone"></i>
      </button>
    </el-tooltip>
    <el-dialog
      :visible.sync="dialogVisible"
      :append-to-body="true">
      <AllowMicrophone :is-in-modal="true" v-if="errorType == 'NotAllowedError'" :system="message === 'Permission denied by system'"></AllowMicrophone>
      <p v-if="errorType == 'NoCaptureDevice'">{{ $t("call.an-available-microphone-was-not-found-to-use-connect-or-enable-a-microphone-to-be-able-to-answer-the-call") }}</p>
    </el-dialog>
  </div>
</template>

<script>
import AllowMicrophone from '@/views/help/AllowMicrophone'
import * as Sentry from '@sentry/browser';
export default {
  name: "AnswerButton",
  props: {
    janus: {
      required: true,
    },
    jsep : {
      required : false,
      defafult : false,
    }
  },
  data() {
    return {
      dialogVisible: false,
      errorType: '',
      message : "",
    };
  },
  methods: {
    async answer() {
      try {
        await this.janus.answer(this.jsep)
      } catch (error) {
        if (error.name === "NotAllowedError" || error.name === "NoCaptureDevice") {
          this.dialogVisible = true;
          this.errorType = error.name;
          this.message = error.message
        } else if (error.name === "UnexpectedAnswer") {
          const sound = new Audio('/sounds/busy_phone_tone.mp3');
          sound.volume = 0.3;
          sound.play();
          setTimeout(() => {
            sound.pause();
          }, 2000);
        } else {
          this.$alert(error).catch(() => {})
          if (process.env.VUE_APP_SENTRY_DSN) {
            Sentry.captureMessage(error);
          }
        }
      }      
    },
  },
  components : {
    AllowMicrophone
  }
};
</script>

<style scoped>
.fa-phone {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;

  background-color: var(--green);
  border-radius: 50%;
  color: white;
}

button {
  margin-top: 0 !important;
  border: 0 !important;
  outline: none;
  background-color: transparent;
}
</style>