<template>
  <el-card class="integration" shadow="never">
    <div class="header">
      <h3>{{ integration.title }}</h3>
      <img :src="integration.img" />
    </div>
    <p>{{ integration.description  }}</p>
    <div>
      <el-switch v-model="enabled"></el-switch>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    integration: {
      default: () => ({
        name: "",
        title: "",
        description: "",
        img: "",
        enabled: false,
      })
    }
  },
  computed: {
    tmpIntegration() {
      return this.$store.getters["integrations/getIntegrationByName"](this.integration.name);
    },
    enabled: {
      get() {
        return this.tmpIntegration.enabled;
      },
      set(value) {
        this.$store.dispatch("integrations/toggleIntegration", { name: this.integration.name, value });
      }
    }
  }
}
</script>

<style scoped>
.header {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: calc(var(--column) * 2);
}

img {
  width: 100px;
}

.integration {
  min-width: 400px;
}

p {
  border-bottom: 1px solid #EBEEF5;
  padding-bottom: calc(var(--column) * 2);
}
</style>