<template>
  <div class="main-content">
    <h2>{{ $t("main-menu.reports.usage-report") }}</h2>
    <el-date-picker v-model="dateRange" type="daterange" align="left" unlink-panels :picker-options="pickerOptions" size="small" style="margin-bottom:var(--column)"/>
    <the-table :columns="columns" :pagination="{}" :rows="rows" :loading="loading" :search="['organization.name']" @fetch="fetch">
      <template v-slot:organization.name="scope">
        <router-link 
          :to="{
            name: 'usage_report_by_organization', 
            params: { 
              id: scope.row.organization.id, 
              name: scope.row.organization.name, 
              from: formatDate(dateRange[0]), 
              to: formatDate(dateRange[1]) 
            },
            query : {
              id: scope.row.organization.id,
              from: formatDate(dateRange[0]), 
              to: formatDate(dateRange[1]) 
            }
          }">
          {{ scope.row.organization.name }}
        </router-link>
      </template>
    </the-table>
  </div>
</template>

<script>
import TheTable from "@/components/crud/TheTable";
import { mapActions, mapGetters } from 'vuex';
export default {
  name : 'TheUsageReports',
  data() {
    const today = new Date();

    return {
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateRange: [
        today,
        today,
      ],
      loading : true,
      search : "",
      columns : [
        { index: "organization.name", label : this.$t("configuration.organization.organization") },
        { index: "percentage_logged", label : this.$t("reports.percentage_logged"), sortable: true },
        { index: "percentage_not_logged", label : this.$t("reports.percentage_not_logged"), sortable: true },
      ]
    };
  },
  computed: {
    ...mapGetters('reports', {
      percentageOfUserLoggedByOrganization : 'getPercentegeOfUserLoggedByOrganization'
    }),
    rows() {
      return this.percentageOfUserLoggedByOrganization.filter(values => !this.search || values.organization.name.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods : {
    ...mapActions('reports', ['fetchPercentegeOfUserLoggedByOrganization']),
    async fetch(params) {
      this.loading = true
      const [from, to] = this.dateRange
      try {
        if (params && params["organization.name"]) {
          params.organizationName = params["organization.name"];
        }
        await this.fetchPercentegeOfUserLoggedByOrganization({from : this.formatDate(from), to : this.formatDate(to), ...params })    
      } finally {
        this.loading = false
      }
    },
    formatDate(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return [year, month, day].join('-');
    }
  },
  created() {
    this.fetch()
  },
  components: {
    TheTable,
  }
};
</script>