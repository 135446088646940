import http from "@/api/http";
import cookie from "@/plugins/cookie";

const PBX_TOKEN_COOKIE = "PBX_TOKEN";
const ORGANIZATION_ID = "ORGANIZATION_ID";
const ORGANIZATION_NAME = "PBX_ORGANIZATION_NAME";

const state = {
  pbxToken: cookie.getCookie(PBX_TOKEN_COOKIE) ?? null,
  organizationId: cookie.getCookie(ORGANIZATION_ID) ?? null,
  organizationName: cookie.getCookie(ORGANIZATION_NAME) ?? "",
  organization: null
}

const getters = {
  getOrganization: (state) => state.organization,
  getPbxToken: (state) => state.pbxToken,
  getOrganizationId: (state) => state.organizationId,
  getOrganizationName: (state) => state.organizationName,
}

const mutations = {
  SET_PBX_TOKEN: (state, value) => state.pbxToken = value,
  SET_ORGANIZATION_ID: (state, value) => {
    state.organizationId = value
    cookie.setCookie(ORGANIZATION_ID, value, 12);
  },
  SET_ORGANIZATION_NAME: (state, value) => {
    state.organizationName = value
    cookie.setCookie(ORGANIZATION_NAME, value, 12);
  },
  SET_ORGANIZATION: (state, value) => {
    state.organization = value
  }
}

const actions = {
  async doLoginPbx({commit, getters}) {
    const id = getters.getOrganizationId;
    const { data } =  await http.post(`/pbx/${id}/auth`);
    if (data.status) {
      commit("SET_PBX_TOKEN", data.token);
      cookie.setCookie(PBX_TOKEN_COOKIE, data.token, 12);
    }
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}
