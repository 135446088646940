<template>
  <div :class="node.className" v-show="isHidden" class="item">
    <h2 v-if="node.component === 'h2'" >{{ node.label }}</h2>
    <h3 v-if="node.component === 'h3'" >{{ node.label }}</h3>

    <el-form-item :prop="node.index" :label="node.label" :required="node.required" v-if="isInput" :error="error">
      <el-input v-model="value" v-if="node.component === 'input' && type !== 'number'" @input="update" :type="type" :rows="rows" :show-password="type === 'password'"/>

      <el-input v-model.number="value" v-if="node.component === 'input' && type === 'number'" @input="update" type="number" />

      <el-checkbox v-model="value" v-if="node.component === 'checkbox'" @change="update"/>

      <el-select v-model="value" v-if="node.component === 'select' && !('group-by' in node)" :multiple="node.multiple" :placeholder="node.placeholder" @change="update" :loading="loading" :disabled="disabled" :filterable="true" :remote-method="node['remothe-method']" :remote="node['remothe-method'] ? true : false" :clearable="node.clearable">
        <el-option v-if="node.clearable" value="" :label="node.clearableLabel ? node.clearableLabel : 'None'"></el-option>
        <el-option v-for="(item, index) in options" :key="index" :label="typeof item === 'string' ? item : item.label" :value="typeof item === 'string' ? item : item.value"></el-option>
      </el-select>
      
      <el-select v-model="value" v-if="node.component === 'select' && node['group-by'] " :multiple="node.multiple" :placeholder="node.placeholder" @change="update" :loading="loading" :disabled="disabled" :filterable="true">
        <el-option-group v-for="group in options" :key="group.label" :label="group.label">
          <el-option v-for="(item, index) in group.options" :key="index" :label="typeof item === 'string' ? item : item.label" :value="typeof item === 'string' ? item : item.value" ></el-option>
        </el-option-group>
      </el-select>

      <el-radio-group v-model="value" v-if="node.component === 'radio'" @change="update">
          <el-radio v-for="(item, index) in node.values" :key="index" :label="item.value">{{ item.label }}</el-radio>
      </el-radio-group>

      <el-switch v-model="value" v-if="node.component === 'switch'"></el-switch>
    </el-form-item>

    <div v-if="hasNotes">
      <p class="mt-0 notes">{{ node.notes }}</p>
    </div>

    <div v-if="node.validations">
      <the-rule v-for="rule in node.validations.filter(rule => rule.show)" :key="rule.index" :rule="rule" :value="value"/>
    </div>

    <div v-if="type === 'password' && !node.repeat" >
      <password-strength-meter strength-meter-only v-model="value"/>
    </div>

    <div v-if="hasChildren" class="childrens">
      <the-item v-for="(node, index) in children" :key="index" :node="node" :edit="edit"></the-item>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PasswordStrengthMeter from 'vue-password-strength-meter'
import TheRule from './TheRule'
export default {
  name : 'TheItem',
  props : {
    node : {
      required : true
    },
    visible : {
      required : false,
      default : true
    },
    edit: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      inputs : ['input', 'select', 'radio', 'checkbox', "switch"],
      loading : false,
    }
  },
  computed : {
    ...mapGetters('form', {
      getForm: 'getForm',
      getFormValue : 'getFormValue',
      getItemIsHidden : 'getItemIsHidden',
      getError : 'getError',
    }),
    hasChildren() {
      return this.children.length > 0
    },
    rows(){
        if( ! this.node.type == 'textarea' ){
            return null;
        }

        if( !('rows' in this.node ) ){
            return 3;
        }

        return this.node.rows;
    },
    type() {
      if (!('type' in this.node)) {
        return 'text'
      }

      return this.node.type
    },
    children() {
      return this.node.children ? this.node.children : []
    },
    isInput() {
      return this.inputs.includes(this.node.component)
    }, 
    isFetchingValues() {
      return this.$store.getters[`${this.node.store}/getFetching`]
    },
    hasNotes() {
      return ('notes' in this.node)
    },
    disabled() {
      if ('disabled' in this.node) {
        return this.node.disabled;
      }

      let disabled = false
      
      if ('disabled-if-empty' in this.node) {
        if (typeof this.node['disabled-if-empty'] === 'string') {
          disabled = this.$store.getters['form/getFormValue'](this.node['disabled-if-empty'])
        } else {
          const isDisabled = this.node['disabled-if-empty']
            .map(index => this.$store.getters['form/getFormValue'](index).value)
            .filter(value => value != "")
          disabled = isDisabled.length === 0
        }
      }

      return this.loading || disabled
    },
    options() {
      const groups = []
      let tmpvalues = this.node.values ?? []

      // Get the data from other value
      if (this.node.component === "select" && 'remothe-method' in this.node) {
        const data = this.$store.getters['form/getFormFullValue'](this.node.index)
        if (data) {
          if (Array.isArray(data.value)) {
            tmpvalues = data.value
          } else {
            tmpvalues.push(data.value)
          }
        }
      }

      // Get the data from other value
      if (this.node.component === "select" && 'values-from' in this.node) {
        const value = this.$store.getters['form/getFormValue'](this.node['values-from']['index'])
        const data = this.$store.getters['form/getFormFullValue'](this.node['values-from']['index'])
        if (this.node.index === 'organization' && data) {
          const keys = this.node['values-from']['key'].split('.')
          if (Array.isArray(data.value)) {
            data.value.forEach((data) => {
              if (data['@id'] === value.value) {
                tmpvalues = data[keys[0]]
              }
            })
          } else {
            tmpvalues = data.value[keys[0]]
          }
        }
      }

      // Get the data from the a store
      if (this.node.component === "select" && 'store' in this.node) {
        tmpvalues = this.$store.getters[`${this.node.store}/getData`]
      }

      // Add the default value of the model to the list
      if (this.node['add-value-to-list']) {
        const fullValue = this.$store.getters['form/getFormFullValue'](this.node.index)
        if (fullValue) {
          if (typeof fullValue.value === 'string' || !Array.isArray(fullValue.value)) {
            tmpvalues.push(fullValue.value)
          } else {
            fullValue.value.forEach(value => tmpvalues.push(value))
          }
        }
      }

      if (tmpvalues) {
        tmpvalues.forEach(item => {
          let add = true
          let prop = null;
          let index = '';
          let label = item.label
          let value = item.value;

          
          if ('id' in item) {
            value = item.id;
          }

          if ('@id' in item) {
            value = item['@id'];
          }

          if (this.node['label-value']) {
            if (typeof this.node['label-value'] !== 'string')  {
              // concat the values to form the label 
              label = this.node['label-value'].reduce((prev, curr) => {
              return prev += " " + item[curr]
              }, '')
            } else {
              label = item[this.node['label-value']]
            }            
          }

          const option = { value, label }

          // creates the array of the options 
          if (this.node['group-by']) {
            add = true
            prop = item[this.node['group-by'].prop]
            index = groups.findIndex(group => group.id === prop['@id'])
          }

          const filter = this.node['filter-by']
          if (filter) {
            const find = this.filteredBy.find(value => {
               let response = false

              if (value === item[filter.index]) {
                response = true
              }

              if (filter.prop) {
                if (value === item[filter.prop][filter.index]) {
                  response = true
                }
              }
              return response
            })

            if (this.filteredBy !== 'string' && !find) {
              add = false
            }
          }

          if (add) {
            if (!this.node['group-by']) { 
              groups.push(option);
            } else{
              if (index !== -1) {
                groups[index].options.push(option)
              } else {
                groups.push({ id: prop['@id'], label: prop[this.node['group-by'].label], options: [option] })
              }
            }
          }
        })
      }

      return groups
    },
    isHidden() {
      if (!('hidden' in this.node)) {
        return true;
      }

      if (/:/.test(this.node.hidden) && !/data/.test(this.node.hidden)) {
        const [index, value] = this.node.hidden.split(":")
        return this.getFormValue(index).value === value
      }

      /**
       * Esto solamente funciona si la item del formulario tiene propiedad hidden
       * Ademas, su valor debe de ser como el siguiente formItemIndex:[data-property=valor], (valor puede ser un array si, se separa con |)
       * 
       * formItemIndex: indice de otro item del formulario
       * property: indice de la propiedad donde se va a revisar el valor
       * valor: valores cuando se debe de mostrar el item del formulario
       */
      if (/\[data-.+\]/.test(this.node.hidden)) {
        const [otherFormItemIndex, payload] = this.node.hidden.split(":")
        const cleanPayload = payload.replace('[', '').replace(']', '')
        const showInValues = cleanPayload.split('=').pop().split('|')
        const property = cleanPayload.split('=').shift().split('-').pop()
        const formItem = this.getForm.nodes.find(input => input.index === otherFormItemIndex)
        const { value: otherFormItemValue } = this.getFormValue(otherFormItemIndex)
        const formItemStore = this.$store.getters[formItem.store + '/getDataById'](otherFormItemValue)
        if (formItemStore && formItemStore[property]) {
          return showInValues.includes(formItemStore[property])
        }
      }
      
      const { value } = this.getItemIsHidden(this.node.index);
      return value
    },
    error : {
      set(val) {
        console.log(val)
      },
      get() {
        const err = this.getError(this.node.index);
        if (!err) {
          return "";
        }
        return err.error.join("\n")
      }
    },
    value: {
      get() {
        const { value } = this.getFormValue(this.node.index)
        return value
      },
      set(value) {
        this.$store.dispatch("form/setValue", {  index : this.node.index, value : value })
      }
    },
    filteredBy() {
      if (!this.node['filter-by']) {
        return ""
      }

      const data = this.getFormValue(this.node['filter-by']['field-value'])
      if (data) {
        return data.value;
      } 
      
      return ''
    },
    fetchWhen() {
      if ("fetch-when" in this.node) {
        const index = this.node["fetch-when"].index;
        if (this.$store.getters['form/getFormValue'](index)) {
          return this.$store.getters['form/getFormValue'](index).value;
        }
      }
      return null;
    }
  },
  methods : {
    ...mapActions('form', ['setValue']),
    update() {
      if (this.node.hideIndex) {
        this.$store.dispatch('form/setVisible', {index : this.node.hideIndex, value : this.value })
      }
    },
    async fetchValues(extraParams = {}) {
      const params = {
        ...extraParams,
        pagination : false
      }

      this.loading = true

      if (this.node.params) {
        Object.keys(this.node.params).forEach(key => params[key] = this.node.params[key])
      }

      if (!this.isFetchingValues) {
        await this.$store.dispatch(`${this.node.store}/all`, params )
        this.loading = false
      }
    },
    findValue(keys, haystack) {
      if (keys.length === 0) {
        return haystack;
      }

      let value;

      for (let j in keys) {
        for (let i in haystack) {
          if (keys[j] === i) {
            keys.shift()
            if (Array.isArray(haystack[i])) {
              value = haystack[i].map(item => {
                if (keys.length === 1) {
                  return item[keys[0]]
                }

                return "";
              })
            } else {
              value = this.findValue(keys, haystack[i])
            }

          }
        }
      }
      return value;
    }
  },
  beforeMount() {
    if (this.isInput) {
      if (this.node.component === "select" && 'store' in this.node) {
        if (!('fetch-when' in this.node)) {
          this.fetchValues();
        } else {
          if (this.edit) {
            const values = this.fetchWhen
            if (values) {
              const value = Array.isArray(values) ? values.map(value => value.split("/").pop()) : values.split("/").pop();
              const params = { [this.node['fetch-when'].param] : value }
              this.fetchValues(params)
            }
          }
        }
      }
    }
  },
  components : {
    TheRule,
    PasswordStrengthMeter
  },
  watch : {
    isFetchingValues(value) {
      if (!value) {
        this.loading = false
      }
    },
    fetchWhen(values) {
      const value = Array.isArray(values) ? values.map(value => value.split("/").pop()) : values.split("/").pop();
      const params = { [this.node['fetch-when'].param] : value }
      this.fetchValues(params);
    }
  }
}
</script>

<style scoped>
.notes {
  font-size: 14px;
}
</style>