 <template>
    <div class="user">
        <div class="avatar">
            <el-avatar :src="src" fit="contain" v-if="src" :size="size"></el-avatar>
            <div v-else class="default small" :style="style">{{ fullname | firstLetter }}</div>
            <span class="status" v-if="showStatus" :class="user.status"></span>
        </div>
        <div>
            <p class="mb-0 full" :class="type" v-if="showFullname">{{ fullname }}</p>
            <p class="mb-0 full" :class="type" v-if="showEmail">{{ user.email }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name : "UserItem",
    props : {
        user : {
            required : true,
        },
        showStatus : {
            type : Boolean,
            default : true
        },
        showFullname : {
            type : Boolean,
            default : true
        },
        showEmail : {
            type : Boolean,
            default : false,
        },
        type : {
            default : 'ligth',
            type : String
        },
        size : {
            default : 'large',
            type : [String,Number]
        }
    },
    data() {
      return {
        visible: true
      };
    },
    computed :  {
        fullname() {
            return (this.user.fullname) ? this.user.fullname : `${this.user.name}` 
        },
        src() {
            return this.user.picture
        },
        name() {
            return ""
        },
        style() {
            if (typeof this.size == 'number') {
                return {
                    height: `${this.size}px`, 
                    width: `${this.size}px`,
                    'line-height': `${this.size}px`
                }
            }
            return {}
        }
    },
    methods : {
        methodThatForcesUpdate() {
            this.$forceUpdate();  // Notice we have to use a $ here
        }
    },
    watch : {
        src(value, old) {
            if (value != old) {
                this.methodThatForcesUpdate()
            }
        }
    }
}
</script>

<style scoped>
.user {
    align-items: center;
    display: inline-flex;
    width: 100%;
}
.avatar {
  position: relative;
  width: fit-content;
}

.default {
    background-color: var(--blue);
    color: white;
    text-align: center;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    border-radius: 50%
}
.avatar .status {
  position: absolute;
  bottom: 3px;
  right: 0;
}

.status {
    background-color: var(--grey-status);
    border-radius: 50%;
    display: block;
    height: 0.8em;
    width: 0.8em;
}

.small {
    width: 40px;
    height: 40px;
    line-height: 40px;
}

p {
    color: var(--blue-grey);
    font-size: 14px;
}

p.full {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
    margin-left: var(--column)
}

 .status.connected {
    background-color: var(--green-status);
 }

 .status.away {
  background-color: var(--red-status);
 }
 .status.busy {
    background-color: var(--orange);
 }

</style>