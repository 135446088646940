<template>
  <div v-loading="saving" :element-loading-text="$t('contacts.saving')">
    <div v-if="!formSended">
      <h2>{{ $t("contacts.edit") }}</h2>
      <the-contact-form
        @submitForm="submitForm"
        :user="tmpUser"
        :canEdit="canEdit"
        :type="type"
      ></the-contact-form>
    </div>
    <NotificationABM
      v-else
      route="Contacts"
      :status="saved"
      :successTitle="$t('contacts.saved')"
      :text="saved ? `` : $t('contacts.error-saved')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TheContactForm from './TheContactForm'
import NotificationABM from '@/components/common/NotificationABM'
export default {
  props : {
    id : {
      required : true
    },
    user : {
      required: true
    },
    canEdit : {
      type : Boolean,
      required : false,
      default : true
    },
    type : {
      default : 'user'
    }
  },
  components : {
    TheContactForm,
    NotificationABM
  },
  data() {
    return {
      tmpUser : this.user,
      saving : false,
      formSended : false,
      saved : false
    }
  },
  methods : {
    ...mapActions('contacts',['updateContact','findById']),
    ...mapActions('organization_contacts',['update']),
    submitForm(form) {
      let isEmpty = true
      Object.keys(form).forEach((key) => {
        if (form[key] !== "") {
          isEmpty = false
        }
      })

      if (!isEmpty) {
        this.saving = true
        if (this.type === 'user') {
          const contact = {}
          const props = ['id','props','status','type','videocallRoomId','visibleExtension','visiblename','organization','canEdit']
          Object.keys(form).forEach((index) => {
            if (!props.includes(index)) {
              contact[index] = form[index]
            }
          })
          this.updateContact({ id : this.id, contact }).then(() => {
            this.formSended = true
            this.saving = false
            this.saved = true
          })
       } else {
         form.organization = form.organization['@id']

         this.update({ payload : form, id : this.id }).then(() => {
            this.formSended = true
            this.saving = false
            this.saved = true
          })
       }
      }
    }
  }
}
</script>

<style>
</style>