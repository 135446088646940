import http from './http';

export default {
	fetchCanLoggin() {
		return http.get(`/sessions/agents`);
	},
	fetchAgentsData(queryParams = { page : 1 }) {
		return http.get(`/agents`, { 
			params : queryParams
		});
	},
	fetchAgent(id) {
		return http.get(`/agents/` + id);
	},
	createAgent(data) {
		return http.post(`/agents`, data);
	},
	updateAgent(data) {
		return http.put(`/agents/` + data.id, data);
	},
	deleteAgent(id) {
		return http.delete(`/agents/` + id);
	},
	cleanAgentFirebase(id) {
		return http.put(`/agents/${id}/clean-from-firebase`);
	},
	saveJanusConnection(janusServer, janusSessionId) {
		return http.post(`save-janus-connection`, {
			janusServer, janusSessionId
		})
	}
};
