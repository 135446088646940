const calendar = (value) => {
	const date = new Date(value);
	return date.toLocaleString(window.navigator.userLanguage || window.navigator.language, {
			year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'
	});
}

const chatRedeableDate = (value, hours = false) => {
	if (!value) {
			return;
	}

	const today = new Date();
	const date = new Date(value);

	if (date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
			if (date.getDate() === today.getDate()) {
					return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			}

			if (date.getDate() === today.getDate() - 1) {
					return 'Ayer, ' + date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			}

			const dayOfWeek = date.getDay();
			const todayDayOfWeek = today.getDay();
			if (dayOfWeek <= todayDayOfWeek) {
					return date.toLocaleString([], { weekday: 'short', hour: '2-digit', minute: '2-digit' });
			}
	}

	return date.toLocaleString([], hours ? { dateStyle: 'short', timeStyle: 'short' } : { dateStyle: 'short' });
}

const secondsToFormat = (value) => {
	const date = new Date(0);
	date.setSeconds(value);
	return date.toISOString().substr(11, 8);
}

const secondsToHourMinutes = (date) => {
	return date.toISOString().substr(11, 5);
}

const redeableDate = (value, hours = false) => {
	if (!value) {
			return;
	}

	const today = new Date();
	const date = new Date(value);

	if (date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
			if (date.getDate() === today.getDate()) {
					return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			}

			if (date.getDate() === today.getDate() - 1) {
					let s = 'ayer';
					if (hours) {
							s += `, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
					}
					return s;
			}

			if ((today.getDate() - date.getDate()) < 8) {
					let s = date.toLocaleDateString([], { weekday: 'long' });
					if (hours) {
							s += `, ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
					}
					return s;
			}
	}

	return date.toLocaleString([], hours ? { dateStyle: 'short', timeStyle: 'short' } : { dateStyle: 'short' });
}

const secondsToDate = (value) => {
	const date = new Date(value);
	const today = new Date();

	if (date.getDate() === today.getDate() - 1 && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
			return 'ayer';
	}
	return `${(date.getDate() < 10) ? "0" + date.getDate() : date.getDate()}.${(date.getMonth() + 1 < 10) ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}.${date.getFullYear()}`;
}

const ticketDate = (value) => {
	const date = new Date(value);
	const today = new Date();

	if (date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
			if (date.getDate() === today.getDate()) {
					return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
			}

			const dayOfWeek = date.getDay();
			const todayDayOfWeek = today.getDay();
			if (dayOfWeek <= todayDayOfWeek) {
					return date.toLocaleString([], { weekday: 'short', hour: '2-digit', minute: '2-digit' });
			}
	}

	return date.toLocaleDateString();
}

export {
	calendar,
	redeableDate,
	secondsToFormat,
	chatRedeableDate,
	secondsToHourMinutes,
	secondsToDate,
	ticketDate
}
