<template>
  <span :style="`background-color: var(${color})`">{{ tag.name }}</span>
</template>

<script>
export default {
  props: {
    tag: {
      required: true,
      default: () => ({
        name: '',
        description: '',
        color: ''
      })
    }
  },
  computed: {
    color() {
      return this.tag.color ?? '--blue';
    }
  }
}
</script>

<style scoped>
span {
  padding: 0 5px;
  font-size: 12px;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  color: hsl(0, 0%, 50%);
}
</style>