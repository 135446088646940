import List from "../../views/extensions/List.vue";
import Form from "../../views/extensions/Form.vue";

const routes = [
  {
    name: "extension",
    path: "/configuration/extensions",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.extensions',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true,
    },
  },
	{
		name: "extensionsNew",
		path: "/configuration/extensions/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
	},
  {
    name: "extensionsEdit",
    path: "/configuration/extensions/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
  }
];

export default routes;
