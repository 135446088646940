<template>
  <div class="logout" v-loading="true">
    <div class="content">
      <h1>Cerrando sesión</h1>
      <img src="@/img/undraw_quitting_time_dm-8-t.svg" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex';
import cookie from '@/plugins/cookie'
export default {
  methods: {
    ...mapActions(['updateStatus','setDefaultValues','firebaseLogout']),
    ...mapMutations(['DELETE_TOKEN']),
    async doLogout() {
      try {
        cookie.deleteCookie();
        this.updateStatus("disconnected");
        this.DELETE_TOKEN();
				localStorage.removeItem("token")
				localStorage.removeItem("tabCount")
				localStorage.removeItem("osticket_token")
				localStorage.removeItem("osticket_staff")
        await this.firebaseLogout()        
        window.location.href = '/login';
      } catch (error) {
        console.log(error);
      }
    }
  },
  created() {
    setTimeout(() => {
      this.doLogout();
    }, 5000)
  },
  mounted() {
		this.$title = this.$t("user-buttons.logout")
	}
}
</script>

<style scoped>
.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
img {
	width: 30em;
}

h1 {
  text-align: center;
  margin-bottom: var(--column);
}

.content {
  background: white;
  border-radius: var(--radius);
  padding: calc(var(--column) * 3);
}
</style>