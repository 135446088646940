import store from '../store'

const requireAuth = async (to, from, next)  => {

	if (!store.getters.isAuthenticated && to.path !== "/login") {
		return next({ name: "Login"});
	} 

	if (to.path === '/' && store.getters.isAuthenticated) {
		return next({name : 'Home'})
	}

	if (to.path === "/home" && store.getters.isAuthenticated) {
		return next();
	}

	if (store.getters.isAuthenticated && !store.getters.getUser.uid && to.path !== "/home")  {
		return next();
	}
	
	if (to.meta.requiresAuth) {
		let hasAccess = false
		const user = store.getters.getUser

		if (!user.uid) {
			return next({name : 'Logout'})
		}

		if (user.roles.includes('ROLE_ADMIN')) {
			hasAccess = true;
		} else {

			to.meta.access_control.forEach((role) => {
				if (user.roles.includes(role)) {
					hasAccess = true;
				}
			})
		}

		if (hasAccess) {
			next()
		} else {
			next(from.path)
		}
	} else {
		next();
	}
}

export default requireAuth