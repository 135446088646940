const createUserItem = (user) => ({
  uid: user.uid,
  fullname: user.fullname ?? 'Unknow',
  status: user.status ?? 'disconnected',
  extension: user.extension ?? '',
  picture: user.picture ?? "",
  email : user.email
})

const state = {
  users : [],
  loadingUsers : true
}

const getters = {
  getUsers : (state) => state.users,
  getLoadingUsers : (state) => state.loadingUsers,
  getUserByUid: (state) => (uid) => state.users.find(user => user.uid === uid)
}

const mutations = {
  ADD_USER : (state, user) => state.users.push(user),
  UPDATE_USER: (state, user) => state.users = state.users.map(u => u.uid === user.uid ? user : u),
  REMOVE_USER: (state, user) => state.users = state.users.filter(u => u.uid !== user.uid),
  TOGGLE_LOADING : (state) => state.loadingUsers = !state.loadingUsers,
}

const actions = {
  addUser({ rootGetters, commit}, data) {
    return new Promise((resolve) => {
      const firebase = rootGetters.getFirebase
      firebase.database().ref('users').child(data.uid).once('value', (snapshot) => {
          const user = createUserItem({...snapshot.val(), uid : data.uid})
          commit("ADD_USER", user)
          return resolve(user)
      })
    })
  },
  fetchUsers({ rootGetters, dispatch, commit }) {
    const users = new Set()
    const firebase = rootGetters.getFirebase
    const loggedUser = rootGetters.getUser
    const { organizations } = rootGetters.getUser

    users.add(loggedUser.uid) 

    // Fetch once all the users 
    const promises = organizations.map(async (organization) => {
      const snap = await firebase.firestore().collection('organizations').doc(organization.id.toString()).collection('users').where('active', 'in', [1, '1']).get();
      const promises = snap.docs
        .filter(doc => !users.has(doc.id) && loggedUser.uid !== doc.id)
        .map((doc) => {
          const user = { ...doc.data(), uid : doc.id }
          users.add(user.uid) 
          return dispatch('addUser', user)
        })
      return await Promise.all(promises)
    })

    Promise.all(promises.flat()).then((_users) => {
      // Listen to changes in the users already fetched
      _users.flat().forEach(user => {
        firebase.database().ref('users').child(user.uid).on('value', (snapshot) => {
          const user = createUserItem({ uid: snapshot.key, ...snapshot.val()})
          commit('UPDATE_USER', user)
          dispatch('corpochat/updateUser', user, { root : true })
        })
      })

      // Listen to new users
      organizations.forEach((organization) => {
        firebase.firestore().collection('organizations').doc(organization.id.toString()).collection('users').where('active', 'in', [1, '1']).onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type == 'removed') {
              commit('REMOVE_USER', { uid: change.doc.id })
            } 

            if (change.type === 'added' && !users.has(change.doc.id)) {
              const user = { ...change.doc.data(), uid :change.doc.id }
              dispatch('addUser', user)
            }
          })
        })
      })

    })
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}