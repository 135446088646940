<template>
  <div class="crud-content">
    <div class="header">
      <div class="title">
        <el-button type="text" icon="fas fa-arrow-left" @click="goBackTo" v-if="goBack"></el-button>
        <h2>{{ title }}</h2>
      </div>
      <div class="buttons">
        <slot name="buttons" />
        <el-button size="small" type="primary" v-if="canCreate" @click="goToNew">{{ $t('common.new') }}</el-button>
        <el-button size="small" v-if="masiveCreationColumns.length > 0" @click="$refs.masive.toggle()">{{ $t('common.masive-creation') }}</el-button>
      </div>
    </div>

    <the-table :loading="loading" :pagination="pagination" :rows="rows" :search="search" @fetch="fetch" :columns="_columns" :filters="filters" @change-page="fetch" :select="select" @selection-change="selectionChange">
      <template v-slot:action-buttons="scope">
          <el-dropdown trigger="click">
            <el-button icon="fas fa-ellipsis-h" type="text"></el-button>
            <el-dropdown-menu slot="dropdown">
                <slot name="action-buttons" v-bind:row="scope.row"></slot>
                <el-dropdown-item v-if="canEdit" icon="fas fa-pencil-alt" @click.native="handleEdit(scope.row)">{{ $t("common.edit") }}</el-dropdown-item>
                <el-dropdown-item v-if="canDelete" icon="fas fa-trash" @click.native="handleDelete(scope.row)">{{ $t("common.delete") }}</el-dropdown-item>
                <el-dropdown-item icon="fas fa-eye" @click.native="handleView(scope.row)">{{ $t("common.view") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </template>
    </the-table>

    <the-modal-masive-creation title="Creación masiva" :resource="store" ref="masive" :columns="masiveCreationColumns" v-if="masiveCreationColumns.length > 0"></the-modal-masive-creation>

    <el-drawer direction="rtl" :visible.sync="drawer" :modal="false" size="20%" :close-on-press-escape="!loadingView" :show-close="!loadingView" :wrapperClosable="!loadingView"	>
      <div class="info" v-loading="loadingView" :element-loading-text="$t('common.loading-data-please-wait')">
        <the-list :columns="columns" :value="view"/>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import TheList from './TheList'
import TheTable from './TheTable'
import TheModalMasiveCreation from '@/components/masive-creation/TheModalMasiveCreation'
export default {
  props : {
    title : {
      required: true,
      type: String
    },
    store : {
      required : true,
      type: String
    },
    columns : {
      required : true,
      type : Array
    },
    canCreate :{
      required : false,
      type: Boolean,
      default : true,
    },
    canEdit : {
      required : false,
      type: Boolean,
      default : true,
    },
    canDelete : {
      required : false,
      type: Boolean,
      default : false,
    },
    masiveCreationColumns : {
      require : false,
      type : Array,
      default : () => ([])
    },
    search : {
      required : false,
      type: [Array, Object],
      default : () => ([])
    },
    filters : {
      required : false,
      type: Array,
      default : () => ([])
    },
    customDelete:{
      required : false,
      type : Function,
    },
    afterDelete : {
      required : false,
      type : Function,
    },
    goBack: {
      required: false,
      type: Function
    },
    initQueryParams: {
      required: false,
      type: Object,
      default: () => ({})
    },
    select: {
      required: false,
      type : String,
      validator : (value) => (['simple','multiple'].includes(value))
    },
    hiddenQueryParams: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading : true,
      loadingView : false,
      drawer : false,
      view : {}
    }
  },
  methods: {
    async fetch(params = { page : 1}) {
      this.loading = true;
      try {
        await this.$store.dispatch(`${this.store}/all`, { ...params, ...this.hiddenQueryParams});
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false;
      }
    },
    async fetchOne(id) {
      try {
        const response = await this.$store.dispatch(`${this.store}/one`, id);
        return response
      } catch (error) {
        return false
      } 
    },
    async viewEntity(id) {
      this.drawer = true
      this.loadingView = true
      try {
        const entity = await this.fetchOne(id)
        this.handleView(entity)
      } catch (error) {
        console.log(error)
      }
      this.loadingView = false  
    },
    goToNew() {
      this.$router.push({
        name : `${this.store}New`
      })
    },
    handleEdit(row) {
      this.$router.push({
        name : `${this.store}Edit`,
        params : {
          id : row.id + '',
          entity : row,
          ...row['edit-params']
        }
      });
    },
    handleView(row) {
      this.drawer = true
      if (row) {
        this.view = row
        if (row.id != this.$route.query) {
          this.$router.replace({ path : this.$router.path, query : { id : row.id } })
        }
      }
    },
    async handleDelete(row) {
      const response = await this.$confirm("¿Esta seguro que quiere eliminar " +  row.name +  "? Esta acción no se puede deshacer", this.$t("common.delete"), { confirmButtonText: this.$t("common.yes"), cancelButtonText: this.$t("common.no"), type: 'warning'})
      if (response === "confirm") {
        try {
          if( this.customDelete ){
            await this.customDelete(row);
          }else{
            await this.$store.dispatch(`${this.store}/destroy`, row.id);
          }

          this.$message({ type: 'success', message: 'Se ha borrado exitosamente'})
          if (this.afterDelete) {
            this.afterDelete(row)
          }
        } catch (error) {
          this.$message({ type: 'error',message: this.$t("Ha ocurrido un error y no se ha podido eliminar") })
        }
      }
    },
    goBackTo() {
      this.goBack();
    },
    selectionChange(value) {
      this.$emit('selection-change', value)
    }
  },
  computed : {
    rows() {
      return this.$store.getters[`${this.store}/getData`];
    },
    pagination() {
      return this.$store.getters[`${this.store}/getPagination`];
    },
    _columns() {
      return this.columns.filter(column => !('hide in table' in column)).concat([{ index : 'action-buttons', label : '' }])
    }
  },
  watch : {
    drawer(value) {
      if (!value) {
        this.$router.replace({ path : this.$router.path, params : null })
      }
    }
  },
  created() {
    this.fetch({...this.$route.query, ...this.initQueryParams });

    if ('id' in this.$route.query) {
      this.viewEntity(this.$route.query.id)
    }
  },
  components : {
    TheList,
    TheTable,
    TheModalMasiveCreation
  }
}
</script>

<style scoped>

.header {
  margin-bottom: var(--column);
}

.list {
  display: none;
}

.list .item {
  background: white;
  border: 1px solid var(--blue-grey);
  border-radius: var(--radius);
  padding: var(--column);
}

.item .columns > div:first-child{
  display: inline-flex
}

.item .columns > div:first-child p {
  margin-right: var(--column);
  color: #606266 
}

.title {
  display: inline-flex;
  align-items: center;
  gap: var(--column);;
}

.title h2 {
  margin: 0;
}

</style>

<style >
.header-table th {
  background: rgb(243,243,243)!important;
  border-bottom: 0px solid black!important;
}

.header-table th:first-child { 
  border-radius: var(--radius) 0 0 var(--radius);
}

.header-table th:nth-last-child(2) {
  border-radius: 0 var(--radius) var(--radius) 0;
}
</style>