<template>
  <el-dialog
    :title="$t('agent-console.break.title')"
    :visible.sync="show"
    center
    :close-on-click-modal="closeOnClickModal"
    :show-close="closeOnClickModal"
    :close-on-press-escape="closeOnClickModal"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item :label="$t('agent-console.break.breaks')" prop="break">
        <el-select
          v-model="form.break"
          :placeholder="
            $t('agent-console.break.select-the-break-that-you-want-to-take')
          "
        >
          <el-option
            v-for="item in breaks"
            :key="item.id"
            :label="item.name | utf8"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-button type="primary" @click="submitForm('form')" :disabled="!form.break">{{
        $t("agent-console.break.take-break")
      }}</el-button>
      <el-button @click="show = false">{{ $t("common.cancel") }}</el-button>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "TheModalBreaks",
  data() {
    return {
      show: false,
      form: {
        break: "",
      },
      rules: {
        break: [
          {
            required: true,
            message: this.$t("agent-console.break.select-a-break"),
            trigger: "change",
          },
        ],
      },
      closeOnClickModal: true,
    };
  },
  methods: {
    ...mapActions(["doBreak"]),
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doBreak({
            id: this.form.break,
            name: this.breaks.find((b) => b.id === parseInt(this.form.break)).name,
          });
          this.show = false;
        }
        return false;
      });
    },
  },
  computed: {
    ...mapGetters({
      breaks: "getBreaks",
    })
  },
};
</script>

<style scoped>
form {
  width: 80%;
  margin: 0 auto;
  text-align: center;
}
</style>
