<template>
  <li class="item">
    <slot name="content"></slot>
  </li>
</template>

<script>
export default {

}
</script>

<style scoped>
  .item {
    padding: var(--column) 0;
    margin: var(--column);
  }
</style>