<template>
	<el-dialog
		:visible.sync="show"
		width="45%"
		:close-on-click-modal="!deleting"
		:show-close="!deleting"
		:close-on-press-escape="!deleting"
	>
		<div v-if="!deleting">
			<h2>{{ $t("groups.delete-group.title", { name : name }) }}</h2>
			<p v-if="!owner">{{ $t("groups.delete-group.user-message") }}</p>
			<p v-if="owner">{{ $t("groups.delete-group.owner-message") }}</p>
			<el-button size="mini" @click="toggleGroupDeleteModal">{{ $t("common.cancel") }}</el-button>
			<el-button size="mini" @click="deleteGroup" type="danger">{{ $t("common.delete") }}</el-button>
		</div>
		<div v-if="deleting">
			<p>{{ $t("groups.delete-group.deleting-group") }}</p>
			<i class="fas fa-circle-notch fa-spin"></i>
		</div>
	</el-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
	name: "TheGroupModalDelete",
	computed: {
		show: {
			get() {
				return this.$store.getters.groupDeletesIsVisible;
			},
			set() {
				return this.$store.commit("toggleGroupDelete");
			},
		},
		name: {
			get() {
				return this.$store.getters.getGroupName;
			},
		},
		roomId: {
			get() {
				return this.$store.getters.getRoomId;
			},
		},
		gid: {
			get() {
				return this.$store.getters.getGid;
			},
		},
		owner: {
			get() {
				return this.$store.getters.getOwner;
			},
		},
	},
	data() {
		return {
			deleting: false,
		};
	},
	methods: {
		...mapActions(["toggleGroupDeleteModal", "deleteMemberOfGroup"]),
		deleteGroup() {
			const user = this.$store.state.user.user;
			this.deleting = true;
			this.deleteMemberOfGroup({
				roomId: this.roomId,
				gid: this.gid,
				uid: user.uid,
				deleteForever : true,
				action : 'leave'
			}).then(() => {
				if (window.location.pathname === `/messages/${this.gid}`) {
					this.$router.push({ name : "Messages"});
				}
				this.deleting = false;
				this.show = false;
			});
		},
	},
};
</script>

<style scoped>
	div {
		text-align: center;
	}
	h2, p, button {
		margin-bottom: var(--column);
	}
	.fa-circle-notch {
		color: var(--blue);
		font-size: 24px;
	}
</style>
