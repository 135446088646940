<template>
  <div class="crud">
    <div class="crud-content">
      <div class="header">
        <h2>{{ $t("contacts.title") }}</h2>
        <div class="buttons">
          <el-button type="primary" size="mini" class="mt-0" @click.native="$refs.modal.toggleVisible()" v-if="visibleButton">{{ $t("common.masive-creation")}}</el-button>
          <router-link v-if="visibleButton" :to="{ name: 'NewContact', params : { type : 'organization' } }" class="el-button--primary el-button el-button--mini">{{ $t("contacts.new-organization-contact") }}</router-link>
          <el-dropdown>
            <span class="el-dropdown-link">
              <el-button type="primary" size="mini" class="mt-0">{{ $t("contacts.new") }}</el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="$router.push({ name : 'NewContact'})"> {{ $t("contacts.new") }}</el-dropdown-item>
              <el-dropdown-item @click.native="$refs.modalImport.show = true"> {{ $t("common.import") }} </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <the-table :rows="contacts" :columns="columns" :pagination="null" :loading="loading" :filters="filters" :search="search" @fetch="fetch" height="800" @scrollInBottom="scrollInBottom">
      <template v-slot:visiblename="scope">
        <p style="display:none">{{ scope.row }}</p>
        <div class="user">
          <button class="favorite" @click="toggleFavorite(scope.row)"><i :class="[ scope.row.isFavorite ? 'fas' : 'far']" class="fa-star"></i></button>
          <user-item :user="{ fullname : scope.row.visiblename, picture : scope.row.picture }" :show-status="false"/>
        </div>
      </template>
      <template v-slot:status="scope">
        <el-tag :type="getType(scope.row.status)"> {{scope.row.status}} </el-tag>
      </template>
      <template v-slot:phones="scope">
        <ul class="phones" v-if="scope.row.phones.length > 0">
          <li v-for="(phone, index) in scope.row.phones" :key="index">
            <phone-button :extension="phone.value" :displayname="scope.row.visiblename" :janus="janus" class="phone" v-if="janus"/>
            <p class="mb-0 d-inline-block">{{ phone.value }} ({{ phone.label }})</p>
          </li>
        </ul>
        <p class="mb-0" v-else>No tiene telefonos</p>
      </template>
      <template v-slot:action-buttons="scope">
        <videocall-button v-if="scope.row.hasChat" :room-id="scope.row.videocallRoomId" type="user" :user-id="scope.row.uid" @click.native.stop class="phone"/>
        <chat-button :user="scope.row" v-if="scope.row.hasChat && getHasChat"/>
        <el-dropdown trigger="click" v-if="scope.row.canEdit">
          <span class="el-dropdown-link">
            <el-button icon="fas fa-ellipsis-h" type="text"></el-button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleEdit(scope.row)">{{ $t("common.edit") }}</el-dropdown-item>
            <el-dropdown-item @click.native="handleDelete(scope.row)">{{$t("common.delete")}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </the-table>
    <the-masive-contact-load ref="modal"></the-masive-contact-load>
    <the-modal-import-contacts ref="modalImport"/>
  </div>
</template>

<script>
import countries from '@/helpers/countries.json'
import { mapActions, mapGetters } from "vuex";
import TheTable from '@/components/crud/TheTable'
import UserItem from "@/components/common/UserItem";
import ChatButton from "@/components/webrtc/ChatButton";
import PhoneButton from "@/components/webrtc/PhoneButton";
import TheMasiveContactLoad from "./TheMasiveContactLoad";
import TheModalImportContacts from "./TheModalImportContacts";
import VideocallButton from "@/components/webrtc/VideocallButton";
export default {
  data() {
    return {
      searchValue : '',
      loading : false,
      columns : [
        { index : 'visiblename', label : this.$t("contacts.contact")},
        { index : 'type', label : this.$t("contacts.type")},
        { index : 'email', label : this.$t("contacts.email")},
        { index : 'status', label : this.$t("contacts.status")},
        { index : 'phones', label : this.$t("contacts.phones")},
        { index : 'action-buttons', label : ''},
      ],
      search : ['visiblename','email'],
      filters : [{
        index : 'status',
        label : this.$t("contacts.status"),
        type : 'select',
        multiple : true,
        options : [
          { value : 'busy', label : 'Busy'},
          { value : 'away', label : 'Away'},
          { value : 'disconnected', label : 'Disconnected'},
          { value : 'connected', label : 'Connected'},
        ]
      },
      {
        index : 'type',
        label : this.$t("contacts.type"),
        type : 'select',
        multiple : true,
        options : [
          { value : 'HiperMe', label : 'HiperMe'},
          { value : 'Organization', label : 'Organization'},
          { value : 'Personal', label : 'Personal'},
        ]
      },
      {
        index: 'country',
        label: this.$t("contacts.props.country"),
        type : 'select',
        multiple: true,
        options: Object.keys(countries).map((index) => ({ label: countries[index], value : index}))
      },
      {
        index : 'department',
        label : this.$t("contacts.props.department"),
        type : 'text',
        options : ['contains']
      },
      {
        index : 'company',
        label : this.$t("contacts.props.company"),
        type : 'text',
        options : ['contains']
      },
      {
        index : 'phone',
        label : this.$t("contacts.props.phone"),
        type : 'text',
        options : ['contains']
      }],
      filterStatus : [],
      filterType : [],
      filterCountry: [],
      filterDepartment: "",
      filterCompany: "",
      filterPhone: "",
      rowsShown: 30,
      page: 1,
    }
  },
  methods : {
    ...mapActions("users", {loadNextUsersPage: "loadNextPage", fetchUsers: "all"} ),
    ...mapActions("contacts", ["listenContacts", "deleteContact", "toggleFavorite"]),
    ...mapActions("organization_contacts", { deleteOrganizationContact : "destroy", loadNexOrganizationContactstPage : "loadNextPage", fetchOrganizationContacts: "all"}),
    async fetch(queryParams) {
      this.loading = true;
      this.filterStatus = queryParams.status ?? []
      this.filterType = queryParams.type ?? []
      this.filterCountry = queryParams.country ?? []
      this.filterCountry = queryParams.country ?? ''
      this.filterDepartment = queryParams.department ?? null
      this.filterCompany = queryParams.company ?? null
      this.filterPhone = queryParams.phone ?? null
      const organizations = this.getUser.organizations.map(organization => organization.id)
      const params = { "organizations.id" : organizations, isActive : 1 };

      if (queryParams.visiblename) {
        params.simplesearch = queryParams.visiblename
      }

      if (queryParams.country) {
        params.country = queryParams.country
      }

      params.phone = queryParams.phone ?? null
      params.company = queryParams.company ?? null
      params.department = queryParams.department ?? null

      await this.fetchUsers(params);
      await this.fetchOrganizationContacts(params);
      this.loading = false;
      this.searchValue = queryParams.visiblename ?? ''
    },
    handleEdit(user) {
      this.$router.push({ name: "EditContact",
        params: {
          id: user.id,
          user: user,
          canEdit: user.canEdit,
          type : user.type === 'Personal' ? 'user' : 'organization'
        },
      })
    },
    async handleDelete(user) {
      try {
        const res = await this.$confirm(this.$t("contacts.are-you-sure-that-you-want-to-delete", { visiblename: user.visiblename }), this.$t("contacts.delete-contacts"), { type: "warning"})
        if (res === 'confirm') {
          if (user.type === "Personal") {
            await this.deleteContact(user.id)
          } else {
            await this.deleteOrganizationContact(user.id)
          }
          this.$message({ type: "success", message: this.$t("contacts.the-contact-was-deleted-successfully")})
        }
      } catch (err) {
        this.$message({ type: "error", message: this.$t("contacts.there-was-a-problem-trying-to-delete-the-contact")});
      }
    },
    getType(status) {
      const states = { connected : 'success', disconnected : 'info', busy : 'warning', away : 'danger', none : 'info'}
      return states[status]
    },
    async scrollInBottom() {
      this.loading = true;
      await this.loadNextUsersPage();
      await this.loadNexOrganizationContactstPage();
      this.loading = false;
    }
  },
  components : {
    TheTable,
    UserItem,
    ChatButton,
    PhoneButton,
    VideocallButton,
    TheMasiveContactLoad,
    TheModalImportContacts
  },
  computed : {
    ...mapGetters(["getJanus", "getUser","getHasChat"]),
    ...mapGetters("contacts", { tmpcontacts: "getAllContacts", props : 'getHiddenProps'}),
    visibleButton() {
      return this.getUser.roles.includes('ROLE_ADMIN') || this.getUser.roles.includes('ROLE_MANAGER')
    },
    janus() {
      if (this.getJanus) {
        return this.getJanus
      }

      return false
    },
    contacts() {
      const data = this.tmpcontacts
      return data
        .sort((a, b) => {
          if (a.isFavorite && !b.isFavorite) {
            return -1
          }

          if (b.isFavorite && !a.isFavorite) {
            return 1;
          }

          return 0;
        })
        .filter((_contact) => {
          if (this.searchValue === "") {
            return true
          }          
          return _contact.visiblename.toLowerCase().includes(this.searchValue.toLowerCase()) || _contact.email.toLowerCase().includes(this.searchValue.toLowerCase())
        })
        .filter((_contact) => this.filterStatus.length === 0 || this.filterStatus.includes(_contact.status))
        .filter((_contact) => this.filterType.length === 0 || this.filterType.includes(_contact.type))
        .filter((_contact) => this.filterCountry.length === 0 || this.filterCountry.includes(_contact.country))
        .filter((_contact) => !this.filterDepartment || _contact.department.toLowerCase().includes(this.filterDepartment.toLowerCase()))
        .filter((_contact) => !this.filterCompany || _contact.company.toLowerCase().includes(this.filterCompany.toLowerCase()))
        .filter((_contact) => !this.filterPhone || _contact.phone?.toLowerCase().includes(this.filterPhone.toLowerCase()))
    },
  }
}
</script>

<style scoped>

.user {
  display: inline-flex;
  align-items: center;
}
.favorite {
  background-color: transparent;
  outline: none;
  border: none;
  display: inline-block;
  color: var(--blue-grey);
  margin-right: calc(var(--column) / 2)
}

.favorite .fas {
  color: var(--orange)
}

.phones {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 0;
}

.phones li {
  display: inline-flex;
}

.phone {
  display: inline-block;
  margin-right: calc(var(--column) / 2);
}
</style>