<template>
    <div class="box send-email">
        <div class="header">
            <p>{{ $t("agent-console.send-email-to", {email : destination}) }}</p>
            <i class="fas fa-times" @click="closeBox"></i>
        </div>
        <el-form ref="form" :model="form" label-width="120px" label-position="top" size="small">
            <el-form-item :label="$t('agent-console.send-email-box.subject')">
                <el-input v-model="form.subject"></el-input>
            </el-form-item>
            <el-form-item :label="$t('agent-console.send-email-box.message')" prop="desc">
                <el-input type="textarea" v-model="form.message"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary">{{ $t("agent-console.send") }}</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name : "TheSendEmailBox",
    props : {
        destination : {
            default : '',
        }
    },
    data() {
        return {
            form : {
                subject : '',
                message : ''
            }
        }
    },
    methods : {
        closeBox() {
            this.$emit('closeEmailBox');
        }
    }
}
</script>

<style scoped>
.send-email .header {
    align-content: center;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
</style>