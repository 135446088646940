<template>
  <div style="width:300px">
    <h2>Transcripción</h2>
    <div class="messages" v-if="activeClient">
      <div v-for="(item, index) in transcription" :key="index" class="chat-message" 
      :class="{
          'myself' : item.client === false
        }">
        <div>
          <div class="background">
            <span>{{ item.text }}</span>
          </div>
          <span class="date">{{ item.timestamp | redeableDate }}</span>
        </div>
      </div>
      <div v-if="transcription.length == 0">
        <p>La llamada no contiene una transcripción</p>
      </div>
    </div>
    <div v-else>
      <p>Debe de seleccionar una llamada para ver su transcripción</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      activeClient : 'getActiveItem'
    }),
    transcription() {
      if (!this.activeClient) {
        return []
      }
      return Object.values(this.activeClient.transcription).filter((item) => item.text)
    }
  }
}
</script>

<style scoped>
.messages {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}

.chat-message {
  display: flex;
  font-size: 12px;
}

.chat-message.myself {
  justify-content: flex-end;
}

.chat-message .background {
  background-color: #f3f3f3;
  border-radius: 25px;
  padding: var(--column);
}

.chat-message.myself .background {
  background-color: var(--bubble-self-background-color);
  color: var(--bubble-self-text-color)
}

.myself .date{
  text-align: right;
  width: 100%;
  display: block;
}

h2 {
  font-size: 1.5rem;
}



</style>