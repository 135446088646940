const state = {
  currentCallsList: [],
  refCurrentCalls: null
}

const getters = {
  getCurrentCallsList: () => state.currentCallsList
}

const mutations = {
  UPDATE_CURRENT_CALLS: (state, calls) => state.currentCallsList = calls,
  UPDATE_REF_CURRENT_CALLS: (state, ref) => state.refCurrentCalls = ref
}

const actions = {
  startListenCurrentCalls ({ commit, rootGetters }, organizationId)  {
    const firebase = rootGetters.getFirebase;
    if(state.refCurrentCalls !== null){
      state.refCurrentCalls.off();
    }
    commit('UPDATE_REF_CURRENT_CALLS', firebase.database().ref(`organization/${organizationId}/current_calls`));
    state.refCurrentCalls.on('value', (snap) => {
      const currentCalls = snap.val();
      if(currentCalls){
        const keys = Object.keys(currentCalls);
        const currentCallsArray = keys.map((key) => {
          return {
            callId: key,
            src: currentCalls[key].src,
            dst: currentCalls[key].dst,
            startedAt: currentCalls[key].date,
            realDestiny: currentCalls[key].realDestiny
          }
        })
        commit('UPDATE_CURRENT_CALLS', currentCallsArray);
      }else {
        commit('UPDATE_CURRENT_CALLS', []);
      }
    })
  },
  stopListenCurrentCalls({ commit }){
    if(state.refCurrentCalls !== null){
      state.refCurrentCalls.off();
      commit('UPDATE_CURRENT_CALLS', []);
    }
  }
}

export default{
  state,
  getters,
  mutations,
  actions,
  namespaced : true,
}