<template>
  <el-card class="box-card">
    <div class="extensions">
      <p>Extension {{ src }} En llamada con {{ dst }}</p>
    </div>
    <Chronometer class="chronometer" :timestamp="startedAt"></Chronometer>
  </el-card>
</template>
<script>
import Chronometer from '../common/Chronometer.vue';
export default {
  name: "TheCallCard",
  props: {
    src: String,
    dst: String,
    startedAt: String
  },
  components: {
    Chronometer
  }
}
</script>
<style>
.calls .box-card .el-card__body{
	width: 100%;
  display: flex !important;
  justify-content: space-between !important;
}
</style>
<style scoped>
.box-card {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between; 
}

  .box-card .extensions {
    display: flex;
    justify-content: space-between;
  }
</style>