import RequireAuth from '../auth'
import AppView from '../../views/AppLayout'
import UserRoutes from './user'
import AgentRoutes from './agent'
import ExtensionRoutes from './extension'
import OrganizationRoutes from './organization'
import ServerRoutes from './servers'
import GeneralConfigurationRoutes from './general-configuration'
import ContactMeRoutes from "./contact-me";
import ChatCenterRoutes from "./contact-me/chat-center"

export default [
  {
    name: "Configuration",
    path: "/configuration",
    meta: {
      isInMenu: true,
      title: 'main-menu.configuration.title',
      icon: 'fas fa-cog',
      type: 'title',
      order: 3,
      access_control : ['ROLE_ADMIN','ROLE_MANAGER']
    },
    children: [
      ...ServerRoutes,
      ...OrganizationRoutes,
      ...ExtensionRoutes,
      ...UserRoutes,
      ...AgentRoutes,
      ...GeneralConfigurationRoutes,
      ...ContactMeRoutes,
      ...ChatCenterRoutes,
    ],
    components : { 
			appView : AppView
		},
    beforeEnter : RequireAuth,
  }
]
