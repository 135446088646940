const createQueueItem = (snap, organizationId, extra) => {
  const data = snap.val( )
  data.organizationId = organizationId;
  data.extra = extra;
  return data;
}


const defaultQueuesValues = () => ({
  times : {
    loggin : 0,
    talking : 0,
    break : 0
  },
  logged_started_at : 0,
  break_started_at : 0,
  calls_per_hour : [],
  calls_total : {
    incoming : 0,
    outgoing : 0,
    manual : 0,
    transfer : 0
  },
  chats_total : {
    normal : 0,
    whatsapp : 0
  }
})

const state = {
  listeningOrganizationsIds: [],
  alreadyListening : false,
  queuesDashboardList: [],
  defaultQueueValues : defaultQueuesValues(),
  queuesRefsList : []
}



const getters = {
  getQueuesDashboardList : (state) => state.queuesDashboardList,
  getDefaultQueueValues : (state) => state.defaultQueueValues,
  getQueueDashboardFromList : (state) => (number) => state.queuesDashboardList.find(queue => queue.queue === number),
}


const mutations = {
  TOGGLE_ALREADY_LISTENING_LIST : (state) => (state.alreadyListening = !state.alreadyListening),
  ADD_QUEUE_DASHBOARD_LIST: (state, queue) => state.queuesDashboardList.push(queue),
  REMOVE_QUEUE_DASHBOARD_LIST: (state, uid) => state.queuesDashboardList = state.queuesDashboardList.filter(queue => uid !== queue.queue),
  UPDATE_QUEUE_DASHBOARD_LIST : (state, queue) => (state.queuesDashboardList = state.queuesDashboardList.map(a => a.queue === queue.queue ? {...queue} : a)),
  UPDATE_QUEUE_LIST : (state, queue) => state.defaultQueueValues = { ...state.defaultQueueValues, ...queue },
  ADD_QUEUE_REF_LIST : (state, data) => (state.queuesRefsList.push(data)),
  REMOVE_QUEUE_REF_LIST : (state, key) => state.queuesRefsList = state.queuesRefsList.filter(ref => ref.queue !== key)
}

const actions = {
  listenQueuesDashboardArray({ dispatch }, organizationIdsArray = [] ){
    Array.from(organizationIdsArray).forEach( organizationId => {
      //salteo las que ya estaba escuchando...
      if( state.listeningOrganizationsIds.includes( organizationId ) ) return; 

      //agrego si no la estaba escuchando...
      state.listeningOrganizationsIds.push( organizationId );
      dispatch( 'startListenQueuesDashboard', organizationId);
    } );


    //elimino las que dejé de escuchar.
    [ ...state.listeningOrganizationsIds ].forEach( ( storeId, idx ) => {
      if(  ! organizationIdsArray.includes( storeId ) ){
          state.listeningOrganizationsIds.splice( idx, 1 );
          dispatch( 'stopListeningQueuesDashboard', storeId);
          return; 
      }
    }); 
  },


  stopListeningQueuesDashboard({ commit }, organizationId){
    const queuesToRemove = state.queuesDashboardList.filter(queue => queue.organizationId === organizationId );
    Array.from(queuesToRemove).forEach( queue => {

        commit('REMOVE_QUEUE_REF_LIST', queue.queue)
        commit('REMOVE_QUEUE_DASHBOARD_LIST', queue.queue);
        commit('UPDATE_QUEUE_LIST', defaultQueuesValues())
    } );
  
  },


  startListenQueuesDashboard({ rootGetters, commit }, organizationId) {
    const firebase = rootGetters.getFirebase;

    firebase.database().ref(`organization/${organizationId}/queues_dashboard`).on('child_added', (snap) => {
      const queue = createQueueItem(snap, organizationId, 'child-added-grid');
      commit('ADD_QUEUE_DASHBOARD_LIST', queue);

      const ref = firebase.database().ref(`organization/${organizationId}/queues_dashboard/${snap.key}`);

      ref.on('value', (snap) => {
        const queue = createQueueItem(snap, organizationId, 'onvalue-grid');
        commit('UPDATE_QUEUE_DASHBOARD_LIST', queue);
      });

      commit('ADD_QUEUE_REF_LIST', { ref, uid : queue.queue })
    });

    firebase.database().ref(`organization/${organizationId}/queues_dashboard`).on('child_removed', (snap) => {
      commit('REMOVE_QUEUE_DASHBOARD_LIST', snap.key);
      commit('UPDATE_QUEUE_LIST', defaultQueuesValues())
    });
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}