<template>
<div class="crud">
  <the-crud :title="title" :store="store" :columns="columns" :search="search" :canDelete="true">
  </the-crud>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheCrud from '@/components/crud/TheCrud'
export default {
  components : {
    TheCrud
  },
  data() {
    return {
      closeModal : true,
      server : null,
      confirmationValue: '',
      confirmationMessage: '',
      title : this.$t("configuration.servers.chat.title"),
      store : 'livehelperchats',
      search : ['name'],
      columns : [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('configuration.servers.name') },
        { index : 'address', label : this.$t('configuration.servers.chat.address') },
        { index : 'hash', label : this.$t('configuration.servers.chat.hash'), 'hide in table': true },
        { index : 'restApiAddress', label : this.$t('configuration.servers.chat.api_address') },
        { index : 'adminUsername', label : this.$t('configuration.servers.chat.username'), 'hide in table': true },
        { index : 'adminPassword', label : this.$t('configuration.servers.chat.password'), 'hide in table': true },
      ],
    }
  },
  computed : {
    ...mapGetters(['isAdmin']),
  }
}
</script>

<style>

</style>