import TheChatCenterPanel from "@/views/chat-center/TheChatCenterPanel.vue"
import TheDepartmentsList from "@/views/chat-center/department/TheList.vue"
import TheDepartmentsForm from "@/views/chat-center/department/TheForm.vue"

import TheChatProvidersList from "@/views/chat-center/provider/TheList.vue";
import TheChatProvidersForm from "@/views/chat-center/provider/TheForm.vue";

import TheBotsList from "@/views/chat-center/bot/TheList.vue";
import TheBotsForm from "@/views/chat-center/bot/TheForm.vue";

import TheCacheChatList from "@/views/chat-center/cache-chats/TheList.vue";

import TheOfficeHoursList from "@/views/chat-center/time-conditions/TheList.vue";
import TheOfficeHoursForm from "@/views/chat-center/time-conditions/TheForm.vue";

import TheChatDispositionList from "@/views/chat-center/chat-dispositions/TheList.vue";
import TheChatDispositionForm from "@/views/chat-center/chat-dispositions/TheForm.vue";

import TheFormList from '@/views/chat-center/form/TheList.vue';
import TheCreateForm from '@/views/chat-center/form/CreateForm.vue';

import TheChatClassificationList from '@/views/chat-center/chat-classification/TheList.vue';
import TheChatClassificationForm from '@/views/chat-center/chat-classification/TheForm.vue';

import TheQuickResponseTemplateList from "@/views/chat-center/quick-response-templates/TheList.vue";
import TheQuickResponseTemplateForm from "@/views/chat-center/quick-response-templates/TheForm.vue";

const routes = [
  {
    name: "contact-me-chat-center",
    path: "/contact-me/chat-center",
    component: TheChatCenterPanel,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "departmentsList",
    path: "/contact-me/chat-center/departments",
    component: TheDepartmentsList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "departmentsNew",
    path: "/contact-me/chat-center/departments/new",
    component: TheDepartmentsForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "departmentsEdit",
    path: "/contact-me/chat-center/departments/:id/edit",
    component: TheDepartmentsForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
  },
  {
    name: "chatProvidersList",
    path: "/contact-me/chat-center/providers",
    component: TheChatProvidersList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "chatProvidersNew",
    path: "/contact-me/chat-center/providers/new",
    component: TheChatProvidersForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "chatProvidersEdit",
    path: "/contact-me/chat-center/providers/:id/edit",
    component: TheChatProvidersForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
  },
  {
    name: "botsList",
    path: "/contact-me/chat-center/bots",
    component: TheBotsList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "botsNew",
    path: "/contact-me/chat-center/bots/new",
    component: TheBotsForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "botsEdit",
    path: "/contact-me/chat-center/bots/:id/edit",
    component: TheBotsForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
  },
  {
    name: "cacheChatList",
    path: "/contact-me/chat-center/cache-chats",
    component: TheCacheChatList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "timeConditionList",
    path: "/contact-me/chat-center/time-conditions",
    component: TheOfficeHoursList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "timeConditionsNew",
    path: "/contact-me/chat-center/time-conditions/new",
    component: TheOfficeHoursForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "timeConditionsEdit",
    path: "/contact-me/chat-center/time-conditions/:id/edit",
    component: TheOfficeHoursForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "chatDispositionsList",
    path: "/contact-me/chat-center/chat-dispositions",
    component: TheChatDispositionList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "chatDispositionsNew",
    path: "/contact-me/chat-center/chat-dispositions/new",
    component: TheChatDispositionForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "chatDispositionsEdit",
    path: "/contact-me/chat-center/chat-dispositions/:id/edit",
    component: TheChatDispositionForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "formsList",
    path: "/contact-me/chat-center/forms",
    component: TheFormList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "formsNew",
    path: "/contact-me/chat-center/forms/new",
    component: TheCreateForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "formsEdit",
    path: "/contact-me/chat-center/forms/:id/edit",
    component: TheCreateForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "chatClassificationList",
    path: "/contact-me/chat-center/chat-classifications",
    component: TheChatClassificationList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
    name: "chatClassificationsNew",
    path: "/contact-me/chat-center/chat-classifications/new",
    component: TheChatClassificationForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },

  {
    name: "chatClassificationsEdit",
    path: "/contact-me/chat-center/chat-classifications/:id/edit",
    component: TheChatClassificationForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "quickResponseTemplatesList",
    path: "/contact-me/chat-center/quick-response-templates",
    component: TheQuickResponseTemplateList,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "quickResponseTemplatesNew",
    path: "/contact-me/chat-center/quick-response-templates/new",
    component: TheQuickResponseTemplateForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "quickResponseTemplatesEdit",
    path: "/contact-me/chat-center/quick-response-templates/:id/edit",
    component: TheQuickResponseTemplateForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  }

  
]

export default routes
