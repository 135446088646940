<template>
  <div class="form-agent-console" v-loading="form.status === 'saving'" :element-loading-text="$t('common.saving')">
    <p v-if="form.description">{{ form.description | utf8 }}</p>
    <el-form  label-position="top"  size="small"  :disabled="disabled">
      <div v-for="input in inputs" :key="input.id">
        <el-form-item :label="input.label | utf8" v-if="!isLink(input.label)">
          <el-input v-model="input.value" v-if="input.type === 'TEXT'" @change.native="update(input.index, input.value)"></el-input>
          <el-input type="textarea" v-model="input.value" v-if="input.type === 'TEXTAREA'" @change.native="update(input.index, input.value)"  ></el-input>
          <el-date-picker v-if="input.type === 'DATE'"  v-model="input.value" type="date" placeholder="Pick a day" value-format="yyyy-MM-dd" @change="update(input.index, input.value)"></el-date-picker>
          <el-select placeholder="Select" v-if="input.type === 'LIST'" v-model="input.value" @change="update(input.index, input.value)">
              <el-option v-for="item in input.list" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <a :href="input.label" target="_blank" v-else>{{ input.label }}</a>
      </div>
      <el-form-item v-if="form.status !== 'saved'">
        <el-popconfirm :title="$t('agent-console.are-you-sure-to-cancel-the-form?-Once-this-action-is-performed,-the-form-cannot-be retrieved')" :confirm-button-text="$t('common.yes')" :cancel-button-text="$t('common.no')" @onConfirm="removeForm({id: form.id, callkey: callKey, formKey : formKey})">
            <el-button slot="reference">{{ $t("common.cancel") }}</el-button>
        </el-popconfirm>
        <el-button type="primary" @click="submit">{{ $t("common.save") }}</el-button>
      </el-form-item>
    </el-form>
    <el-dialog width="30%" :visible.sync="dialog">
      <success v-if="form.status === 'saved'"></success>
      <error v-else></error>
      <p class="message">{{ dialogMessage | utf8}}</p>
      <div style="display: flex; justify-content: center;">
        <el-button @click="dialog = false" type="primary">{{ $t("common.close") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions  } from "vuex"
import Error from '@/components/icons/Error'
import Success from '@/components/icons/Success'
export default {
  props : {
    form : {
      required : true
    },
    path :{
      required : false
    }
  },
  data() {
    return {
      inputs : this.form.inputs,
      dialog : false
    }
  },
  computed : {
    status() {
      return this.form.status;
    },
    disabled() {
      return this.status === 'saving' || this.status === 'saved'
    },
    dialogMessage() {
      if (this.status === 'saved') {
        return this.$t("agent-console.the-form-has-been-saved-successfully", { name : this.form.name })
      } else if (this.status === 'error') {
        return this.$t("agent-console.an-error-occurred-while-trying-to-save-the-form", { name : this.form.name })
      } else {
        return this.$t("agent-console.the-form-could-not-be-saved-time-out", { name : this.form.name })
      }
    }
  },
  methods : {
    ...mapActions(['updateValueOfForm']),
    isLink(value) {
      return /(^https:\/\/)/.test(value)
    },
    update(index, value) {
      this.updateValueOfForm({ path : this.path, index, value })
    },
    submit() {
      const form = this.form
      form.inputs = this.inputs.map(input => ({
        id_form_field : input.id,
        value : input.value
      }))
      this.$emit('submit', form);
    }
  },
  watch : {
    status : function(value) {
      if (value === 'saved' || value === 'error' || value === 'timeout') {
          this.dialog = true;
          return
      }
      this.dialog = false
    }
  },
  components : {
    Error,
    Success
  }
}
</script>

<style scoped>
  .message {
      color: var(--blue-grey);
      text-align: center;
  }
  .error,
  .success {
      margin: 0 auto;
      margin-bottom: var(--column);
      display: block;
      width: fit-content;
  }
  .el-button {
      margin-right: var(--column);
  }
</style>