<template>
  <div class="el-message" :class="className">
    <p class="el-message__content">{{ message }}</p>
    <el-button type='text' @click="$emit('close')" class="ml-2 mb-0 mt-0">{{ $t('common.close') }}</el-button>
  </div>
</template>

<script>
export default {
  props : {
    type : {
      required : false,
      default : 'info'
    },
    message : {
      required : false,
      default : ''
    }
  },
  computed : {
    className : function() {
      const classNames = {
        success : 'success',
        warning : 'warning',
        info : 'info',
        error : 'error'
      }
      return `el-message--${classNames[this.type]}`
    }
  }
  
}
</script>

<style>

</style>