<template>
  <div class="file" :class="{'download-active' : download }">
    <i class="far" :class="className"></i>
    <p class="mb-0 name">{{ file.name }}</p>
    <div v-if="download" class="download">
        <p class="mb-0">Descargar</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "File",
  props: {
    file: {
      required: true,
      type: Object,
      default: () => {
        return {
          name: "",
          type: "",
        };
      },
      validator: (value) => {
        return ["image", "text", "video", "audio"].indexOf(value.type) !== -1;
      },
    },
    download: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    className() {
      return this.file.type !== "text"
        ? `fa-file-${this.file.type}`
        : "fa-file";
    },
  },
};
</script>

<style scoped>
.file {
  cursor: pointer;
  display: inline-flex;
  padding: var(--column);
}

.file.download-active {
    position: relative;
}

.file.download-active:hover .download {
    display: block;
}

.file .download {
    background-color: rgb(51 54 69 / 0.9);
    color: white;
    display: none;
    height: 100%;
    padding: var(--column);
    position: absolute;
    left: 0;
    text-align: center;
    top: 0;
    width: 100%;
}

.far {
  font-size: 20px;
  color: var(--blue);
}

.name {
  color: var(--bubble-text-color);
  font-size: 14px;
  margin-left: 10px;
}
</style>