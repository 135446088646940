import zohoapi from '@/api/zoho';
import { getDate } from '@/helpers';

const ZohoProxy = () => {
  let call_id,
    caller_id,
    calling_to,
    start_date,
    user = '';

  let isIncomingCall = false;

  let enabled = true;

  const toggleIntegration = zohoapi.toggleIntegration

  const toggleClick2Dial = zohoapi.toggleClick2Dial

  const callControls = zohoapi.callControls

  const getPhoneNumber = (username) => username.replace('sip:', '').split('@').shift()

  const incomingcall = (message) => {
    isIncomingCall = true;
    caller_id = getPhoneNumber(message.username)

    return {
      type: 'received',
      state: 'ringing',
      id: call_id,
      from: caller_id,
      to: user
    }
  }

  const ringing = (message) => {
    calling_to = message.to;
    return {
      type: 'dialed',
      state: 'ringing',
      from: message.from,
      id: message.id,
      to: message.to
    }
  }

  const hangup = (message) => {
    const codes = {
      200: 'ended',
      486: 'busy',
      487: 'missed',
    }

    const data = {
      state: codes[message.code],
      id: call_id,
      type: isIncomingCall ? 'received' : 'answered',
      from: isIncomingCall ? caller_id : user,
      to: isIncomingCall ? user : calling_to,
      duration: message.code === 487 ? null : parseInt((new Date().getTime() - start_date.getTime()) / 1000),
      start_time: getDate(start_date),
    }

    isIncomingCall = false;
    return data
  }

  const accepted = () => {
    start_date = new Date();
    return {
      state: 'answered',
      id: call_id,
      type: isIncomingCall ? 'received' : 'dialed',
      from: isIncomingCall ? caller_id : user,
      to: isIncomingCall ? user : calling_to
    }
  }

  const events = {
    incomingcall,
    ringing,
    accepted,
    hangup,
  }

  const send = (event, message) => {
    if (events[event] && enabled) {
      const data = events[event](message);
      zohoapi.callNotify(null, data)
    }
  }

  const setUser = (value) => user = value
  const setCallId = (value) => call_id = value
  const setEnabled = (value) => enabled = value

  return {
    send,
    setUser,
    setCallId,
    setEnabled,
    callControls,
    toggleIntegration,
    toggleClick2Dial,
  }
}

export default ZohoProxy;