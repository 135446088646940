<template>
    <el-date-picker :type="field.configuration.time ? 'datetime' : 'date'" :placeholder="field.configuration.placeholder" :picker-options="datePickerOptions" v-model="value" value-format="yyyy-MM-dd HH:mm:ss"/>
</template>

<script>
export default {
    props:{
        field: {
            required: true
        }
    },
    data() {
        return {
            value: '',
        }
    },
    methods: {
        disabledDate (date) {
            return (this.field.configuration.min !== 0 && date < new Date(this.field.configuration.min)) || (this.field.configuration.max !== 0 && date > new Date(this.field.configuration.max))
        },
        getValue() {
            return this.value
        }
    },
    computed: {
        datePickerOptions() {
            return {
                disabledDate: this.disabledDate
            }
        }
    }
}
</script>

<style>

</style>
