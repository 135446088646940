import { render, staticRenderFns } from "./TheExtensionCard.vue?vue&type=template&id=23b70581&scoped=true&"
import script from "./TheExtensionCard.vue?vue&type=script&lang=js&"
export * from "./TheExtensionCard.vue?vue&type=script&lang=js&"
import style0 from "./TheExtensionCard.vue?vue&type=style&index=0&id=23b70581&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b70581",
  null
  
)

export default component.exports