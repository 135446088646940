<template>
    <div>
        <el-tag :size="size" class="ml-1 mb-1" v-for="(value, index) in data.slice(0, max)" :key="index" :type="(value.type) ? value.type : ''" >{{ value.value }}</el-tag>
        <el-dropdown class="ml-1 mb-1" v-if="data.slice(max).length !== 0">
            <el-tag :size="size" type="info" class="el-dropdown-link more">
                <i class="fas fa-plus"></i> {{ data.slice(max).length }}
            </el-tag>           
            <el-dropdown-menu slot="dropdown" class="dropdown">
                <el-tag :size="size" class="tag mb-1" v-for="(value, index) in data.slice(max)" :key="index" :type="(value.type) ? value.type : ''" >{{ value.value }}</el-tag>
            </el-dropdown-menu> 
        </el-dropdown>
    </div>
</template>

<script>
export default {
    name : 'MultiTags',
    props : {
        data : {
            type : Array,
            required : true
        },
        max : {
            type : Number,
            required : false,
            default : 3
        },
        size : {
            type : String,
            required : false,
            default : ''
        }
    }
}
</script>

<style scoped>
    .more .fas{
        color: #909399
    }

    .dropdown {
        padding: var(--column)
    }

    .dropdown .tag {
        margin-left: calc(var(--column) / 2);
    }
</style>