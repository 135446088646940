<template>
  <div>
    <h2>{{ $t("general-configurations.title") }}</h2>
    <el-tabs tab-position="top">
      <el-tab-pane :label="$t('general-configurations.side-images.title')"><TheSideImageConfiguration></TheSideImageConfiguration></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import TheSideImageConfiguration from "./TheSideImageConfiguration";
export default {
  name: "TheMainGeneralConfigurationView",
  components : {
    TheSideImageConfiguration
  }
};
</script>

<style>
</style>