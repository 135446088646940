<template>
  <div v-loading="saving" :element-loading-text=" $t('contacts.saving')">
    <div v-if="!formSended">
      <h2>{{ $t("contacts.new") }}</h2>
      <the-contact-form @submitForm="submitForm" :type="type"></the-contact-form>
    </div>
    <NotificationABM
      v-else
      route="Contacts"
      :status="saved"
      :successTitle="$t('contacts.saved')"
      :text="saved ? `` : $t('contacts.error-saved')"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TheContactForm from './TheContactForm'
import NotificationABM from '@/components/common/NotificationABM'
export default {
  props : {
    type : {
      required : false,
      default : 'user'
    }
  },
  data() {
    return {
      saving : false,
      formSended : false,
      saved : false
    }
  },
  components : {
    TheContactForm,
    NotificationABM
  },
  methods : {
    ...mapActions('contacts',['addContact']),
    ...mapActions('organization_contacts', ['create']),
    submitForm(form) {
      let isEmpty = true
      Object.keys(form).forEach((key) => {
        if (form[key] !== "") {
          isEmpty = false
        }
      })

      if (!isEmpty) {
        this.saving = true

        if (this.type === 'user') {
          this.addContact(form).then(() => {
            this.formSended = true
            this.saving = false
            this.saved = true
          })
        } else {
          this.create(form).then(() => {
            this.formSended = true
            this.saving = false
            this.saved = true
          })
        } 
      }
    }
  }
}
</script>

<style>

</style>