function getCookie(cookieName = 'hiper_token') {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${cookieName}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return '';
}

function deleteCookie() {
    document.cookie = 'hiper_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function setCookie(name, value, hours) {
    const date = new Date();
    date.setHours(date.getHours() + hours);
    const expires = "expires=" + date.toUTCString();
    document.cookie = `${name}=${value};${expires};path=/`;
}

export default { getCookie, deleteCookie, setCookie }