<template>
  <div class="crud form main-view">
  <el-form size="small" label-position="right" target="#app-main-content" ref="ruleForm" :rules="rules" :model="form" v-loading="saving || loading || updating" v-if="status === 0"  :element-loading-text="loadingtext">
    <h2>{{ title }}</h2>
    <el-form-item :label="$t('common.name')" required prop="name">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contact-me.chat-center.department')" required prop="department">
      <el-select v-model="form.department" :disabled="disabled">
        <el-option label="Todos los departmentos" value=" "></el-option>
        <el-option :label="department.name" :value="department['@id']" v-for="department in departments" :key="department.id"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('common.description')" required prop="description">
      <el-input type="textarea" :rows="3" show-word-limit maxlength="255" v-model="form.description"></el-input>
    </el-form-item>
    <el-form-item :label="$t('common.color')" required prop="color">
      <div class="colors">
        <el-radio v-model="form.color" v-for="color in colors" :key="color.value"  :label="color.value" border>
          <span :style="`background-color: var(${color.value})`"></span>
          <span>{{ color.label}}</span>
        </el-radio>
      </div>
    </el-form-item>
    <el-button size="small" @click="$router.go(-1)">{{ $t("common.cancel") }}</el-button>
    <el-button type="primary" size="small" @click.native="submit">{{ $t("common.save") }}</el-button>
  </el-form>
  <div class="message" v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 200"/>
        <img src="@/img/error.svg" v-else/>
        <p> {{ message }} </p>
        <div class="buttons">
          <el-button type="text" @click="$router.go(-1)">{{ $t("common.go-back-to-the-list") }}</el-button>
          <el-button type="text" @click="status = 0; saving = false; loading = false" v-if="status !== 404">{{ $t("common.show-the-form") }}</el-button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    edit : {
      required : false,
      default : false,
    },
    id : {
      required : false,
      type : String,
      default : "0"
    },
    entity : {
      required : false,
      type : Object
    }
  },
  data() {
    return {
      title: this.id !== '0' ? this.$t('contact-me.chat-center.edit-chat-disposition') : this.$t('contact-me.chat-center.create-chat-disposition'),
      form: {
        name: this.entity ? this.entity.name : '',
        description: this.entity ? this.entity.description : '',
        color: this.entity ? this.entity.color : '',
        department: this.entity ? (this.entity.department ? this.entity.department : '') : ''
      },
      rules: {
        name: [
          { required: true, message: this.$t('rules.required'), trigger: 'blur' },
          {  min: 3, message: this.$t('rules.min-characters', { number: 3 }), trigger: 'blur' },
          {  max: 50, message: this.$t('rules.max-characters', { number: 50 }), trigger: 'blur' },
        ],
        description: [
          { required: true, message: this.$t('rules.required'), trigger: 'blur' },
          {  min: 3, message: this.$t('rules.min-characters', { number: 3 }), trigger: 'blur' },
          {  max: 255, message: this.$t('rules.max-characters', { number: 255 }), trigger: 'blur' },
        ]
      },
      loading: false,
      saving: false,
      updating: false,
      departments: [],
      status: 0,
      colors: [
          { label: 'green', value: "--green-500" },
          { label: 'violet', value: "--violet-500" },
          { label: 'blue', value: "--blue-500" },
          { label: 'red', value: "--red-500" },
          { label: 'yellow', value: "--yellow-500" },
      ],
      disabled: false
    }
  },
  methods: {
    submit() {
      console.log(this.form)
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          return false
        }

        try {
          let id = 0;
          this.saving = true
          this.loading = true
          if(this.form.department.trim().length === 0){
              this.form.department = null
            }
          if (this.id === "0") {
            const organizationId = this.$store.getters["contactMe/getOrganizationId"];
            const response = await this.$store.dispatch('chatDispositions/create', { ...this.form, organization: `/api/organizations/${organizationId}` })
            id = response.id
          } else {
            id = this.id
            const payload = { ...this.form }
            await this.$store.dispatch('chatDispositions/update', { id, payload })
          }
          this.status = 200
        } finally {
          this.loading = false
        }
      })
    },
  },
  computed: {
    message() {
      if (this.status === 200) {
        return this.$t("common.saved")
      }

      if (this.status === 404) {
        return this.$t("common.not-found")
      }

      return this.$t("common.not-saved")
    },
    loadingtext() {
      if (this.saving || this.updating) {
        return this.$t('common.saving') 
      }

      if (this.loading) {
        return this.$t('common.loading-please-wait')
      }

      return "";
    },
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  },
  async created(){
    this.disabled = true;
    this.departments = await this.$store.dispatch('departments/all', {'organization.id': this.organizationId})
    this.disabled = false;
  }
}
</script>

<style scoped>
.colors {
  display: inline-flex;
  gap: var(--column);
  width: 100%;
}

.colors span:first-child {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}
</style>

<style>
.colors .el-radio {
  margin: 0!important;
  height: 50px!important;
  display: inline-flex;
  padding: 0 15px!important;
}

.colors .el-radio .el-radio__inner {
  display: none;
}

.colors .el-radio .el-radio__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--column) / 2);
}

</style>