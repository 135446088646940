<template>
  <el-dialog
		:close-on-click-modal="false"
		:show-close="false"
		:close-on-press-escape="false"
		:visible.sync="show"
	>
    <p>Su contraseña esta a punto de expirar. Por favor cambie su contraseña</p>
    <the-change-password></the-change-password>
  </el-dialog>
</template>

<script>
import TheChangePassword from './TheChangePassword';
export default {
  data() {
    return {
      show : false,
    }
  },
  computed: {
    hasToChangePassword() {
      return this.$store.getters.getActions.find(action => action.type === "change_password");
    }
  },
  components: {
    TheChangePassword
  },
  watch: {
    hasToChangePassword(value) {
      if (value) {
        this.show = true;
      }
    }
  }
}
</script>