import List from "../../views/agents/List";
import Form from "../../views/agents/Form.vue";

const routes = [
  {
    name: "agent",
    path: "/configuration/agents",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.agents',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
  },
	{
		name: "agentsNew",
		path: "/configuration/agents/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
	},
  {
    name: "agentsEdit",
    path: "/configuration/agents/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
  },
];

export default routes;
