<template>
  <button @click="initChat"><i class="fas fa-comment"></i></button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    user: {
      required: true,
    }
  },
  methods: {
    ...mapActions("_chats", ["createNewChatroom"]),
    ...mapGetters('corpochat', ['getChatById', 'getlastCreatedWasCreated']),
    async initChat() {
      let chat = this.getChatById()(this.user.uid)
      if (!chat) {
        try {
          await this.createNewChatroom(this.user.uid)
          this.$store.commit('corpochat/LAST_CREATED', this.user.uid)
        } catch (error) {
          console.log(error)
        }
      } else {
        this.openChat(chat)
      }
    },
    openChat(chat) {
      this.$router.push({
        name: "MessagesWith",
        params: {
          id: chat.id,
          chat: chat
        },
      })
    },
  },
  watch: {
    getlastCreatedWasCreated(value) {
      if (value) {
        setTimeout(() => {
          const chat = this.getChatById()(value)
          if (chat) {
            this.$store.commit('corpochat/LAST_CREATED', '')
            this.$store.commit('corpochat/LAST_CREATED_WAS_CREATED', false)
            this.openChat(chat)
          }
        }, 1000)
      }
    }
  }
}
</script>

<style scoped>
button {
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--blue-grey);
}
</style>