<template>
  <div class="numpad" ref="numpad">
    <button class="info" :class="{ 'active' : button.active }" @click="sendTone(button.value)" v-for="button in buttons" :key="button.value">{{ button.value }}</button>
  </div>
</template>

<script>
const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;
import Tone from "@/plugins/tone";
import {mapGetters} from 'vuex';
export default {
  name : "TheNumpad",
  props : {
    janus: {
      required: true,
    },
  },
  data() {
    return {
      buttons : [
        {value: "1", active : false, f1: 697, f2: 1209 },
        {value: "2", active : false, f1: 697, f2: 1336 },
        {value: "3", active : false, f1: 697, f2: 1477 },
        {value: "4", active : false, f1: 770, f2: 1209 },
        {value: "5", active : false, f1: 770, f2: 1336 },
        {value: "6", active : false, f1: 770, f2: 1477 },
        {value: "7", active : false, f1: 852, f2: 1209 },
        {value: "8", active : false, f1: 852, f2: 1336 },
        {value: "9", active : false, f1: 852, f2: 1477 },
        {value: "*", active : false, f1: 941, f2: 1209 },
        {value: "0", active : false, f1: 941, f2: 1336 },
        {value: "#", active : false, f1: 941, f2: 1477}
      ],
      dtmf: new Tone(new AudioContext(), 350, 440),
    }
  },
  methods : {
    pressedKey(value) {
      const button = Array.from(this.$refs.numpad.querySelectorAll('button')).find(button => button.innerHTML == value);
      if (button) {
        button.classList.add('active');
        this.playTone(value);
        setTimeout(() => {
          button.classList.remove('active');
        }, 250)
      }
    },
    sendTone(value) {
      this.playTone(value);
      if (this.janus.getIsInCall()) {
        this.janus.sendDtmf(value);
      }
      this.$emit('keyClicked', value)
    },
    playTone(value) {
      const frequencyPair = this.buttons.find(button => button.value === value)
      if (!frequencyPair) {
        return;
      }

      this.dtmf.freq1 = frequencyPair.f1;
      this.dtmf.freq2 = frequencyPair.f2;

      if (this.dtmf.status == 1) {
       this.dtmf.stop();
      }

      if (this.dtmf.status == 0) {
        this.dtmf.start();
      }

      setTimeout(() => {
        this.dtmf.stop();
      }, 250);
    }
  }, 
  computed: {
    ...mapGetters({
      interactWithPage: 'getInteractWithPage'
    })
  }
}
</script>

<style scoped>
.numpad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--column);
  grid-auto-rows: minmax(10px, auto);
  margin-top: var(--column);
}
</style>