<template>
  <el-form
    :model="form"
    label-position="top"
    class="form"
    size="small"
    status-icon
  >
    <el-form-item :label="$t('contacts.name')">
      <el-input :disabled="!canEdit" v-model="user.name"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.lastname')">
      <el-input :disabled="!canEdit" v-model="user.lastname"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.phone')">
      <el-input v-model="user.phone"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.extension')">
      <el-input :disabled="!canEdit" v-model="user.extension"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.email')">
      <el-input :disabled="!canEdit" v-model="user.email"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.props.department')">
      <el-input :disabled="!canEdit" v-model="user.department"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.props.phone_one')">
      <el-input :disabled="!canEdit" v-model="user.phone_one"></el-input>
    </el-form-item>    
    <el-form-item :label="$t('contacts.props.phone_two')">
      <el-input :disabled="!canEdit" v-model="user.phone_two"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.props.address')">
      <el-input :disabled="!canEdit" v-model="user.address"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.props.notes')">
      <el-input :disabled="!canEdit"  type="textarea" v-model="user.notes"></el-input>
    </el-form-item>    
    <el-form-item :label="$t('contacts.props.country')" filterable>
      <el-select v-model="user.country" placeholder="Select">
        <el-option v-for="(country, index) in countries" :key="index" :label="country" :value="index"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('contacts.props.company')">
      <el-input :disabled="!canEdit" v-model="user.company"></el-input>
    </el-form-item>
    <el-form-item :label="$t('contacts.props.organization')" v-if="type === 'organization'">
      <el-select v-model="user.organization" placeholder="Select">
        <el-option v-for="organization in organizations" :key="organization.id" :label="organization.name" :value="organization['@id']"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click.native="submitForm">{{$t("common.save")}}</el-button>
      <router-link
          :to="{ name: 'Contacts' }"
          class="el-button el-button--small"
          >{{ $t("common.cancel") }}</router-link
        >
    </el-form-item>
  </el-form>
</template>

<script>
import countries from '@/helpers/countries.json'
import { mapActions, mapGetters } from 'vuex'
export default {
  props : {
    canEdit : {
      type : Boolean,
      required : false,
      default : true
    },
    user : {
      required : false,
      default: () => ({
        name : '',
        lastname : '',
        phone : '',
        extension : '',
        email : '',
        department : '',
        phone_one : '',
        phone_two : '',
        address : '',
        notes : '',
        organization : '',
        country: '',
        company: '',
      }),
    },
    type : {
      required : true,
      default : 'user'
    }
  },
  data() {
    return {
      form : {
        name : this.user.name,
        lastname : this.user.lastname,
        phone : this.user.phone,
        extension : this.user.extension,
        email : this.user.email,
      },
      countries
    }
  },
  methods: {
    ...mapActions('organizations', { fetchOrganizations : 'all' }),
    submitForm() {
      this.$emit("submitForm", this.user)
    }
  },
  computed : {
    ...mapGetters('organizations',{
      organizations : 'getData'
    })
  },
  created() {
    if (this.type === 'organization') {
      this.fetchOrganizations({pagination : false})
    }
  }
}
</script>

<style>

</style>