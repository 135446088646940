import Home from '@/views/Home'

export default [
  {
    name : "Home",
    path : "/home",
    meta : {
      title : 'main-menu.home',
      access_control : ['ROLE_ADMIN','ROLE_USER','ROLE_AGENT','ROLE_MANAGER','ROLE_BASIC', 'ROLE_RECEPTIONIST'],
      isInMenu : true,
      requiresAuth : true,
      active : false,
      type : 'link',
      icon : 'fas fa-home',
      order : 1
    },
    component : Home
  }
]