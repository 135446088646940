const state = {
  chats : [],
  sort : 'date',
  openedMinChats : [],
  lastCreated : null,
  lastCreatedWasCreated : null,
  chatViewMembers : []
}

const getters = {
  getSort : (state) => state.sort,
  getChats : (state) => state.chats,
  getChatById : (state) => (id) => state.chats.find(chat => chat.id === id),
  getOpenedMinChats : (state) => state.openedMinChats,
  getlastCreatedWasCreated : (state) => state.lastCreatedWasCreated,
  getOpenedMinChatById : (state) => (id) => state.openedMinChats.find(chat => chat.id === id),
  getChatViewMembers : (state) => state.chatViewMembers,
  getChatByRoomId: (state) => roomId => state.chats.find(chat => chat.roomId === roomId),
  getChatByUser: (state) => user => state.chats.find(chat => chat.type === 'user' && chat.user?.uid === user.uid),
}

const mutations = {
  SET_CHAT_VIEW_MEMBERS : (state, value) => state.chatViewMembers = value,
  ADD_CHAT : (state, chat) => state.chats.push(chat),
  UPDATE_SORT_BY : (state, value) => state.sort = value,
  ADD_OPENED_MIN_CHAT : (state, chat) => state.openedMinChats.push(chat),
  REMOVE_OPENED_MIN_CHAT : (state, chat) => state.openedMinChats = state.openedMinChats.filter(_chat => _chat.id !== chat.id),
  UPDATE_USER : (state, user) => state.chats = state.chats.map(chat => chat.type === 'user' && chat.user.uid === user.uid && user.fullname ? { ...chat, user : user } : chat),
  UPDATE_CHAT : (state, chat) => state.chats = state.chats.map(c => c.roomId === chat.roomId ? chat : c),
  UPDATE_MIN_CHAT : (state, chat) => state.openedMinChats = state.openedMinChats.map(c => c.roomId === chat.roomId ? {...c, ...chat} : c),
  TOGGLE_IS_MAX_OPENED_MIN_CHAT : (state, chat) => state.openedMinChats = state.openedMinChats.map(c => c.roomId === chat.roomId ? {...c, isMax : !c.isMax, hide : false } : c),
  UPDATE_OPENED_MIN_CHAT_HEIGHT : (state, {id, height}) => state.openedMinChats = state.openedMinChats.map(c => c.id === id ? {...c, height : height } : c),
  UPDATE_OPENED_MIN_CHAT_WIDTH : (state, {id, width}) => state.openedMinChats = state.openedMinChats.map(c => c.id === id ? {...c, width : width } : c),
  TOGGLE_HIDE_ALL_CHATS : (state) => state.openedMinChats = state.openedMinChats.map(chat => {
    chat.hide = !chat.hide
    return chat
  }),
  SET_ALL_CHATS_MIN : (state) => state.openedMinChats = state.openedMinChats.map(chat => {
    chat.isMax = false
    return chat
  }),
  REMOVE_CHAT : (state, id) => state.chats = state.chats.filter(chat => chat.id !== id),
  LAST_CREATED : (state, id) => state.lastCreated = id,
  LAST_CREATED_WAS_CREATED : (state, value) => state.lastCreatedWasCreated = value
}

const actions = {
  listenChats({ rootGetters, dispatch }) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    firebase.firestore().collection('chatrooms').doc('users').collection(user.uid).onSnapshot((snap) => dispatch('manageChats', { snap, type : 'user' }))
    firebase.firestore().collection('usersgroups').doc('users').collection(user.uid).onSnapshot((snap) => dispatch('manageChats', { snap, type : 'group' }))
  },
  manageChats({commit, dispatch, rootGetters}, { snap, type }) {
    const user = rootGetters.getUser
    for (const change of snap.docChanges()) {
      const id = change.doc.id
      const data = change.doc.data()
      const participants = []
      const messagesCount = 0
      const join = (type === 'group') ? data.join : true
      const owner = (type === 'group') ? data.owner : true 
      const favorite = ('favorite' in data) ? data.favorite : false
      const totalParticipants = 0
      const lastMessage = data.lastMessage ?? {}
      const roomId = (type === 'group') ? `@${data.room}` : `${data.room}-`

      if (change.type === "added") {

        if (state.lastCreated === change.doc.id) {
          commit('LAST_CREATED_WAS_CREATED', state.lastCreated)
        }

        const unread = data.unread ? data.unread : 0
        const tmp = rootGetters['_users/getUserByUid'](change.doc.id)
        const _user = (tmp) ? tmp :  {
          uid : change.doc.id,
          fullname : 'Unknow',
          extension : '',
          picture : '',
          status : 'disconnected'
        }
        const name = (type === 'group') ? data.name : _user.fullname
        const chat = { roomId, user : _user, path : 'chats', myself : { id : user.uid } , name, unread, lastMessage, id, type, join, owner, participants, favorite, messagesCount, totalParticipants }

        dispatch('_chats/initChatroom', chat, { root : true }).then(() => {
          setTimeout(() => {
            const timestamp = chat.join ? new Date().getTime() : chat.lastMessage;
            dispatch("_chats/fetchLastMessagesFromChatroomFromTimestamp", { roomId, timestamp, callback: (messages) => {
              const message = messages.length > 0 ? messages[0] : { date : new Date().getTime() };
              const messagesCount = messages.length ? 1 : 0
              commit('ADD_CHAT', {...chat, type, lastMessage : message, messagesCount, });
              dispatch('_chats/listenDataFromChatroom', { roomId : chat.roomId, callback : (data) => {
                const item = state.chats.find(c => c.roomId === chat.roomId)
                commit('UPDATE_CHAT', { ...item, ...data })
              }}, { root: true })
            }}, { root: true });
          }, 1000)
        })

        setTimeout(() => {
          if (join) {
            dispatch('_chats/listenMessagesFromChatroom', {
              roomId,
              newMessageCallback: (message) => {
                const item = state.chats.find(c => c.roomId === roomId);
                if (item) {
                  item.lastMessage = message
                  item.messagesCount++
                  commit('UPDATE_CHAT', item)
                  commit('UPDATE_MIN_CHAT', item)
                }
              }
            }, { root : true })
          }
        }, 1000)
      }

      if (change.type === "modified") {
        const chat = state.chats.find(chat => chat.id === id);
        commit('UPDATE_CHAT', {...chat, unread : data.unread, join, participants, favorite  })
        commit('UPDATE_MIN_CHAT', {...chat, unread : data.unread, join })

        commit("_chats/UPDATE_UNREAD_MESSAGE", { roomId, value: data.unread }, { root: true});

        if (!join) {
          dispatch('_chats/stopListenMessagesFromChatroom', { roomId }, { root : true })
        } else {
          dispatch('_chats/listenMessagesFromChatroom', {
            roomId,
            newMessageCallback: (message) => {
              const item = state.chats.find(c => c.roomId === roomId);
              item.lastMessage = message
              commit('UPDATE_CHAT', item)
              commit('UPDATE_MIN_CHAT', item)
            }
          }, { root : true })
        }
      }

      if (change.type === 'removed') {
        commit('REMOVE_CHAT', id)
        commit('REMOVE_OPENED_MIN_CHAT', id)
      }
    }
  },
  updateUser({ commit }, user) {
    commit('UPDATE_USER', user)
  },
  updateSortBy({commit}, value) {
    commit('UPDATE_SORT_BY', value)
  },
  updateUnreadMessage({ rootGetters }, {id, type = 'group'} ) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    const root = (type === 'group') ? 'usersgroups' : 'chatrooms' 
    firebase.firestore().collection(root).doc('users').collection(user.uid).doc(id).update({
      unread : 0
    })
  },
  addOpenedMinChat({commit}, chat) {
    commit('ADD_OPENED_MIN_CHAT', {...chat, isMax : true, height : 'initial', width : '', hide : false})
  },
  removeOpenedMinChat({commit}, chat) {
    commit('REMOVE_OPENED_MIN_CHAT', chat)
  },
  updateOpenedMinChatHeight({ commit }, { id, height }) {
    commit('UPDATE_OPENED_MIN_CHAT_HEIGHT', { id, height })
  },
  updateOpenedMinChatWidth({ commit }, { id, width }) {
    commit('UPDATE_OPENED_MIN_CHAT_WIDTH', { id, width })
  },
  toggleIsMaxOpenedMinChat({commit}, chat) {
    commit('TOGGLE_IS_MAX_OPENED_MIN_CHAT', chat)
  },
  toggleHideAllChats({ commit }) {
    commit('TOGGLE_HIDE_ALL_CHATS')
  },
  setAllChatsMin({ commit }) {
    commit('SET_ALL_CHATS_MIN')
  },
  sendStartCallgroup({ dispatch, rootGetters }, { roomId, id }) {
    const user = rootGetters.getUser
    return dispatch('_chats/sendSystemMessageToChatroom', {
      roomId: roomId,
      data: {
        name: user.uid,
        messageType: "start_callgroup",
        active : true,
        roomId : id
      }
    }, { root : true })
  },
  toggleFavorite({ rootGetters }, { id, type }) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    const collection = type === 'group' ? 'usersgroups' : 'chatrooms'
    const ref = firebase.firestore().collection(collection).doc('users').collection(user.uid).doc(id)
    ref.get().then((doc) => {
      if (doc.exists) {
        return doc.ref.update({ favorite: !doc.data().favorite });
      }
    })
  },
  async getParticipantsOfChat({ rootGetters, commit }, roomId ) {
    const firebase = rootGetters.getFirebase
    const snap = await firebase.firestore().collection('chats').doc(roomId).collection('participants').limit(3).get()
    commit("SET_CHAT_VIEW_MEMBERS", snap.docs.map(doc => (doc.data())))
  }
}

export default {
  namespaced : 'corpochat',
  state,
  getters,
  mutations,
  actions
}