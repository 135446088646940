<template>
  <el-dialog
  :title="$t('agent-console.buttons.close-session')"
  :visible.sync="show"
  :close-on-click-modal="!loading"
  :close-on-press-escape="!loading"
  :show-close="false"
  width="30%"
  >
  <div v-loading="loading" :element-loading-text='message'>
  <p>{{ $t("agent-console.there-are-still-chats-open-what-do-you-want-to-do-with-them-before-logging-out") }}</p>
    <el-form ref="form" :model="form" label-width="120px" label-position="top" >
      <el-radio-group v-model="form.action">
        <el-form-item><el-radio label="pending" border size="medium">{{ $t("agent-console.mark-chat-as-pending") }}</el-radio></el-form-item>
        <el-form-item><el-radio label="close" border size="medium">{{ $t("agent-console.close-chats") }}</el-radio></el-form-item>
        <el-form-item><el-radio label="none" border size="medium">{{ $t("agent-console.do-nothing") }}</el-radio></el-form-item>
      </el-radio-group>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" @click.native="handleContinue">{{ $t("common.continue") }}</el-button>
    </span>
  </div>
</el-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
  name : "TheConfirmationLogoutModal",
  data() {
    return {
      show : false,
      form : {
        action : 'pending'
      },
      loading : false
    }
  },
  methods : {
    ...mapActions(['doActionBeforeCloseSession', 'listenToAction','doLogout']),
    handleContinue() {
      if (this.form.action === "none") {
        this.doLogout();
        return
      }

      this.loading = true;
      this.doActionBeforeCloseSession({ action : this.form.action, chats : this.chats.map(c => c.data.roomId)})
      .then(() => {
        setTimeout(() => {
          this.loading = false;
          this.show = false;
          this.doLogout();
        }, 10000)
      })
    }
  },
  computed : {
    ...mapGetters(['getActivityList']),
    chats() {
      return this.getActivityList.filter(item => item.active && item.type === 'chat')
    },
    message() {
      return this.$t(`agent-console.action-${this.form.action}`);
    }
  }
}
</script>

<style>

</style>