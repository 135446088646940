import http from './http'

export default {
    getPercentageOfUserLogged(params) {
        return http.get('/reports/get-percentage-of-users-logged', { params })
    },
    getChatsByPhone(phone) {
        return http.get('/chat-engine/reports/chats/getChatsByPhone', { params: { phone} })
    },
    getMessagesOfChat(roomId) {
        return http.get(`/chat-engine/reports/chats/${roomId}/messages`)
    },
    getChats(params) {
        return http.get('/chat-engine/reports/chats', { params })
    },
    getChatsFromClient(params) {
        return http.get(`/chat-engine/reports/getChatsFromClient`, { params })
    }
}