import http from "./http";

let isRefreshing = false;
let token = null;

const getAuthToken = async () => {
    if (!token && !isRefreshing) {
        isRefreshing = true;
        try {
            const response = await http.post('/call-center/auth');
            token = response.data.token;
        } catch (error) {
            throw new Error('Unable to authenticate');
        } finally {
            isRefreshing = false;
        }
    }
    return token;
};

const authInterceptor = async (config) => {
    if(/call-center/.test(config.url)){
        const authToken = await getAuthToken();
        if (authToken) {
            config.headers['CCAuthorization'] = `Bearer ${authToken}`;
        }
    }
    
  return config;
};


http.interceptors.request.use(authInterceptor)

export default {
  async getDepartments(){
    return await http.get('/agents/me');
  },
  async getTemplatesWsp(departmentId, page = 1){
    return await http.get(`/call-center/whatsapp/${departmentId}/templates?page=${page}`);
  },
  async getApplicationId(phone){
    return await http.get(`/call-center/whatsapp/applications?phone=${phone}`);
  }
}


