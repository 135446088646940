<template>
  <el-button @click.native="goBackToCall">
    {{ $t("agent-console.transfer.go-back-to-call")}}
  </el-button>
</template>

<script>
export default {
  props : {
    janus : {
      required : true
    }
  },
  methods : {
    goBackToCall() {
      this.janus.sendDtmf('**')
    }
  }
}
</script>

<style>

</style>