
const state = {
    name : '',
    roomId : '',
    gid : '',
    owner : false,
    theGroupModalMemberVisible : false,
    theGroupModalDeleteVisible : false,
    theGroupModalLeaveVisible : false,
    theGroupModalEdit : false,
    ownerUid : '',
};

const getters = {
    groupMembersIsVisible: state => state.theGroupModalMemberVisible,
    groupDeletesIsVisible: state => state.theGroupModalDeleteVisible,
    groupLeaveIsInvisible: state => state.theGroupModalLeaveVisible,
    groupEditIsVisible: state => state.theGroupModalEdit,
    getGid : state => state.gid,
    getRoomId : state => state.roomId,
    getOwner : state => state.owner,
    getGroupName : state => state.name,
    getOwnerUid : state => state.ownerUid,
};

const mutations = {
	toggleGroupMembers(state) {
        state.theGroupModalMemberVisible = !state.theGroupModalMemberVisible;
    },
    toggleGroupDelete(state) {
        state.theGroupModalDeleteVisible = !state.theGroupModalDeleteVisible;
    },
    toggleGroupLeave(state) {
        state.theGroupModalLeaveVisible = !state.theGroupModalLeaveVisible;
    },
    toggleGroupEdit(state) {
        state.theGroupModalEdit = !state.theGroupModalEdit;
    },
    setGid(state, gid) {
        state.gid = gid;
    },
    setOwner(state, value) {
        state.owner = value;
    },
    setName(state, value) {
        state.name = value;
    },
    setRoomId(state, value) {
        state.roomId = value;
    },
    setOwnerUid (state, value) {
        state.ownerUid = value;
    }
};

const actions = {
    toggleGroupMembersModal({ commit }) {
        commit('toggleGroupMembers');
    },
    toggleGroupDeleteModal({ commit }) {
        commit('toggleGroupDelete');
    },
    toggleGroupLeaveModal({ commit }) {
        commit('toggleGroupLeave');
    },
    toggleGroupEditModal({ commit }) {
        commit('toggleGroupEdit');
    },
    setRommIdOfGroup({ commit }, roomId) {
        commit('setRoomId', roomId);
    },
    setGroupGid({commit}, gid) {
        commit('setGid', gid);
    },
    setOwnerOfGroup({ commit }, value) {
        commit('setOwner', value);
    },
    setNameOfGroup({ commit }, value) {
        commit('setName', value);
    },
    setOwnerUid({commit}, uid) {
        commit('setOwnerUid', uid);
    }
};

export default {
	state,
	getters,
	mutations,
	actions,
};
