const actions = {
  saveAgentMediaServerPerformance({ rootGetters }, data) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    const agent = rootGetters.getAgent

    data.uid = user.uid
    data.email = user.email
    data.organization_id = agent.organization_id
    data.organization_name = agent.organization_name
    return firebase.functions().httpsCallable('saveAgentMediaServerPerformance')(data)
  },
  saveAgentErrorLogin({rootGetters}, data) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    const agent = rootGetters.getAgent

    data.uid = user.uid
    data.email = user.email
    data.number = agent.number
    data.organization_id = agent.organization_id
    data.organization_name = agent.organization_name
    return firebase.functions().httpsCallable('saveAgentErrorLogin')(data)
  },
  saveUserLogin({ rootGetters }) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase

    if (user.organization_session.data_set) { 
      return firebase.functions().httpsCallable('saveUserLogin')({
        uid : user.uid,
        organization_id : user.organization_session.id,
        data_set : user.organization_session.data_set,
        uniqueid : new Date().getTime() + '' + user.uid,
        event : 'login',
        fullname : user.fullname,
        email: user.email
      })
    }
  }
}

export default {
  namespaced: true,
  actions
}