import axios from 'axios';
import http from './http';
import i18n from '../i18n'
import store from "../store";
import { MessageBox } from 'element-ui'
const messages = i18n.messages[i18n.locale]
import { jsonToFormData } from './utils'

const getAuthToken = () => localStorage.getItem('osticket_token');

const authInterceptor = (config) => {
	const token = getAuthToken();
	if (token) {
		config.headers['Osticket-Authorization'] = `Bearer ${token}`;
	}
	return config;
}

let tries = 0

let cancelFetch = null;

http.interceptors.request.use(authInterceptor);
http.interceptors.response.use(undefined, (error) => {
	const originalRequest = error.config;

	if (originalRequest === undefined) {
		return Promise.reject(error)	
	}

	if (/mail-center/.test(originalRequest.url) && !originalRequest._retry) {
		if (error.response.status === 403) {
			MessageBox.alert(error.response.data.error, {
				confirmButtonText: messages.common.continue,
				distinguishCancelAndClose: true,
				center: true,
			})
		}

		if (error.response.status === 401 && tries < 5) {
			localStorage.removeItem('osticket_token');
			originalRequest._retry = true;
			tries++
			return http.post('/mail-center/auth').then((response) => {
				localStorage.setItem('osticket_token', response.data.token);
				http.defaults.headers.common['Osticket-Authorization'] = 'Bearer ' + response.data.token;
				http.get('/mail-center/auth/me').then((res) => {
					localStorage.setItem('osticket_staff', JSON.stringify(res.data));
					store.commit("SET_STAFF", res.data);
				});

				return http(originalRequest);
			});
		}
	}
	return Promise.reject(error)
});

export default {
	doAuth() {
		return http.post('/mail-center/auth');
	},
	getUserData() {
		return http.get('/mail-center/auth/me');
	},
	fetchTickets(params) {
		if (cancelFetch) {
			cancelFetch.cancel()
		}

		cancelFetch = axios.CancelToken.source()

		return http.get(`/mail-center/tickets`, {
			cancelToken: cancelFetch.token,
			params
		});
	},
	fetchClosedTickets(dateInit = null, dateEnd = null) {
		const params = (!dateInit && !dateEnd) ? {} : { timestampInit: dateInit, timestampEnd: dateEnd };
		return http.get('/mail-center/tickets?sort=desc', {
			params: params
		});
	},
	fetchTicketByNumber(ticketNumber) {
		return http.get(`/mail-center/tickets/${ticketNumber}`);
	},
	fetchEntriesByTicketId(ticketId, page){
		return http.get(`/mail-center/tickets/${ticketId}/entries/?page=${page}`);
	},
	fetchEventsByTicketId(ticketId, page){
		return http.get(`/mail-center/tickets/${ticketId}/events/?page=${page}`);
	},
	fetchDataToUpdateTheTicket() {
		return http.get(`/mail-center/ticket-data-avaliable`);
	},
	fetchTicketsByEmail(email) {
		return {
			request: http.get(`/mail-center/tickets?email=${email}` )
		}
	},
	downloadFile(key, signature) {
		return http.get(`/mail-center/files`, {
			params: {
				key: key,
				signature: signature
			},
		});
	},
	downloadFiles(files) {
		return http.get(`/mail-center/multiple-files`, {
			params: {
				files: files
			},
			responseType: 'arraybuffer'
		});
	},
	updateTicket(ticketId, update, id, reason) {
		const data = {
			'staff_id': (update === 'assignee') ? id : 0,
			'department_id': (update === 'transfer') ? id : 0,
			'status_id': (update === 'change-status') ? id : 0,
			'priority_id': (update === 'change-priority') ? id : 0,
			'sla_id': (update === 'change-sla') ? id : 0, 
			'body': reason,
			'team_id': (update === 'team') ? id : 0
		}
		return http.put(`/mail-center/tickets/${ticketId}/${update}`, data, {
			headers: {
				'Content-Type': 'application/json'
			}
		});
	},
	answerTicket(ticketId, body, response, attachments, type, title, signature) {
		const headers = {
			'Content-Type': 'multipart/form-data',
		}

		const form = new FormData();

		form.append('type', type);
		form.append('body', body);
		form.append('title', title);
		form.append('response', response);
		form.append('signature', signature);

		for (var i = 0; i < attachments.length; i++) {
			form.append('files[' + i + ']', attachments[i]);
		}

		return http.post(`/mail-center/tickets/${ticketId}/thread/response`, form, { headers: headers });
	},
	createNewTicket(data) {
		const form = jsonToFormData(data)
		const headers = {
			'Content-Type': 'multipart/form-data',
		}

		return http.post(`/mail-center/tickets?showTicket=false`, form, { headers : headers });
	},
	fetchDataToCreateANewTicket() {
		return http.get(`/mail-center/ticket-data-creation`);
	},
	fetchCannedResponseParsed(ticketId, cannedId) {
		return http.get(`mail-center/tickets/${ticketId}/canned-response/${cannedId}`);
	},
	createUser(user) {
		return http.post(`mail-center/users`, user)
	},
	fetchUser(userId, queryParams) {
		return http.get(`mail-center/users/${userId}`, {
			params: queryParams
		})
	},
	multipleUpdate(data) {
		return http.put(`mail-center/tickets/multiple`, data)
	},
	fetchContactInformation() {
		const FORM_ID = 1;
		return http.get(`mail-center/forms/${FORM_ID}`)
	},
	fetchOrganizationForm() {
		const FORM_ID = 4;
		return http.get(`mail-center/forms/${FORM_ID}`)
	},
	searchClient(value) {
		return http.get(`mail-center/users?query=${value}&paginated=false`)
	},
	fetchUsers(params) {
		return http.get(`mail-center/users`, {params});
	},
	updateUser(user) {
		return http.put(`mail-center/users/${user.id}`, user)
	},
	resetPassword(userId, data) {
		return http.put(`mail-center/users/${userId}/reset-password`, data)
	},
	fetchOrganizations(params) {
		return http.get(`mail-center/organizations`, {params});
	},
	createOrganization(organization) {
		return http.post(`mail-center/organizations`, organization)
	},
	fetchOrganization(id) {
		return http.get(`mail-center/organizations/${id}`)
	},
	updateOrganization(organization) {
		return http.put(`mail-center/organizations/${organization.id}`, organization)
	},
	fetchFormsByHelpTopic(helpTopic) {
		return http.get(`mail-center/forms-by-help-topic/${helpTopic}`);
	},
	updateFormsFields(ticketId, data) {
		return http.put(`mail-center/tickets/${ticketId}/update-forms-fields`, data);
	},
	addCollaboratorsToTicket(ticketId, user_id) {
		return http.post(`mail-center/tickets/${ticketId}/collaborator`, { user_id });
	},
	removeCollaboratorFromTicket(ticketId, collaboratorId) {
		return http.delete(`mail-center/tickets/${ticketId}/collaborator/${collaboratorId}`)
	},
	fetchAgentMailMetrics() {
		return http.get('mail-center/reports/dashboard')
	},
	fetchAdvanceFiltersFields() {
		return http.get('mail-center/get-advance-search-fields')
	},
	fetchDisplayImage({ key, signature}) {
		return http.get(`/mail-center/files/display`, {
			params: { 
				key, 
				signature 
			}
		});
	}
};

