<template>
  <div class="message" :class="{'is-myself' : isMyself, 'join-videocall' : message.messageType === 'start_callgroup' }" :data-id="message.id">
    <the-system-message v-if="isSystemMessage" :message="message"></the-system-message>
    <div v-if="message.messageType === 'start_callgroup'">
      <TheVideocallButton :message="message"></TheVideocallButton>
    </div>
    <p v-if="isConsideredText && !isSystemMessage" class="mb-0 text" v-text="text" ></p>
    <div v-if="message.type === 'list'" class="list">
      <p class="text">{{ message.payload.body }}</p>
      <ul v-for="(items, index) in message.payload.items" :key="index">
        <li v-for="item in items.options" :key="item.postbackText" class="text"> {{ item.title }}</li>
      </ul>
    </div>
    <download-button v-if="message.type === 'image' || message.type === 'file'" :url="file.url" :name="file.name" :file="message" class="download" />
    <a v-if="message.type == 'link'" :href="message.text">{{ message.text }}</a>
    <div>
      <img v-if="message.type == 'image'" class="image" :src="url" @click="showPreviewImage=true">
      <video v-if="message.type == 'video'" :src="url" controls class=""></video>
      <div v-if="message.type === 'file'" class="file">
        <i :class="fileType"></i>
        <p class="mb-0 file-name">{{ file.name }}</p>
      </div>
      <audio v-if="message.type == 'audio'" :src="message.url" controls>
        <source :src="url" />
      </audio>
      <div v-if="message.type === 'location'">
        <a :href="`https://www.google.com/maps/search/?api=1&query=${location}`" target="__blank">
          <img :src="locationImg" style="width: 100%;"/>
        </a>
      </div>
      <p v-if="message.payload?.caption" class="mb-0"> {{ message.payload.caption  }}</p>
    </div>
    <el-dialog top="5vh" class="preview-image-dialog" fullscreen v-if="message.type == 'image'" :visible.sync="showPreviewImage">
      <div class="dialog-header">
        <download-button v-if="message.type === 'image' || message.type === 'file'" :url="file.url" :name="file.name" :file="message" class="download-from-preview" />
      </div>
      <div class="preview-image-container" @mousemove="handlePreviewMouseMove" @mouseleave="resetPreviewZoom">
        <img class="preview-image zoom-image" :src="url">
      </div>
      
    </el-dialog>
  </div>
</template>

<script>
import DownloadButton from "./DownloadButton"
import TheSystemMessage from './TheSystemMessage'
import TheVideocallButton from "./TheVideocallButton";
export default {
  props: {
    message: {
      required: true
    },
    isMyself: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data(){
    return {
      showPreviewImage: false
    }
  },
  components: {
    DownloadButton,
    TheSystemMessage,
    TheVideocallButton
  },
  computed: {
    fileType() {
      if (!this.message.contentType) return "fas fa-file";

      if (this.message.contentType === "application/pdf") {
        return "fas fa-file-pdf";
      }

      if (this.message.contentType === "text/csv") {
        return "fas fa-file-csv";
      }
      
      if (this.message.contentType === "text/plain") {       
        return "fas fa-file-alt";
      }

      const spreedSheets = ["application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

      const presenstations = ["application/vnd.ms-powerpoint","application/vnd.openxmlformats-officedocument.presentationml.presentation"];

      const docs = ["application/msword",]
      
      if (spreedSheets.includes(this.message.contentType)) return "fas fa-file-excel";

      if (presenstations.includes(this.message.contentType)) return "fas fa-file-powerpoint";

      if (docs.includes(this.message.contentType)) return "fas fa-file-word";

      return "fas fa-archivo";
    },
    location() {
      if(this.message.version){
        return this.message.payload.latitude + ',' + this.message.payload.longitude;
      }
      return this.message.text.latitude + ',' + this.message.text.longitude;
    },
    locationImg() {
      return `https://maps.googleapis.com/maps/api/staticmap?center=${this.location}&key=${process.env.VUE_APP_GOOGLE_API}&size=300x300&zoom=16&markers=color:red|${this.location}`
    },
    text() {
      if (this.message.version) {
        if (this.message.type === 'button_reply' || this.message.type === 'list_reply') {
          return this.message.payload.title
        }

        if (this.message.type === 'quick_reply') {
          if (this.message.payload.content) {
            return this.message.payload.content.text
          }
        }

        return this.message.payload.text
      }

      return this.message.text
    },
    file() {
      if (this.message.version) {
        return { name: this.message.payload.filename, url: this.message.payload.url }
      }

      return { name: this.message.filename, url: this.message.text }
    },
    url() {
      if (this.message.version) {
        return this.message.payload.url
      }

      return this.message.text
    },
    isSystemMessage() {
      if (!this.message.version) {
        return this.message.user_id === -1
      }
      return this.message.user.id === -1
    },
    isConsideredText() {
      return this.message.type === 'text' || this.message.type === 'button_reply' || this.message.type === 'quick_reply' || this.message.type === 'list_reply'
    }
  },
  methods: {
    handlePreviewMouseMove(event) {
      const imageContainer = event.currentTarget;
      const zoomImage = imageContainer.querySelector('.zoom-image');

      const rect = imageContainer.getBoundingClientRect();
      const offsetX = event.clientX - rect.left;
      const offsetY = event.clientY - rect.top;

      const xPercent = (offsetX / rect.width) * 100;
      const yPercent = (offsetY / rect.height) * 100;

      zoomImage.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      zoomImage.style.transform = 'scale(2)'; // Ajusta la escala según lo necesites
    },
    resetPreviewZoom(event) {
      const zoomImage = event.currentTarget.querySelector('.zoom-image');
      zoomImage.style.transform = 'scale(1)';
      zoomImage.style.transformOrigin = 'center center';
    }
  }
}
</script>
<style>
.preview-image-dialog.el-dialog__wrapper{
  overflow: hidden !important;
}
.preview-image-dialog .el-dialog{
  /* width: fit-content !important; */
  background: rgba(0,0,0,0.8);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
} 
.preview-image-dialog .el-dialog__header{
  padding: 0 !important;
}
.preview-image-dialog .el-dialog__body{
  padding: 0 !important;
}
.preview-image-dialog .el-dialog__close{
  font-size: 30px !important;
  color: #fff !important

} 
.dialog-header{
  position: absolute;
  top: 12px;
  right: 70px;
  padding: 0;
  background: 0 0;
  border: none;
  outline: 0;
  cursor: pointer;
  font-size: 30px;
  color: #fff;
}
</style>
<style scoped>
.image {
  width: 90%;
}

audio,
video {
  margin: 0 var(--column);
}
  img {
    border-radius: 12.5px;
  }

  audio,
  video {
    margin: 0 var(--column);
  }

video {
  width: 100%;
}

.file,
.message {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}

.file {
  margin: 0 calc(var(--column) / 2);
}

.file i {
  font-size: 28px;
  display: block;
}

.file-name {
  margin-bottom: 0 !important;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  margin: 0 10px;
}

.text {
  /* white-space: pre-wrap; 
    word-wrap: break-word; */
  font-family: inherit;
  font-size: 14px;
  overflow-wrap: break-word;
  word-break: break-word;
  white-space: pre-wrap;
  line-height: 19px;
}

.chat-little .text {
  max-width: 230px;
}

.list ul {
  margin-top: 8px;
/*   .join-videocall {
    padding: var(--column);
    background-color: red;
  } */
}

.preview-image{
  width: auto;
  border-radius: 0;
  height: 600px;
}
.preview-image-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom-image {
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.2s ease;
  object-fit: contain;
  cursor: zoom-in;
}

</style>