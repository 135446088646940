<template>
    <div class="not-found">
        <div>
            <p>No pudimos encontrar la pagina que estabas buscando</p> 
            <p>Haz click <a href="" @click.prevent="$router.go(-1)">aquí</a> para volver a la pagina anterior o haz click <router-link :to="(isAuthenticated() ? '/home' : '/login')">aqui</router-link> para volver a la pagina de inicio</p>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
export default {
    name : 'NotFound',
    methods : {
        ...mapGetters(['isAuthenticated'])
    }
}
</script>

<style scoped>
    .not-found {
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100vh;
        text-align:center
    }
</style>