import List from "@/views/organizations/List.vue"
import Form from '@/views/organizations/Form.vue'

const routes = [
  {
    name: "organization",
    path: "/configuration/organizations",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.organizations',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
	{
		name: "organizationsNew",
		path: "/configuration/organizations/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    }
	},
  {
    name: "organizationsEdit",
    path: "/configuration/organizations/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    },
    props: true,
  },
];

export default routes;
