import createStore from "../helper";
import organizationapi from '@/api/organizations'

const store = createStore('organizations');

const getters = {
  getData: (state) => state.data.map((organization) => {
    if (organization.logo) {
      organization.logoAlt = process.env.VUE_APP_API_HOST.replace('/api', '/img/logo') + "/" + organization.logo;
    }
    return organization;
  }),
}

const actions = {
  changeLogo({ commit }, { file, organization }) {
    return organizationapi.changeLogo(organization.id, file)
      .then(({ data }) => {
        organization.logo = data.logo;
        commit("UPDATE_DATA", organization);
      });
  },
  removeLogo({ commit }, organizationId) {
    return organizationapi.removeLogo(organizationId)
      .then(({ data }) => {
        commit("UPDATE_DATA", data);
      });
  }
}

store.getters = {...store.getters, ...getters };
store.actions = {...store.actions, ...actions };

export default store;