import AppView from '../../views/AppLayout'
import TheTicket from '../../views/mail-center/TheTicket'
import TheNewTicket from '../../views/mail-center/TheNewTicket'
import TheMainMailCenter from '../../views/mail-center/TheMainMailCenter'
import TheMainMailCenterCrud from '../../views/mail-center/TheMainMailCenterCrud'
import TheUserEdit from '../../views/mail-center/TheUserEdit'
import TheUserNew from '../../views/mail-center/TheUserNew'
import TheOrganizationNew from '../../views/mail-center/TheOrganizationNew'
import TheOrganizationEdit from '../../views/mail-center/TheOrganizationEdit'
import store from '@/store'

const beforeEnter = (to, from, next) => {
  const user = store.getters.getUser;
  const agent = store.getters.getAgent;

  if (!hasAccessToMailCenter(user, agent)) {
    next(from.fullPath)
  } else {
    next();
  }
}

const hasAccessToMailCenter = (user, agent) => {
  if (!user || !agent) {
    return false;
  }

  if (Object.keys(user).length === 0) {
    return false;
  }

  if (user.roles.includes('ROLE_ADMIN')) {
    return true;
  }

  if (agent) {
    if (agent.mail && user.roles.includes('ROLE_AGENT')) {
      return true;
    }
  }

  return false;
}

export default [
  {
    name: "Mail Center",
    path: "/tickets",
    meta: {
      isInMenu: true,
      title: 'main-menu.tickets.title',
      icon: 'fas fa-inbox',
      type: 'title',
      order: 7,
      custom_access_control : hasAccessToMailCenter,
      beforeEnter: beforeEnter
    },
    components : { 
			appView : AppView
		},
    children : [
      {
        name : "TicketNew",
        path : "/tickets/new",
        component : TheNewTicket,
        meta : {
          requiresAuth : true,
          isInMenu : true,
          title : 'main-menu.tickets.create-new-ticket',
          custom_access_control : hasAccessToMailCenter
        },
        beforeEnter: beforeEnter
      },
      {
        name : "TicketsAssignedToMe",
        path : "/tickets",
        meta : {
          title : 'tickets.tickets',
          requiresAuth : true,
          isInMenu: true,
          custom_access_control : hasAccessToMailCenter,
          query: {
            status: 'open'
          }
        },
        components: {
          default : TheMainMailCenter
        },
        beforeEnter: beforeEnter
      }/* ,
      {
        name : "TicketsOpened",
        path : "/tickets",
        meta : {
          requiresAuth : true,
          isInMenu : true,
          title : 'main-menu.tickets.openeds',
          custom_access_control : hasAccessToMailCenter,
          query: {
            status: 'open'
          }
        },
        components: {
          default : TheMainMailCenter
        },
        beforeEnter: beforeEnter
      },
      {
        name : "TicketsClosed",
        path : "/tickets",
        meta : {
          requiresAuth : true,
          isInMenu : true,
          title : 'main-menu.tickets.closed',
          custom_access_control : hasAccessToMailCenter,
          query: {
            status: 'closed'
          }
        },
        components: {
          default : TheMainMailCenter
        },
        props : {
          default : true,
          status : 'closed'
        },
        beforeEnter: beforeEnter
      } */,
      {
        name : "ticket",
        path : "/ticket/:ticketNumber",
        meta : {
          requiresAuth : true,
          custom_access_control : hasAccessToMailCenter
        },
        components: {
          default : TheTicket
        },
        props : {
          default : true
        },
        beforeEnter: beforeEnter
      },



      {
        name : "mailcenter.newUser",
        path : "/mail-center/users/new",
        meta : {
          requiresAuth : true,
          custom_access_control : hasAccessToMailCenter
        },
        components: {
          default : TheUserNew
        }
      },
      {
        name : "mailcenter.usersList",
        path : "/mail-center/:tab",
        meta : {
          isInMenu: true,
          requiresAuth : true,
          title : 'main-menu.tickets.admin',
          custom_access_control : hasAccessToMailCenter
        },
        components: {
          default : TheMainMailCenterCrud
        },
        props : {
          default : true,
          tab: 'users'
        },
        beforeEnter: beforeEnter
      },
      {
        name : "mailcenter.editUser",
        path : "/mail-center/users/:id",
        meta : {
          requiresAuth : true,
          custom_access_control : hasAccessToMailCenter
        },
        components: {
          default : TheUserEdit
        },
        props : {
          default : true,
          user : null,
        },
        beforeEnter: beforeEnter
      },
      {
        name : "mailcenter.organizationList",
        path : "/mail-center/:tab",
        meta : {
          requiresAuth : true,
          access_control : ['ROLE_ADMIN'],
        },
        components: {
          default : TheMainMailCenterCrud
        },
        props : {
          default : true,
          tab: 'organizations'
        },
      },
      
      {
        name : "mailcenter.newOrganization",
        path : "/mail-center/organizations/new",
        meta : {
          requiresAuth : true,
          access_control : ['ROLE_ADMIN'],
        },
        components: {
          default : TheOrganizationNew
        }
      },
      {
        name : "mailcenter.organizationEdit",
        path : "/mail-center/organizations/:id",
        meta : {
          requiresAuth : true,
          access_control : ['ROLE_ADMIN'],
        },
        components: {
          default : TheOrganizationEdit
        },
        props : {
          default : true,
          organzation: null,
        },
      },
    ]
  }
]