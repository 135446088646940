<template>
  <div>
    <div style="padding: 0 var(--column)">
      <the-search-input v-if="searchBar" v-model="value" :style="{ 'display' : isMax ? 'block' : 'none'}" :placeholder="$t('common.search')" class="mb-3"></the-search-input>
    </div>
    <ul v-if="showUsers">
      <li v-for="user in users" :key="user.uid" @click="initChat(user)">
       <el-tooltip effect="dark" :content="user.fullname" placement="right">
        <user-item :user="user" class="chat-item" :show-fullname="isMax" ></user-item>
        </el-tooltip>
      </li>
    </ul>
    <div v-else>
      <ul>
        <li v-for="chat in favorites" :key="chat.roomId"><the-message-item :chat="chat" class="chat-item" :full-item="isMax"></the-message-item></li>
        <li v-for="chat in chats" :key="chat.roomId"><the-message-item :chat="chat" class="chat-item" :full-item="isMax"></the-message-item></li>
      </ul>
      <p v-if="favorites.length === 0 && chats.length === 0 && value === ''" class="text-center no-activity">{{ $t("chats.no-activity") }}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserItem from '@/components/common/UserItem'
import TheMessageItem from '@/components/messages/TheMessageItem'
import TheSearchInput from '@/components/html/TheSearchInput'
export default {
  data() {
    return {
      value : ''
    }
  },
  props : {
    showUsers : {
      required : true,
      default : false
    },
    searchBar : {
      required : true,
      default : false
    },
    isMax : {
      required : true,
      default : false,
    }
  },
  computed : {
    ...mapGetters({
      tmpusers : '_users/getUsers'
    }),
    ...mapGetters('corpochat', {
      tmpchats : 'getChats',
      sort : 'getSort',
      getOpenedMinChatById : 'getOpenedMinChatById',
      getlastCreatedWasCreated : 'getlastCreatedWasCreated'
    }),
    users : function() {
      return this.tmpusers.filter(user => !this.value || user.fullname.toLowerCase().indexOf(this.value.toLowerCase()) > -1)
    },  
    favorites : function() {
      return this.tmpchats
      .filter(chat => chat.favorite)
      .filter(chat => !this.value || chat.name.toLowerCase().indexOf(this.value.toLowerCase()) > -1)
    },
    chats : function() {
      return this.tmpchats
        .filter(chat => chat.favorite === false)
        .filter(chat => chat.messagesCount > 0)
        .filter(chat => {
          return !this.value || chat.name.toLowerCase().indexOf(this.value.toLowerCase()) > -1
        })
        .sort((a,b) => {          
          if (this.sort === 'date') {
            const date1 = new Date(a.lastMessage.date)
            const date2 = new Date(b.lastMessage.date)
           if (date1 > date2) return -1;
           if (date1 < date2) return 1;
           return 0;
         }

        const textA = a.name.toUpperCase()
        const textB = b.name.toUpperCase()
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
      })
    },
    showName : function() {
      return this.isMax;
    }
  },
  components : {
    UserItem,
    TheMessageItem,
    TheSearchInput
  },
  methods : {
    ...mapGetters('corpochat', ['getChatById']),
    ...mapActions('_chats', ['createNewChatroom']),
    ...mapActions('corpochat', ['addOpenedMinChat', 'toggleIsMaxOpenedMinChat']),
    async initChat(user) {
      let chat = this.getChatById()(user.uid)

      if (!chat) {
        try {
          this.$message(this.$t("chats.the-chat-will-open-shortly", { name: user.fullname}), { duration : 6000 });
          await this.createNewChatroom(user.uid)
          this.$store.commit('corpochat/LAST_CREATED', user.uid)
        } catch (error) {
          console.log(error)
        }
      } else {
        this.openChat(chat)
        this.$emit('toggleShowUsers')
      }
    },
    openChat(chat) {
      if (/^\/messages/.test(this.$router.currentRoute.path)) {
        this.$router.push({
          name: "MessagesWith",
          params: {
            id: chat.id,
            chat : chat
          },
        })
      } else {
        if (!this.getOpenedMinChatById(chat.id)) {
          this.addOpenedMinChat(chat)
        } else {
          this.toggleIsMaxOpenedMinChat(chat)
        }
      }
    }
  },
  watch : {
    getlastCreatedWasCreated(value) {
      if (value) {
        setTimeout(() => {
          const chat = this.getChatById()(value)
          if (chat) {
            this.$store.commit('corpochat/LAST_CREATED','')
            this.$store.commit('corpochat/LAST_CREATED_WAS_CREATED',false)
            this.$emit('toggleShowUsers')
            this.openChat(chat)
          }
        }, 1500)
      }
    }
  }
}
</script>

<style scoped>

ul {
  margin-bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  list-style: none;
}

ul li {
  margin-bottom: var(--column);
  cursor: pointer;
  padding-right: 10px;
}

ul li:last-child {
  margin-bottom: 0;
}

.search {
  margin: var(--column);

}

ul :deep(.chat-item)  {
  color: var(--blue-grey);
}

.light ul :deep(.chat-item)   {
  color: var(--light-menu-links-color);
}

.clasic .search :deep(input) {
  background-color: rgb(243,243,243)
}

.min .no-activity {
  display: none;
}

</style> 