import { render, staticRenderFns } from "./TheIncomingCall.vue?vue&type=template&id=228876ba&scoped=true&"
import script from "./TheIncomingCall.vue?vue&type=script&lang=js&"
export * from "./TheIncomingCall.vue?vue&type=script&lang=js&"
import style0 from "./TheIncomingCall.vue?vue&type=style&index=0&id=228876ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228876ba",
  null
  
)

export default component.exports