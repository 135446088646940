import { render, staticRenderFns } from "./VideocallButton.vue?vue&type=template&id=fb4db99e&scoped=true&"
import script from "./VideocallButton.vue?vue&type=script&lang=js&"
export * from "./VideocallButton.vue?vue&type=script&lang=js&"
import style0 from "./VideocallButton.vue?vue&type=style&index=0&id=fb4db99e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb4db99e",
  null
  
)

export default component.exports