<template>
  <div class="crud form main-view">
  <el-form size="small" label-position="right" target="#app-main-content" ref="ruleForm" :rules="rules" :model="form" v-loading="saving || loading || updating" v-if="status === 0"  :element-loading-text="loadingtext">
    <h2>{{ title }}</h2>
    <el-form-item :label="$t('common.name')" required prop="name">
      <el-input v-model="form.name"></el-input>
    </el-form-item>
    <el-form-item :label="$t('common.description')" required prop="description">
      <el-input type="textarea" :rows="3" show-word-limit maxlength="255" v-model="form.description"></el-input>
    </el-form-item>

    <div class="row">
      <el-form-item :label="$t('contact-me.chat-center.actionIfMatch')" required prop="actionIfMatch" class="col-6">
        <el-select v-model="form.actionIfMatch">
          <el-option v-for="(action, index) in actions" :key="index" :label="$t('common.' + action.toLocaleLowerCase())" :value="action"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t(`common.${form.actionIfMatch}`)" prop="bot" v-if="!['nothing', 'close'].includes(form.actionIfMatch)" class="col-6">
        <el-select v-model="form.actionIfMatchValue">
          <el-option v-for="(action, index) in actionsIfMatch" :key="index" :label="action.name" :value="action.id"></el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="row">
      <el-form-item :label="$t('contact-me.chat-center.actionIfNotMatch')" required prop="actionIfNotMatch" class="col-6">
        <el-select v-model="form.actionIfNotMatch">
          <el-option v-for="(action, index) in actions" :key="index" :label="$t('common.' + action.toLocaleLowerCase())" :value="action"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t(`common.${form.actionIfNotMatch}`)" prop="bot" v-if="!['nothing', 'close','sendmessage'].includes(form.actionIfNotMatch)" class="col-6">
        <el-select v-model="form.actionIfNotMatchValue">
          <el-option v-for="(action, index) in actionsIfNotMatch" :key="index" :label="action.name" :value="action.id"></el-option>
        </el-select>
      </el-form-item>
      <div v-if="form.actionIfNotMatch === 'sendmessage'" class="col-6">
        <el-form-item :label="$t('contact-me.chat-center.notWorkingHoursMessage')" prop="notWorkingHoursMessage"  style="width: 100%">
          <el-input type="textarea" :rows="3" show-word-limit maxlength="255" v-model="form.notWorkingHoursMessage" style="width: 100%"></el-input>
        </el-form-item>
      </div>
    </div>

    <el-button @click="hourOptionSelected = 'general'; addHour()">{{$t('common.add-day')}}</el-button>
    <el-button @click="hourOptionSelected = 'specific'; addHour()">{{$t('common.add-specificDay')}}</el-button>
    
    <div class="hour-wraper">
      <div class="hours-row" v-for="(hour, index) in form.hours" :key="index">
          <el-form-item v-if="!form.hours[index].dateMonth" style="margin-bottom: 0px" :label="$t('common.day')" :prop="'hours.' + index + '.day'">
            <el-select v-model="form.hours[index].day">
              <el-option v-for="(day, index) in days" :key="index" :label="$t('common.' + day.toLocaleLowerCase())" :value="day.toLowerCase()"></el-option>
            </el-select>
          </el-form-item>  
          <el-form-item v-if="form.hours[index].dateMonth" style="margin-bottom: 0px" :label="$t('common.month')" :prop="'hours.' + index + '.day'" >
            <el-select v-model="form.hours[index].monthSelected" @change="getDaysOfMonth(index, form.hours[index].monthSelected)">
              <el-option v-for="(month, index) in months" :key="index" :label="$t('common.' + 'months.' + month.toLocaleLowerCase())" :value="index+1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="form.hours[index].dateMonth" style="margin-bottom: 0px; width: 100px;" :label="$t('common.day')" :prop="'hours.' + index + '.day'">
            <el-select v-model="form.hours[index].selectedDayOfMonth" :disabled="form.hours[index].availableDaysOfMonth.length == 0">
              <el-option v-for="(day, index) in (form.hours[index].availableDaysOfMonth)" :key="index" :label="day" :value="day"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-bottom: 0px" :label="$t('common.hours')" required :prop="'hours.' + index + '.hours'">
            <el-time-picker is-range v-model="form.hours[index].hours" range-separator="-" ></el-time-picker>
          </el-form-item>
          <el-popconfirm
            :confirm-button-text="$t('common.yes')"
            :cancel-button-text="$t('common.no')"
            icon="el-icon-info"
            icon-color="red"
            :title="$t('general-configurations.side-images.are-you-sure-to-delete-this')"
            v-if="form.hours[index].id"
            @confirm="deleteHour(form.hours[index].id, index)"
          >
            <el-button slot="reference" size="small" type="danger" >{{$t('common.delete')}}</el-button>"
          </el-popconfirm>
          <el-button @click.native="removeHour(index)" v-else size="small" type="danger">{{$t('common.delete')}}</el-button>
      </div>
    </div>
    <el-button size="small" @click="$router.go(-1)">{{ $t("common.cancel") }}</el-button>
    <el-button type="primary" size="small" @click.native="submit">{{ $t("common.save") }}</el-button>
  </el-form>
  <div class="message" v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 200"/>
        <img src="@/img/error.svg" v-else/>
        <p> {{ message }} </p>
        <div class="buttons">
          <el-button type="text" @click="$router.go(-1)">{{ $t("common.go-back-to-the-list") }}</el-button>
          <el-button type="text" @click="status = 0; saving = false; loading = false" v-if="status !== 404">{{ $t("common.show-the-form") }}</el-button>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    edit : {
      required : false,
      default : false,
    },
    id : {
      required : false,
      type : String,
      default : "0"
    },
    entity : {
      required : false,
      type : Object
    }
  },
  data() {
    return {
      title: this.id !== '0' ? this.$t('contact-me.chat-center.edit-office-hour') : this.$t('contact-me.chat-center.create-office-hour'),
      form: {
        name: this.entity ? this.entity.name : '',
        description: this.entity ? this.entity.description : '',
        notWorkingHoursMessage: this.entity ? this.entity.notWorkingHoursMessage : '',
        hours: [{
          day: '',
          monthSelected: 1,
          selectedDayOfMonth: 1,
          dateMonth: null,
          availableDaysOfMonth:[],
          hours: [new Date(2016, 9, 10, 8, 0), new Date(2016, 9, 10, 19, 0)]
        }],
        // bot: this.entity && this.entity.bot ? this.entity.bot['@id'] : null,
        actionIfNotMatch: this.entity ? this.entity.actionIfNotMatch : 'nothing',
        actionIfMatch: this.entity ? this.entity.actionIfMatch : 'nothing',
        actionIfNotMatchValue: null,
        actionIfMatchValue: null,
      },
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      actions: ["nothing","dept", "timecondition"],
      rules: {
        name: [
          { required: true, message: 'The name is required', trigger: 'blur' },
        ],
      },
      loading: false,
      saving: false,
      updating: false,
      status: 0,
      showAddHourOptions: false,
      hourOptionSelected: 'general',
    }
  },
  methods: {
    submit() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          return false
        }

        try {
          let id = 0;
          this.saving = true
          this.loading = true
          const actionIfMatch = !['nothing', 'close'].includes(this.form.actionIfMatch) ?  `${this.form.actionIfMatch}_${this.form.actionIfMatchValue}` : this.form.actionIfMatch
          const actionIfNotMatch = !['nothing', 'close'].includes(this.form.actionIfNotMatch) ? `${this.form.actionIfNotMatch}_${this.form.actionIfNotMatchValue}` : this.form.actionIfNotMatch
          if (this.id === "0") {
            const organizationId = this.$store.getters["contactMe/getOrganizationId"];
            const response = await this.$store.dispatch('timeConditions/create', { ...this.form, organization: `/api/organizations/${organizationId}`, actionIfMatch, actionIfNotMatch  })
            id = response.id
          } else {
            id = this.id
            const payload = { ...this.form, actionIfMatch, actionIfNotMatch }
            await this.$store.dispatch('timeConditions/update', { id, payload })
          }

          //this.form.bot = this.form.actionIfNotMatch === 'bot' ? this.form.bot : null;

          const promises = this.form.hours.map(async (workHour) => {
            const fromHour = `${workHour.hours[0].getHours().toString().padStart(2, '0')}:${workHour.hours[0].getMinutes().toString().padStart(2, '0')}`
            const toHour = `${workHour.hours[1].getHours().toString().padStart(2, '0')}:${workHour.hours[1].getMinutes().toString().padStart(2, '0')}`
            const date = workHour.monthSelected + '/' + workHour.selectedDayOfMonth;
            if (!workHour.id) {
  
                return await this.$store.dispatch('timeConditionsHour/create', { day: workHour.day, fromHour, toHour, timeCondition: `/api/time_conditions/${id}`, dateMonth: workHour.dateMonth ? date : null })
              
            }
            const _id = workHour.id
            
            const payload = { day: workHour.day, fromHour, toHour, dateMonth: workHour.dateMonth ? date : null }
            
            return await this.$store.dispatch('timeConditionsHour/update', { id: _id, payload })
          })
          await Promise.all(promises)
          this.status = 200
        } finally {
          this.loading = false
        }
      })
    },
    addHour() {
      this.showAddHourOptions = true;
      console.log(this.hourOptionSelected);
      this.form.hours.push({
        day: this.hourOptionSelected == 'specific' ? null : '',
        monthSelected: 1,
        selectedDayOfMonth: 1,
        dateMonth: this.hourOptionSelected == 'specific' ? new Date() : null,
        availableDaysOfMonth:[...this.daysOfMonth(1)],
        hours: [new Date(2016, 9, 10, 8, 0), new Date(2016, 9, 10, 19, 0)]
      })
    },
    removeHour(index) {
      this.form.hours = this.form.hours.filter((_hour, i) => i !== index)
    },
    async deleteHour(id, index) {
      await this.$store.dispatch('timeConditionsHour/destroy', id)
      this.removeHour(index)
      this.$message({message: this.$t('contact-me.chat-center.the-working-hour-was-removed'), type: 'warning'});
    },
    getDaysOfMonth(index, month){
      const fullYear = new Date().getFullYear();
      const lastDay = new Date(fullYear, month, 0).getDate();
      console.log(month);
      console.log(index);
      let days = [];
      for(let i = 0; i < lastDay; i++){
        days[i] = i+1;
      }
      console.log(days)
      console.log(this.form)
      this.form.hours[index].availableDaysOfMonth = days;
    },
    daysOfMonth(month){
      const fullYear = new Date().getFullYear();
      const lastDay = new Date(fullYear, month, 0).getDate();
      let days = [];
      for(let i = 0; i < lastDay; i++){
        days[i] = i+1;
      }

      return days;
    }
  },
  computed: {
    message() {
      if (this.status === 200) {
        return this.$t("common.saved")
      }

      if (this.status === 404) {
        return this.$t("common.not-found")
      }

      return this.$t("common.not-saved")
    },
    loadingtext() {
      if (this.saving || this.updating) {
        return this.$t('common.saving') 
      }

      if (this.loading) {
        return this.$t('common.loading-please-wait')
      }

      return "";
    },
    departments() {
      return this.$store.getters['departments/getData']
    },
    timeConditions() {
      return this.$store.getters['timeConditions/getData']
    },
    actionsIfNotMatch() {
      if (this.form.actionIfNotMatch === 'dept') {
        return this.departments
      }

      if (this.form.actionIfNotMatch === 'timecondition') {
        if (this.id !== '0') {
          return this.timeConditions.filter(timeCondition => timeCondition.id != this.id)
        } else {
          return this.timeConditions
        }
      }

      return []
    },
    actionsIfMatch() {
      if (this.form.actionIfMatch === 'dept') {
        return this.departments
      }

      if (this.form.actionIfMatch === 'timecondition') {
        if (this.id !== '0') {
          return this.timeConditions.filter(timeCondition => timeCondition.id != this.id)
        } else {
          return this.timeConditions
        }
      }

      return []
    }
    
    
  },
  created() {
    this.$store.dispatch("departments/all", { ["organization.id"] : this.$store.getters["contactMe/getOrganizationId"] })

    if (this.entity) {
      if (!['nothing', 'close'].includes(this.entity.actionIfMatch)) {
        const [actionIfMatch, actionIfMatchValue] = this.entity.actionIfMatch.split("_")
        this.form.actionIfMatch = actionIfMatch
        this.form.actionIfMatchValue = parseInt(actionIfMatchValue)
      }
      if (!['nothing', 'close'].includes(this.entity.actionIfNotMatch)) {
        const [actionIfNotMatch, actionIfNotMatchValue] = this.entity.actionIfNotMatch.split("_")
        this.form.actionIfNotMatch = actionIfNotMatch
        this.form.actionIfNotMatchValue = parseInt(actionIfNotMatchValue)
      }

      this.form.hours = this.entity.timeConditionHours.map((hour) =>  {
        const [hourFrom, minFrom] = hour.fromHour.split(':')
        const [hourTo, minTo] = hour.toHour.split(':')

        const fromHour = new Date()
        fromHour.setHours(hourFrom)
        fromHour.setMinutes(minFrom)
        fromHour.setSeconds(0)

        const toHour = new Date()
        toHour.setHours(hourTo)
        toHour.setMinutes(minTo)
        toHour.setSeconds(0)
        
        return {
          id: hour["@id"].replace('/api/time_condition_hours/', ''),
          monthSelected: hour.dateMonth ? parseInt(hour.dateMonth.split('/')[0]) : 1,
          selectedDayOfMonth: hour.dateMonth ?  parseInt(hour.dateMonth.split('/')[1]) : 1,
          dateMonth: hour.dateMonth ? hour.dateMonth : null,
          availableDaysOfMonth:hour.dateMonth ? [...this.daysOfMonth(parseInt(hour.dateMonth.split('/')[0]))] : [],
          day: hour.day,
          hours: [fromHour, toHour]
        }
      })
      console.log(this.entity.timeConditionHours);
    }
  }
}
</script>

<style scoped>
.hours-row {
  display: inline-flex;
  width: 100%;
  gap: var(--column);
  align-items: flex-end;
}
.notDisplay{
  display: none;
}
.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
}
.hour-wraper-items-container{
  display: flex;
  align-items: flex-end;
  
}
.hour-wraper{
  margin-bottom: 30px;
}
</style>