<template>
  <el-tag class="ml-1" size="small" :type="type"> {{ rule.label }} </el-tag>
</template>

<script>
export default {
    props : {
        rule : {
            required : true
        },
        value : {
            required : true
        }
    },
    data() {
        return {
            type : 'info',
        }
    },
    type() {
        return this.rule.validIf(this.value)
    },
    watch : {
        value(value) {
            this.rule.validator(null, value, (response) => {
                this.type = response === undefined ? 'success' : 'info'
            })
        }
    }
}
</script>

<style>

</style>