const decode = (value) => (decodeURI(escape(value)))

const firstLetter = (value) => (value[0])

const usersWriting = (value) => (value.join())

export {
  decode,
  firstLetter,
  usersWriting
}