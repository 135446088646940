<template>
  <div class="chat-message" :class="userClass">
    <div v-if="haveUserIcon">
      <user-item :user="userItem" :showStatus="false" :showFullname="false" :showEmail="false" v-if="isHiperMeUser" />
      <div class="icon" :class="type" v-else>
          <i :class="iconType"></i>
      </div>
    </div>
    <div class="chat-message-data" :class="{'system' : message.type === 'system'}" :data-id="message.id">
      <div class="background">
        <div v-if="message.answer" class="answer">
          <p v-if="message.answer.user.id">{{ message.answer.user.name }}</p>
          <the-message :is-myself="isMyself" :message="message.answer"></the-message>
        </div>
        <the-message :is-myself="isMyself" :message="message"></the-message>
      </div>
      <div class="info">
        <p class="mb-0 date">{{ message.date | redeableDate(true) }}</p>
        <span v-if="message.user.id > 0 && message.status">
          <i class="fas fa-times" :class="[message.status]" v-if="message.status === 'failed'"></i>
          <i class="fas fa-check" :class="[message.status]" v-if="message.status === 'sent'"></i>
          <i class="fas fa-check-double" :class="[message.status]" v-if="message.status === 'delivered' || message.status === 'read'"></i>
        </span>
      
        <el-dropdown class="dropdown" placement="bottom-start" trigger="click" v-if="canAnswer">
          <span class="el-dropdown-link">
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
             <el-dropdown-item @click.native="answer">{{ $t("chat.answer") }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TheMessage from "./TheMessage"
import UserItem from "@/components/common/UserItem"
export default {
  name : "ChatMessage",
  props : {
    roomId : {
      type : String
    },
    message : {
      type : Object,
      default : () => ({
          text : '',
          date : '',
          user : {},
      })
    },
    myself : {
      type : Object,
      default : () => ({
          id : 0,
          name : '',
      })
    },
    userType : {
      type : String,
      required : false,
      default : 'normal'
    },
    canAnswer : {
      type : Boolean,
      required : false,
      default : false
    }
  },
  components : {
    UserItem,
    TheMessage
  },
  computed : {
    ...mapGetters({
      'user' : 'getUser',
      'users' : '_users/getUsers'
    }),
    iconType() {
      if (this.isBot) {
        return 'fas fa-robot'
      }

      return {
          "fab fa-instagram" : (this.type === 'instagram'),
          "fab fa-whatsapp" : (this.type === 'whatsapp'),
          "fab fa-facebook-f" : (this.type === 'facebook'),
          "fab fa-telegram-plane" : (this.type === 'telegram'),
          "fas fa-comment" : this.type === 'web'
      }
    },
    userClass() { 
      return {
        'myself' : this.message.user.id === this.myself.id && (this.myself.id > 0 || typeof this.myself.id === 'string') || this.myself.id === 0 && this.message.user.id > 0, 
        'system' : this.message.user.id === -1 || this.message.type === "system" || this.message.type === "bot",
        'bot' : this.message.user.id === -2
      }
    },
    messageName() {
      let name = this.message.user.name

      if (this.message.user.name === this.message.user.id) {
        name = (this.message.user.id === this.user.uid) ? this.user.fullname : this.users.find(user => user.uid === this.message.user.id).fullname
      }

      return name
    },
    isMyself() {
      return this.message.user.id === this.myself.id && (this.myself.id > 0 || typeof this.myself.id === 'string') || this.myself.id === 0 && this.message.user.id > 0
    },
    userItem() {
      if (this.isMyself) {
        return this.user
      }

      const user = this.users.find(user => user.uid === this.message.user.id)

      if (user) {
        return user
      }

      return {
        fullname : this.messageName,
      }
    },
    isSystemMessage() {
      if (!this.message.version) {
        return (this.message.type === 'system' || this.message.type === 'bot') && this.message.messageType !== 'start_callgroup'
      }

      return this.message.user.id === -1
    },
    isBot() {
      const { id } = this.message.user
      return id == -2
    },
    haveUserIcon() {
      if (this.message.type === 'system') {
        return false
      }

      const { id } = this.message.user
      return id >= 0 || id === -2 || typeof id === 'string'
    },
    isHiperMeUser() {
      const { id = 0 } = this.message.user
      return typeof id === 'string' || id > 0
    },
    type() {
      if (this.isBot) {
        return 'bot'
      }
      return this.userType
    }
  },
  methods : {
    ...mapActions('_chats',['setAnswerMessage']),
    answer() {
      this.setAnswerMessage({ roomId : this.roomId, message : this.message })
    }
  }
}
</script>

<style scoped>
    .chat-message {
        color: var(--bubble-text-color);
        display: inline-flex;
        margin-bottom: var(--column);
        width: 100%;
    }

    .chat-message-data {
      max-width: 65%;
    }

    .chat-message-data.system {
      max-width: initial;
    }

    .chat-message-data .background {
        margin: 0 var(--column);
        background-color: var(--bubble-background-color);
        border-radius: 25px;
        padding: var(--column);
        margin-right: 0;
        color: var(--bubble-text-color);
    }

    .chat-message.myself .background, 
    .chat-message.bot .background{
      margin-left: 0;
      margin: 0 var(--column);
    }

    .chat-message.myself,
    .chat-message.bot {
        flex-direction: row-reverse;
    }


    .chat-message .date {
        font-size: 12px;
        margin: 0 var(--column);
    }

    .chat-message.myself .background {
        background-color: var(--bubble-self-background-color);
        color: var(--bubble-self-text-color)
    }

    .chat-message-data .image{
        width: 350px;
    }

    .chat-message-data audio,
    .chat-message-data video {
          margin: 0 var(--column);
    }

    .chat-message.myself .date,
    .chat-message.bot .date {
        text-align: right;
    }

    .chat-message.system {
        text-align: center;
        font-size: 13px;
        justify-content: center;
    }

    /* .chat-message.system .chat-message-data{
        width: 100%
    } */

    .chat-message.system .chat-message-data .background {
        background: rgb(243,245,236);
    }

    .chat-message .username {
        color: var(--bubble-text-color);
        font-size:12px;
        font-weight: bold;
        margin: 0 var(--column);
    }

    .icon {
        background-color: var(--blue);
        border-radius: 3px;
        color: white;
        display: inline-block;
        height: 2em;
        line-height: 2em;
        text-align: center;
        text-transform: uppercase;
        width: 2em;
    }

    .icon.whatsapp {
        background-color: var(--whatsapp-green);
    }

    .icon.instagram {
      background: #d6249f;
      //background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    }

    .icon.bot {
      background: #A7C71D
    }

    .background .fa-arrow-circle-down {
        margin-right: 10px;
    }

    .background .file {
        display: inline-block;
        text-align: center;
    }

    .dropdown {
      visibility: hidden;
    }

    .chat-message:hover .dropdown {
      visibility: visible;
    }

    .answer {
      background-color: rgba(0,0,0,0.1);
      border-radius: var(--radius);
      padding: calc(var(--column) / 2);
      margin-bottom: var(--column);
      color: #585858;
      font-size:13.2px;
    }

    .info {
      display: inline-flex;
      width: 95%;
      margin: 0 auto;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .myself .info,
    .bot .info {
      flex-direction: row;
    }

    .system .info {
      justify-content: center;
    }

    .chat-little  .chat-message-data {
      max-width: calc(100% - var(--column));
    }

    /* @media screen and (min-width: 1366px) {
      .chat-message-data {
        max-width: calc(100% - var(--column));
      }
    } */

    .chat-message .delivered,
    .chat-message .sent {
      color: var(--hiperpbx-grey);
    }

    .chat-message .read {
      color: var(--bubble-self-background-color)
    }

    .chat-message .failed {
      color: var(--red)
    }

</style>