<template>
  <div class='main-content'>
    <button @click="goBack" class="goback">
      <i class="fas fa-angle-left"></i>
      {{ $t('common.go-back') }}
    </button>
    <div>
      <h2>{{ $t("queues-dashboard.title") }}</h2>
    </div>
	<div class='filters-row'>
		<el-select v-model="selected" filterable collapse-tags :placeholder="getLoadingPlaceholder( loadingOrganizations, 'organizations')" @change="fetchQueues" >
			<el-option v-for="item in organizations" :key="item.id" :label="item.name" :value="item.id" />
		</el-select>
		<el-select v-model="selectedQueues" multiple collapse-tags :placeholder="getLoadingPlaceholder( loadingQueues, 'queues')" filterable style="width: 300px">
			<el-option v-for="queue in tmpqueuesList" :key="queue.queue" :label="`${queue.name} (${queue.queue})`" :value="queue.queue" />
		</el-select>
    <el-button @click="selectedQueues = []" type="primary">{{  $t("queues-dashboard.placeholders.clean-queues")  }}</el-button>
		<el-select v-model="additionalColumns" multiple collapse-tags :placeholder="$t('queues-dashboard.placeholders.columns')" style="margin-left: auto; width: 300px">
			<el-option v-for="col in filters.additionaColumns" :key="col" :label="getLabel( col )" :value="col" />
		</el-select>
	</div>

    <div id="results-table">
      <TheQueuesTable :records="queues" :columns="selectedColumns"></TheQueuesTable>
    </div>

  </div>
</template>
<script>
import TheQueuesTable from '@/components/reports/TheQueuesTable';
import { mapActions, mapGetters } from 'vuex';

export default {
  name : "QueuesDashboard",
  data( ){
	return{
		selected: null,
		organizations: [ ],
		loadingOrganizations: true,
		loadingQueues: false,
		additionalColumns: [],
		selectedQueues: [],
		filters:{
			additionaColumns: [
				'incomingCalls',
				'attendedCalls',
				'unAttendedCalls',
				'abandonCalls',
				'transferCalls',
				'maxCalls',
				'avgCalls',
				'avgWaitCalls',
				'porNeglectedCalls',
				'porAttendedCalls',
				'extra'
			],
			availableQueues: [],
			selected: []
		}
	}
  },
  methods:{
    goBack() {
      this.$router.push({ name: "switchBetweenDashboards"});
    },
    async fetchOrganizations( ){
      this.loadingOrganizations = true;
      try {
        this.organizations = await this.$store.dispatch(`organizations/all`, {pagination : false} );
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingOrganizations = false;
      }
    },
    async fetchQueues(){
      this.loadingQueues = true;
      let selectedArray = [ this.selected ];
      this.$store.state.selectedOrganizations = selectedArray;
      this.$store.dispatch(`listenQueuesDashboardArray`, selectedArray );

      this.selectedQueues = [];
      this.filters.availableQueues = [];
      this.loadingQueues = false;
    },
    getLabel( column ){
      return this.$t(`queues-dashboard.table.${column}`);
    },
    getLoadingPlaceholder( placeholder, text ){
      return placeholder ? 'Loading...' : this.$t( `queues-dashboard.placeholders.${text}` );
    },
  },
  computed : {
	...mapActions(['listenQueuesDashboardArray']),
	...mapGetters({
		tmpqueuesList: 'getQueuesDashboardList',
		organizationlist: 'organizations/getData'
	}),
	queues( ){
		return this.tmpqueuesList.filter( q => this.selectedQueues.includes( q.queue ) );
	},
	selectedColumns( ){
		return this.additionalColumns;
	},
	availableQueues( ){
		return this.filters.availableQueues;
	},
	organizationIdsArray() {
		return this.selected;
	}
  },
  async created( ){
    this.selected = this.$store.state.selectedOrganizations != undefined ? this.$store.state.selectedOrganizations[0] || null : null;
    this.fetchOrganizations( );
  },
  components: {
	TheQueuesTable
  }
}
</script>

<style scoped>
button.goback {
  outline: none;
  border: none;
  background-color: transparent;
}

.filters-row{
    display: flex;
    gap: 3px;
}

#results-table{
	margin-top: 2em;
}
</style>