<template>
  <div class="main-content" v-loading="loading">
    <button class="back" @click="$router.push({ name: 'contact-me-pbx-extensions' })"><i class="fas fa-angle-left"></i>{{ $t('common.go-back') }}</button>
    <h2>{{ !id ? $t("contact-me.pbx.extensions.create") : $t("contact-me.pbx.extensions.edit") }}</h2>
    <el-form label-position="top" size="large" :model="form" :rules="rules" v-if="status === 'form'">
      <el-tabs v-model="tab">
        <el-tab-pane :accesskey="$t('contact-me.pbx.extensions.basic-information')" name="basic">
          <span slot="label">
            <el-badge is-dot v-if="errors.name || errors.extension">{{$t('contact-me.pbx.extensions.basic-information')}}</el-badge>
            <p v-else>{{$t('contact-me.pbx.extensions.basic-information')}}</p> 
          </span>
          <h3>{{ $t('contact-me.pbx.extensions.basic-information') }}</h3>
          <el-form-item :label="$t('contact-me.pbx.extensions.name')" required :error="errors.name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.extension')" required :error="errors.extension">
            <el-input v-model="form.extension"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.secret')" required :error="errors.secret" prop="secret">
            <el-input v-model="form.secret"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.outbound-callerid')">
            <el-input v-model="form.outboundcid"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.is-recording-calls')">
            <el-switch v-model="form.recording" :active-text="$t('common.yes')" :inactive-text="$t('common.no')"></el-switch>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.is-callwaiting')">
            <el-switch v-model="callwaitValue" :active-text="$t('common.yes')" :inactive-text="$t('common.no')" active-value="enabled" inactive-value="disabled"></el-switch>
          </el-form-item>
          <div>
            <el-button type="primary" @click="setTab('voicemail')" :disabled="!basicInformationIsValid">{{ $t("common.next") }}</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.extensions.voicemail.title')" name="voicemail" :disabled="!basicInformationIsValid">
          <h3>{{$t('contact-me.pbx.extensions.voicemail.title')}}</h3>
          <p>{{$t('contact-me.pbx.extensions.voicemail.allow-incoming-callers-to-record-a-message-in-case-you-dont-answer-the-call')}}</p>
          <el-form-item :label="$t('contact-me.pbx.extensions.voicemail.email')">
            <el-input v-model="voicemail.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.voicemail.password')">
            <el-input v-model="voicemail.password" type="number"></el-input>
          </el-form-item>
          <el-button @click="setTab('basic')">{{ $t("common.back") }}</el-button>
          <el-button type="primary" @click="setTab('followme')">{{ $t("common.next") }}</el-button>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.extensions.followme.title')" name="followme" :disabled="!basicInformationIsValid">
          <h3>{{$t('contact-me.pbx.extensions.followme.title')}}</h3>
          <p>{{$t('contact-me.pbx.extensions.followme.allow-users-to-route-their-incoming-calls-to-multiple-phones-in-a-specified-sequence,-allowing-each-user-to-create-a-unique-call-flow-and-failover-for-their-extension')}}</p>
          <div v-if="!showFollowMe">
            <p>{{$t('contact-me.pbx.extensions.followme.this-configuration-is-optional-do-you-wanna-configure-it')}}</p>
            <el-button @click="setTab('voicemail')">{{ $t("common.back") }}</el-button>
            <el-button @click="setTab('type')">{{$t('contact-me.pbx.extensions.followme.no-take-me-to-the-next-step')}}</el-button>
            <el-button type="primary" @click="showFollowMe = true">{{$t('contact-me.pbx.extensions.followme.yes-configure-it')}}</el-button>
          </div>
          <div v-else>
            <el-button type="danger" @click="showFollowMe = false">{{$t('contact-me.pbx.extensions.followme.remove-configuration')}}</el-button>
            <el-form-item :label="$t('contact-me.pbx.extensions.followme.initial-wait-time')">
              <el-input v-model="form.followme.pre_ring"></el-input>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.extensions.followme.wait-time')">
              <el-input v-model="form.followme.ring"></el-input>
            </el-form-item>
            <el-form-item :label="$t('contact-me.pbx.extensions.followme.ring-strategy')">
              <!-- <el-input v-model="form.followme.strategy"></el-input> -->
              <el-select v-model="form.followme.strategy">
                <el-option v-for="strategy in strategies" :key="strategy.value" :label="$t('contact-me.pbx.strategies.' + strategy.value)" :value="strategy.value" ></el-option>
              </el-select>
            </el-form-item>
            <h4>{{$t('contact-me.pbx.extensions.followme.list')}}</h4>
            <div>
              <div class="d-inline-flex" style="width: 100%">
                <el-select v-model="type" style="width: 200px">
                  <el-option value="extension" :label="$t('contact-me.pbx.extensions.title')"></el-option>
                  <el-option value="queue" :label="$t('contact-me.pbx.queues.title')"></el-option>
                  <el-option value="phone" :label="$t('contact-me.pbx.extensions.followme.phone-value')"></el-option>
                </el-select>
                <el-select v-model="last" filterable v-if="type !== 'phone'" style="width: 200px">
                  <el-option v-for="(item, index) in destinations" :key="index" :value="item[keyIdDestination]" :label="item.name">
                  </el-option>
                </el-select>
                <el-input v-model="last" v-else style="width: 200px"></el-input>
                <el-button type="primary" @click="addFollowMeDestination">{{ $t('common.add') }}</el-button>
              </div>
              <div class="destinations">
                <el-tag
                  v-for="(destination, index) in followMeDestinationsParsed"
                  :key="index"
                  class="tag"
                  closable
                  @close="handleClose(destination)">
                  {{ destination.type }}: {{ destination.label }}
                </el-tag>
              </div>
            </div>
            <h4>{{ $t('contact-me.pbx.extensions.followme.action-if-none-one-answers') }}</h4>
            <el-select v-model="form.followme.action_no_answer.type">
              <el-option v-for="(dest, index) in noAnswerDestination" :key="index" :value="dest.toLowerCase()" :label="dest"></el-option>
            </el-select>
            <el-select v-model="form.followme.action_no_answer.id">
              <el-option v-for="(item, index) in noAnswerDestinations[form.followme.action_no_answer.type.toLowerCase()]" :key="index" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
            <div>
              <el-button @click="setTab('voicemail')">{{ $t("common.back") }}</el-button>
              <el-button type="primary" @click="setTab('type')">{{ $t("common.next") }}</el-button>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.extensions.type')" name="type" :disabled="!basicInformationIsValid">
          <h3>{{ $t('contact-me.pbx.extensions.type') }}</h3>
          <p>{{ $t('contact-me.pbx.extensions.select-where-you-will-use-this-extension') }}</p>
          <div class="radios">
            <el-radio v-model="form.type" label="Normal">
              <div class="radio">
                <p>{{ $t('contact-me.pbx.extensions.normal')}} </p>
                <p>{{ $t('contact-me.pbx.extensions.use-this-extension-with-a-physical-phone')}} </p>
              </div>
            </el-radio>
            <el-radio v-model="form.type" label="HiperMe">
              <div class="radio">
                <p>HiperMe</p>
                <p>{{ $t('contact-me.pbx.extensions.use-this-extension-only-in-users-or-agents-of-HiperMe') }} </p>
              </div>
            </el-radio>
          </div>
          <el-button @click="setTab('followme')">{{ $t("common.back") }}</el-button>
          <el-button type="primary" @click="setTab('advance')">{{ $t("common.next") }}</el-button>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.extensions.advance-configuration.title')" name="advance" :disabled="!basicInformationIsValid">
          <h3>{{ $t('contact-me.pbx.extensions.advance-configuration.title') }}</h3>
          <el-form-item :label="$t('contact-me.pbx.extensions.advance-configuration.pickupgroup')">
            <el-input v-model="advanced_options.pickupgroup"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.advance-configuration.accountcode')">
            <el-input v-model="advanced_options.accountcode"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.advance-configuration.callgroup')">
            <el-input v-model="advanced_options.callgroup"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.advance-configuration.context')">
            <el-input v-model="advanced_options.context"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.extensions.advance-configuration.dial')">
            <el-input v-model="advanced_options.dial"></el-input>
          </el-form-item>
          <div>
            <el-button @click="setTab('type')">{{ $t("common.back") }}</el-button>
            <el-button type="primary" @click="setTab('finish')">{{ $t("common.next") }}</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Finish" name="finish" :disabled="!basicInformationIsValid">
          <h3>Review</h3>
          <the-details :extension="{ ...form, voicemail, ...advanced_options, followMeDestinations }"></the-details>
          <el-alert
            v-if="Object.keys(errors).length > 0"
            title="You have some errors, check the forms and try again"
            type="error">
          </el-alert>
          <div>
            <el-button @click="setTab('advance')">{{ $t("common.back") }}</el-button>
            <el-button type="primary" @click="submit">Save</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class="message" v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 'saved'"/>
        <img src="@/img/error.svg" v-else/>
        <p> {{ message }} </p>
        <div class="buttons">
          <router-link :to="{ name: 'contact-me-pbx-extensions' }">{{ $t("common.go-back-to-the-list") }}</router-link>
          <!-- <el-button type="text" @click="status = null" v-if="status !== 404">{{ $t("common.show-the-form") }}</el-button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TheDetails from "./TheDetails.vue";
export default {
  props: {
    entity: {
      required: false,
      default: () => ({
        name: "",
        extension: "",
        secret: "",
        recording: false,
        callwaiting: "disabled",
        outboundcid: "",
        voicemail: false,
        followme: false,
        type: "Normal",
        advanced_options: {
          pickupgroup: "",
          accountcode: "",
          callgroup: "",
          context: "from-internal",
          dial: "",
        }
      })
    },
    id: {
      required: false,
      default: 0
    }
  },
  data() {

    const minLength = (rule, value, callback) => {
      if (value) {
        if (value.length < 10) {
          callback(new Error("Minimo 10"))
        }
      }
      callback();
    }

    const maxgLength = (rule, value, callback) => {
      if (value) {
        if (value.length > 64) {
          callback(new Error("Max 10"))
        }
      }
      callback();
    }

    const diffFromName = (rule, value, callback) => {
      if (this.form.name === value) {
        callback(new Error("Tiene que ser diferente que el nombre"))
      } 
      callback();
    }

    const diffFromExtension = (rule, value, callback) => {
      if (this.form.extension === value) {
        callback(new Error("Tiene que ser diferente que la extension"))
      } 
      callback();
    }

    const atLeastOneNumber = (rule, value, callback) => {
      if (!/[0-9]+/.test(value)) {
        callback(new Error("Tiene que tener al menos un numero"))
      }
      callback();
    }

    const atLeastOneLetterMin = (rule, value, callback) => {
      if (!/[a-z]+/.test(value)) {
        callback(new Error("Tiene que tener al menos una letra minuscula"))
      }
      callback();
    }

    const atLeastOneLetterMax = (rule, value, callback) => {
      if (!/[A-Z]+/.test(value)) {
        callback(new Error("Tiene que tener al menos una letra mayuscula"))
      }
      callback();
    }

    const atLeastOneChar = (rule, value, callback) => {
      if (!/[-!$%^&*()_+|~=`{}[\]:";'<>?,./]+/.test(value)) {
        callback(new Error("Tiene que tener al menos una caracter especial (-!$%^&*()_+|~=`{}[]:;'<>?,./)"))
      }
      callback();
    }

    return {
      form: this.entity,
      tab: "basic",
      type: "extension",
      showFollowMe: this.entity.followme,
      advanced_options: this.entity.advanced_options ?? {
        pickupgroup: this.entity.pickupgroup,
        accountcode: this.entity.accountcode,
        callgroup: this.entity.callgroup,
        context: this.entity.context,
        dial: this.entity.dial,
      },
      showVoicemail: false,
      last: "",
      followMeDestinations: !this.entity.followme ? [] : this.entity.followme.list.filter(item => item),
      rules: {
        name: [
          { required: true,}
        ],
        extension: [
          { required: true}
        ],
        secret: [
          { required: true},
          { validator: minLength, trigger: 'blur' },
          { validator: maxgLength, trigger: 'blur' },
          { validator: diffFromName, trigger: 'blur' },
          { validator: diffFromExtension, trigger: 'blur' },
          { validator: atLeastOneNumber, trigger: 'blur' },
          { validator: atLeastOneLetterMin, trigger: 'blur' },
          { validator: atLeastOneLetterMax, trigger: 'blur' },
          { validator: atLeastOneChar, trigger: 'blur' },
        ]
      },
      errors: {},
      loading: false,
      status: "form",
      idInHiperMe: 0,
      voicemail: this.entity.voicemail ? this.entity.voicemail : { email: "", password: "" },
    }
  },
  methods: {
    ...mapActions("pbx_extensions", { fetchExtensions: "all" }),
    ...mapActions("pbx_queues", { fetchQueues: "all" }),
    ...mapActions("pbx_destinations", { fetchDestinations: "all" }),
    setTab(value) {
      this.tab = value;
    },
    addFollowMeDestination() {
      this.followMeDestinations.push(this.last);
    },
    handleClose(dest) {
      this.followMeDestinations = this.followMeDestinations.filter((item) => {
        return item != dest.value;
      });

      this.form.followme.list = this.followMeDestinations;
    },
    async submit() {
      this.loading = true;

      if (this.showFollowMe) {
        this.form.followme.list = this.followMeDestinations;
        this.form.followme.action_no_answer.type = this.form.followme.action_no_answer.type.toLowerCase();
      }

      this.form.advanced_options = this.advanced_options;
      this.form.record = this.form.recording;

      if (this.voicemail.email && this.voicemail.password) {
        this.form.voicemail = this.voicemail;
      } else {
        this.form.voicemail = false;
      }


      if( ! /enabled|disabled/.test( this.form.callwaiting ) ){
        this.form.callwaiting = this.form.callwaiting == true ? 'enabled': 'disabled';
      }

      try {
        const type = this.form.type;
        
        delete this.form.type;
        delete this.form.hipermeExtension;
        delete this.form.pickupgroup;
        delete this.form.accountcode;
        delete this.form.callgroup;
        delete this.form.call_group;
        delete this.form.context;
        delete this.form.dial;
        delete this.form.recording;

        const action = !this.id ? "create" : "update";
        const payload = !this.id ? this.form : { resourceId: this.id, payload: this.form };
        await this.$store.dispatch(`pbx_extensions/${action}`, payload);

        if (type === "HiperMe") {
          const data = {
            name: this.form.name,
            extension: this.form.extension,
            secret: this.form.secret,
            organization: `/api/organizations/${this.organizationId}`
          }

          const _payload = !this.createdInHiperMe ? data : { id: this.idInHiperMe, payload: data };
          const _action = !this.createdInHiperMe ? "create" : "update";
          await this.$store.dispatch(`extensions/${_action}`, _payload);
        }

        this.status = "saved";
      } catch (error) {
        if (error.response) {
          const { data } = error.response;
          this.errors = data.errors;
        }
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    strategies() {
      return this.$store.getters["pbx_extensions/getRingStrategies"]
    },  
    extensions() {
      return this.$store.getters["pbx_extensions/getData"]
    },
    queues() {
      return this.$store.getters["pbx_queues/getData"]
    },
    destinations() {
      const items = this.type === "extension" ? this.extensions : this.queues;
      return Object.values(items).filter((item) => {
        let already = true;
        this.followMeDestinations.forEach((dest) => {
          if (dest.type === this.type && dest.value === item[this.keyIdDestination]) {
            already = false
          }
        });
        if (already) {
          return item;
        }
      });
    },
    noAnswerDestination() {
      return Object.keys(this.$store.getters["pbx_destinations/getData"]);
    },
    noAnswerDestinations() {
      return this.$store.getters["pbx_destinations/getData"];
    },
    keyIdDestination() {
      return this.type === "extension" ? "extension" : "id";
    },
    message() {
      if (this.status == "saved") {
        return this.$t("common.saved")
      }
      return this.$t("common.not-saved")
    },
    followMeDestinationsParsed() {
      if (this.followMeDestinations.length == 0) return [];
      return this.followMeDestinations.map((destination) => {
        let dest = {
          label: "",
          type: "",
          value: destination,
        }
        const extension = Object.values(this.extensions).find(exten => exten.extension == destination);
        if (extension) {
          dest.type = "Extension"
          dest.label = `${extension.name} (${extension.extension})`
        }
        const queue = Object.values(this.queues).find(queue => queue.number == destination);
        if (queue) {
          dest.type = "Queue"
          dest.label = `${queue.name} (${queue.number})`;
        }
        return dest;
      })
    },
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"]
    },
    basicInformationIsValid() {
      return this.form.name && this.form.secret && this.form.extension
    },
    callwaitValue:{
      get( ){
        if( /enabled|disabled/.test( this.form.callwaiting ) ) return this.form.callwaiting;
        return this.form.callwaiting == true ? 'enabled': 'disabled';
      },
      set( val ){
        this.form.callwaiting = val;
      }
    }
  },
  async created() {
    this.loading = true;
    await this.fetchQueues();
    await this.fetchExtensions();
    await this.fetchDestinations();
    this.loading = false;
    this.voicemail.password = this.form.voicemail.pin;
    delete this.form.voicemail.pin;
  },
  components: {
    TheDetails
  },
  watch: {
    showFollowMe(value) {
      if (value) {
        this.form.followme = {
          pre_ring: 0,
          ring: 0,
          list: [],
          action_no_answer: {
            type: "",
            id: ""
          }
        }
      } else {
        this.form.followme = false;
      }
    }
  }
}
</script>

<style scoped>
.destinations {
  margin: var(--column) 0;
}

.destinations .tag {
  margin-right: var(--column)
}
.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.radio {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
}

.radio p:first-child {
  font-weight: bold;
}

.radios {
  display: flex;
  flex-direction: column;
}

.back {
  outline: none;
  border: none;
  background-color: transparent;
}
</style>