<template>
  <div>
    <el-form-item label="Organización" prop="value">
      <el-select v-model="value" placeholder="Seleccionar organization" filterable remote allow-create>
        <el-option v-for="item in organizations" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
    </el-form-item>
    <el-dialog width="30%" :title="$t('configuration.organization.create')" :visible.sync="show" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" v-loading="saving">
      <div>
        <el-form :model="form" label-width="120px" label-position="top" class="form" size="small">
          <the-fields :fields="fields" :errors="errors" ref="fields"/> 
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="save" type="primary">{{$t("common.save") }}</el-button>
        <el-button size="small" @click="show = false">{{$t("common.cancel") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ticketapi from '@/api/tickets'
import TheFields from '@/components/mail-center/TheFields';
import { mapActions, mapGetters } from 'vuex'
export default {  
  components: {
    TheFields
  },
  data() {
    return {
      value: null,
      show: false,
      form: {},
      fields: [],
      errors : {},
      saving: false,
    }
  },
  methods: {
    ...mapActions('mailcenter', ['fetchOrganizations','createOrganization']),
    async fetchForm() {
      const response = await ticketapi.fetchOrganizationForm()
      this.fields = response.data.data.fields
    },
    async save() {
      this.saving = true;
      const organization = this.$refs.fields.getValues();
      try {
        const response = await this.createOrganization(organization)
        this.value = response.data.id
        this.show = false
      } catch (error) {
        this.errors = error.response.data.errors;
      } finally {
        this.saving = false;
      }
    },
    getValue() {
      return this.value;
    }
  },
  computed: {
    ...mapGetters('mailcenter', {
      organizations : 'getOrganizations'
    })
  },
  mounted() {
    this.fetchOrganizations();
  },
  watch: {
    value(val) {
      if (typeof val === 'string') {
        this.show = true;
      }
    }
  },
  created() {
    this.fetchForm()
  } 
}
</script>

<style>

</style>