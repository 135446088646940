<template>
  <div class="crud">
    <div class="crud-content">
      <div class="header">
        <h2>{{ $t("tickets.tickets") }}</h2>
        <div class="buttons">
          <div>
            <el-date-picker size="small" v-model="date" type="daterange" range-separator="-" @change="handleChangeDates"
              start-placeholder="Start date" end-placeholder="End date"/>
            <el-input size="small" v-model="search" :placeholder="$t('tickets.search-by')" style="width: 250px" @clear="handleSearch" clearable />
            <el-button @click.native="handleSearch" size="small" type="primary">{{ $t('common.search') }}</el-button>
            <el-button @click.native="showDialogAdvanceSearch" size="small" type="primary">{{ $t('common.advanceSearch')
              }}</el-button>
          </div>
          <div>
            <el-button size="small" @click="fetchData">{{ $t("common.update") }}</el-button>
            <el-button size="small" type="primary" @click="$router.push({ name: 'TicketNew' })">{{$t("tickets.new-ticket") }}</el-button>
          </div>
        </div>
        <TheFilters :filters="filters" @submit="handleFilters" :other-applied-filters="advanceFilters" @removeAdvanceFilter="handleRemoveAdvanceFilter" @removeFilter="handleRemoveFilter"></TheFilters>
      </div>
      <div>
        <div class="selects">
          <el-select @change="updateTickets('status')" v-model="updated.status"
            :placeholder="$t('tickets.change-status')" size="small" :disabled="selectDisabled">
            <el-option v-for="item in ticketStatus" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
          <el-select @change="updateTickets('team')" v-model="updated.team" :placeholder="$t('tickets.change-teams')"
            size="small" :disabled="selectDisabled">
            <el-option v-for="item in teams" :key="item.team_id" :label="item.name" :value="item.team_id">
            </el-option>
          </el-select>
          <el-select @change="updateTickets('staff')" v-model="updated.staff" :placeholder="$t('tickets.change-staff')"
            size="small" :disabled="selectDisabled">
            <el-option v-for="item in staff" :key="item.staff_id" :label="`${item.firstname} ${item.lastname}`"
              :value="item.staff_id">
            </el-option>
          </el-select>
          <el-select @change="updateTickets('priority')" v-model="updated.priority"
            :placeholder="$t('tickets.change-priority')" size="small" :disabled="selectDisabled">
            <el-option v-for="item in priorities" :key="item.priority_id" :label="item.priority_desc"
              :value="item.priority_id">
            </el-option>
          </el-select>
          <el-select @change="updateTickets('departament')" v-model="updated.departament"
            :placeholder="$t('tickets.change-departament')" size="small" :disabled="selectDisabled">
            <el-option v-for="item in departments" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>

        <TheTable :rows="tickets" :columns="columns" :pagination="pagination" :loading="loading" select="multiple"
          @selection-change="selectionChange" @change-page="handleChangePage" @sortChange="handleSortChange" ref="eltable">
          <template v-slot:number="scope">
            <i class="fas fa-exclamation" v-if="scope.row.thread?.entries[scope.row.thread?.entries.length - 1]?.user_id !== 0 && scope.row.thread?.entries[scope.row.thread?.entries.length - 1]?.type === 'R'"></i>
            <router-link :to="{ name: 'ticket', params: { ticketNumber: scope.row.number } }">{{ scope.row.number
              }}</router-link>
          </template>
          <template v-slot:[prioritySlot]="scope">
            <TheTicketPriorityTag v-if="hasPriority(scope.row)" :name="scope.row.cdata.priority_info.priority_desc"
              :state="scope.row.cdata.priority_info.priority" />
          </template>
          <template v-slot:assigned="scope">
            <div>
              <p class="department">{{ scope.row.department.name }}</p>
              <p v-if="isAssigned(scope.row)" class="mb-0">{{ scope.row.assigned.firstname }} {{
          scope.row.assigned.lastname }}</p>
              <p v-else class="mb-0">No esta asignado a un agente</p>
            </div>
          </template>
        </TheTable>
      </div>
    </div>
    <TheAdvanceSearchDialogVue ref="advanceSearchDialogVue" @addQueryParams="handleAdvanceFilters" :defaultFilters="defaultAdvanceFilters">
    </TheAdvanceSearchDialogVue>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TheTable from '@/components/crud/TheTable'
import TheFilters from '@/components/crud/TheFilters'
import TheTicketPriorityTag from "@/components/ticket/TheTickePriorityTag";
import TheAdvanceSearchDialogVue from './TheAdvanceSearchDialog.vue';
export default {
  data () {
    const today = new Date()

    today.setMinutes(0)
    today.setSeconds(0)
    today.setHours(0)
    today.setMilliseconds(0)

    return {
      date: [
        this.$route.query.timestampInit ? new Date(this.$route.query.timestampInit * 1000) : undefined,
        this.$route.query.timestampEnd ? new Date(this.$route.query.timestampEnd * 1000) : undefined,
      ],
      prioritySlot: 'priority',
      search: this.$route.query.search ?? '',
      loading: true,
      updated: {
        status: "",
        staff: "",
        priority: "",
        departament: "",
        team: "",
      },
      key: {
        status: "status_id",
        staff: "staff_id",
        priority: "priority_id",
        departament: "dept_id",
        team: "team_id",
      },
      selectedTickets: [],
      pagination: {},
      params: {},
      sorters: {},
      normalFilters: {},
      advanceFilters: [],
      defaultAdvanceFilters: []
    }
  },
  components: {
    TheTable,
    TheFilters,
    TheTicketPriorityTag,
    TheAdvanceSearchDialogVue
  },
  computed: {
    ...mapGetters({ user: 'getMailCenterUser' }),
    ...mapGetters(["getAllTickets", "getAllPriorities", "getDataToUpdateTicket", "getAdvanceFilters"]),
    selectDisabled () {
      return this.selectedTickets.length === 0
    },
    tickets () {
      return this.getAllTickets
        .map((ticket) => {
          const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

          const today = new Date()
          ticket.est_duedate_limit = new Date(ticket.est_duedate).getTime() < today.getTime()

          const created = new Date(ticket.created)
          ticket.created = created.toLocaleString('es-ES', { timeZone })

          if (ticket.lastupdate) {
            const lastupdate = new Date(ticket.lastupdate + " UTC")
            ticket.lastupdate = lastupdate.toLocaleString('es-ES', { timeZone })
          }

          return ticket;
        })
    },
    columns () {
      return [
        { index: 'number', label: '#', width: '100', selection: 'selection', fixed: true },
        { index: 'cdata.subject', label: this.$t('tickets.subject'), fixed: true },
        { index: 'user.name', label: this.$t('tickets.from') },
        { index: 'assigned', label: this.$t('tickets.assigned-to') },
        { index: 'status.name', label: this.$t('tickets.status'), width: '150' },
        { index: 'priority', className: 'priority', label: this.$t('tickets.priority'), sortable:'test', width: '150'},
        { index: 'created', className: 'created', label: this.$t('tickets.created'), sortable:'test', type: 'date', width: '150'},
        { index: 'lastupdate', className: 'lastupdate', label: this.$t('tickets.last-updated'), sortable:'test', type: 'date', width: '150' },
        { index: 'closed', className: 'closed', label: this.$t('tickets.closed'), sortable:'test', type: 'date', width: '150' },
      ]
    },
    ticketStatus () {
      return this.getDataToUpdateTicket.ticket_status ?? []
    },
    staff () {
      return this.getDataToUpdateTicket.staff ?? []
    },
    priorities () {
      return this.getDataToUpdateTicket.ticket_priority ?? []
    },
    departments () {
      return this.getDataToUpdateTicket.departments ?? []
    },
    teams () {
      return this.getDataToUpdateTicket.teams
    },
    path () {
      return this.$route.path
    },
    agent () {
      return this.$store.getters['getAgent']
    },
    query() {
      return this.$route.query
    },
    agents() {
      return this.$store.getters['agents/getData'].map((agent) => {
        return {
          label: agent.user.name + " " + agent.user.lastname,
          value: agent.number
        }
      })
    },
    filters () {
      return [
        { type: 'select', index: 'staff', label: this.$t('tickets.agent'), showLabel: true, options: this.agents },
        { type: 'select', index: 'department', label: this.$t('tickets.departament'), options: this.departments.map(department => ({ label: department.name, value: department.id })), showLabel: true },
        {
          type: 'select', index: 'source', label: this.$t('tickets.source'), options: [
            {
              value: 'phone',
              label: this.$t('common.phone')
            },
            {
              value: 'email',
              label: this.$t('common.email')
            },
            {
              value: 'api',
              label: 'Web'
            }
          ]
        },
        {
          type: 'select', index: 'status', label: 'Estado general', options: [
            {
              value: 'open',
              label: 'Abierto'
            },
            {
              value: 'closed',
              label: 'Cerrado'
            }
          ]
        },
        { type: 'select', index: 'statusId', label: this.$t('tickets.status'), options: this.ticketStatus.map(status => ({ label: `${status.name} (${status.state})`, value: status.id })), showLabel: true, multiple: true },
        { type: 'select', index: 'priorityId', label: this.$t('tickets.priority'), options: this.priorities.map(priority => ({ label: priority.priority_desc, value: priority.priority_id })), showLabel: true },
        { type: 'boolean', index: 'not-assigned', label: this.$t("tickets.not-assigned"), booleanType: 'yes|no' },
        { type: 'boolean', index: 'expired', label: this.$t('tickets.expired'), booleanType: 'yes|no' },
        { type: 'boolean', index: 'onlyAssigned', label: this.$t('tickets.assigned-to-me'), booleanType: 'yes|no' },
      ]
    }
  },
  methods: {
    ...mapActions(["fetchTickets", "fetchDataToUpdateTheTicket", "multipleUpdate"]),
    showDialogAdvanceSearch () {
      this.$refs.advanceSearchDialogVue.toggleShowDialog()
    },
    selectionChange (value) {
      this.selectedTickets = value
    },
    hasPriority (row) {
      return row.cdata && row.cdata.priority_info
    },
    isAssigned (row) {
      return row.assigned
    },
    handleRemoveFilter(filter) {
      const { index } = filter
      this.replaceQueryParams({ [index] : undefined })
    },
    handleFilters(filter) {
      if (Object.keys(filter).length > 0) {
        this.replaceQueryParams(filter)
      }
    },
    handleAdvanceFilters(filters) {
      const _filters = {}

      this.advanceFilters = [
        ...filters, 
        ...this.advanceFilters.map((advanceFilter) => {
          if (!filters.find(filter => filter.name === advanceFilter.name)) {
            advanceFilter.value = undefined
          }
          return advanceFilter
        }) 
      ].reduce((acc, advanceFilter) => {
        if (!acc.some((filter) => filter.name === advanceFilter.name)) {
          acc.push(advanceFilter);
        }
        return acc;
      }, [])

      this.advanceFilters.forEach(filter => {
        _filters[filter.name] = filter.value
      })

      this.replaceQueryParams(_filters)
    },
    handleRemoveAdvanceFilter(filter) {
      const { name } = filter
      this.advanceFilters = this.advanceFilters.filter(filter => filter.name !== name)
      this.$refs.advanceSearchDialogVue.removeFilter(filter)
      this.replaceQueryParams({ [name] : undefined })
    },
    replaceQueryParams(params) {
      const query = { ...this.query, ...params }
      if (!params.page)  {
        delete query.page
      }
      this.$router.replace({ query })
    },
    handleSearch() {
      const search = this.search !== "" ? this.search : undefined
      this.replaceQueryParams({ search })
    },
    handleChangePage({ page }) {
      this.replaceQueryParams({ page })
    },
    handleChangeDates(dates) { 
      if (dates) {
        const [initDate, endDate] = dates
        this.replaceQueryParams({ timestampInit: initDate.getTime() / 1000, timestampEnd: endDate.getTime() / 1000 })
      } else {
        this.replaceQueryParams({ timestampInit: undefined, timestampEnd: undefined })
      }
    },
    handleSortChange({ prop, order }) {
      const key = `order[${prop}]`
      const sort = order ? (order === 'ascending' ? 'asc' : 'desc') : null
      this.replaceQueryParams({ [key]: sort })
    },
    async fetchData () {
      this.loading = true
      this.fetchDataToUpdateTheTicket();
      try {
        const { items_per_pag, total_items } = await this.fetchTickets(this.query)
        this.pagination = {
          totalItems: total_items,
          pageSize: items_per_pag,
        }
      } finally {
        this.loading = false
      }
    },
    async updateTickets (type) {
      const data = {
        ids: this.selectedTickets.map(ticket => ticket.ticket_id),
        action: this.key[type],
        value: this.updated[type]
      }
      try {
        await this.multipleUpdate(data)
        this.$message({ type: "success", message: this.$t("tickets.tickets-updated") });
        this.updated = {
          status: "",
          staff: "",
          priority: "",
          departament: "",
        }
      } catch (error) {
        this.$message({ type: "danger", message: 'No se ha podido actualizar el ticket' });
      }
    },
    setDefaultAdvanceFilters() {
      const arrFilters = [...this.filters.map((filter) => filter.index), 'timestampInit', 'timestampEnd', 'search', 'page']
      const advanceFiltersApplied = Object.keys(this.query).filter(query => !arrFilters.includes(query) && !/^order\[/.test(query))
      this.advanceFilters = advanceFiltersApplied.map((index) => {
        const filter = this.getAdvanceFilters.find(filter => filter.name === index)
        filter.value = this.query[index]
        return filter
      })
      
      this.advanceFilters.forEach((filter) => {
        this.defaultAdvanceFilters.push(filter.id)
      })
    },
  },
  async created () {
    await this.$store.dispatch('agents/all', { pagination: false, mail: 'yes', 'organization.id': this.agent.organization_id })
    this.setDefaultAdvanceFilters()
    await this.fetchData()
  },
  watch: {
    query() {
      this.fetchData()
    }
  }
}
</script>
<style scoped>
.selects {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
}

.selects .el-select {
  margin-left: calc(var(--column) / 2);
  margin-top: calc(var(--column) / 2)
}

.department {
  font-weight: bold;
  margin-bottom: calc(var(--column) / 2);
  display: block;
}

.department+p {
  font-size: 14px
}

.fa-exclamation {
  margin-right: var(--column);
  color: var(--red);
}

.duedate {
  display: inline-flex;
  align-items: center;
}

.duedate .fa-exclamation {
  margin-left: var(--column);
}

.header {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttons {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(--column);
  flex-wrap: wrap;
  gap: calc(var(--column) / 2)
}

.buttons div {
  display: inline-flex;
  gap: calc(var(--column) / 2);
  flex-wrap: wrap;
}

.buttons .el-button {
  margin: 0;
}
</style>