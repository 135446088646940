<template>
  <span class="chronometer">
    <span v-if="timestampEnded">{{ time | hhmmss }}</span>
    <span v-else>{{ seconds | hhmmss }}</span>
    <i class='far fa-clock'></i>
  </span>
</template>

<script>
export default {
  props: {
    timestamp: {
      required: false,
    },
    timestampEnded: {
      required: false,
    },
    type: {
      default: 'default',
      required: false
    }
  },
  data() {
    return {
      interval: null,
      seconds: 0,
    }
  },
  methods: {
    sumSeconds() {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.seconds++;
        }, 1000)
      }
    },
    start() {
      if (this.type !== 'clock') {
        this.seconds = ((new Date().getTime() - new Date(this.timestamp).getTime()) / 1000)
      } else {
        const dt = new Date();
        this.seconds = dt.getSeconds() + (60 * dt.getMinutes()) + (60 * 60 * dt.getHours());
      }
      this.sumSeconds();
    },
    stop() {
      clearInterval(this.interval);
      this.interval = null;
    }
  },
  computed: {
    time() {
      return (new Date(this.timestampEnded).getTime() - new Date(this.timestamp).getTime()) / 1000;
    }
  },
  created() {
    this.start();
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        this.stop();
      } else {
        this.start();
      }
    });
  },
  watch: {
    timestamp() {
      this.seconds = ((new Date().getTime() - new Date(this.timestamp).getTime()) / 1000);
      this.stop();
      if (!this.timestampEnded) {
        this.sumSeconds();
      }
    }
  }
}
</script>

<style scoped>
.fa-clock {
  margin-left: 10px;
}
</style>