<template>
  <div>
    <h2>{{ $t("user-configuration.audio.title") }}</h2>
    <el-form label-position="top" class="audio-config-form">
      <div class="devices">
        <h3>{{ $t("user-configuration.audio.microphone") }}</h3>
        <div v-if="devicesInput.length > 0">
          <el-form-item :label="$t('user-configuration.audio.input-devices')">
            <el-select
              v-model="inputDevice"
              placeholder="Select"
              @change="updateInputDevice()"
            >
              <el-option
                v-for="device in devicesInput"
                :key="device.deviceId"
                :label="device.label"
                :value="device.deviceId"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="microphone">
            <p style="margin-bottom: 10px !important">{{ $t("user-configuration.audio.test-microphone") }}</p>
              <el-button
                size="mini"
                :type="!testingMicrophone ? 'primary' : 'danger'"
                @click.native="testMicrophone"
                class="mt-0"
                >{{ !testingMicrophone ? $t("user-configuration.audio.test") : $t("user-configuration.audio.stop-test") }}</el-button
              >
            <canvas id="meter" width="500" height="10"></canvas>
            <p class="mb-0">{{ $t("user-configuration.audio.if-you-are-not-with-headphones-you-will-have-feedback") }}</p>
          </div>
        </div>
        <div v-else>
          <p>{{ $t("user-configuration.audio.the-microphone-is-disabled") }}</p>
        </div>
      </div>
      <div class="devices">
        <h3>{{ $t("user-configuration.audio.speakers") }}</h3>
        <p>{{ $t("user-configuration.audio.select-the-ringtone-device") }}</p>
        <el-form-item :label="$t('user-configuration.audio.output-devices')">
          <el-select
            v-model="outputDevice"
            placeholder="Select"
            @change="updateOutputDevice"
          >
            <el-option
              v-for="device in devicesOutput"
              :key="device.deviceId"
              :label="device.label"
              :value="device.deviceId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user-configuration.audio.volumen-output')">
          <el-slider
            v-model="outputVolumen"
            @change="updateOutputVolume"
          ></el-slider>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import volumeMeter from "@/plugins/volume-meter";
export default {
  name: "TheAudioConfiguration",
  data() {
    return {
      stream: null,
      inputDevice: "",
      outputDevice: "",
      devicesInput: [],
      devicesOutput: [],
      outputVolumen: 0,
      testingMicrophone: false,
      audioContext: null,
      mediaStreamSource: null,
      meter: null,
      rafID : null,
      canvasContext : null,
      WIDTH : 500,
      HEIGHT : 20,
    };
  },
  methods: {
    ...mapActions(["updateOutputAudioDevice", "updateInputAudioDevice","updateOutputAudioVolume"]),
    getAudioDevices() {
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.deviceId && device.label) {
                if (device.kind === "audiooutput") {
                  this.devicesOutput.push(device);
                }

                if (device.kind === "audioinput") {
                  this.devicesInput.push(device);
                }
              }
            });
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    updateOutputDevice() {
      this.updateOutputAudioDevice(this.outputDevice);
    },
    updateInputDevice() {
      this.updateInputAudioDevice(this.inputDevice);
    },
    updateOutputVolume(value) {
      this.updateOutputAudioVolume(value)
    },
    testMicrophone() {
      this.testingMicrophone = !this.testingMicrophone;

      if (!this.testingMicrophone) {
        window.cancelAnimationFrame(this.rafID);
        this.canvasContext.clearRect(0,0,this.WIDTH, this.HEIGHT);
      }

      if (!this.canvasContext) {
        this.canvasContext = document.getElementById( "meter" ).getContext("2d");
      }


      if (navigator.mediaDevices) {
        const constraints = (window.constraints = {
          audio: true,
          video: false,
        });
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(this.handleSuccess);
      }
    },
    handleSuccess(stream) {
      if (window.stream) {
        window.stream.getAudioTracks().forEach((track) => track.stop());
        window.stream = null;
      } else {
        const audio = document.createElement("audio");
        audio.controls = true;
        audio.autoplay = true;
        window.stream = stream;
        audio.srcObject = stream;
        this.audioContext = new AudioContext();
        this.volumeMeter(window.stream);
      }
      this.stream = window.stream;
    },
    volumeMeter(stream) {
      this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
      this.meter = volumeMeter.createAudioMeter(this.audioContext);
      this.mediaStreamSource.connect(this.meter);
      this.onLevelChange();
    },
    onLevelChange() {
      let fill = (this.meter.checkClipping()) ? '#F7646C' : '#36DA39';
      this.canvasContext.fillStyle = fill;
      this.canvasContext.clearRect(0,0,this.WIDTH,this.HEIGHT);
      this.canvasContext.fillRect(0, 0, this.meter.volume * this.WIDTH * 1.4, this.HEIGHT);
      this.rafID = window.requestAnimationFrame( this.onLevelChange );
    }
  },
  created() {
    this.getAudioDevices();
    this.inputDevice = this.getInputAudioDevice
    this.outputDevice = this.getOutputAudioDevice
    this.outputVolumen = this.getOutputAudioVolumeRound
  },
  computed : {
    ...mapGetters(['getOutputAudioVolumeRound','getInputAudioDevice','getOutputAudioDevice'])
  }
};
</script>

<style scope>
.audio-config-form {
  width: 50%;
  margin-right: var(--column);
}

.microphone {
  padding-bottom: var(--column);
  border-bottom: 1px solid var(--blue-grey);
  margin-bottom: var(--column);
}

#meter {
  background-color : var(--light-menu-chat);
  display: block;
  margin: var(--column) 0;
}
</style>