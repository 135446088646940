<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="entity" :title="title" :submit="submit" :after-submit="afterSubmit" :updating="updating"></the-form>
</template>

<script>

import TheForm from '@/components/crud/TheForm'
import form from './form'
export default {
  props : {
    entity : {
      required : false,
    },
    id : {
      required : false,
      type : String,
      default : "0"
    }
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("configuration.agents.edit") : this.$t("configuration.agents.create")
    },
  },
  data() {
    return {
      updating : false,
      store: 'agents',
      form 
    }
  },
  components : {
    TheForm
  },
  methods: {
    submit(data) {
      data.call = data.call ? 'yes' : 'no'
      data.mail = data.mail ? 'yes' : 'no'
      data.chat = data.chat ? 'yes' : 'no'
      data.automaticAnswer = data.automaticAnswerAlt ? 'yes' : 'no'
      data.hasDashboard = data.hasDashboard ? 'yes' : 'no'
      data.originalExtension = data.extension
      data.enableSendTemplate = data.enableSendTemplate ? 1 : 0;
      if (data.call === 'no') {
        data.extension = null
      }

      delete data.automaticAnswerAlt
      return data
    },
    async afterSubmit({ form }) {
      if (form.call == 'yes') {
        this.updateExtension(form.originalExtension, form.call === 'yes')
      }
    },
    async updateExtension(ext, used) {
      try {
        this.updating = true
        const id = ext.replace('/api/extensions/', '')
        const extension = await this.$store.dispatch('extensions/one', id)
        extension.used = used ? 'true' : 'false'
        extension.organization = extension.organization['@id'];
        await this.$store.dispatch('extensions/update', { id, payload : extension })
      } catch (error) {
        console.log(error)
      } finally {
        this.updating = false
      }
    }
  },
  created() {
    this.$store.dispatch('form/setFormFullValue', { index: 'user', value : [] })
  }
}
</script>

<style>

</style>