<template>
	<div class="locale-changer">
        <el-select v-model="$i18n.locale" placeholder="Select">
            <el-option v-for="(lang, i) in langs" :key="`Lang${i}`" :label="$i18n.messages[$i18n.locale]['languages'][lang]" :value="lang"></el-option>
        </el-select>
	</div>
</template>

<script>
import userapi from '@/api/user'
export default {
	name: "locale-changer",
	data() {
		return { langs: ["es", "en", "pt"] };
	},
	computed: {
		language() {
			return this.$i18n.locale;
		}
	},
	watch: {
		language(value) {
			userapi.changeLanguague(value);
		}
	}
};
</script>
