import { render, staticRenderFns } from "./TheVoicemail.vue?vue&type=template&id=1b021e6d&scoped=true&"
import script from "./TheVoicemail.vue?vue&type=script&lang=js&"
export * from "./TheVoicemail.vue?vue&type=script&lang=js&"
import style0 from "./TheVoicemail.vue?vue&type=style&index=0&id=1b021e6d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b021e6d",
  null
  
)

export default component.exports