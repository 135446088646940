import auth from '@/api/auth'
import cookie from '@/plugins/cookie';

const state = {
	token: cookie.getCookie()
};

const getters = {
  isAuthenticated: state => !!state.token,
  geToken : state => state.token
};

const mutations = {
	SET_TOKEN : (state, token) => state.token = token,
  DELETE_TOKEN : (state) => state.token = ''
};

const actions = {
	setToken({ commit }, token) {
		commit('SET_TOKEN', token)
		const date = new Date();
		date.setHours(date.getHours() + 12)
    const expires = "expires=" + date.toUTCString()
    document.cookie ="hiper_token=" + token + ";" + expires + ";path=/"
	},
	login({ commit }, user) {
		return new Promise((resolve, reject) => {
				auth.loginCheck(user.email, user.password)
				.then((response) => {
						commit('SET_TOKEN', response.data.token);
						const date = new Date();
						date.setHours(date.getHours() + 12)
						const expires = "expires=" + date.toUTCString();
						document.cookie ="hiper_token=" + response.data.token + ";" + expires + ";path=/";
						resolve(true);
				})
				.catch((e) => reject(e));
		});
}
}

export default {
	state,
	getters,
	mutations,
	actions,
}
