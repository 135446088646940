<template>
  <div>
    <div v-if="contact" style="width: 100%; display: flex; align-items: center; flex-direction: column;">
      <p class="text-center" style="width: 100%" >Te esta llamando</p>
      <user-item :user="contact"  :show-fullname="false" :show-status="false"  class="contact"/>
      <p class="text-center name">{{ contact.visiblename }}</p>
    </div>
    <p v-else class="text-center name">{{ $t("softphone.incomingcall.title", { displayname : displayName }) }} <br> {{ callerid }}</p>
    <div class="buttons">
      <answer-button  :janus="janus" :jsep="jsep"></answer-button>
      <decline-button :janus="janus"></decline-button>
    </div>
  </div>
</template>

<script>
import UserItem from '../common/UserItem';
import AnswerButton from '../webrtc/AnswerButton'
import DeclineButton from '../webrtc/DeclineButton'
import { mapGetters } from 'vuex';
export default {
  components : {
    UserItem, 
    AnswerButton,
    DeclineButton,
  },
  props : {
    janus : {
      required : true,
    },
    jsep : {
      required : false,
      default : false
    },
    displayName :{
      required : true,
    },
    callerid : {
      required : true
    },
    stopAudio:  {
      required: true
    }
  },
  computed : {
    ...mapGetters('contacts', ['getContactByVisibleExtension']),
    contact() {
      return this.getContactByVisibleExtension(this.callerid)
    }
  },
}
</script>

<style scoped>

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 80%;
    justify-content: space-evenly;
    margin: 0 auto;
  }

  .contact {
    width: auto;
  }
  .name {
    font-weight: bold;
  }
</style>