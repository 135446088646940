<template>
  <el-dialog :title="$t('help.title')" :visible.sync="show" class="help" width="60%">
    <el-tabs :tab-position="!mobile ? 'left' : 'top'">
    <el-tab-pane :label="$t('help.connectivity-test')">
      <TheConnectivityTest :title="$t('ping.connectivity-test-coop')" :info="corpoData" :server="corpoJanus.server()" v-if="corpoJanus && corpoJanus.server()"/> 
      <TheConnectivityTest :title="$t('ping.connectivity-test-agent-console')" :info="agentData" :server="agentJanus.server()" v-if="agentJanus && agentJanus.server()"/>
    </el-tab-pane>
    <el-tab-pane :label="$t('help.tours')">
      <TheTourItem v-for="tour in tours" :key="tour.id" :tour="tour" @click.native="show = false"/>
    </el-tab-pane>
  </el-tabs>
  </el-dialog>
</template>

<script>
import TheConnectivityTest from "./TheConnectivityTest"
import TheTourItem from "@/components/modal-tour/TheTourItem"
import { mapGetters } from "vuex"
export default {
  name: "TheHelpModal",
  data() {
    return {
      mobile: false,
      show : false,
      corpoData : {},
      agentData : {}
    }
  },
  methods: {
    toggleVisible() {
      this.show = !this.show;
    },
    startPing() {
      if (this.agentJanus) {
        this.agentJanus.startPing()
        this.agentJanus.onReceivedPing(data => this.agentData = data)
      }

      if (this.corpoJanus) {
        this.corpoJanus.startPing()
        this.corpoJanus.onReceivedPing(data => this.corpoData = data)
      }
    },
    stopPing() {
      if (this.agentJanus) {
        this.agentJanus.stopPing()
      }
    }
  },
  computed : {
    ...mapGetters({
      agentJanus : 'getAgentJanus',
      corpoJanus : 'getJanus'
    }),
    ...mapGetters('tours', {
      tours : 'getTours'
    })
  },
  components : {
    TheTourItem, 
    TheConnectivityTest
  },
  watch : {
    show(value) {
      if (value) {
        this.startPing()
      } else {
        this.stopPing()
      }
    }
  }
}
</script>

<style>

</style>