<template>
	<div class="agent-in-break">
		<div v-if="type == 'ilustration'" class="ilustration">
			<p>{{ $t("agent-console.you-are-in-break", { name : name }) }}</p>
			<img src="@/img/undraw_coffee_break_j3of.svg" />
			<Chronometer :timestamp="startedAt"/>
			<p>{{ $t("agent-console.click") }} <span @click="closeBreak">{{ $t("agent-console.here") }}</span> {{ $t("agent-console.to-get-out-of-the-break") }}</p>
		</div>
		<div v-else class="header">
			<p class="mb-0">{{ $t("agent-console.you-are-in-break") }} <span class="break-name">'{{name}}'</span></p>
			<Chronometer :timestamp="startedAt"/>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import Chronometer from "@/components/common/Chronometer";
export default {
	name: "AgentInBreak",
	props: {
		name: {
			required: true,
		},
		startedAt: {
			required: true,
		},
		type: {
			default: "ilustration",
			required: false,
		},
	},
	components : {
		Chronometer
	},
	methods : {
		...mapActions(['unBreak']),
		closeBreak() {
			this.unBreak();
		}
	}
};
</script>

<style scoped>
img {
	width: 20em;
}

.agent-in-break .ilustration {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 20%;
	text-align: center;
}

.agent-in-break .ilustration .chronometer {
	margin: var(--column) 0;
}

.agent-in-break .ilustration p span {
	color: var(--blue);
	cursor: pointer;
}

.agent-in-break .header {
	background-color: #F56C6C;
	border-radius: var(--radius);
	color: white;
	display: inline-flex;
	justify-content: space-between;
	padding: var(--column);
	width: 100%;
}
</style>
