<template>
  <el-form v-loading="sending" v-if="!sended">
    <el-form-item :label="$t('change-password.new-password')">
    <el-input v-model="form.password" show-password @keyup.native="validation"></el-input>
      <password v-model="form.password" :strength-meter-only="true"/>
    </el-form-item>
    <el-form-item :label="$t('change-password.repeat-password')">
      <el-input v-model="form.repeatPassword" show-password></el-input>
    </el-form-item>
    <p v-show="form.password !== form.repeatPassword">{{ $t("change-password.the-password-must-be-equal") }}</p>
    <p class="mb-0">{{ $t("change-password.must-have") }}:</p>
    <div class="tags">
      <el-tag size="small" :type="rules.minCharacters ? 'success' : 'info'">{{ $t("change-password.rules.ten-character") }}</el-tag>
      <el-tag size="small" :type="rules.atLeastOneMayus ? 'success' : 'info'" >{{ $t("change-password.rules.at-least-one-mayus") }}</el-tag>
      <el-tag size="small" :type="rules.atLeastOneMinus ? 'success' : 'info'" >{{ $t("change-password.rules.at-least-one-minus") }}</el-tag>
      <el-tag size="small" :type="rules.atLeastOneNumber ? 'success' : 'info'" >{{ $t("change-password.rules.at-least-one-number") }}</el-tag>
      <el-tag size="small" :type="rules.atLeastOneChar ? 'success' : 'info'" >{{ $t("change-password.rules.at-least-one-special-char") }}</el-tag>
    </div>
    <el-button :disabled="!isValid" type="primary" @click.native="onClick">{{ $t("common.update") }}</el-button>
  </el-form>
  <div v-else>
    <NotificationABM :status="success" :show-message="false" :error-title="errorTitle" :success-title="successTitle"></NotificationABM>
    <div v-if="!user">
      <p v-if="success" class="mb-0 text-center">{{ $t("change-password.your-session-will-be-closed-in", { seconds : seconds }) }}</p>
      <el-button v-else type="text">{{ $t("common.go-back") }}</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NotificationABM from "@/components/common/NotificationABM"
import Password from 'vue-password-strength-meter'
export default {
  props : {
    user : {
      required : false,
    }
  },
  data() {
    return {
      form : {
        password : '',
        repeatPassword: '',
      },
      rules : {
        minCharacters: false,
        atLeastOneMayus: false,
        atLeastOneNumber: false,
        atLeastOneChar: false,
        atLeastOneMinus: false,
      },
      sending : false,
      sended : false,
      interval : null,
      seconds : 5,
      success : false,
    }
  },
  methods : {
    ...mapActions(["changePassword","changeUserPassword"]),
    validation() {
      this.rules.atLeastOneMinus = /[a-z]/g.test(this.form.password)
      this.rules.atLeastOneNumber = /\d/g.test(this.form.password)
      this.rules.atLeastOneMayus = /[A-Z]/g.test(this.form.password)
      this.rules.minCharacters = this.form.password.length >= 10 ? true : false
      this.rules.atLeastOneChar = /[-_%&/*?¡¿!#$;,:.<>]/g.test(this.form.password)
    },
    async onClick() {
      this.$emit('changingPassword', true)
      this.sending = true
      try {
        if (!this.user) {
          await this.changePassword(this.form.password)
        } else {
          await this.changeUserPassword({ id : this.user.id, password : this.form.password })
        }
        this.sended = true
        this.success = true
        if (!this.user) {
          setInterval(() => {
            this.seconds--
            if (this.seconds == 0) {
              clearInterval(this.interval)
            }
          }, 1000)
          setTimeout(() => window.location.href = '/logout', 5000)
        } else {
          this.$emit('changingPassword', false)
        }
      } catch (e) {
        this.sended = true
        this.$emit('changingPassword', false)
      }
    },
    reset() {
      this.form.password = ''
      this.rules.minCharacters= false
      this.rules.atLeastOneMayus= false
      this.rules.atLeastOneNumber= false
      this.rules.atLeastOneChar= false
      this.sending = false
      this.sended = false
      this.interval = null
      this.seconds = 5
      this.success = false
    }
  },
  computed : {
    ...mapGetters({firebase : 'getFirebase'}),
    isValid() {
      return this.rules.atLeastOneNumber
        && this.rules.atLeastOneMayus
        && this.rules.minCharacters
        && this.rules.atLeastOneChar
        && this.form.repeatPassword === this.form.password
    },
    errorTitle() {
      return !this.user ? this.$t('change-password.there-was-a-problem-trying-to-change-your-password') : this.$t('change-password.there-was-a-problem-trying-to-change-password', { name : this.user.name + " " + this.user.lastname })
    },
    successTitle() {
      return !this.user ? this.$t('change-password.your-password-was-changed') : this.$t('change-password.password-was-changed', { name : this.user.name + " " + this.user.lastname })
    }
    
  },
  components : {
    NotificationABM,
    Password
  },
}
</script>

<style scoped>
.el-tag {
  margin-right: var(--column);
  margin-bottom: var(--column)
}
</style>