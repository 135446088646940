<template>
    <div class="item">
        <user-item :user="item" v-if="item.type === 'user'" :show-status="false" :show-fullname="false" style="width: auto; margin-right: calc(var(--column) / 2)"></user-item>
        <div class="box" :class="{'center': center}" v-else-if="item.type === 'queue'">
            <el-avatar style="background-color: var(--blue);" shape="circle">
                <p>C</p>
            </el-avatar>
        </div>
        <div class="box" :class="{'center': center}" v-else-if="item.type === 'agent'">
            <el-avatar style="background-color: var(--blue);" shape="circle">
                <p>A</p>
            </el-avatar>
        </div>
        <div class="box" :class="{ 'center' : center }" v-else-if="item.type === 'bot'">
            <i class="fas fa-robot"></i>
        </div>
        <div class="box" :class="{ 'center' : center }" v-else-if="item.type === 'department'">
            <i class="fas fa-building"></i>
        </div>
        <div class="box" :class="{ 'center' : center }" v-else>
            <i class="fas fa-phone"></i>
        </div>
        <div class="title">
            <p style="margin-bottom: calc(var(--column) / 2)">{{ item.title }}</p>
            <div class="other-content">
                <slot name="other-content"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import UserItem from "@/components/common/UserItem";
export default {
    name : "ListItem",
    props : {
        center : {
            required : false,
            default : false
        },
        item : {
            required : true,
        }
    },
    components : {
        UserItem
    }
}
</script>

<style scoped>
    .item {
        align-items: center;
        display: inline-flex;
        line-height: 1;
        position: relative;    
    }

    .box {
        background-color: var(--blue);
        border-radius: 50%;
        color: white;
        display: inline-block;
        height: 40px;
        line-height: 40px;
        text-align: center;
        text-transform: uppercase;
        width: 40px;
        margin-right: calc(var(--column) / 2) 
    }

    .title {
        display: inline-block;
    }

    .other-content {
        color: var(--blue-grey);
        font-size: 12px;
    }

    .box.center {
        margin-bottom: calc(var(--column) / 2);
        margin-right: 0px;
    }
</style>