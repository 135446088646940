import i18n from '@/i18n'

export default [
    { index : 'id', label : '#id', sortable : true, width: '100', },
    { index : 'name.lastname', label : i18n.tc('configuration.users.name-lastname') , type : 'join', options : ["name", "lastname"], joinBy : " ", withAvatar: "picture" },
    { index : 'email', label : i18n.tc('configuration.users.email') },
    { index : 'organizations', label : i18n.tc('configuration.users.organizations'), type : 'tags', options: ["name"] },
    { index : 'roles', label : i18n.tc('configuration.users.roles'), type : 'tags'},
    { index : 'isActive', label : i18n.tc('configuration.users.is-active'), type : 'boolean'},
    { index : 'uid', label : 'UID', 'hide in table' : true},
    { index : 'hasVideoCall', label : i18n.tc('configuration.users.has-videocall'), 'hide in table' : true, type : 'boolean'},
    { index : 'extensions', label : i18n.tc('configuration.users.extensions'), 'hide in table' : true, type : 'tags' , options : ['name'," ",'extension','@','organization.name']},
    { index : 'organizationSessionsAlt.name', label : i18n.tc('configuration.users.where-to-take-the-sessions'), 'hide in table' : true }
]