<template>
  <div class='main-content'>
    <button @click="goBack">
      <i class="fas fa-angle-left"></i>
      {{ $t('common.go-back') }}
    </button>
    <div>
      <h2>{{ $t("queues-details.title", { name: queue.name }) }}</h2>
    </div>
    <div>
      <div class='queue-stats'>
        <h3 class='d-none'>{{ $t('queues-details.stats.title') }}</h3>
        <ul>
          <li class='box'>
            <b><i class='i-blue fas fa-chart-line'></i> {{ $t('queues-details.stats.service-level') }}</b>
            <span>{{ queue.sla }}%</span>
          </li>
          <li class='box'>
            <b><i class='i-green fas fa-phone-alt'></i> {{ $t('queues-details.stats.calls-in-course') }}</b>
            <span>{{queue.inCurseCalls}}</span>
          </li>
          <li class='box'>
            <b><i class='i-blue fas fa-users'></i> {{ $t('queues-details.calls.in-queue') }}</b>
            <span>{{queue.waitingCalls}}</span>
          </li>
          <li class='box'>
            <b><i class='i-red fas fa-clock'></i> {{ $t('queues-details.stats.calls-avg') }}</b>
            <span>{{queue.avgCalls}}</span>
          </li>
          <li class='box'>
            <b><i class='i-violet fas fa-tachometer-alt  '></i> {{ $t('queues-details.stats.queue-max') }}</b>
            <span>{{queue.maxCalls}}</span>
          </li>
          <li class='box'>
            <b><i class='i-red fas fa-clock'></i> {{ $t('queues-details.stats.avg-wait') }}</b>
            <span>{{queue.avgWaitCalls}}</span>
          </li>
        </ul>
      </div>
      <div class='queue-charts'>
        <div class='box'>
          <h3><i class='i-green fas fa-phone-alt'></i> {{ $t('queues-details.calls.title') }}</h3>
          <div>
            <apexchart type="donut" :options="getCallsChart.options" :series="getCallsChart.options.series" class="donut" />
            <ul class='chart-values'>
              <li class='chart-value-green'>
                <b>{{ queue.attendedCalls }}</b>
                <span>{{ $t('queues-details.calls.attended') }}</span>
              </li>
              <!-- <li class='chart-value-blue'>
                <b>{{ queue.waitingCalls }}</b>
                <span>{{ $t('queues-details.calls.in-queue') }}</span>
              </li> -->
              <li class='chart-value-red'>
                <b>{{ queue.abandonCalls }}</b>
                <span>{{ $t('queues-details.calls.abandoned') }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class='box'>
          <h3><i class='i-red fas fa-headset'></i> {{ $t('queues-details.agents.title') }}</h3>
          <div>
            <apexchart type="donut" :options="getAgentsChart.options" :series="getAgentsChart.options.series" class="donut" />
            <ul class='chart-values'>
              <li class='chart-value-green'>
                <b>{{ queue.totalAgentsAvaliables }}</b>
                <span>{{ $t('queues-details.agents.available') }}</span>
              </li>
              <li class='chart-value-blue'>
                <b>{{ queue.totalAgentsBusy }}</b>
                <span>{{ $t('queues-details.agents.busy') }}</span>
              </li>
              <li class='chart-value-orange'>
                <b>{{ queue.totalAgentsInBreak }}</b>
                <span>{{ $t('queues-details.agents.in-break') }}</span>
              </li>
              <li class='chart-value-red'>
                <b>{{ queue.totalAgentsOffline }}</b>
                <span>{{ $t('queues-details.agents.offline') }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  name : "TheQueuesDetails",
  methods:{
    goBack() {
      this.$router.push({ name: "queuesDashboard"});
    },
  },
  computed:{
    getCallsChart( ){
      return {
        options: {
          legend: { 
            show: false,
          },
          series: [
            parseInt(this.queue.attendedCalls),
            parseInt(this.queue.abandonCalls),
          ],
          labels: [
            this.$t('queues-details.calls.attended'),
            this.$t('queues-details.calls.abandoned')
          ],
          colors: [ "#B5F2B1", "#FFB7AD"],
          plotOptions: { 
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: { show: true, label: this.$t('queues-details.calls.total'), fontSize: "28px", fontWeight: 600, color: "#373d3f"  }
                }
              }
            } 
          }
        }
      }
    },
    getAgentsChart( ){
      return {
        "options": {
          "legend": { "show": false },
          "series": [
            this.queue.totalAgentsAvaliables,
            this.queue.totalAgentsBusy,
            this.queue.totalAgentsInBreak,
            this.queue.totalAgentsOffline,
          ],
          "labels": [
            this.$t('queues-details.agents.available'),
            this.$t('queues-details.agents.in-call'),
            this.$t('queues-details.agents.in-break'),
            this.$t('queues-details.agents.offline'),
          ],
          "colors": [ "#B5F2B1", "#9CCBFB","#FDD998", "#FFB7AD"],
          "plotOptions": { 
            "pie": {
              "donut": {
                "labels": {
                  show: true,
                  total: { 
                    show: true,
                    label: this.$t('queues-details.calls.total'),
                    fontSize: "28px",
                    fontWeight: 600,
                    color: "#373d3f",
                    formatter: () => this.queue.totalAgents
                  }
                }
              }
            } 
          }
        }
      }
    },
    queue() {
      return this.$store.getters[`getQueueDashboardFromList`](this.$route.params.id)
    }
  },
}
</script>

<style scoped>
button {
  outline: none;
  border: none;
  background-color: transparent;
}

.queue-stats{
  margin: var(--column) 0;
}

.queue-stats ul{
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  /* grid-template-columns: repeat( 5, 1fr ); */
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--column);
  width: 100%;
}

.queue-stats li{
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: calc( var(--column) / 2 );
  padding: var(--column);
}

.queue-stats li b{
  font-weight: normal;
  font-size: 1.12rem;
  color: #808181;
}

.queue-stats li span{
  font-size: 1.5rem;
  font-weight: bold;
}

.queue-charts{
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: var(--column);
  width: 100%;
}

.queue-charts .box {
  flex: 1
}

.queue-charts .box h3{
  font-size: 1.25rem;
}

.queue-charts .box i,
.queue-stats i:not(.i-arrow){
  display: inline-flex;
  margin-right: 10px;
  width: 2em;
  height: 2em;
  line-height: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.queue-charts .box{
  padding: var(--column);
}

.queue-charts .box > div{
  display: flex;
  gap: calc( var(--column) / 2 );
}
.queue-charts .box > div .donut{
  width: 63%;
}

.queue-charts .box > div .donut + *{
  flex: 1;
}

.chart-values{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--column);
}

.chart-values li{
  display: flex;
  flex-direction: column-reverse;
  padding-left: 2em;
}

.chart-values li span{
  display: flex;
  align-items: center;
  font-size:1.1rem;
  color: #808181;
}

.chart-values li span::before{
  display: inline-block;
  content: '';
  width: 1.75em;
  height: 1.75em;
  margin-right: calc( var(--column) / 2);
  border-radius: 50%;
  margin-left: calc( (1.75em + calc( var(--column) / 2) )* -1 )
}
.chart-values li b{
  font-size: 1.5rem;
  font-weight: bold;
}

.chart-values li.chart-value-orange span::before{
  background: #FDD998;
}

.chart-values li.chart-value-red span::before{
  background: #FFB7AD;
}
.chart-values li.chart-value-blue span::before{
  background: #9CCBFB;
}
.chart-values li.chart-value-green span::before{
  background: #B5F2B1;
}

.queue-stats i.fa-long-arrow-alt-up{
  color: var(--green-500);
}

.queue-stats i.fa-long-arrow-alt-down{
  color: var(--red-500);
}

.queue-stats i.i-blue{
  background: var(--blue-100);
  color: var(--blue-500);
}

.queue-charts i.i-green,
.queue-stats i.i-green{
  background: var(--green-100);
  color: var(--green-500);
}

.queue-charts i.i-red,
.queue-stats i.i-red{
  background: var(--red-100);
  color: var(--red-500);
}

.queue-stats i.i-violet{
  background: var(--violet-100);
  color: var(--violet-500);
}

/* .main-content{
  background: none;
} */

.main-content .box{
  background: white;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 0 3px rgba(155,155,155,.15);
}
</style>