import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const languages = ['es','en', "pr", "pt"];

function loadLocaleMessages() {
	const messages = {};
	const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const name = matched[1].split("-");
			const locale = name[1];
			messages[locale] = locales(key);
			languages.push(locale);
		}
	})
	return messages;
}

let userLang = navigator.language || navigator.userLanguage; 

if (/-/.test(userLang)) {
	userLang = userLang.split("-").shift();
}

export default new VueI18n({
	locale: languages.includes(userLang) ? userLang : 'en',
	fallbackLocale: "en",
	messages: loadLocaleMessages(),
});
