import AppView from '../../views/AppLayout'
import RequireAuth from '../auth'
import DefaultRoutes from './default'

import MessagesRoutes from './messages'
import ContactsRoutes from './contacts'
import AgentDashboard from './agent-dashboard'
import AgentConsoleRoutes from './agent-console'
import ReportsRoutes from './reports'

const routes = [
  ...DefaultRoutes,
  ...AgentDashboard,
  ...ContactsRoutes,
  ...MessagesRoutes,
  ...AgentConsoleRoutes,
  ...ReportsRoutes
]

export default [
  {
		path : '/',
		components : { 
			appView : AppView
		},
		beforeEnter : RequireAuth,
    children : routes
	}
]