<template>
  <section class="box">
    <header>
      <section>
      <slot name="dropdown" />
      <h3>{{ title }}</h3>
    </section>
      <button v-if="!isStatic" @click="show = !show">
        <i :class="{
          'fas fa-chevron-down': !show,
          'fas fa-chevron-up' : show
        }"></i>
      </button>
    </header>
    <slot v-if="show"/>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    },
    isStatic:{
      required: false,
      default: false
    }
  },
  data() {
    return {
      show: true
    }
  }
}
</script>

<style scoped>
  header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  header section {
    display: inline-flex;
    gap: 8px;
    align-items: center;
  }

  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }

  header button {
    outline: none;
    border: none;
    background: transparent;
    color: #606266;
  }
</style>