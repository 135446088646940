<template>
  <el-dialog
    title="Crear usuario"
    :visible="visible"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    @closed="closed"
  >
    <div
      v-loading="creatingUser"
      v-if="!userCreated"
    >
      <el-form
        :model="form"
        label-width="120px"
        label-position="top"
        class="form"
        size="small"
      >
        <el-form-item
          :label="$t('common.fullname')"
          prop="name"
          :error="errors.name"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('common.email')"
          prop="email"
          :error="errors.email"
        >
          <el-input v-model="form.email"></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          @click="show = false"
        >{{
          $t("common.cancel")
        }}</el-button>
        <el-button
          size="small"
          type="primary"
          @click="createUser"
        >{{
          $t("common.save")
        }}</el-button>
      </span>
    </div>
    <div
      v-else
      class="created"
    >
      <Success class="success" />
      <p>
        {{ $t("common.click") }}
        <el-button
          type="text"
          @click="show = false"
        >{{
          $t("common.here")
        }}</el-button>
        {{ $t("common.to-go-back-to-the-form") }}
      </p>
    </div>
  </el-dialog>
</template>
<script>
import Success from "@/components/icons/Success";
import { mapMutations } from "vuex";
import ticketapi from "@/api/tickets"
export default {
  props: {
    show: {
      required: true,
      default: false
    }
  },
  data() {
    return {
      visible: this.show,
      form: {
        name: "",
        email: "",
      },
      errors: {
        name: "",
        email: "",
      },
      creatingUser: false,
      userCreated: false,
      rules : {
        value : [
          { required : true, message : 'Falta el usuario'}
        ]
      }
    }
  },
  methods: {
    ...mapMutations(['ADD_USER_TO_DATA_TO_CREATE_TICKET']),
    closed() {
      this.form = {
        name: "",
        email : "",
      }
      this.errors = {
        name: "",
        email : "",
      },
      this.creatingUser = false
      this.userCreated = false
    },
    createUser() {
      this.creatingUser = true;
      ticketapi
        .createUser(this.form)
        .then((response) => {
          this.userCreated = true;
          this.creatingUser = false;
          this.ADD_USER_TO_DATA_TO_CREATE_TICKET(response.data.data)
          this.$emit('userWasCreated', response.data.data)
        })
        .catch((errors) => {
          this.creatingUser = false;
          if (errors.response.status === 422) {
            const data = errors.response.data;
            if (data.detail) {
              if (data.detail.name) {
                this.errors.name = data.detail.name[0];
              }

              if (data.detail.email) {
                this.errors.email = data.detail.email[0];
              }
            }
          }
        });
    }
  },
  components: {
    Success
  },
  watch: {
    show(value) {
      this.visible = value
    }
  }
}
</script>