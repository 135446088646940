import http from './http';

export default {
	fetchExtensionData(queryParams) {
		return http.get(`/extensions`, { params : queryParams });
	},
	fetchExtension(id) {
		return http.get(`/extensions/` + id);
	},
	createExtension(data) {
		return http.post(`/extensions`, data);
	},
	updateExtension(data) {
		return http.put(`/extensions/` + data.id, data);
	},
	deleteExtension(id) {
		return http.delete(`/extensions/` + id);
	},
	fetchExtensionsUnused(params) {	
		return http.get(`extensions-unused`, { params });
	},
	setUsedExtensions(payload) {
		return http.post('extension-set-used', payload);
	},
	fetchExactExtension(params) {
		return http.get('extensions/find-exact', { params});
	}
};
