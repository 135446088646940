<template>
  <section class="box">
    <h3>Tags</h3>
    <div class="tagsContainer">
      <the-tag v-for="tag in tags" :key="tag.id" :tag="tag"/>
    </div>
  </section>
</template>

<script>
import TheTag from './TheTag'
export default {
  props: {
    tags: {
      required: true,
      type: Array
    }
  },
  components: {
    TheTag
  }
}
</script>

<style scoped>
h3 {
  font-size: 16px;
}
.box .tagsContainer{
  display: flex;
  gap: 5px;
}
</style>