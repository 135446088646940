<template>
  <div v-loading="loading" :element-loading-text="$t('common.loading-data-please-wait')">
    
    <div class="search" v-if="search.length > 0">
      <div>
        <the-search-input v-model="searchValue" :placeholder="message" @enter="submit"/>
        <el-button type="primary" @click="submit()" v-if="!realTime">{{ $t('common.search') }}</el-button>
      </div>
    </div>

     <div v-if="filters.length > 0"  class="filters">
        <div>
          <el-tag v-for="filter in filteredValues" :key="filter.index" type="info" class="filter-tag" closable @close="removeFilter(filter)" @click.native="showTheFilter(filter)">{{getFilterText(filter)}}</el-tag>
        </div>
        <div class="add-filter">
          <el-dropdown :hide-on-click="false" trigger="click" placement="bottom-start" ref="dropdown" > 
            <el-button class="button-filter" size="small">{{ $t('common.add-filter') }} <i class="el-icon-arrow-down el-icon--right"></i></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-popover placement="right-start" trigger="manual" :visible-arrow="false" v-model="showFilterForm">
                <div label-width="300px" v-if="selectedFilter" @click="showDropdown" size="small" label-position="top" class="popover-content">
                  <div>
                    <el-radio-group v-model="filterSearch" v-if="selectedFilter.type === 'text'">
                      <div v-for="(option, index) in selectedFilter.options" :key="index">
                        <el-radio :label="option" >{{ option }}</el-radio>
                        <el-input size="small" v-model="filterValues[filterIndex]" v-show="option === filterSearch" @keydown.enter.native="showFilterForm = false; submit()"></el-input>
                      </div>
                    </el-radio-group>
                    <div v-if="selectedFilter.type === 'boolean'">
                      <el-switch v-model="filterValues[filterIndex]" :active-text="$t('common.yes')" :inactive-text="$t('common.no')"></el-switch>
                    </div>
                    <div v-if="selectedFilter.type === 'select'">
                      <div style="margin-bottom:0" label="">
                        <el-select v-model="filterValues[filterIndex]" :multiple="selectedFilter.multiple" placeholder="Select" @click.native="showDropdown" filterable>
                          <el-option v-for="item in selectedFilter.options" :key="item.value" :label="item.label" :value="item.value" @click.native="showDropdown">
                        </el-option>
                      </el-select>
                      </div>
                    </div>
                  </div>
                  <div class="filter-buttons">
                    <el-button size="small" type="primary" @click="showFilterForm = false; submit()">{{ $t("common.apply-filter") }}</el-button>
                    <el-button size="small" type="info" @click="showFilterForm = false;">{{ $t("common.cancel") }}</el-button>
                  </div>
                </div>
                <el-dropdown-item v-for="(filter, index) in filters" :key="index" @click.native="handleSelectedFilter(filter.index)" slot="reference" :class="{'selected' : filterIndex === filter.index}">{{ filter.label }}</el-dropdown-item>
              </el-popover>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

  <div @scroll="scroll" :style="{ 'max-height': height + 'px', overflow: 'auto' }" id="table-container">
    <el-table :data="_rows" header-row-class-name="header-table" class="eltable" :border="border" @selection-change="selectionChange" @sort-change="handleSortChange">
      <el-table-column v-if="select === 'multiple'" type="selection" width="55"/>
      <el-table-column v-for="(row, key) in columns" :key="row.index" :class-name="row.className ? row.className : ''" :prop="row.index" :label="row.label" :sortable="row.sortable === 'test' ? false : row.sortable" :width="row.width" show-overflow-tooltip :fixed="row.fixed ? row.fixed :  false">
        <template v-if="row.sortable === 'test'" slot="header">
          <TheSortable @sort-change="handleSortChange" :row="row" :id="key"></TheSortable>
        </template>
        <template slot-scope="scope">
          <slot :name="row.index" v-bind:row="scope.row">
            <the-cell :value="scope.row" :row="row" />
          </slot>
        </template>
      </el-table-column>
    </el-table>
  </div>

  <div class="cards">
    <el-card v-for="(row, index) in _rows" :key="index" shadow="never">
      <div v-for="column in columns" :key="column.index" class="card-row">
        <p class="mb-0">{{ column.label }}</p>
        <the-cell :value="row" :row="column" />
      </div>
    </el-card>
  </div>

  <div class="pagination" v-if="_pagination && _pagination.totalItems > 0">
    <p class="mb-0">{{ $t("pagination.showing-of", { items : _rows.length, totalItems : _pagination.totalItems }) }}</p>
    <el-pagination layout="prev, pager, next" :page-size="_pagination.pageSize ?? rowsShown" :total="_pagination.totalItems" @current-change="change" :current-page="page" hide-on-single-page></el-pagination>
  </div>

  </div>
</template>

<script>
import TheCell from './TheCell'
import TheSearchInput from '@/components/html/TheSearchInput'
import TheSortable from '@/components/ticket/TheSortable'
export default {
  props : {
    pagination : {
      required : false
    },
    rows : {
      required : true,
      default : () => ([]),
      type : Array
    },
    loading : {
      required : false,
      default : true,
      type : Boolean
    },
    translate: {
        required: false
    },
    columns : {
      required : true
    },
    filters : {
      required : false,
      default : () => ([])
    },
    search : {
      required : false,
      default : () => ([])
    },
    border : {
      required : false,
      default : false,
      type : Boolean
    },
    select : {
      required : false,
      type : String,
      validator : (value) => (['simple','multiple'].includes(value))
    },
    realTime: {
      required: false,
      type: Boolean,
    },
    height: {
      required: false,
      default: "auto"
    },
    infiniteScroll: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      searchValue : '',
      filterIndex : '',
      showFilterForm : false,
      filteredValues : [],
      filterOptions : {},
      filterValues : {},
      filterSearch : 'is',
      page: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
      rowsShown: 30,
    }
  },
  components : {
    TheCell,
    TheSearchInput,
    TheSortable
  },
  computed : {
    selectedFilter() {
      return this.filters.find(filter => filter.index === this.filterIndex)
    },
    message() {
      return this.$t("common.search-by") + this.columns
        .filter(column => {
          let columns = []

          if (this.search) {
            if (this.search instanceof Array) {
              columns = this.search.map(search => typeof search === 'string' ? search : search.properties).flat()
            } else {
              columns = this.search.properties
            }
          }

          return columns.includes(column.index) || column.type === 'join' && columns.find(search => column.options.includes(search))

        })
        .map(column => column.label.toLowerCase()).join(', ') + '...'
    },
    _rows() {
      if (!this.realTime) return this.rows;
      const reg = new RegExp(this.searchValue, "i");

      const rows =  this.rows.filter((row) => {
        if (!this.searchValue) return row;

        let filter = false;

        if (this.search instanceof Array) {
          this.search.forEach((index) => {
            if (reg.test(row[index])) {
              filter = true;
            }
          })
        }

        if (filter) {
          return row;
        }
      });

      if (!this.rowsShown)

      if (rows.length < this.rowsShown || this.infiniteScroll) {
        return rows;
      }

      return this.rows.slice((this.page - 1) * this.rowsShown, this.page * this.rowsShown);
    },
    _pagination() {
      if (this.pagination) return this.pagination;
      if (this.pagination === null) return null;
      return {
        totalItems: !this.searchValue ? this.rows.length : this._rows.length,
      }
    }
  },
  methods : {
    handleSortChange(payload) {
      this.$emit("sortChange", payload)
    },
    scroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit("scrollInBottom")
      }
    },
    selectionChange(value) {
      this.$emit('selection-change', value)
    },
    handleSelectedFilter(index) {
      this.filterIndex = index
      this.showFilterForm = true
      if ('options' in this.selectedFilter) {
        this.filterOptions[this.filterIndex] = this.selectedFilter.options[0];
        this.filterSearch = this.selectedFilter.options[0]
      }
    },
    showDropdown() {
      this.$refs.dropdown.show()
    },
    showTheFilter(filter) {
      this.$refs.dropdown.show();
      setTimeout(() => {
        this.handleSelectedFilter(filter.index)
      }, 200)
    },
    getFilterText(filter) {
      let select = filter.multiple ? filter.value.join(',') : filter.value 
      const text = filter.label;

      if (filter.showLabel) {
        if (filter.multiple) {
          select = filter.value.map((value) => {
            const option = filter.options.find(opt => opt.value === value)
            return option.label
          }).join(',')
        } else {
          const option = filter.options.find(opt => opt.value === filter.value)
          select = option.label
        }
      }

      const values = {
        text : `${filter.match} ${filter.value}`,
        boolean: filter.value === 1 || filter.value === 'true' ? 'Si' : 'No',
        select
      }
      return `${text} ${values[filter.type]}`
    },
    removeFilter(filter) {
      this.filteredValues = this.filteredValues.filter(item => item.index !== filter.index)
      delete this.filterValues[filter.index]
      this.submit()
    },
    submit() {
      const params = this.makeParams();
      this.$emit('fetch', params)
    },
    makeParams() {
      const params = {}

      if (this.search) {
        if (this.search instanceof Array) {
          this.search.forEach(index => {
            if (this.searchValue) {
              if (typeof index === 'string') {
                params[index] = this.searchValue
              } else {
                params[index.index] = this.searchValue
              }
            }
          })
        } else {
          if (this.searchValue) {
            params[this.search.index] = this.searchValue
          }
        }
      }

      if (Object.keys(this.filterValues).length > 0) {
        Object.keys(this.filterValues).forEach(index => {
          const filter = this.filters.find(filter => filter.index === index)
          let value = this.filterValues[index]
          if (filter) {
            if (filter.type === 'boolean') {
              if (filter.booleanType === 'numeric') {
                  value = this.filterValues[index] ? 1 : 0
              } else if (filter.booleanType === 'yes|no') {
                value = this.filterValues ? 'yes' : 'no'
              } else {
                value = this.filterValues[index] ? "true" : "false"
              }
            }
          }
          const filteredIndex = this.filteredValues.findIndex(filter => filter.index === index)
          if (filteredIndex < 0) {
            this.filteredValues.push({
              ...filter, 
              value,
              match : this.filterOptions[index]
            })
          } else {
            this.filteredValues[filteredIndex].value = value;
          }
          params[index] = value
        })
      }
      return params;
    },
    change(page) {
      const params = this.makeParams();
      this.page = page;
      this.$emit('change-page', { page, ...params });
    }
  },
  watch : {
    filterSearch(value) {
      this.filterOptions[this.filterIndex] = value;
    },
    showFilterForm(value) {
      if (!value) {
        setTimeout(() => {
          this.$refs.dropdown.hide()
        }, 100)
      }
    },
    _pagination(value) {
      if (value.totalItems === 0) {
        this.page = 1
      }
    }
  },
  created() {
    const queryParams = this.$route.query;
    Object.keys(queryParams).forEach((key) => {
      const value = queryParams[key];

      if (this.search) {
        if (!(this.search instanceof Array)) {
          if (this.search.index === key) {
            this.searchValue = value;
          }
        }
      }

      if (this.filters.length > 0) {
        this.filters.forEach((filter) => {
          if (filter.index === key) {
            this.filteredValues.push({...filter, value, match: "contains" });
            this.filterValues[filter.index] = value;
          }
        })
      }
    });    
  },
}
</script>

<style scoped>

.eltable {
  margin-top: var(--column);
}

.search > div:first-child {
  display: inline-flex;
  min-width: 420px;
}

.search button {
  margin-left: var(--column)
}

.pagination {
  margin-top: var(--column);
  align-items: center;
  display: inline-flex;
}

.filter-buttons {
  display: inline-flex;
  margin-left: var(--column);
}

.filters {
  display: inline-flex;
}

.filter-tag {
  margin-right: var(--column);
  cursor: pointer
}
.selected {
  background-color: #ecf5ff;
  color: #66b1ff;
}

.search {
  margin-bottom: var(--column);
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.cards {
  display: none;
}

.card-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-row p{
  color: #909399;
  font-weight: bold;
}

.popover-content {
  display: inline-flex;
  align-items: self-end;
  justify-content: center;
}
</style>