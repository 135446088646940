<template>
  <main>
    <h2>{{ $t("dashboard.hello", { name : user.fullname}) }},</h2>
    <p>{{ $t("dashboard.this-is-your-activity-of-the-day") }}</p>
    <section>
      <the-info-card class="box" :title="$t('dashboard.login-time')" :seconds="dashboard.times.logged ?? 0" :started="dashboard.logged_started_at ?? 0 "/>
      <the-info-card class="box" :title="$t('dashboard.talking-time')" :seconds="dashboard.times.talking ??  0"/>
      <the-info-card class="box" :title="$t('dashboard.break-time')" :seconds="dashboard.times.break ?? 0" :started="(dashboard.in_break) ? dashboard.break_started_at : 0"/>
      <div class="box only-value" v-if="agent.chat">
        <h3>Chats activos</h3>
        <p>{{ totalChats.active }}</p>
      </div>
      <div class="box only-value" v-if="agent.chat">
        <h3>Chats cerrados</h3>
        <p>{{ totalChats.closed }}</p>
      </div>
      <div class="box only-value" v-if="agent.mail">
        <h3>Tickets abiertos</h3>
        <p>{{ mailDashboard?.totalTicketByStatus?.open.total ?? 0  }}</p>
      </div>
      <div class="box only-value" v-if="agent.mail">
        <h3>Tickets cerrados</h3>
        <p>{{ mailDashboard?.totalTicketByStatus?.closed.total ?? 0  }}</p>
      </div>
    </section>
    <section>
      <div class="box" v-if="agent.call">
        <h3>{{ this.$t('dashboard.call-activity') }}</h3>
        <div>
          <apexchart type="donut" :options="activityCall.options" :series="activityCall.options.series" class="donut" />
        </div>
      </div>
      <div class="box" v-if="agent.chat">
        <h3>{{ this.$t('dashboard.chat-activity') }}</h3>
        <div>
          <apexchart type="donut" :options="activityChat.options" :series="activityChat.options.series" class="donut" />
        </div>
      </div>
      <div class="box" v-if="agent.mail">
        <h3>{{ this.$t('dashboard.activity-of-tickets') }}</h3>
        <div>
          <apexchart type="donut" :options="activityMail.options" :series="activityMail.options.series" class="donut" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import TheInfoCard from "@/components/dashboard/TheInfoCard";
export default {
  data() {
    return {
      chartConfig: {
        chart: {
            width: 450
          },
          responsive: [{
            breakpoint: 1366,
            options: {
              chart: {
                width: 350
              }
            },
        }]
      }
    }
  },  
  computed: {
    ...mapGetters({
      user: 'getUser',
      agent: 'getAgent',
      dashboard: 'getDefaultDashboardValues',
      mailDashboard: 'getAgentMailDashboard',
      activities: 'getActivityList'
    }),
    activityChat() {
      const activities = this.chatsOfToday
      const normal = activities.filter(item => item.data.from === 'web').length
      const whatsapp = activities.filter(item => item.data.from === 'whatsapp').length
      const facebook = activities.filter(item => item.data.from === 'facebook').length
      const instagram = activities.filter(item => item.data.from === 'instagram').length
      return {
        options: {
          ...this.chartConfig,
          title : {
            text : 'Actividad de chat',
            align : 'center',
            style : {
              color: 'white'
            }
          },
          legend: {
            position: 'bottom',
          },
          series: [ normal, whatsapp, facebook, instagram],
          labels: ['Web', "Whatsapp", "Facebook", "Instagram"],
          colors : ['#409EFF', '#25D366', '#4267B2', '#d6249f'],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                  show: true,
                    label: this.$t("dashboard.total"),
                    fontSize: "22px",
                    fontWeight: 600,
                    color: "#373d3f",
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  }
                }
              }
            }
          }
        },
      }
    },
    activityCall() {
      const { incoming = 0, outgoing = 0, transfer = 0} = this.dashboard.calls_total
      return {
        options: {
          ...this.chartConfig,
          title : {
            text : this.$t("dashboard.call-activity"),
            align : 'center',
            style : {
              color: 'white'
            }
          },
          legend: {
            position: 'bottom',
          },
          series: [incoming, outgoing, transfer],
          labels: [this.$t("dashboard.ingoing"), this.$t("dashboard.outgoing"), this.$t("dashboard.transfer")],
          colors : ['#FF577F', '#b33d6d','#ff7b57', '#1F306E'],
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                  show: true,
                    label: this.$t("dashboard.total"),
                    fontSize: "22px",
                    fontWeight: 600,
                    color: "#373d3f",
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  }
                }
              }
            }
          },
        },
      }
    },
    activityMail() {
      const { totalTicketsByDept } = this.mailDashboard ?? { totalTicketsByDept : [] }
      const series = totalTicketsByDept ? totalTicketsByDept.map(dept => dept.total) : []
      const labels = totalTicketsByDept ? totalTicketsByDept.map(dept => dept.label) : []
      return {
        options: {
          ...this.chartConfig,
          title : {
            text : 'Actividad de tickets',
            align : 'center',
            style : {
              color: 'white'
            }
          },
          legend: {
            position: 'bottom',
          },
          series,
          labels,
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  total: {
                  show: true,
                    label: this.$t("dashboard.total"),
                    fontSize: "22px",
                    fontWeight: 600,
                    color: "#373d3f",
                    formatter: function (w) {
                      return w.globals.seriesTotals.reduce((a, b) => {
                        return a + b;
                      }, 0);
                    },
                  }
                }
              }
            }
          }
        },
      }
    },
    totalChats() {
      const activities = this.chatsOfToday
      return {
        active: activities.filter(item => item.active).length,
        closed: activities.filter(item => !item.active).length,
      }
    },
    chatsOfToday() {
      const chats = this.activities.filter(item => item.type === 'chat')
        .filter((item) => {
          const { date } = item
          const today = new Date()

          date.setHours(0, 0, 0, 0);
          today.setHours(0, 0, 0, 0);

          if (date.getTime() === today.getTime()) {
            return item
          }
        })
      return chats;
    }
  },
  components: {
    TheInfoCard
  },
  created() {
    this.$store.dispatch('listenAgentsDashboard', this.agent.organization_id)
    this.$store.dispatch('listenAgent')
    if (this.agent.mail) {
      this.$store.dispatch('fetchAgentMailMetrics')
      this.interval = setInterval(() => {
        this.$store.dispatch('fetchAgentMailMetrics')
      }, 60000)
    }
  },
  mounted() {
		this.$title = this.$t("main-menu.dashboard")
	},
  beforeDestroy() {
    if (this.agent.mail) {
      clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>
h2,h3, p {
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  gap: var(--column);
  width: 100%;
  padding: var(--column)
}

section {
  display: flex;
  flex-direction: row;
  gap: var(--column);
  width: 100%;
}

section > div {
  background-color: white;
  padding: var(--column)
}

section .box {
  flex: 1;
}

h3,
section > div p {
  text-align: center;
}

.only-value h3 {
  color: #91A0A5!important;
  font-size: 14px!important;
  margin-bottom: 0.5rem;
}

section p {
  color: var(--dark-blue);
  font-weight: bold;
  font-size: 22px;
}

.donut {
  display: flex;
  justify-content: space-around;
}

</style>