<template>
  <el-dialog :visible.sync="visible" :append-to-body="true" :close-on-click-modal="close" :close-on-press-escape="close" :show-close="close" @closed="showUserInfo" class="account-modal">
    <button class="back" v-if="!show.userInfo" @click="showUserInfo" :disabled="!close"><i class="fas fa-arrow-left"></i></button>
    <the-change-password v-if="show.password"  @changingPassword="toggleClose" ></the-change-password>
    <div class="user-information" v-if="show.userInfo">
      <div>
        <the-change-profile-picture></the-change-profile-picture>
        <p>{{ user.fullname }}</p>
        <p>@{{ user.uid }}</p>
        <el-button type="text" size="small" @click.native="showPasswordChange" v-if="canChangePassword">{{ $t("account-modal.change-password")  }}</el-button>
      </div>
      <the-user-information :user="user"></the-user-information>
    </div>
  </el-dialog>
</template>

<script>
import TheChangePassword from './TheChangePassword'
import TheUserInformation from './TheUserInformation'
import TheChangeProfilePicture from './TheChangeProfilePicture'
import { mapActions, mapGetters } from 'vuex'
export default {
  props : {
    user : {
      required : true
    }
  },
  data() {
    return {
      close : true,
      visible : false,
      show : {
        password : false,
        changeImage : false,
        userInfo : true
      },
      deletingImage : false
    }
  },
  methods : {
    ...mapActions(['updateProfilePicture','changeProfilePicture']),
    toggleVisible() {
      this.visible = !this.visible
    },
    toggleClose() {
      this.close = !this.close
    },
    showPasswordChange() {
      this.show = {
        password : true,
        changeImage : false,
        userInfo : false
      }
    },
    showUserInfo() {
      this.show = {
        password : false,
        changeImage : false,
        userInfo : true
      }
    },
  },
  components : {
    TheChangePassword,
    TheUserInformation,
    TheChangeProfilePicture
  },
  computed : {
    ...mapGetters(['getFirebase']),
    canChangePassword() {
      if (process.env.VUE_APP_CHANGE_PASSWORD !== undefined) {
        return process.env.VUE_APP_CHANGE_PASSWORD === "false" ? false : true;
      }

      return true;
    }
  }
}
</script>

<style scoped>
.user-information {
  display: inline-flex;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
}

.user-information > div:nth-child(1) {
  display: flex;
    flex-direction: column;
    align-items: center;
}

.el-button {
  margin-left: 0px!important
}

.back {
  background-color: white;
  outline: none;
  border: none;
  color: var(--blue-grey)
}

.user-item :deep(img){
  object-fit: scale-down;
  height: 200px;
  width: 300px;
}

.user-item.image :deep(.user-profile)  {  
  background-color: white;
}
</style>