import Rules from '@/components/crud/rules'
import i18n from '@/i18n'
import userapi from '@/api/user'
import store from '@/store'

const fetchUsersWithoutAgent = async (value) => {
    if (value.length < 3) {
        return
    }

    try {
        let { data } = await userapi.fetchUsersWithoutAgents(value)
        data = data.map(user => {
            user['@id'] = '/api/users/' + user.id
            user.organizations = user.organizations.map(organization => {
                organization['@id'] = '/api/organizations/' + organization.id
                return organization
            })
            return user
        })

        store.dispatch('form/setFormFullValue', { index: 'user', value : data })
    } catch (error) {
        console.log(error)
    }
}

export default {
    nodes : [
        { component : 'select', index : 'user', label : i18n.tc('configuration.agents.user'), required : true, 'remothe-method' : fetchUsersWithoutAgent, filterable : true, 'label-value' : ['name','lastname'], notes : "Busque un usuario por su nombre"  },
        { component : 'input', index : 'number', label : i18n.tc('configuration.agents.number'), required : true, type : 'number' },
        { component : 'select', index : 'organization', required : true, label: i18n.tc('configuration.users.where-to-take-the-sessions'),filterable : true, 'values-from' :  {index : 'user', key : 'organizations.@id'}, 'label-value' : 'name' },
        { component : 'input', index : 'groupNumber', label : i18n.tc('configuration.agents.group-number'), required : true, type : 'number' },
        { component : 'checkbox' , index : 'chat', label : i18n.tc('configuration.agents.answer-chats'), 'hideIndex' : 'chatcenter' },
        { component : 'checkbox' , index : 'call', label : i18n.tc('configuration.agents.answer-calls'), 'hideIndex' : 'callcenter' },
        { component : 'checkbox' , index : 'mail', label : i18n.tc('configuration.agents.answer-mails'), 'hideIndex' : 'mailcenter' },
        { component : 'h3', index : 'callcenter', label : 'Configuración de Call Center', hidden : true, children : [
            {
                component: 'select',
                index: 'extension',
                label: i18n.tc('configuration.agents.extension'),
                store: 'extensions',
                params: { used: 'false', pagination: false },
                'label-value': ['name','extension'],
                'add-value-to-list': true,
                'disabled-if-empty': ["user"], 
                notes: "Seleccione un usuario para seleccionar su extensión",
                validations : [
                    { validator : Rules.requiredIf('call'), trigger : 'blur', label : 'je' },
                ],
                'fetch-when' : {
                    index : "organization",
                    param : "organization.id"
                }
            },
            { component : 'checkbox', index : 'automaticAnswerAlt', label : i18n.tc('configuration.agents.automatic-answer')},
            { component : 'checkbox', index : 'hasDashboard', label : i18n.tc('configuration.agents.has_dashboard')},
        ]},
        { component : 'h3', index : 'mailcenter', label : 'Configuración de Mail Center', hidden : true, children : [
            { component : 'input', index : 'osticketUserPasswordAlt', label : i18n.tc('configuration.agents.mail-password')},
        ]},
        {
            component : 'h3', index : 'chatcenter', label : 'Configuración de Chat Center', hidden : true, children: [
                {
                    component: 'select',
                    index: 'departments',
                    label: i18n.tc('configuration.agents.departments'),
                    store: 'departments',
                    params: { pagination: false },
                    'label-value': ['name'],
                    //'add-value-to-list': true,
                    validations : [
                        { validator : Rules.requiredIf('chat'), trigger : 'blur', label : '' },
                    ],
                    'fetch-when' : {
                        index : "organization",
                        param : "organization.id"
                    },
                    multiple : true,
                },
                {
                    component: 'checkbox',
                    index: 'enableSendTemplate',
                    label: i18n.tc('configuration.agents.enabledSendTemplate')
                }
            ]
        }
    ]
}