<template>
    <header>
      <button @click="goBack">
        <i class="fas fa-angle-left"></i>
        {{ $t('common.go-back') }}
      </button>
      <h2>{{ title }}</h2>
    </header>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-pbx"});
    }
  }
}
</script>

<style scoped>
button {
  outline: none;
  border: none;
  background-color: transparent;
}

h2 {
  margin: var(--column) 0;
}
</style>