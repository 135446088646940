<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="chatProvider" :title="title" search="name" :submit="beforeSubmit"></the-form>
</template>

<script>
import TheForm from '@/components/crud/TheForm'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data() {

    let chatProvider = { ...this.entity }

    if (this.entity && this.entity.provider) {
      chatProvider = { ...this.entity, ...this.entity.provider}
    }

    return {
      store: "chatProviders",
      form: {
        nodes: [
          { component: "input", index: "name", required: true, label: this.$t("common.name") },
          { disabled: this.id !== "0", component: 'select', index: "type", label: this.$t("contact-me.chat-center.provider-type"), values : [
            { label: "Gupshup", value: "Gupshup" },
            { label: "Hiperme", value: "Hiperme" },
            { label: "Twilio", value: "Twilio" },
            { label: "Custom", value: "Custom" },
            { label: "Voice Bot", value: "VoiceBot" },
          ]},
          {
            component: "h3", 
            label: this.$t('contact-me.chat-center.provider-config'),
            children: [
              { "hidden": "type:Gupshup", component: "input", index: "appName", label : "App Name"},
              { "hidden": "type:Gupshup", component: "input", index: "apiKey", label : "Api Key" },
              { "hidden": "type:Gupshup", component: "input", index: "appIgId", label : "Instagram App Id"},
              { "hidden": "type:Gupshup", component: "input", index: "apiKeyIg", label : "Instagram App Key" },

              { "hidden": "type:Twilio", component: "input", index: "accountSid", label : "Account Sid"},
              { "hidden": "type:Twilio", component: "input", index: "authToken", label : "Auth Token" },

              { "hidden": "type:Custom", component: "input", index: "webhook", label : "Webhook" },
            ]
          }
        ]
      },
      chatProvider,
    }
  },
  components: {
    TheForm
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? this.$t("contact-me.chat-center.edit-chat-provider") : this.$t("contact-me.chat-center.new-chat-provider")
    }
  },
  methods: {
    beforeSubmit(data) {
      data.organization = this.$store.getters["contactMe/getOrganizationId"]
      return data;
    }
  }
}
</script>