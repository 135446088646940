import createStore from "../helper"
import http from '../../api/http'

const store = createStore('clients')

const state = {
  clientLicenses: [],
}

const getters = {
  getClientLicenses: (state) => state.clientLicenses,
}

const mutations = {
  SET_CLIENT_LICENSES: (state, value) => state.clientLicenses = value,
  ADD_LICENSE: (state, license) => state.clientLicenses.push(license),
  REMOVE_LICENSE: (state, id) => state.clientLicenses = state.clientLicenses.filter(license => license.id !== id),
  UPDATE_LICENSE: (state, license) => state.clientLicenses = state.clientLicenses.map(_license => _license.id === license.id ? license : _license)
}

const actions = {
  async fetchLicenses({ commit }, id) {
    const { data } = await http.get(`/clients/${id}/licenses`);
    commit("SET_CLIENT_LICENSES", data);
  },
  async refreshLicense({ commit }, id) {
    const { data } = await http.get(`/clients/${id}/refresh-licenses`);
    commit("SET_CLIENT_LICENSES", data);
  }
}

store.state = {...store.state, ...state}
store.getters = {...store.getters, ...getters}
store.actions = {...store.actions, ...actions}
store.mutations = {...store.mutations, ...mutations}

export default store
