<template>
  <div class="main-content" v-loading="loading">
    <button class="back" @click="$router.push({ name: 'contact-me-pbx-ivrs' })"><i class="fas fa-angle-left"></i> {{ $t('common.go-back') }}</button>
    <h2>{{ !id ? $t("contact-me.pbx.ivrs.create") : $t("contact-me.pbx.ivrs.edit") }}</h2>
    <el-form label-position="top" size="large" :model="form" :rules="rules" v-if="status === 'form'">
      <el-tabs v-model="tab">
        <el-tab-pane :label="$t('contact-me.pbx.ivrs.basic-information')" name="basic" >
          <h3>{{ $t('contact-me.pbx.ivrs.basic-information') }}</h3>
          <el-form-item :label="$t('contact-me.pbx.ivrs.name')" required :error="errors.name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.ivrs.timeout-time')" :error="errors.timeout">
            <el-input v-model="form.timeout"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.ivrs.loops')" :error="errors.loops">
            <el-input v-model="form.loops"></el-input>
          </el-form-item>
          <el-form-item :label="$t('contact-me.pbx.ivrs.announcement-form')" :error="errors.announcement">
            <el-select v-model="form.announcement" filterable :loading="loadingSystemRecording">
              <el-option :label="$t('common.none')" value="" />
              <el-option v-for="recording in systemRecordings" :key="recording.id" :label="recording.name" :value="recording.id" ></el-option>
            </el-select>
          </el-form-item>
          <el-button @click.native="setTab('options')" type="primary" :disabled="!basicInformationIsValid">{{ $t("common.next") }}</el-button>
        </el-tab-pane>
        <el-tab-pane :label="$t('contact-me.pbx.ivrs.options')" name="options" :disabled="!basicInformationIsValid">
          <h3>{{ $t('contact-me.pbx.ivrs.options') }}</h3>
          <div class="d-inline-flex" style="width: 100%">
            <el-input v-model="pad_option" style="width: 150px" />
            <el-select v-model="type" style="width: 200px" @change="last = ''">
              <el-option v-for="(dest, index) in noAnswerDestination" :key="index" :value="dest.toLowerCase()" :label="dest"></el-option>
            </el-select>
            <el-select v-model="last" filterable style="width: 200px">
              <el-option v-for="(item, index) in destinations" :key="index" :value="item.id" :label="item.name">
              </el-option>
            </el-select>
            <el-button type="primary" @click="addOptionDestination">{{ $t('common.add') }}</el-button>
          </div>
          <div class="destinations">
            <el-tag
              style="display: block"
              v-for="(destination, index) in parsedDestinations"
              :key="index"
              class="tag"
              closable
              @close="handleClose(index)">
              {{ getDestinationLabel(index) }}: {{ destination.name }} ({{ destination.type }})
            </el-tag>
          </div>
          <div>
            <el-button @click="setTab('basic')">{{ $t("common.back") }}</el-button>
            <el-button type="primary" @click="setTab('finish')">{{ $t("common.next") }}</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Finish" name="finish" :disabled="!basicInformationIsValid">
          <h3>Review</h3>
          <the-details :ivr="{ ...form, destinationOptions }"></the-details>
          <el-alert
            v-if="Object.keys(errors).length > 0"
            title="You have some errors, check the forms and try again"
            type="error">
          </el-alert>
          <div>
            <el-button @click="setTab('options')">{{ $t("common.back") }}</el-button>
            <el-button type="primary" @click="submit">Save</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <div class='message' v-else>
      <div>
        <img src="@/img/success.svg" v-if="status === 'saved'"/>
        <img src="@/img/error.svg" v-else/>
        <p> {{ message }} </p>
        <div class="buttons">
          <router-link :to="{ name: 'contact-me-pbx-ivrs' }">{{ $t("common.go-back-to-the-list") }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TheDetails from "./TheDetails.vue";

export default {
  props: {
    entity: {
      required: false,
      default: () => ({
        name: "",
        timeout: 10,
        loops: 0,
        announcement: "",
        dests: {}
      })
    },
    id: {
      required: false,
      default: 0
    }
  },
  data() {
    return {
      form: this.entity,
      tab: "basic",
      errors: {},
      loading: false,
      status: "form",
      rules: {},
      type: "extension",
      last: "",
      pad_option: "t",
      padOptions: [
        't',
        'i',
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9'
      ],
      loadingSystemRecording: true,
      destinationOptions: this.entity.dests
    }
  },
  methods: {
    ...mapActions("pbx_destinations", { fetchDestinations: "all" }),
    setTab(value) {
      this.tab = value;
    },
    async fetchSystemRecordings() {
      this.loadingSystemRecording = true;
      await this.$store.dispatch("pbx_system_recordings/all");
      this.loadingSystemRecording = false;
    },
    async fetchDestinations() {
      this.loadingDestinations = true;
      await this.$store.dispatch("pbx_destinations/all");
      this.loadingDestinations = false;
    },
    getDestinationLabel( val ){
      if( val == 't' ) return 'Timeout';
      if( val == 'i' ) return 'Invalid';
      return val;
    },
    handleClose(index) {
      this.$delete( this.destinationOptions, index );
    },
    addOptionDestination() {
      const index = this.pad_option;
      const id = this.last;
      const type = this.type;
      const name = this.destinations.filter( e => e.id == id )[0]?.name;
      if( ! id || ! index ) return;

      this.$set(this.destinationOptions, index, { id, name, type });
    },
    async submit() {
      this.loading = true;

      try {
        const action = !this.id ? "create" : "update";
        const payload = !this.id ? this.form : { resourceId: this.id, payload: this.form };
        const response = await this.$store.dispatch(`pbx_ivrs/${action}`, payload);
        this.status = "saved";
        console.log(response);
      }catch( error) {
        if (error.response) {
          const { data } = error.response;
          this.errors = data.errors;
        }
      }finally{
        this.loading = false;
      }
    }
  },
  computed: {
    systemRecordings() {
      return this.$store.getters["pbx_system_recordings/getDataAsArray"]
    },
    noAnswerDestination() {
      return Object.keys(this.$store.getters["pbx_destinations/getData"]);
    },
    noAnswerDestinations() {
      return this.$store.getters["pbx_destinations/getData"];
    },
    destinations() {
      return this.noAnswerDestinations[this.type] ?? [];
    },
    parsedDestinations( ){
      if( Object.keys( this.destinationOptions ).length == 0 ) return [];
      const values = {};
      for( let index in this.destinationOptions ){
        if( this.destinationOptions[index] ){
          values[index] = this.destinationOptions[index];
        }

      }

      for( var i in values ) values[i].type = values[i].type.toLowerCase( ); 
      return values;
    },
    message() {
      if (this.status == "saved") {
        return this.$t("common.saved")
      }
      return this.$t("common.not-saved")
    },
    basicInformationIsValid() {
      return this.form.name;
    }
  },
  components: {
    TheDetails
  },
  async mounted( ){
  },
  async created() {
    await this.fetchSystemRecordings();
    await this.fetchDestinations();
    if( this.form.announcement ){
      this.form.announcement = this.form.announcement.id
    }
  }
}
</script>

<style scoped>
.back {
  outline: none;
  border: none;
  background-color: transparent;
}

.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.destinations span{
  margin-top: 5px;
}
</style>