const state = {
    userLoginLogoutReport : []
}

const getters = {
    getUserLoginLogoutReport : (state) => state.userLoginLogoutReport
}

const mutations = {
    SET_USER_LOGIN_LOGOUT_REPORT : (state, values) => (state.userLoginLogoutReport = values)
}

const actions = {
    async fetchReportUsersLoginLogout({commit, rootGetters}, params = {})  {
        const firebase = rootGetters.getFirebase
        const user = rootGetters.getUser
        const { data } = await firebase.functions().httpsCallable('getLoginLogoutReport')({
            ...params,
            data_set : user.organization_session.data_set
        })
        commit('SET_USER_LOGIN_LOGOUT_REPORT', data.data)
        return data.pagination
    }
}

export default {
    namespaced : true,
    actions,
    getters,
    state,
    mutations
}