<template>
  <div class="sortableContainer">
      <el-dropdown class="sortableIcons" trigger="hover" @command="handleCommand">
        <span class="el-dropdown-link">
          {{ row.label }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu class="dropdownMenu" slot="dropdown">
          <el-dropdown-item command="ascending" icon="el-icon-top">
            {{ $t('common.sort.ascending') }}
          </el-dropdown-item>
          <el-dropdown-item command="descending" icon="el-icon-bottom">
            {{ $t('common.sort.descending') }}
          </el-dropdown-item>
          <el-dropdown-item command="null" icon="el-icon-error">
            {{ $t('common.sort.remove-sort') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <i class="icon-helper" :class="icon"></i>
  </div>
</template>
<script>
export default {
  name:"TheSortable",
  data(){
    return {
      order: null
    }
  },
  props: {
    row: {
      require: true
    },
    id: {
      require: true
    }
  },
  methods: {
    handleCommand(command){
      this.order = command == 'null' ? null : command
      this.$emit('sort-change', {
        order: this.order,
        prop: this.row.index
      })
    }
  },
  computed: {
    icon() {
      if (!this.order) {
        return ''
      }

      return this.order === 'descending' ? 'el-icon-bottom' : 'el-icon-top'
    }
  },
  mounted(){
    const key = `order[${this.row.index}]`
    if (this.$route.query[key]) {
      const order = this.$route.query[key] === 'asc' ? 'ascending' : 'descending'
      this.order = order
    }
  }
}
</script>
<style scoped>
.sortableContainer{
  display: flex;
  align-items: center;
}
.sortableContainer .sortableLabel{
  margin: 0;
}
.sortableContainer .dropdownMenu{
  display: flex;
  flex-direction: column;
}
.sortableContainer .icon-helper{
  font-size: 20px;
}
</style>