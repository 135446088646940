<template>
  <div class="crud main-view">
    <div class="header">
      <the-header :title="$t('contact-me.pbx.extensions.title')"></the-header>
      <div class="buttons">
        <el-button type="primary" @click="$router.push({ name: 'contact-me-pbx-extensions-new'})">{{ $t("common.new") }}</el-button>
      </div>
    </div>
    <the-main-crud resource="extensions" :columns="columns" :search="search" id="extension" name="name" @deleted="handleDeleted"></the-main-crud>
  </div>
</template>

<script>

import TheHeader from "../TheHeader.vue";
import TheMainCrud from "../TheMainCrud.vue";
export default {
  data() {
    return {
      columns: [
        { index: "name", label: this.$t("contact-me.pbx.extensions.name") },
        { index: "extension", label: this.$t("contact-me.pbx.extensions.extension")},
        { index: "secret", label: this.$t("contact-me.pbx.extensions.secret")},
        { index: "voicemail", label: this.$t("contact-me.pbx.extensions.have-voicemail"), type: "boolean"},
        { index: "recording", label: this.$t("contact-me.pbx.extensions.is-recording-calls"), type: "boolean"},
        { index: "type", label: this.$t("contact-me.pbx.extensions.type"), type: "tag"},
      ],
      search: ["extension", "name"]
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-pbx"});
    },
    handleDeleted(extension) {
      if (extension.type === "HiperMe") {
        const hipermeExtension = extension.hipermeExtension;
        this.$store.dispatch("extensions/destroy", hipermeExtension.id)
      }
    }
  },
  components: {
    TheHeader,
    TheMainCrud,
  },
  computed: {
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  },
  async beforeMount() {
    await this.$store.dispatch("extensions/all", {
      ['organization.id']: this.organizationId,
      paginate: false
    });
  }
}
</script>

<style scoped>
.main-view .header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>