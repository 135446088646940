import i18n from '@/i18n'

export default {
    nodes : [
      { component : "h3" , label : i18n.tc('common.basic_information') },
      { component : "input", label : i18n.tc('common.name'), index : "name", required : true },
      { component : "input", label : i18n.tc('configuration.servers.mediacenter.sip_server'), index : "janusSipServer", required : true },
      { component : "input", label : i18n.tc('configuration.servers.mediacenter.sip_password'), index : "janusSipServerPassword", required : true },
      { component : "input", label : i18n.tc('configuration.servers.mediacenter.rest_api_url'), index : "restApiUrl"},
      { component : "input", label : i18n.tc('configuration.servers.mediacenter.rest_api_password'), index : "restApiPassword"}
    ]
  }