<template>
  <div class="chat-wrapper">
    <header class="chat-header">
        <div class="header-content">
            <img src="https://arcc-soporte.hiperpbx.com/assets/css/ui/iso-verde.png" alt="Contact Image" class="contact-image">
            <div class="contact-info">
                <h2 class="contact-name">Hiperpbx</h2>
            </div>
        </div>
    </header>
    <div class="chat-container">
        <div class="ContentTemplateExample whatsapp-message-preview">
            <div class="message-container">
                <div class="message-bubble">
                    <img class="message-image" v-if="this.selectedTemplateContent.templateType == 'IMAGE'" :src="this.selectedTemplateContent.img" >
                    <span class="message-header">{{ selectedTemplateContent.header }}</span>
                    <p class="message-text">{{ templateString }}</p>
                    <span class="message-footer">{{selectedTemplateContent.footer}}</span>
                    <div class="message-time-container">
                        <span class="message-time">Today</span>
                    </div>
                </div>
                <div class="message-buttons-container" v-if="selectedTemplateContent.buttons && selectedTemplateContent.buttons.length > 0">
                    <button :class="['message-button', {'more-than-two-button': selectedTemplateContent.buttons.length > 2} ]" v-for="(button, key) in selectedTemplateContent.buttons" :key="key">{{ button.text }}</button>
                </div>
            </div>
        </div>
    </div>
    <footer class="chat-footer">
          <div class="footer-content">
            
            <i class="fas fa-laugh"></i>
            <input disabled type="text" class="text-input" placeholder="Escribe un mensaje">
            <i class="fas fa-camera"></i>
            <i class="fas fa-paperclip"></i>
          
            
            <i class="fas fa-microphone mic-button"></i>
        </div>
    </footer>
  </div>
</template>
<script>
export default{
  props: {
    selectedTemplateContent: {
      type: Object,
      required: true
    },
    templateVariables: {
      type: Array,
      required: false,
      default: ()=>[]
    }
  },
  computed: {
    templateString(){
      let templateModified = this.selectedTemplateContent.text;
      if(this.selectedTemplateContent.text !== '' && this.templateVariables.length > 0){
        
        for(let i = 0; i < this.templateVariables.length; i++){
          templateModified = templateModified.replace(`{{${i+1}}}`, this.templateVariables[i] !== '' ? this.templateVariables[i] : `{{${i+1}}}`);
        }
      }
      return templateModified;
    }
  }
}
</script>
<style>
.whatsapp-message-preview {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.chat-wrapper {
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
    min-height: 470px;
    max-height: 600px;
}

.chat-header {
    background-color: #fff;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.header-content {
    display: flex;
    align-items: center;
}

.contact-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.contact-name {
    color: #000;
    margin: 0;
    font-size: 1.1em;
}

.chat-container {
    padding: 10px;
    background-color: #ECE5DD;
    overflow-y: auto;
    height: 100%;
    display: flex;
    min-height: 349px;
  }

.message-header{
  font-weight: bold;
}
.message-footer{
  color: #999;
}
.timestamp {
    font-size: 0.75em;
    color: #999;
    position: absolute;
    bottom: 5px;
    right: 10px;
}

.chat-footer {
    background-color: #FFF;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.footer-content {
    display: flex;
    width: 100%;
    align-items: center;
}

.footer-content .fas {
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    margin: 0 5px;
}

.text-input {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    background-color: #F0F0F0;
    margin: 0 5px;
}

.ContentTemplateExample {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.message-bubble {
    background-color: #DCF8C6;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    max-width: 400px;
}

.message-text {
    margin: 0;
    word-break: auto-phrase;
}

.message-buttons-container {
    display: flex;
    margin-top: 10px;
    width: 100%;
    padding-bottom: 10px;
}

.message-button {
    color:#0069d9;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.message-footer-container,
.message-time-container {
    display: flex;
    justify-content: flex-end;
}

.message-time {
    font-size: 0.75em;
    color: #999;
}


.message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin: 10px 0;
}

.message-bubble {
  background-color: #fff;
  padding: 12px 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.message-text {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}
.message-time-container{
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
}
.message-time {
  font-size: 12px;
  color: #999;
}
.message-buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 12px;
  gap: 5px;
}
.message-button.more-than-two-button{
  grid-column: 1/3;
}
.message-image{
  width: 100%;
}
.message-button {
  outline: none;
  background-color: #fff; 
  border: none; 
  padding: 7px 15px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  font-size: 14px; 
  cursor: pointer; 
  border-radius: 5px; 
}
</style>