<template>
    <div class="notification-abm">
        <div>
            <img src="@/img/success.svg" v-if="status">
            <img src='@/img/error.svg' v-else>
            <p class="mt-3 title">{{ (status) ? successTitle : errorTitle }}</p>
            <p v-if="showMessage">{{ text }} <span v-if="!status">{{ $t("common.click") }} <a href="#" @click.prevent="resend">{{ $t("common.here") }}</a> {{ $t("notification-abm.to-retry-or-try-later") }}</span></p>
            <p v-if="showMessage"><router-link :to="{ name: route}" v-if="route">{{ $t('common.back_list') }}</router-link></p>
        </div>
    </div>
</template>

<script>
export default {
    name : "NotificationABM",
    props : {
        showMessage : {
            type : Boolean,
            default : true
        },
        status : {
            required : false,
            type : Boolean,
            default : true
        },
        show : {
            type : Boolean,
            default : false
        },
        successTitle : {
            type : String,
            required : true
        },
        errorTitle : {
            type : String,
            required : false,
            default : ''
        },
        text : {
            type : String,
            required : false
        },
        route : {
            type : String,
            required : false
        },
    },
    methods : {
        resend() {
            this.$emit('retry');
        }
    }
}
</script>

<style scoped>
    .notification-abm {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: space-around;
        text-align: center;
        width: 100%;
    }

    .notification-abm p {
        color: var(--blue-grey);
    }

    .notification-abm .title {
        font-size: 24px;
        font-weight: bold;
        word-break: break-word;
    }

    .notification-abm img {
        height: 150px;
        width: 150px;
    }
</style>