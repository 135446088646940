<template>
  <div @click.stop="call">
    <button  class="phone-button">
      <i class="fas fa-phone"></i>
    </button>
    <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
      <allow-microphone :is-in-modal="true" v-if="errorType == 'NotAllowedError'" :system="message === 'Permission denied by system'"></allow-microphone>
      <p v-if="errorType == 'NoCaptureDevice'">
        {{ $t("call.an-available-microphone-was-not-found-to-use-connect-or-enable-a-microphone-to-be-able-to-answer-the-call") }}
      </p>
    </el-dialog>
  </div>
</template>

<script>
import AllowMicrophone from '@/views/help/AllowMicrophone'
import * as Sentry from '@sentry/browser';
export default {
  name: "PhoneButton",
  data() {
    return {
      dialogVisible: false,
      errorType: "",
      message : "",
    };
  },
  props: {
    extension: {
      type: String,
      required: true,
    },
    displayname: {
      required: true,
      type: String,
    },
    uid: {
      type: String,
    },
    janus: {
      required: true,
    },
  },
  methods: {
    call() {
      if (this.extension !== "" && this.janus) {
        this.janus.call(this.extension, this.displayname).catch((error) => {
          if (error.name === "NotAllowedError" || error.name === "NoCaptureDevice") {
            this.dialogVisible = true;
            this.errorType = error.name;
            this.message = error.message
          } else if (error.name === "UnexpectedAnswer") {
            const sound = new Audio('/sounds/busy_phone_tone.mp3');
            sound.volume = 0.3;
            sound.play();
            setTimeout(() => {
              sound.pause();
            }, 2000);
          } else {
            this.$alert(error).catch(() => {})
            if (process.env.VUE_APP_SENTRY_DSN) {
              Sentry.captureMessage(error);
            }
          }
        });
      }
    },
  },
  components : {
    AllowMicrophone
  }
};
</script>

<style scoped>
button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--blue-grey);
  cursor: pointer;
  margin-top: 0px!important;
}

.clasic button {
  color: var(--green-button);
}
</style>