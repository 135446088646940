<template>
  <div class="main-content" v-loading="loading">
    <header>
        <el-page-header @back="$router.push({ name : 'usage_report' })"></el-page-header>
        <h2>{{ header }}</h2>
        <el-button @click="fetch(true)" type="primary" size="small">{{ $t("common.export-to-csv") }}</el-button>
    </header>
    <el-form size="large">
      <el-form-item label="">
        <el-date-picker v-model="dateRange" type="daterange" align="left" unlink-panels range-separator="To" start-placeholder="Start date" end-placeholder="End date" :picker-options="pickerOptions" size="small"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <the-search-input v-model="search" style="width: 300px;"/>
        <el-button style="margin-left: var(--column)" type="primary" @click="fetch()">{{ $t("common.search") }}</el-button>
      </el-form-item>
    </el-form>
    <div class="chart-container">
      <apexchart width="500" type="donut" :options="options.options" :series="options.options.series" class="donut" />
    </div>
    <p v-if="percentageOfUserLoggedByOrganization[0]">
      {{ $t("reports.users-have-not-logged-into-the-app-between", { 
        percentage: percentageOfUserLoggedByOrganization[0] ? percentageOfUserLoggedByOrganization[0].percentage_not_logged : 0,
        total: percentageOfUserLoggedByOrganization[0].users.length,
        dateInit: formatDate(dateInit),
        dateTo: formatDate(dateEnd)
      }) }}
    </p>
    <the-table :columns="columns" :pagination="{}" :rows="users" :loading="loading"></the-table>
  </div>
</template>

<script>
import TheTable from "@/components/crud/TheTable";
import TheSearchInput from '@/components/html/TheSearchInput'
import { mapActions, mapGetters } from 'vuex';
export default {
  name : 'TheUsageReports',
  props : {
    id : {
      required : true
    },
    from : {
      required : false,
    },
    to : {
      required : false
    }
  },
  data() {
    const from = new Date(this.from);
    from.setDate(from.getDate() + 1);

    const to = new Date(this.to);
    to.setDate(to.getDate() + 1);

    return {
      pickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last month',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: 'Last 3 months',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      dateRange: [
        from,
        to,
      ],
      loading : true,
      search: "",
      columns: [
        { index : 'name.lastname', label : this.$t('configuration.users.name-lastname') , type : 'join', options : ["name", "lastname"], joinBy : " " },
        { index: 'email', label : this.$t("common.email") },
        { index: 'last_login', label : this.$t("configuration.users.last_login"), type : "datetime", sortable: true },
      ]
    };
  },
  computed: {
    ...mapGetters('reports', {
      percentageOfUserLoggedByOrganization : 'getPercentegeOfUserLoggedByOrganization'
    }),
    users() {
      if (!this.percentageOfUserLoggedByOrganization[0]) {
        return [];
      }

      if(!this.search) {
        return this.percentageOfUserLoggedByOrganization[0].users
      }

      const reg = new RegExp(this.search, "i");

      return this.percentageOfUserLoggedByOrganization[0].users.filter((user) => {
        return reg.test(user.name) || reg.test(user.lastname) || reg.test(user.email)
      })
    },
    dateInit() {
      return this.dateRange[0]
    },
    dateEnd() {
      return this.dateRange[1];
    },
    header() {
      if (!this.percentageOfUserLoggedByOrganization[0]) {
        return ""
      }

      return this.$t("reports.usage-reports-of", { name: this.percentageOfUserLoggedByOrganization[0].organization.name })
    },
    options() {
      const logged = this.percentageOfUserLoggedByOrganization[0] ? this.percentageOfUserLoggedByOrganization[0].total_logged : 0
      const notLogged = this.percentageOfUserLoggedByOrganization[0] ? this.percentageOfUserLoggedByOrganization[0].total_not_logged : 0
      return {
        options: {
          title : {
            text : this.$t("dashboard.call-activity"),
            align : 'center',
            style : {
              color: 'white'
            }
          },
          legend: {
            position: 'bottom',
          },
          series: [notLogged, logged],
          labels: [this.$t("reports.not-logged"), this.$t("reports.logged")],
          colors : ['#b33d6d','#71b33d'],
        },
      }
    }
  },
  methods : {
    ...mapActions('reports', ['fetchPercentegeOfUserLoggedByOrganization']),
    async fetch(csv = false) {
      this.loading = true
      const [from, to] = this.dateRange
      const params = {from : this.formatDate(from), to : this.formatDate(to), organization_id : this.id }

      if (csv) {
        params.csv = true
      }

      try {
        await this.fetchPercentegeOfUserLoggedByOrganization(params)    
      } finally {
        this.loading = false
      }
    },
    formatDate(date) {
      let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;

      return [year, month, day].join('-');
    },
    exportToCsv() {
      this.fetch(true)
    }
  },
  created() {
    this.fetch()
  },
  components: {
    TheTable,
    TheSearchInput
  }
};
</script>

<style scoped>
header,
.chart-container {
  display: inline-flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}
header button:last-child {
  margin-left: auto;
}

p {
  text-align: center;
}
</style>