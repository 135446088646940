<template>
  <div class="reply">
    <el-form ref="form" :model="form" v-loading="saving" size="small">
      <el-form-item :label="$t('tickets.answer-to')" v-if="type === 'R'">
        <el-select v-model="form.address">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      
      <el-form-item v-if="type == 'N'" :label="$t('tickets.title')">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item :label="$t('tickets.responses')" v-if="type == 'R'">
        <el-select v-model="cannedResponse" :placeholder="$t('tickets.select-response')" @change="addCannedResponse">
          <el-option :label="$t('tickets.last-message')" value="-1"></el-option>
          <el-option
          v-for="item in cannedResponses"
          :key="item.canned_id"
          :label="item.title"
          :value="item.canned_id"
          ></el-option>
        </el-select> 
      </el-form-item>

      <div class="collaborators" v-if="type === 'R'">
        <p class="mb-0">{{ $t("tickets.collaborators") }}</p>
        <ul>
          <li v-for="collaborator in collaborators" :key="collaborator.id">
            {{ collaborator.user.name }}
            {{ collaborator.user.email.address }}
          </li>
          <el-button type="text" @click="showDialogCC = true">{{ $t("tickets.manage-collaborators") }}</el-button>
        </ul>
      </div>
      
      <TheTicketResponse ref="TheTicketResponse"></TheTicketResponse>

      <div v-if="type == 'R'" style="margin-top: var(--column)">
        <p class="mb-0">{{ $t("tickets.signature") }}</p>
        <el-radio v-model="form.signature" label="0">{{ $t("tickets.none") }}</el-radio>
        <el-radio v-model="form.signature" label="1">{{ $t('tickets.department-signature') }}</el-radio>
      </div>

      <el-form-item>
        <el-button type="primary" @click="answer">{{ $t("tickets.answer-ticket")}}</el-button>
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="showDialogCC" @closed="showFormAdd = false">
      <div v-if="!showFormAdd">
        <h3>{{ $t("tickets.collaborators") }}</h3>
        <ul class="collaborators">
          <li v-for="collaborator in collaborators" :key="collaborator.id"> 
            <p class="mb-0">{{ collaborator.user.name }} ({{ collaborator.user.email.address }})</p>
            <el-button circle :icon="collaborator.deleting ? 'fas fa-circle-notch fa-spin' : 'fas fa-trash'" @click.native="handleDelete(collaborator)" size="small" :disabled="collaborator.deleting"></el-button>
        </li>
        </ul>
          <el-button type="text" @click="showFormAdd = true">{{  $t("tickets.add-collaborator") }}</el-button>
      </div>
      <div v-else>
        <TheUserSearch @selectedUser="selectedUser"></TheUserSearch>
      </div>
    </el-dialog>

    <el-dialog
      :visible.sync="showDialogResponse"
      width="30%">
      <div v-if="response">
        <p v-if="!response.status_alert.status && response.status_alert.message_agents" class="text-center">{{ $t('tickets.the-ticket-was-created-but-the-agents-of-the-new-ticket-could-not-be-notified-(via-email)') }}</p>
        <p v-if="!response.status_alert.status && response.status_alert.message_user_css" class="text-center">{{ $t('tickets.the-ticket-was-created-but-the-user-of-the-new-ticket-could-not-be-notified-(via-email)') }}</p>
        <p v-if="!response.status_alert.status && response.status_alert.message_agents_internal_note" class="text-center">{{ $t('tickets.the-note-was-created-but-the-agents-could-not-be-notified-(via-email)') }}</p>
      </div>
    </el-dialog>


  </div>
</template>

<script>

import { mapActions, mapGetters } from "vuex";
import TheTicketResponse from './TheTicketResponse';
import TheUserSearch from "./TheUserSearch.vue";

export default {
  name: "TheTicketResponseBox",
  props: {
    ticketNumber : {
      required : false,
      type : String
    },
    ticketId: {
      required: true,
      type: Number,
      default: 0,
    },
    ticketOwner: {
      required: true,
    },
    type : {
      required : false,
      default : 'R',
      type : String
    },
  },
  data() {
    return {
      form: {
        address: "all",
        title : '',
        signature : "0"
      },
      saving: false,
      cannedResponse : '',
      response : null,
      showDialogResponse : false,
      showDialogCC : false,
      showFormAdd: false,
      deletingsIds: [],
    };
  },
  components: {
    TheUserSearch,
    TheTicketResponse
  },
  methods: {
    ...mapActions(["answerTicket"]),
    ...mapGetters(['getDataToUpdateTicket']),
    answer() {
      if (this.$refs.TheTicketResponse.form.response !== "") {
        this.saving = true;
        this.answerTicket({
          ticket_id: this.ticketId,
          response: this.form.address,
          body: this.$refs.TheTicketResponse.form.response,
          attachments : this.$refs.TheTicketResponse.files,
          type : this.type,
          title : this.form.title,
          signature: this.form.signature
        })
        .then((response) => {
            this.$refs.TheTicketResponse.cleanFiles()
            this.$refs.TheTicketResponse.form.response = '';
            this.form.title = '';
            this.response = response.data
            if (!this.response.status_alert.status) {
              this.showDialogResponse = true
            } else {
              this.$message({
                message: this.type === "R" ? this.$t("tickets.the-ticket-was-answered-correctly") : this.$t("tickets.the-internal-note-was-created"),
                type: "success",
              });
            }
          })
          .catch(() => {
            this.$message({
              message: this.$t(
                "tickets.the-ticket-could-not-be-answered.-Please-try-again-later"
              ),
              type: "error",
            });
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        this.$refs.TheTicketResponse.errors = {
          response : 'The response is required'
        }
      }
    },
    addCannedResponse(cannedId) {
      if (cannedId > 0) {
        const cannedResponse = this.cannedResponses.find((canned) => canned.canned_id === cannedId );
        this.$refs.TheTicketResponse.form.response = cannedResponse.response;
      } else {
        let ticket = this.$store.getters.getTicketByNumber(this.ticketNumber);
        let lastMessage = null;
        for (let i = ticket.history.length -1 ; i > 0 ; i-- ) {
          if (ticket.history[i].type === "R" && !lastMessage) {
            lastMessage = ticket.history[i];
          }
        }

        if (lastMessage) {
          this.$refs.TheTicketResponse.form.response += lastMessage.body;
        }
      }
    },
    async selectedUser(user) {
      this.showFormAdd = false;
      await this.$store.dispatch("addCollaboratorsToTicket", {
        ticketId: this.ticketId,
        userId: user.id
      })
      this.$message({
        message: `Se agrego el colaborador ${user.name}`,
        type: 'success'
      });
    },
    async handleDelete(collaborator) {
      this.deletingsIds.push(collaborator.id);
      await this.$store.dispatch('removeCollaboratorFromTicket', { ticketId: this.ticketId, collaboratorId: collaborator.id })
      this.$message({
        message: `Se elimino el colaborador ${collaborator.user.name}`,
        type: 'success'
      });
    }
  },
  computed: {
    options() {
      let options = [
        { label: "Todos", value: "all" },
        { label: "No responder al email", value: "none" },
      ];
      if (this.ticketOwner) {
        options.push({
          label: `${this.ticketOwner.name} (${this.ticketOwner.email})`,
          value: "user",
        });
      }
      return options;
    },
    cannedResponses() {
      return this.getDataToUpdateTicket().canned_responses;
    },
    ticket() {
      return this.$store.getters.getTicketByNumber(this.ticketNumber);
    },
    users() {
      return this.getDataToUpdateTicket().users;
    },
    collaborators() {
      return this.$store.getters.getTicketByNumber(this.ticketNumber) ? 
        this.$store.getters.getTicketByNumber(this.ticketNumber).thread.collaborators.map((collaborator) => {
          collaborator.deleting = this.deletingsIds.find(id => collaborator.id == id) ? true : false;
          return collaborator;
        })
      : []
    },
  },
};
</script>

<style scoped>
.reply {
  background-color: white;
  padding: var(--column);
}

.text-editor {
  margin-bottom: 10px;
}

.fa-paperclip {
  margin-left: 10px;
  color: var(--bubble-text-color);
}

button {
  margin-top:10px!important;
}

div.collaborators {
  display: inline-flex;
  gap: var(--column);
}

ul.collaborators li {
  list-style: none;
  width: 100%;
  display: inline-flex;
  gap: var(--column);
  align-items: center;
}

ul.collaborators li button {
  margin: 0;
}

.collaborators li{
  font-size: 12px;
  list-style: none;
}
</style>