<template>
  <div @click="onClick" :class="{ 'active' : !notification.isRead }" class="notification">
    <div class="header">
      <p>{{ notification.title}} </p>
      <button @click="clean($event)"><i class="fas fa-times"></i></button>
    </div>
    <p> {{ notification.body }} </p>
    <p class="notification__created-at"> {{ notification.createdAt }} </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    notification: {
      required: true,
    },
  },
  methods : {
    ...mapActions(['cleanNotification', 'setNotificationToInactive']),
    assigned() {
      this.$router.push({
        name : "ticket",
        params : { 
          ticketNumber : this.notification.ticket.number + ""
        }
      })
    },
    clean(event) {
      this.cleanNotification(this.notification.id)
      event.stopPropagation()
    },
    onClick() {
        this.setNotificationToInactive(this.notification.id)
    }
  },
  computed : {
    message() {
      const object = {
        assigned : this.notification.ticket
      }
      return this.$t(`tickets.notifications.${this.notification.action}.message`, object[this.notification.action])
    }
  }
};
</script>

<style scoped>
.active {
  background-color: var(--light-blue)!important;
}

.notification {
  padding: var(--column);
  width: 300px;
  font-size:14px;
  cursor: pointer;
  border-bottom: 1px solid #DCDFE6;
}

p {
  margin: 0
}

button {
  margin-top: 0px!important;
  border: none;
  outline: none;
  background: none;
  color: var(--red)
}

.header {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: calc(var(--column) / 2);
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}
.notification__created-at{
  margin-top: calc(var(--column) / 2);
  font-size: 12px;
}
</style>