
import createPBXStore from "../pbx";
const storage = createPBXStore("extensions", "extension");

const getters = {
  getData: (state, getters, rootState, rootGetters) => {
    return Object.values(state.data).map((item) => {
      item.type = "Normal";
      item.tagType = "primary";
      item.hipermeExtension = null;
      const extension = rootGetters["extensions/getExtensionByNumber"](item.extension);
      if (extension) {
        item.type = "HiperMe";
        item.tagType = "success";
        item.hipermeExtension = extension;
      }
      return item;
    })
  }
}

storage.getters = {...storage.getters, ...getters}


export default storage;