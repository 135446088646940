<template>
  <div class="videocall" :class="{ active : active }" @click="join">
    <div>
      <img src="/images/hiperme_isologo.png" width="50"/>
      <div>
        <p>HiperMeet!</p>
        <the-system-message :message="message"></the-system-message>
      </div>
    </div>
    <p v-if="active">¡Unete a la videollamada!</p>
    <p v-else>La videollamada termino</p>
  </div>
</template>

<script>
import TheSystemMessage from './TheSystemMessage'
import { mapActions } from 'vuex'
export default {
  props : {
    message : {
      required : true
    }
  },
  methods : {
    ...mapActions(["joinVideocall"]),
    async join() {
      if (this.active) {
        const roomId = this.message.roomId
        const route = this.$router.resolve({ name: "Videocall", params: { roomId } });
        await this.joinVideocall(roomId);
        window.open(route.href, '_blank');
      }
    }
  },
  components: {
    TheSystemMessage
  },
  computed: {
    active() {
      return this.message.active;
    }
  }
}
</script>

<style scoped>
.videocall {
  padding: var(--column);
  background-color: #f9f9f9;
  color: var(--hiperpbx-grey);
  border-radius: var(--radius);
}

p {
  margin: 0;
}

.videocall div:first-child {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  text-align: left;
  align-items: center;
}

.videocall div p {
  flex: 1;
}

.videocall.active {
  cursor: pointer;
}

.videocall > p:last-child {
  padding-top: var(--column);
  border-top: 1px solid #EBEEF5
}
</style>