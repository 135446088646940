<template>
  <div class="chats">
    <the-min-corpo-chat v-for="chat in chats" :key="chat.id" :id="chat.id" :chat="chat" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import TheMinCorpoChat from "@/components/chat/TheMinCorpoChat"

export default {
  computed : {
    ...mapGetters('corpochat', {
      tmpchats : 'getOpenedMinChats'
    }),
    chats : function() {
      return this.tmpchats.filter(chat => chat.hide === false)
    }
  },
  components : {
    TheMinCorpoChat
  }
}
</script>

<style scoped>
  .chats {
    bottom: 0;
    right: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 3;
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  
</style>