<template>
  <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="entity" :title="title"
    search="name" :submit="beforeSubmit"></the-form>
</template>

<script>
import TheForm from '@/components/crud/TheForm'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      store: "chatClassifications",
      form: {
        nodes: [
          { component: 'input', index: 'name', label: this.$t('common.name'), required: true },
          { component: 'input', index: 'description', label: this.$t('common.description') },
          { component: "select", index: "form", label: this.$t("contact-me.chat-center.form"), store: 'forms', required: true, 'label-value': ['name'], params: { ["organization.id"]: this.$store.getters["contactMe/getOrganizationId"] } },
        ]
      }
    }
  },
  components: {
    TheForm
  },
  computed: {
    edit () {
      return this.id !== "0"
    },
    title () {
      return this.edit ? this.$t("contact-me.chat-center.edit-chat-classification") : this.$t("contact-me.chat-center.create-chat-classification")
    },
    organizationId () {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  },
  methods: {
    beforeSubmit (data) {
      const organizationId = this.$store.getters["contactMe/getOrganizationId"];
      data.organization = `/api/organizations/${organizationId}`;
      return data;
    }
  }
}
</script>