<template>
  <Box :title="$t('agent-console.system-messages')" v-show="messages.length > 0">
  <el-timeline>
      <el-timeline-item
        v-for="(message, index) in messages"
        :key="index"
        :timestamp="message.date">
        {{ message.text }}
      </el-timeline-item>
    </el-timeline>
  </Box>
</template>

<script>
import Box from './Box.vue'
import * as Dates from '@/filters/dates'
export default {
  props: {
    roomId: {
      required: true,
      type: String
    }
  },
  computed: {
    messages() {
      return this.$store.getters["_chats/getChatByRoomId"](this.roomId).messages
        .filter(message => message.type === "system" || message.user.id === -1)
        .map(message => {

          if (!message.version) { 
            return {
              text: message.text,
              date : Dates.chatRedeableDate(message.date, true)
            }
          }

          if (message.type === 'text') {
            return {
              text: message.payload.text,
              date : Dates.chatRedeableDate(message.date, true)
            }
          }

          return null;
        })
    }
  },
  components: {
    Box
  }
}
</script>