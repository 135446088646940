<template>
  <div>
    <h4>{{ $t('contact-me.pbx.ivrs.basic-information') }}</h4>
    <ul>
      <li><span class="label">{{$t('contact-me.pbx.ivrs.name')}}</span>: {{ ivr.name }}</li>
      <li><span class="label">{{$t('contact-me.pbx.ivrs.timeout')}}</span>: {{ ivr.timeout }}</li>
      <li><span class="label">{{$t('contact-me.pbx.ivrs.loops')}}</span>: {{ ivr.loops }}</li>
      <li><span class="label">{{$t('contact-me.pbx.ivrs.announcement')}}</span>: {{ announcement( ivr.announcement ) }}</li>
    </ul>
    <h4>{{$t('contact-me.pbx.ivrs.options')}}</h4>
    <ul v-if="listDestinationsLength > 0">
      <li v-for="(option, index) in listDestinations" :key="index">{{ getDestinationLabel(index) }}: {{ option.type }} / {{ option.name }}</li>
    </ul>
    <p v-else>Ninguna seleccioando</p>
  </div>
</template>

<script>
export default {
  props: {
    ivr: {
      required: true
    }
  }, 
  methods: {
    getDestinationLabel( val ){
      if( val == 't' ) return 'Timeout';
      if( val == 'i' ) return 'Invalid';
      return val;
    }
  },
  computed: {
    announcements() {
      return this.$store.getters["pbx_system_recordings/getData"]
    },
    announcement( ){
      const t = this;
      return id_anuncio => {
        const item = Object.values(t.announcements).find(a => a.id == id_anuncio);
        return item ? item.name : "";
      }
    },
    listDestinations( ){
      const values = {};
      for( let i in this.ivr.destinationOptions ){
        if( this.ivr.destinationOptions[i] ){
          values[i] = this.ivr.destinationOptions[i];
        }
      }
      return values;
    },
    listDestinationsLength( ){
      return Object.keys( this.listDestinations ).length;
    }
  }
}
</script>

<style scoped>
.label {
  font-weight: bold;
  color: #5A5B5B;
}

li {
  margin-bottom: calc(var(--column) / 2 );
  font-size: 14px
}

.destinations .tag {
  margin-right: var(--column)
}
</style>