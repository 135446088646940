<template>
  <el-dialog :visible.sync="show" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" width="20%">
    <span>Contact ME esta abierto en otra ventana. Haz click en "Usar aquí" para habilitar Cotnact Me en esta ventana</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click.native="close">Cerrar</el-button>
      <el-button type="primary" @click.native="useHere">Usar aquí</el-button>
    </span>
  </el-dialog>
</template>

<script>
import WindowStatus from '@/plugins/window-status'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      show : false,
      windowStatus : null
    }
  },
  methods : {
    ...mapActions(['setIsMainWindow']),
    close() {
      window.location.href = 'http://hiperpbx.com'
    },
    useHere() {
      this.windowStatus.setToMain()
    }
  },
  created() {
    this.windowStatus = new WindowStatus();
    this.windowStatus.start(true, (isMainWindow) => {
      this.show = !isMainWindow
      this.setIsMainWindow(isMainWindow)
    })
  }
}
</script>

<style>

</style>