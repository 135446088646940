<template>
  <el-dialog :visible.sync="show">
    <h3>{{ $t('common.advanceSearch')}}</h3>
    <p class="mb-0">{{  $t('tickets.addField') }}</p>
    <el-select v-model="fieldsSelected" multiple collapse-tags style="margin-top: 8px; width: 270px;"  size="small" filterable>
      <el-option v-for="field in fields" :key="field.id" :value="field.id" :label="`${field.form?.title}/${field.label}`" />
    </el-select>
    <section>
      <div v-for="field in selectedFields" :key="field.id" class="item">
        <p>{{ field.form?.title }} / {{ field.label }}</p>
        <el-select v-if="field.type === 'choices' || field.type === 'priority' || field.type === 'state'" v-model="field.value" size="small" filterable>
          <el-option v-for="option in getValuesByField(field)" :value="option.value" :key="option.value" :label="option.label" />
        </el-select>
        <div v-if="field.type === 'text' || field.type === 'memo' || field.type === 'thread' || field.type === 'phone'" >
          <el-input v-model="field.value" size="small"></el-input>
        </div>
      </div>
    </section>
    <footer>
      <el-button type="primary" @click.native="handleSearch">{{ $t('common.search')  }}</el-button>
    </footer>
  </el-dialog>
</template>

<script>
/* import ticketsApi from '@/api/tickets' */
export default {
  props : {
    defaultFilters: {
      required: false,
      default: () => ([])
    }
  },
  data() {
    return {
      show: false,
      fieldsSelected: this.defaultFilters,
      queryParams: [],
      conditions:[],
      selectedFields: [],
    }
  },
  methods: {
    toggleShowDialog() {
      this.show = !this.show
    },
    getConditionsByField(field) {
      if (field.type === 'choices') {
        return ['has value', 'does not have a value', 'includes', 'does not include']
      }

      return []
    },
    getValuesByField(field) {
      if (field.type === 'choices' || field.type === 'priority') {
        return field.configuration.choices
      }

      return ''
    },
    handleSearch() {
      this.show = false
      this.$emit('addQueryParams', this.selectedFields)
    },
    removeFilter(filter) {
      const field = this.fields.find(f => f.name === filter.name)
      this.fieldsSelected = this.fieldsSelected.filter((value) => {
        return value !== field.id
      })
    }
  },
  computed: {
    /* selectedFields() {
      return this.fields.filter(field => this.fieldsSelected.includes(field.id))
    } */
    fields() {
      return this.$store.getters.getAdvanceFilters
    }
  },
  async created() {
    this.fields.forEach((field) => {
      if ( this.$route.query[field.name]) {
        // this.queryParams[field.name] = ''
        // this.queryParams[field.name] =  this.$route.query[field.name]
      }
    })

    this.$store.dispatch('fetchAdvanceFiltersFields')
  },
  watch: {
    fieldsSelected(newFieldsId, oldFieldsId) {
      // Add the new fields
      newFieldsId.forEach((id) => {
        const field = this.fields.find(field => field.id === id) 
        const selectedFieldIndex = this.selectedFields.find(selectedField => selectedField.id === id)
        if (!selectedFieldIndex) {
          this.selectedFields.push({
            ...field,
            value: this.$route.query[field.name] ?? ''
          })
        }
      })

      oldFieldsId.forEach((id) => {
        if (!newFieldsId.includes(id)) {
          this.selectedFields = this.selectedFields.filter(selectedField => selectedField.id !== id)
        }
      })

    }
  }
}
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  align-items: baseline;
}

section {
  margin-top: 16px;
}
</style>