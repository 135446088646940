<template>
  <div>
    <div v-if="!system">
      <p>{{ $t("allow-microphone.the-microphone-is-blocked") }}</p>
      <p>{{ $t("allow-microphone.to-enable-the-microphone-follow-the-following-steps-depending-on-your-browser") }}</p>
      <el-collapse v-model="broswer" accordion>
        <el-collapse-item title="Google Chrome" name="1">
          <ol>
            <li value="1">
              {{ $t("allow-microphone.click-on-the-padlock-in-the-upper-left-corner,-next-to-the-refresh-button") }}
            </li>
            <li>{{ $t("allow-microphone.the-site-permissions-window-will-be-displayed") }}</li>
            <li>{{ $t("allow-microphone.change-the-Microphone-value-to-'allow'") }}</li>
          </ol>
          <p v-if="isInModal">
            {{ $t("allow-microphone.if-you-are-on-a-call,-close-this-window-and-click-on-the-button-to-take-the-call") }}
          </p>
        </el-collapse-item>
        <el-collapse-item title="Microsoft Edge" name="2">
        </el-collapse-item>
        <el-collapse-item title="Mozilla Firefox" name="3">
          <ol>
            <li value="1">
              {{ $t("allow-microphone.click-on-the-crossed-out-microphone-in-the-search-bar") }}
            </li>
            <li>
              {{ $t("allow-microphone.click-on-the-cross-to-the-right-of-use-microphone") }}
            </li>
          </ol>
          <p v-if="isInModal">
            {{ $t("allow-microphone.if-you-are-on-a-call,-close-this-window,-click-on-the-button-to-take-the-call") }} <br />
            {{ $t("allow-microphone.a-window-will-open-requesting-permission-to-use-the-microphone.-Click-on-Allow") }}
          </p> 
        </el-collapse-item>
      </el-collapse>
    </div>
    <div>
      <p>El microfono se encuentra bloqueado por el sistema operativo</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllowMicrophone",
  props: {
    isInModal: {
      required: false,
      default: false,
    },
    system : {
      required : false,
      default : false,
    }
  },
  data() {
    const isOpera =
      !!window.opr ||
      !!window.opera ||
      navigator.userAgent.indexOf(" OPR/") >= 0;

    const isFirefox = typeof InstallTrigger !== "undefined";
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof safari !== "undefined" && window["safari"].pushNotification)
      );

    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
    const isBlink = (isChrome || isOpera) && !!window.CSS;

    return {
      broswer: "",
      isSafari: isSafari,
      isEdge: isEdge,
      isChrome: isChrome,
      isEdgeChromium: isEdgeChromium,
      isBlink: isBlink,
      isFirefox: isFirefox,
    };
  },
  created() {
    if (this.isChrome) {
      this.broswer = "1";
    }

    if (this.isEdge) {
        this.broswer = "2"
    }

    if (this.isFirefox) {
        this.broswer = "3";
    }
  },
};
</script>

<style>
</style>