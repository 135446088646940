<template>
  <el-dialog :visible.sync="dialogVisible" width="300px">
    <p>{{ $t("contact-me.to-continue-you-have-to-select-an-organization-to-manage") }}</p>
    <el-form label-position="top" :model="form">
      <el-form-item label="Organization">
        <el-select v-model="organizationId" :placeholder="$t('contact-me.select-organization')">
          <el-option v-for="organization in organizations" :key="organization['@id']" :label="organization.name" :value="organization.id" ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        organizationId: null
      }
    }
  },
  methods: {
    fetchOrganizations() {
      this.$store.dispatch("organizations/all", { pagination: false });
    }
  },
  computed: {
    organizationId: {
      set(value) {
        this.$store.commit("contactMe/SET_ORGANIZATION_ID", value);
      },
      get() {
        return this.$store.getters["contactMe/getOrganizationId"]
      }
    },
    organizations() {
      return this.$store.getters["organizations/getData"]
    }
  },
  mounted() {
    this.fetchOrganizations();
    if (this.organizationId === null) {
      this.dialogVisible = true;
    }
  },
  watch: {
    organizationId(value) {
      if (value) {
        this.dialogVisible = false;
        this.$store.dispatch("contactMe/doLoginPbx");
      }
    }
  }
}
</script>