<template>
  <div>
      <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
    name : "TheChatHeader",
    props : {
        title : {
            type : String,
            required : true
        }
    }
}
</script>

<style>

</style>