import store from '../../store'
import TheLogin from "../../views/auth/TheLogin"
import TheLoading from "../../views/auth/TheLoading"
import TheLogout from "../../views/auth/TheLogout"
import TheRedirect from "../../views/TheRedirect"

export default [
  {
		path: "/login",
		name: "Login",
		components: {
			appView : TheLogin,
		},
		beforeEnter : (to, from, next) => {
			if (store.getters.isAuthenticated) {
				next({ name : 'Home' })
			} else {
				next()
			}
		},
		meta : {
			title : "login.title"
		}
	},
	{
		path: '/logout',
		name: 'Logout',
		components :{
			appView: TheLogout
		}
	},
	{
		path: "/loading",
		name: "Loading",
		components: {
			appView : TheLoading
		},
		meta : {
			title : "login.loading"
		}
	},{
    path : "/redirect",
		components :{
			appView: TheRedirect
		}
  }
]