import Vue from 'vue'
//import i18n from '@/i18n/index';
import VueRouter from 'vue-router'
import AppRoutes from './routes/app'
import LoginRoutes from './routes/login'
import TicketsRoutes from './routes/tickets'
import NotFoundRoute from './routes/not-found'
import VideoCallRoutes from './routes/videocall'
import ConfigurationsRoutes from './routes/configurations'
import AdministrationRoutes from './routes/administration'
import RecoverPassword from './routes/recover-password'

Vue.use(VueRouter)

const routes = [
	...LoginRoutes,
	...RecoverPassword,
	...VideoCallRoutes,
	...AppRoutes,
    ...AdministrationRoutes,
	...ConfigurationsRoutes,
	...TicketsRoutes,
	...NotFoundRoute
]

// ].map(route => {

// 	if ('meta' in route) {
// 		route.meta.title = i18n.t(route.meta.title)
// 	}

// 	// if ('children' in route) {
// 	// 	route.children = route.children.map(r => {

// 	// 		if ('meta' in r) {
// 	// 			r.meta.title = i18n.t(r.meta.title)
// 	// 		}
			
// 	// 		return r
// 	// 	})
// 	// }

// 	return route
// })



const router = new VueRouter({
	mode: 'history',
	routes
})

export default router