<template>
  <div>
    <div class="chat-textarea">
      <the-answering-message v-if="answering" :message="answering" :room-id="roomId" :only-message="false" />
      <div class="files" v-if="files.length > 0">
        <the-preview-file v-for="(file, index) in files" :key="index" :file="file" @removeFile="removeFile(file)" />
      </div>
      <div class="controls">
        <el-dropdown placement="top" trigger="click" class="emojis">
          <span class="el-dropdown-link">
            <button><i class="far fa-smile"></i></button>
          </span>
          <el-dropdown-menu slot="dropdown">
            <v-emoji-picker @select="selectEmoji" />
          </el-dropdown-menu>
        </el-dropdown>
        <label class="mb-0">
          <i class="fas fa-paperclip"></i>
          <input type="file" @change="onFilesChanges" multiple :accept="accept" ref="fileInput" />
        </label>
        <div class="fake-textarea">
          <textarea v-model="message" @keydown.enter.exact.prevent="sendMessage" @keyup="handleKeyup" @keydown="handleKeydown" @paste="handlePaste" ref="textarea" rows="1" :placeholder="$t('chat.write-your-message-here')"></textarea>
        </div>
        <slot name="additionalButtons">
        </slot>
        <i class="fas fa-paper-plane" @click="sendMessage"></i>
      </div>
    </div>
    <p v-if="characterLimitReached" class="mb-0 char-limit">Se excedió el límite de caracteres permitidos. Límite {{ characterLimit }}, total {{ message.length }}</p>
    <p v-if="filesTypes.length > 0 && invalidFileType" class="mb-0 char-limit">Solo se permiten archivos {{ filesTypes.join(', ') }}</p>
  </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker'
import ThePreviewFile from './ThePreviewFile'
import TheAnsweringMessage from './TheAnsweringMessage'

export default {
  name: 'TheChatTextarea',
  props: {
    roomId: {
      type: String,
      required: true,
    },
    emojis: {
      type: Boolean,
      default: false,
    },
    characterLimit: {
      type: Number,
      default: 0,
    },
    filesTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      message: localStorage.getItem(this.roomId) || '',
      files: [],
      uploadFiles: [],
      invalidFileType: false,
      inputFiles: null,
    }
  },
  methods: {
    handlePaste(event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items
      Array.from(items).forEach(item => {
        if (item.kind === 'file' && item.type.startsWith('image/')) {
          const blob = item.getAsFile()
          this.readFile(blob)
        }
      })
    },
    focus() {
      this.$refs.textarea.focus()
    },
    selectEmoji(emoji) {
      this.message += emoji.data
    },
    handleKeyup(evt) {
      if (evt.keyCode !== 13) {
        this.$emit('isWriting')
      }
      localStorage.setItem(this.roomId, this.message)
    },
    handleKeydown() {
      this.$nextTick(() => {
        const textarea = this.$refs.textarea
        textarea.style.height = this.message ? `${textarea.scrollHeight}px` : 'auto'
      })
    },
    sendMessage(evt) {
      if (this.characterLimitReached) return

      if (this.message !== '') {
        evt.stopPropagation()
        this.$emit('sendMessage', this.message)
        this.message = ''
        localStorage.setItem(this.roomId, '')
      }

      if (this.files.length > 0) {
        this.$emit('uploadFiles', this.uploadFiles)
      }
    },
    onFilesChanges(e) {
      this.invalidFileType = false
      this.files = []
      this.uploadFiles = []
      const files = e.target.files || e.dataTransfer.files
      Array.from(files).forEach(file => {
        if (this.filesTypes.includes(file.type)) {
          this.readFile(file)
        } else {
          this.showInvalidFileTypeMessage()
          this.invalidFileType = true
        }
      })
    },
    readFile(file) {
      const reader = new FileReader()
      reader.onload = e => {
        const contentType = e.target.result.split(';')[0].split(':')[1]
        this.files.push({
          name: file.name,
          data: e.target.result,
          type: file.type.split('/')[0],
          uploading: false,
          uploadedSize: '0%',
          uploadTask: null,
          contentType,
        })
        this.uploadFiles.push(file)
      }
      reader.readAsDataURL(file)
    },
    removeFile(file) {
      this.files = this.files.filter(f => f.name !== file.name)
      this.uploadFiles = this.uploadFiles.filter(f => f.name !== file.name)
    },
    showInvalidFileTypeMessage() {
      this.$message({
        showClose: true,
        message: 'El tipo de archivo no es permitido.',
        type: 'error',
      })
    },
    uploadingFiles (index, value) {
      this.$refs.fileInput.value = '';
      this.files.map((file, key) => {
        if (key === index) {
          file.uploading = true;
          file.uploadedSize = `${value}%`;
        }

        if (value === 100) {
          this.removeFile(file);
        }

        return file;
      });
    }
  },
  components: {
    VEmojiPicker,
    ThePreviewFile,
    TheAnsweringMessage,
  },
  computed: {
    answering() {
      return this.$store.getters['_chats/getChatByRoomId'](this.roomId).answerMessage
    },
    characterLimitReached() {
      return this.characterLimit > 0 && this.message.length > this.characterLimit
    },
    accept() {
      return this.filesTypes.join(',')
    },
  },
  mounted() {
    if (localStorage.getItem(this.roomId)) {
      this.message = localStorage.getItem(this.roomId)
      this.$nextTick(() => {
        this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`
      })
    }
  }
}
</script>
<style scoped>
.chat-textarea {
  align-items: center;
  border: 1px solid var(--blue-grey);
  border-radius: 10px;
  padding: var(--column);
}
.chat-textarea .fas {
  color: var(--blue-grey);
}
.fake-textarea {
  flex: 1;
  position: relative;
}

.fake-textarea .label {
  position: absolute;
  margin-left: 3px;
}

.fake-textarea .textarea {
  flex: 1;
  max-height: 93px;
  overflow-y: auto;
  z-index: 1;
}

.fake-textarea .textarea:focus {
  outline: none;
}

.chat-textarea textarea:focus {
  outline: none;
}

.fa-paperclip,
.fa-paper-plane,
.fa-smile,
.fa-bolt {
  cursor: pointer;
  padding: calc(var(--column) / 2);
  border-radius: 50%;
}

.fa-paperclip:hover,
.fa-paper-plane:hover,
.fa-smile:hover,
.fa-bolt:hover {
  color: white;
  background-color: var(--blue);
}

input[type='file'] {
  display: none;
}

.controls {
  width: 100%;
  display: flex;
  align-items: center;
}

button {
  outline: none;
  border: none;
  background-color: white;
  color: var(--blue-grey);
  font-size: 16px;
  margin-top: 0px !important;
}

.fa-smile:hover,
.fa-paperclip:hover, 
.fa-paper-plane:hover,
.fa-bolt:hover {
    color: white;
    background-color: var(--blue);
}

.clasic .fa-smile,
.clasic .fa-paperclip, 
.clasic .fa-paper-plane,
.clasic .fa-bolt {
    color: var(--hiperpbx-green)!important
}

.clasic .fa-smile:hover,
.clasic .fa-paperclip:hover, 
.clasic .fa-paper-plane:hover,
.clasic .fa-bolt:hover {
    color: white!important;
    background-color: var(--hiperpbx-green)!important;
}

.char-limit {
  font-size: 12px;
  color: var(--red);
  margin-top: 10px;
}

textarea {
  resize: none;
  width: 100%;
  border: none;
  overflow: hidden;
}
</style>
