<template>
  <div class="main-content">
    <header>
      <button @click="goBack">
        <i class="fas fa-angle-left"></i>
        {{ $t("common.go-back") }}
      </button>
      <h2>{{ $t("contact-me.pbx-of", { name }) }}</h2>
    </header>
    <div class="cards">
      <el-card shadow="hover" v-for="(item, index) in menu" :key="index" @click.native="$router.push({ path: item.route })" class="card">
        <div class="content">
          <i :class="item.icon"></i>
          <h3>{{ item.title }}</h3>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: [
        {
          icon: "fas fa-phone extensions",
          title: this.$t("contact-me.pbx.extensions.title"),
          route: "/contact-me/pbx/extensions"
        },
        {
          icon: "fas fa-users queues",
          title: this.$t("contact-me.pbx.queues.title"),
          route: "/contact-me/pbx/queues"
        },
        {
          icon: "fas fa-volume-up audios",
          title: this.$t("contact-me.pbx.audios.title"),
          route: "/contact-me/pbx/audios"
        },
        {
          icon: "fas fa-th ivrs",
          title: this.$t("contact-me.pbx.ivrs.title"),
          route: "/contact-me/pbx/ivrs"
        }
      ]
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-config" });
    }
  },
  computed: {
    name() {
      return this.$store.getters["contactMe/getOrganizationName"];
    }
  }
}
</script>

<style scoped>
button {
  outline: none;
  border: none;
  background-color: transparent;
}

h2 {
  margin: var(--column) 0;
}

h3 {
  margin: 0;
  font-size: 1.12em;
}

.cards {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.card:hover {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}
.cards .fas {
  display: block;
  padding: var(--column);
  border-radius: 50%;
}

.cards .extensions {
  color: var(--green-400);
  background-color: var(--green-100);
}

.cards .queues {
  color: var(--blue-400);
  background-color: var(--blue-100);
}

.cards .ivrs {
  color: var(--violet-400);
  background-color: var(--violet-100);
}

.cards .audios {
  color: var(--red-400);
  background-color: var(--red-100);
}

.content {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
</style>