<template>
  <div class="input-content">
    <a :href="configuration.url" v-if="input.type === 'link'">{{ input.label }}</a>
    <el-form-item :label="input.label" :required="configuration.required">
      <el-input :type="input.type" v-if="isTextType(input.type)" v-model="value" :disabled="disabled" style="width: 250px"/>
      <el-select type="select" v-if="input.type === 'select'" v-model="value" :disabled="disabled"
        :multiple="configuration.multiple">
        <el-option v-for="(option, j) in configuration.options" :key="j" :label="option"
          :value="option"></el-option>
      </el-select>
      <el-date-picker type="date" placeholder="Pick a day" v-if="input.type === 'date'" v-model="value" :default-value="defaultValue" :disabled="disabled"
        :picker-options="{ disabledDate: (date) => handleDisabledDate(input, date) }"></el-date-picker>
      <el-date-picker type="datetime" placeholder="Pick a day" v-if="input.type === 'datetime'" :disabled="disabled"
        v-model="value" :default-value="defaultValue"
        :picker-options="{ disabledDate: (date) => handleDisabledDate(input, date) }"></el-date-picker>
      </el-form-item>
      <p class="help">{{ input.description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    input: {
      required: true
    },
    defaultValue: {
      required: false
    },
    disabled: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      value: this.defaultValue
    }
  },
  methods: {
    handleDisabledDate (input, date) {
      return (input.configuration.minDate && input.configuration.minDate > date) || (input.configuration.maxDate && input.configuration.maxDate < date)
    },
    isTextType (type) {
      return ['text', 'textarea', 'number', 'email'].includes(type)
    },
  },
  computed: {
    configuration() {
      return typeof this.input.configuration === "string" ? JSON.parse(this.input.configuration) : this.input.configuration
    },
    isMultiple() {
      return this.configuration.multiple
    }
  },
  watch: {
    value(newValue ) {
      this.$emit('input', newValue)
    },
    isMultiple(multiple) {
      this.value = multiple ? [] : ''
    }
  }
}
</script>

<style scoped>
.help {
  font-size: 14px;
  color: #8492a6;
  max-width: 500px;
}
</style>