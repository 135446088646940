
const state  = {
    outputAudioVolume : parseInt(localStorage.getItem('outputAudioVolume')) || 50,
    outputAudioDevice : localStorage.getItem('outputAudioDevice') || 'default',
    inputAudioDevice : localStorage.getItem('inputAudioDevice') || 'default',
    notificationPermission : (Notification.permission === "granted") ? true : false,
    showNotifications: false,
    interactWithPage: false,
    showSoftphoneModal : false,
    showLeftMenu: false,
}

const getters = {
    getShowLeftMenu: (state) => state.showLeftMenu,
    getInteractWithPage: state => state.interactWithPage,
    getOutputAudioDevice : (state) => state.outputAudioDevice,
    getInputAudioDevice : (state) => state.inputAudioDevice,
    getOutputAudioVolumeRound : (state) => state.outputAudioVolume,
    getOutputAudioVolume : (state) => state.outputAudioVolume / 100,
    getDesktopNotificationPermission : (state) => state.notificationPermission,
    getShowSoftphoneModal : (state) => state.showSoftphoneModal,
    getShowNotifications: (state) => state.showNotifications,
}
const mutations = {
    SET_OUTPUT_AUDIO_VOLUME : (state, value) => (state.outputAudioVolume = value),
    SET_OUTPUT_DEVICE : (state, value) => (state.outputAudioDevice = value),
    SET_INPUT_DEVICE : (state, value) => (state.inputAudioDevice = value),
    SET_INTERACT_WITHH_PAGE: (state, value) => (state.interactWithPage = value),
    SET_SHOW_MODAL: (state, value) => (state.showSoftphoneModal = value),
    SET_SHOW_LEFT_MENU: (state, value) => (state.showLeftMenu = value),
    SET_SHOW_NOTIFICATIONS: (state,value) => state.showNotifications = value
} 

const actions = {
    updateOutputAudioVolume({commit}, value) {
        commit('SET_OUTPUT_AUDIO_VOLUME', value);
        localStorage.setItem('outputAudioVolume', value);
    },
    updateOutputAudioDevice({commit}, value) {
        commit('SET_OUTPUT_DEVICE', value);
        localStorage.setItem('outputAudioDevice', value);
    },
    updateInputAudioDevice({ commit }, value) {
        commit('SET_INPUT_DEVICE', value);
        localStorage.setItem('inputAudioDevice', value)
    },
    setInteractWithPage({commit}, value) {
        commit('SET_INTERACT_WITHH_PAGE', value);
    },
    setSettingValue({ rootGetters }, {setting, value = false}) {
        const user = rootGetters.getUser
        const firebase = rootGetters.getFirebase
        firebase.firestore().collection('users').doc(user.uid).collection('settings').doc(setting).set({ value })
    },
    listenSettings({ rootGetters, commit }) {
      const user = rootGetters.getUser
      const firebase = rootGetters.getFirebase
      firebase.firestore().collection('users').doc(user.uid).collection('settings').onSnapshot((snapshot) => {
			for (const change of snapshot.docChanges()) {
				if (change.type === 'added' || change.type === 'modified') {
					const setting = {...change.doc.data(), id : change.doc.id }
					if (setting.id === 'ALLOW_NOTIFICATIONS') {
            commit('SET_SHOW_NOTIFICATIONS', setting.value)
					}
				}
			}
		})


    }
}

export default {
    state, 
    getters,
    mutations,
    actions
}