<template>
  <button @click="onClick" class="videocall-button"><i class="fas fa-video"></i></button>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
export default {
  name: "VideocallButton",
  props : {
    roomId : {
      required : false
    },
    type : {
      required : false
    },
    userId: {
      required: false
    }
  },
  data() {
    return {
      id : "@" + new Date().getTime()
    }
  },
  computed : {
    ...mapGetters({
      user : 'getUser'
    }),
    chat() {
      return this.$store.getters["corpochat/getChatByRoomId"](this.roomId)
    }
  },
  methods : {
    ...mapGetters('corpochat', ['getChatById']),
    ...mapActions("corpochat", ['sendStartCallgroup']),
    ...mapActions(['addVideocall']),
    ...mapActions('_chats', ['createNewChatroom']),
    async onClick() {
      if (this.type !== 'meet') {
        await this.addVideocall({ id : this.id, participants : [ this.user.uid ], type : this.type, roomId : this.roomId, messageId : '1234' })
        let roomId = this.roomId
        if (!this.chat && this.userId) {
          const chat = await this.createNewChatroom(this.userId)
          roomId = !/[-]/.test(chat.room) ?  chat.room + '-' : chat.room
        }
        this.sendStartCallgroup({ roomId, id : this.id })
      } else {
        await this.addVideocall({ id : this.id, participants : [ this.user.uid ], type : this.type, roomId : this.roomId })
      }
      window.open(`/videocall/${this.id}`,"_blank")
    }
  } 

};
</script>

<style scoped>
button {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--blue-grey);
  cursor: pointer;
  margin-top: 0!important;
}


.clasic button {
  color: var(--hiperpbx-green)
}
</style>