const getLastPage = (view) => ( (view['hydra:last']) ? parseInt(view['hydra:last'].split('=').pop()) : 1 );

function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
          formData = buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
      formData.append(parentKey, value);
    }
    return formData
}
  
export const jsonToFormData = (data) => {
  const formData = new FormData();
  return buildFormData(formData, data);
}

export default {
    getLastPage,
}