<template>
  <div class="member">
    <user-item :user="user" type="dark" :showStatus="false" class="user"></user-item>
      <el-popconfirm title="Are you sure to delete this?" :confirmButtonText="$t('groups.members.sure-delete')" :cancelButtonText='$t("common.cancel")' @confirm="deleteUser">
        <el-button slot="reference" type="icon" :icon="!deleting ? 'fas fa-trash' : 'fas fa-circle-notch fa-spin'" v-if="owner && logged.uid !== user.uid" circle :disabled="deleting"></el-button>
      </el-popconfirm>
  </div>
</template>

<script>
import UserItem from "@/components/common/UserItem";

export default {
  data() {
    return {
      deleting : false,
    }
  },
  props: {
    roomId : {
      required: true
    },
    user: {
      required: true,
    },
    owner : {
      required: true,
      default: false,
    },
  },
  components: {
    UserItem,
  },
  computed : {
    logged() {
      return this.$store.getters.getUser
    }
  },
  methods : {
    async deleteUser() {
      this.deleting = true
      this.$emit('deleting')
      try {
        await this.$store.dispatch('deleteMemberOfGroup', {
          roomId : this.roomId,
          uid : this.user.uid,
          action : 'kick'
        })
        this.$emit('deleted', this.user)
      } catch (error) {
        console.error(error)
      } finally {
        this.deleting = false
      }
      
    }
  }
};
</script>

<style scoped>
.member {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}


.user :deep(.user-name)  p {
  max-width: 500px;
}
</style>