<template>
    <the-form :form="form" :store="store" class="crud form" :id="id" :edit="edit" :entity="entity" :title="title" :submit="submit"></the-form>
</template> 

<script>
import form from './formulario'
import TheForm from '@/components/crud/TheForm'
export default {
  props : {
    entity : {
      required : false,
    },
    id : {
      required : false,
      type : String,
      default : "0"
    }
  },
  data() {
    return {
      store : "clients",
      form
    }
  },
  components : {
    TheForm
  },
  computed: {
    edit() {
      return this.id !== "0"
    },
    title() {
      return this.edit ? 'Editar Cliente' : "Crear Cliente"
    }
  },
  methods : {
    submit(data) {
      data.uid = this.id === "0" ? this.$store.getters.getUser.uid : "0";
      return data;
    }
  }
}
</script>