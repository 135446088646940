import ticketapi from '@/api/tickets'

const state = {
  users: [],
  organizations: [],
}

const getters = {
  getUsers: state => state.users,
  getOrganizations: state => state.organizations
}

const mutations = {
  SET_USERS: (state, users) => state.users = users,
  UPDATE_USER: (state, user) => state.users = state.users.map(u => u.id === user.id ? user : u),
  SET_ORGANIZATIONS: (state, organizations) => state.organizations = organizations,
  ADD_ORGANIZATION: (state, organization) => state.organizations.push(organization),
}

const actions = {
  fetchUsers({ commit }, queryParams = {}) {
    return ticketapi.fetchUsers(queryParams)
    .then((response) => {
      commit('SET_USERS', response.data.data);
      return response.data;
    })
  },
  updateUser({commit}, user) {
    return ticketapi.updateUser(user)
    .then((response) => {
      commit('UPDATE_USER', response.data.data);
      return response.data;
    })
  },
  fetchOrganizations({ commit }, queryParams = {}) {
    return ticketapi.fetchOrganizations(queryParams)
    .then((response) => {
      commit('SET_ORGANIZATIONS', response.data.data);
      return response.data;
    })
  },
  createOrganization({commit}, organization) {
    return ticketapi.createOrganization(organization)
    .then((response) => {
      commit('ADD_ORGANIZATION', response.data.data);
      return response.data;
    })
  }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}