<template>
  <div class="group-item">
    <i class="fas fa-users"></i>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .group-item {
    background-color: var(--blue);
    border-radius: 50%;
    color: white;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
</style>