import List from "@/views/servers/live_helper_chat/List.vue"
import Form from '@/views/servers/live_helper_chat/Form.vue'

const routes = [
  {
    name: "livehelperchats",
    path: "/configuration/servers/chat",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.servers.chat',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
	name: "livehelperchatsNew",
	path: "/configuration/servers/chat/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    }
  },
  {
    name: "livehelperchatsEdit",
    path: "/configuration/servers/chat/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    },
    props: true,
  },
];

export default routes;
