<template>
  <el-form :model="form" ref="form" v-loading="searching" :rules="rules" :element-loading-text="saving ? $t('tickets.saving-the-client') : ''" size="small">
    <the-fields :fields="fields" :errors="errors" ref="fields" :rules="rules" :values="values"/> 
    <h3>Configuración avanzada</h3>
    <the-organization-input ref="organization"/>
    <el-form-item :label="$t('mail-center.users.password')" prop="password" :error="errors.password">
      <el-input v-model="form.password" show-password></el-input>
      <password v-model="form.password" :strength-meter-only="true"/>
    </el-form-item>
    <el-form-item :label="$t('mail-center.users.repeat-password')" prop="repeat_password" :error="errors.repeat_password">
      <el-input v-model="form.repeat_password" show-password></el-input>
    </el-form-item>
    <el-button @click="goBack" size="small">{{ $t("common.cancel") }}</el-button>
    <el-button type="primary" @click="save" size="small">{{ $t("common.save") }}</el-button>
  </el-form>
</template>

<script>
import ticket from '@/api/tickets'
import TheOrganizationInput from '@/components/mail-center/TheOrganizationInput';
import TheFields from '@/components/mail-center/TheFields';
import Password from 'vue-password-strength-meter'
export default {
  components: {
    Password,
    TheFields,
    TheOrganizationInput
  },
  props : {
    values : {
      required : false,
      default : () => ({})
    }
  },
  data() {
    const checkSamePassword = (rule, value, callback) => {
      if (value !== this.form.password ) {
        return callback(new Error());
      }
      return callback();
    };

    return {
      searching : false,
      fields: [],
      form : this.values,
      errors : {},
      rules : {
        password: [
          {
            message: this.$t(
              "configuration.users.verify-the-password-requirements"
            ),
            trigger: "blur",
          },
        ],
        repeat_password: [
          {
            validator: checkSamePassword,
            message: this.$t(
              "configuration.users.the-password-are-not-the-same"
            ),
          },
        ],
      },
      saved : false,
      saving : false
    }
  },
  methods : {
    async fetchForm() {
      try {
        this.searching = true
        const response = await ticket.fetchContactInformation()
        this.fields = response.data.data.fields
      } catch (error) {
        console.log(error)
      } finally {
        this.searching = false
      }
    },
    save() {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) {
          return
        }
        const values = this.$refs.fields.getValues();
        this.saving = true
        this.searching = true;

        try {
          let response;
          if (!this.values.id) {
            response = await ticket.createUser({...values,...this.form, org_id : this.$refs.organization.getValue()})
          } else {
            response = await ticket.updateUser({...this.form, ...values ,org_id : this.$refs.organization.getValue()});
          }
          this.saved = true
          this.$emit('created', response.data.data)
        } catch (error) {
          this.saved = false;
          this.errors = error.response.data.errors;
        } finally {
          this.searching = false;
          this.saving = false;
        }
      })
    },
    reset() {
      this.searching = false
      this.fields= null
      this.form = {}
      this.errors = {}
      this.saved = false
      this.saving = false
    },
    goBack() {
      if (/mailcenter/.test(this.$route.name)) {
        this.$router.push({ name : 'mailcenter.usersList' })
      } else {
        this.$emit("close");
      }
    }
  },
  
}
</script>

<style>

</style>