<template>
  <el-dialog width="50%" :center="true" :visible.sync="isVisible" :close-on-click-modal="false"
    :destroy-on-close="true">
    <section class="customer-search" v-loading="isLoading">
      <h2>
        <el-button @click="selectedCustomer = null" type="text" v-if="selectedCustomer" icon="fas fa-arrow-left">{{ $t('common.go-back') }}</el-button>{{ title }}
      </h2>
      <div class="customer-search__input-container" v-if="!selectedCustomer">
        <el-input :placeholder="$t('customer.search-customer-input-placeholder')" prefix-icon="el-icon-search"
          v-model="filteredValue" @keydown.native.enter="handleSearch">
        </el-input>
        <el-button @click="handleSearch" type="primary">{{ $t("common.search") }}</el-button>
      </div>
      <span :class="['customer-search__details-message', detailsMessage.type]">
        {{ detailsMessage.text }}
      </span>
      <div class="cutomer-search__info-rows-container" v-if="!selectedCustomer">
        <div class="cutomer-search__info-row-container" v-for="(customer, key) in friendlyCustomer" :key="key">
          <div @click="selectCustomer(key)" class="cutomer-search__info-row">
            <div class="customer-search__avatar-container">
              <i class="customer-search__avatar fas fa-user"></i>
            </div>

            <div class="customer-search__info-box">
              <span>{{ $t('common.name') }}: {{ customer.name }}</span>
              <span>{{ $t('common.lastname') }}: {{ customer.lastname }}</span>
              <span>{{ $t('common.phone') }}: {{ customer.phone }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <TheClientBox class="customer-search-dialog__client-info-box" :client="selectedCustomer" :isStatic="true" />
      </div>
    </section>
  </el-dialog>
</template>

<script>
import TheClientBox from '../../components/agent-console/TheClientBox.vue'
export default {
  props: {
    isExternalVisible: {
      required: true
    }
  },
  components: {
    TheClientBox
  },
  data () {
    return {
      customers: [],
      filteredValue: '',
      selectedCustomer: null,
      isDialogVisible: false,
      isLoading: false,
      isVisible: false,
      detailsMessage: {
        type: '',
        text: ''
      }
    }
  },
  methods: {
    async handleSearch () {
      try {
        if (this.filteredValue.trim().length > 2) {
          this.detailsMessage.text = ''
          this.isLoading = true
          this.customers = await this.$store.dispatch("customers/fetchCustomerByKey", { organizationId: this.organizationId, key: 'name', value: this.filteredValue })
          this.isLoading = false
          if (this.customers.length === 0) {
            this.detailsMessage.text = this.$t('agent-console.no-results');
            this.detailsMessage.type = 'message'
          }
        } else {
          this.detailsMessage.text = this.$t('common.more-than-three');
          this.detailsMessage.type = 'alert'
        }
      } catch (error) {
        this.isLoading = false
        this.isVisible = false
      }


    },
    selectCustomer (index) {
      this.selectedCustomer = this.customers[index]
      this.isDialogVisible = true
    }
  },
  computed: {
    organizationId () {
      return this.$store.getters["getAgent"].organization_id.toString()
    },
    activeClient () {
      return this.$store.getters['getActiveItem']
    },
    isValidActivity () {
      return this.activeClient && this.activeClient.type === 'chat'
    },
    friendlyCustomer () {
      const customersTmp = [...this.customers];
      const customerModified = customersTmp ? customersTmp.map((element) => {
        const result = element.values.reduce((tmpObj, currentObj) => {
          tmpObj[currentObj.key] = currentObj.value
          return tmpObj
        }, {})
        return result
      }) : []
      return customerModified
    },
    title() {
      return this.selectedCustomer ? this.selectedCustomer.values.filter(v => v.key === 'name' || v.key === 'lastname').map(v => v.value).join(' ') : this.$t("customer.search-customer")
    }
  },
  watch: {
    isExternalVisible (newValue) {
      this.isVisible = newValue;
    },

    isVisible (newValue) {
      if (!newValue) {
        this.$emit('closeDialog')
      }
    }
  }
}
</script>

<style scoped>
section {
  max-height: 100vh;
  overflow: hidden;
  padding: 0 calc(var(--column) / 2);
}

.customer-search h2 {
  display: inline-flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.customer-search__input-container {
  display: flex;
  gap: 5px;
  margin-bottom: 10px;
}

.cutomer-search__info-rows-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 50vh;
  overflow-y: auto;

}

.cutomer-search__info-row {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #dcdfe6;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: #606266;
  align-items: center;
  gap: 5px;
}

.customer-search__info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.customer-search__avatar-container {
  background-color: var(--light-green);
  border-radius: 50px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customer-search__avatar {
  font-size: 30px
}

.customer-search__details-message {
  font-weight: bold;
  font-size: 12px;
}

.customer-search__details-message.alert {
  color: red;
}
</style>