<template>
  <div>
    <h2>{{ $t("agent-console.call-disposition.title") }}</h2>
    <p v-if="!activeClient">{{ $t("agent-console.call-disposition.select-a-call-before-classifying") }}</p>
    <div v-else>
      <p>{{ $t("agent-console.call") }} {{activeClient.title}}</p>
      <el-select v-model="clasificationIds" multiple size="small" @remove-tag="remove" @change="change" placeholder="Seleccione las clasificaciones de la llamada" :collapse-tags="true">
        <el-option v-for="disposition in dispositions" :key="disposition.id" :value="disposition.id" :label="disposition.name" :disabled="savedForms.includes(disposition.id)"/>
      </el-select>
      <el-collapse v-model="active">
        <el-collapse-item v-for="(form, index) in forms" :key="index" :title="getClasification(form.additional_data.call_disposition_id)" :name="index">
          <Form :form="form" :path="path + '/' + form.key" @submit="submit" style="margin-right: 10px"></Form>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import Form from '@/components/agent-console/Form'
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {
      active : 1,
      values : [],
      fetching : []
    }
  },
  components : {
    Form
  },
  methods: {
    ...mapActions(['listenToAction','setFormStatusToActive']),
    ...mapActions('call_disposition', ['fetchCallDispositions','fetchFormByCallDispositionIds','saveForm','saveCallDisposition','removeCallDispositionForm']),
    change(values) {
      // if (values.length > this.values.length) {
      //   this.fetchFormByCallDispositionIds({
      //     ids : values[values.length - 1],
      //     callKey : this.activeClient.callKey
      //   })
      // }
      this.values = values;
    },
    async submit(form) {
      const data = {
        formKey : form.key,
        callKey : this.activeClient.callKey,
        form : {
          agent : this.agent.number,
          uniqueid : this.activeClient.data.uniqueid,
          callerid : this.activeClient.data.callerid ? this.activeClient.data.callerid : this.activeClient.title,
          disposition : this.getClasification(form.additional_data.call_disposition_id),
          inputs : form.inputs
         }
      }
      try {
        await this.saveForm(data)
        await this.setFormStatusToActive(this.path + '/' + data.formKey )
      } catch (err) {
        console.log(err);
      }
    },
    getClasification(id) {
      console.log(this.dispositions)
      console.log(id)
      return this.dispositions.find(disposition => disposition.id === id).name
    },
    remove(id) {
      if (this.savedForms.includes(id)) {
        //this.clasificationIds.push(id)
        return;
      }

      Object.keys(this.activeClient.call_disposition_forms)
        .map(index => this.activeClient.call_disposition_forms[index])
        .filter(form => form.additional_data.call_disposition_id === id)
        .map(form => form.key)
        .forEach(index => {
          this.removeCallDispositionForm({ formKey : index, callKey: this.activeClient.callKey, id })
        })

      this.saveCallDisposition({callKey : this.activeClient.callKey, values : this.clasificationIds})
    }
  },
  computed: {
    ...mapGetters('call_disposition', {
      dispositions : 'getDispositions'
    }),
    ...mapGetters({
      agent : 'getAgent',
      activeClient : 'getActiveItem'
    }),
    forms() {
      return this.activeClient ? this.activeClient.call_disposition_forms : {}
    },
    savedForms() {
      if (this.activeClient) {
        return Object.keys(this.activeClient.call_disposition_forms)
          .map(index => this.activeClient.call_disposition_forms[index])
          .filter(form => 'status' in form && form.status === 'saved')
          .map(form => form.additional_data.call_disposition_id)
      }

      return [];
    },
    organizationId() {
      return this.agent.organizationId
    },
    path() {
      return this.activeClient ? `organization/${this.agent.organization_id}/calls/${this.activeClient.callKey}/call_disposition_forms` : ''
    },
    clasificationIds : {
      get() {
        return this.activeClient ? this.activeClient.call_disposition_values : []
      },
      set(values) {
        this.saveCallDisposition({callKey : this.activeClient.callKey, values })
      }
    },
  },
  created() {
    this.fetchCallDispositions();
  },
  watch : {
    activeClient() {
      this.values = this.clasificationIds;
    },
    values(values, old) {
      values.forEach(value => {
        const find = Object.keys(this.forms).find(key => this.forms[key].additional_data.call_disposition_id == value)
        if (!old.find(o => o == value) && !find) {
          this.fetchFormByCallDispositionIds({
           ids : value,
           callKey : this.activeClient.callKey
          })
        }
      })

      old.forEach(value => {
        if (!values.find(o => o == value)) {
          this.remove(value)
        }
      })
    },
  }
}
</script>

<style>

</style>