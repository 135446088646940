import i18n from '@/i18n'

export default {
    nodes : [
      { component : "h3" , label : i18n.tc('common.basic_information') },
      { component : "input", label : i18n.tc('common.name'), index : "name", required : true },
      { component : "input", label : i18n.tc('configuration.servers.ostickets.address'), index : "address", required : true },
      { component : "input", label : i18n.tc('configuration.servers.ostickets.api_address'), index : "restApiAddress", required : true },
      { component : "input", label : i18n.tc('configuration.servers.ostickets.username'), index : "adminUsername", required : true },
      { component : "input", label : i18n.tc('configuration.servers.ostickets.password'), index : "passwordUsername", required : true }
    ]
  }