<template>
  <div>
    <div v-if="!listening">
      <img src="@/img/voicemail.svg">
      <p>{{ $t("softphone.voicemail.title") }}</p>
      <div v-if="voicemail > 0">
        <p>{{ $t("softphone.voicemail.you-have-voicemails", { voicemail: voicemail}) }}</p>
        <el-button type="success" @click.native="onClick">{{ $t("softphone.voicemail.listen") }}</el-button>
      </div>
      <div v-else>
        <p>{{ $t("softphone.voicemail.you-dont-have-voicemails") }}</p>
      </div>
    </div>
    <div v-else>
      <pulse-animation class="ringback">
        <i class="fas fa-microphone"></i>
      </pulse-animation>
      <el-button type="success" @click.native="hangup">
        {{ $t("sofphone.voicemail.stop-listening") }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PulseAnimation from '@/components/common/PulseAnimation';
export default {
  components : {
    PulseAnimation
  },
  computed : {
    ...mapGetters({
      janus : 'getJanus',
      voicemail : 'getVoicemail',
      listening : 'isListeningVoicemail'
    }),
  },
  methods : {
    onClick() {
      this.janus.call("*97", "Voicemail");
    },
    hangup() {
      this.janus.hangup();
    }
  }
}
</script>

<style scoped>
  img {
    display: block;
    width: 100%
  }

  p {
    margin-top: var(--column);
    text-align: center;
    color: var(--blue-grey);
  }

  button {
    width: 100%
  }

  .ringback {
  margin: 0 auto;
  margin-bottom: calc(var(--column) * 2)
}

.ringback .fa-microphone {
  color: white;
  position: relative;
}
</style>