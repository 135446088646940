<template>
  <div class="callhistory">
    <div style="display: inherit;">
      <el-tooltip class="item" effect="dark" placement="right" :content="$i18n.messages[$i18n.locale]['common'][call.type]">
        <span class="type"><i class="fas" :class="className"></i></span>
      </el-tooltip>
      <el-popover
        v-if="call.displayname.length > 11"
        placement="top-start"
        width="200"
        trigger="hover"
        :content="call.displayname">
      
        <p slot="reference" class="bold displayName">{{ call.displayname }}</p>
      </el-popover>
      <p v-else slot="reference" class="bold displayName">{{ call.displayname }}</p>
    </div>
    <div class="buttons">
      <div class="time">
        <p v-if="!isToday">{{ formatedCallDate }}</p>
        <p v-else>{{ formatedCallDate }}</p>
      </div>
      <phone-button :janus="janus" :extension="call.number" :displayname="call.displayname" class="phone"></phone-button>
    </div>
  </div>
</template>

<script>
import PhoneButton from '../webrtc/PhoneButton'
import { mapGetters } from 'vuex'
export default {
  components: { 
    PhoneButton 
  },
  props : {
    call : {
      required : true
    }
  },
  computed : {
    ...mapGetters({
      janus : 'getJanus',
    }),
    className() {
      return {
        'fa-arrow-up': this.call.type === 'outgoing',
        'fa-arrow-down': this.call.type === 'ingoing',
        'fa-arrow-down lost': this.call.type === 'lost',
      }
    },
    isToday() {
      const today = new Date()
      const date = new Date(this.call.date)
      console.log(this.call.date)
      if (today.getFullYear() === date.getFullYear() && today.getDate() === date.getDate() && today.getMonth() === date.getMonth()) {
        return true
      }

      return false;
    },
    formatedCallDate(){
      const date = new Date(this.call.date);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;
      console.log(formattedDate);
      return formattedDate;
    }
  }
}
</script>

<style scoped>
  .callhistory {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    color: var(--blue-grey);
    margin-bottom: 7px;
  }

  .time p {
    text-align: center;
  }


  .fa-arrow-up,
  .fa-arrow-down {
    font-size: 12px;
    transform: rotate(45deg);
  }
 
  .lost {
    color: var(--red);
  }

  .type {
    margin-right: var(--column);
  }

  .buttons {
    display: inline-flex;
    align-items: baseline;
  }

  .phone {
    margin-left: var(--column);
  }
  .callhistory .displayName{
    width: 100px;
    margin-right: var(--column);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
</style>