<template>
  <div class="agent-options" v-if="showMenu && menu.length > 0">
    <div class="main-agent-menu-content" v-if="show">
      <router-view name="agentConsoleTools"></router-view>
    </div>
    <div class="main-agent-menu">
      <ul>
        <el-tooltip v-for="(link, index) in menu" :key="index" :content="link.name" effect="dark" placement="left-start">
          <menu-icon-item :link="link" :active="link.path === active.path" :showName="false" @click.native="setActive(link)"/>
        </el-tooltip>
        <el-tooltip v-if="agent.enableSendTemplate" class="item send-template-icon" :content="$t('chatTemplate.title')" effect="dark" placement="left-start">
          <menu-icon-item :link="{title: $t('chatTemplate.title'), icon: 'fab fa-whatsapp'}" :showName="false" @click.native="handleModalTemplateMessage" />
        </el-tooltip>
        
        <el-tooltip v-if="agent.customersEnabled" class="item" :content="$t('customer.search-customer')" effect="dark" placement="left-start">
          <menu-icon-item :link="{title: $t('customer.search-customer'), icon: 'fas fa-search'}" :showName="false" @click.native="handleDialogSearchCustomer" />
        </el-tooltip>
      </ul>
      <el-dialog class="modalTemplateMessage" 
        :destroy-on-close="true" 
        :visible.sync="showModalTemplateMessage" 
        :title="$t('chatTemplate.configurationTemplateTitle')" 
        :close-on-click-modal="false" 
        :close-on-press-escape="allowCloseModal" 
        :show-close="allowCloseModal"
        top="5vh"
      >
        <TheModalTemplateMsgBody @closeModal="showModalTemplateMessage=false; allowCloseModal = true" @sendingTemplate="allowCloseModal = false"></TheModalTemplateMsgBody>
      </el-dialog>
      <TheSearchCustomer :isExternalVisible="showDialogSearchCustomer" @closeDialog="showDialogSearchCustomer=false"></TheSearchCustomer>
    </div>
  </div>
</template>

<script>
import MenuIconItem from "@/components/common/MenuIconItem";
import TheModalTemplateMsgBody from "@/components/agent-console/TheModalTemplateMsgBody";
import { mapGetters } from "vuex";
import TheSearchCustomer from '../../views/agent-console/TheSearchCustomer.vue';
export default {
  name: "TheAgentMenu",
  data() {
    return {
      active: {
        name: "",
        path: "",
      },
      show: false,
      showMenu: true,
      showModalTemplateMessage: false,
      showDialogSearchCustomer: false,
      allowCloseModal: true
    };
  },
  methods: {
    goBack() {
      this.$router.push({ path: "/agent-console" }).catch(() => {})
      this.show = false;
      this.active = {
        name: "",
        path: "",
      };
    },
    setActive(link) {
      if (this.active.path !== link.path) {
        this.active = link;
        this.show = true;
        this.$router.push({ path: `/agent-console/${link.path}` /*, append: true */ }).catch(() => {})
      } else {
        this.goBack();
      }
    },
    toggleMenu(route) {
      this.showMenu = /agent-console/.test(route.path);
      if (!this.showMenu) {
        this.show = false;
        this.active = {
          name : "",
          path: "",
        }
      }
    },
    handleModalTemplateMessage(){
      this.showModalTemplateMessage = true;
    },
    handleDialogSearchCustomer(){
      this.showDialogSearchCustomer = true;
    }
  },
  mounted() {
    document.querySelector("#app > .main-view").append(this.$el);
    if (this.$route.path !== '/agent-console') {
      const item = this.menu.find(menu => menu.pathName === this.$route.name);
      this.show = true;
      this.active = item;
    } else {
      this.show = false;
    }
  },
  beforeDestroy() {
    this.$el.remove();
  },
  components: {
    MenuIconItem,
    TheModalTemplateMsgBody,
    TheSearchCustomer
  },
  watch: {
    $route: "toggleMenu",
  },
  computed: {
    ...mapGetters({
      agent: "getAgent",
    }),
    menu() {
      const menu = [];

      if (this.agent.has_dashboard) {
        menu.push({
          icon: "fas fa-users",
          name: "Dashboard",
          path: "dashboard",
          pathName: "AgentDashboard",
        });
      }

      menu.push({
        icon : "fas fa-tags",
        name : this.$t("agent-console.call-disposition.title"),
        path : "call-clasification",
        pathName : 'CallClasification',
      })


      if (this.$store.getters.isAdmin) {
        menu.push({
          icon : "fas fa-comment-alt",
          name : this.$t("agent-console.transcriptions"),
          path : "call-transcription",
          pathName : 'CallTranscription',
        })
      }

      if (this.agent.customersEnabled) {
          menu.push({
            icon : "fas fa-user-plus",
            name: this.$t("customer.add-customer"),
            path : "new-customer",
            pathName : 'AddNewCustomer',
          })
        }
      return menu;
    },
  },
};
</script>
<style>
.modalTemplateMessage .el-dialog{
  width: 65% !important;
}
.send-template-icon .fa-whatsapp{
  font-size: 20px !important;
}
</style>
<style scoped>
.agent-options {
  display: inline-flex;
}

.main-agent-menu {
  background-color: var(--dark-blue);
  display: flex;
  flex-direction: column;
  padding: var(--column);
  height: 100%;
}

.main-agent-menu ul {
  text-align: center;
  list-style: none;
}

.main-agent-menu-content {
  padding: var(--column);
  background: white;
  max-width: 400px;
}

.main-agent-menu-content .header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.light .main-agent-menu {
  background: var(--light-menu-color);
}

.clasic .main-agent-menu {
  background-color: var(--hiperpbx-grey);
}

</style>