<template>
  <div class="contact-list">
    <h2>{{ $t("softphone.contacts.title") }}</h2>
    <the-search-input v-model="search" :placeholder="$t('softphone.contacts.search')"></the-search-input>
    <ol class="list">
      <li v-for="list in orderedContacts" :key="list.letter">
        <span>{{ list.letter }}</span>
        <ol>
          <li v-for="(contact, index) in list.contacts" :key="index" class="item">
            <user-item :user="contact"></user-item>
            <phone-button v-if="contact.extensions.length === 1" :extension="contact.extensions[0].value" :displayname="contact.name" :janus="janus"></phone-button>
            <button v-else @click="showDialog = true; callTo = contact"><i class="fas fa-phone"></i></button>
          </li>
        </ol>
      </li>
    </ol>

    <div class="modal-drawer" v-if="showDialog" @click="callTo = null; showDialog = false">
      <div v-if="callTo">
        <h3>{{ $t("softphone.contacts.choose-how-to-call", { name : callTo.name }) }}</h3>
        <ul>
          <li v-for="(extension, index) in callTo.extensions" :key="index">
            <div>
              <span class="d-block">{{ extension.label  }}</span>
              <span class="d-block">{{ extension.value  }}</span>
            </div>
            <phone-button :extension="extension.value" :displayname="callTo.name" :janus="janus"></phone-button>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import UserItem from "@/components/common/UserItem";
import TheSearchInput from '@/components/html/TheSearchInput'
import PhoneButton from '../webrtc/PhoneButton';
export default {
  data() {
    return {
      search : '',
      name : '',
      showDialog : false,
      callTo : null,
    }
  },
  components : {
    UserItem,
    TheSearchInput,
    PhoneButton
  },
  methods: {
    ...mapActions("extensions", { fetchExtensions : "all"} ),
    ...mapActions("contacts", ["listenContacts", "deleteContact"]),
    ...mapActions("organization_contacts", { fetchOrganizationContacts: "all", deleteOrganizationContact : "destroy" }),
  },
  computed : {
    ...mapGetters({ janus : 'getJanus'}),
    ...mapGetters("_users", ["getUsers"]),
    ...mapGetters("contacts", { tmpcontacts: "getContacts"}),
    ...mapGetters("extensions", {'getExtensions' : 'getData'} ),
    ...mapGetters("organization_contacts", { organizationContacts: "getData" }),
    contacts: function () {
      const users = this.getUsers.filter(user => user.extension !== "").map(user => ({ name : user.fullname, status : user.status, extensions : [{
        value : user.extension
      }] }))
      const extensions = this.getExtensions.map(extension => ({ name : extension.name, lastname : '', status : 'none', extensions : [{ value : extension.extension}] }))
      const organizationContacts = this.organizationContacts.map(user => {
        const numbers = [{ label : 'Telefono', value  : user.phone }, { label : 'Extension', value : user.extension }, { label : 'Telefono 1', value : user.phone_one, }, { label : 'Telefono 2', value : user.phone_two }]
        return { 
          name : user.name, 
          lastname : user.lastname,
          status : 'none', 
          extensions : numbers.filter(number => number.value !== null && number.value !== "")
        }
      })
      const contacts = this.tmpcontacts.map(user => ({ name : user.name, status : 'none', extensions : [] }))
      return [...users, ...contacts, ...extensions, ...organizationContacts ].filter(user => user.extensions.length > 0).filter(contact => !this.search || contact.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
    },
    orderedContacts: function() {
      const all = []
      this.contacts.map(contact => {
        const letter = contact.name.slice(0, 1).toLowerCase()
        const position = all.findIndex(a => a.letter === letter)
        if (position === -1) {
          all.push({ letter, contacts : [contact]})
        } else {
          all[position].contacts.push(contact)
        }
      })

      return all.sort((a, b) => {
        if (a.letter < b.letter) { 
          return -1; 
        }

        if (a.letter > b.letter) { 
          return 1; 
        }

        return 0;
      })
    }
  }
}
</script>

<style scoped>
  ul,
  ol {
    list-style: none;
  }



  ol li span {
    text-transform: uppercase;
    display: block;
    font-weight: bold;
    width: 100%;
    color: #333645
  }

  input {
    margin-bottom: var(--column);
  }

  .list {
    list-style: none;
    height: 65vh;
    display: block;
    overflow-y: auto;
    margin-top: var(--column);
  }

  .item {
    padding: var(--column) 0;
    display: inline-flex;
    width: 95%;
    justify-content: space-between;
    color: var(--blue-grey);
  }

  .item:last-child {
    border-bottom: 0px;
  }

  h2 {
    font-size: 20px;
  }

  .contact-list {
    position: relative
  }

  .modal-drawer {
    position: absolute;
    background: rgba(0,0,0,0.5);;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .modal-drawer > div {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    padding: var(--column);
    width: 80%;
  }

  ul li{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: var(--column);
    font-size: 14px;
  }

  h3 {
    font-size: 1rem;
  }

  button {
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--blue-grey);
  }

  .clasic button {
    color: var(--green-button);
  }

</style>