<template>
  <li class="list-item">
    <router-link
      :to="{ name: route.name, params: route.props }"
      class="menu-item"
      exact-active-class="active"
      @click.native.prevent="handleClick"
      event
      v-if="!min"
    >
      <i v-if="route.meta.icon" :class="route.meta.icon" class="icon"></i>
      <span v-if="!min" class="name">{{ $t(route.meta.title) }}</span>
    </router-link>
    <div v-else>
      <router-link
        :to="{ name: route.name, params: route.props }"
        class="menu-item"
        exact-active-class="active"
        @click.native.prevent="handleClick"
        event
        v-if="!isTitle"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t(route.meta.title)"
          placement="right"
        >
          <i v-if="route.meta.icon" :class="route.meta.icon" class="icon"></i>
        </el-tooltip>
      </router-link>
      <el-dropdown v-else trigger="click" placement="top-end">
        <span class="el-dropdown-link">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t(route.meta.title)"
            placement="right"
          >
            <i :class="route.meta.icon" class="icon menu-item"></i>
          </el-tooltip>
        </span>
        <el-dropdown-menu slot="dropdown" v-if="childrens.length > 0">
          <el-dropdown-item v-for="(children, index) in childrens" :key="index">
            <el-dropdown v-if="children.items">
                <router-link
                :to="{ name: children.name, params: children.props }"
                class="dropdown menu-item"
                exact-active-class="active"
                >{{ $t(children.meta.title) }}</router-link>
                <el-dropdown-menu slot="dropdown" placement="bottom-start">
                    <el-dropdown-item v-for="(child,idx) in children.items" :key="idx">
                        <router-link
                        :to="{ name: child.name, params: child.props }"
                        class="dropdown menu-item"
                        exact-active-class="active"
                        >{{ $t(child.meta.title) }}</router-link>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <router-link v-else
                :to="{ name: children.name, params: children.props, query : children.meta.query ?? {}  }"
                class="dropdown menu-item"
                exact-active-class="active"
                >{{ $t(children.meta.title) }} </router-link>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    route: {
      required: true,
    },
    min: {
      required: true,
      default: false,
    },
  },
  methods: {
    handleClick() {
      if (this.isTitle) {
        this.$emit("childrenMenu", this.route);
      } else {
        if (this.$route.name !== this.route.name) {
          try {
            this.$emit('clicked');
            this.$router.push({ name: this.route.name, params : this.route.props, query: this.route.meta.query ?? {} });
          } catch (err) {
            console.info(err)
          }
        }
      }
    },
  },
  computed: {
    isTitle() {
      return this.route.meta.type === "title";
    },
    childrens() {
      return this.route.children
        ? this.route.children.filter((menu) => {
            if (menu.meta && menu.meta.isInMenu) {
              if (menu.meta.custom_access_control) {
                return menu.meta.custom_access_control(this.$store.getters.getUser, this.$store.getters.getAgent  );
              }
              return true;
            }
          })
        : [];
    }
  },
  created( ){
    //busco los sub-sub-items
    if( this.route.children && this.route.children.length > 0 ){
        this.route.children.forEach( (c,i) => {
            if(c.children){
                this.route.children[i].items = this.route.children[i].children.filter( e => (
                    e.meta 
                    && e.meta.isInMenu
                    //&& e.meta.custom_access_control(this.$store.getters.getUser, this.$store.getters.getAgent  )
                ) )
            }
        });
    }
  },
};
</script>

<style scoped>
li.list-item {
  font-family: "Nunito", sans-serif;
  padding: 0.5em;
  padding-top: 0;
}

.menu-item {
  border-radius: var(--radius);
  color: var(--menu-color);
  cursor: pointer;
  display: block;
  text-decoration: none;
  padding: calc(1em / 2);
  transition: color 0.2s;
}

.dropdown.menu-item {
  padding: 0 calc(1em / 2);
}

span.name {
  margin-left: 10px;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--menu-color-active);
  text-decoration: none;
}

.router-link-active {
  color: white;
}

.dropdown.menu-item:hover,
.dropdown.menu-item.router-link-active {
  color: var(--blue);
}

.min .main span.name {
  display: none;
}

p {
  color: var(--blue-grey);
  margin: var(--column);
  font-weight: bold;
}

.light .menu-item {
  color: var(--light-menu-links-color);
}

.light .router-link-active {
  color: var(--hiperpbx-dark-green);
}

.light .menu-item:hover {
  background-color: #ffffff;
}

.clasic .menu-item {
  color: white;
}

.clasic .router-link-active {
  background-color: rgba(255, 255, 255, 0.1);
}

.clasic .menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>