<template>
  <div>
    <div class="header mb-3">
      <h3 class="mb-0">{{ $t("configuration.organization.organizations") }}</h3>
      <div>
        <el-button type="primary" size="mini" @click="$router.push({ name : 'mailcenter.newOrganization' })" >{{ $t("configuration.organization.create") }}</el-button>
      </div>
    </div>
    <el-table class="custom-table" :data="organizations" style="width: 100%" v-loading="loading">
      <el-table-column prop="id" label="#"/>
      <el-table-column prop="name" :label="$t('common.name')"/>
      <el-table-column >
        <template slot-scope="scope">
          <el-button size="mini" class="custom" circle icon="fas fa-pencil-alt" @click="handleEdit(scope.row)"/>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      small
      layout="prev, pager, next"
      :total="pagination.total"
      :page-size="pagination.per_page"
      @current-change="changePage">
    </el-pagination>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
  data() {
    return {
      loading: true,
      pagination : {
        total : 0,
        per_page: 0
      },
      page: 1
    }
  },
  methods : {
    ...mapActions('mailcenter', ['fetchOrganizations', 'updateOrganization']),
    handleEdit(organization) {
      this.$router.push({name: 'mailcenter.organizationEdit', params: {id: organization.id, organization}});
    },
    async fetch() {
      this.loading = true
      try {
        const data = await this.fetchOrganizations({
          page : this.page
        });

        if (data.meta) {
          this.pagination = data.meta;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    changePage(val) {
      this.page = val;
      this.fetch();
    },
  },
  async created() {
    this.fetch()
  },
  computed : {
    ...mapGetters('mailcenter', {
      organizations : 'getOrganizations',
    })
  }
}
</script>

<style scoped>
.header {
  align-items: center;
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}
</style>