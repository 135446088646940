import store from '@/store';
import TheForm from "@/views/contact-me-configuration/queues/TheForm.vue"
import TheQueues from "@/views/contact-me-configuration/queues/TheList.vue"

const routes = [
  {
    name: "contact-me-pbx-queues",
    path: "/contact-me/pbx/queues",
    component: TheQueues,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "contact-me-pbx-queues-new",
    path: "/contact-me/pbx/queues/new",
    component: TheForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
  },
  {
    name: "contact-me-pbx-queues-edit",
    path: "/contact-me/pbx/queues/:id/edit",
    component: TheForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
    beforeEnter: (to, from, next) => {
      const id = to.params.id;
      if (!store.getters['pbx_queues/getItemById'](id)) {
        return next({ name : "contact-me-pbx-queues"})
      }
      return next();
    }
  }
];

export default routes;
