<template>
  <div v-loading="loading">
    <div v-if="!isFormSended">
      <h2>{{ $t("tickets.open-new-ticket") }}</h2>
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="top"
        class="form"
        size="small"
        status-icon
      >
        <div class="new-ticket-container">
          <div class="new-ticket__column">
            <h3>{{ $t("tickets.users-and-collaborators") }}</h3>
            <TheUserInput @userCreated="userCreated" ref="userInput" />
            <el-form-item :label="$t('tickets.collaborators')">
              <el-select
                v-model="form.collaborators"
                :placeholder="$t('tickets.select-cc')"
                filterable
                remote
                multiple
                :remote-method="searchUsersCC"
                allow-create
              >
                <el-option
                  v-for="item in userscc"
                  :key="item.id"
                  :label="`${item.name} (${getEmail(item)})`"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="row">
              <el-form-item :label="$t('tickets.notifiy')" class="col-12">
                <el-select
                  v-model="form.notify"
                  :placeholder="$t('tickets.select-who-notify')"
                >
                  <el-option
                    v-for="item in notify"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="new-ticket__column info-and-options">
            <h3>{{ $t("tickets.information-and-options") }}</h3>
            <div class="new-ticket__column new-ticket-container">
              <div class="new-ticket__column ">
                <el-form-item :label="$t('tickets.source')">
                  <el-select
                    v-model="form.source"
                    :placeholder="$t('tickets.select-source')"
                  >
                    <el-option
                      v-for="item in sources"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('tickets.sla-plan')">
                  <el-select
                    v-model="form.sla_id"
                    :placeholder="$t('tickets.select-the-sla-plan')"
                  >
                    <el-option
                      v-for="item in data.sla"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('tickets.due-date')">
                  <el-date-picker
                    v-model="form.duedate"
                    type="datetime"
                    :placeholder="$t('tickets.select-a-date')"
                    :picker-options="pickerOptions"
                    value-format="timestamp"
                    style="width:100%"
                  >
                  </el-date-picker>
                </el-form-item>
              </div>
              <div class="new-ticket__column ">
                <el-form-item :label="$t('tickets.help-topic')" prop="topic_id">
                  <el-select
                    v-model="form.topic_id"
                    :placeholder="$t('tickets.select-the-help-topic')"
                    @change="fetchForms(form.topic_id)"
                    filterable
                  >
                    <el-option
                      v-for="item in data.helpTopic"
                      :key="item.topic_id"
                      :label="item.topic"
                      :value="item.topic_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('tickets.departament')"
                  :error="errors.dept_id"
                  prop="dept_id"
                >
                  <el-select
                    v-model="form.dept_id"
                    :placeholder="$t('tickets.select-the-departament')"
                    filterable
                  >
                    <el-option
                      v-for="item in data.departments"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  :label="$t('tickets.assigned-to')"
                  :error="errors.assigned"
                  required
                  prop="assigned"
                >
                  <el-select
                    v-model="form.assigned"
                    :placeholder="$t('tickets.select-the-staff-member')"
                    filterable 
                  >
                  
                  <el-option-group
                    v-for="group in assigned"
                    :key="group.id"
                    :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-option-group>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
      
        <div v-loading="loadingForms">
          <the-form v-for="(form, index) in forms" :key="index" :form="form" :ref="'form' + index" :errors="formsErrors[index]"></the-form>
        </div>

        <div style="margin-bottom: var(--column)">
          <el-checkbox v-model="form.add_forms_to_thread">{{ $t('tickets.add_forms_to_thread') }}</el-checkbox>
        </div>

        <el-popconfirm
          confirm-button-text="Yes"
          cancel-button-text="No, Thanks"
          icon="el-icon-info"
          icon-color="red"
          title="¿Desea borrar el borrador del ticket?"
          @confirm="clean"
          @cancel="goBack"
        >
          <el-button slot="reference" style="margin-right: 10px">{{
            $t("common.cancel")
          }}</el-button>
        </el-popconfirm>

        <el-button type="primary" @click="submit" :disabled="loadingForms">{{
          $t("tickets.open-ticket")
        }}</el-button>
      </el-form>
    </div>
    <div v-else>
      <NotificationABM
        route="TicketsOpened"
        :status="wasSaved"
        :successTitle="$t('tickets.the-ticket-was-created-successfully')"
        :errorTitle="$t('tickets.there-was-a-proble-trying-to-create-the-ticket')"
        :showMessage="false"
        @retry="resend"
      />
      <p class="text-center"><router-link :to="{ name: prevRoute.name,  query: prevRoute.query }" v-if="prevRoute.name">{{ $t('common.back_list') }}</router-link></p>
      <p v-if="!response.status_alert.status && response.status_alert.message_agents && form.notify !== 'none'" class="text-center">{{ $t('tickets.the-ticket-was-created-but-the-agents-of-the-new-ticket-could-not-be-notified-(via-email)') }}</p>
      <p v-if="!response.status_alert.status && response.status_alert.message_user_css && form.notify !== 'none'" class="text-center">{{ $t('tickets.the-ticket-was-created-but-the-user-of-the-new-ticket-could-not-be-notified-(via-email)') }}</p>
    </div>
    <TheCreateUserModal :show="showCreateUserModal" @userWasCreated="addUserCreated"></TheCreateUserModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import NotificationABM from "@/components/common/NotificationABM";
import TheUserInput from "./TheUserInput";
import TheForm from "./TheForm"
import TheCreateUserModal from "../../components/mail-center/TheCreateUserModal.vue";

export default {
  name: "TheNewTicket",
  data() {
    return {
      showCreateUserModal: false,
      response : null,
      loading: true,
      isFormSended: false,
      wasSaved: false,
      users: [],
      userscc: [],
      data: {},
      rules: {
        topic_id: [
          {
            required: true,
            message: this.$t("tickets.new-ticket-validation.subject"),
          },
        ],
        dept_id : [
          {
            required: true,
            message: this.$t("tickets.new-ticket-validation.deparment")
          }
        ],
        assigned : [
          {
            required: true,
            message: this.$t("tickets.new-ticket-validation.staff")
          }
        ]
      },
      errors: {
        assigned: "",
        dept_id: "",
      },
      sources: [
        { value: "Phone", label: this.$t("tickets.sources.phone") },
        { value: "Email", label: this.$t("tickets.sources.email") },
        { value: "Other", label: this.$t("tickets.sources.other") },
      ],
      notify: [
        { value: "all", label: this.$t("tickets.notify.all") },
        { value: "user", label: this.$t("tickets.notify.user") },
        { value: "none", label: this.$t("tickets.notify.none") },
      ],
      pickerOptions: {
        disabledDate(time) {
          return Date.now() > time.getTime();
        },
      },
      formsErrors : [],
      loadingForms: false,
      prevRoute: null
    };
  },
  components: {
    NotificationABM,
    TheUserInput,
    TheForm,
    TheCreateUserModal
  },
  methods: {
    ...mapGetters(["getDataToCreateTicket", "getEraserTicket"]),
    ...mapActions([
      "fetchDataToCreateANewTicket",
      "createNewTicket",
      "setEraserTicket",
      "cleanTicket",
      "fetchFormsByHelpTopic"
    ]),
    init() {
      this.data = this.getDataToCreateTicket();
      this.users = this.data.users;
      this.userscc = this.data.users;
      this.form.sla_id = parseInt(this.data.defaultValues.default_sla_id) === 0 ? "" : parseInt(this.data.defaultValues.default_sla_id);
      this.form.topic_id = parseInt(this.data.defaultValues.default_help_topic) === 0 || !("default_help_topic" in this.data.defaultValues) ? "" : parseInt(this.data.defaultValues.default_help_topic);
      this.form.priority = parseInt(this.data.defaultValues.default_priority_id);

      if (this.form.topic_id) {
        this.fetchForms()
      }
    },
    async fetchForms() {
      this.loadingForms = true
      await this.fetchFormsByHelpTopic(this.form.topic_id)
      this.loadingForms = false
    },  
    searchUser(value) {
      if (value !== "") {
        setTimeout(() => {
          this.users = this.getDataToCreateTicket().users.filter((item) => {
            return (
              item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
              item.email.address.toLowerCase().indexOf(value.toLowerCase()) > -1
            );
          });
        }, 200);
      } else {
        this.users = this.getDataToCreateTicket().users;
      }
    },
    searchUsersCC(value) {
      if (value !== "") {
        setTimeout(() => {
          this.userscc = this.getDataToCreateTicket().users.filter((item) => {
            if (item) {
              return (
                item.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                item.email.address && item.email.address.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
                typeof item.email === 'string' && item.email.toLowerCase().indexOf(value.toLowerCase()) > -1
              );
            }
          });
        }, 200);
      } else {
        this.userscc = this.getDataToCreateTicket().users;
      }
    },
    async submit() {
      const promises = [];
      for (let key in this.$refs) {
          if (this.$refs[key][0]) {
            const form = this.$refs[key][0];
            promises.push(form.getFieldsAndValues());
          }
      }

      const formValues = await Promise.all(promises);

      this.$refs.form.validate((valid) => {
        this.$refs.userInput.getForm().validate(async (userValid) => {
          if (valid && userValid) {
            this.formsErrors = []

            this.loading = true;

            this.form.user_id = this.$refs["userInput"].value;
            const form = this.form;
            const [key, value] = form.assigned.split(":");
            form.user_id = form.user_id ? form.user_id : null;

            form.staff_id = key === "staff" ? value : null;
            form.team_id = key === "team" ? value : null;

            form.dept_id = form.dept_id ? form.dept_id : null;
            form.forms = formValues

            try {
              const response = await this.createNewTicket(form)
              this.isFormSended = true;
              this.wasSaved = true;
              this.response = response
            } catch (error) {
              console.log(error)
              if (error.response.status === 422) {
                const { data } = error.response
                for (const err in data.errors) {
                  if (/^forms/.test(err)) {
                    const value = data.errors[err]
                    const indexs = err.split('.')
                    const formIndex = indexs[1]
                    const valueIndex = indexs[3]

                    if (!this.formsErrors[formIndex]) {
                      this.formsErrors[formIndex] = []
                    }

                    if (!this.formsErrors[formIndex][valueIndex]) {
                      this.formsErrors[formIndex][valueIndex] = value
                    } else {
                      this.formsErrors[formIndex][valueIndex].push(value)
                    }
                  }
                }
                /* const details = error.response.data.detail;
                for (let index in details) {
                  this.errors[index] = details[index][0];
                } */
              }
            } finally {
              this.loading = false
            }

          }
        })
      })
      
    },
    resend() {
      this.loading = true;
      this.createNewTicket(this.form)
        .then(() => {
          this.loading = false;
          this.isFormSended = true;
          this.wasSaved = true;
        })
        .catch(() => {
          this.loading = false;
          this.isFormSended = true;
        });
    },
    userCreated(user) {
      this.users.push(user);
      this.form.user_id = user.id;
    },
    clean() {
      //this.$refs.TheTicketResponse.setValue("");
      this.cleanTicket();
      this.goBack();
    },
    goBack() {
      this.$router.back(-1);
    },
    getEmail(item) {
      return typeof item.email === 'string' ? item.email : item.email.address
    },
    addUserCreated(user) {
      this.form.collaborators = this.form.collaborators.filter(cc => typeof cc !== 'string')
      this.form.collaborators.push(user.id)
    }
  },
  computed: {
    ...mapGetters({
      forms : 'getHelpTopicForms'
    }),
    form: {
      get() {
        return this.getEraserTicket();
      },
      set(value) {
        return this.setEraserTicket(value);
      },
    },
    staffs() {
      if (!this.data.staff) return [];

      const staffs = this.data.staff;

      return staffs.map(staff => ({
        label: staff.firstname + " " +  staff.lastname,
        value: `staff:${staff.staff_id}`,
      })).sort((a, b) => {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
    },
    teams() {
      if (!this.data.teams) return [];

      const teams = this.data.teams

      return teams.map(team => ({
        label: team.name,
        value: `team:${team.team_id}`
      })).sort((a, b) => {
        var textA = a.label.toUpperCase();
        var textB = b.label.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      })
    },
    assigned() {
      return [{
        "id" : "staff",
        "label" : "Staff",
        "options" : this.staffs,
      },
      {
        "id" : "teams",
        "label" : "Teams",
        "options" : this.teams,
      }];
    }
  },
  beforeDestroy() {
    this.setEraserTicket(this.form);
  },
  created() {
    this.fetchDataToCreateANewTicket().then(() => {
      this.loading = false;
      this.init();
    });
  },
  watch: {
    ['form.collaborators'](users) {
      this.showCreateUserModal = false
      users.forEach((user) => {
        if (typeof user === 'string') {
          this.showCreateUserModal = true
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  }
};
</script>

<style scoped>
.new-ticket-container {
  display: inline-flex;
  width: 100%!important;
  gap: 16px;
}

.new-ticket__column {
  width: 50%
}


</style>

<style>
.info-and-options .el-form-item {
  margin-bottom: 3px!important
}</style>