<template>
  <the-cards-menu :menu="menu"></the-cards-menu>
</template>

<script>
import TheCardsMenu from '@/components/menu/TheCardsMenu';
export default {
  data() {
    return {
      menu: [{
        title: this.$t('contact-me.chat-center.providers'),
        icon: "fas fa-users chat-icon",
        route: "chatProvidersList"
      },{
        title: this.$t('contact-me.chat-center.deparments'),
        icon: "fas fa-users chat-icon",
        route: "departmentsList"
      },{
        title: this.$t('contact-me.chat-center.bots'),
        icon: "fas fa-robot chat-icon",
        route: "botsList"
      },{
        title: this.$t('contact-me.chat-center.cache-chats'),
        icon: "fas fa-comments chat-icon",
        route: "cacheChatList"
      },{
        title: this.$t('contact-me.chat-center.chat-dispositions'),
        icon: "fas fa-tag chat-icon",
        route: "chatDispositionsList"
      },
      {
        title: this.$t('contact-me.chat-center.time-conditions'),
        icon: "fas fa-clock chat-icon",
        route: "timeConditionList"
      },
      {
        title: this.$t('contact-me.form.title'),
        icon: "fas fa-align-justify chat-icon",
        route: "formsList"
      },
      {
        title: this.$t('contact-me.chat-center.chat-classification'),
        icon: "fas fa-tag  chat-icon",
        route: "chatClassificationList"
      },{
        title: this.$t('contact-me.chat-center.quick-response-template.menu-title'),
        icon: "fas fa-comment-dots chat-icon",
        route: "quickResponseTemplatesList"
      }]
    }
  },
  components: {
    TheCardsMenu
  }
}
</script>

<style>
.fas.chat-icon {
  color: var(--blue-400);
  background-color: var(--blue-100);
}

</style>