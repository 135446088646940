<template>
  <div>
    <el-form ref="form" :model="form" label-position="top" class="form" size="small" status-icon >
      <el-form-item prop="response" :error="errors.response" style="width: 100%; margin-bottom: 0">
        <vue-editor v-model="form.response" class="text-editor"></vue-editor>
        <el-input placeholder="Please input" type="hidden" v-model="form.response"></el-input>
      </el-form-item>
    </el-form>
    <el-upload ref="upload" action="" name="files" :auto-upload="false" :multiple="true" :on-change="onChange" :on-remove="onRemove">
        <el-button slot="trigger" size="small" type="primary">Selecciona un archivo</el-button>
    </el-upload>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name : "TheTicketResponse",
  props : {
    value : {
      default : '',
    }
  },
  data() {
    return {
      form : {
        response : this.value,
      },
      fileName : '',
      errors : {},
      files : []
    }
  },
  methods : {
    getForm() {
      return this.$refs.form;
    },
    getFiles() {
      return this.files;
    },
    setValue(value) {
      this.form.response = value
    },
    onChange(file, fileList) {
      this.files = fileList.map(file => file.raw)
    },
    onRemove(file, fileList) {
      this.files = fileList.map(file => file.raw)
    },
    cleanFiles() {
      this.files = []
      this.$refs.upload.clearFiles()
    }
  },
  components : {
    VueEditor,
  }
};
</script>

<style scoped>
.el-form-item,
.text-editor {
  margin: 0px!important
}

.text-editor {
  background: white;
  border-radius: var(--radius);
  width: 100%;
  /* border: 1px solid #DCDFE6; */
}

.files {
  margin-top: 10px;
  justify-content: start;
}

.fa-paperclip {
  margin-left: 0px
}

label:hover {
  cursor: pointer;
}
</style>