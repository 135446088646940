<template>
    <el-switch
      v-model="presence"
      active-text="Web"
      inactive-text="App">
    </el-switch>
</template>

<script>
export default {
  computed: {
    presence: {
      get() {
        return this.$store.getters.getPresence === 'web'
      },
      set(value) {
        this.$store.dispatch('setPresence', value ? 'web' : 'app')
      }
    }
  }
}
</script>
