const state = {
  integrations: [],
}

const getters = {
  getIntegrations: (state, getters, rootState, rootGetters) => {
    const integrations = state.integrations;
    integrations["zoho"].enabled = rootGetters["zoho/getZohoEnabled"];
    return integrations;
  },
  getIntegrationByName: (state) => (name) => state.integrations[name],
}

const mutations = {
  addIntegration: (state, integration) => (state.integrations.push(integration)),
  setIntegrations: (state, integrations) => (state.integrations = integrations),
  toggleIntegration: (state, { name, value }) => (state.integrations[name].enabled = value)
}

const actions = {
  toggleIntegration({ commit, dispatch }, { name, value}) {
    const action = value ? "enable" : "disable";
    dispatch(`${name}/${action}`, null, { root: true });
    commit("toggleIntegration", { name, value})
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}