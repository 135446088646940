<template>
  <div class="message-item" @click="handleClick">
    <div class="info">
      <el-badge :value="chat.unread" :max="99" class="item" v-if="!fullItem" :hidden="chat.unread === 0">
        <user-item :user="user" v-if="chat.type === 'user'" :showFullname="false" class="user-item"></user-item>
        <group-item v-else></group-item>
      </el-badge>
      <div v-else>
        <user-item :user="user" v-if="chat.type === 'user'" :showFullname="false" class="user-item"></user-item>
        <group-item v-else></group-item>
      </div>
      <div>
        <p class="mb-0 name">{{ chat.name }}</p>
        <div v-if="lastMessage">
          <p class="mb-0" v-if="lastMessage.type === 'text'">{{ lastMessage.text }}</p>
          <the-system-message v-else-if="lastMessage.type === 'system' || lastMessage.from === 'system'" :message="lastMessage"></the-system-message>
          <p class="mb-0" v-else><i class="fas" :class="typeMessage"></i> {{ textMessage }}</p>
        </div>
        <p class="mb-0" v-else>Todavía no has iniciado un chat</p>
      </div>
    </div>
    <div>
      <p v-if="lastMessage" class='date mb-0'>{{ chat.lastMessage.date | chat }}</p>
      <div class="buttons">
        <el-badge :value="chat.unread" :max="99" class="unread-message" :hidden="chat.unread == 0"/>
        <i class="fas fa-thumbtack" v-if="chat.favorite"></i>
        <the-message-dropdown :chat="chat" v-if="fullItem" class="the-message-dropdown" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserItem from '@/components/common/UserItem'
import GroupItem from '@/components/common/GroupItem'
import TheSystemMessage from '@/components/chat/TheSystemMessage'
import TheMessageDropdown from '@/components/messages/TheMessageDropdown'
export default {
  props : {
    chat : {
      required : true
    },
    fullItem : { 
      required : true,
      default : false,
      type : Boolean
    }
  },
  methods : {
    ...mapActions('corpochat', ['updateUnreadMessage','addOpenedMinChat', 'toggleIsMaxOpenedMinChat']),
    handleClick() {
      this.updateUnreadMessage({ id : this.chat.id, type : this.chat.type })
      this.openChat()
    },
    openChat() {
      if (/^\/messages/.test(this.$router.currentRoute.path)) {
        this.$router.push({
					name: "MessagesWith",
					params: {
						id: this.chat.id,
            chat : this.chat
					},
				}).catch(() => {})
      } else {
        
        if (!this.getOpenedMinChatById(this.chat.id)) {
          this.addOpenedMinChat(this.chat)
        } else {
          this.toggleIsMaxOpenedMinChat(this.chat)
        }
      }
    }
  },
  computed : {
    ...mapGetters('_chats', ['getChatByRoomId']),
    ...mapGetters('corpochat',['getOpenedMinChatById']),
    user : function() {
      return this.chat.user
    },
    lastMessage : function() {
      return this.chat.lastMessage
    },
    totalUnreadMessages : function() {
      return this.chat.unread
    },
    typeMessage : function() {
      return {
        'fa-file' : this.lastMessage.type === 'file',
        'fa-camera' : this.lastMessage.type === 'image'
      }
    },
    textMessage : function() {
      const types = { 'file' : this.lastMessage.filename, 'image' : 'Imagen' }
      return types[this.lastMessage.type]
    }
  },
  components : {
    UserItem,
    GroupItem,
    TheMessageDropdown,
    TheSystemMessage
  },
  watch : {
    totalUnreadMessages(value) {
      if (value > 0) {
        const audio = new Audio('/sounds/appointed.mp3');
        audio.play();
      }
    }
  }
}
</script>

<style scoped>
.message-item {
  align-items: self-start;
  cursor: pointer;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

p {
  font-size: .9em;
}

.message-item .info > div:last-child {
  margin-left: calc(var(--column) / 2)
}

.min .message-item .info div:last-child,
.min .date,
.min .unread-message {
  display: none
}

.info {
  align-items: center;
  display: inline-flex;
}

.info p {
  display: -webkit-box;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

p.name {
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
	font-weight: bold;
}

.buttons {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
}

.fa-thumbtack,
.the-message-dropdown {
  color: var(--blue-grey);
}

.message-item:hover .the-message-dropdown :deep(.fa-angle-down) {
  display: block;
  margin-left: 10px;
}

.fa-thumbtack {
  transform: rotate(45deg);
}

</style>