const state = {
  dispositions : [],
  fetching : []
}

const getters = {
  getDispositions : state => state.dispositions,
}

const mutations = {
  setDispositions : (state, values) => (state.dispositions = values),
  addFetchingValue : (state, value) => (state.fetching.push(value)),
  removeFetchingValue : (state, value) => (state.fetching = state.fetching.filter(val => val !== value))
}

const actions = {
  saveCallDisposition({rootGetters}, {callKey, values}) {
    const firebase = rootGetters.getFirebase;
    const organizationId = rootGetters.getOrganizationId;
    return firebase.database().ref(`organization/${organizationId}/calls/${callKey}`).update({
      call_disposition_values : values
    })
  },
  fetchCallDispositions({rootGetters, commit}) {
    const firebase = rootGetters.getFirebase;
    const organizationId = rootGetters.getOrganizationId;
    firebase.firestore().collection('call_dispositions').doc(organizationId + "").onSnapshot((data) => {
      if (data.exists) {
        const dispositions = data.data().call_dispositions.filter(disposition => disposition.status === 'ACTIVE' && disposition.name);
        commit('setDispositions', dispositions);
      }
    });
  },
  fetchFormByCallDispositionIds({rootGetters, commit}, {ids, callKey}) {
    const user = rootGetters.getUser;
    const agent = rootGetters.getAgent;
    const firebase = rootGetters.getFirebase;
    const organizationId = rootGetters.getOrganizationId;

    const hash = ids + '@' + callKey

    if (state.fetching.includes(hash)) {
      return 
    }

    commit('addFetchingValue', hash)

    return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
      type: 'fetch_form_by_call_disposition_ids',
      ids : ids,
      callKey : callKey,
      need_response : true
    })
  },
  saveForm({rootGetters}, {form, callKey, formKey}) {
    const user = rootGetters.getUser;
    const agent = rootGetters.getAgent;
    const firebase = rootGetters.getFirebase;
    const organizationId = rootGetters.getOrganizationId;
    return firebase.database().ref(`organization/${organizationId}/group-${agent.groupNumber}/agents/${user.uid}/actions/call_center`).push({
      type: 'save_call_disposition',
      form,
      callKey,
      formKey
    });
  },
  removeCallDispositionForm({rootGetters, commit}, { callKey, formKey, id}) {
    const firebase = rootGetters.getFirebase;
    const organizationId = rootGetters.getOrganizationId;

    const hash = id + '@' + callKey
    commit('removeFetchingValue', hash)

    firebase.database().ref(`organization/${organizationId}/calls/${callKey}/call_disposition_forms`).child(formKey).remove();
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}