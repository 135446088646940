<template>
  <div class="box" v-if="chat" style="height: 100%">
    <div class="call">
      <div class="header">{{ chat.nick }}</div>
    </div>
    <div class="acept-chat">  
      <div v-if="chat.status === 0"> 
        <img src="@/img/undraw_chat_1wo5.svg" />
        <div v-if="chat.agentIsTryingToAccept === 0">
          <p v-if="!waiting">{{ $t("agent-console.click") }} <a href="" @click.prevent="acceptChat">{{ $t("agent-console.here") }}</a> {{ $t("agent-console.to-acept-the-chat-with", { name: chat.nick }) }} </p>
          <p v-else> {{ $t("agent-console.please-wait-while-the-chat-is-accepted") }} </p>
        </div>
        <div v-else>
          <p v-if="agent.chat_center_id === chat.agentIsTryingToAccept">{{ $t("agent-console.please-wait-while-the-chat-is-accepted") }} </p>
          <p v-else>{{ $t("agent-console.the-chat-has-already-been-accepted-by-another-agent")}}</p>
          <p> {{ $t("common.click") }} <a href="" @click.prevent="closeWindow">{{ $t("common.here") }}</a> {{ $t("common.to-close-this-window") }}</p>
        </div>
      </div>
      <div v-if="chat.status === 2" >
        <p>{{ $t("agent-console.the-chat-was-closed") }}</p>
        <p> {{ $t("common.click") }} <a href="" @click.prevent="closeWindow">{{ $t("common.here") }}</a> {{ $t("common.to-close-this-window") }}</p>
      </div>
      <div v-if="chat.status === 1">
        <p v-if="agent.chat_center_id === chat.agentIsTryingToAccept">{{ $t("agent-console.the-chat-was-assigned-to-you-open-it") }}</p>
        <p v-else> El chat fue aceptado por otro agente <br> {{ $t("common.click") }} <a href="" @click.prevent="closeWindow">{{ $t("common.here") }}</a> {{ $t("common.to-close-this-window") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TheAceptChatWindow",
  data() {
    return {
      waiting: false
    };
  },
  methods: {
    ...mapGetters(["getPendingChatAcept", "getAgent"]),
    ...mapActions([
      "acceptPendingChat",
      "listenToAction",
      "removeAction",
    ]),
    acceptChat() {
      this.waiting = true;
      this.acceptPendingChat(this.chat);
      /* this.acceptPendingChat(this.chat).then((snap) => {
        this.listenToAction({
          path: "chat_center",
          actionId: snap.key,
          callback: (data) => {
            if (data) {
              if ("status" in data) {
                setTimeout(() => {
                  this.removeAction({
                    path: "chat_center",
                    actionId: snap.key,
                  });
                  this.waiting = false;
                }, 1000);
              }
            }
          },
        });
      }); */
    },
    closeWindow() {
      this.$emit("closeWindow");
    }
  },
  computed: {
    chat() {
      return this.getPendingChatAcept();
    },
    agent() {
      return this.getAgent();
    },
  },
  mounted() {
    this.waiting = false;
  }
};
</script>

<style scoped>
.main-content {
  height: 100%;
}

.main-content .title {
  color: var(--blue-grey);
}

.header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

img {
  width: 20em;
}

.acept-chat {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20%;
  text-align: center;
}
</style>