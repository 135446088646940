<template>
  <section>
    <el-dropdown trigger="click">
      <el-button>
        <i class="fas fa-ellipsis-v"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(button, index) in buttons"
          :key="index"
          :icon="button.icon"
          v-show="button.show"
          @click.native="button.handleClick"
        >
          {{ button.label }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <the-tag-chat-modal
      :show="showTagModal"
      :room-id="roomId"
      @closeModal="handleCloseModal"
    />
    <TheClasificaseChatModal 
      :show="showClassificationModal"
      :room-id="roomId"
      @closeModal="handleCloseModal"></TheClasificaseChatModal>
  </section>
</template>

<script>
import TheTagChatModal from './TheTagChatModal.vue'
import TheClasificaseChatModal from './TheClasificaseChatModal.vue'
import { mapActions } from "vuex"
export default {
  props: {
    roomId: {
      required: true,
      type: String,
    },
    active: {
      require: true,
      type: Boolean
    },
    chatId: {
      required: true,
      type: String,
    }
  },
  data () {
    return {
      showTagModal: false,
      showClassificationModal: false
    }
  },
  methods: {
    ...mapActions([
      "doCloseChat",
      "listenToAction",
      "removeAction",
      "markChatAsPending"
    ]),
    handleCloseModal() {
      this.showTagModal = false
      this.showClassificationModal = false
    },
    showTransferDrawer () {
      this.$emit("showTransferDrawer", { type: "chat", data: { roomId: this.roomId } })
    },
    closeChatDialog () {
      const chat = this.chat
      const name = chat ? chat.title : ''
      this.$confirm(this.$t("agent-console.chat.are-you-sure-to-close-the-chat", { name })).then(async (confirmResponse) => {
        if (confirmResponse === "confirm") {
          const response = await this.doCloseChat({ roomId: this.roomId, chat_id: this.chatId })
          const actionKey = response.key
          if (chat.createdWitChatEngine) {
            this.$store.dispatch("agentConsoleChat/listenToChatEngineAction", { key: actionKey, callback: (data) => {
              this.callback(chat, data, actionKey, "agent-console.chat.success-chat-closed", "agent-console.chat.error-chat-closed")
            }})
          } else {
            this.listenToAction({
              path: "chat_center", actionId: actionKey, callback: (data) => {
                this.callback(chat, data, actionKey)
              }
            })
          }
        }
      })
    },
    callback (chat, data, actionKey, successMessage, errorMessage) {
      if (data && "status" in data) {
        const name = chat.title
        this.$message({
          showClose: true,
          message: data.status === "success" ? this.$t(successMessage, { name }) : this.$t(errorMessage, { name }),
          type: data.status === "success" ? "success" : "error"
        })
        if (chat.createdWitChatEngine) {
          this.$store.dispatch("agentConsoleChat/removeAction", actionKey)
        } else {
          this.removeAction({ path: "chat_center", actionId: actionKey })
        }
      }
    },
    async markAsPending() {
      const name = this.chat.title
      const confirmResponse = await this.$confirm(this.$t("agent-console.chat.are-you-sure-to-mark-the-chat-as-pending", { name }))
      if (confirmResponse === "confirm") {
        const response = await this.markChatAsPending({ roomId: this.roomId, chat_id: this.chatId })
        const { key } = response
        this.$store.dispatch("agentConsoleChat/listenToChatEngineAction", { key, callback: (data) => { 
          this.callback(this.chat, data, key, 'agent-console.chat.success-chat-pending', 'agent-console.chat.error-chat-pending') 
        }})          
      }
    }
  },
  computed: {
    chat () {
      return this.$store.getters["getChatInChatCenterByRoomId"](this.roomId)
    },
    buttons () {
      return [
      {
          label: this.$t("agent-console.buttons.mark-as-pending"),
          show: this.chat.createdWitChatEngine,
          handleClick: this.markAsPending,
          icon: "fas fa-history",
        },
        {
          label: this.$t("agent-console.buttons.tag-chat"),
          show: true,
          handleClick: () => this.showTagModal = true,
          icon: "fas fa-tag"
        },
        {
          label: this.$t("agent-console.buttons.transfer"),
          show: this.active,
          handleClick: this.showTransferDrawer,
          icon: "fas fa-reply"
        },
        /*{
          label: this.$t("agent-console.buttons.clasificase-chat"),
          show: true,
          handleClick: () => this.showClassificationModal = true,
          icon: "fas fa-tag",
        },*/
        {
          label: this.$t("agent-console.buttons.close-chat"),
          show: this.active,
          handleClick: this.closeChatDialog,
          icon: "fas fa-times",
        }
      ]
    }
  },
  components: {
    TheTagChatModal,
    TheClasificaseChatModal
  }
}
</script>