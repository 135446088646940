import i18n from '@/i18n'

export default {
    nodes : [
      { component : "h3" , label : i18n.tc('common.basic_information') },
      { component : "input", label : i18n.tc('common.name'), index : "name", required : true },
      { component : "input", label : i18n.tc('configuration.servers.firebase.cloud_region'), index : "cloudFunctionRegion", required : true },
      { component : "input", type: "textarea", label : i18n.tc('configuration.servers.firebase.config_filename'), rows: 10, index : "firebaseConfigContent", required : true },
      { component : "input", type: "textarea",  label : i18n.tc('configuration.servers.firebase.account_filename'), rows: 10, index : "serviceAccountContent", required : true },
      { component : "input", type: "textarea",  label : i18n.tc('configuration.servers.firebase.iosConfig'), rows: 10, index : "firebaseIosConfigContent", required : false },
    ]
  }