<template>
  <div class="home">
    <div class="home__header-titles">
      <div class="favorite-contacts-title"></div>
    </div>
    <div class="content-header-container">
      <div class="home__left-header-section">
        <h2>{{ $t("home.welcome-back", { name: user.name }) }}</h2>
        <div class="content-header">
          <el-card shadow="never">
            <div class="middle">
              <the-change-profile-picture class="margin" />
              <!-- <p style="margin-bottom: 0" v-if="extension">
                <span class="label">{{
                  $t("configuration.agents.extension")
                }}</span
                >
                <span>{{ extension }}</span>
              </p> -->
            </div>
          </el-card>
          <el-card shadow="never" v-if="extension">
            <div
              class="middle"
              style="position: relative"
              v-loading="!isJanusReady"
            >
              <img src="@/img/voicemail.svg" width="80" class="margin" />
              <span class="voicemail-count">
                {{ voicemail }}
              </span>
              <div v-if="voicemail > 0 && isJanusReady">
                <!-- <p class="text-cente margin">
                  {{
                    $t("home.you-have-unheard-voicemails", {
                      voicemail: voicemail,
                    })
                  }}
                </p> -->
                <!-- <el-button
                  type="success"
                  style="display: block; margin: 0 auto"
                  @click.native="listenVoicemails"
                  >{{ $t("softphone.voicemail.listen") }}</el-button
                > -->
              </div>
              <!-- <p
                v-if="voicemail === 0 && isJanusReady"
                style="margin-bottom: 0"
              >
                {{ $t("home.congratulations!-You-have-no-unheard-voicemails") }}
              </p> -->
            </div>
          </el-card>
          <!-- <el-card shadow="never" v-if="!isRoleBasic">
            <div class="middle">
               <img
                src="@/img/undraw_begin_chat_re_v0lw.svg"
                width="80"
                class="margin"
              /> 
              <i class="fas fa-comments" style="font-size: 50px; color: var(--green)"></i>
              <p class="text-center message-section-text" style="margin-bottom: 0">{{ message }}</p>
            </div>
          </el-card> -->
        </div>
      </div>
      <div class="favorites-contacts-container" v-if="contacts.length > 0">
        <div class="favorites-contacts-title-container">
          <h3>{{ $t("contacts.favorite-contacts") }}</h3>
        </div>
        <div
          class='favorites-contacts'
          id="favorites-contact"
          ref="favoritesContacts"
        >
        <div class="favorites-contact-items-container">
          <the-favorite-contact-item
            v-for="(user, index) in contacts"
            :key="index"
            :user="user"
            style="gap: var(--column)"
            class="favorite-item"
          ></the-favorite-contact-item>
        </div>
        </div>
      </div>
    </div>
    <div class="rss">
      <div class="rss__news-header-container">
        <div class="rss__news-title">
          <h3>{{ $t("home.news") }}</h3>
          <el-link
            class="rss__redirect-btn"
            type="success"
            :href=blogUrl
            target="blank"
            :underline="false"
            >{{ $t("home.moreNews") }}</el-link
          >
        </div>
        <el-button
          type="success"
          class="rss__news-btn"
          @click="showNewsLetterForm = true"
          >{{ $t("home.subscribeNewsletter") }}</el-button
        >
        <el-dialog :visible.sync="showNewsLetterForm">
          <getresponse-form
            form-id="ee93486c-f6e3-47db-8afa-80e8c14783e0"
            e="1"
          ></getresponse-form>
        </el-dialog>
      </div>
      <div class="content">
        <el-card
          class="rss__article"
          v-for="(item, index) in rssData"
          :key="index"
          style="cursor: pointer"
          shadow="hover"
          @click.native="open(item.link)"
        >
          <div
            class="rss__article-content-container"
            style="
              display: flex;
              justify-content: space-between;
              flex-direction: column;
            "
          >
            <div class="rss__article-image-container">
              <img
                class="rss__image-article"
                :src="item.image.url"
                v-if="item.image"
                style="margin-right: var(--column)"
              />
              <div class="rss__image-article-overlay"></div>
              <div v-if="item.categories" class="rss__article-tags-container">
                <span
                  v-for="(category, key) in item.categories"
                  :key="key"
                  class="rss__article-tag"
                >
                  {{ category["_"] }}
                </span>
              </div>
            </div>
            <div
              :class="[
                'rss__description-container',
                {
                  'rss__description-container--full':
                    item.image == null ? true : false,
                },
              ]"
            >
              <div>
                <h4>{{ item.title }}</h4>
                <p class="summary">{{ item.contentSnippet }}</p>
              </div>
              <p
                style="
                  font-size: 14px;
                  margin-top: var(--column);
                  margin-bottom: 0;
                "
              >
                {{ item.pubDate | date }}
              </p>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <el-dialog v-if="zohoIntegration" :visible.sync="showModalZoho" width="30%">
      <div class="zohoModal">
        <img :src="zohoIntegration.img" />
        <img src="@/img/success.svg" class="success" />
        <p>
          Tu cuenta de Zoho ha sido vinculada exitosamente. Ahora podrás
          aprovechar todas las funcionalidades y beneficios de Zoho para
          potenciar tus operaciones empresariales
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Parser from "rss-parser";
import { mapGetters } from "vuex";
import TheFavoriteContactItem from "@/components/common/TheFavoriteContactItem";
import TheChangeProfilePicture from "@/components/account/TheChangeProfilePicture";
export default {
  data() {
    return {
      rssData: [],
      showModalZoho: true,
      showNewsLetterForm: false,
      blogUrl: ''
    };
  },
  computed: {
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters({
      user: "getUser",
      voicemail: "getVoicemail",
      isJanusReady: "isJanusReady",
      token: "getToken",
      janus: "getJanus",
    }),
    ...mapGetters("corpochat", { chats: "getChats" }),
    unreadMessages() {
      return this.chats
        .filter((chat) => chat.unread > 0)
        .map((chat) => chat.unread)
        .reduce((acc, curr) => acc + curr, 0);
    },
    message() {
      return this.unreadMessages === 0
        ? this.$t("home.congratulations!-You-have-no-unread-messages")
        : this.$t("home.you-have-unread", { unread: this.unreadMessages });
    },
    extension() {
      if (this.user.localExtension) {
        return this.user.localExtension.extension;
      }
      return "";
    },
    contacts() {
      const aux = this.getAllContacts.filter((contact) => contact.isFavorite);

      return aux;
    },
    isRoleBasic() {
      return this.user.roles.includes("ROLE_BASIC");
    },
    zohoIntegration() {
      return this.$store.getters["integrations/getIntegrationByName"]("zoho");
    },
    zohoWasConnected() {
      return this.$store.getters["zoho/getConnected"];
    },
  },
  methods: {
    listenVoicemails() {
      this.janus.call("*97", "Voicemail");
    },
    open(link) {
      window.open(link);
    },
  },
  async created() {
    this.showModalZoho = this.zohoWasConnected;
    const parser = new Parser({
      customFields: {
        item: ["media:thumbnail", "atom:summary", { keepArray: true }],
      },
    });
    try {
      const feed = await parser.parseURL(
        process.env.VUE_APP_API_HOST + "/rss-feed"
      );
      console.log(feed)
      this.rssData = feed.items
        .sort((a, b) => {
          return new Date(b.isoDate) - new Date(a.isoDate);
        })
        .map((item) => {
          item.image = item["media:thumbnail"]
            ? item["media:thumbnail"]["$"]
            : null;
          if (item.image) {
            console.log(/(\/s72-).*?\//.test(item.image.url));
            item.image.url = item.image.url.replace(/s72-.*/, "s520-nu");
            
          }
          return item;
        });

      if (this.rssData.length > 4) {
        this.rssData.splice(4);
      }
      this.blogUrl = process.env.VUE_APP_RSS
    } catch (error) {
      console.log(error);
    }
  },
  components: {
    TheFavoriteContactItem,
    TheChangeProfilePicture,
  },
};
</script>
<style>
.rss .rss__article .el-card__body {
  height: 100% !important;
  padding: 0;
}
.home .content-header .el-card {
  height: 130px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home__left-header-section .picture{
  margin: 0;
}
.home .favorite-item .el-card{
  height: 130px !important;
}
</style>

<style scoped>
.rss .rss__article-tags-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  position: absolute;
  top: 20px;
  left: 20px;
  opacity: 0;
  transition: opacity ease-in 0.2ms;
}
.rss .rss__article-tags-container span {
  padding: 5px;
  background-color: var(--green);
  border-radius: 5px;
  color: var(--white);
}
.rss .rss__description-container {
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1;
}
.rss .rss__description-container--full {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70%;
}
.rss .rss__image-article-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
}
.rss .rss__article:hover .rss__image-article-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}
.rss .rss__article:hover .rss__article-tags-container {
  opacity: 1;
}
.rss .rss__article-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
}
.rss__news-title {
  display: flex;
  gap: 20px;
  align-items: baseline;
}
.rss .rss__news-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.rss .rss__news-btn {
  color: var(--white);
}
.content .rss__article-image-container {
  display: flex;
  justify-content: center;
  position: relative;
}
.content .rss__image-article {
  width: 100%;
  height: auto;
}
.rss {
  margin-top: 30px;
}
.rss .content {
  display: grid;
  grid-template-columns: repeat(2, minmax(320px, 1fr));
  grid-template-rows: repeat(2, minmax(390px, 1fr));
  column-gap: var(--column);
  row-gap: var(--column);
  margin-bottom: var(--column);
}
.home {
  position: relative;
}
.rss .rss__news-letter-form-container {
  position: absolute;
  top: 50vh;
  z-index: 100;
  width: 50vw;
}
.home .home__header-titles .favorite-contacts-title {
  display: flex;
  justify-content: flex-start;
  width: 50%;
}
.home .home__header-titles {
  display: flex;
  justify-content: space-between;
}
.content-header-container h3,
h2 {
  font-size: 2rem;
}
.content-header {
  display: grid;
  grid-template-columns: repeat(2, minmax(136px, 1fr));
  column-gap: var(--column);
  row-gap: var(--column);
  box-shadow: 0 10px 10px 0 #e0e0e0;
  padding: 20px;
  border-radius: 10px;
}
/* .content-header .el-card {
  height: 20vh;
} */
.content-header-container {
  display: grid;
  grid-template-columns: 0.25fr 1fr;
  gap: 40px;
  overflow: hidden;
  padding-bottom: var(--column);
}
.favorites-contacts-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  padding-left: 10px;
  padding-right: 3px;
}
.favorite-item {
  display: inline-block;
  white-space: normal;
}
.home .message-section-text {
  font-size: 10px;
}
.inline-flex {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-basis: 120px;
  flex-wrap: wrap;
}

.middle {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.label {
  color: #5a5b5b;
  font-weight: bold;
  margin-right: calc(var(--column) / 2);
}

/* .margin {
  margin-bottom: var(--column)
} */
.voicemail-count {
  background-color: var(--green);
  border-radius: 50px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -5px;
  right: 10px;
}
.news-updates {
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
}

.rss .rss__description-container .summary {
  color: rgb(117, 117, 117);
  display: -webkit-box;
  font-size: 15px;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  font-weight: 400;
  line-height: 24px;
  overflow-wrap: break-word;
  text-size-adjust: 100%;
  word-break: break-word;
  -webkit-box-direction: normal;
  overflow: hidden;
  height: 50px;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.favorites-contacts {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  box-shadow: 0 10px 10px 0 #e0e0e0;
  padding: 20px;
  border-radius: 10px;
  height: 170px;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 20px;
}
.favorites-contacts-long {
  width: 100%;
}
.favorites-contact-items-container{
  overflow-x: auto;
  overflow-y: hidden;
  gap: var(--column);
  display: flex;
}
.favorites-contacts::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.favorites-contacts::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.favorites-contacts::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.favorites-contacts::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.favorites-contacts-title-container {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.zohoModal {
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: center;
}
.zohoModal p {
  word-break: normal;
  text-align: center;
}

.zohoModal img {
  width: 100px;
}

.zohoModal .success {
  width: 200px;
}

.rss .rss__description-container h4 {
  margin-bottom: 10px;
}

.rss .rss__description-container p {
  margin-top: 5px;
}

.rss .rss__image-article-container {
  position: relative;
}

.rss .rss__news-title h3{
  margin: 0;
}


@media only screen and (max-width: 575px) {
  .content {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1025px) {
  .favorites-contacts-title-container {
    padding-left: 0;
  }
  .favorites-contacts {
    max-width: 49vw;
  }
}
</style>
