import reportapi from '@/api/reports'

const state = {
    percentageOfUserLoggedByOrganization : []
}

const getters = {
    getPercentegeOfUserLoggedByOrganization : (state) => state.percentageOfUserLoggedByOrganization
}

const mutations = {
    SET_PERCENTAGE_OF_USER_LOGGED_BY_ORGANIZATION : (state, value) => (state.percentageOfUserLoggedByOrganization = value)
}

const actions = {
    async fetchPercentegeOfUserLoggedByOrganization({commit}, params) {
        try {
            const {data} = await reportapi.getPercentageOfUserLogged(params)
            if (!params.csv) {
                commit('SET_PERCENTAGE_OF_USER_LOGGED_BY_ORGANIZATION', data)
            } else {
                const filename = new Date().getTime()
                const encodedUri = 'data:text/csv,' + encodeURI(data);
                const link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", filename + ".csv");
                document.body.appendChild(link);
                link.click();
            }
        } catch (error) {
            console.log(error)
        }
    }
} 

export default {
    namespaced : true,
    state,
    getters,
    mutations,
    actions
}