<template>
    <div></div>
</template>

<script>

import { mapActions } from 'vuex';
export default {
    name : "Messages",
    methods : {
        ...mapActions(['cleanOpenedChats'])
    },
    created() {
        this.cleanOpenedChats();
    }
}
</script>

<style>

</style>