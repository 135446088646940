<template>
    <div class="side-image">
        <el-card :body-style="{ padding: '0px' }" :class="{ 'active' : isActive }">
          <el-image style="width: 200px; height: 200px" :src="src" fit="cover" :preview-src-list="srcList" @error="error"></el-image>
          <div style="padding: 14px;">
            <span>{{ image.name }}</span>
            <el-form label-width="120px" ref="form">
                <el-form-item :label="$t('general-configurations.side-images.active')">
                    <el-switch v-model="active" @change="change" :disabled="disabled"></el-switch>
                </el-form-item>
            </el-form>
            <el-popconfirm :title="$t('general-configurations.side-images.are-you-sure-to-delete-this')" :confirm="deleteImage" :confirm-button-text="$t('common.yes')" :cancel-button-text="$t('common.no')" @onConfirm="deleteImage">
                <el-button size="mini" class="custom danger" circle icon="fas fa-trash" slot="reference" ></el-button>
            </el-popconfirm>
          </div>
        </el-card>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name : "TheSideImage",
    props : {
        image : {
            required : true,
            default: () => {
                return {
                    id : 0,
                    path : "",
                    name : "",
                    active : 0,
                    fullPath : ""
                }
            }
        }
    },
    data() {
        return {
            active : (parseInt(this.image.active) === 1) ? true : false,
            disabled : false,
        }
    },
    methods :  {
        ...mapActions(['updateSideImage', 'deleteSideImage']),
        change(value) {
            this.$emit('toggleUpdating');
            setTimeout(() => {
                this.updateSideImage({ active : (value) ? 1 : 0, id : this.image.id }).then(() => {
                    this.$emit('toggleUpdating')
                });
            }, 3000);
        },
        deleteImage() {
            this.deleteSideImage(this.image);
        },
        error() {
            this.disabled = true;
        }
    },
    computed : {
        src() {
            return `${process.env.VUE_APP_API_HOST.replace('/api','')}${this.image.fullPath}`;
        },
        isActive() {
            return parseInt(this.image.active) === 1 
        },
        srcList() {
            return [this.src];
        }
    },
    watch : {
        isActive(value) {
            this.active = value;
        }
    }
}
</script>

<style scoped>
.side-image {
    margin-right: var(--column)
}

.side-image .active {
    border: 2px solid var(--blue)!important;
}
</style>