import List from "../../views/users/List.vue";
import Form from "../../views/users/Form.vue";

const routes = [
  {
    name: "user",
    path: "/configuration/users",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.users',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
  },
  {
		name: "usersNew",
		path: "/configuration/users/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    },
	},
  {
    name: "usersEdit",
    path: "/configuration/users/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
];

export default routes;
