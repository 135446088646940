<template>
    <el-tooltip effect="dark" :content="message" placement="bottom" :disabled="type === 'text'">
        <button @click="click" :class="className" class="info" :aria-label="message">
            <i v-if="type === 'icon'" class="fas fa-pause"></i>
            <p v-else><i class="fas fa-pause"></i> {{ message }}</p>
        </button>
    </el-tooltip>
</template>

<script>
export default {
    name: "HoldButton",
    props : {
        janus : {
            required : true
        },
        type : {
            default : 'icon',
            required : false,
        }
    },
    data() {
        return {
            hold : false,
        }
    },
    methods : {
       click() {
            if (this.hold) {
                this.janus.unhold() 
            } else {
                this.janus.hold()
            }
            this.hold = !this.hold
        }
    },
    computed : {
        message() {
            return (!this.hold) ? this.$t("call.hold") : this.$t("call.unhold")
        },
        className() {
            return {
                'text' : this.type === 'text',
                'is-holding': this.hold 
            }
        }
    }
}
</script>

<style scoped>
    .is-holding {
        background: var(--blue-grey)!important;
        color: white!important;
    }
</style>