<template>
  <div class="ticket-content" v-loading="loading"  element-loading-text="Buscando ticket">
    <div v-if="!loading && ticket">
      <el-page-header @back="goBack" :content="header" />
      <div class="ticket">
        <div class="threads">
            <div class="threadsContainer" ref="scrollContainer" @scroll="handleScroll" v-loading="loadingEntries">
              <div  v-for="entry in entries" :key="entry.id" class="thread">
                <TheThreadItem :thread="entry" />
              </div>
            </div>
          <div class="ticket-info user" v-if="forms.length > 0">
            <h3>{{ $t("tickets.ticket-details") }}</h3>
            <el-collapse v-model="activeForm"> 
              <el-collapse-item v-for="(form, index) in forms" :key="index" :title="form.name" :name="index">
                <div v-if="form.entry_values" class="items">
                  <div v-for="(field, i) in form.entry_values" :key="i" class="item">
                    <p class="mb-0 label">
                      {{ field.field.label }} 
                      <el-button 
                          class="p-0 button" 
                          type="text" 
                          v-if="field.field.editable" 
                          @click="fieldEdit = { id : form.id,  fields: [{...field.field, value : field.value }] }; showModalEdit = true">
                            {{ $t("common.edit") }}
                        </el-button>
                      </p>
                    <p class="mb-0" v-if="field.field.type === 'choices'">{{getChoice(field)}}</p>
                    <p class="mb-0" v-else>{{ field.value ? field.value : '' }}</p>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <el-dialog
              :before-close="beforeClose"
              :visible.sync="showModalEdit"
              v-loading="updating">
              <the-form v-if="fieldEdit !== null" :form="fieldEdit" ref="formUpdate"></the-form>
              <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="showModalEdit = false">{{ $t("common.cancel") }}</el-button>
                <el-button size="small" type="primary" @click="updateFormField">{{ $t("common.save") }}</el-button>
              </span>
            </el-dialog>
          </div>


          <el-tabs v-model="activeName" class="ticket-tabs">
            <el-tab-pane :label="$t('tickets.reply')" name="response">
              <TheTicketResponseBox :ticket-id="ticket.ticket_id" :ticket-owner="ticket.user" :ticket-number="ticket.number" class="response-box"></TheTicketResponseBox>
              </el-tab-pane>
            <el-tab-pane :label="$t('tickets.internal-notes')" name="internal-note">
              <TheTicketResponseBox :ticket-id="ticket.ticket_id" :ticket-owner="ticket.user" type="N"></TheTicketResponseBox>
            </el-tab-pane>
          </el-tabs>
        </div>
        <TheTicketInfo
            v-if="ticket"
            :ticket-id="ticket.ticket_id"
            :status="ticket.status"
            :priority="ticket.priority"
            :created="ticket.created"
            :department="ticket.department"
            :assigned="ticket.assigned"
            :user="ticket.user"
            :ticket="ticket"
          ></TheTicketInfo>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import TheTicketInfo from '@/components/ticket/TheTicketInfo';
import TheThreadItem from '@/components/ticket/TheThreadItem';
import TheTicketResponseBox from '@/components/ticket/TheTicketResponseBox';
import TheForm from "@/views/mail-center/TheForm"
export default {
  name: "TheTicket",
  props: {
    ticketNumber: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading : true,
      loadingEntries: true,
      activeName : 'response',
      activeForm : 0,
      showModalEdit: false,
      fieldEdit : null,
      updating: false,
      currentEntriesPage: 0,
      currentEventsPage: 0
    }
  },
  components : {
    TheForm,
    TheTicketInfo,
    TheThreadItem,
    TheTicketResponseBox
  },
  computed : {
    header() {
      return `${this.ticket.subject} (# ${this.ticketNumber})`
    },
    user() {
      return (this.ticket.user) ? this.ticket.user : null
    },
    ticket() { 
      return this.$store.getters.getTicketByNumber(this.ticketNumber)
    },
    forms() {
      let forms = this.ticket.forms
      const form = forms.find(form => form.id == 2)
      if (form) {
        if (form.entry_values) {
          form.entry_values = form.entry_values.filter(_field => _field.field.type !== 'priority' && _field.field.type !== 'thread' && _field.field.id != 20) 
        }
        forms = forms.map(_form => _form.id == 2 ? form : _form).filter(form => form.entry_values.length > 0)
      }
      return forms
    },
    entries() {
      const entries = [...this.ticket.thread.entries.data]
      entries.sort((a, b) => new Date(a.created) - new Date(b.created))
      return entries
    }
  },
  methods : {
    ...mapActions(['fetchTicketByNumber','updateFormFields', 'fetchEntriesByTicketId','fetchEventsByTicketId']),
    async searchTicket() {
      let response = await this.fetchTicketByNumber(this.ticketNumber);
      this.currentEntriesPage = response.thread.entries.total_pages;

      await this.fetchEntriesByTicketId({ticketId: this.ticket.ticket_id, page: this.currentEntriesPage, initialize: true});
      this.currentEntriesPage--;
      while(this.ticket.thread.entries.data.length < 10 && this.currentEntriesPage > 0){
        await this.fetchEntriesByTicketId({ticketId: this.ticket.ticket_id, page: this.currentEntriesPage, initialize: false});
        this.currentEntriesPage--;
      }
      this.$forceUpdate();
      this.loading = false;
      this.loadingEntries = false;
    },
    goBack() {
      this.$router.back(1)
    },
    async updateFormField() {
      this.updating = true
      const values = await this.$refs.formUpdate.getFieldsAndValues()
      try {
        await this.updateFormFields({ ticketId : this.ticket.ticket_id, data : { forms : [values] }});
        this.$message({ message: 'Se ha actualizado el ticket', type: "success" });
        this.showModalEdit = false
      } finally {
        this.fieldEdit = null
        this.updating = false;
      }
    },
    beforeClose() {
      this.showModalEdit = false
      this.fieldEdit = null
    },
    getChoice(field) {
      const choice = field.field.configuration.choices.find((choice) => {
        return choice.value == field.value;
      });

      if (choice) {
        return choice.label;
      }

      return "";
    },
    async updateEntries(){
      await this.fetchEntriesByTicketId({ticketId: this.ticket.ticket_id, page: this.currentEntriesPage, initialize: false})
    },
    async updateEvents(){
      await this.fetchEventsByTicketId({ticketId: this.ticket.ticket_id, page: this.currentEventsPage, initialize: false})
    },
    async handleScroll() {
      const scrollContainer = this.$refs.scrollContainer;
      const scrollTop = scrollContainer.scrollTop;
      const scrollHeight = scrollContainer.scrollHeight;

      if (scrollTop === 0 && !this.loadingEntries) {
        if (this.currentEntriesPage > 0) {
          
          const previousScrollHeight = scrollHeight;
          
          this.loadingEntries = true;
          await this.updateEntries();
          this.$forceUpdate();
          
          this.$nextTick(() => {
            const newScrollRef = this.$refs.scrollContainer;
            const newScrollHeight = newScrollRef.scrollHeight;
            const scrollDifference = newScrollHeight - previousScrollHeight;
            newScrollRef.scrollTop = newScrollRef.scrollTop + scrollDifference;
          });

          
          this.currentEntriesPage--;
        }
        this.loadingEntries = false;
      }
    }
  },
  async created() {
    await this.searchTicket();
    this.$nextTick(() => {
      const newScrollRef = this.$refs.scrollContainer;
      newScrollRef.scrollTop = newScrollRef.scrollHeight;
    });
  },
  watch : {
    ticketNumber : 'searchTicket'
  },
  deactivated(){
    this.loading = true;
    this.ticket = null;
    this.history = [];
  },
  activated() {
    this.searchTicket();
  }
};
</script>

<style scoped>

.ticket {
  display: inline-flex;
  width: 100%
}

.ticket > div {
  width: 30%
}

.threads {
  /* background-color: white; */
  flex: 1;
  max-height: 80vh;
}
.threadsContainer{
  
  max-height: 80%;
  overflow-y: auto;
}
.threads .thread {
  margin-bottom: calc(var(--column) / 2);
  
  
}


.threads .thread-event + .response-box {
  border:none;
}

.ticket-content {
  height: 100%;
}

.ticket-tabs .is-top{
  margin-left: 10px!important;
}

.ticket-info {
  background-color: white;
  border-radius: var(--radius);
  padding: var(--column);
}

.ticket-info h3 {
  font-size: 18px;
}

.ticket-info h4 {
  font-size: 16px;
}

.user {
  margin-bottom: var(--column);
}
.item {
  width: 100%;
  padding: calc(var(--column) / 2) var(--column);
}

.items .item:nth-child(odd) {
  background-color: #f9f9f9;
}

.item .label {
  font-weight: bold;
}


.values {
  display: inline-flex;
  align-items: baseline;
}

</style>