<template>
  <div v-loading="saving">
    <h3>{{ $t("tickets.search-user") }}</h3>
    <div v-if="!user">
      <el-select v-model="value" filterable remote reserve-keyword :placeholder="$t('tickets.search-by-name-or-email')"
        :remote-method="remoteMethod" :loading="loading" @change="handleChange"
        width="100%" size="small">
        <el-option v-for="item in options" :key="item.id" :label="`${item.name} (${item.email})`" :value="item.id">
        </el-option>
      </el-select>
      <div class="new-user">
        <h3>{{ $t("tickets.create-user") }}</h3>
        <el-form size="small">
          <el-form-item :label="$t('tickets.name')" required>
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item :label="$t('tickets.email')" required>
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item :label="$t('tickets.phone')">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('tickets.notes')">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="form.notes">
            </el-input>
          </el-form-item>
          <el-button @click="createUser" :disabled="!form.name || !form.email" type="primary">{{ $t("common.save") }}</el-button>
        </el-form>
      </div>
    </div>
    <div v-else>
      <p>{{ user.name }}</p>
      <p>{{ user.email }}</p>
      <el-button @click="reset">{{ $t("common.cancel") }}</el-button>
      <el-button @click="selectedUser" type="primary">{{ $t("common.select") }}</el-button>
    </div>
  </div>
</template>

<script>
import ticketapi from "@/api/tickets";
export default {
  data() {
    return {
      options: [],
      loading: false,
      value: "",
      form: {
        name: "",
        email: "",
        phone: "",
        notes: "",
      },
      saving: false,
      user: null,
    }
  },
  methods: {
    async remoteMethod(query) {
      this.loading = true;
      try {
        const { data } = await ticketapi.fetchUsers({ query, paginated: false });
        this.options = data.data;
      } catch {
        console.log(1)
      } finally {
        this.loading = false;
      }
    },
    async createUser() {
      this.saving = true;
      try {
        const { data } = await ticketapi.createUser({
          name: this.form.name,
          email: this.form.email,
        });
        this.user = data.data;
      } catch {
        console.log(1)
      } finally {
        this.saving = false;
      }
    },
    reset() {
      this.options = []
      this.loading = false
      this.value = ""
      this.form = {
        name: "",
        email: "",
        phone: "",
        notes: "",
      }
      this.saving = false
      this.user = null
    },
    handleChange() {
      this.user = this.options.find(user => user.id == this.value)
    },
    selectedUser() {
      this.$emit("selectedUser", this.user)
    }
  }
}
</script>

<style scoped>
.new-user {
  margin-top: var(--column);
}
</style>