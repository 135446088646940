<template>
  <div class="filters">
    <div class="tags" v-if="filteredValues.length > 0 || otherAppliedFilters.length > 0">
      <el-tag v-for="filter in otherAppliedFilters" :key="filter.name" type="info" class="filter-tag" @close="handleRemoveAdvanceFilter(filter)" closable>{{ filter.label }}: {{ filter.value }}</el-tag>
      <el-tag v-for="filter in filteredValues.filter(f => Array.isArray(f.value) && f.value.length > 0 || !Array.isArray(f.value) && f.value)" :key="filter.index" type="info" class="filter-tag" closable
        @close="removeFilter(filter)" @click.native="showTheFilter(filter)">{{ getFilterText(filter) }}</el-tag>
    </div>
    <div class="add-filter">
      <el-dropdown :hide-on-click="false" trigger="click" placement="bottom-start" ref="dropdown" @visible-change="handleVisibleChange">
        <el-button class="button-filter" size="small">{{ $t('common.add-filter') }} <i
            class="el-icon-arrow-down el-icon--right"></i></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-popover placement="right-start" trigger="manual" :visible-arrow="false" v-model="showFilterForm">
            <div label-width="300px" v-if="selectedFilter" @click="showDropdown" size="small" label-position="top"
              class="d-inline-flex align-items-baseline">
              <div>
                <el-radio-group v-model="filterSearch" v-if="selectedFilter.type === 'text'">
                  <div v-for="(option, index) in selectedFilter.options" :key="index">
                    <el-radio :label="option">{{ option }}</el-radio>
                    <el-input size="small" v-model="filterValues[filterIndex]" v-show="option === filterSearch"
                      @keydown.enter.native="showFilterForm = false; submit()"></el-input>
                  </div>
                </el-radio-group>
                <div v-if="selectedFilter.type === 'boolean'">
                  <el-switch v-model="filterValues[filterIndex]" :active-text="$t('common.yes')"
                    :inactive-text="$t('common.no')"></el-switch>
                </div>
                <div v-if="selectedFilter.type === 'select'">
                  <div style="margin-bottom:0" label="">
                    <el-select v-model="filterValues[filterIndex]" :multiple="selectedFilter.multiple"
                      placeholder="Select" @click.native="showDropdown()" filterable>
                      <el-option v-for="item in sortOptions(selectedFilter.options)" :key="item.value" :label="item.label"
                        :value="item.value" @click.native="showDropdown(item)">
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
              <div class="filter-buttons">
                <el-button size="small" type="primary" @click="showFilterForm = false; submit()">{{
                  $t("common.apply-filter") }}</el-button>
                <el-button size="small" type="info" @click="showFilterForm = false;">{{ $t("common.cancel") }}</el-button>
              </div>
            </div>
            <el-dropdown-item v-for="(filter, index) in filters" :key="index"
            @click.native="handleSelectedFilter(filter.index)" slot="reference"
            :class="{'selected' : filterIndex === filter.index}">{{ filter.label }}</el-dropdown-item>
        </el-popover>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</div>
</template>

<script>
export default {
  props: {
    filters : {
      required : false,
      default : () => ([])
    },
    otherAppliedFilters: {
      required: false,
      default: () => ([])
    }
  },
  data() {
    return {
      searchValue : '',
      filterIndex : '',
      showFilterForm : false,
      filteredValues : [],
      filterOptions : {},
      filterValues : {},
      filterSearch : 'is',
      page: 1,
      rowsShown: 30,
    }
  },
  methods: {
    handleVisibleChange(value) {
      if (!value) {
        this.filterIndex = ''
        this.showFilterForm = false;
      }
    },
    handleSelectedFilter(index) {
      this.filterIndex = index
      this.showFilterForm = true
      if ('options' in this.selectedFilter) {
        this.filterOptions[this.filterIndex] = this.selectedFilter.options[0];
        this.filterSearch = this.selectedFilter.options[0]
      }
    },
    showDropdown() {
      this.$refs.dropdown.show()
    },
    showTheFilter(filter) {
      this.$refs.dropdown.show();
      setTimeout(() => {
        this.handleSelectedFilter(filter.index)
      }, 200)
    },
    getFilterText(filter) {
      let select = filter.multiple ? filter.value.join(',') : filter.value 
      const text = filter.label;

      if (filter.showLabel) {
        if (filter.multiple) {
          select = filter.value.map((value) => {
            const option = filter.options.find(opt => opt.value === value)
            return option.label
          }).join(',')
        } else {
          const option = filter.options.find(opt => parseInt(opt.value) === parseInt(filter.value))
          select = option.label
        }
      }

      const values = {
        text : `${filter.match} ${filter.value}`,
        boolean: filter.value === 1 || filter.value === 'true' || filter.value === 'yes' ? 'Si' : 'No',
        select: select
      }

      return `${text}: ${values[filter.type]}`
    },
    removeFilter(filter) {
      this.filteredValues = this.filteredValues.filter(item => item.index !== filter.index)
      delete this.filterValues[filter.index]
      this.$emit('removeFilter', filter)
      this.submit()
    },
    submit() {
      const params = this.makeParams();
      this.$emit('submit', params)
    },
    handleRemoveAdvanceFilter(filter) {
      this.$emit('removeAdvanceFilter', filter)
    },
    makeParams() {
      const params = {}

      if (this.search) {
        if (this.search instanceof Array) {
          this.search.forEach(index => {
            if (this.searchValue) {
              if (typeof index === 'string') {
                params[index] = this.searchValue
              } else {
                params[index.index] = this.searchValue
              }
            }
          })
        } else {
          if (this.searchValue) {
            params[this.search.index] = this.searchValue
          }
        }
      }

      if (Object.keys(this.filterValues).length > 0) {
        Object.keys(this.filterValues).forEach(index => {
          const filter = this.filters.find(filter => filter.index === index)
          let value = this.filterValues[index]
          if (filter) {
            if (filter.type === 'boolean') {
              if (filter.booleanType === 'numeric') {
                  value = this.filterValues[index] ? 1 : 0
              } else if (filter.booleanType === 'yes|no') {
                value = this.filterValues ? 'yes' : 'no'
              } else {
                value = this.filterValues[index] ? "true" : "false"
              }
            }
          }
          const filteredIndex = this.filteredValues.findIndex(filter => filter.index === index)
          if (filteredIndex < 0) {
            this.filteredValues.push({
              ...filter, 
              value,
              match : this.filterOptions[index]
            })
          } else {
            this.filteredValues[filteredIndex].value = value;
          }
          params[index] = value
        })
      }
      return params;
    },
    sortOptions(options) {
      const arr = [...options ]
      return arr.sort((a,b) => {
        const labelA = a.label.toUpperCase() 
        const labelB = b.label.toUpperCase()

        if (labelA < labelB) {
          return -1
        }

        if (labelA > labelB) {
          return 1
        }

        return 0
      })
    }
  },
  computed: {
    selectedFilter() {
      return this.filters.find(filter => filter.index === this.filterIndex)
    }
  },
  watch: {
    filterSearch(value) {
      this.filterOptions[this.filterIndex] = value;
    },
    showFilterForm(value) {
      if (!value) {
        setTimeout(() => {
          this.$refs.dropdown.hide()
        }, 100)
      }
    },
  },
  created() {
    Object.entries(this.$route.query).map(([index, value]) => {
      const filter = this.filters.find(filter => filter.index === index)
      
      if (filter) {
        if (filter.type === 'select') {

          if (filter.multiple) {
            value = Array.isArray(value) ? value.map(val => parseInt(val)) : [parseInt(value)]
          }

          const option = filter.options.find(option => parseInt(option.value) === parseInt(value) && !filter.multiple || value.includes(option.value))
          this.filteredValues.push({
            ...filter, 
            value,
            match : option
          })
          this.filterValues[index] = value
        }

        if (filter.type === 'boolean') {
          this.filteredValues.push({
            ...filter, 
            value:  value ? 'yes' : 'no'
          })
        }
      }
    })
  }
}
</script>

<style scoped>
.filters,
.tags {
  display: inline-flex;
  gap: 8px;
}

.filter-buttons {
  display: inline-flex;
  margin-left: var(--column);
}

.filter-tag {
  cursor: pointer
}
.selected {
  background-color: #ecf5ff;
  color: #66b1ff;
}

</style>