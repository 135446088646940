<template>
  <div>
    <h2>{{ $t("user-configuration.notifications.title") }}</h2>
    <div class="notifications">
      <div>
        <p>
          {{
            $t("user-configuration.notifications.enable-desktop-notifications")
          }}
        </p>
        <el-switch
          v-model="notificationsDesktopStatus"
        ></el-switch>
      </div>
    </div>
    <p v-if="notificationStatus === 'denied'">
      {{ $t("user-configuration.notifications.notifications-blocked") }}
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "TheNotificationConfiguration",
  data() {
    return {
      notificationStatus: Notification.permission,
      notificationsDesktopStatus: this.$store.getters.getDesktopNotificationPermission && this.$store.getters.getShowNotifications,
    };
  },
  methods: {
    ...mapActions(["geTokenAndSaveIt", "removeToken", "setSettingValue"]),
    async updateNotificationDesktop() {
      try {
        const permission = await Notification.requestPermission()
        if (permission === "granted" && this.notificationsDesktopStatus) {
          this.geTokenAndSaveIt()
          this.setSettingValue({ setting: 'ALLOW_NOTIFICATIONS', value: true })
        } else {
          this.removeToken()
          this.setSettingValue({ setting: 'ALLOW_NOTIFICATIONS', value: false })
        }
      } catch {
        this.removeToken()
      }
    },
  },
  watch:{
    notificationsDesktopStatus: function() {
      this.updateNotificationDesktop()
    }
  }
};
</script>

<style scoped>
.notifications > div {
  display: inline-flex;
  justify-content: space-between;
  margin-top: var(--column);
}

.notifications > div p {
  margin-right: var(--column);
}
</style>