<template>
  <div :class="{ 'edit' : edit, 'normal' : !edit }"> 
    <header>
      <h2>{{ $t("softphone.call-history.title") }}</h2>
      <el-button type='text' :icon="!edit ? 'fas fa-edit' : ''" @click.native="handleButton">{{ edit ? $t("softphone.call-history.empty") : '' }}</el-button>
    </header>
    <div v-if="callHistory.length > 0" >
      <the-search-input v-model="search" :placeholder="$t('common.search')"></the-search-input>
      <div class="buttons">
        <el-button size="small" round :class="{ 'active' : filter === 'all' }" @click.native="setFilter('all')">{{ $t("softphone.call-history.all") }}</el-button>
        <el-button size="small" round :class="{ 'active' : filter === 'lost' }" @click.native="setFilter('lost')">{{ $t("softphone.call-history.lost") }}</el-button>
      </div>
      <p v-if="history.length === 0 && search !== ''" class="text-center mt-5">{{ $t("softphone.call-history.empty-results") }}</p>
      <p v-if="history.length === 0 && search === ''" class="text-center mt-5">{{ $t("softphone.call-history.empty-calls") }}</p>
      <ul>
        <el-checkbox-group v-model="checkList">
          <li>
            <el-checkbox v-for="(call, index) in history" :key="index" :label="call.id" :disabled="!edit" class="checkbox">
              <the-call-history-item :call="call"></the-call-history-item>
            </el-checkbox>
          </li>
        </el-checkbox-group>
      </ul>
    </div>
    <div v-else class="information center">
      <i class="fas fa-history"></i>
      <p>{{ $t("keypad.here-will-appear-the-calls") }}</p>
    </div>
  </div>
</template>

<script>
import TheCallHistoryItem from './TheCallHistoryItem'
import TheSearchInput from '@/components/html/TheSearchInput'
import { mapGetters, mapActions } from 'vuex'
export default {
  props : {
    from : {
      required : true,
      type : String
    }
  },
  data() {
    return {
      search : '',
      filter : 'all',
      checkList : [],
      edit : false,
    }
  },
  components : {
    TheCallHistoryItem,
    TheSearchInput
  },
  methods : {
    ...mapActions(['toggleChecked', 'deleteCallHistory']),
    setFilter(filter) {
      this.filter = filter
    },
    handleButton() {
      if (!this.edit) {
        this.edit = true;
      } else {
        if (this.checkList.length === 0) {
          this.edit = false;
        } else {
          this.delete()
        }
      } 
    },
    async delete() {
      this.checkList.map(id => this.deleteCallHistory({ from : this.from, id }))
    }
  },
  computed : {
    ...mapGetters({
      user : 'getUser',
      callHistory : 'getCallHistory'
    }),
    history() {
      return this.callHistory(this.from)
      .map(call => {
        call.checked = ('checked' in call) ? call.checked : false
        return call;
      })
      .filter(call => !this.search || call.displayname.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
      .filter(call => this.filter === 'all' || this.filter === call.type)
    }
  },
}
</script>

<style scoped>
  h2 {
    font-size: 20px;
  }

  ul {
    list-style: none;
    height: 65vh;
    display: block;
    overflow-y: auto;
    margin-top: var(--column)
  }

  li {
    margin-bottom: var(--column)
  }

  .buttons {
    display: inline-flex;
    width: 100%;
    margin-top: var(--column)
  }

  .buttons button {
    width: 48%;
  }

  .el-button.active {
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
  }

  li > label {
    width: 100%;
  }

  li > label :deep(.el-checkbox__label)  {
    width: 90%
  }

  header {
    display:inline-flex;
    width:100%;
    justify-content:space-between;
    align-items: baseline;
  }

  .normal :deep(.el-checkbox__inner)  {
    display: none;
  }

  .normal >>> .el-checkbox__input.is-disabled+span.el-checkbox__label {
    color: inherit;
    cursor: context-menu;
  }

  .checkbox {
    margin-right: 0;
  }

</style>