<template>
  <button @click="click" class="info" :class="type" :aria-label="message">
    <i v-if="type === 'icon'" class="fas" :class="{ 'fa-microphone': !muted, 'fa-microphone-slash': muted }"></i>
    <p v-else>  <i class="fas" :class="{ 'fa-microphone': !muted, 'fa-microphone-slash': muted }"></i> {{ message }} </p>
  </button>
</template>

<script>
export default {
  name: "MuteButton",
  data() {
    return {
      muted: false,
    };
  },
  props: {
    janus: {
      required: true,
    },
    type : {
      default : 'icon',
      required : false
    }
  },
  methods: {
    click() {
      if (this.muted) {
        this.janus.unmute();
      } else {
        this.janus.mute();
      }
      this.muted = !this.muted;
    },
  },
  computed: {
    message() {
      return this.muted
        ? this.$t("call.turn-on-mic")
        : this.$t("call.turn-off-mic");
    },
  },
};
</script>

<style>
</style>