<template>
	<li
		@click="handleClick"
	>	<div class="item-header">
			<div :class="['item-type', item.type, item.data?.from]">
				<i :class="icon"/>
			</div>
			<div>
				<h3>{{ title }}</h3>
				<p>{{ subtitle  }}</p>
			</div>
			<div>
				<p class="datetime">{{ date | redeableDate(true) }}</p>
				<i class="notification" v-if="notification > 0">{{ notification }}</i>
			</div>
		</div>
		<div class="item-footer">
			<el-tag :type="active ? 'success' : 'danger'" size="mini">{{ status }}</el-tag>
			<TheTickePriorityTag v-if="item.type === 'ticket'" :name="item.cdata.priority_info.priority_desc" :state="item.cdata.priority_info.priority"/>
			<div class="tagsContainer" v-if="item.type === 'chat'">
				<the-tag v-for="tag in item.data.tags" :key="tag.id" :tag="tag"/>
			</div>
			<div class="tagsContainer-disposition" v-if="item.type === 'call' && item.data.call_disposition_saved">
				<i v-for="tag in item.data.call_disposition_saved" :key="tag.id" class="fas fa-tags"></i>
			</div>
			<div class="containerCampains" v-if="item.type === 'call'">
				<div v-if="item.data.type === 'ingoing'" class="containerCampain">
					<InGoingCall class="containerCampain__phone-icon"></InGoingCall>	
					<span>{{ $t('common.ingoing-campaign') }}</span>
				</div>
				<div class="containerCampain" v-if="item.data.type === 'outgoing'">
					<OutGoingCall class="containerCampain__phone-icon"></OutGoingCall>
					<span>{{ $t('common.outgoing-campaign') }}</span>
				</div>
			</div>
			<i v-if="item.type === 'chat' && item.data.chatClassifications && item.data.chatClassifications.length > 0"  class="fas fa-tags"></i>
		</div>
	</li>
</template>

<script>
import { mapActions } from 'vuex'
import TheTag from './TheTag'
import TheTickePriorityTag from "@/components/ticket/TheTickePriorityTag"
import InGoingCall from '../icons/InGoingCall.vue'
import OutGoingCall from '../icons/OutGoingCall.vue'
export default {
	props: {
		item: {
			required: true
		},
		enabledNotification: {
			required: false,
			default: true
		}
	},
	components: {
		TheTag,
		TheTickePriorityTag,
		InGoingCall,
		OutGoingCall
	},
	methods: {
		...mapActions([
			'resetUnreadMesages'
		]),
		handleClick () {
			if (this.item.type === 'chat') {
				this.resetUnreadMesages(this.item.data.roomId)
			}
		}
	},
	computed: {
		icon() {
			if (this.item.type === 'call') {
				return 'fas fa-phone'
			}

			if (this.item.type === 'ticket') {
				return 'fas fa-envelope'
			}
			
			return this.item.data.from === 'web' ? 'fas fa-comment' : `fab fa-${this.item.data.from}`
		},
		title() {
			if (this.item.type === 'call' || this.item.type === 'chat') {
				return this.item.title
			}
			return this.item.user.name
		},
		subtitle() {
			if (this.item.type === 'chat') {
				const { lastMessage } = this.$store.getters['_chats/getChatByRoomId'](this.item.key)
				if (lastMessage) {
					const { version = 0 } = lastMessage
					const messages = {
						video : this.$t("agent-console.message-types.video"),
						audio : this.$t("agent-console.message-types.audio"),
						image : this.$t("agent-console.message-types.image"),
						file : this.$t("agent-console.message-types.file"),
						location: this.$t("agent-console.message-types.location"),
						text: version === 0 ? lastMessage.text : lastMessage.payload.text,
						system: version === 0 ? lastMessage.text : lastMessage.payload.text
					}
					return messages[lastMessage.type]
				} else {
					return ""
				}
			}

			if (this.item.type === 'ticket') {
				return this.item.cdata.subject
			}

			if (this.item.data.status && this.item.data.status === 'not-connected') {
				return this.$t("agent-console.the-call-failed")
			}


			return this.item.active ? this.$t("agent-console.message-types.you-are-in-call") : this.$t("agent-console.message-types.the-call-ended");
		},
		status() {
			if (this.item.type === 'call' || this.item.type === 'chat') {
				return this.item.active ? 'Active' : 'Finish'
			}
			return 'Open'
		},
		active() {
			return this.item.type === 'call' || this.item.type === 'chat' ? this.item.active : true
		},
		date() {
			return this.item.type === 'call' || this.item.type === 'chat' ? this.item.date : new Date(this.item.lastupdate).getTime()
		},
		notification() {
			if (this.item.type === 'chat') {
				return this.item.data.unreadMessages
			}

			return 0
		},
	},
	watch: {
		notification(value) {
			if (value > 0 && this.enabledNotification) {
				if (document.querySelector('#agent-notifications')) {
					document.querySelector('#agent-notifications').play();
				}
			}
		}
	}
}
</script>

<style scoped>
li {
	display: flex;
	flex-direction: column;
	gap: calc(var(--column) / 2);
	cursor: pointer;
	width: 100%;
}

.item-header,
.item-footer {
	display: inline-flex;
	gap: calc(var(--column) / 1.5);
	width: 100%;
}
.item-footer .tagsContainer{
	display: flex;
	gap: 5px;
}
.item-footer{
	align-items: center;
}
.item-type {
	border-radius: 3px;
	color: white;
	display: inline-block;
	height: 32px;
	line-height: 32px;
	text-align: center;
	text-transform: uppercase;
	width: 32px;
	font-size: 14px;
	padding: 0 10px;
}

.item-type.call {
	background-color: var(--blue);
}

.item-type.ticket {
	background-color: var(--orange);
}

.item-type.chat {
	background-color: var(--red);
}

li h3 {
	font-size: 16px
}

h3,
.item-header p {
	margin-bottom: 0;
	display: -webkit-box;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.item-header div:last-child {
	margin-left: auto;;
}

.item-type.chat.web {
	background-color: var(--blue);
}

.item-type.chat.whatsapp {
	background-color: var(--whatsapp-green);
}

.item-type.chat.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
}

.item-type.chat.facebook {
  background-color: #3b5998 
}

.datetime {
	text-align: right;
}

.notification {
	border-radius: 50%;
  background-color: var(--red);
  color: white;
	display: inline-block;
  text-align: center;
  line-height: 20px;
  margin: 0 auto;
  height: 20px;
  width: 20px;
  transition: display 0.5s;
  font-size: 12px;
}
.tagsContainer-disposition{
	display: flex;
	align-items: center;
}
.containerCampain{
	display: flex;
	align-items: center;
	gap: 5px;
}
.containerCampain__phone-icon{
	width: 15px;
	height: 15px;
}
</style>
