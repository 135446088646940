<template>
  <el-tooltip effect="dark" :content="$t('call.hangup')" placement="bottom" :disabled="type === 'text'">
    <button @click="hangup" class="info" :class="type" :aria-label="$t('call.hangup')">
        <i class="fas fa-phone" v-if="type === 'icon'"></i>
        <p v-else class="mb-0"> <i class="fas fa-phone"></i> {{ $t('call.hangup') }}</p>
    </button>
  </el-tooltip>
</template>

<script>
export default {
  name : "HangupButton",
  props : {
    janus : {
        required : true
    },
    type : {
      default : 'icon',
      required : false,
    }
  },
  methods : {
    hangup() {
        this.janus.hangup()
    },
  }
}
</script>

<style scoped>
button {
  background-color: var(--red)!important;
  color: white!important;
}


button:hover {
  background-color: #d8525a!important;
  color: white!important;
}


button.text {
  border-radius: 0;
  background-color: #d8525a!important;
  color: white!important;
  text-align: center;
  width: 100%;
}

</style>