import List from "@/views/servers/osticket/List.vue"
import Form from '@/views/servers/osticket/Form.vue'

const routes = [
  {
    name: "ostickets",
    path: "/configuration/servers/ostickets",
    component: List,
    meta : {
      isInMenu : true,
      title : 'main-menu.configuration.servers.ostickets',
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    }
  },
  {
	name: "osticketsNew",
	path: "/configuration/servers/ostickets/new",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    }
  },
  {
    name: "osticketsEdit",
    path: "/configuration/servers/ostickets/:id/edit",
    component: Form,
    meta : {
      access_control : ['ROLE_ADMIN'],
      requiresAuth : true
    },
    props: true,
  },
];

export default routes;
