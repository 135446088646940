<template>
  <el-tag :type="type" size="mini" effect="dark">{{ name }} ({{ state }})</el-tag>
</template>

<script>
export default {
  name: "TheTickePriorityTag",
  props: {
    name: {
      required: true,
      type: String,
    },
    state: {
      required: true,
      type: String,
    },
  },
  computed: {
    type() {
      let type = "low";
      switch (this.state) {
        case 'closed':
          type = "success";
        break;
        case "normal":
          type = "";
          break;
        case "high":
          type = "warning";
          break;
        case "emergency":
          type = "danger";
          break;
      }
      return type;
    },
  },
};
</script>