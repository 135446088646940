<template>
    <div class="preview">
        <i class="fas fa-times" @click="removeFile"></i>
        <div class="image" v-if="file.type === 'image'" :style="{ 'background-image': 'url(' + file.data + ')' }"></div>
        <i class="file fas fa-file" v-if="file.type === 'file' || file.type === 'application'"></i>
        <i class="file fas fa-file-audio" v-if="file.type === 'audio'"></i>
        <i class="file fas fa-file-video" v-if="file.type === 'video'"></i>
        <el-tooltip class="item" effect="dark" :content="file.name" placement="bottom">
            <p class="mb-0 name" v-if="file.type !== 'image'">{{ file.name }}</p>
        </el-tooltip>
        <i class="fas fa-circle-notch fa-spin" v-if="file.uploading"></i>
        <div class="progress-bar" v-if="file.uploading">
            <div class="bar" :style="{ 'width': file.uploadedSize }"></div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'ThePreviewFile',
    props : {
        file : {
            required : true,
        }
    },
    methods : {
        removeFile() {
            this.$emit('removeFile')
        }
    }
}
</script>

<style scoped>
    .file {
        color: var(--blue);
        font-size: 3em;
    }

    .name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

       .preview {
        background: #f3f2f2;
        border-radius: var(--radius);
        position: relative;
        padding: var(--column);
        text-align: center;
        height: 100px;
        width: 100px;
        margin-right: var(--column);
        margin-bottom: var(--column);
    }

    .preview .fa-times {
        color: white;
        background: var(--red);
        border-radius: 50%;
        height: 20px;
        width: 20px;
        text-align: center;
        line-height: 20px;
        position: absolute;
        top: -10px;
        right: -10px;
    }
    
    .preview .fa-file {
        font-size: 3em;
    }

    .preview.uploading::before {
        content: " ";
        background-color: hsla(0, 0%, 75%, 0.7);
        display: block;
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        left: 0;
    }

    .preview.uploading .fa-circle-notch {
        color: #607d8b;
        position: absolute;
        top: 40px;
        left: 40px;
    }

    .preview .image  {
        background-color: var(--panel-background-deeper);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 70px;
        width: 70px;
    }

    .preview .progress-bar {
        background: white;
        bottom: 10%;
        height: 5px;
        position: absolute;
        left: 10%;
        width: 80%;
    }

    .progress-bar .bar {
        height: 5px;
        background: var(--blue);
    }
</style>