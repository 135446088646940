const state = {
    janus : null,
    registered : false,
    reloadJanus : false,
    voicemail : 0,
    isListeningVoicemail : false,
    isMainWindow : true,
    isInCall : false,
    isJanusReady : false,
    inCallWith: null,
    janusConfig: null,
};

const getters = {
    getJanusConfig: (state) => state.janusConfig,
    getJanus : (state) => state.janus,
    isRegistered : (state) => state.registered,
    reloadJanus : (state) => state.reloadJanus,
    getVoicemail : (state) => state.voicemail,
    isListeningVoicemail : (state) => state.isListeningVoicemail,
    getIsMainWindow : (state) => state.isMainWindow,
    getCorpoIsInCall : (state) => state.isInCall,
    isJanusReady : (state) => state.isJanusReady,
    getIsInCallWith: (state) => state.inCallWith,
}

const mutations = {
    SET_JANUS_CONFIG: (state, config) => (state.janusConfig = config),
    SET_JANUS : (state, janus) => (state.janus = janus),
    SET_REGISTERED: (state, value) => (state.registered = value),
    RELOAD_JANUS : (state, value) => (state.reloadJanus = value),
    SET_VOICEMAIL : (state, value) => (state.voicemail = value),
    SET_LISTENING_VOICEMAIL : (state, value) => (state.isListeningVoicemail = value),
    SET_IS_MAIN_WINDOW : (state, value) => (state.isMainWindow = value),
    SET_IS_IN_CALL : (state, value) => (state.isInCall = value),
    SET_IS_JANUS_READY : (state, value) => (state.isJanusReady = value),
    SET_IS_IN_CALL_WITH: (state, value) => (state.inCallWith = value),
}

const actions = {   
    saveJanus({ commit }, janus) {
        commit('SET_JANUS', janus)
    },
    updateRegistered({ commit }, value) {
        commit('SET_REGISTERED', value)
    },
    reloadJanus({ commit }, value) {
        commit('RELOAD_JANUS', value)
    },
    setVoicemail({commit}, value) {
        commit('SET_VOICEMAIL', value)
    },
    setIsListeningVoicemail({commit}, value) {
        commit('SET_LISTENING_VOICEMAIL', value)
    },
    setIsMainWindow({ commit }, value) {
        commit('SET_IS_MAIN_WINDOW', value)
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}