<template>
  <el-dialog :title="title" width="45%" :visible.sync="show" :before-close="handleClose">
    <el-carousel arrow="always" :autoplay="false" height="320px">
      <el-carousel-item v-for="(step, index) in stepts" :key="index">
        <h3 v-if="step.title">{{ step.title }}</h3>
        <p v-html="step.description"></p>
        <img v-if="step.image" :src="step.image" :width="step.width" :height="step.height ? step.height : 'auto'" class="gif" :class="{'fit' : step.fit}"/>
      </el-carousel-item>
    </el-carousel>
    <span slot="footer" class="dialog-footer">
      <el-checkbox v-model="checked">{{ $t("tours.common.dont-show-again") }}</el-checkbox>
    </span>
  </el-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "TheModalTour",
  props: {
    title: {
      required: true,
      type: String,
    },
    tour : {
      required : true,
      type : String
    }
  },
  data() {
    return {
      show : false,
      checked : false
    }
  },
  methods : {
    ...mapActions('tours', {
      updateTour : 'updateTour'
    }),
    translated(value) {
      return this.$t(value)
    },
    handleClose(done) {
      if (this.checked) {
        this.updateTour({ tour : this.tour, value : false })
      }
      done()
    },
  },
  computed : {
    ...mapGetters('tours', ['getTour']),
    _tour() {
      return this.getTour(this.tour)
    },
    stepts() {
      return this._tour.stepts
    },
    active() {
      return this._tour.active
    }
  },
  watch : {
    active(value) {
      this.show = value
    }
  },
  mounted() {
    this.show = this.active
  }
}
</script>

<style>
.el-carousel__button {
  background-color: var(--dark-blue)!important
}

.gif {
  display: block;
  margin: 0 auto;
}

.gif.fit {
  height: fit-content;
  width: fit-content;
}
</style>