const state = {
  recentlyTransferred: [],
  active : 'keypad',
  calling:  false,
}

const getters = {
  getRecentlyTransferred : (state) => state.recentlyTransferred,
  getActive : (state) => state.active,
  getCalling: (state) => state.calling,
}

const mutations = {
  ADD_TO_RECENTLY_TRANSFERED : (state, value) => state.recentlyTransferred.push(value),
  SET_ACTIVE : (state, value) => state.active = value,
  SET_CALLING: (state, value) => state.calling = value,
}

const actions = {
  addToRecentrlyTransfer({ commit }, value) {
    commit('ADD_TO_RECENTLY_TRANSFERED', value)
  }
}

export default {
  namespaced : true,
  state,
  getters,
  mutations,
  actions
}