<template>
  <div class="recover">
    <div v-if="!sended" v-loading="loading">
      <h1 class="logo">HiperPBX</h1>
      <p class="mb-0">{{ $t("recover-password.enter-the-email-of-your-account-and-we-will-send-you-an-email-with-the-instructions-to-recover-your-password") }}</p>
      <el-form :model="form" size="medium" class="form" label-width="100%" label-position="top">
        <el-form-item prop="email" label="Email" :rules="[
          { required: true, message: $t('recover-password.please-input-email-address'), trigger: 'blur' },
          { type: 'email', message: $t('recover-password.please-input-correct-email-address'), trigger: ['blur', 'change'] }
        ]">
          <el-input v-model="form.email"></el-input>
        </el-form-item>
        <el-button type="primary" class="mb-0" :disabled="!form.email" @click="sendInstructions">{{ $t('recover-password.send-instructions') }}</el-button>
      </el-form>
    </div>
    <div v-else>
      <div v-if="success">
        <h1 class="text-center">{{ $t('recover-password.check-your-email') }}</h1>
        <Success class="svg mb-2"></Success>
        <p class="text-center">{{ $t('recover-password.we-have-sent-to-your-email-the-instructions-to-be-able-to-recover-your-password') }}</p>
        <p class="text-center">{{ $t('common.click') }} <router-link :to="{ name : 'Login' }">{{ $t("common.here") }}</router-link> {{ $t("recover-password.to-return-to-the-login-screen") }}</p>
      </div>
      <div v-else>
        <Error class="svg"></Error>
        <p class="text-center">{{ $t("recover-password.we-have-not-found-the-email", { email : this.form.email }) }}</p>
        <p class="text-center">{{ $t('common.click') }} <a href="#" @click="sended = false">{{ $t("common.here") }}</a> {{ $t("recover-password.to-try-another-email") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/api/auth'
import Error from '@/components/icons/Error'
import Success from '@/components/icons/Success'
export default {
  data() {
    return {
      form : {
        email : ''
      },
      loading : false,
      sended : false,
      success : false
    }
  },
  methods : {
    sendInstructions() {
      this.loading = true
      auth.forgotPassword(this.form.email)
      .then(() => {
        this.success = true
      }).finally(() => {
        this.loading = false
        this.sended = true;
      })
    }
  },
  components : {
    Error, 
    Success
  }
}
</script>

<style scoped>
.recover {
  align-items: center;
  display: flex;
  justify-content: space-around;
  height: 100vh;
  width: 100%;
}

.recover > div {
  background-color: white;
  width: 40%;
  padding: var(--column)
}

.svg {
  margin: 0 auto
}

h1.logo {
  background: url('/images/logo.png') no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
  text-indent: -50000px;
  width: 100%;
}


@media (max-width: 1024px) {
  .recover > div {
    width: 90%;
  }

  .form > div,
  .form button {
    width: 100%;
  }
}
</style>