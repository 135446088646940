<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :can-delete="true" :goBack="goBack" :init-query-params="{
        ['organization.id'] : organizationId
      }"></the-crud>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'

export default {
  data() {
    return {
      title: this.$t("contact-me.chat-center.bots"),
      store: "bots",
      columns: [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('configuration.organization.name') },
        { index : "type", label: this.$t('contact-me.chat-center.robot-type')},
      ]
    }
  },
  components: {
    TheCrud
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-chat-center"})
    }
  },
  computed: {
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  }
}
</script>