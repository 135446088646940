import http from './http';


export default {
	ping() {
		return http.get(`/ping`);
	},
	fetchUserData() {
		return http.get(`/user/data`);
	},
	fetchUsers(queryParams) {
		queryParams.isActive = ('isActive' in queryParams) ? queryParams.isActive : 1;
		return http.get(`/users`, { params : queryParams });
	},
	fetchUser(id) {
		return http.get(`/users/` + id);
	},
	createUser(data) {
		return http.post(`/users`, data);
	},
	updateUser(data) {
		return http.put(`/users/` + data.id, data);
	},
	deleteUser(id) {
		return http.delete(`/users/` + id);
	},
	changePassword(data) {
		return http.put(`change-password`, data);
	},
	changeUserPassword(id, password) {
		return http.put(`user/${id}/change-password`, {password})
	},
	changeProfilePicture(data) {
		return http.put(`change-profile-picture`, data);
	},
	fetchUsersWithoutAgents(value) {
		return http.get(`/users-without-agent?query=${value}`);
	},
	toggleUserActive(id, active) {
		const data = { isActive : active }
		return http.put(`/users/${id}/active`, data);
	},
	deleteChatsFromUser(id) {
		return http.delete(`/user/${id}/chats`);
	},
	deleteProfilePictureFromUser(id) {
		return http.delete(`/user/${id}/delete-profile-picture`)
	},
	changeLanguague(language) {
		return http.put(`/change-language`, { language });
	},
	customToken() {
		return http.get(`/custom-token`);
	}
};
