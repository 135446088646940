<template>
  <div>
    <el-input v-model="number" class="number size" v-if="!onlyNumpad" ref="search" @keydown.enter.native="call" @keypress.native="handlePress"></el-input>
    <div class="keypad size">
      <the-button v-for="(button,i) in buttons" :key="i" :value="button.value" :text="button.text" :class="{ 'active' : button.active }" @click.native="onClick(button)"></the-button>  
    </div>
    <phone-button :janus="janus" :extension="number" :displayname="number" v-if="!onlyNumpad" class="phone-button" @click.native="recall"></phone-button>
  </div>
</template>

<script>
const AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext;
import Tone from "@/plugins/tone";
import PhoneButton from '@/components/webrtc/PhoneButton'
import TheButton from './TheButton'
import { mapGetters } from 'vuex'
export default {
  props : {
    onlyNumpad : {
      required : false,
      default : false
    }
  },
  data() {
    return {
      number : '',
      dtmf: new Tone(new AudioContext(), 350, 440),
      buttons : [
        {value: "1", active : false, f1: 697, f2: 1209 },
        {value: "2", text: 'abc', active : false, f1: 697, f2: 1336 },
        {value: "3", text: 'def', active : false, f1: 697, f2: 1477 },
        {value: "4", text: 'ghi', active : false, f1: 770, f2: 1209 },
        {value: "5", text: 'jkl', active : false, f1: 770, f2: 1336 },
        {value: "6", text: 'mno', active : false, f1: 770, f2: 1477 },
        {value: "7", text: 'pqrs',active : false, f1: 852, f2: 1209 },
        {value: "8", text: 'tuv', active : false, f1: 852, f2: 1336 },
        {value: "9", text: 'vwxyz', active : false, f1: 852, f2: 1477 },
        {value: "*", text: '', active : false, f1: 941, f2: 1209 },
        {value: "0", text: '+', active : false, f1: 941, f2: 1336 },
        {value: "#", text: '', active : false, f1: 941, f2: 1477}
      ]
    }
  },
  components : {
    TheButton,
    PhoneButton
  },
  methods : {
    call() {
      if (this.number !== '') {
          this.janus.call(this.number, this.number).catch((error) => {
              this.$alert(error);
          })
      }
    },
    onClick(button) {
      this.number += button.value;
      this.playTone(button);
    },
    playTone(button) {
      this.janus.sendDtmf(button.value)
      
      this.dtmf.freq1 = button.f1;
      this.dtmf.freq2 = button.f2;

      if (this.dtmf.status == 1) {
       this.dtmf.stop();
      }

      if (this.dtmf.status == 0) {
        this.dtmf.start();
      }

      setTimeout(() => {
        this.dtmf.stop();
      }, 250);
    },
    handlePress(evt) {
      const value = evt.key;
      const button = this.buttons.find(button => button.value == value)

      if (button){
        this.playTone(button);
        button.active = true;
        this.buttons = this.buttons.map(btn => btn.value === value ? button : btn)
        setTimeout(() => {
          button.active = false;
          this.buttons = this.buttons.map(btn => btn.value === value ? button : btn)
        }, 250)
      }
    },
    recall() {
      if (!this.number) {
        const lastCall = this.history[0]
        if (lastCall) {
          this.number = lastCall.number
        }
      }
    }
  },
  computed : {
    ...mapGetters({
      janus : 'getJanus',
      callHistory : 'getCallHistory',
      showSoftphone : 'getShowSoftphoneModal',
      tabActive : 'corpocalls/getActive'
    }),
    history() {
      return this.callHistory('corpo')
    }
  },
  created() {
    setTimeout(() => {
      if (this.$refs.search) {
        this.$refs.search.focus()
      }
    }, 100)
  },
  mounted() {
    // const self = this; 
    // window.addEventListener('keyup', function(ev) {
    //   if (self.showSoftphone && (self.tabActive === 'keypad' || self.tabActive === 'incall')) {
    //     self.handlePress(ev.key);
    //   }
    // }, false);
  }
}
</script>

<style scoped>
.keypad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--column);
    grid-auto-rows: minmax(10px, auto);
  }

  .icon {
    background: white;
    border:none;
    outline: none;
    font-size: 30px;
    color: var(--hiperpbx-green)
  }

  .icon.active {
    color: var(--hiperpbx-dark-green)
  }

  .phone-button {
    border-radius: 50%;
    font-size: 20px;
    height: 70px;
    width: 70px;
    background-color: #49c772;
    color: white!important;
    margin: 0 auto;
    margin-top: var(--column)!important;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .phone-button :deep(button) {
    background-color:#49c772!important;
    color: white;
  }

  .phone-button:hover {
    background-color: white;
    color: #49c772!important;
    border: 1px solid #49c772!important;
    cursor: pointer;
  }

  .phone-button:hover :deep(button) {
    background-color: white!important;
    color:#49c772!important
  }

  .number {
    margin-bottom: var(--column);
  }

  .number :deep(.el-input__inner) {
    font-size: 20px;
    color: var(--hiperpbx-green);
    font-weight: bold;
  }
</style>