import TheMainGeneralConfigurationView from "@/views/general-configuration/TheMainGeneralConfigurationView";

const routes = [
  {
    name: "general-configuration",
    path: "/configuration/general",
    component: TheMainGeneralConfigurationView,
    meta: {
      isInMenu: true,
      title : 'main-menu.configuration.general',
      access_control: ['ROLE_ADMIN'],
      requiresAuth: true
    }
  }
];

export default routes;
