
const state = {
    theme : localStorage.getItem('theme') || 'clasic',
}

const getters = {
    getTheme : (state) => state.theme
}

const mutations = {
    SET_THEME : (state, value) => {
        state.theme = value,
        localStorage.setItem('theme', value)
    }
}

const actions = {
    setTheme({commit}, value) {
        commit('SET_THEME', value)
    }
}

export default {
    state,
    getters, 
    mutations,
    actions
}