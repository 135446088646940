<template>
	<div class="success">
		<svg width="272px" height="206px" viewBox="0 0 272 206" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ok</title>
    <defs>
        <rect id="path-1" x="0.173623128" y="-1.34205934" width="96" height="96"></rect>
        <filter x="-52.1%" y="-41.7%" width="204.2%" height="204.2%" filterUnits="objectBoundingBox" id="filter-2">
            <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
            <feGaussianBlur stdDeviation="15" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
            <feColorMatrix values="0 0 0 0 0.250980407   0 0 0 0 0.749019623   0 0 0 0 1  0 0 0 0.239999995 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
        </filter>
    </defs>
    <g id="ok" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group" transform="translate(10.000000, 4.000000)">
            <g id="Order-Success-Copy">
                <path d="M0.324428569,128.386383 C4.06733777,89.3624763 19.4296011,85.0975653 31.0560578,79.3683724 C44.252121,72.8643884 55.5447676,71.7412961 70.6797569,39.5981079 C90.6585108,-2.83772393 126.610324,0.0410888431 140.21832,0.0410888431 C186.425846,0.0410888431 185.871892,45.895958 208.180199,68.7203226 C229.181684,90.2012404 275.076713,136.766921 223.095018,178.015686 C196.028173,199.489495 64.3800224,206.320459 21.0986405,179.494188 C4.99537099,168.917221 -1.92936633,151.881286 0.324428569,128.386383 Z" id="Background" fill="#F0F3E7" fill-rule="nonzero"></path>
                <path d="M48.2402581,35.1283981 C47.5267132,35.1838225 46.8616606,34.7889235 46.5776281,34.1376864 C45.1228279,30.784508 43.2870082,28.6853072 41.866846,29.0386379 C40.4466838,29.3988967 39.8301241,32.1146942 40.1280118,35.7588509 C40.1834327,36.4724405 39.7885611,37.1375348 39.1373648,37.421585 C35.7843963,38.8764764 33.6853269,40.712411 34.0386355,42.1326621 C34.3988718,43.5529132 37.1144966,44.1695089 40.7584251,43.8716026 C41.47197,43.8161781 42.1370227,44.2110774 42.4210551,44.8623146 C43.8758554,48.2154929 45.7116751,50.3146937 47.1318373,49.961363 C48.5519995,49.6011041 49.1685592,46.8853091 48.8706715,43.2411524 C48.8152506,42.5275628 49.2101251,41.8624685 49.8613215,41.5784183 C53.2142899,40.1165989 55.3202843,38.2875897 54.960048,36.8673386 C54.5998117,35.4470875 51.8841867,34.8304917 48.2402581,35.1283981 Z" id="Vector" fill="#009E4C" fill-rule="nonzero"></path>
                <path d="M246.593302,78.019874 C245.931481,77.7947182 245.481166,77.1738313 245.481166,76.4710725 C245.474343,72.8754036 244.648774,70.2554157 243.222789,70.0166142 C241.803626,69.7846355 240.17295,72.0020744 239.01988,75.4067019 C238.794725,76.0685234 238.173828,76.5188378 237.471069,76.5188378 C233.875402,76.5256607 231.255416,77.3512301 231.016614,78.7772164 C230.784636,80.1963798 233.002073,81.8270563 236.406699,82.9801267 C237.06852,83.2052824 237.518835,83.8261589 237.518835,84.5289177 C237.525657,88.1245866 238.351227,90.7445852 239.777212,90.9833868 C241.196375,91.2153654 242.82705,88.9979266 243.98012,85.593299 C244.205276,84.9314776 244.826173,84.4811628 245.528931,84.4811628 C249.131421,84.4743399 251.751408,83.6487705 251.983387,82.2227842 C252.215365,80.8036208 249.997928,79.1729443 246.593302,78.019874 Z" id="Vector" fill="#A1C700" fill-rule="nonzero"></path>
                <path d="M8.5,171 C3.80558,171 0,167.194426 0,162.5 C0,157.805582 3.80558,154 8.5,154 C13.1944219,154 17,157.805582 17,162.5 C17,167.194426 13.1944219,171 8.5,171 Z" id="Vector" fill="#009E4C"></path>
            </g>
            <g id="Success-Icon-Copy" transform="translate(81.000000, 65.000000)">
                <path d="M0.173623128,-1.34205934 L96.1736231,-1.34205934 L96.1736231,94.6579407 L0.173623128,94.6579407 L0.173623128,-1.34205934 Z" id="Success-Icon-(Background)"></path>
                <g id="Rectangle-383">
                    <use fill="black" fill-opacity="1" filter="url(#filter-2)" xlink:href="#path-1"></use>
                    <use fill="#32B768" fill-rule="evenodd" xlink:href="#path-1"></use>
                </g>
                <path d="M32.1736231,48.1579407 L42.8402898,57.6579407 L64.1736231,38.6579407" id="Vector-41" stroke="#FFFFFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0"></path>
            </g>
        </g>
    </g>
</svg>
	</div>
</template>

<script>
export default {
	name: "Success",
};
</script>

<style scoped>
.success {
	height: 250px;
	width: 250px;
}
.st0 {
	fill: #36da39;
}
.st1 {
	fill: #ffffff;
}
</style>
