import createStore from "../helper"
import extensionapi from '@/api/extensions'

const store = createStore('extensions')

const state = {
  extensionsData : [],
  extensionsUnused : []
}

const getters = {
  getExtensionsData : (state) => state.extensionsData,
  getExtensionsUnused : (state) => state.extensionsUnused,
  getExtensionsContact: (state) => state.extensionsData.map(extension => ({title : extension.name, number : extension.extension})),
  getExtensionByNumber: (state) => (value) => state.data.find(extension => extension.extension == value)
}

const mutations = {
  SET_EXTENSIONS_UNUSED : (state, extensions) => (state.extensionsUnused = extensions),
  SET_EXTENSIONS_DATA : (state, extensions) => (state.extensionsData = extensions),
}

const actions = {
  fetchExtensionsUnused({commit}, queryParams) {
    return new Promise((resolve, reject) => {
      extensionapi.fetchExtensionsUnused(queryParams).then((response) => {
        commit('SET_EXTENSIONS_UNUSED', response.data);
        return resolve(response.data);
      }).catch((error) => {
        return reject(error);
      })
    })
  },
  fetchExtensionData({ commit }) {
    return new Promise((resolve, reject) => {
      extensionapi.fetchExtensionData().then((response) => {
        commit('SET_EXTENSIONS_DATA', response.data['hydra:member']);
        return resolve(response);
      }).catch((error) => {
        return reject(error);
      })
    })
  },
  fetchExactExtension({ commit }, { number, organizationId}) {
    return new Promise((resolve) => {
      extensionapi.fetchExactExtension({ number, organizationId }).then((response) => {
        commit('SET_EXTENSIONS_DATA', response.data);
        return resolve(response.data);
      }).catch(() => {
        return resolve(false);
      })
    })
  }
}

store.state = {...store.state, ...state }
store.getters = {...store.getters, ...getters}
store.mutations = {...store.mutations, ...mutations }
store.actions = {...store.actions, ...actions }

export default store