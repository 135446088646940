import RequireAuth from '../auth'
import Videocall from '../../views/Videocall'

export default [
  {
		name : "Videocall",
		path : "/videocall/:roomId",
		components : {
			appView : Videocall
		},
		props : {
			appView : true
		},
		beforeEnter: RequireAuth,
		meta : {
			requiresAuth : true,
			access_control: ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_MANAGER'],
		},
	}
]