<template>
  <el-drawer
:title="$t('agent-console.buttons.new-call')"
:close-on-press-escape="!calling"
:show-close="!calling"
:wrapperClosable="!calling"
:visible.sync="show"
:modal="false"
:append-to-body="true"
direction="rtl"
ref="drawer"
@closed="resetDrawer"
  >
    <div class="container" v-if="view === 'list'">
      <div class="section">
        <div class="content">
          <el-form label-position="top" label-width="500px" :model="form">
            <el-form-item :label="$t('agent-console.search-for-the-user-or-write-the-number-to-call')">
              <el-input v-model="form.name" ref="search" @keydown.enter.native.prevent @keydown.enter.native="call({
                    title: form.name,
                    number: form.name,
                    type: 'phone',
                    date: new Date().getTime(),
                  }, true)
                "
              ></el-input>
            </el-form-item>
          </el-form>
          <el-button
            class="mt-0 pt-0"
            v-if="form.name !== ''"
            type="text"
            @click.native="
              call({
                title: form.name,
                number: form.name,
                type: 'phone',
                date: new Date().getTime(),
              }, true)
            "
            >{{ $t("agent-console.call-to", { name: form.name }) }}
            <i class="fas fa-phone"></i
          ></el-button>
          <p v-if="form.name === ''">
            {{ $t("agent-console.recent") }}
          </p>
          <div v-if="calls.length < 1 && form.name === ''">
            <p>{{ $t("agent-console.here-the-destinations-you-have-called-will-be-shown")}}</p>
          </div>
        </div>
        <ul class="scrollable-content content">
          <div v-if="form.name !== '' && contacts.length > 0 ">
            <p>{{ $t("agent-console.all-the-contacts") }}</p>
            <li v-for="(item, key) in contacts" :key="key"> 
              <user-item :user="item" ></user-item>
              <phone-button v-if="!('phone_one' in item)" :janus="janus" @click.native="call({ number : item.visibleExtension, title : item.visiblename, date : new Date().getTime(), type : 'user'})" :extension="item.visibleExtension" :displayname="item.visiblename"></phone-button>
              <button @click="contact = item, view = 'contact'" v-else class="back">
                <i class="fas fa-phone"></i>
              </button>
            </li>
          </div>
          <p v-if="form.name !== '' && calls.length > 0">{{ $t("agent-console.not-in-your-contacts") }}</p>
          <li v-for="(item, key) in calls" :key="key">
            <div>
              <!-- <user-item :user="item" v-if="item.type === 'user'" :show-status="false"></user-item> -->
              <ListItem :item="item" :type="item.type">
                <template slot="other-content">{{
                  item.date | redeableDate(true)
                }}</template>
              </ListItem>
            </div>
            <phone-button :janus="janus" @click.native="call({...item, date : new Date().getTime()})" :extension="item.number" :displayname="item.title"></phone-button>
          </li>
        </ul>
        <el-button style="margin-bottom: var(--column)" @click="loadMore" :disabled="noMoreResults">{{ $t("common.view-more") }}</el-button>
      </div>
    </div>
    <div class="container" v-if="view === 'contact'">
      <div>
        <button class="back" @click="view = 'list'"><i class="fas fa-arrow-left"></i></button>
        <list-item :title="contact.title" type='user' class="contact-list-item d-block mt-3 mb-3" />
        <ul class="phones">
          <li v-if="contact.number"><p>Número {{ contact.number }} </p> <phone-button :janus="janus" @click.native="call({...contact, date : new Date().getTime()})" :extension="contact.number" :displayname="contact.title"></phone-button></li>
          <li v-if="contact.extension"><p>Extension {{ contact.extension }} </p> <phone-button :janus="janus" @click.native="call({...contact, number: contact.extension, date : new Date().getTime()})" :extension="contact.extension" :displayname="contact.title"></phone-button></li>
          <li v-if="contact.phone_one"><p>Telefono 1 {{ contact.phone_one }} </p> <phone-button :janus="janus" @click.native="call({...contact, number: contact.phone_one, date : new Date().getTime()})" :extension="contact.phone_one" :displayname="contact.title"></phone-button></li>
          <li v-if="contact.phone_two"><p>Telefono 2 {{ contact.phone_two }} </p> <phone-button :janus="janus" @click.native="call({...contact, number: contact.phone_two, date : new Date().getTime()})" :extension="contact.phone_two" :displayname="contact.title"></phone-button></li>
        </ul>
      </div>
    </div>
    <div class="middle" v-if="view === 'calling'">
      <div class="calling">
        <p>{{ $t("agent-console.making-call-to", { dest : callTo.title }) }}</p>
        <pulse-animation class="ringback">
          <i class="fas fa-phone"></i>
        </pulse-animation>
        <p>{{ $t("agent-console.please-wait") }}</p>
        <hangup-button :janus="janus" class="hangup"/>
      </div>
    </div>
    <audio ref="audio">
      <source
        src="/sounds/Phone_Ringing_8x-Mike_Koenig-696238708.mp3"
        type="audio/ogg"
      />
    </audio>
  </el-drawer>
</template>

<script>
import { mapActions } from "vuex";
import UserItem from "@/components/common/UserItem";
import ListItem from "@/components/common/ListItem";
import HangupButton from "@/components/webrtc/HangupButton";
import PulseAnimation from '@/components/common/PulseAnimation';
import PhoneButton from '@/components/webrtc/PhoneButton.vue';
export default {
  name: "NewCallDrawer",
  props: {
    janus: {
      required: true,
    },
  },
  data() {
    return {
      view: 'list',
      calling: false,
      show: false,
      showContent: true,
      form: {
        name: "",
      },
      titleList: "Recientes",
      callTo: null,
      audioInterval: null,
      contact: null,
    };
  },
  methods: {
    ...mapActions([
      "doCall",
      "addCallHistory",
      "listenCallHistory",
      "setInCall"
    ]),
    call(callTo, useJanus = false) {

      this.view = 'calling';

      if (!this.show) {
        this.show = true
      }

      if (useJanus) {
        this.janus.call(callTo.number, callTo.title).catch((error) => {
          this.$alert(error);
        })
      }
      
      if (this.$refs.audio) {
        this.$refs.audio.volume = 0.5;
      }

      this.calling = true;
      this.callTo = callTo;

      this.doCall(callTo).then(() => {
        this.$refs.audio.play();
        this.audioInterval = setInterval(() => {
          if (this.$refs.audio) {
            this.$refs.audio.play();
          }
        }, 5000);

        this.addCallHistory({ from : 'call_center', call : callTo });
      });

      this.setInCall();
    },
    resetDrawer() {
      this.view = 'list';
      this.calling = false;
      this.callTo = null;
      this.form.name = "";
      this.pauseRingback()
    },
    closeAndReset() {
      this.$refs.drawer.closeDrawer();
      setTimeout(() => {
        this.resetDrawer();
      }, 2000);
    },
    pauseRingback() {
      if (this.$refs.audio) {
        this.$refs.audio.pause();
        this.$refs.audio.currentTime = 0;
      }
      clearInterval(this.audioInterval);
    },
    loadMore() {
      const lastCall = this.$store.getters['getLastDoc']('call_center')
      this.listenCallHistory({ from : 'call_center', lastCall});
    },
    externalCall(callTo) {
      this.show = true
      this.call({ title: callTo, number: callTo, type: 'phone', date: new Date().getTime() }, true)
    }
  },
  computed : {
    contacts() {
      const name = this.form.name.replace(/\*/ig,'\\*').replace(/\(/ig, '\\(').replace(/\)/ig,'\\)').replace(/[$#%&/()=?¡¿!'\\|°"^[\]{}~;,.:\-_*+¬ `]/g,'');
      const reg = new RegExp(name, "ig");
      return this.$store.getters['contacts/getAllContacts'].filter(contact => contact.visibleExtension).filter(contact => !this.form.name || (reg.test(contact.visiblename) || reg.test(contact.visibleExtension))  );
    },
    calls() {
      const name = this.form.name.replace(/\*/ig,'\\*').replace(/\(/ig, '\\(').replace(/\)/ig,'\\)').replace(/[$#%&/()=?¡¿!'\\|°"^[\]{}~;,.:\-_*+¬ `]/g,'');
      const reg = new RegExp(name, "ig");
      return this.$store.getters["getCallHistory"]('call_center')
        .filter(call => !this.form.name || reg.test(call.title) || reg.test(call.number))
        .map((call) => {
          if (call.type == 'user') {
            return {...this.$store.getters['contacts/getContactByVisibleExtension'](call.number), ...call}
          }
          return call;
        })
    },
    callingFromMailcenter() {
      return this.$store.getters['getCallingFromMailCenter']
    },
    noMoreResults() {
      return this.$store.getters['getNoMoreResults']('call_center')
    }
  },
  components: {
    ListItem, 
    HangupButton,
    PulseAnimation,
    PhoneButton,
    UserItem
  },
  watch: {
    show: function (value) {
      if (value) {
        setTimeout(() => {
          if (this.$refs.search) {
            this.$refs.search.focus();
          }
        }, 100);
      }
    },
    callingFromMailcenter(value) {
      if (value) {
        this.show = true
        this.call(value)
      }
    }
  },
  created() {
    this.listenCallHistory({ from : 'call_center'});
  }
};
</script>

<style scoped>
.container {
  position: absolute;
  top: 50px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* for Firefox */
  min-height: 0;
}

.scrollable-content {
  flex-grow: 1;
  overflow: auto;
  /* for Firefox */
  min-height: 0;
  margin-top: 20px;
}

.phones li,
.scrollable-content li {
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  margin-bottom: var(--column);
  padding-right: calc(var(--column) / 2);
  width: 100%;
}

.middle {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: var(--column);
}

.middle p {
  text-align: center;;
}

.img-message {
  height: 150px;
  width: 150px;
}

.el-drawer > header > span:focus {
  outline-color: white;
}
.el-drawer > header > button:focus {
  outline-color: white;
}
.el-drawer > header > button:hover {
  color: rgb (64, 158, 255);
}

.fa-phone {
  cursor: pointer;
}

.ringback {
  margin: 0 auto;
}

.ringback .fa-phone {
  color: white;
}

.hangup {
  margin: 0 auto;
  display: block;
}

.back {
  background-color: white;
  border: none;
  color: var(--blue-grey);
  outline: none;
}
.contact-list-item{
  padding-top: 20px;
}
</style>