<template>
  <div>
    <h2>{{ title }}</h2>
      <ul>
        <li><span>{{ $t("ping.ping-sent") }}</span> <span>{{ info.pingsSents }}</span></li>
        <li><span>{{ $t("ping.ping-received") }}</span> <span>{{ info.pingsReceived }}</span></li>
        <li><span>{{ $t("ping.average-ping-time") }}</span> <span>{{ info.averagePingTime }}</span>ms</li>
        <li><span>{{ $t("ping.average-jitter") }}</span> <span>{{ info.avgJitter }}</span>ms</li>
        <li><span>{{ $t("ping.packet-loss") }}</span> <span>{{ info.packetLoss }}</span>%</li>
      </ul>
    <p v-html="message"></p>
    <p v-html="packetLost"></p>
  </div>
</template>

<script>
export default {
  props : {
    title : {
      required : true,
      type : String
    },
    info : {
      required : true
    },
    server : {
      required : true,
      type : String
    }
  },
  computed : {
    message() {
      return this.$t("ping.you-are-connected-to-the-server-with-an-average-latency-of", { server: this.server, pingtime : this.info.averagePingTime })
    },
    packetLost() {
      return this.$t("ping.the-packet-loss-rate-is", { packageloss: this.info.packetLoss })
    }
  }
}
</script>

<style>

</style>