import createNotification from "@/plugins/notification";

const state = {
  notifications: [],
  totalNotifications: 0,
  firstTime: true
}

const getters = {
  getNotifications: (state) => {
  return state.notifications
  },
  getVideocallsNotifications: (state) => state.notifications.filter(notification => notification.type === 'videocall' && notification.active),
  getTotalNotifications: (state) => state.totalNotifications,
  getFirstTime: (state) => state.firstTime

}

const mutations = {
  ADD_NOTIFICATION: (state, notification) => {
    if(state.notifications.some((element) => element.id == notification.id)){
      return
    }
    state.notifications.push(notification)
  },
  UPDATE_NOTIFICATION: (state, notification) => ([...state.notifications.filter(n => n.id !== notification.id), notification]),
  REMOVE_NOTIFICATION: (state, notification) => (state.notifications = state.notifications.filter(n => n.id !== notification.id)),
  SET_TOTAL_UNREAD_NOTIFICATIONS: (state, number) => (state.totalNotifications = number),
  SET_FIRST_TIME: (state, value) => (state.firstTime = value)
}

const actions = {
  async listenToNewNotifications({ rootState, commit, rootGetters }, {uid, offset}) {
    const firebase = rootState.firebase.firebase.firestore();
    const query = firebase.collection(`/users/${uid}/notifications`).orderBy('createdAt', 'desc').limit(offset)
    query.onSnapshot((snapshot) => {
      snapshot.docs.forEach(async (element)=>{
        const data = element.data();
        data.id = element.id
        
        commit('ADD_NOTIFICATION', data);
        
        const totalQuery = await firebase.collection(`/users/${uid}/notifications`).get();
        let totalIsNotRead = []
        if(totalQuery){
          totalIsNotRead = totalQuery.docs.filter((element)=>element.data().isRead == false)
        }
        commit('SET_TOTAL_UNREAD_NOTIFICATIONS', totalIsNotRead.length);
      
      })
      const docChanges = snapshot.docChanges();
      const notOnlyAdd = docChanges.some((element) => element.type !== 'added')
      if(!rootGetters.getFirstTime && !notOnlyAdd){
        createNotification('Tienes una nueva notificacion', true)
      }
      commit('SET_FIRST_TIME', false)
    });
    
    // firebase.database().ref(`notifications/${uid}`).orderByChild('createdAt', ).on('child_added', (snap) => {
    //   let message = '';
    //   const data = snap.val();
    //   data.id = snap.key;
      // console.log(data)
      // const user = users.find(user => (user.uid === data.uid));
      // const fullname = (user) ? user.fullname : ''
      // switch (data.type) {
      //   case 'new_message':
      //     if (user) {
      //       message = `${fullname} te ha enviado un mensaje`;
      //     }
      //     dispatch('notificationNewMessage', { roomId: data.roomId, count: data.value });
      //     break;
      //   case "videocall":
      //     message = `${fullname} te esta llamando`
      //     break;
      // }
      // commit('ADD_NOTIFICATION', data);
      // firebase.database().ref("notifications").child(uid).once('value', (snapshot) => {
      //   commit('SET_TOTAL_UNREAD_NOTIFICATIONS', snapshot.numChildren());
      // });
      // if (message) {
      //   createNotification(message)
      // }
    // });

    // firebase.database().ref("notifications").child(uid).on('child_changed', (snap) => {
    //   let message = '';
    //   const data = snap.val();
    //   data.id = snap.key;
    //   const user = users.find(user => (user.uid === data.uid));
    //   switch (data.type) {
    //     case 'new_message':
    //       dispatch('notificationNewMessage', { roomId: data.roomId, count: data.value });
    //       if (user) {
    //         message = `${user.fullname} te ha enviado un mensaje`;
    //       }
    //       break;
    //   }
    //   commit('UPDATE_NOTIFICATION', data);
    //   if (message) {
    //     createNotification(message)
    //   }
    // });

    // firebase.database().ref("notifications").child(uid).on('child_removed', (snap) => {
    //   const data = snap.val();
    //   data.id = snap.key;
    //   commit('REMOVE_NOTIFICATION', data);
    // });
  },
  async setNotificationToInactive({ rootState, commit }, id) {
    // const user = rootState.user.user;
    // const firebase = rootState.firebase.firebase
    // const notification = state.notifications.find(notification => (notification.id === id));
    // if (notification) {
    //   firebase.database().ref("notifications").child(user.uid).child(id).update({
    //     active : false
    //   }).then(() => {
    //     notification.active = false;
    //     commit('UPDATE_NOTIFICATION', notification)
    //   });
    // }
    const firebase = rootState.firebase.firebase.firestore();
    const user = rootState.user.user;
    const notification = state.notifications.find(notification => (notification.id === id));
    if(notification){
    const ref = firebase.collection(`/users/${user.uid}/notifications`).doc(id);
    await ref.update({
      isRead: true
    })
    notification.isRead = true
    commit('UPDATE_NOTIFICATION', notification)
    }
    
  },
  async cleanNotification({ rootGetters, commit }, id) {
    const user = rootGetters.getUser
    // const firebase = rootGetters.getFirebase
    // firebase.database().ref('notifications').child(user.uid).child(id).remove()
    const firebase = rootGetters.getFirebase.firestore();
    const docRef = firebase.collection(`users/${user.uid}/notifications`).doc(id);
    await docRef.delete();
    commit('REMOVE_NOTIFICATION', {id:docRef.id});
    
    commit('SET_TOTAL_UNREAD_NOTIFICATIONS', rootGetters.getTotalNotifications == 1 ?  0 : rootGetters.getTotalNotifications);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}