<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :can-delete="true" :goBack="goBack" :init-query-params="{
        ['organization.id'] : organizationId
      }" />
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'

export default {
  data() {
    return {
      title: this.$t('contact-me.chat-center.chat-classification'),
      store: "chatClassifications",
      columns: [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('common.name') },
        { index : 'description', label : this.$t('common.description') },
      ]
    }
  },
  components: {
    TheCrud
  },
  methods:{
    goBack() {
      this.$router.push({ name: "contact-me-chat-center"})
    },
  },
  computed: {
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  }
}
</script>