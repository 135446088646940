<template>
  <div v-loading.fullscreen.lock="loading" class="login-form" :style="{
    'background-image' : 'url(' + src + ')',
    'background-repeat': 'no-repeat',
    'background-position': 'center right',
    'background-size': 'auto 100%',
  }">
    <div style="display: flex; align-items: center; flex: 1">
      <div class="form">
        <div style="width: 70%;    margin: 0 auto;">
          <h1 class="logo" style="display:none">HiperMe!</h1>
          <img src="@/img/hiperme_isologotipo_miscelanias.svg" class="img-logo">
          <h2>{{ $t("login.title") }}</h2>
          <div v-if="auth0">
            <el-button type="primary" @click.native="$auth.loginWithRedirect()" >{{ $t("login.login") }}</el-button>
          </div>
          <el-form ref="form" :model="form" v-else size="medium">
            <el-form-item :label="$t('login.email')">
              <el-input v-model="form.email" @keyup.enter.native="doLogin"></el-input>
            </el-form-item>
            <el-form-item :label="$t('login.password')">
              <el-input v-model="form.password" show-password @keyup.enter.native="doLogin"></el-input>
            </el-form-item>
            <el-button type="success" @click.native="doLogin" :disabled="!isValid">{{ $t("login.login") }}</el-button>
            <p class="text-center">
              <router-link :to="{ name: 'ForgotPassword' }">{{ $t("recover-password.you-forgot-the-password") }}</router-link>
            </p>
            <p v-if="errorMessage" class="error-message">{{ message }}</p>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import http from "@/api/http";
import cookie from '@/plugins/cookie'
import { mapActions } from "vuex";

import { VueReCaptcha } from "vue-recaptcha-v3";
import Vue from "vue";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      src: "",
      message: "",
      loading: true,
      signIn : false,
      isValid: false,
      errorMessage: false,
      auth0: process.env.VUE_APP_DOMAIN && process.env.VUE_APP_CLIENTID,
    };
  },
  methods: {
    ...mapActions([
      "login",
      "doLogout",
    ]),
    errorLogin(error) {
      this.loading = false;
      this.errorMessage = true;
      this.message = error;
      cookie.deleteCookie()
    },
    async recaptcha() {
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha('login').then( t => {
          this.form.token = t;
          this.isValid = true;
      });
      return token;
    },
    async doLogin() {
      this.message = ''
      this.loading = true;
      this.errorMessage = false;

      try {
       /*  const validation = await http.post('/recaptcha', { "token": this.form.token });

        if( ! validation.data.success ) throw validation; */

        await this.login({ email: this.form.email, password: this.form.password})
        this.$router.push({ name : "Home"});
      } catch (error) {
        this.handleError(error)
      }
    },
    async handleError(error) {
      if ( error.response.status == 400 ) {
        const data = error.response.data;
        if (data.code === 4001) {
          /*this.message = "Token invalid. Intente nuevamente";
          this.$alert('Ha permanecido demasiado tiempo en esta pantalla sin iniciar sesión. Presione "Aceptar" para recargar la página', 'Token invalido', {
            confirmButtonText: 'Aceptar',
            callback: () => location.reload()
          })*/
          await this.recaptcha();
          this.doLogin();
          return;
        }
        this.loading = false;
        this.errorMessage = true;
        this.message = error.response.data.message;
      }

      if (error.response.status == 401) {
        this.loading = false;
        this.errorMessage = true
        this.message = error.response.data.message;
      }

      if ('sessions' in error) {
        this.errorLogin(this.$t('login.the-limit-of-available-sessions-has-been-reached-please-try-again-later'))
      }

      if ('redirect' in error) {
        this.errorLogin(this.$t('login.you-will-be-redirected-to', { url: error.redirect }))
        setTimeout(() => {
          window.location.href = error.redirect
        },5000)
      }

      if ('not-found' in error) {
        this.errorLogin(error['not-found'])
      }
    },
    validate(response) {
      this.isValid = false;
      if (response) {
        this.isValid = true;
      }
    }
  },
  created() {
    Vue.use(VueReCaptcha, {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY
    });
    localStorage.removeItem('user')
    http
      .get(`side_images/active`)
      .then((response) => {
        this.src = response.data
          ? process.env.VUE_APP_API_HOST.replace("/api", "") +
            "" +
            response.data.fullPath
          : "";
      })
      .finally(async () => {
        this.loading = false;
        this.isValid = true;
        this.recaptcha( );
      });
  },
};
</script>

<style scoped>
.login-form {
  justify-content: space-evenly;
  align-items: stretch;
  display: flex;
  height: 100vh;
  width: 100%;
  background-image: --login-splash
}

.form {
  background: rgba(255,255,255,0.8);
  padding: 0 calc(var(--column) * 2);
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

h1 {
  display: none;
  text-align: center;
}

h2 {
  text-align: center;
}

.error-message {
  color: var(--red);
  text-align: center;
  margin-top: var(--column);
}

button {
  width: 100%;
}

.login-form .el-button {
  margin-bottom: 1.5em;
}

.recaptcha >>> div:first-child {
  margin: 0 auto;
  margin-bottom: var(--column);
}

.img-logo {
  display:block; 
  margin: 0 auto;
  margin-top:10px;
  max-width: 200px;
}

.text-center {
  padding-bottom: var(--column)
}

@media (max-width: 1024px) {
  .form {
    width: 100%;
  }

  .text-center {
    padding-bottom: calc(var(--column) / 2);
  }

  .recaptcha {
    margin: calc(var(--column) / 2);
  }

}
</style>
