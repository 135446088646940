import http from '../api/http'

export const createPBXStore = (resource, model = null) => {
  const state = {
    data: [],
    item: null,
    id: null,
    ringStrategies: [
      { value: "ringall"},
      { value: "roundrobin"},
      { value: "leastrecent"},
      { value: "fewestcalls"},
      { value: "random"},
      { value: "rrmemory"},
    ]
  }

  const getters = {
    getData: (state) => state.data,
    getItem: (state) => state.item,
    getDataAsArray: (state) => Object.values(state.data),
    getItemById: (state) => (id) => (Object.values(state.data).find(item => item[state.id] == id)),
    getRingStrategies: (state) => state.ringStrategies,
  }

  const mutations = {
    SET_ID: (state, value) => state.id = value,
    SET_DATA: (state, value) => state.data = value,
    SET_ITEM: (state, value) => state.item = value,
    ADD_DATA: (state, value) => (state.data[value[state.id]] = value),
    UPDATE_DATA: (state, value) => (state.data[value[state.id]] = value),
    DELETE_DATA: (state, id) => (
      state.data = Array.isArray( state.data ) ? 
        (state.data.filter(item => item[state.id] != id ) ) :
        (Object.keys(state.data).filter(key => key !== id).reduce((obj, key) => (obj[key] = state.data[key], obj), {})
        )
    )
  }

  const actions = {
    async all({ commit, rootGetters }) {
      const id = rootGetters["contactMe/getOrganizationId"];
      const token = rootGetters["contactMe/getPbxToken"];
      const { data } = await http.get(`/pbx/${id}/${resource}`, {
        headers: {
          "Api-Authorization": token
        }
      });
      commit("SET_DATA", data);
    },
    async one({ commit, rootGetters }, resourceId) {
      const id = rootGetters["contactMe/getOrganizationId"];
      const token = rootGetters["contactMe/getPbxToken"];
      const { data } = await http.get(`/pbx/${id}/${resource}/${resourceId}`, {
        headers: {
          "Api-Authorization": token
        }
      });
      commit("SET_ITEM", data);
    },
    async create({ commit, rootGetters }, payload) {
      const id = rootGetters["contactMe/getOrganizationId"];
      const token = rootGetters["contactMe/getPbxToken"];
      const { data } = await http.post(`/pbx/${id}/${resource}/create`, payload, {
        headers: {
          "Api-Authorization": token
        }
      });
      commit("ADD_DATA", data[model]);
      return data;
    },
    async update({ rootGetters, commit }, { resourceId, payload }) {
      const id = rootGetters["contactMe/getOrganizationId"];
      const token = rootGetters["contactMe/getPbxToken"];

      const { data } = await http.post(`/pbx/${id}/${resource}/${resourceId}/update`, payload, {
        headers: {
          "Api-Authorization": token
        }
      });
      commit("UPDATE_DATA", {
        [state.id] : resourceId,
        ...payload
      });
      return data;
    },
    async delete({ rootGetters, commit }, resourceId) {
      const id = rootGetters["contactMe/getOrganizationId"];
      const token = rootGetters["contactMe/getPbxToken"];

      const { data } = await http.post(`/pbx/${id}/${resource}/${resourceId}/delete`, null, {
        headers: {
          "Api-Authorization": token
        }
      });
      commit("DELETE_DATA", resourceId);
      return data;
    }
  }

  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
}

export default createPBXStore;