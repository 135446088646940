<template>
  <div class="crud main-view">
    <div class="header">
      <the-header :title="$t('contact-me.pbx.ivrs.title')"></the-header>
      <div class="buttons">
        <el-button type="primary" @click="$router.push({ name: 'contact-me-pbx-ivrs-new'})">{{ $t("common.new") }}</el-button>
      </div>
    </div>
    <the-main-crud resource="ivrs" :columns="columns" :search="search" id="id" name="name" @deleted="handleDeleted"></the-main-crud>
  </div>
</template>

<script>

import TheHeader from "../TheHeader.vue";
import TheMainCrud from "../TheMainCrud.vue";
export default {
  data() {
    return {
      columns: [
        { index: "name", label: this.$t("contact-me.pbx.queues.name") },
        { index: "announcement.name", label: this.$t("contact-me.pbx.ivrs.announcement") },
        { index: "dests.t.name", label: this.$t("contact-me.pbx.ivrs.timeout") },
      ],
      search:  ["name"]
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-pbx"});
    },
    handleDeleted(ivr) {
      console.log(ivr)
    }
  },
  components: {
    TheHeader,
    TheMainCrud,
  }
}
</script>

<style scoped>
.main-view .header {
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>