<template>
  <div class="chat-messages-display" :class="{ invisible: !showMessages, 'hide-scroll': searching }" ref="messages" @scroll="handleScroll">
    <chat-message v-for="(message, index) in messages" :key="index" :message="message" :myself="myself"
      :user-type="userType" :room-id="roomId" :canAnswer="reply" />
      <div class="unreadMessage" v-if="unreadMessages > 0 && !nearBottom && showButtonToBottom">
        <el-badge :value="unreadMessages" :max="99" >
          <el-button icon="el-icon-arrow-down" circle size="small" @click="scrollToBottom"></el-button>
        </el-badge>
      </div>
  </div>
</template>

<script>
import ChatMessage from './ChatMessage';
export default {
  name: "MessagesDisplay",
  props: {
    roomId: {
      type: String
    },
    messages: {
      type: Array
    },
    unparsedMessages: {
      type: Array
    },
    myself: {
      type: Object,
      default: () => ({
        id: 0,
        name: '',
      })
    },
    userType: {
      type: String,
      required: false,
      default: 'normal'
    },
    unreadMessages: {
      required: false,
      default: 0
    },
    reply: {
      required: false,
      default: true
    },
    showButtonToBottom: {
      required: false,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
      showMessages: false,
      searching: false,
      nearBottom: true,
    }
  },
  components: {
    ChatMessage
  },
  methods: {
    handleScroll({ target: { scrollTop, clientHeight, scrollHeight }}) {
      if (scrollTop === 0) {
        this.$emit('searchChatHistory', this.messages[0].date);
      }

      this.nearBottom = scrollTop + clientHeight > scrollHeight - 300;
    },
    checkIfScrollToBottom() {
      const lastMessage = this.messages[this.messages.length - 1];
      const time = lastMessage.type === "image" ? 500 : 20;
      setTimeout(() => {
        if (this.nearBottom || !this.showMessages) {
          this.scrollToBottom();
        }
      }, time)
    },
    scrollToBottom() {
      const messagesDisplay = this.$refs.messages;
      this.$nextTick(() => {
        this.$refs.messages.scrollTop = messagesDisplay.scrollHeight
      });
    }
  },
  watch: {
    messages(value) {
      if (value.length) {
        this.checkIfScrollToBottom();
        setTimeout(() => {
          this.showMessages = true;
        }, 1000)
      }
    }
  },
  mounted() {
    if (this.messages.length > 0) {
      this.$nextTick(() => {
      this.showMessages = true;
        this.scrollToBottom();
      });
    }
  }
}
</script>

<style>
.chat-messages-display {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  overscroll-behavior: contain;
  background-image: url('/images/interlaced.png')
}

.hide-scroll {
  overflow: hidden;
}

.chat-messages-display.invisible {
  visibility: hidden;
}

.chat-messages-display {
  padding: 10px;
}

.unreadMessage {
  position: absolute;
  bottom: 75px;
}
</style>