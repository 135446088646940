<template>
  <div>
    <header class="header">
      <h2>{{ $t("configuration.users.title") }}</h2>
      <aside class="buttons">
        <el-button type="primary" @click="$router.push({ name : 'mailcenter.newUser' })" >{{ $t("configuration.users.create") }}</el-button>
      </aside>
    </header>
    <the-table :rows="rows" :columns="columns" :pagination="pagination" :loading="loading" :filters="filters" :search="search" @fetch="fetch" @change-page="changePage" >
      <template v-slot:action-buttons="scope">
          <el-dropdown trigger="click">
            <el-button icon="fas fa-ellipsis-h" type="text"></el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="fas fa-pencil-alt" @click.native="handleEdit(scope.row)">{{ $t("common.edit") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </template>
    </the-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TheTable from '@/components/crud/TheTable'
export default {
  data() {
    return {
      columns : [
        { index : 'id', label : '#'},
        { index : 'name', label: this.$t('configuration.users.name')},
        { index : 'email', label : this.$t("contacts.email")},
        { index : 'active', label : this.$t("mail-center.users.active"), type: 'boolean'},
        { index : 'action-buttons', label : ''},
      ],
      loading: true,
      filters: [],
      search: ['email', 'name'],
      pagination : {
        totalItems : 0,
        pageSize: 0
      },
      page: 1
    }
  },
  methods: {
    ...mapActions('mailcenter', ['fetchUsers', 'updateUser']),
    async fetch({ email } = { email : '' }) {
      this.loading = true
      try {

        const queryParams = {
          page: this.page
        }

        if (email) {
          queryParams.query = email
        }

        const data = await this.fetchUsers(queryParams)

        if (data.meta) {
          const { total= 0, per_page = 0 } = data.meta
          this.pagination = {
            totalItems: total,
            pageSize: per_page
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }  
    },
    changePage(params) {
      this.page = params.page
      this.fetch(params)
    },
    setStatus(user) {
      this.updateUser(user)
    },
    handleEdit(user) {
      this.$router.push({name: 'mailcenter.editUser', params: {id: user.id, user}});
    }
  },
  components: {
    TheTable
  },
  created() {
    this.fetch()
  },
  computed:{
    ...mapGetters('mailcenter', {
      rows : 'getUsers',
    })
  }
}
</script>