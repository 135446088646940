import store from '@/store';
import TheForm from "@/views/contact-me-configuration/audios/TheForm.vue";
import TheAudios from "@/views/contact-me-configuration/audios/TheList.vue";

const routes = [
  {
    name: "contact-me-pbx-audios",
    path: "/contact-me/pbx/audios",
    component: TheAudios,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true
  },
  {
    name: "contact-me-pbx-audios-new",
    path: "/contact-me/pbx/audios/new",
    component: TheForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
  },
  {
    name: "contact-me-pbx-audios-edit",
    path: "/contact-me/pbx/audios/:id/edit",
    component: TheForm,
    meta : {
      access_control : ['ROLE_ADMIN','ROLE_MANAGER'],
      requiresAuth : true
    },
    props: true,
    beforeEnter: (to, from, next) => {

      const type = to.params.entity?.type ?? 'system-recording';
      const storage = type == 'moh' ? 'pbx_music_categories' : 'pbx_system_recordings' ;
      const id = to.params.id;
      { store, storage, id }
      /*
      if (!store.getters[storage+'/getItemById'](id)) {
        return next({ name : "contact-me-pbx-audios"})
      }
      */
      return next();
    }
  },
];

export default routes;
