<template>
  <el-dropdown trigger="click" v-if="agent.call_logged">
    <el-button icon="fas fa-ellipsis-v" type="text" placement="bottom-start"></el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item v-if="isJanusReady && isInCall" :disabled="getCorpoIsInCall" @click.native="call('spy', agent)">{{ $t("agents-dashboard.actions.spy") }}</el-dropdown-item>
      <el-dropdown-item v-if="isJanusReady && isInCall" :disabled="getCorpoIsInCall" @click.native="call('whisper', agent)">{{ $t("agents-dashboard.actions.whisper") }}</el-dropdown-item>
      <el-dropdown-item v-if="agent.call_logged" @click.native="logout">{{ $t("agents-dashboard.actions.logout") }}</el-dropdown-item>
      <el-dropdown-item v-if="!agent.in_break && agent.call_logged" @click.native="showBreakModal">{{ $t("agents-dashboard.actions.break") }}</el-dropdown-item>
      <el-dropdown-item v-if="agent.in_break && agent.call_logged" @click.native="doUnbreak">{{ $t("agents-dashboard.actions.unbreak") }}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      types: {
        spy: 551,
        whisper: 553
      }
    }
  },
  props: {
    agent: {
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isJanusReady: 'isJanusReady',
      janus: 'getJanus'
    }),
    ...mapGetters(['getCorpoIsInCall']),
    isInCall() {
      return this.agent.call_status === "call"
    }
  },
  methods: {
    call(type, agent) {
      const number = this.types[type];
      if (!number) return false;
      this.janus.call(`*${number}${agent.extension}`, type);
    },
    logout() {
      this.$store.dispatch("doLogoutAction", {
        uid: this.agent.uid, 
        groupNumber: this.agent.groupNumber,
        organizationId: this.agent.organization
      });
    },
    showBreakModal() {
      this.$store.commit("SET_AGENT", this.agent)
      this.$store.commit("TOGGLE_BREAK_MODAL")
    },
    doBreak() {
      this.$store.dispatch("showBreakModal")
    },
    doUnbreak() {
      this.$store.dispatch("unBreakAction", {
        uid: this.agent.uid, 
        groupNumber: this.agent.groupNumber,
        organizationId: this.agent.organization
      });
    }
  }
}
</script>

<style scoped>
.el-button.compact {
  padding: 1px 10px;
}
</style>