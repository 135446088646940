<template>
	<el-dialog
		@open="searchMembersOfGroup"
		@closed="closeModal"
		:visible.sync="show"
    :close-on-click-modal="closeOnClickModal"
		:show-close="closeOnClickModal"
		:close-on-press-escape="closeOnClickModal"
	>
		<h2>{{ $t("groups.members.title", { name : name }) }}</h2>
		<div>
			<p v-if="userOwner">{{ userOwner.fullname }} {{ $t("groups.members.admin") }}</p>
			<ul
				v-loading="loading" >
				<li v-for="user in users" :key="user.uid">
					<the-group-member :user="user" :owner="owner" :roomId="roomId" @deleted="userDeleted" @deleting="closeOnClickModal = false"></the-group-member>
				</li>
			</ul>
			<p v-if="users.length === 0 && !loading">No hay usuarios en este grupo</p>
			<el-button @click="searchMembersOfGroup" :disabled="empty" v-if="users.length > 0 && !loading">{{ $t("common.view-more") }}</el-button>
		</div>
	</el-dialog>
</template>

<script>
import TheGroupMember from './TheGroupMember'
export default {
	data() {
		return {
			users : [],
			loading: false,
			closeOnClickModal : true,
			latesUser : null,
			empty : false,
		}
	},
	components : {
		TheGroupMember
	},
	computed: {
		show: {
			get() {
				return this.$store.getters.groupMembersIsVisible
			},
			set() {
				return this.$store.commit("toggleGroupMembers")
			},
		},
		name() {
			return this.$store.getters.getGroupName
		},
		roomId() {
				return this.$store.getters.getRoomId
		},
		gid() {
				return this.$store.getters.getGid
		},
		owner() {
			return this.$store.getters.getOwner
		},
		userOwner() {
			if (this.$store.getters.getUser.uid === this.$store.getters.getOwnerUid) {
				return this.$store.getters.getUser
			}

			return this.$store.getters['_users/getUsers'].find(user => user.uid === this.$store.getters.getOwnerUid)
		}
	},
	methods : {
		async searchMembersOfGroup() {
			if (this.loading) {
				return
			}

			this.loading = true
			try {
				const ref = await this.$store.dispatch("getMembersOfGroupLimit", { roomId : this.roomId, latesUser : this.latesUser  })
				const data = await ref.get()
				this.empty = data.empty
				if (!this.empty) {
					this.users = [...this.users, ...data.docs.map((doc) => {
						return this.$store.getters['_users/getUsers'].find(u => u.uid === doc.id)
					})]
					this.latesUser = data.docs[data.docs.length - 1]
				}
			} catch (error) {
				console.error(error)
			} finally {
				this.loading = false
			}
		},
		closeModal() {
			this.users = []
			this.loading = false
			this.latesUser = null
		},
		userDeleted(user) {
			this.users = this.users.filter(_user => _user.uid !== user.uid)
			this.closeOnClickModal = true
		}
	},
}
</script>

<style scoped>
	ul {
		margin-top: var(--column);
	}

	ul li {
		list-style: none;
		margin-bottom: var(--column);
		width: 100%;
	}

	h2 {
		text-align: center;
	}

	h2 + div {
		margin-top: var(--column);
		text-align: center;
	}

	div p {
		text-align: center;
		margin-bottom: var(--column);
	}
</style>