const state = {
	chatList : [],
	messages : {},
	chatListListeners : {},
	openedChats : []
};

const getters = {
	getChatList : (state) => state.chatList,
	getOpenedChats : (state) => state.openedChats
};

const mutations = {
	addToChatList : (state, chat ) => (state.chatList.push(chat)),
	modifiedChatroom : (state, chat ) => {
		state.chatList.forEach((chatroom, index) => {
			if (chat.room == chat.room) {
				if ("owner" in chat) {
					state.chatList[index].data.owner = chat.owner;
				}

				if ("join" in chat ){
					state.chatList[index].data.join = chat.join;
					if (!chat.join) {
						let roomId = '@' + chat.room;
						if (roomId in state.chatListListeners) {
							state.chatListListeners[roomId]()
							delete state.chatListListeners[roomId]
						}
					}
				}
			}
		});
	},
	removeChatroom : (state, id) => {
		const index = state.chatList.findIndex(msg => msg.id == id);
		if (index > -1) {
			state.chatList.splice(index, 1)
		}
	},
	removeChatbox(state, chat) {
		const index = state.openedChats.findIndex(msg => msg.id == chat.id);
		if (index > -1) {
			state.openedChats.splice(index, 1)
		}
	},
	addUserToChat : (state, { room, user }) => {
		state.chatList.forEach((chat, index) => {
			if (chat.room === room) {
				state.chatList[index].data.name = user.fullname;
				state.chatList[index].data.user = user;
			}
		})
	},
	updateLastMessageChatList : (state, { roomId, message }) => {
		state.chatList.forEach((chat, index) => {
			if (chat.roomId === roomId || parseInt(chat.roomId.replace(/\D/g,'')) === parseInt(roomId)) {
				state.chatList[index].data.lastMessage = message
			}
		});
	},
	searchRoomAndSetMessageNotReaded : (state, {roomId, count}) => {
		setTimeout(() => {
			state.chatList.forEach((chat, index) => {
				if (chat.roomId === roomId) {					
					state.chatList[index].hasMessagesNotReaded = count;
				}
			});
		}, 250);
	},
	sort(state) {
		if (state.sortBy === "alphabet") {
			state.chatList.sort(function(a, b) {
				let textA = a.data.name.toUpperCase();
				let textB = b.data.name.toUpperCase();
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
			});
		} else {
			state.chatList.sort(function(a, b) {
				return new Date(b.data.lastMessage.date) - new Date(a.data.lastMessage.date);
			});
		}
	},
	addMessageToChatroom(state, { roomId, message }) { 
		const lastMessage = state.messages[roomId][state.messages[roomId].length - 1];
		if (lastMessage) {
			let push = addToMessage(lastMessage, message);
			if (!push) {
				state.messages[roomId].push(message) 
			} else {
				lastMessage.text += `<br>${message.text}`; 
			}
		} else {
			state.messages[roomId].push(message) 
		}
	},
	addPreviousMessageToChatroom(state, { roomId, message }) {
		const firstMessage = state.messages[roomId][0];
		if (firstMessage) {
			let unshift = addToMessage(firstMessage, message);
			if (!unshift) {
				state.messages[roomId].unshift(message)
			} else {
				firstMessage.date = message.date;
				firstMessage.text = ` ${message.text}<br>${firstMessage.text}`;
			}
		} else {
			state.messages[roomId].unshift(message)
		}
	},
	removeChatListener(state, chat) {
		if (chat.roomId in state.chatListListeners) {
			state.chatListListeners[chat.roomId]()
			delete state.chatListListeners[chat.roomId]
		}
	},
	pushOpenedChat : (state, chat) => { state.openedChats.unshift(chat);} ,
	setMessagesOfChatroom : (state, roomId) => (state.messages[roomId] = []), 
	addNewMessagesListener : (state, { roomId, ref }) => (state.chatListListeners[roomId] = ref),  
	updateSorBy : (state, sort) => { state.sortBy = sort; localStorage.setItem('user-list-sort-by', sort) }, 
	cleanChatBoxs : (state) => (state.openedChats = [])
};

const actions = {
	getUserFromChatByRoomId({rootState}, roomId ) {
		return new Promise((resolve, reject) => {
			const chats = rootState.chats.chatList;
			for(let index in chats) {
				if (chats[index].roomId === roomId) {
					return resolve(chats[index].data.user.uid)
				}
			}
			return reject()
		});
    },
	createNewChatroom({ rootGetters }, id) {
		return new Promise(function(resolve) {
			const user = rootGetters.getUser
			const firebase = rootGetters.getFirebase
			const room = new Date().getTime();
			firebase.firestore().collection("chatrooms").doc("users").collection(user.uid).doc(id).set({ room: room }).then(() => {
				firebase.firestore().collection("chats").doc(room + "-").set({ createdAt: new Date().getTime(), participants : [user.uid, id] }).then(() => {
					resolve({ id: id,room: room,});
				});
			});
		});
	},
	listenChatrooms({ dispatch, rootGetters }) {
		const user = rootGetters.getUser;
		const firebase = rootGetters.getFirebase;
		firebase.firestore().collection("usersgroups").doc("users").collection(user.uid).onSnapshot((chatSnapshot) => {
			dispatch('manageChatrooms',chatSnapshot);
		});
		firebase.firestore().collection("chatrooms").doc("users").collection(user.uid).onSnapshot((chatSnapshot) => {
			dispatch('manageChatrooms',chatSnapshot);
		});
	}, 
	getLastMessageFromChatroom({rootGetters}, {roomId, timestamp = null}) {
		roomId = (/-/.test(roomId)) ? roomId : '@' + roomId;
		roomId = (/@@/.test(roomId)) ? roomId.replace('@','') : roomId;
 
		const firebase = rootGetters.getFirebase;
		if (!timestamp) {
			return firebase.firestore().collection("chats").doc(roomId).collection("messages").orderBy("date", "desc").limit(1).get()
		}
		return firebase.firestore().collection("chats").doc(roomId).collection("messages").where('date','<', timestamp).orderBy("date", "desc").limit(1).get()
	},
	manageChatrooms({ dispatch, commit }, chatSnapshot) {
		for (const change of chatSnapshot.docChanges()) {
			const chat = change.doc.data();
			chat.id = change.doc.id;
			chat.roomId = ('owner' in chat) ? '@' + chat.room : chat.room + '-';
			if (change.type === "added") {
				dispatch('addChatroom', chat);
				if ('join' in chat) {
					if (chat.join) {
						dispatch('listenNewMessagesFromChatroom', { chatroom : chat});
					}
				} else {
					dispatch('listenNewMessagesFromChatroom', { chatroom : chat});
				}
			}

			if (change.type === "modified") {
				commit('modifiedChatroom', chat);
			}

			if (change.type === "removed") {
				commit('removeChatroom', chat.id);
				commit('removeChatListener', chat)
			}
		}
	},
	addChatroom({ commit, dispatch, rootGetters }, chat) {
		const user = rootGetters.getUser;
		const users = rootGetters.getUsers;
		const type = ('owner' in chat) ? 'group' : 'user';
		const roomId = (type === 'group') ? `@${chat.room}` : `${chat.room}-`;
		let data = (type == 'group') ? { name: chat.name , type: "group", owner : chat.owner, join : chat.join,  active : false } : { type : 'user', user : {}, active : false }
		
		let timestamp = null;
		if ('lastMessageDate' in chat && !chat.join) {
			data.lastMessageDate = parseInt(chat.lastMessageDate);
			timestamp = parseInt(chat.lastMessageDate);
		}
		
		dispatch('getLastMessageFromChatroom', { roomId : roomId, timestamp : timestamp }).then((messageSnapshot) => {
			const message = (!messageSnapshot.empty) ? messageSnapshot.docs[0].data() : { text : '', date : 14182940}
			data.lastMessage = typeOfMessage(message, user, users);
			commit('addToChatList', { id : chat.id, roomId : roomId, room : chat.room, type : type, data : data, hasMessagesNotReaded: 0 });
			commit('sort');
			if (type === 'user') {
				dispatch('fetchUserFromFirebase', chat.id).then((snapshot) => {
					const data = snapshot.val();
					if (data) {
						if (data.fullname && data.status) {
							commit('addUserToChat', { roomId : roomId, room : chat.room, user : { fullname: data.fullname, status: data.status, extension: data.extension, uid : chat.id, picture : data.picture } });
							commit('setMessagesOfChatroom', roomId);
						}
					}
				});
			} else {
				commit('setMessagesOfChatroom', roomId);
			}
		});
	},
	canJoin({ rootGetters }, {id, type }) {
		const user = rootGetters.getUser;
		const firebase = rootGetters.getFirebase;
		return new Promise((resolve) => {
			if (type === 'group') {
				let join = false;
				setTimeout(() => {
					firebase.firestore().collection("usersgroups").doc("users").collection(user.uid).doc(id).get().then((snapshot) => {
						if (snapshot.exists) {
							join = snapshot.data().join;
						}
						resolve(join)
					});
				}, 1000);
			} else {
				resolve(true)
			}
		})
	},
	fetchMessagesFromChatroom({ rootGetters, dispatch, commit }, { chatroom , history, timestamp = null } ) {
		const firebase = rootGetters.getFirebase;
		return new Promise((resolve) => {
			let promise;

			if (!history) {
				commit('setMessagesOfChatroom', chatroom.roomId);
				promise = firebase.firestore().collection("chats").doc(chatroom.roomId).collection("messages").orderBy("date", "desc").limit(30).get()
			} else {
				if (!timestamp) {
					timestamp = state.messages[chatroom.roomId][0].date;
				} 				
				promise = firebase.firestore().collection("chats").doc(chatroom.roomId).collection("messages").where('date','<', timestamp).orderBy("date", "desc").limit(30).get()
			}

			promise.then((collection) => {
				collection.docs.forEach((doc) => {
					const message = doc.data();
					message.id = doc.id;
					if (message.text !== '') {
						dispatch('manageMessage', {message, roomId : chatroom.roomId, type : 'old'});
					}
				});
				resolve()
			});
		});
	},
	manageMessage({ commit, rootGetters }, { message, roomId, type }) {
		const user = rootGetters.getUser;
		const users = rootGetters.getUsers;
		const commitName = (type === 'old') ? 'addPreviousMessageToChatroom' : 'addMessageToChatroom';
		if (message.from !== 'system' && message.from !== "systemMessage") {
			message.type = 'user';
			
			if (message.from === user.uid) {
				message.user = user;
			}

			users.forEach((user) => {
				if (message.from === user.uid) {
					message.user = user;
				}
			});
		} else {
			message.type == message.type || 'system';
		}

		message = typeOfMessage(message, user, users);

		commit(commitName, { message, roomId })
	},
	manageLastMessage({commit, rootGetters}, {message, roomId}) {
		const user = rootGetters.getUser;
		const users = rootGetters.getUsers;
		message = typeOfMessage(message, user, users);
		commit('updateLastMessageChatList', { message, roomId });
		commit('sort');
	},
	listenNewMessagesFromChatroom({ rootGetters, dispatch, commit }, { chatroom, callback } ) {
		if (!(chatroom.roomId in state.chatListListeners) || chatroom.roomId in state.chatListListeners && callback) {
			const user = rootGetters.getUser;
			const users = rootGetters.getUsers;
			const firebase = rootGetters.getFirebase;
			const ref = firebase.firestore().collection("chats").doc(chatroom.roomId).collection("messages").where("date", ">", new Date().getTime()).onSnapshot((snapshot) => {
				for (const change of snapshot.docChanges()) {
					if (change.type === "added") {
						let message = change.doc.data();
						message.id = change.doc.id;
						if (message.text !== '') {
							if (callback) {
								callback()
							}
							message = typeOfMessage(message, user, users);

							if (!callback) {
								dispatch('manageMessage', { message, roomId : chatroom.roomId});
								commit('updateLastMessageChatList', { message, roomId : chatroom.roomId});
								commit('sort');
							}
						}
					}
				}
			});
			commit('addNewMessagesListener', { roomId : chatroom.roomId , ref });
		}
	},
	saveMessage({ rootGetters }, { roomId, message }) {
		const user = rootGetters.getUser;
		const firebase = rootGetters.getFirebase;
		const msg = { from: user.uid, text: message, date: new Date().getTime(), readed: false, user_id : user.uid }
		firebase.firestore().collection("chats").doc(roomId).collection("messages").add(msg)
	},
	updateSort({ commit }, sort) {
		commit("updateSorBy", sort);
		commit("sort")
	},
	notifyUserNewChatroom({ rootGetters }, data) {
		const uid = rootGetters.getUser.uid;
		const firebase = rootGetters.getFirebase
		let notification = {}
		notification[`newChatRoom@${uid}`] = data.roomId;
		firebase.database().ref("users").child(data.uid).update(notification);
	},
	notificationNewMessage({ commit }, { roomId, count }) {
		commit('searchRoomAndSetMessageNotReaded', { roomId, count });
	},
	cleanNotificationNewMessage({ commit, rootGetters}, roomId) {
		const user = rootGetters.getUser
		const firebase = rootGetters.getFirebase
		commit('searchRoomAndSetMessageNotReaded', { roomId : roomId, count : 0 });
		firebase.database().ref('notifications').child(user.uid).child(`newMessage@${roomId}`).remove();
	},
	getMessagesNotificationFromUser({ rootGetters }, {uid, by}) {
		const firebase = rootGetters.getFirebase;
		return firebase.database().ref('users/' + uid + '/notifications/' + by).once('value');
	},
	updateNotificationNewMessage({ rootGetters }, { uid, index, count }) {
		const firebase = rootGetters.getFirebase;
		let data = {}
		data[index] = count; 
		return firebase.database().ref('users/' + uid + '/notifications').update(data);
	},
	addToOpenedChats({ commit }, chat) {
		if (!state.openedChats.find(c => c.id === chat.id)) {
			commit('pushOpenedChat', chat);
		}
	},
	closeChatBox({commit}, chat) {
		commit('removeChatbox', chat);
		commit('removeChatListeners', chat);
	},
	cleanOpenedChats({commit}) {
		const chats = state.openedChats;
		for(let index in chats) {
			commit('removeChatListeners', chats[index]);
		}
		commit('cleanChatBoxs');
	},
	joinChat({rootGetters}, {id, roomId, index}) {
		let data = {};
		const user = rootGetters.getUser;
		const firebase = rootGetters.getFirebase;
		data[index] = null;
		firebase.firestore().collection("chatrooms").doc("users").collection(user.uid).doc(id).set({ room: roomId }).then(() => {
			firebase.database().ref('users').child(user.uid).update(data);
		});
	},
	// sendSystemMessageToChatroom({rootGetters}, { roomId, data }) {
	// 	const firebase = rootGetters.getFirebase;
	// 	const base = {
	// 		from: 'system',
	// 		date: new Date().getTime(),
	// 	}
	// 	firebase.firestore().collection("chats").doc(roomId).collection("messages").add({...base, ...data});
	// }
}

function typeOfMessage (message, user, _users) {
	const users = [..._users]
	users.push(user);
	message.messageType = message.messageType || 'text';
	if (/^https/.test(message.text)) {
		message.messageType = 'link';
	}

	if (/^https:\/\/firebasestorage.googleapis.com/.test(message.text)) {
		message.messageType = 'file';
		const match = message.text.match(/\d+_[A-zA-Z0-9._]+\?/)
		if (match) {
			message.filename =  match[0].split(/\d+_/).pop().replace(/\?$/,"");
		}

		if (/(png|jpg|jpeg)/.test(message.text)) {
			message.messageType = 'image';
		}
	}

	if (message.messageType === 'group_created') {
		if (user.uid === message.createdBy) {
			message.translationKey = 'chat.you-created-this-group';
		} else {
			let u = users.find(u => u.uid === message.createdBy);
			message.name = (u) ? u.fullname : '@' + message.createdBy;
			message.translationKey = 'chat.created-this-group';
		}
	}

	if (message.messageType === 'add_to_group') {
		let u = users.find(u => u.uid === message.name);
		message.name = (u) ? u.fullname : '@' + message.name;
		message.translationKey = 'chat.add_to_group';
	}


	if (message.messageType === 'kick') {
		let u = users.find(u => u.uid === message.name);
		if (user.uid === message.owner) {
			message.name = (u) ? u.fullname : '@' + message.name;
			message.translationKey = 'chat.you-kick-user';
		} else {
			let o = users.find(u => u.uid === message.owner);
			message.name = (u) ? u.fullname : '@' + message.name;
			message.owner = (o) ? o.fullname : '@' + message.owner;
			message.translationKey = 'chat.kicked-user';
		}
	}

	if (message.messageType === 'leave') {
		if (user.uid === message.name) {
			message.translationKey = "chat.you-leave-the-group"
		} else {
			let u = users.find(u => u.uid === message.name);
			message.name = (u) ? u.fullname : '@' + message.name;
			message.translationKey = "chat.leave-the-group";
		}
	}

	if (message.messageType === 'new_owner') {
		if (user.uid === message.name) {
			message.translationKey = "chat.you-are-the-new-owner";
		} else {
			let u = users.find(u => u.uid === message.name);
			message.name = (u) ? u.fullname : '@' + message.name;
			message.translationKey = "chat.is-the-new-owner";
		}
	}

	if (message.messageType === 'start_callgroup') {		
		if (user.uid === message.name) {
			message.translationKey = "chat.you-have-init-videocall";
		} else {
			let u = users.find(u => u.uid === message.name);
			message.name = (u) ? u.fullname : '@' + message.name;
			message.translationKey = 'chat.init-videocall';
		}
	}

	if (message.messageType === 'lost_videocall') {		
		if (user.uid === message.call_from) {
			let u = users.find((u) => {
				return u.uid === message.call_to
			});
			message.name = u.fullname;
			message.translationKey = 'videocall.has-lost-videocall';	
			message.callback = true;
		} else {
			if (users) {
				let u = users.find((u) => {
					return u.uid === message.call_from
				});
				message.name = u.fullname;
				message.translationKey = 'videocall.you-have-a-lost-videocall';
				message.returnCall = true;
			}
		}
	}
	return message;
}

function addToMessage(message, newMessage) {
	if (message.from !== "system" || message.from !== "systemMessage") {
		if ('user' in message) {
			if (message.user.uid === newMessage.from && message.messageType === newMessage.messageType) {
				const messageDate = new Date(message.date)
				const newMessageDate = new Date(newMessage.date);
				if (messageDate.getMonth() == newMessageDate.getMonth() && messageDate.getDate() == newMessageDate.getDate() && messageDate.getFullYear() == newMessageDate.getFullYear()) {
					if (messageDate.getHours() == newMessageDate.getHours() && messageDate.getMinutes() == newMessageDate.getMinutes()) {
						return true;
					}
				}
			}
		}
	}
	return false;
}


export default {
	state,
	getters,
	mutations,
	actions,
};