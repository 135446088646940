<template>
  <el-tooltip
    class="item"
    effect="dark"
    :content="$t('common.download-file', { file : name })"
    placement="top-start"
  >
    <i class="fas fa-arrow-circle-down" @click="onClick"></i>
  </el-tooltip>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name : "DownloadButton",
  props : {
    url : {
      required : true,
      type : String,
    },
    name : {
      required : true,
      type : String,
    },
    file: {
      required: true,
    }
  },
  methods : {
    ...mapGetters(['getFirebase']),
    onClick() {
      if (this.file.isExternalFile) {
        const a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          a.href = this.url;
          a.target = "_blank"
          a.click();
      } else {
        fetch(this.url)
        .then(response => response.blob())
        .then((blob) => {
          const a = document.createElement("a");
          a.style = "display: none";
          document.body.appendChild(a);
          const _url = window.URL.createObjectURL(blob);
          a.href = _url;
          a.download = this.name
          a.click();
          window.URL.revokeObjectURL(_url);
        });
      }
    }
  },
};
</script>

<style scoped>
.fas {
  cursor: pointer;
}

.fas:hover {
  color: var(--blue);
}
</style>