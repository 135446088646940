<template>
  <div class="event" >
    <p class="mb-0">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name : "TheThreadEvent",
  props : {
    event : {
      required : true
    },
    previous : {
      required : false,
    }
  },
  computed : {
    eventName() {
      return this.event.event?.name ?? ''
    },
    message() {
      let message = '';

      switch (this.eventName) {
        default :
          message = this.eventName;
        break;
        case 'transferred':
          message = `${this.event.by.name} transfirio a ${this.event.to.name}`
          break;
        case 'created':
          message = `Ticket creado por ${this.event.by.name}`;
          break;
        case 'closed':
          message = `${this.event.by.name} cerro el ticket`;
          break;
        case 'assigned':
          if ('claimed_by' in this.event) {
            message = `${this.event.claimed_by.name} reclamo el ticket`
          }
          if ('assigned_by' in this.event && 'assigned_to' in this.event) {
            const name = this.event.assigned_to.name ?? this.event.assigned_to
            message = `${this.event.assigned_by.name} asigno el ticket a ${name}`;
          }
          break;
        case 'overdue':
          message = `El sistema fue marcado como atrasado por ${this.event.by}`;
          break;
        case 'collab':
          message = `${this.event.added_by.name} agrego a ${this.event.collab_added.name} como colaborador`;
          break;
        case 'edited':
          if ('status_changed_to' in this.event) {
            message = `${this.event.by.name} cambio el estado a ${this.event.status_changed_to}`;
          }

          if ('priority_changed' in this.event) {
            message = `${this.event.by.name} actualizo, ${this.event.priority_changed.label} de ${this.event.priority_changed.from} a ${this.event.priority_changed.to}`
          }

          if ('added' in this.event) {
            let added = '';
            for(let index in this.event.added) {
              added += `, ${index} marco ${this.event.added[index]}`;
            }
            message = `${this.event.by.name} actualizo el ticket. ${added}`;
          }
          break;
        case 'reopened':
          message= `${this.event.by.name} reabrio el ticket`;
            break;
        case 'referred':
          message = `${this.event.by.name} refirio este ticket a ${this.event.to.name}`
          break;
        case 'merged':
          message = `${this.event.by.name} mergio este ticket con ${this.event.ticket}`
          break;
      }

      return message;
    },
    created() {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const created = new Date(this.event.createdAt)
      return created.toLocaleString('es-ES', { timeZone })
    }
  }
}
</script>
