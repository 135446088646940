<template>
    <div>
      <ul>
        <li>
          <span class="label">{{ $t("configuration.users.name") }}</span>
          <span class="user-data">{{ user.name }}</span>
        </li>
        <li v-if="user.extension">
          <span class="label">{{ $t("configuration.users.extensions") }}</span>
          <span class="user-data">{{ user.extension }}</span>
        </li>
        <li>
          <span class="label">{{ $t("configuration.users.lastname") }}</span>
          <span class="user-data">{{ user.lastname }}</span>
        </li>
        <li>
          <span class="label">{{ $t("configuration.users.roles") }}</span>
          <MultiTags :data="roles" size="medium"></MultiTags>
        </li>
        <li>
          <span class="label">{{ $t("configuration.users.email") }}</span>
          <span class="user-data">{{ user.email }}</span>
        </li>
        <li>
          <span class="label">{{ $t("configuration.users.organizations") }}</span>
          <MultiTags :data="organizations" size="medium"></MultiTags>
        </li>
      </ul>
    </div>
</template>

<script>
import MultiTags from "@/components/common/MultiTags"
export default {
  props : {
    user : {
      required : true
    }
  },
  computed : {
    organizations() {
      return this.user.organizations.map((organization) => ({ value: organization.name }))
    },
    roles() {
      return this.user.roles.map((rol) => ({ value: rol }))
    }
  },
  components : {
    MultiTags
  }
}
</script>

<style scoped>
.user-information {
  align-items: center;
  display: inline-flex;
  justify-content: space-around;
  width: 100%
}

ul {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--column);
  grid-auto-rows: minmax(80px, auto);
  list-style: none;
  margin-top: var(--column);
}

.label {
  color: var(--grey);
  display: block;
  margin-bottom: var(--column);
}

li .label + .user-data {
  color: var(--blue-grey);
}

.basic {
  text-align: center;
}

</style>