
const state = {
  videocalls: []
}

const getters = {
  getVideocalls: (state) => state.videocalls,
  getActiveVideocalls: (state) => state.videocalls.filter(videocall => videocall.active),
  getVideocallByRoomId : (state) => (id) => (state.videocalls.find(videocall => videocall.id === id))
}
const mutations = {
  ADD_VIDEOCALL : (state, videocall) => (state.videocalls.unshift(videocall)),
  REMOVE_VIDEOCALL : (state, id) => state.videocalls = state.videocalls.filter(videocall => videocall.id !== id),
  UPDATE_VIDEOCALL : (state, videocall) => (state.videocalls.map(_videocall => _videocall.id === videocall.id ? videocall : _videocall))
}

const actions = {
  listenVideocall({ rootGetters, commit }, id) {
    rootGetters.getFirebase.firestore().collection('videocalls').doc(id).onSnapshot((snap) => {
      if (snap.data()) {
        commit('UPDATE_VIDEOCALL', { ...snap.data(), id : id })
      } else {
        commit('REMOVE_VIDEOCALL', id)
      } 
    })
  },
  addVideocall({ rootGetters, commit, dispatch }, videocall) {
    rootGetters.getFirebase.firestore().collection('videocalls').doc(videocall.id).set(videocall)
    commit('ADD_VIDEOCALL', videocall)
    dispatch('listenVideocall', videocall.id)
  },
  removeVideocall({ commit }, id) {
    commit("REMOVE_VIDEOCALL", id)
  },
  joinVideocall({ rootGetters }, id ) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    firebase.firestore().collection('videocalls').doc(id).update({
      participants : firebase.firestore.FieldValue.arrayUnion(user.uid)
    })
  },
  leftVideocall({ rootGetters }, id) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    firebase.firestore().collection('videocalls').doc(id).update({
      participants : firebase.firestore.FieldValue.arrayRemove(user.uid)
    })
  },
  getVideocall({ rootGetters }, id ) {
    const firebase = rootGetters.getFirebase
    return firebase.firestore().collection('videocalls').doc(id).get()
  },
  setInVideocall({ rootGetters}, roomId) {
    const user = rootGetters.getUser
    const firebase = rootGetters.getFirebase
    const ref = firebase.database().ref("videocalls").child(roomId).child(user.uid)
    ref.set({ active : true, last_changed: firebase.database.ServerValue.TIMESTAMP })
    ref.onDisconnect().remove()
  }

}

export default {
  state,
  getters,
  mutations,
  actions
}