<template>
  <div class="agent-dashboard" v-if="agents.length > 0">
    <!-- <the-small-agent-card v-for="agent in agents" :key="agent.uid" :agent="agent" :stats="false" :show-status="false" class="agent"></the-small-agent-card> -->
    <the-agent-card v-for="agent in agents" :key="agent.uid" :agent="agent" :stats="false" :show-status="true" :show-actions="false" class="agent"></the-agent-card>
  </div>
  <div v-else>
    <p>{{ $t("agent-console.dashboard.no-agents-connected") }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TheAgentCard from '@/components/dashboard/TheAgentCard';
export default {
    name : "AgentDashboard",
    computed : {
      ...mapGetters({
        tmpagents : 'getAgentsDashboard',
        agent : 'getAgent',
      }),
      organizationId() {
        return this.agent.organization_id
      },
      agents() {
        return this.tmpagents
          .filter(agent => agent.number !== undefined && agent.number != this.agent.number)
          .filter(agent => agent.call_logged || agent.chat_logged)
      }
    },
    components : {
      TheAgentCard,
    }
}
</script>

<style scoped>
.agent-dashboard {
  height: 90vh;
  overflow-y: auto;
}

.agent-card {
  margin-bottom: var(--column);
  width: 200px;
}
.agent-card >>> i {
  font-size: 20px;
}

.agent {
  margin-bottom: var(--column);
  width: 257px;
}
</style>