<template>
<div class="crud">
  <the-crud :title="title" :store="store" :columns="columns" :canDelete="true" :search="search">
  </the-crud>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheCrud from '@/components/crud/TheCrud'
export default {
  components : {
    TheCrud
  },
  data() {
    return {
      closeModal : true,
      server : null,
      confirmationValue: '',
      confirmationMessage: '',
      title : this.$t("configuration.servers.firebase.title"),
      store : 'firebases',
      search : ['name'],
      columns : [
        { index : 'id', label : '#id', sortable : true},
        { index : 'name', label : this.$t('configuration.servers.name') },
        { index : 'firebaseConfigFilename', label : this.$t('configuration.servers.firebase.filename'), 'hide in table': true },
        { index : 'serviceAccountFileName', label : this.$t('configuration.servers.firebase.account_filename'), 'hide in table': true },
        { index : 'cloudFunctionRegion', label : this.$t('configuration.servers.firebase.cloud_region') },
      ],
    }
  },
  computed : {
    ...mapGetters(['isAdmin']),
  }
}
</script>

<style>

</style>