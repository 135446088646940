<template>
  <section>
    <h2>{{ $t("customer.add-customer") }}</h2>
    <el-form :model="customer" :rules="rules" v-if="isValidActivity" v-loading="submittingForm" ref="form">
      <el-form-item v-for="input in inputs" :key="input.key" :label="input.label" :prop="input.key">
        <el-input v-model="customer[input.key]" v-if="input.type !== 'select'"/>
        <el-select v-model="customer[input.key]" style="width: 100%;" v-else filterable >
						<el-option v-for="option in input.options" :key="option.value" :label="option.label" :value="option.value" >
              <span>
                <img :src="option.image" width="15" v-if="option.label" lazy style="margin-right: 8px">
                <span>{{ option.label }}</span>
              </span>
            </el-option>
				</el-select>
      </el-form-item>
      <el-button type="primary" @click="handleSubmit">{{ $t("common.save") }}</el-button>
    </el-form>
    <p v-else>
      {{ $t("customer.must-select-a-chat-to-add-a-client")}}
    </p>
  </section>
</template>

<script>
import { EventBus } from '@/plugins/event-bus.js';
export default {
  data () {
    return {
      rules: {},
      customer: {},
      inputs: [],
      submittingForm: false
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.form.validate(async (isValid) => {
        if (!isValid) return
        this.submittingForm = true
        const chatId = this.activeClient.key
        const keys = Object.keys(this.customer)
        const values = this.inputs.map(input => {
          input.value = !keys.includes(input.key) ? '' : this.customer[input.key]
          delete input.isRequired
          return input
        })
        const customer = { values, chatsId: [chatId] }

        const promisees = values.filter(value => value.type === 'phone').map(async ({ value }) => {
          const exists = await this.$store.dispatch('customers/fetchCustomerByPhone', { organizationId: this.organizationId, phone: value })
          return exists ? true : false
        })

        const customerExists = await Promise.all(promisees);

        if (!customerExists.includes(true)) {
          await this.$store.dispatch('customers/addCustomer', { organizationId: this.organizationId, customer })
          this.$message({ message: this.$t('customer.the-customer-was-saved'), type: 'success' })
          EventBus.$emit('newCustomer')
        } else {
          this.$message({ message: this.$t('customer.the-customer-already-exists-with-that-number') })
        }

        this.submittingForm = false  
      })
    }
  },
  computed: {
    organizationId () {
      return this.$store.getters["getAgent"].organization_id.toString()
    },
    activeClient () {
      return this.$store.getters['getActiveItem']
    },
    isValidActivity () {
      return this.activeClient && this.activeClient.type === 'chat'
    }
  },
  created () {
    const inputs = this.$store.getters["customers/getCustomerForm"].sort((a, b) => a.order - b.order)
    this.inputs = inputs
    this.inputs.forEach((input) => {
      if (input.isRequired) {
        this.rules[input.key] = [
          { required: true, trigger: 'blur' },
        ]
      }
    })
  }
}
</script>

<style scoped>
section {
  max-height: 100vh;
  overflow: auto;
  padding: 0 calc(var(--column) / 2);
}
</style>