<template>
	<el-table :data="records" style="width: 100%" stripe size="medium" :cell-style="{ textAlign : 'center'}" :header-cell-style	="{ textAlign : 'center'}">
		<el-table-column fixed width="300" prop="queue" :label="$t('queues-dashboard.table.queue')" sortable >
			<template slot-scope="scope">
        <i class="fas fa-headset" v-show="scope.row.isQueueAgent"></i>
        <span style="margin-left: 10px">{{ scope.row.name }} ({{ scope.row.queue }})</span>
      </template>
		</el-table-column>
		<el-table-column :label="$t('queues-dashboard.table.calls')">
			<el-table-column prop="inCurseCalls" :label="$t('queues-dashboard.table.inCurseCalls')" sortable min-width="80"/>
			<el-table-column prop="waitingCalls" :label="$t('queues-dashboard.table.waitingCalls')" sortable min-width="80">
				<template slot-scope="scope">
					<span :style="{ fontWeight : 'bold', fontSize: '16px'}">{{ scope.row.waitingCalls }}</span>
			</template>
			</el-table-column>
		</el-table-column>
		<el-table-column :label="$t('queues-dashboard.table.agents')">
			<el-table-column prop="totalAgents" :label="$t('queues-dashboard.table.totalAgents')" sortable min-width="150" />
			<el-table-column prop="onlineAgents" :label="$t('queues-dashboard.table.onlineAgents')" sortable min-width="200"/>
			<el-table-column prop="totalAgentsInBreak" :label="$t('queues-dashboard.table.totalAgentsInBreak')" sortable min-width="200"  />
			<el-table-column prop="totalAgentsBusy" :label="$t('queues-dashboard.table.totalAgentsBusy')" sortable min-width="200"  />
			<el-table-column prop="totalAgentsAvaliables" :label="$t('queues-dashboard.table.totalAgentsAvaliables')" sortable min-width="200"  />
		</el-table-column>
		<el-table-column prop="sla" :label="$t('queues-dashboard.table.sla')" sortabl min-width="80">
			<template slot-scope="scope">
        {{ scope.row.sla }}%
      </template>
		</el-table-column>
		<el-table-column v-if="isSelected('incomingCalls')" prop="incomingCalls" :label="$t('queues-dashboard.table.incomingCalls')" sortable width="120"/>
		<el-table-column v-if="isSelected('attendedCalls')" prop="attendedCalls" :label="$t('queues-dashboard.table.attendedCalls')" sortable width="120"/>
		<el-table-column v-if="isSelected('unAttendedCalls')" prop="unAttendedCalls" :label="$t('queues-dashboard.table.unAttendedCalls')" sortable width="180"/>
		<el-table-column v-if="isSelected('abandonCalls')" prop="abandonCalls" sortable :label="$t('queues-dashboard.table.abandonCalls')" width="180"/>
		<el-table-column v-if="isSelected('transferCalls')" prop="transferCalls" :label="$t('queues-dashboard.table.transferCalls')"  width="190"/> 
		<el-table-column v-if="isSelected('maxCalls')" prop="maxCalls" :label="$t('queues-dashboard.table.maxCalls')" sortable width="180" />
		<el-table-column v-if="isSelected('avgCalls')" prop="avgCalls" :label="$t('queues-dashboard.table.avgCalls')" sortable width="180" />
		<el-table-column v-if="isSelected('avgWaitCalls')" prop="avgWaitCalls" :label="$t('queues-dashboard.table.avgWaitCalls')" sortable width="230" />
		<el-table-column v-if="isSelected('porNeglectedCalls')" prop="porNeglectedCalls" :label="$t('queues-dashboard.table.porNeglectedCalls')" sortable width="220">
			<template slot-scope="scope">
        {{ scope.row.porNeglectedCalls }}%
      </template>
		</el-table-column>
		<el-table-column v-if="isSelected('porAttendedCalls')" prop="porAttendedCalls" :label="$t('queues-dashboard.table.porAttendedCalls')" sortable width="220" >
			<template slot-scope="scope">
        {{ scope.row.porAttendedCalls }}%
      </template>
		</el-table-column>
    <el-table-column>
      <template slot-scope="scope">
        <router-link 
          :to="{
            name: 'queuesDashboardDetails', 
            params: { 
              id: scope.row.queue,
              organization: scope.row.organizationId
            }
          }">
          ver
        </router-link>
      </template>
    </el-table-column>
	</el-table>
</template>

<script>
// poner en negrita las llamads en cola
// agregar los porcentajes donde corresponde
// una vez termino esto, hacer el merge
export default {
  name: "TheQueuesTable",
  props: {
    records: {
        required: true
    },
	columns:{
		required: true
	}
  },
  methods:{
	isSelected( col  ){
		return this.columns.includes( col );
	}
  }
}
</script>

<style>
.dashboard-cell {
	text-align: center;
}
</style>