<template>
	<div class="error">
		<svg
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			viewBox="0 0 200 200"
			style="enable-background:new 0 0 200 200;"
			xml:space="preserve"
		>
			<circle class="st0" cx="99.8" cy="100.3" r="100" />
			<image
				style="overflow:visible;"
				width="87"
				height="87"
				xlink:href="2E13502B.jpg"
				transform="matrix(1 0 0 1 213 106)"
			></image>
			<g>
				<rect
					x="89.8"
					y="49.8"
					transform="matrix(0.7071 0.7071 -0.7071 0.7071 100.1332 -41.1591)"
					class="st1"
					width="20"
					height="101"
				/>

				<rect
					x="89.8"
					y="49.8"
					transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 241.201 100.6751)"
					class="st1"
					width="20"
					height="101"
				/>
			</g>
		</svg>
	</div>
</template>

<script>
export default {
    name : "Error"
};
</script>

<style scoped>
.error {
	height: 150px;
	width: 150px;
}
	.st0 {
		fill: #f7646c;
	}
	.st1 {
		fill: #ffffff;
	}
</style>
