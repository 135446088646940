<template>
    <the-keypad-container :go-back="goBack">
      <template v-slot:header>
        <el-popconfirm v-if="history.length > 0"
          :title="$t('keypad.do-you-want-to-delete-the-call-history?')"
          :confirm-button-text="$t('common.yes')" :cancel-button-text="$t('common.no')"
          @onConfirm="deleteHistory"
        >
          <button :aria-label="$t('keypad.delete-call-history')" slot="reference"><i class="fas fa-trash" ></i></button>
        </el-popconfirm>
      </template>
      <template v-slot:container>
        <ul v-if="history.length > 0" class="list">
          <li v-for="(call, index) in history" :key="index"><TheCallHistoryItem :call="call" :janus="janus"></TheCallHistoryItem></li>
        </ul>
        <div v-else class="information center">
          <i class="fas fa-history"></i>
          <p>{{ $t("keypad.here-will-appear-the-calls") }}</p>
        </div>
      </template>
    </the-keypad-container>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'
import TheKeypadContainer from './TheKeypadContainer';
import TheCallHistoryItem from './TheCallHistoryItem';
export default {
  name : "TheCallHistory",
  props : {
    goBack : {
      required : true
    },
    janus : {
      required : false,
    }
  },
  methods : {
    ...mapActions(['cleanCallhistory']),
    deleteHistory() {
      this.cleanCallhistory('corpo')      
    }
  },
  computed : {
    ...mapGetters({
      user : 'getUser',
      callHistory : 'getCallHistory'
    }),
    history() {
      return this.callHistory('corpo')
    }
  },
  components : {
    TheCallHistoryItem,
    TheKeypadContainer
  }
}
</script>

<style scoped>
button {
  background-color: white;
  border: none;
  color: var(--blue-grey);
  outline: none;
}
</style>