<template>
  <the-form 
    @saveForm="handleSaveForm" 
    @cancel="handleGoBack"
    v-loading="saving" 
    element-loading-text="Saving form. Please wait..."
    :entity="entity" 
    :deleteFormInput="handleDeleteInput"
    v-if="status === 0"
    >
  </the-form>
  <div class="message" v-else>
    <div>
      <img src="@/img/success.svg" v-if="status === 200" />
      <img src="@/img/error.svg" v-else />
      <p> {{ message }} </p>
      <div class="buttons">
        <el-button type="text" @click="handleGoBack">{{ $t("common.go-back-to-the-list") }}</el-button>
        <el-button type="text" @click="status = null" v-if="status !== 404">{{ $t("common.show-the-form") }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import TheForm from './TheForm.vue'
export default {
  props: {
    entity: {
      required: false,
    },
    id: {
      required: false,
      type: String,
      default: "0"
    }
  },
  data () {
    return {
      saving: false,
      status: 0,
    }
  },
  components: {
    TheForm
  },
  computed: {
    organizationId () {
      return this.$store.getters["contactMe/getOrganizationId"];
    },
    edit () {
      return this.id !== "0"
    },
    message() {
      if (this.status === 200) {
        return this.$t("common.saved")
      }

      if (this.status === 404) {
        return this.$t("common.not-found")
      }

      return this.$t("common.not-saved")
    }
  },
  methods: {
    async handleSaveForm (form) {
      this.saving = true
      try {
        const action = !this.edit ? 'create' : 'update'
        const { name, description, inputs } = form
        const body = this.edit ? {
          id: this.id,
          payload: {
            name,
            description,
            organization: '/api/organizations/' + this.organizationId
          }
        } : {
          name,
          description,
          organization: '/api/organizations/' + this.organizationId
        }

        const response = await this.$store.dispatch(`forms/${action}`, body)

        const promises = inputs.map((input) => {
          if (typeof input.id === 'string') {
            delete input.id
            return this.$store.dispatch('formsInputs/create', { ...input, form: response['@id'] })
          }
          return this.$store.dispatch('formsInputs/update', { id: input.id, payload: { ...input, form: response['@id'] } })
        })

        await Promise.all(promises)

        await this.$store.dispatch('forms/getLastUpdatedForm', response.id)

        this.status = 200

      } catch (err) {
        this.status = err.status
      } finally {
        this.saving = false
      }
    },
    handleDeleteInput ({ id }) {
      return this.$store.dispatch('formsInputs/destroy', id)
    },
    handleGoBack() {
      this.$router.go(-1)
    }
  }

}
</script>

<style scoped>
.message {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.buttons {
  display: flex;
  flex-direction: column;
}
</style>