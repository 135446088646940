/* eslint-disable */

function windowStatus() {
  
  const _LOCALSTORAGE_KEY = 'WINDOW_VALIDATION'; 
  const RECHECK_WINDOW_DELAY_MS = 100;

  let _initialized = false;
  let _isMainWindow = false;
  let _unloaded = false;
  let _windowArray;
  let _windowId;
  let _isNewWindowPromotedToMain = false;
  let _onWindowUpdated;

  const start = (isNewWindowPromotedToMain, onWindowUpdated) => {
    _onWindowUpdated = onWindowUpdated;
    _isNewWindowPromotedToMain = isNewWindowPromotedToMain;
    _windowId = Date.now().toString();
    bindUnload();
    determineWindowState();
    _initialized = true;
    //_onWindowUpdated();
  }

  const determineWindowState = () => {
    const self = this;
    let _previousState = _isMainWindow;
    _windowArray = localStorage.getItem(_LOCALSTORAGE_KEY);

    if (_windowArray === null || _windowArray === "NaN") {
      _windowArray = [];
    } else {
      _windowArray = JSON.parse(_windowArray);
    }

    if (_initialized) {
      //Determine if this window should be promoted
      _isMainWindow = (_windowArray.length <= 1 || (_isNewWindowPromotedToMain ? _windowArray[_windowArray.length - 1] : _windowArray[0]) === _windowId) ? true : false
    } else {
      if (_windowArray.length === 0) {
        _isMainWindow = true;
        _windowArray[0] = _windowId;
        localStorage.setItem(_LOCALSTORAGE_KEY, JSON.stringify(_windowArray));
      } else {
        _isMainWindow = false;
        _windowArray.push(_windowId);
        localStorage.setItem(_LOCALSTORAGE_KEY, JSON.stringify(_windowArray));
      }
    }

    //If the window state has been updated invoke callback
    if (_previousState !== _isMainWindow){
      _onWindowUpdated(_isMainWindow);
    }

    setTimeout(function() { 
      determineWindowState();
    }, RECHECK_WINDOW_DELAY_MS);
  }

  //Remove the window from the global count
  const removeWindow = () => {
    let tmp = JSON.parse(localStorage.getItem(_LOCALSTORAGE_KEY));
    var __windowArray = tmp ? tmp : [];
    for (var i = 0, length = __windowArray.length; i < length; i++){
      if (__windowArray[i] === _windowId){
        __windowArray.splice(i, 1);
        break;
      }
    }
    //Update the local storage with the new array
    localStorage.setItem(_LOCALSTORAGE_KEY, JSON.stringify(__windowArray));
  }

  const bindUnload = () => {
    window.addEventListener('beforeunload', function () {
      if (!_unloaded) {
        removeWindow();
      }
    });
    window.addEventListener('unload', function () {
      if (!_unloaded) {
          removeWindow();
      }
    });
  }

  const setToMain = () => {
    _isNewWindowPromotedToMain = true
    _initialized = false;
    determineWindowState();
    _initialized = true;
  }

  return {
    start,
    setToMain
  }

}

export default windowStatus