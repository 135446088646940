import store from '../../store'
import auth from '../../api/auth'
import TheForgotPassword from "../../views/recover-password/TheForgotPassword"
import TheRecoverPassword from "../../views/recover-password/TheRecoverPassword"

export default [
  {
    name : "ForgotPassword",
    path : '/forgot-password',
    components : {
			appView : TheForgotPassword
		},
    beforeEnter: (to, from, next) => {
			if (!store.getters.isAuthenticated) {
				next()
			} else {
        next("/home")
      }
		}
  },
  {
    name : 'RecoverPassword',
    path : '/recover-password/:token',
    props: true,
    components : {
			appView : TheRecoverPassword
		},
    beforeEnter: async (to, from, next) => {
      const token = to.params.token

      try {
        await auth.checkRecoverPasswordToken(token)
        next()
      } catch (e) {
        next("/login")
      }
			
		}
  }
]