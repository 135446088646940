<template>
	<div id="app">
		<router-view name="appView"></router-view>
	</div>
</template>

<script>
export default {
	mounted() {
		this.$title = this.$t("main-menu.home")
	}
}
</script>
<style></style>
