<template>
  <div class="thread-item" :class="{ 'internal-note': thread.type === 'N' }">
    <div class="header">
      <UserItem :user="user" :showStatus="false" :showEmail="false" class="user-item"></UserItem>
      <p class="time">{{ created }} </p>
    </div>
    <p v-if="thread.type === 'N'">{{ thread.title }}</p>
    <div class="body mb-0" v-html="formatedBody" v-lazy-load></div>
    <TheFileAttachmentsDisplay v-if="files.length > 0" :files="files"></TheFileAttachmentsDisplay>
  </div>
</template>

<script>

import UserItem from '@/components/common/UserItem';
import TheFileAttachmentsDisplay from '@/components/ticket/TheFileAttachmentsDisplay';
import LazyLoad from '@/directives/lazyLoad.js';

export default {
  name: "TheThreadItem",
  props: {
    thread: {
      required: true
    }
  },
  components: {
    UserItem,
    TheFileAttachmentsDisplay
  },
  directives: {
    lazyLoad: LazyLoad
  },
  computed: {
    user () {
      return {
        fullname: this.thread.user_type === 'staff' ? this.thread.user.firstname + ' ' +  this.thread.user.firstname : this.thread.user.name,
        picture: ''
      }
    },
    files () {
      return this.thread.attachments.map(attachment => {
        attachment.type = attachment.type.split('/').shift()
        return attachment
      }) ?? []
    },
    created () {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      const created = new Date(this.thread.created)
      return created.toLocaleString('es-ES', { timeZone })
    },
    formatedBody() {
      const aux = {...this.thread};
      const replacements = {
        'Ã¡': 'á',
        'Ã©': 'é',
        'Ã­': 'í',
        'Ã³': 'ó',
        'Ãº': 'ú',
        'Ã±': 'ñ',
        'Ã¼': 'ü',
        'Â' : ' ',
        // eslint-disable-next-line vue/no-parsing-error
        'Ã': ' ',
        // eslint-disable-next-line vue/no-parsing-error
        'Â': ' ',
        // eslint-disable-next-line vue/no-parsing-error
        'â': ' ',
        // eslint-disable-next-line vue/no-parsing-error
        'â': ' '
      };

      const regex = new RegExp(Object.keys(replacements).join('|'), 'g');

      return aux.body.replace(regex, match => replacements[match] || match);
  }
  }
}
</script>

<style scoped>
.thread-item {
  padding: var(--column);
  background: #ffffff;
  border-top: 2px solid #dadcde;
}

.user-item {
  margin-bottom: var(--column);
}

.header {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.time {
  color: var(--dark-grey);
  font-size: 13px;
}

p {
  font-size: 14px;
}


.internal-note {
  background-color: #fffae9;
  border-top: 2px solid #ffc107;
}
</style>