<template>
  <div class="main-content">
    <div class="grid">
      <div class="card pbx">
        <h3>PBX</h3>
        <img src="@/img/undraw_contact_us_re_4qqt.svg" width="300"/>
        <el-button @click="goTo('contact-me-pbx')" :disabled="!pbxEnabled" style="margin-top: auto;">{{ $t("common.config") }}</el-button>
      </div>
      <div class="card pbx">
        <h3>Chat center</h3>
        <img src="@/img/undraw_online_chat_re_c4lx.svg" width="300"/>
        <el-button @click="goTo('contact-me-chat-center')">{{ $t("common.config") }}</el-button>
      </div>
    </div>
    <TheSelectOrganizationDialog></TheSelectOrganizationDialog>
  </div>
</template>

<script>
import TheSelectOrganizationDialog from "./TheSelectOrganizationDialog.vue";
export default {
  methods: {
    goTo(name) {
      this.$router.push({ name })
    }
  },
  components: {
    TheSelectOrganizationDialog,
  },
  computed: {
    pbxEnabled() {
      return this.$store.getters["contactMe/getPbxToken"]
    }
  },
  async beforeCreate() {
    const { pbxRestApi, pbxRestApiUsername, pbxRestApiPassword } = this.$store.getters["contactMe/getOrganization"]
    if (pbxRestApi && pbxRestApiUsername && pbxRestApiPassword) {
      if (!this.$store.getters["contactMe/getPbxToken"] && this.$store.getters["contactMe/getOrganizationId"]) {
        await this.$store.dispatch("contactMe/doLoginPbx");
      }
    }
  }
}
</script>

<style scoped>
.main-content {
  background-color: transparent;
  padding: 0;
}
.header {
  display: inline-flex;
}
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 0px;
}

.grid .card {
  display: flex;
  flex-direction: column;
  padding: var(--column);
  border: none;
}

h3 {
  margin-bottom: var(--column);
}

.pbx {
  background-color: white;
}

.fas {
  font-size: 32px;
  margin-bottom: calc(var(--column) / 2);
  display: block;
}

img {
  width: 200px;
  margin: 0 auto;
  margin-bottom: var(--column);
}
</style>