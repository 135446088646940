<template>
  <div class="incoming-videocall">
    <p v-html="$t('videocall.incoming', { callerid: displayName })"></p>
    <div class="buttons">
      <VideocallButton
        @click.native="setNotificationToInactive(index)"
        :_type="'answer'"
        :room-id="roomId"
        class="fas fa-video"
      ></VideocallButton>
      <i @click="setNotificationToInactive(index)" class="fas fa-times"></i>
    </div>
    <audio ref="ringtone" loop>
      <source
        src="/sounds/Slow_Asus_Zenfone_5Z_Stock-635292.mp3"
        type="audio/ogg"
      />
    </audio>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VideocallButton from "@/components/webrtc/VideocallButton";

export default {
  name: "IncomingVideocall",
  props: {
    displayName: {
      type: String,
      required: true,
    },
    roomId: {
      type: String,
      required: true,
    },
    index: {
      type: String,
      required: true,
    },
  },
  components: {
    VideocallButton,
  },
  methods: {
    ...mapActions(["setNotificationToInactive"]),
  },
  mounted() {
    this.$refs.ringtone.play()
  },
  beforeDestroy() {
   this.$refs.ringtone.pause()
  }
};
</script>

<style scoped>
.incoming-videocall {
  background-color: white;
  position: fixed;
  padding: calc(var(--column) * 2) var(--column);
  text-align: center;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  width: 250px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.incoming-videocall p {
  color: var(--blue-grey);
  font-size: 18px;
  font-weight: bold;
  line-height: 35px;
}

.incoming-videocall .buttons {
  display: flex;
  margin: 0 auto;
  margin-top: calc(var(--column) * 2);
  justify-content: space-between;
  width: 150px;
}

.incoming-videocall .fas {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
}

.incoming-videocall .fa-times {
  background-color: var(--red);
  border-radius: 50%;
  color: white;
}

.incoming-videocall .fa-video {
  background-color: var(--green);
  border-radius: 50%;
  color: white;
}

@media (max-width: 900px) {
  .incoming-videocall .fas {
    height: 60px;
    width: 60px;
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }
}
</style>