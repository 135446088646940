<template>
  <div class="main-content">
    <header>
      <button @click="goBack">
        <i class="fas fa-angle-left"></i>
        {{ $t("common.go-back") }}
      </button>
      <h2>{{ $t("contact-me.chat-center-of", { name }) }}</h2>
    </header>
    <div class="cards">
      <el-card shadow="hover" v-for="(item, index) in menu" :key="index" @click.native="$router.push({ name: item.route })" class="card">
        <div class="content">
          <i :class="item.icon"></i>
          <h3>{{ item.title }}</h3>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menu: {
      required: true,
      default: () => ([])
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "contact-me-config" });
    }
  },
  computed: {
    name() {
      return this.$store.getters["contactMe/getOrganizationName"];
    },
    organizationId() {
      return this.$store.getters["contactMe/getOrganizationId"];
    }
  }
}
</script>

<style scoped>
button {
  outline: none;
  border: none;
  background-color: transparent;
}

h2 {
  margin: var(--column) 0;
}

h3 {
  margin: 0;
}

.cards {
  width: 100%;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.card:hover {
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}
.cards .fas {
  display: block;
  padding: var(--column);
  border-radius: 50%;
}

.cards .extensions {
  color: var(--green-400);
  background-color: var(--green-100);
}

.content {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
</style>