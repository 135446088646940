<template>
  <el-dropdown placement="bottom-start" trigger="click" @click.native.stop>
		<span class="el-dropdown-link"><i class="fas fa-angle-down"></i></span>
		<el-dropdown-menu slot="dropdown">
      <div v-if="chat.type === 'group'">
			<el-dropdown-item v-if="chat.join" @click.native="openMembersModal" icon="fas fa-users">{{ $t("chats.group.members") }}</el-dropdown-item>
			<el-dropdown-item v-if="chat.owner === user && chat.join" @click.native="openGroupEdit" icon="fas fa-user-plus">{{ $t("chats.group.add-members") }}</el-dropdown-item>
			<el-dropdown-item @click.native="openDeleteGroupModal" icon="fas fa-trash" >{{ $t("chats.group.delete-group") }}</el-dropdown-item>
			<el-dropdown-item v-if="chat.join" @click.native="openLeaveGroupModal" icon="fas fa-sign-out-alt">{{ $t("chats.group.leave") }}</el-dropdown-item>
      </div>
      <el-dropdown-item @click.native="onClickFavorite" icon="fas fa-thumbtack">{{ (!chat.favorite) ? $t("chat.add-to-favorites") : $t("chat.remove-from-favorites") }}</el-dropdown-item>
		</el-dropdown-menu>
	</el-dropdown>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props : {
    chat : {
      required : true
    }
  },
  methods : {
    ...mapActions([
      "toggleGroupMembersModal",
			"setGroupGid",
			"setOwnerOfGroup",
			"setNameOfGroup",
			"setRommIdOfGroup",
			"toggleGroupMembers",
			"toggleGroupDeleteModal",
			"toggleGroupLeaveModal",
			"toggleGroupEditModal",
			"addToOpenedChats",
      "setOwnerUid"]),
    ...mapActions("corpochat",['toggleFavorite']),
    openMembersModal() {
      this.configureModal()
      this.toggleGroupMembersModal()
    },
    openGroupEdit() {
      this.configureModal()
      this.toggleGroupEditModal()
    },
    openDeleteGroupModal() {
      this.configureModal()
      this.toggleGroupDeleteModal()
    },
    openLeaveGroupModal() {
      this.configureModal()
      this.toggleGroupLeaveModal()
    },
    configureModal() {
      this.setRommIdOfGroup(this.chat.roomId);
			this.setNameOfGroup(this.chat.name);
			this.setOwnerOfGroup(this.chat.owner === this.$store.getters.getUser.uid);
			this.setGroupGid(this.chat.id);
      this.setOwnerUid(this.chat.owner)
    },
    onClickFavorite() {
      this.toggleFavorite({ id : this.chat.id, type : this.chat.type })
    },
  },
  computed : {
    user() {
      return this.$store.getters.getUser.uid
    }
  }
  
}
</script>

<style scoped>
.fa-angle-down {
  display: none;
}

</style>