<template>
  <div class="mainContainer">
    <button @click="goBack" class="goback">
      <i class="fas fa-angle-left"></i>
      {{ $t('common.go-back') }}
    </button>
    <div class='filters-row'>
      <el-select v-model="selected" placeholder="Seleccion organizacion" @change="fetchExtensions" >
        <el-option v-for="item in organizations" :key="item.id" :label="item.name" :value="item.id" />
      </el-select>
      <el-select v-model="filters" multiple collapse-tags>
        <el-option class="option-filter" v-for="item in options" :key="item" :label="$t(`extensionStatus.${item}`)"
          :value="item">
          <i v-if="item==='idle'" class="fas fa-phone idle"></i>
          <i v-if="item==='unavailable'" class="fas fa-phone unavailable"></i>
          <i v-if="item==='ringing'" class="fas fa-phone ringing"></i>
          <i v-if="item==='hold'" class="fas fa-phone hold"></i>
          <i v-if="item==='inuse'" class="fas fa-phone inuse"></i>
          <span>{{ item }}</span>
        </el-option>
      </el-select>
      <TheSearchInput class="searchExtensionInput" :placeholder="$t('common.searchExtensionOrName')" v-model="extensionSearch"></TheSearchInput>
      <!-- <el-input icon="el-icon-search" class="searchExtensionInput" placeholder="Enter the extension's name or number" v-model="extensionSearch"></el-input> -->
    </div>
    <div class="dashboard-content-container">
      <div class="extensions">
        <div class="extensions-status">
            <TheExtensionCard v-for="extension in extensionsFilter.list" :key="extension.extension" :extension="extension" class="agent-card" :ellipsis="true"></TheExtensionCard>
        </div>
        <el-pagination :hideOnSinglePage="true" :current-page.sync="currentPage" :page-size="itemsPerPage" layout="prev, pager, next" :total="extensionsFilter.total">
        </el-pagination>
      </div>
      <div class="calls">
        <TheCallCard v-for="currentCall in currentCallsFormated" :key="currentCall.callId" :src="currentCall.src" :dst="currentCall.dst" :startedAt="currentCall.startedAt"></TheCallCard>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TheExtensionCard from '@/components/dashboard/TheExtensionCard'
import TheCallCard from '../components/dashboard/TheCallCard.vue'
import TheSearchInput from '@/components/html/TheSearchInput'
export default {
  name: "ExtensionsDashboard",
  data(){
    return {
      selected: [],
      options: [
        'idle',
        'unavailable',
        'ringing',
        'hold',
        'inuse'
      ],
      filters: [
        'idle',
        'ringing',
        'hold',
        'inuse'
      ],
      currentPage: 0,
      itemsPerPage: 35,
      extensionsPage: [],
      extensionSearch: ''
    }
  },
  methods: {
    fetchExtensions(){
      this.$store.dispatch('extensionsFirebase/startListenExtensionsDashboard', this.selected);
      this.$store.dispatch('callsExtensionsFirebase/startListenCurrentCalls', this.selected)
    },
    goBack() {
      this.$store.dispatch('extensionsFirebase/stopLitenExtensionsDashboard');
      this.$store.dispatch('callsExtensionsFirebase/stopListenCurrentCalls');
      this.$router.push({ name: "switchBetweenDashboards"});
    },
  },
  components: {
    TheExtensionCard,
    TheCallCard,
    TheSearchInput
  },
  /* methods: {
    ...mapActions(['extensionsFirebase/startListenExtensionsDashboard']),
  } ,*/
  computed : {
    /* ...mapGetters(['getExtensionsDashboardList']),
    extensions () {
      // Esto no va a funcionar porque estas disparando una acción
      //return this.$store.dispatch('getExtensionsDashboardList');
      return this.getExtensionsDashboardList
    } */
    ...mapGetters({
      extensions: 'extensionsFirebase/getExtensionsDashboardList',
      currentCalls: 'callsExtensionsFirebase/getCurrentCallsList',
      user: 'getUser',
    }),
    extensionsModified(){
      let extensionsCopy = this.extensions
      if(this.currentCalls.length > 0){
        const currentCallsFiltered = this.currentCalls.filter((element) => element.realDestiny !== '')
        if(currentCallsFiltered.length > 0){
          for (const call of currentCallsFiltered) {
            for (let [index, extension] of extensionsCopy.entries()) {
              if(extension.extension === call.realDestiny){
                const dst = call.dst.replace(/^SIP\/|^IAX\//, '').replace(/-(.*)/, '')
                extensionsCopy[index].status = 'hold'
                extensionsCopy[index].fakeDestiny =  dst
              }
            }
          }
        }
      }
      return extensionsCopy
    },
    organizations(){
      return this.user.organizations;
    },
    extensionsFilter(){
      let extensionsList = this.extensionsModified.filter((element) => {
        return (
          (element.status.includes('ringing') && this.filters.includes('ringing')) ||
          (element.status.includes('inuse') && this.filters.includes('inuse')) ||
          (element.status.includes('idle') && this.filters.includes('idle')) ||
          (element.status.includes('unavailable') && this.filters.includes('unavailable')) || 
          (element.status.includes('hold') && this.filters.includes('hold'))
        )
      })
      if(this.extensionSearch.length > 2){
        extensionsList = extensionsList.filter((element) => {
          return element.name.includes(this.extensionSearch) || element.extension.includes(this.extensionSearch)
        }) 
      }
      return {list: extensionsList.slice((this.currentPage-1)*this.itemsPerPage, ((this.currentPage-1)*this.itemsPerPage) + this.itemsPerPage), total: extensionsList.length};
    },
    currentCallsFormated(){
      return this.currentCalls.map((element) => {
        let src = element.src.replace(/^SIP\/|^IAX\//, '');
        let dst = element.dst.replace(/^SIP\/|^IAX\//, '');
        const startedAt = (element.startedAt._seconds+element.startedAt._nanoseconds/1000000000)*1000;
        src = src.replace(/-(.*)/, '');
        dst = dst.replace(/-(.*)/, '');
        return {
          src,
          dst,
          startedAt
        }
      })
    }
  }
}
</script>
<style>
.searchExtensionInput input{
  background-color: #fff !important;
}
.dashboard-content-container .calls .el-card.box-card{
  height: auto !important;
}
.dashboard-content-container .calls .el-card.box-card p{
  margin-bottom: 0 !important;
}
.dashboard-content-container .calls .el-card .el-card__body{
  font-size: .9rem;
}
</style>
<style scoped >
button.goback {
  outline: none;
  border: none;
  background-color: transparent;
}
.dashboard-content-container {
  display: flex;
  gap: 10px;

}
.dashboard-content-container .extensions{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}
.dashboard-content-container .extensions-status{
  display: grid;
  grid-template: repeat(5, 1fr)/repeat(5, 1fr);
  gap: 10px;
  width: 60%;
  align-items: flex-start;
}
.dashboard-content-container .calls{
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.filters-row{
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
  width: 100%;
}
.searchExtensionInput{
  width: 40%;
}
.option-filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* COLOR TELEFONO ICONO */
.fa-phone{
  color: var(--green);
}
.unavailable {
  color: var(--danger);
}
.inuse{
  color: var(--green);
}
.idle{
  color: var(--grey-neutral-900);
}
.hold{
  color: #FFC300
}
.ringing {
  color: var(--blue);
}

@media (max-width: 1025px) {
  .dashboard-content-container .extensions-status{
    grid-template: repeat(5, 1fr)/repeat(3, 1fr);
  }
  
}
@media (min-width: 1026px) and (max-width: 1440px) {
  .dashboard-content-container .extensions-status{
    grid-template: repeat(5, 1fr)/repeat(5, 1fr);
  }
}
</style>

