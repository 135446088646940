<template>
  <div class="crud">
    <the-crud :title="title" :store="store" :columns="columns" :search="search" :filters="filters" :masive-creation-columns="masiveCreationColumns" can-delete :initQueryParams="params"></the-crud>
  </div>
</template>

<script>
import TheCrud from '@/components/crud/TheCrud'
export default {
  components : {
    TheCrud
  },
  data() {
    return {
      title : this.$t('configuration.extensions.title'),
      store : 'extensions',
      search : ['name'],
      columns : [
        { index : 'id', label : '#id', sortable : true, width: '100' },
        { index : 'name', label : this.$t("configuration.extensions.name") },
        { index : 'extension',label : this.$t("configuration.extensions.number") },
        { index : 'organization.name', label : this.$t("configuration.extensions.organization")},
        { index : 'secret', label : this.$t("configuration.extensions.password") },
        { index : 'used', label : this.$t("configuration.extensions.used"), type : 'boolean'},
      ],
      filters : [
        {
          type : 'text',
          index : 'organization.name',
          label : 'Organización',
          options : ['contains']
        },
        {
          type : 'text',
          index : 'extension',
          label : 'Numero',
          options : ['contains']
        },
        {
          type : 'boolean',
          index : 'used',
          label : this.$t("configuration.extensions.used"),
          booleanType : 'string'
        }
      ],
      masiveCreationColumns : [
        { 
          title : 'Name', 
          description : this.$t("masive-creation.extensions.Name"),
          type : 'string',
          example : 'John Doe'
        },
        { 
          title : 'Extension', 
          description : this.$t("masive-creation.extensions.Extension"),
          type : 'number',
          example : '500'
        },
        { 
          title : 'Secret', 
          description : this.$t("masive-creation.extensions.Secret"),
          type : 'string',
          example : 'Q1t5w2r4e3%'
        },
        { 
          title : 'Organization',
          description : this.$t("masive-creation.extensions.Organizatio"),
          type : 'number',
          example : '1'
        }
      ],
      params: {
        groups: 'crud'
      }
    }
  }
}
</script>

<style>

</style>