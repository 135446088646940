<template>
  <div v-loading="loading">
    <h2>Nuevo cliente</h2>
    <div v-if="!saved"> 
      <the-new-user-form class="form" ref="userForm" @created="created"/>
    </div>
    <NotificationABM route="mailcenter.usersList" :status="saved"
      :successTitle="$t('configuration.users.the-user-was-edited-successfully')"
      :text="
        saved
          ? this.user.name
          : $t('configuration.users.the-user-could-not-be-edited')
      "
      v-else
    />
  </div>
</template>

<script>
import NotificationABM from "@/components/common/NotificationABM";
import TheNewUserForm from '@/components/ticket/TheNewUserForm'
export default {
  components : {
    NotificationABM,
    TheNewUserForm,
  },
  data() {
    return {
      userEdit: this.user,
      loading : false,
      saved : false,
      errors : {},
      user : null
    }
  },
  methods : {
    created(user) {
      this.saved = true;
      this.user = user;
    }
  },
  mounted() {
    this.$refs.userForm.fetchForm()
  }
}
</script>

<style>

</style>