export default function createNotification(title, userInteraction = false) {
    if (Notification.permission == 'granted' && !document.hasFocus()) {
        const notification = new Notification(title);
        notification.onclick = function () {
            window.focus();
        };
    }

    if (userInteraction) {
        const audio = new Audio('/sounds/appointed.mp3');
        audio.play();
    }
}

