<template>
  <div>
    <the-table :rows="items" :columns="_columns" :loading="loading" :search="search" :real-time="true">
      <template v-slot:actions="scope">
        <el-dropdown trigger="click">
            <el-button icon="fas fa-ellipsis-h" type="text"></el-button>
            <el-dropdown-menu slot="dropdown">
                <slot name="action-buttons" v-bind:row="scope.row"></slot>
                <el-dropdown-item icon="fas fa-pencil-alt" @click.native="handleEdit(scope.row)">{{ $t("common.edit") }}</el-dropdown-item>
                <el-dropdown-item icon="fas fa-trash" @click.native="deleteEntity = scope.row; showDialogDeleteConfirm = true">{{ $t("common.delete") }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </template>
    </the-table>
    <el-dialog 
      :visible.sync="showDialogDeleteConfirm" 
      :close-on-click-modal="!deletingEntity" 
      :close-on-press-escape="!deletingEntity" 
      :show-close="!deletingEntity"
      @closed="deletingClosed"
      width="500px"
    >
    <div v-if="deletingStatus == 0">
      <div v-if="!deletingEntity">
        <h3>{{ this.$t("contact-me.are-you-sure-you-want-to-delete", { name: deleteEntityName } ) }}</h3>
        <p>{{ this.$t("contact-me.this-action-can-not-be-undone") }}</p>
      </div>
      <div v-else>
        <h3>{{ this.$t("contact-me.deleting-please-wait", { name: deleteEntityName }) }}</h3>
      </div>
    </div>
    <div v-else-if="deletingStatus == 200">
      <h3>{{ this.$t("contact-me.has-been-deleted-successfully", { name: deleteEntityName }) }}</h3>
    </div>
    <div v-else>
      <h3>{{ this.$t("contact-me.an-error-occurred-while-trying-to-delete", { name: deleteEntityName }) }}</h3>
    </div>
      <span slot="footer" class="dialog-footer" v-if="!deletingEntity && deletingStatus == 0">
        <el-button @click="showDialogDeleteConfirm = false; deleteEntity = null">{{ this.$t("common.no") }}</el-button>
        <el-button type="primary" @click="handleDelete()">{{ this.$t("common.yes") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TheTable from '@/components/crud/TheTable';
export default {
  props: {
    resource: {
      required: true,
    },
    columns: {
      required: true
    },
    search: {
      required: false,
    },
    id: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: true,
      deleteEntity: null,
      deletingEntity: false,
      deletingStatus: 0,
      showDialogDeleteConfirm: false,
    }
  },
  methods: {
    async fetch() {
      this.loading = true;
      await this.$store.dispatch(`pbx_${this.resource}/all`);
      this.loading = false;
    },
    handleEdit(row) {
      this.$router.push({
        name : `contact-me-pbx-${this.resource}-edit`,
        params : {
          id: row[this.id],
          entity : row
        }
      });
    },
    async handleDelete() {
      this.deletingEntity = true;
      //try {
        await this.$store.dispatch(`pbx_${this.resource}/delete`, this.deleteEntity[this.id]);
        this.deletingStatus = 200;
        this.$emit("deleted", this.deleteEntity);
      //} catch {
      //  this.deletingStatus = 400;
      //} finally {
        this.deletingEntity = false; 
      //}
    },
    deletingClosed() {
      this.deletingStatus = 0;
      this.deleteEntity = null;
      this.deletingEntity = false;
    }
  },
  computed: {
    items() {
      const items = this.$store.getters[`pbx_${this.resource}/getData`];
      if (Array.isArray(items)) return items;
      return Object.values(items);
    },
    _columns() {
      return [...this.columns, {
        index: "actions",
        label: ""
      }]
    },
    deleteEntityName() {
      return this.deleteEntity ? this.deleteEntity[this.name] : ""
    }
  },
  created() {
    this.fetch();
    this.$store.commit(`pbx_${this.resource}/SET_ID`, this.id);
  },
  components: {
    TheTable,
  }
}
</script>

<style scoped>
h3 {
  word-break: break-word;
}
</style>